import { useCallback } from 'react'
import {
	useGetSiteLiveQuery,
	selectSiteRfSensors,
	selectCameraTrackedTargetId,
	selectSiteAlwaysShowRf,
	selectCurrentCamera,
	selectSiteFilterDetections,
	selectSiteGenericSensors,
} from '@Store/sites/sitesWsApi'
import { skipToken } from '@reduxjs/toolkit/query'

import type { LatLngTuple } from 'leaflet'

import type { Detection, GenericSensor, RfSensor } from '@Store/types'
import {
	selectActiveTargetId,
	toggleActiveTargetId,
	// setActiveCameraId,
} from '@Store/ui/uiSlice'
import { useAppDispatch, useAppSelector } from '@Store/index'
import { useTrackDetectionMutation } from '@Store/cameras/camerasApi'

import DetectionMarker from '@Components/Markers/DetectionMarker/DetectionMarker'
import RfDetectionSector from '@Components/Sectors/RfDetectionSector/RfDetectionSector'
import useMeasurementToolActive from '@Components/MapControls/MeasurementTool/useMeasurementToolActive'
import ErrorBoundary from '@/components/App/ErrorHandling/ErrorBoundary'
import { selectUserSettings } from '@/store/user/userSlice'

// prettier-ignore
export const getSpeedLeaderPosition = (detection: Detection) =>
	detection.speed_leader_latitude !== 0 &&
	detection.speed_leader_longitude !== 0
		? [
			detection.speed_leader_latitude,
			detection.speed_leader_longitude,
		] as LatLngTuple
		: undefined

// Detections
const Detections = ({ siteId }: { siteId: number }) => {
	const { showControllerDetections = true, showWhitelistedDetections = true } =
		useAppSelector(selectUserSettings) ?? {}
	const {
		fusedDetections,
		genericSensors,
		rawRfDetections,
		rfSensors,
		trackedTargetId,
		alwaysShowRf,
		currentCamera,
	} = useGetSiteLiveQuery(siteId > 0 ? siteId : skipToken, {
		selectFromResult: ({ data }) => ({
			fusedDetections: selectSiteFilterDetections(data, {
				showControllerDetections,
				showWhitelistedDetections,
				rawRfDetection: false,
			}),
			genericSensors: selectSiteGenericSensors(data),
			rawRfDetections: selectSiteFilterDetections(data, {
				showControllerDetections,
				showWhitelistedDetections,
				rawRfDetection: true,
			}),
			rfSensors: selectSiteRfSensors(data),
			trackedTargetId: selectCameraTrackedTargetId(data),
			alwaysShowRf: selectSiteAlwaysShowRf(data),
			currentCamera: selectCurrentCamera(data),
		}),
	})

	const selectedTargetId = useAppSelector(selectActiveTargetId)
	const disableSelection = useMeasurementToolActive()

	const [trackDetection] = useTrackDetectionMutation()

	const dispatch = useAppDispatch()

	const handleClick = async (
		targetId: Detection['target_id'],
		e?: L.LeafletMouseEvent
	) => {
		dispatch(toggleActiveTargetId(targetId))
		if (currentCamera?.status_color === 'green') {
			if (e && e.originalEvent.shiftKey) {
				await trackDetection({
					siteId,
					cameraId: currentCamera?.id,
					targetId,
				}).unwrap()
				// TODO: check is this necessary
				// dispatch(setActiveCameraId(currentCamera?.id))
			}
		}
	}

	const getRfSensor = useCallback(
		(rfSensorId: RfSensor['id']) =>
			rfSensors.find((sensor) => sensor.id === rfSensorId),
		[rfSensors]
	)

	const getGenericSensor = useCallback(
		(genericSensorId: GenericSensor['id']) =>
			genericSensors.find((sensor) => sensor.id === genericSensorId),
		[genericSensors]
	)

	const isCameraLocked =
		currentCamera?.auto_track !== 0 && currentCamera?.target_locked

	return (
		<>
			{fusedDetections?.map((detection) => {
				return (
					<ErrorBoundary key={detection.target_id} quiet>
						<DetectionMarker
							targetId={detection.target_id}
							classification={detection.classification}
							latitude={detection.latitude}
							longitude={detection.longitude}
							speedLeaderPosition={getSpeedLeaderPosition(detection)}
							preventSave={detection.prevent_save}
							displayFiltered={detection.display_filtered}
							gcsPosition={[detection.gcs_latitude, detection.gcs_longitude]}
							homePosition={[detection.home_latitude, detection.home_longitude]}
							locationVariance={detection.location_variance}
							isWhitelisted={detection.state === 'whitelisted'}
							isSelected={detection.target_id === selectedTargetId}
							isTracking={detection.target_id === trackedTargetId}
							isTrackingLocked={
								isCameraLocked && detection.target_id === trackedTargetId
							}
							onMouseDown={(e) => handleClick(detection.target_id, e)}
						/>
						{alwaysShowRf &&
							!detection.raw_drone_locator_detection &&
							detection.detection_contributions
								.filter(({ sensor_type }) =>
									['rfSensor', 'dsx', 'generic'].includes(sensor_type)
								)
								.map((contribution, index) => {
									let rfSensor
									if (contribution.sensor_type === 'generic') {
										rfSensor = getGenericSensor(contribution.sensor_id)
										rfSensor = {
											...rfSensor,
											model: 'rf_one',
											show_sector_as_line:
												rfSensor?.detection_visualisation.includes('line') ??
												false,
											show_outside_detections: true,
											show_720_detection: false,
											show_sectors:
												rfSensor?.detection_visualisation.includes('sector') ??
												false,
											reach: rfSensor?.max_range,
										} as GenericSensor &
											Pick<RfSensor, 'model'> & {
												reach: GenericSensor['max_range']
												show_sectors: boolean
												show_sector_as_line: boolean
												show_outside_detections: boolean
												show_720_detection: boolean
											}
									} else {
										rfSensor = getRfSensor(contribution.sensor_id)
									}

									if (rfSensor)
										return (
											<ErrorBoundary
												key={`${detection.target_id}-always-show-rf-${index}`}
												quiet
											>
												<RfDetectionSector
													latitude={rfSensor.latitude}
													longitude={rfSensor.longitude}
													sensorBearing={rfSensor.direction}
													model={rfSensor.model}
													classification={detection.classification}
													reach={rfSensor.reach}
													aoa={contribution.aoa}
													aoaError={contribution.aoa_error}
													preventSave={detection.prevent_save}
													displayFiltered={detection.display_filtered}
													droneLocatorConfirmed={
														detection.drone_locator_confirmed
													}
													showSector={
														rfSensor.show_sectors ||
														(rfSensor.show_720_detection &&
															contribution.aoa > 12)
													}
													showLine={rfSensor.show_sector_as_line}
													showOutside={rfSensor.show_outside_detections}
													showNoAoa={rfSensor.show_720_detection}
													isWhitelisted={detection.state === 'whitelisted'}
													isSelected={detection.target_id === selectedTargetId}
													onMouseDown={
														disableSelection
															? undefined
															: () => handleClick(detection.target_id)
													}
												/>
											</ErrorBoundary>
										)
									else return null
								})}
					</ErrorBoundary>
				)
			})}

			{rawRfDetections.map((detection, index) =>
				detection.detection_contributions
					.filter(({ sensor_type }) =>
						['rfSensor', 'dsx', 'generic'].includes(sensor_type)
					)
					.map((contribution) => {
						const rfSensor = getRfSensor(contribution.sensor_id)
						if (rfSensor)
							return (
								<ErrorBoundary quiet key={`${detection.target_id}-${index}`}>
									<RfDetectionSector
										latitude={rfSensor.latitude}
										longitude={rfSensor.longitude}
										sensorBearing={rfSensor.direction}
										model={rfSensor.model}
										classification={detection.classification}
										reach={rfSensor.reach}
										aoa={contribution.aoa}
										aoaError={contribution.aoa_error}
										preventSave={detection.prevent_save}
										displayFiltered={detection.display_filtered}
										droneLocatorConfirmed={detection.drone_locator_confirmed}
										showSector={
											rfSensor.show_sectors ||
											(rfSensor.show_720_detection && contribution.aoa > 12)
										}
										showLine={rfSensor.show_sector_as_line}
										showOutside={rfSensor.show_outside_detections}
										showNoAoa={rfSensor.show_720_detection}
										isWhitelisted={detection.state === 'whitelisted'}
										isSelected={detection.target_id === selectedTargetId}
										onMouseDown={
											disableSelection
												? undefined
												: () => handleClick(detection.target_id)
										}
									/>
								</ErrorBoundary>
							)
						else return null
					})
			)}
		</>
	)
}

export default Detections
