// Chakra
import {
	FormControl,
	FormErrorMessage,
	FormLabel,
	NumberInput,
	NumberInputField,
} from '@chakra-ui/react'
// Components
import Tooltip from '@UI/Tooltip/Tooltip'
// Types
import { useEffect, useState, type FC } from 'react'
import type { RegisterType } from '@/components/FormElements/types/RegisterType'
import type { FormElementProps } from '@Components/FormElements/types/FormElementProps'
import { isValidNumber } from '@/utils/numbers'

type LatLongInputProps = FormElementProps & {
	type: 'latitude' | 'longitude'
	value?: number | null
	onChange?: (value: number) => void
	onFocus?: () => void
	onBlur?: () => void
} & RegisterType<string>

const LatLongInput: FC<LatLongInputProps> = ({
	id = undefined,
	title,
	type,
	error,
	register,
	disabled = undefined,
	testId,
	tooltip,
	value = undefined,
	onChange = undefined,
	onFocus = undefined,
	onBlur = undefined,
}) => {
	const [stringValue, setStringValue] = useState<string | undefined>(
		value?.toString()
	)

	const htmlId = id ?? register?.name ?? type

	const handleChange = (valueAsString: string, valueAsNumber: number) => {
		setStringValue(valueAsString)

		if (isValidNumber(valueAsNumber)) {
			onChange?.(valueAsNumber)
		}
	}

	// Overwrite local state if the props value is different than local value
	useEffect(() => {
		const number = parseFloat(stringValue ?? '')
		if (isValidNumber(value) && value !== number) {
			setStringValue(value?.toString())
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value])

	const errorMessage = error

	return (
		<Tooltip label={tooltip} type='info'>
			<FormControl isInvalid={!!error} isDisabled={disabled}>
				<FormLabel htmlFor={htmlId} aria-labelledby={title}>
					{title}
				</FormLabel>
				<NumberInput
					id={htmlId}
					precision={8}
					clampValueOnBlur={false}
					pattern='-*[0-9]*(.[0-9]+)?'
					value={stringValue}
					onChange={handleChange}
					onFocus={onFocus}
					onBlur={onBlur}
				>
					<NumberInputField {...(register || {})} data-testid={testId} />
				</NumberInput>
				<FormErrorMessage>{errorMessage}</FormErrorMessage>
			</FormControl>
		</Tooltip>
	)
}

export default LatLongInput
