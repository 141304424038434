import { useNavigate, useParams } from 'react-router-dom'
import { Button, Icon, Text, Spinner } from '@chakra-ui/react'

import {
	MdModeEdit,
	MdAdd,
	MdPlayArrow,
	MdClose,
	MdTrackChanges,
	MdFilterList,
	MdStop,
	MdOutlineReplay,
	MdOpenInNew,
	MdDelete,
	MdFilterTiltShift,
} from 'react-icons/md'
import Tooltip from '@UI/Tooltip/Tooltip'
import { useTranslation } from 'react-i18next'

import { useUiLoggerMutation } from '@Store/ui/uiLoggerApi'
import { getErrorMessage } from '@Store/utils/errorUtils'

const buttons = {
	add: {
		icon: MdAdd,
		label: 'add',
	},
	edit: {
		icon: MdModeEdit,
		label: 'edit',
	},
	delete: {
		icon: MdClose,
		label: 'delete',
	},
	delete_basket: {
		icon: MdDelete,
		label: 'delete',
	},
	enable: {
		icon: MdPlayArrow,
		label: 'enable',
	},
	disable: {
		icon: MdStop,
		label: 'disable',
	},
	mask: {
		icon: MdTrackChanges,
		label: 'editRadarMask',
	},
	filter: {
		icon: MdFilterList,
		label: 'editFilters',
	},
	restart: {
		icon: MdOutlineReplay,
		label: 'restart',
	},
	open: {
		icon: MdOpenInNew,
		label: 'open',
	},
	calibrate: {
		icon: MdFilterTiltShift,
		label: 'calibrate',
	},
}

type EditButtonProps = {
	type?:
		| 'edit'
		| 'add'
		| 'enable'
		| 'disable'
		| 'delete'
		| 'delete_basket'
		| 'mask'
		| 'filter'
		| 'restart'
		| 'open'
		| 'calibrate'
	to?: string
	onClick?: () => void
	disabled?: boolean
	label?: string
	testId?: string
	tooltip?: string
	isNoLabel?: boolean
	isLoading?: boolean
	asMenuListItem?: boolean
}

const EditButton = ({
	type = 'edit',
	to,
	onClick,
	disabled = false,
	label = undefined,
	testId,
	tooltip,
	isNoLabel = false,
	isLoading = false,
	asMenuListItem = false,
}: EditButtonProps) => {
	const navigate = useNavigate()
	const { siteId: siteIdParam } = useParams()
	const siteId = Number(siteIdParam)
	const [logUiMessage] = useUiLoggerMutation()

	const handleClick = () => {
		if (typeof onClick === 'function') {
			try {
				onClick()
			} catch (e) {
				const error = getErrorMessage(e)
				logUiMessage({
					siteId,
					level: 'error',
					message: error.message,
					trace: error.stack,
				})
			}
		} else if (typeof to === 'string') navigate(to)
	}

	const { t } = useTranslation('panels', {
		keyPrefix: 'siteInstallations.buttons',
	})

	return (
		<Tooltip label={tooltip} placement='bottom'>
			<Button
				onClick={handleClick}
				variant={asMenuListItem ? 'menuListItem' : 'unstyled'}
				isDisabled={disabled}
				display='flex'
				data-testid={testId}
				color='button_text'
			>
				<Icon
					as={buttons[type].icon}
					marginInlineEnd={1}
					boxSize={type === 'add' ? '20px' : undefined}
				/>
				{isNoLabel ? (
					<></>
				) : (
					<Text as='span' fontSize='0.75rem'>
						{label ? label : t(`${type}`)}
					</Text>
				)}
				{isLoading && <Spinner size='xs' ml={2} color='disabled' />}
			</Button>
		</Tooltip>
	)
}

export default EditButton
