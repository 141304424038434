import { z } from 'zod'
import globalSchema from '@/components/Forms/BaseForm/BaseForm.schema'

import i18n from 'i18next'
const t = (key: string) => i18n.t(`forms:radarForm.validation.${key}`)

const radarFormFields = z.object({
	name: globalSchema.shape.name,
	sentry_id: z.number(),
	serial_number: z.string().min(1),
	// altitude: z.number(),
	height_offset_to_installation:
		globalSchema.shape.height_offset_to_installation,
	direction_offset: globalSchema.shape.direction_offset,
	radar_type: z.enum(['rada', 'radar_zero', 'echo_shield']),
	reach_min: z.number().min(20),
	reach_max: z.number().max(10000),
	tilt: z.number().min(-45).max(45),
	roll: z.number().min(-45).max(45),
	channel: z.number(),
	tcm_num: z.number(),
	radar_az_step: z.number().min(2).max(120),
	radar_el_step: z.number().min(2).max(80),
	mask_clutter_width: z.number(),
	radar_az_max_search: z.number().min(-60).max(60),
	radar_az_max_track: z.number().min(-60).max(60),
	radar_az_min_search: z.number().min(-60).max(60),
	radar_az_min_track: z.number().min(-60).max(60),
	radar_el_min_search: z.number().min(-40).max(40),
	radar_el_max_search: z.number().min(-40).max(40),
	radar_el_min_track: z.number().min(-40).max(40),
	radar_el_max_track: z.number().min(-40).max(40),
	max_rcs: z.number(),
	min_rcs: z.number(),
	min_reporting_confidence: z.number().min(0).max(100),
	max_confidence_time: z.number().min(0).max(10),
	max_coast_time: z.number().min(0).max(10),
	max_target_velocity: z.number().min(0).max(100),
	north_offset_to_installation: z.number().min(-10).max(10),
	east_offset_to_installation: z.number().min(-10).max(10),
})

const refineMaxConfidenceTime = {
	path: ['max_confidence_time'],
	message: t('max_confidence_time'),
	code: z.ZodIssueCode.custom,
}

export const radarFormSchema = radarFormFields.superRefine((data, ctx) => {
	if (data.max_confidence_time > data.max_coast_time) {
		ctx.addIssue(refineMaxConfidenceTime)
	}
})
export type RadarSchema = z.infer<typeof radarFormSchema>

const defaultValuesSchema = radarFormFields
	.omit({ name: true, sentry_id: true, serial_number: true })
	.superRefine((data, ctx) => {
		// SuperRefined object can't be omitted
		// https://github.com/colinhacks/zod/issues/1245#issuecomment-1201329996
		if (data.max_confidence_time > data.max_coast_time) {
			ctx.addIssue(refineMaxConfidenceTime)
		}
	})

type DefaultValues = z.infer<typeof defaultValuesSchema>

export const ADD_RADAR_DEFAULT_VALUES: DefaultValues = {
	// altitude: 0
	height_offset_to_installation: 0,
	direction_offset: 0,
	radar_type: 'radar_zero',
	reach_min: 20,
	reach_max: 1000,
	tilt: 12,
	roll: 0,
	channel: 0,
	tcm_num: -1,
	radar_az_step: 4,
	radar_el_step: 12,
	mask_clutter_width: 3,
	radar_az_min_search: -48,
	radar_az_max_search: 48,
	radar_az_min_track: -60,
	radar_az_max_track: 60,
	radar_el_min_search: -16,
	radar_el_max_search: 32,
	radar_el_min_track: -28,
	radar_el_max_track: 40,
	max_rcs: -5,
	min_rcs: -50,
	min_reporting_confidence: 20,
	max_confidence_time: 1.0,
	max_coast_time: 4.0,
	max_target_velocity: 100,
	north_offset_to_installation: 0,
	east_offset_to_installation: 0,
}
