// React
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
// Chakra
import {
	Box,
	Card,
	CardHeader,
	CardBody,
	Flex,
	Spacer,
	useDisclosure,
} from '@chakra-ui/react'
// Components
import DeleteModal from '@UI/Modals/DeleteModal'
import ActionSection from '../../ActionSection'
import ActiveSensorButton from '../../ActiveSensorButton'
import EditButton from '../../EditButton'
import EditPalette from '../../EditPalette'
import DisruptorBandStatus from './DisruptorBandStatus'
import DeviceHeader from '../DeviceHeader'
import Chunk from '../Chunk'
// Utils
import { formatDirection } from '@Utils/formatUtils'
import useAuth from '@Hooks/useAuth'
import ConfigureAllBands from './ConfigureAllBands'
import { useDeleteDisruptorMutation } from '@Store/disruptors/disruptorsApi'
// Types
import { type SiteInstallation } from '@Store/types'
type DisruptorContentProps = {
	devices: SiteInstallation['disruptors']
}

const DisruptorContent = ({ devices }: DisruptorContentProps) => {
	const { siteId: siteIdParam, installationId } = useParams()
	const siteId = Number(siteIdParam)

	const { t } = useTranslation('panels', {
		keyPrefix: 'devicesContent',
	})

	const baseRoute = `/${siteId}/installation/${installationId}`

	const [disruptorToDelete, setDisruptorToDelete] = useState<{
		id: number
		name: string
	} | null>(null)

	const { isOpen, onOpen, onClose } = useDisclosure()
	const [deleteDisruptor] = useDeleteDisruptorMutation()

	const handleOpenDeleteModal = (id: number, name: string) => {
		onOpen()
		setDisruptorToDelete((prev) => ({ ...prev, id, name }))
	}

	const handleDeleteDisruptorSensor = async () => {
		try {
			if (disruptorToDelete) {
				await deleteDisruptor({ siteId, sensorId: disruptorToDelete.id })
				onClose()
			}
		} catch (error) {
			console.error('Delete disruptor error', error)
		}
	}

	const handleCloseDeleteModal = () => {
		onClose()
		setDisruptorToDelete(null)
	}

	const showConfigureAllBands =
		devices.length > 1 &&
		devices.every((device) => device.status_color !== 'red') // Comment out this condition to see the component on most sites

	const { isSiteManager } = useAuth()

	return (
		<>
			<Card variant='devices'>
				{isSiteManager && (
					<CardHeader textAlign='right'>
						<Spacer />
						<EditButton
							to={`${baseRoute}/disruptor/add`}
							type='add'
							label={t('buttons.add')}
							testId='add-effector'
						/>
					</CardHeader>
				)}
				<CardBody>
					{showConfigureAllBands && (
						<ConfigureAllBands siteId={siteId} devices={devices} />
					)}
					{devices.map((device) => (
						<Box key={device.id} paddingBlockEnd={4}>
							<Flex paddingInlineStart={2}>
								<DeviceHeader device={device} deviceType='disruptor' />
								<ActionSection>
									<ActiveSensorButton deviceId={device.id} />
									{isSiteManager && (
										<EditPalette testId={`device-menu-${device.name}`}>
											<EditButton
												to={`${baseRoute}/disruptor/${device.id}/settings`}
												label={t('buttons.edit')}
												disabled={device.power_trigger_engaged}
												tooltip={
													device.power_trigger_engaged
														? t('tooltips.cannot_update_while_disrupting')
														: undefined
												}
												testId='edit-device'
												asMenuListItem
											/>
											<EditButton
												onClick={() =>
													handleOpenDeleteModal(device.id, device.name)
												}
												label={t('buttons.delete')}
												type='delete'
												testId='delete-device'
												asMenuListItem
											/>
										</EditPalette>
									)}
								</ActionSection>
							</Flex>
							<Flex flexWrap='wrap' paddingInlineStart={8}>
								<Chunk
									units='deg'
									label={t('content.direction')}
									value={formatDirection(device.direction) ?? '-'}
									testId='direction'
								/>
								<Chunk
									label={t('content.status')}
									value={device.status}
									testId='status'
								/>
								<Chunk
									label={t('content.serial')}
									value={device.serial_number ?? '-'}
									testId='serial-number'
								/>
							</Flex>
							<DisruptorBandStatus id={device.id} disruptor={device} />
						</Box>
					))}
				</CardBody>
			</Card>

			<DeleteModal
				isOpen={isOpen}
				onClose={handleCloseDeleteModal}
				handleDelete={handleDeleteDisruptorSensor}
				name={disruptorToDelete?.name || ''}
			/>
		</>
	)
}

export default DisruptorContent
