import { type ChakraStylesConfig } from 'chakra-react-select'

// Extra styles applied to the select component, similar to how react-select is
// normally styled, but in a 'chakra manner'
// More info here: https://www.npmjs.com/package/chakra-react-select#chakrastyles

const chakraStylesSelect: ChakraStylesConfig = {
	option: (provided, { isFocused }) => {
		return {
			...provided,
			height: '44px',
			backgroundColor: 'body_bg',
			color: isFocused ? 'primary' : 'body_text',
			_selected: {
				bg: 'primary',
				color: 'body_text__dark',
			},
		}
	},
	// dropdownIndicator: (provided) => {
	// 	return {
	// 		...provided,
	// 		marginRight: '2px',
	// 		backgroundColor: 'transparent',
	// 	}
	// },
	// menu: (provided) => {
	// 	return {
	// 		...provided,
	// 		marginY: '2px',
	// 	}
	// },
	// menuList: (provided) => {
	// 	return {
	// 		...provided,
	// 		width: '160px',
	// 		backgroundColor: 'body_bg',
	// 		borderColor: 'transparent',
	// 		padding: '0px',
	// 	}
	// },
	valueContainer: (provided) => ({
		...provided,
		padding: '2px 6px',
	}),
}

const defaultSelectStyles: ChakraStylesConfig = {
	...chakraStylesSelect,
	container: (provided, { isFocused }) => {
		return {
			...provided,
			boxShadow: isFocused ? '0 0 0 1px var(--c2-colors-primary)' : undefined,
			borderRadius: '2px',
			transitionProperty: 'var(--c2-transition-property-common)',
			transitionDuration: 'var(--c2-transition-duration-normal)',
		}
	},
	control: (provided) => {
		return {
			...provided,
			marginX: '0 !important',
		}
	},
	option: (provided, { isFocused }) => ({
		...provided,
		height: '32px',
		paddingLeft: '8px',
		backgroundColor: 'body_bg',
		color: isFocused ? 'primary' : 'body_text',
		_selected: {
			bg: 'primary',
			color: 'body_text__dark',
		},
	}),
	menu: (provided) => {
		return {
			...provided,
			marginY: '2px',
		}
	},
	menuList: (provided) => ({
		...provided,
		width: '100%',
		// Set width for text overflow
		'& > div > div > div': {
			width: '180px',
		},
	}),
}

const siteModeSelectStyles: ChakraStylesConfig = {
	...chakraStylesSelect,
	control: (provided) => ({
		...provided,
		cursor: 'pointer',
	}),
	dropdownIndicator: (provided, { selectProps }) => ({
		...provided,
		'> svg': {
			transform: `rotate(${selectProps.menuIsOpen ? -180 : 0}deg)`,
			color: selectProps.menuIsOpen ? 'primary' : 'body_text',
			height: '24px',
			width: '24px',
		},
	}),
}

const siteSelectStyles: ChakraStylesConfig = {
	...siteModeSelectStyles,
	control: (provided) => ({
		...provided,
		cursor: 'pointer',
	}),
	singleValue: (provided) => ({
		...provided,
		// CSS Shit to set truncated text to selected control
		'& > div > div': {
			display: 'inline-block',
			maxW: '200px',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
	}),
	menuList: (provided) => ({
		...provided,
		width: '250px',
		right: '74px',
		// Set width for text overflow
		'& > div > div > div': {
			width: '180px',
		},
		'.AddSiteButton': {
			paddingInline: 0,
			paddingBlock: 0,
			div: {
				justifyContent: 'center',
				fontSize: 'sm',
				textTransform: 'uppercase',
				fontWeight: 500,
				width: '100%',
				height: '100%',
			},
		},
	}),
}

const multiSelectStyles: ChakraStylesConfig = {
	control: (provided) => ({
		...provided,
		_focus: {
			border: '1px solid var(--c2-colors-primary) !important',
		},
	}),
	menu: (provided) => ({
		...provided,
		width: 'max-content',
	}),
	valueContainer: (provided) => ({
		...provided,
		padding: '2px 6px',
	}),
	dropdownIndicator: (provided, { selectProps }) => ({
		...provided,
		padding: '0 .5rem 0 0',
		margin: 0,
		'> svg': {
			width: '1.25rem',
			height: '1.25rem',
			transform: `rotate(${selectProps.menuIsOpen ? -180 : 0}deg)`,
			transition: 'all .2s',
			color: `${selectProps.menuIsOpen ? 'primary' : ''}`,
		},
	}),
	option: (provided, state) => ({
		...provided,
		'.chakra-menu__icon-wrapper': {
			border: `1px solid ${state.isSelected ? 'transparent' : ''}`,
			borderRadius: '2px',
			opacity: 1,
			backgroundColor: `${state.isSelected ? 'primary' : ''}`,
			width: '12px',
			height: '12px',
			svg: {
				width: '8px',
				height: '8px',
				opacity: `${state.isSelected ? 1 : 0}`,
			},
		},
	}),
	noOptionsMessage: () => ({
		display: 'none',
	}),
}

const openedOnTopSelectStyles: ChakraStylesConfig = {
	...defaultSelectStyles,
	container: (provided) => ({
		...provided,
		maxW: '160px',
	}),
	menuList: (provided) => ({
		...provided,
		position: 'absolute',
		top: 'auto',
		bottom: 'calc(100% + 40px)',
		// Set width for text overflow
		'& > div > div > div': {
			width: '180px',
		},
	}),
}

const mapZoneSelectStyles: ChakraStylesConfig = {
	option: (provided, state) => ({
		...provided,
		paddingLeft: 2,
		paddingRight: 3,
		minHeight: '100px',
		background: 'body_bg',
		color: 'body_text',
		_active: null,
		_selected: null,
	}),
	menu: (provided) => ({
		...provided,
		marginBlockStart: 1,
		height: '100%',
	}),

	menuList: (provided) => ({
		height: '100%',
	}),
	control: (provided, { isFocused }) => {
		return {
			...provided,
			borderRadius: '2px',
			transitionProperty: 'var(--c2-transition-property-common)',
			transitionDuration: 'var(--c2-transition-duration-normal)',
			_focus: {
				border: '1px solid var(--c2-colors-primary) !important',
			},
		}
	},
}

const mapZoneMultiSelectStyles: ChakraStylesConfig = {
	menu: (provided) => ({
		...provided,
		marginBlockStart: 1,
		height: '100%',
	}),

	menuList: (provided) => ({
		height: '100%',
	}),

	control: (provided) => ({
		...provided,
		_focus: {
			border: '1px solid var(--c2-colors-primary) !important',
		},
	}),
	valueContainer: (provided) => ({
		...provided,
		padding: '2px 6px',
	}),
	dropdownIndicator: (provided, { selectProps }) => ({
		...provided,
		padding: '0 .5rem 0 0',
		margin: 0,
		'> svg': {
			width: '1.25rem',
			height: '1.25rem',
			transform: `rotate(${selectProps.menuIsOpen ? -180 : 0}deg)`,
			transition: 'all .2s',
			color: `${selectProps.menuIsOpen ? 'primary' : ''}`,
		},
	}),
	option: (provided, state) => ({
		...provided,
		paddingLeft: 2,
		paddingRight: 3,

		_selected: null,
		height: '100px',
		'.chakra-menu__icon-wrapper': {
			display: 'none',
		},
	}),
	noOptionsMessage: () => ({
		display: 'none',
	}),
}

export {
	defaultSelectStyles,
	siteModeSelectStyles,
	multiSelectStyles,
	siteSelectStyles,
	openedOnTopSelectStyles,
	mapZoneSelectStyles,
	mapZoneMultiSelectStyles,
}
