// Packages
import noop from 'lodash/noop'

export type DatePickerContentFooterProps = {
	onSubmit?: () => void
	onCancel?: () => void
}

const DatePickerContentFooter = ({
	onSubmit = noop,
	onCancel = noop,
}: DatePickerContentFooterProps) => {
	return (
		<div data-scope='date-picker' data-part='content-footer'>
			<button
				data-scope='date-picker'
				data-part='button-apply'
				onClick={onSubmit}
			>
				Apply
			</button>
			<button
				data-scope='date-picker'
				data-part='button-cancel'
				onClick={onCancel}
			>
				Cancel
			</button>
		</div>
	)
}

export default DatePickerContentFooter
