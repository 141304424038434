import FIGMA from '../_FIGMA'

const semanticTokens = {
	colors: {
		// CHAKRA default settings
		'chakra-body-bg': {
			default: 'red',
			_dark: FIGMA.colors.body_bg,
		},
		'chakra-body-text': {
			default: 'red',
			_dark: FIGMA.colors.body_text,
		},
		'chakra-border-color': {
			default: 'green',
			_dark: FIGMA.colors.border_color,
		},
		'chakra-inverse-text': {
			default: 'blue',
			_dark: 'blue',
		},
		'chakra-subtle-bg': {
			default: 'magenta',
			_dark: 'magenta',
		},
		'chakra-subtle-text': {
			default: 'red',
			_dark: 'cyan',
		},
		'chakra-placeholder-color': {
			default: FIGMA.colors.placeholder_color,
			_dark: FIGMA.colors.placeholder_color,
		},
		'form-error-color': 'magenta',
		// CUSTOM colors
		body_text: FIGMA.colors.body_text,
		body_text__dark: FIGMA.colors.body_text__dark,
		border_color: FIGMA.colors.border_color,
		body_bg: FIGMA.colors.body_bg,
		headings: FIGMA.colors.headings,
		input_color: FIGMA.colors.input_color,
		measuringLine: FIGMA.colors.measuringLine,
		input_bg: FIGMA.colors.input_bg,
		label_color: FIGMA.colors.label_color,
		primary: FIGMA.colors.primary,
		button_text: FIGMA.colors.action_button_color,
		navbar: {
			top: FIGMA.colors.top_navbar,
			left: FIGMA.colors.left_navbar,
		},
		menu: {
			bg: FIGMA.colors.menu_bg,
			bg_active: FIGMA.colors.menu_bg_active,
		},
		leaflet: {
			control_color: FIGMA.colors.leaflet_control_color,
			control_bg: FIGMA.colors.leaflet_control_bg,
			control_transparent_bg: FIGMA.colors.leaflet_control_transparent_bg,
			control_text: FIGMA.colors.leaflet_control_text,
		},
		camera: {
			control_color: FIGMA.colors.camera_control_color,
			control_bg: FIGMA.colors.camera_control_bg,
			recording_red: '#FA001B',
			recording_red_bg: '#FA001B66',
		},
		login_panel_bg: FIGMA.colors.login_panel_bg,
		panel_bg: FIGMA.colors.panel_bg,
		modal_bg: FIGMA.colors.modal_bg,
		modal_heading: FIGMA.colors.modal_heading,
		card_bg: FIGMA.colors.card_background,
		card_bg__disabled: FIGMA.colors.card_background__disabled,
		badge_bg: FIGMA.colors.badge_background,
		divider_bg: FIGMA.colors.divider_background,
		error: FIGMA.colors.error,
		success: FIGMA.colors.success,
		warning: FIGMA.colors.warning,
		disabled: FIGMA.colors.disabled,
		status: {
			green: FIGMA.colors.status.connection,
			red: FIGMA.colors.status.no_connection,
			orange: FIGMA.colors.status.lost_connection,
			grey: FIGMA.colors.status.no_status,
			// TODO: remove this hell of indirection?
			no_connection: FIGMA.colors.status.no_connection,
			connection: FIGMA.colors.status.connection,
			lost_connection: FIGMA.colors.status.lost_connection,
			no_status: FIGMA.colors.status.no_status,
		},
		detections: {
			default: FIGMA.colors.detections.default,
			whitelisted: FIGMA.colors.detections.whitelisted,
			selected: FIGMA.colors.detections.selected,
			not_saved_detection: FIGMA.colors.detections.not_saved_detection,
			display_filtered: FIGMA.colors.detections.display_filtered,
		},
		notifications: {
			alert: FIGMA.colors.notifications.alert,
			stale: FIGMA.colors.notifications.stale,
			warning: FIGMA.colors.notifications.warning,
			bg: FIGMA.colors.notifications.bg,
			active_bg: FIGMA.colors.notifications.active_bg,
		},
		sectors: {
			cameraStroke: FIGMA.colors.sectors.cameraStroke,
			cameraFill: FIGMA.colors.sectors.cameraFill,
			radarStroke: FIGMA.colors.sectors.radarStroke,
			radarFill: FIGMA.colors.sectors.radarFill,
			rfSensorStroke: FIGMA.colors.sectors.rfSensorStroke,
			rfSensorFill: FIGMA.colors.sectors.rfSensorFill,
			disruptorStroke: FIGMA.colors.sectors.disruptorStroke,
			disruptorFill: FIGMA.colors.sectors.disruptorFill,
			highlight: FIGMA.colors.sectors.highlight,
			dsxReach: FIGMA.colors.sectors.dsxReach,
			calibration: FIGMA.colors.sectors.calibration,
		},
		filter_bg: FIGMA.colors.filter_bg,
		filter_bg__disabled: FIGMA.colors.filter_bg__disabled,
		zones: {
			alert: FIGMA.colors.zones.alert,
			disrupt: FIGMA.colors.zones.disrupt,
			label: FIGMA.colors.zones.label,
			origin: FIGMA.colors.zones.origin,
			editMode: FIGMA.colors.zones.editMode,
		},
		charts: {
			grid: FIGMA.colors.charts.grid,
			bar: FIGMA.colors.charts.bar,
			pie: FIGMA.colors.charts.pie,
			line: FIGMA.colors.charts.line,
			distribution: FIGMA.colors.charts.distribution,
			customAmber: FIGMA.colors.charts.customAmber,
			customGreen: FIGMA.colors.charts.customGreen,
		},
		radarMasks: {
			fill: FIGMA.colors.radarMasks.fill,
			stroke: FIGMA.colors.radarMasks.stroke,
			active: FIGMA.colors.radarMasks.active,
		},
	},
}

export default semanticTokens
