// React
import { useTranslation } from 'react-i18next'
// Components
import { Button } from '@chakra-ui/react'
// Store
import { useAppDispatch } from '@/store'
import { addToast } from '@Store/ui/uiSlice'
import { useGetNotesQuery } from '@Store/notes/notesApi'
import { convertToCSV, downloadCsv } from '@Utils/download'

const NoteExport = ({ siteId }: { siteId: number }) => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'notes',
	})

	const dispatch = useAppDispatch()
	const { data: notes } = useGetNotesQuery({ siteId }, { skip: !siteId })

	const handleNoteExport = async () => {
		if (notes) {
			try {
				const formattedNotes = notes.map(
					({ id, email, name, body, siteId, created_at }) => ({
						id,
						name: name || email,
						body,
						siteId,
						created_at,
					})
				)
				const csvData = convertToCSV(formattedNotes)
				downloadCsv(csvData, `notes_${siteId}.csv`)
			} catch (error) {
				console.error(t('status.errorExport'), error)
				dispatch(
					addToast({
						type: 'error',
						description: t('status.errorExport'),
						siteId,
						error,
						id: `notes-${siteId}`,
					})
				)
			}
		}
	}

	return (
		notes &&
		notes.length > 0 && (
			<Button onClick={handleNoteExport} data-testid='back'>
				{t('buttons.export')}
			</Button>
		)
	)
}

export default NoteExport
