// Packages
import { useTheme } from '@chakra-ui/react'
import { ResponsiveBar } from '@nivo/bar'
import { SymbolCircle } from '@nivo/legends'

// Relatives
import useChartTheme from './useChartTheme'

import type {
	BarDatum,
	BarLegendProps,
	BarSvgProps,
	BarTooltipProps,
} from '@nivo/bar'
import type { AxisProps } from '@nivo/axes'
import type { FC } from 'react'

type BarChartProps = {
	data: BarDatum[]
	indexBy: string
	keys: string[]
	includeLegend?: boolean
	margin: BarSvgProps<BarDatum>['margin']
	padding?: BarSvgProps<BarDatum>['padding']
	isLabelTilted?: boolean
	legendX?: string
	legendY?: string
	tooltip?: FC<BarTooltipProps<BarDatum>>
}

const BarChart = ({
	data,
	keys,
	indexBy,
	margin,
	padding = 0.3,
	includeLegend,
	isLabelTilted,
	legendX,
	legendY,
	tooltip,
}: BarChartProps) => {
	const {
		semanticTokens: { colors },
	} = useTheme()

	const theme = useChartTheme()

	const hasData = data.length > 0

	const axisBottom: AxisProps = legendX
		? {
				legend: legendX,
				legendOffset: 48,
				legendPosition: 'middle',
			}
		: {}

	const axisLeft: AxisProps = legendY
		? {
				legend: legendY,
				legendOffset: -42,
				legendPosition: 'middle',
			}
		: {}

	const legends: BarLegendProps[] = [
		{
			dataFrom: 'keys',
			anchor: 'top',
			direction: 'row',
			translateX: 20,
			translateY: -30,
			toggleSerie: true,
			itemsSpacing: 10,
			itemWidth: 120,
			itemHeight: 18,
			symbolSize: 10,
			symbolShape: (props) => (
				<SymbolCircle
					{...props}
					fill={
						typeof props.id === 'string'
							? colors.charts.bar[keys.indexOf(props.id)]
							: props.fill
					}
				/>
			),
		},
	]

	return (
		<ResponsiveBar
			data={data}
			theme={theme}
			keys={keys}
			indexBy={indexBy}
			enableLabel={false}
			margin={margin}
			padding={padding}
			valueScale={{ type: 'linear', min: 0, max: hasData ? 'auto' : 25 }}
			indexScale={{ type: 'band', round: true }}
			colors={colors.charts.bar}
			labelSkipWidth={12}
			labelSkipHeight={12}
			legends={includeLegend ? legends : undefined}
			tooltip={tooltip}
			axisBottom={{
				...axisBottom,
				tickRotation: isLabelTilted ? 15 : undefined,
			}}
			axisLeft={{
				...axisLeft,
			}}
		/>
	)
}

export default BarChart
