// Packages
import { format, formatDistanceToNow } from 'date-fns'
import locale from 'date-fns/locale/en-US'

// Alias
import {
	convertDegreesToCardinal,
	convertDegreesToMils,
} from '@Utils/mathUtils'
import convertUnits from '@Utils/convertUnits'
import { ECHOSHIELD_RADAR_FREQUENCY_CHANNEL } from '@Constants/radars'
import { formatElapsedTimeLocaleFn } from './dates'

const SECONDS_IN_TWENTY_FOUR_HOURS = 24 * 60 * 60

export const formatEventId = (targetId: string | null) => {
	return targetId === null ? null : targetId.split('-')[0]
}

export const formatProbability = (probability: number) => {
	if (probability >= 0) return (probability * 100).toFixed(2) + '%'
	else return null
}

export const formatThreatLevel = (threatLevel: number, round = false) => {
	if (threatLevel <= 0) {
		return null
	}

	if (round) {
		return Math.round(threatLevel * 100) + '%'
	}

	return (threatLevel * 100).toFixed(2) + '%'
}

export const formatSpeed = (metersPerSecond: number) => {
	return metersPerSecond > 0 ? metersPerSecond.toFixed(2) + ' m/s' : null
}

export const formatRadarRCS = (rcs: number) => {
	return isNaN(rcs) || rcs === 0 ? null : rcs.toFixed(2)
}

// TODO: prior to this formatRssi function, all format functions in this
// file return the proper formatted data, or null, and consumers of the format
// utils are responsible to replace null with '-' or whatever.
//
// should we change this behaviour and expose a defaultValue parameter like this
// across all the format functions?
//
export const formatRssi = (rssi: number, defaultValue = '-') => {
	return isNaN(rssi) || rssi === 0 ? defaultValue : rssi
}

export const formatFrequency = (frequencyHz: number, decimals: number = 4) => {
	if (frequencyHz > 0) {
		const conversion = convertUnits(frequencyHz).from('Hz').toBest()
		return conversion
			? conversion.val.toFixed(decimals) + conversion.unit
			: null
	}

	return null
}

export const formatHeight = (height: number) => {
	const conversion = convertUnits(height)
		.from('m')
		.toBest({ exclude: ['nm', 'μm', 'mm', 'cm'] })
	return conversion !== null
		? conversion.val.toFixed(2) + conversion.unit
		: null
}

export const formatDistance = (distance: number) => {
	if (distance > 0 && distance < 10000) {
		return `${Math.round(distance)}m`
	}
	if (distance > 0 && distance >= 10000) {
		return `${(distance / 1000).toFixed(2)}km`
	}
	return null
}

export const formatBeaconString = (beaconString: string) => {
	if (!beaconString || beaconString === 'Empty_Field') return null
	else return beaconString
}

export const formatCardinal = (heading: number) => {
	return heading === null ? null : convertDegreesToCardinal(heading)
}

export const formatHeading = (heading: number, isMils?: boolean) => {
	if (heading === null) {
		return null
	}
	if (isMils) {
		return formatDegToMils(heading)
	}
	return heading?.toFixed(0)
}

export const formatElevation = (elevation: number, isMils?: boolean) => {
	if (elevation === null) {
		return null
	}
	if (isMils) {
		return convertDegreesToMils(elevation)
	}
	return elevation?.toFixed(2)
}

export const formatDirection = (direction: number) => {
	return direction === null ? null : direction?.toFixed(2)
}

export const formatLatLng = (latOrLng: number) => {
	if (isNaN(latOrLng)) return null
	else if (latOrLng === 0) return null
	else if (latOrLng === -9999) return null
	return latOrLng.toFixed(5)
}

export const formatSecondsToMinutes = (seconds: number) => {
	const date = new Date(0)
	date.setSeconds(seconds)
	return format(date, 'mm:ss')
}

/**
 * Bounds checks the seconds to 0 < seconds < 24 hours and then passes to `formatMillisecondsDurations`.
 */
export const formatTimeOnTarget = (seconds: number) => {
	if (isNaN(seconds) || seconds < 0) return null
	if (seconds > SECONDS_IN_TWENTY_FOUR_HOURS) return '> 24h'

	return formatMillisecondsAsDuration(seconds * 1000)
}

// Formats milliseconds to a duration like: 3h 1m 12s
export const formatMillisecondsAsDuration = (ms: number) => {
	if (!ms || ms < 0 || ms > Number.MAX_VALUE) return null

	const seconds = Math.floor((ms / 1000) % 60)
	const minutes = Math.floor((ms / 1000 / 60) % 60)
	const hours = Math.floor(ms / 1000 / 60 / 60)

	if (hours > 0) {
		return `${hours}h ${minutes}m ${seconds}s`
	}

	if (minutes > 0) {
		return `${minutes}m ${seconds}s`
	}

	return `${seconds}s`
}

export const formatBytes = (bytes: number) => {
	if (bytes < 1024) {
		// Less than 1 KB, display in bytes
		return `${bytes} B`
	} else if (bytes < 1024 * 1024) {
		// Between 1 KB and 1 MB, convert to KB
		return `${(bytes / 1024).toFixed(2)} KB`
	} else if (bytes < 1024 * 1024 * 1024) {
		// Between 1 MB and 1 GB, convert to MB
		return `${(bytes / 1024 / 1024).toFixed(2)} MB`
	} else {
		// 1 GB and above, convert to GB
		return `${(bytes / 1024 / 1024 / 1024).toFixed(2)} GB`
	}
}

export const formatDegToMils = (deg: number) => {
	return (deg * (6400 / 360)).toFixed(0)
}

export const formatMilsToDeg = (mils: number) => {
	return (mils * (360 / 6400)).toFixed(0)
}

export const formatRadarFrequencyChannel = (
	radarType: string,
	channel: number
) => {
	// radar_zero we return just the number but since it starts from zero we add 1
	if (radarType === 'radar_zero') return channel + 1

	return (
		ECHOSHIELD_RADAR_FREQUENCY_CHANNEL.find(({ value }) => value === channel)
			?.label ?? null
	)
}

export const formatElapsedTime = (date: Date | number, options = {}) =>
	formatDistanceToNow(date, {
		...options,
		addSuffix: true,
		locale: { ...locale, formatDistance: formatElapsedTimeLocaleFn },
	})

export const formatVendor = (vendor?: string) => {
	// This constant is coming from backend when is `null`
	if (vendor === 'Empty_Field') {
		return null
	}

	return vendor
}

export const formatProtocol = (protocol?: string) => {
	// This constant is coming from backend when is `null`
	if (protocol === 'Empty_Field') {
		return null
	}

	return protocol
}
