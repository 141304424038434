import { createSelector } from '@reduxjs/toolkit'
import { baseApi } from '@Store/baseApi'
import { type CoreApiError, formatApiErrors } from '@Store/utils/errorUtils'

import type { RfSensor, Site, DsxProfile } from '../types'
import type { RfSchema } from '@Components/Forms/RfSensorForm/RfSensorForm.schema'
import type { DsxFormSchema } from '@Components/Forms/DsxForm/DsxForm.schema'

import {
	DEFAULT_PROTOCOL_LABELS,
	DEFAULT_VENDOR_LABELS,
} from '@Constants/analytics'

type GetRfSensorRequest = { siteId: Site['id']; sensorId: RfSensor['id'] }
type CreateRfSensorRequest = (RfSchema | DsxFormSchema) & { siteId: Site['id'] }
type UpdateRfSensorRequest = (Partial<RfSchema> | Partial<DsxFormSchema>) & {
	sensorId: RfSensor['id']
	siteId: Site['id']
	// Hack for DSX
	gnss_trigger_command?: boolean
	band_24_58_trigger_command?: boolean
}
type DeleteRfSensorRequest = { siteId: Site['id']; sensorId: RfSensor['id'] }
type ToggleRfSensorRequest = {
	siteId: Site['id']
	sensorId: RfSensor['id']
	active: boolean
}
type ToggleDsxDisruptRequest = {
	siteId: Site['id']
	sensorId: RfSensor['id']
	power_trigger_command: boolean
}
type UpdateAllRfSensorsRequest = {
	siteId: Site['id']
	rfSensors: RfSensor[]
	power_trigger_command: boolean
}
type RestartAllRfSensorsRequest = {
	siteId: Site['id']
}

export const rfSensorsApi = baseApi
	.enhanceEndpoints({ addTagTypes: ['RfSensor'] })
	.injectEndpoints({
		endpoints: (builder) => ({
			getRfSensor: builder.query<RfSensor, GetRfSensorRequest>({
				query: ({ siteId, sensorId }) => ({
					url: `/api/sites/${siteId}/rf_sensors/${sensorId}`,
				}),
				providesTags: ['RfSensor'],
				transformResponse: (response: { rf_sensor: RfSensor }) =>
					response.rf_sensor,
				keepUnusedDataFor: 0,
			}),
			getSiteRfSensors: builder.query<RfSensor[], { siteId: Site['id'] }>({
				query: ({ siteId }) => ({
					url: `/api/sites/${siteId}/rf_sensors`,
				}),
				transformResponse: (response: { rf_sensors: RfSensor[] }) =>
					response.rf_sensors,
				providesTags: ['RfSensor'],
			}),
			createRfSensor: builder.mutation<RfSensor, CreateRfSensorRequest>({
				query: ({ siteId, ...rfSensor }) => ({
					url: `/api/sites/${siteId}/rf_sensors`,
					method: 'POST',
					body: {
						rf_sensor: rfSensor,
					},
				}),
				transformResponse: (response: { rf_sensor: RfSensor }) =>
					response.rf_sensor,
				transformErrorResponse: (response: CoreApiError) =>
					formatApiErrors<RfSensor>(response),
				invalidatesTags: ['RfSensor'],
			}),
			updateRfSensor: builder.mutation<RfSensor, UpdateRfSensorRequest>({
				query: ({ siteId, sensorId, ...rfSensor }) => ({
					url: `/api/sites/${siteId}/rf_sensors/${sensorId}`,
					method: 'PUT',
					body: {
						rf_sensor: rfSensor,
					},
				}),
				transformResponse: (response: { rf_sensor: RfSensor }) =>
					response.rf_sensor,
				transformErrorResponse: (response: CoreApiError) =>
					formatApiErrors<RfSensor>(response),
				invalidatesTags: ['RfSensor'],
			}),
			toggleRfSensor: builder.mutation<RfSensor, ToggleRfSensorRequest>({
				query: ({ siteId, sensorId, active }) => ({
					url: `/api/sites/${siteId}/rf_sensors/${sensorId}`,
					method: 'PATCH',
					body: {
						rf_sensor: {
							active,
						},
					},
				}),
				invalidatesTags: ['RfSensor'],
				transformResponse: (response: { rf_sensor: RfSensor }) =>
					response.rf_sensor,
			}),
			toggleDsxJamming: builder.mutation<RfSensor, ToggleDsxDisruptRequest>({
				query: ({ siteId, sensorId, power_trigger_command }) => ({
					url: `/api/sites/${siteId}/rf_sensors/${sensorId}`,
					method: 'PATCH',
					body: {
						rf_sensor: {
							power_trigger_command,
						},
					},
				}),
				invalidatesTags: ['RfSensor'],
				transformResponse: (response: { rf_sensor: RfSensor }) =>
					response.rf_sensor,
			}),
			deleteRfSensor: builder.mutation<
				{ message: string },
				DeleteRfSensorRequest
			>({
				query: ({ siteId, sensorId }) => ({
					url: `/api/sites/${siteId}/rf_sensors/${sensorId}`,
					method: 'DELETE',
				}),
				invalidatesTags: ['RfSensor'],
			}),
			updateAllRfSensors: builder.mutation<
				Promise<void[]>,
				UpdateAllRfSensorsRequest
			>({
				async queryFn(
					{ siteId, rfSensors, power_trigger_command },
					_queryApi,
					_extraOptions,
					baseQuery
				) {
					const promises = rfSensors.map(async (rfSensor: RfSensor) => {
						await baseQuery({
							url: `/api/sites/${siteId}/rf_sensors/${rfSensor.id}`,
							method: 'PATCH',
							body: {
								rf_sensor: {
									power_trigger_command,
								},
							},
						})
					})
					const result = Promise.all(promises)
					return { data: result }
				},
				invalidatesTags: ['RfSensor'],
			}),
			getDsxProfiles: builder.query<
				DsxProfile[],
				{ siteId: Site['id']; sensorId: number }
			>({
				query: ({ siteId, sensorId }) => ({
					url: `/api/sites/${siteId}/rf_sensors/${sensorId}/profiles`,
				}),
				transformResponse: (response: { profiles: DsxProfile[] }) =>
					response.profiles,
			}),
			restartSiteRfSensors: builder.mutation<
				string,
				RestartAllRfSensorsRequest
			>({
				query: ({ siteId }) => ({
					url: `/api/sites/${siteId}/restart_sensors`,
					method: 'POST',
					body: {
						sensor_type: 'rf_sensors',
					},
				}),
				invalidatesTags: ['RfSensor'],
			}),
		}),
	})

export const selectAllProtocols = createSelector(
	(data?: RfSensor[]) => data,
	(rfSensor) => {
		const protocols = rfSensor?.map((sensor: RfSensor) =>
			Object.values(sensor?.supported_detections || {})
				.flatMap((freq) => freq.protocols)
				.flatMap((protocol) => (protocol ? protocol.protocol : []))
		)
		const sortedProtocols = Array.from(new Set(protocols?.flat().sort()))

		return sortedProtocols.length > 0
			? sortedProtocols
			: DEFAULT_PROTOCOL_LABELS
	}
)

export const selectAllVendors = createSelector(
	(data?: RfSensor[]) => data,
	(rfSensor) => {
		const vendors = rfSensor?.map((sensor: RfSensor) =>
			Object.values(sensor?.supported_detections || {})
				.flatMap((freq) => freq.protocols)
				.flatMap((protocol) => (protocol ? protocol.vendor : []))
		)
		const sortedVendors = Array.from(new Set(vendors?.flat().sort()))

		return sortedVendors.length > 0 ? sortedVendors : DEFAULT_VENDOR_LABELS
	}
)

export const {
	useGetRfSensorQuery,
	useCreateRfSensorMutation,
	useUpdateRfSensorMutation,
	useDeleteRfSensorMutation,
	useToggleRfSensorMutation,
	useToggleDsxJammingMutation,
	useUpdateAllRfSensorsMutation,
	useGetSiteRfSensorsQuery,
	useGetDsxProfilesQuery,
	useRestartSiteRfSensorsMutation,
} = rfSensorsApi
