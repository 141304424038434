import { useParams } from 'react-router-dom'
import { useEffect, useRef } from 'react'
import type {
	Camera,
	Disruptor,
	GpsCompass,
	Radar,
	RfSensor,
	GenericSensor,
	SensorType,
	SiteInstallation,
} from '@Store/types'
import {
	Box,
	Card,
	CardHeader,
	CardBody,
	Flex,
	Heading,
	CardFooter,
} from '@chakra-ui/react'

import { Subheading } from '@UI/Headings/Headings'
import StatusIcon from '@UI/StatusIcon/StatusIcon'

import EditButton from './EditButton'
import DeviceButton from './DeviceButton'
import DevicesContent from './DevicesContent/DeviceContent'

import { sensorStatusColorMap } from '@Store/types'

import { formatLatLng } from '@Utils/formatUtils'
import {
	useGetSystemInfoQuery,
	selectIsTactical,
	selectIsDefeatEnabled,
} from '@Store/system/systemApi'

import useAuth from '@Hooks/useAuth'

export type DevicesList = {
	rf_sensors: RfSensor[]
	rf_sensors_dsx: RfSensor[]
	radars?: Radar[]
	cameras?: Camera[]
	disruptors?: Disruptor[]
	gps_compasses?: GpsCompass[]
	generic_sensors?: GenericSensor[]
}

export type DevicesListTactical = Pick<
	DevicesList,
	'rf_sensors' | 'rf_sensors_dsx'
>

type InstallationProps = {
	installation: SiteInstallation
}

const Installation = ({ installation }: InstallationProps) => {
	const {
		siteId: siteIdParam,
		installationId: activeInstallationIdParam,
		sensorType: activeSensorType,
	} = useParams()
	const siteId = Number(siteIdParam)
	const activeInstallationId = Number(activeInstallationIdParam)

	const { isSiteManager } = useAuth()

	const activeInstallationRef = useRef<HTMLDivElement | null>(null)
	useEffect(() => {
		if (activeInstallationRef.current) {
			activeInstallationRef.current.scrollIntoView()
		}
	}, [])

	// C2T
	const {
		isTactical,
		isDefeatEnabled,
		isSuccess: isTacticalSuccess,
	} = useGetSystemInfoQuery(undefined, {
		selectFromResult: ({ data, isSuccess }) => ({
			isTactical: selectIsTactical(data),
			isDefeatEnabled: selectIsDefeatEnabled(data),
			isSuccess,
		}),
	})

	const {
		id: installationId,
		name,
		latitude,
		longitude,
		status_color,
		rf_sensors_status_color,
		rf_sensors_dsx_status_color,
		radars_status_color,
		cameras_status_color,
		disruptors_status_color,
		gps_compasses_status_color,
		generic_sensors_status_color,
		rf_sensors,
		radars,
		cameras,
		disruptors,
		gps_compasses,
		generic_sensors,
	} = installation

	const sensorStatusColors = {
		rf_sensors: rf_sensors_status_color,
		rf_sensors_dsx: rf_sensors_dsx_status_color,
		radars: radars_status_color,
		cameras: cameras_status_color,
		disruptors: disruptors_status_color,
		gps_compasses: gps_compasses_status_color,
		generic_sensors: generic_sensors_status_color,
	}

	// Note: could use sensor selectors to grab devices instead
	const devices: DevicesList = {
		radars,
		rf_sensors: rf_sensors.filter((sensor) => !sensor.model.includes('dsx')),
		rf_sensors_dsx: rf_sensors.filter((sensor) => sensor.model.includes('dsx')),
		cameras,
		disruptors: disruptors.filter(
			(disruptor) => !disruptor.cannon_type.includes('DSX')
		),
		generic_sensors,
		gps_compasses,
	}

	if (isTactical) {
		delete devices.radars
		delete devices.cameras
		delete devices.gps_compasses
		delete devices.generic_sensors
	}

	if (isTactical || !isDefeatEnabled) {
		delete devices.disruptors
	}

	const activeDevices =
		activeSensorType && installationId === activeInstallationId
			? devices[activeSensorType as keyof DevicesList]
			: null

	const placeActiveInstallationRef = activeInstallationId === installationId

	return (
		isTacticalSuccess && (
			<Card
				ref={placeActiveInstallationRef ? activeInstallationRef : null}
				variant='installation'
				data-testid={`installation-card-${installationId}`}
				className='Installation'
			>
				<CardHeader>
					<Flex alignItems='center' justifyContent='space-between'>
						<Flex isTruncated>
							<StatusIcon
								color={sensorStatusColorMap[status_color]}
								testId={`${installationId}-installation-status-color-${status_color}`}
							/>
							<Box paddingInlineStart={1} isTruncated>
								<Heading
									color='white'
									size='xs'
									isTruncated
									data-testid={`installation-name-${installationId}`}
								>
									{name}
								</Heading>
								<Subheading
									data-testid={`installation-location-${installationId}`}
								>
									{formatLatLng(latitude) ?? '-'}{' '}
									{formatLatLng(longitude) ?? '-'}
								</Subheading>
							</Box>
						</Flex>
						<Box>
							{isSiteManager && (
								<EditButton
									to={`/${siteId}/installation/${installationId}/settings`}
									testId={`edit-installation-${installationId}`}
								/>
							)}
						</Box>
					</Flex>
				</CardHeader>
				<CardBody>
					<Flex wrap='wrap' gap='1px'>
						{Object.keys(devices).map((sensorType, index) => {
							const active =
								installationId === activeInstallationId &&
								sensorType === activeSensorType

							const to = active
								? `/${siteId}/installations/`
								: `/${siteId}/installations/${installationId}/${sensorType}`

							return (
								<DeviceButton
									key={index}
									to={to}
									type={sensorType as SensorType}
									count={
										devices[sensorType as keyof typeof devices]?.length ?? 0
									}
									active={active}
									color={
										sensorStatusColorMap[
											sensorStatusColors[
												sensorType as keyof typeof sensorStatusColors
											]
										]
									}
								/>
							)
						})}
					</Flex>
				</CardBody>
				<CardFooter>
					{activeDevices && (
						<DevicesContent
							installationDirection={installation.direction}
							acceptLocationUpdates={installation.accept_location_updates}
							sensorType={activeSensorType as keyof DevicesList}
							devices={activeDevices}
						/>
					)}
				</CardFooter>
			</Card>
		)
	)
}

export default Installation
