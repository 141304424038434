// Packages
import { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
	useReactTable,
	getCoreRowModel,
	createColumnHelper,
} from '@tanstack/react-table'
import { Flex, Box } from '@chakra-ui/react'
import { MdDownload } from 'react-icons/md'

// Alias
import IconButton from '@UI/IconButton/IconButton'
import FetchError from '@UI/FetchError/FetchError'
import TanStackTable from '@UI/TanStackTable/Table'
// import Pagination from '@UI/TanStackTable/Pagination'
import Skeleton from '@UI/Skeleton/Skeleton'
import { formatDateTz } from '@Utils/dates'
// import { capitalize } from '@Utils/stringUtils'
import { formatBytes } from '@/utils/formatUtils'
import { useGetRecordingToolListQuery } from '@/store/recordingTool/recordingToolApi'

// Types
import type { PaginationState } from '@tanstack/react-table'
import type { RecordingToolLog } from '@/store/types/recordingTool'
import { fetchFile } from '@/utils/download'
import useAuth from '@/hooks/useAuth'

type RecordingToolProps = {
	startTime: string
	endTime: string
	siteTimeZone: string
	siteId: number
	testId?: string
}

const columnHelper = createColumnHelper<RecordingToolLog>()

const RecordingToolTable = ({
	startTime,
	endTime,
	siteTimeZone,
	siteId,
	testId,
}: RecordingToolProps) => {
	const { t } = useTranslation('pages', {
		keyPrefix: 'analytics.recordingTool',
	})

	const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 10,
	})

	const pagination = useMemo(
		() => ({ pageIndex, pageSize }),
		[pageIndex, pageSize]
	)

	const handleDownloadVideoRecording = async (
		siteId: number,
		recordingId: number,
		archive_name: string = 'recording_tool.zip'
	) => {
		try {
			const file = await fetchFile(
				`/api/sites/${siteId}/recording_tool/${recordingId}/download`
			)
			if (!file) {
				return
			}

			const url = window.URL.createObjectURL(file)
			const a = document.createElement('a')
			a.href = url
			a.download = archive_name
			document.body.appendChild(a)
			a.click()
			window.URL.revokeObjectURL(url)
		} catch (error) {
			console.error('TODO: unhandled download video recording error', error)
		}
	}

	const { isAdmin, isManager } = useAuth()

	const { isSuccess, isError, isFetching, isLoading, refetch, logs } =
		useGetRecordingToolListQuery(
			{
				siteId,
				startTime,
				endTime,
			},
			{
				selectFromResult: ({
					isSuccess,
					isError,
					isFetching,
					isLoading,
					data,
				}) => ({
					isSuccess,
					isError,
					isFetching,
					isLoading,
					logs: data ?? [],
				}),
			}
		)

	const columns = [
		columnHelper.accessor('created_at', {
			id: 'created_at',
			header: t('table.timestamp'),
			cell: (info) => formatDateTz(info.getValue(), siteTimeZone),
		}),
		columnHelper.accessor('description', {
			id: 'description',
			header: t('table.description'),
		}),
		// columnHelper.accessor('initiating_user_id', {
		// 	id: 'id',
		// 	header: t('table.recordedBy'),
		// 	cell: (info) => capitalize(`${info.getValue()}`),
		// }),
		columnHelper.accessor('size', {
			id: 'size',
			header: t('table.size'),
			cell: (info) => formatBytes(info.getValue()),
		}),
		columnHelper.display({
			id: 'details',
			header: t('table.details'),
			cell: ({ row }) => (
				<Box paddingInlineStart={2}>
					<IconButton
						icon={<MdDownload />}
						testId='details-icon'
						aria-label='details-icon'
						onClick={() =>
							handleDownloadVideoRecording(
								row.original.site_id,
								row.original.id,
								row.original.archive_name
							)
						}
					/>
				</Box>
			),
		}),
	]

	// If is manager or operator, add recorded by column to table
	if (isAdmin || isManager) {
		const column = columnHelper.accessor('initiating_user_name', {
			id: 'initiating_user_name',
			header: t('table.recorded_by'),
		})

		columns.splice(2, 0, column)
	}

	// const pageCount = totalCount ? Math.ceil(totalCount / pageSize) : 0

	const logsFiltered = useMemo(
		() => logs.filter((log) => log.status !== 'ACTIVE'),
		[logs]
	)

	const table = useReactTable({
		columns,
		data: logsFiltered as RecordingToolLog[],
		getCoreRowModel: getCoreRowModel(),
		// pageCount: pageCount ?? -1,
		state: { pagination },
		onPaginationChange: setPagination,
		manualPagination: true,
	})

	return (
		<Skeleton isLoaded={!isLoading && !isFetching}>
			{isSuccess && (
				<Flex direction='column' gap='8px' data-testid={testId}>
					<TanStackTable table={table} />
					{/* <Pagination table={table} totalCount={totalCount ?? 0} /> */}
				</Flex>
			)}
			{isError && (
				<FetchError refetch={refetch} errorMessage={t('fetchError')} />
			)}
		</Skeleton>
	)
}

export default RecordingToolTable
