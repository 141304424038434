import { useRef } from 'react'
import { Box } from '@chakra-ui/react'
import { useSize } from '@chakra-ui/react-use-size'
import {
	Background,
	Features,
	GroundLine,
	Mask,
	Measurement,
	Radar,
	Terrain,
} from './Layers'

type AglMaskPreviewProps = {
	min: number
	max: number
	value: [number, number]
	isLargePreview: boolean
}

// TODO: handle installation, sensor altitude/height
// Storybook link: http://localhost:6006/?path=/story/components-aglmaskpreview--agl-mask-preview&args=min:-424;max:2500

const AglMaskPreview = ({
	min,
	max,
	value,
	isLargePreview,
}: AglMaskPreviewProps) => {
	const ref = useRef<HTMLDivElement>(null)
	const { width } = useSize(ref) ?? { width: 500 }
	const height = width / 1.777

	return (
		<Box ref={ref} className='agl-mask-preview' data-testid='agl-mask-preview'>
			<svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
				<Background isLargePreview={isLargePreview} />
				<Mask min={min} max={max} value={value} />
				<Terrain />
				{isLargePreview && <Measurement />}
				<GroundLine yPos='50%' />
				<Features />
				<Radar />
			</svg>
		</Box>
	)
}

export default AglMaskPreview
