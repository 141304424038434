// Packages
import { createContext } from 'react'

// Types
import type {
	DetectionContribution,
	DetectionContributionSensor,
	DetectionState,
	ThreatContribution,
} from '@/store/types'

export type NotificationContextDefault = {
	hasDroneLocator?: boolean
	targetId?: string
	threat?: number
	confidence?: number
	classification?: string
	contributions?: DetectionContribution[]
	seenContributor?: DetectionContributionSensor[]
	latitude?: number
	longitude?: number
	frequency?: number
	state?: DetectionState
	rssi?: number
	protocol?: string
	vendor?: string
	rcs?: number
	ssid?: string
	heading?: number | null
	speed?: number
	heightOffsetToSite?: number
	gcsLatitude?: number
	gcsLongitude?: number
	homeLatitude?: number
	threatContributions?: ThreatContribution | null
	homeLongitude?: number
	droneSerialNumber?: string
	displayFiltered?: boolean
	preventSave?: boolean
	timeOnTarget?: number
	siteDistance?: number
	zoneName?: string
	expiredAt?: number
	// Extras
	threatLevel?: 'high' | 'medium' | 'low'
}

const NotificationContext = createContext<NotificationContextDefault>({})

export default NotificationContext
