import { useEffect, useRef, useState } from 'react'
import ReactPlayer, { type ReactPlayerProps } from 'react-player'

import { baseUrl, isProduction } from '@Constants/api'
import { Box, Center, Spinner } from '@chakra-ui/react'

type VideoPlayerProps = {
	url: string | string[]
	seekTime?: number
	allowSpinner: boolean
} & ReactPlayerProps

const VideoPlayer = ({
	url,
	width = '100%',
	height = '100%',
	seekTime,
	allowSpinner,
	...props
}: VideoPlayerProps) => {
	const [spinner, setSpinner] = useState(true)
	const playerRef = useRef<ReactPlayer>(null)
	useEffect(() => {
		if (playerRef.current && seekTime !== undefined) {
			playerRef.current.seekTo(seekTime, 'seconds')
		}
	}, [seekTime])

	return (
		<>
			{allowSpinner && spinner && (
				<Box
					position='absolute'
					background='input_bg'
					width='360px'
					marginInline='20px'
					height='200px'
				>
					<Center h='100%'>
						<Spinner
							boxSize={150}
							thickness='5px'
							speed='0.6s'
							color='gray.600'
						/>
					</Center>
				</Box>
			)}
			<ReactPlayer
				ref={playerRef}
				url={`${isProduction ? '' : baseUrl}${url}`} // Ensures videos can be played locally
				width={width}
				height={height}
				{...props}
				onSeek={() => setSpinner(true)}
				onReady={() => setSpinner(false)}
			/>
		</>
	)
}

export default VideoPlayer
