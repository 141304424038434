import { STATUS_COLORS } from '@/constants/installations'
import type { Disruptor, RfSensor } from '@Store/types'

const activeDisruptorColors = [STATUS_COLORS.GREY, STATUS_COLORS.GREEN]

export const isDsxDisabled = (rfSensorDsx: RfSensor) => {
	// This is an edge case when the effector is jamming, however all the bands are turned off. Therefore, we should still allow the user to stop disruption
	if (rfSensorDsx.jamming) {
		return false
	}

	return (
		!activeDisruptorColors.includes(rfSensorDsx.status_color) ||
		!(
			!!rfSensorDsx.gnss_trigger_engaged ||
			!!rfSensorDsx.band_24_58_trigger_engaged ||
			// DSX Mk2
			!!rfSensorDsx.Cannon?.v2_bands_statuses?.gnss_enabled ||
			!!rfSensorDsx.Cannon?.v2_bands_statuses?.spectrum_enabled
		)
	)
}

export const isEveryDsxDisabled = (rfSensorDsx: RfSensor[]) => {
	// This is an edge case when the effector is jamming, however all the bands are turned off. Therefore, we should still allow the user to stop disruption
	if (rfSensorDsx.find((dsx) => dsx.jamming)) {
		return false
	}

	return !(
		!!rfSensorDsx.find((dsx) => !!dsx.gnss_trigger_engaged) ||
		!!rfSensorDsx.find((dsx) => !!dsx.band_24_58_trigger_engaged) ||
		// DSX Mk2
		!!rfSensorDsx.find(
			(dsx) => !!dsx.Cannon?.v2_bands_statuses?.gnss_enabled
		) ||
		!!rfSensorDsx.find(
			(dsx) => !!dsx.Cannon?.v2_bands_statuses?.spectrum_enabled
		)
	)
}

export const isDisruptorDisabled = (disruptor: Disruptor) => {
	// This is an edge case when the effector is jamming, however all the bands are turned off. Therefore, we should still allow the user to stop disruption
	if (disruptor.power_trigger_engaged) {
		return false
	}

	return (
		!activeDisruptorColors.includes(disruptor.status_color) ||
		!(
			!!disruptor.gnss_trigger_engaged ||
			!!disruptor.band_24_58_trigger_engaged ||
			!!disruptor.band_433_trigger_engaged ||
			!!disruptor.band_915_trigger_engaged ||
			!!disruptor.band_433_915_trigger_engaged
		)
	)
}

export const isEveryDisruptorDisabled = (disruptors: Disruptor[]) => {
	// This is an edge case when the effector is jamming, however all the bands are turned off. Therefore, we should still allow the user to stop disruption
	if (disruptors.find((d) => d.power_trigger_engaged)) {
		return false
	}

	return !(
		!!disruptors.find((d) => !!d.gnss_trigger_engaged) ||
		!!disruptors.find((d) => !!d.band_24_58_trigger_engaged) ||
		!!disruptors.find((d) => !!d.band_433_trigger_engaged) ||
		!!disruptors.find((d) => !!d.band_915_trigger_engaged) ||
		!!disruptors.find((d) => !!d.band_433_915_trigger_engaged)
	)
}
