import { useState, useCallback } from 'react'
import { baseUrl, isProduction } from '@Constants/api'
import type { RfSensor_ConnectionTypes } from '@Store/types'
import { DEFAULT_SMARTHUB_IP } from '@Constants/site'

export const useIpValidation = () => {
	const [isLoading, setIsLoading] = useState(false)

	const validateIps = useCallback(async (ips: string[]) => {
		setIsLoading(true)
		for (const ip of ips) {
			try {
				await fetch(ip, {
					mode: 'no-cors',
					signal: AbortSignal.timeout(3000),
				})
				return ip
			} catch (error) {
				!isProduction && console.error(`Invalid IP: ${ip}:`, error)
			} finally {
				setIsLoading(false)
			}
		}
		setIsLoading(false)
		return null
	}, [])

	return { validateIps, isLoading }
}

export const buildRfSensorIps = (
	connectionMode: undefined | RfSensor_ConnectionTypes,
	serialNumber: string,
	ip: undefined | string
) => {
	if (!connectionMode) {
		console.error(
			`Invalid ConnectionMode for sensor with serial ${serialNumber}`
		)
		return
	}
	if (connectionMode === 'v2_direct' && !ip) {
		console.error(
			`Invalid ConnectionMode/Sensor IP for sensor with serial ${serialNumber}`
		)
		return
	}

	if (connectionMode === 'v1' || connectionMode === 'v2_proxy_via_sh') {
		return [
			`${isProduction ? '' : baseUrl}/${ip && ip !== DEFAULT_SMARTHUB_IP ? ip : 'smarthub'}/sensor/${serialNumber}`,
		]
	}

	return [`http://${ip}`, `https://${ip}`]
}

export default useIpValidation
