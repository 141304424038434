import { useTheme } from '@chakra-ui/react'
import type { Camera } from '@Store/types'
import Sector from '@Components/MapShapes/Sector/Sector'

// Utility
import { convertDegreeToRad, convertRadToDegree } from '@Utils/mathUtils'

type CameraSectorProps = {
	latitude: number
	longitude: number
	bearing: number
	isActive: boolean
	pan: Camera['ptz_pan']
	zoom: Camera['ptz_zoom']
} & Pick<Camera, 'max_fov_angle' | 'min_fov_angle'>

// Functions taken from vue to calculate the camera sector angle
const calculateApparentFieldOfView = (zoom: number, AFOV: number) => {
	const HFOV = Math.tan(convertDegreeToRad(AFOV / 2))
	if (1 - zoom <= 0) {
		// Correction to avoid render a circle
		zoom = 0.99999
	}
	return convertRadToDegree(2 * Math.atan(HFOV)) * (1 - zoom)
}

// TODO: Add logic/functions/calculations for dragging, edit mode, active plan?
const CameraSector = ({
	latitude,
	longitude,
	bearing: sensorBearing = 0,
	max_fov_angle: maxFovAngle,
	min_fov_angle: minFovAngle,
	pan = 0,
	zoom = 0,
	isActive = false,
}: CameraSectorProps) => {
	const {
		semanticTokens: { colors },
	} = useTheme()

	const fillColor = colors.sectors.cameraFill
	const strokeColor = isActive
		? colors.sectors.highlight
		: colors.sectors.cameraStroke
	const strokeWeight = isActive ? 4 : 2

	const bearing = (sensorBearing + pan * 180) % 360
	const AFOV = calculateApparentFieldOfView(zoom, maxFovAngle)
	const reach = 700 + 500 * zoom

	return (
		<Sector
			latitude={latitude}
			longitude={longitude}
			reach={reach}
			bearing={bearing}
			angle={AFOV < 1 ? 1 : AFOV}
			color={strokeColor}
			fillColor={fillColor}
			weight={strokeWeight}
		/>
	)
}

export default CameraSector
