// Packages
import { useCallback, useEffect, useMemo } from 'react'
import { Box, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import noop from 'lodash/noop'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useNavigate } from 'react-router-dom'

// Relatives
import DetectionDisplay from './DetectionDisplay'
import Headings from '@/components/_UI/Headings/Headings'
import detectionDisplaySettingsFormSchema from './DetectionDisplaySettingsForm.schema'

// Types
import type { TypeOf } from 'zod'
import { FormButtons } from '@/components/FormElements/FormButtons/FormButtons'

export type DetectionDisplaySettingsFormProps = {
	className?: string
	siteId?: number
	detectionDisplay: {
		showControllerDetections?: boolean
		showWhitelistedDetections?: boolean
	}
	autoSave?: boolean
	onSubmit?: (values: any) => void
}

type Schema = TypeOf<typeof detectionDisplaySettingsFormSchema>

const DetectionDisplaySettingsForm = ({
	siteId,
	detectionDisplay = {
		showControllerDetections: false,
		showWhitelistedDetections: false,
	},
	autoSave = true,
	onSubmit = noop,
}: DetectionDisplaySettingsFormProps) => {
	const { t } = useTranslation('forms', {
		keyPrefix: 'detectionDisplaySettingsForm',
	})

	const navigate = useNavigate()
	const defaultValues = useMemo(
		() => ({ detectionDisplay }),
		[detectionDisplay]
	)
	const {
		control,
		handleSubmit,
		watch,
		formState: { isSubmitting, isDirty },
	} = useForm<Schema>({
		resolver: zodResolver(detectionDisplaySettingsFormSchema),
		defaultValues,
	})

	const handleCancel = useCallback(
		() => navigate('/' + siteId),
		[navigate, siteId]
	)

	useEffect(() => {
		if (autoSave) {
			const subscription = watch(
				handleSubmit(onSubmit) as (values: any) => void
			)

			return () => subscription.unsubscribe()
		}
	}, [handleSubmit, watch, onSubmit, autoSave])

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Controller
				control={control}
				name='detectionDisplay'
				rules={{ required: false }}
				render={({ field: { onChange, value } }) => (
					<Box>
						<Headings.FormHeading title={t('headings.detectionDisplay')} />
						<Text fontSize='sm' paddingTop='8px'>
							{t('detectionDisplayDescription')}
						</Text>
						<DetectionDisplay
							value={value}
							options={
								t('detectionDisplayOptions', {
									returnObjects: true,
								}) as { name: string; value: string }[]
							}
							onChange={onChange}
						/>
					</Box>
				)}
			/>
			{!autoSave && (
				<FormButtons
					isSubmitting={isSubmitting}
					isDirty={isDirty}
					handleCancel={handleCancel}
				/>
			)}
		</form>
	)
}

export default DetectionDisplaySettingsForm
