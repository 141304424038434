import { memo, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useTheme } from '@chakra-ui/react'
import { nanoid } from '@reduxjs/toolkit'
import L from 'leaflet'
import { max, min } from 'lodash'
import { useTranslation } from 'react-i18next'

import {
	useGetSiteQuery,
	useGetSiteExportQuery,
	selectSiteExportRadars,
	selectSiteExportInstallations,
	selectSiteExportDisruptors,
	selectSiteExportRfSensorsOnly,
	selectSiteExportDsx,
	selectSiteExportJammingDsx,
	selectSiteExportGenericSensors,
} from '@Store/sites/sitesApi'
import { useGetZonesQuery } from '@Store/zones/zonesApi'

import { selectUserZoneSettings } from '@Store/user/userSlice'
import {
	setZoneCoordinates,
	setIsZoneCoordinatesDirty,
	selectZoneType,
} from '@Store/ui/uiSlice'
import { useAppSelector, useAppDispatch } from '@Store/index'

import EditControl from '@Components/MapControls/EditControl/EditControl'
import Polygon from '@/components/MapShapes/Polygon/Polygon'
import SiteMarker from '@Components/Markers/SiteMarker/SiteMarker'
import SentryMarker from '@Components/Markers/SentryMarker/SentryMarker'

import useLeafletDrawToolTip from '../../../../../hooks/useLeafletDrawToolTip'
import useCenterMap from '../useCenterMap'
import { createNewDefaultZone } from '@Utils/zones'
import {
	DisruptorSector,
	DsxSector,
	GenericSensorSector,
	RadarSensorSector,
	RfSensorSector,
} from '@/components/Sectors'
import {
	createCirclePolygon,
	createSectorPolygon,
	validateCoordinatesWithinPolygonZone,
} from '@/utils/turfUtils'
import DsxDisruptSector from '@/components/Sectors/DsxSector/DsxDisruptSector'

const ZonePreview = () => {
	const dispatch = useAppDispatch()

	const {
		semanticTokens: { colors },
	} = useTheme()

	const {
		siteId: siteIdParam,
		zoneId: zoneIdParam,
		sensorId: sensorIdParam,
	} = useParams()
	const siteId = Number(siteIdParam)
	const zoneId = Number(zoneIdParam)
	const sensorId = Number(sensorIdParam)

	const zoneType = useAppSelector(selectZoneType)

	const { t } = useTranslation('pages', {
		keyPrefix: 'zonePreview',
	})

	const { resetToolTip, setTooltip } = useLeafletDrawToolTip()

	const { siteLatitude, siteLongitude } = useGetSiteQuery(siteId, {
		selectFromResult: ({ data }) => ({
			siteLatitude: data?.latitude ?? 0,
			siteLongitude: data?.longitude ?? 0,
		}),
		skip: !siteId,
	})

	const { data: zones } = useGetZonesQuery(
		{ siteId },
		{
			skip: !siteId,
		}
	)

	const {
		rfSensors,
		dsx,
		radars,
		installations,
		disruptors,
		dsxDisruptors,
		genericSensors,
	} = useGetSiteExportQuery(siteId, {
		skip: !siteId,
		selectFromResult: ({ data }) => {
			return {
				rfSensors: selectSiteExportRfSensorsOnly(data),
				dsx: selectSiteExportDsx(data),
				radars: selectSiteExportRadars(data),
				installations: selectSiteExportInstallations(data),
				disruptors: selectSiteExportDisruptors(data),
				dsxDisruptors: selectSiteExportJammingDsx(data),
				genericSensors: selectSiteExportGenericSensors(data),
			}
		},
	})

	const zoneSettings = useAppSelector(selectUserZoneSettings)

	useCenterMap({
		latitude: siteLatitude,
		longitude: siteLongitude,
		centerOnZoom: false,
	})

	const isEditZone = !!zoneId
	const editedZone = (zones ?? []).find((zone) => zone.id === zoneId)

	const defaultCoordinates = isEditZone
		? editedZone?.coordinates
		: createNewDefaultZone(siteLatitude, siteLongitude)

	useEffect(() => {
		dispatch(setZoneCoordinates(defaultCoordinates))
		return () => {
			dispatch(setZoneCoordinates([]))
			dispatch(setIsZoneCoordinatesDirty(false))
		}
	}, [dispatch, defaultCoordinates])

	const displayedZones = (zones ?? []).filter((zone) =>
		zoneSettings?.visibleZoneTypes.includes(zone.zone_type)
	)
	// Statically display zones that are not being added or edited
	const staticZones = isEditZone
		? displayedZones.filter((zone) => zone.id !== zoneId)
		: displayedZones

	const rfSensorsPolygons = useMemo(
		() =>
			rfSensors.map((rfSensor) => {
				return createSectorPolygon(
					[rfSensor.latitude, rfSensor.longitude],
					rfSensor.reach,
					rfSensor.direction - 45,
					rfSensor.direction + 45
				)
			}),
		[rfSensors]
	)

	const dsxPolygons = useMemo(
		() =>
			dsx.map((dsxSensor) =>
				createCirclePolygon(
					[dsxSensor.latitude, dsxSensor.longitude],
					dsxSensor.reach
				)
			),
		[dsx]
	)

	const radarsPolygons = useMemo(
		() =>
			radars.map((rfSensor) => {
				const minAz =
					min([rfSensor.radar_az_min_search, rfSensor.radar_az_min_track]) ||
					rfSensor.radar_az_min_track

				const maxAz =
					max([rfSensor.radar_az_max_search, rfSensor.radar_az_max_track]) ||
					rfSensor.radar_az_max_track

				return createSectorPolygon(
					[rfSensor.latitude, rfSensor.longitude],
					rfSensor.reach_max,
					rfSensor.direction + minAz,
					rfSensor.direction + maxAz
				)
			}),
		[radars]
	)

	const disruptorsPolygons = useMemo(
		() =>
			disruptors.map((sensor) =>
				createSectorPolygon(
					[sensor.latitude, sensor.longitude],
					sensor.reach,
					sensor.direction - 45,
					sensor.direction + 45
				)
			),
		[disruptors]
	)

	const dsxDisruptorsPolygons = useMemo(
		() =>
			dsx.map((dsxSensor) =>
				createCirclePolygon(
					[dsxSensor.latitude, dsxSensor.longitude],
					dsxSensor.reach_jamming
				)
			),
		[dsx]
	)

	const genericSensorsPolygons = useMemo(
		() =>
			genericSensors.map((sensor) => {
				if (
					sensor.direction === undefined ||
					sensor.sector_field_of_view === undefined ||
					sensor.sector_field_of_view === 360 ||
					sensor.sector_field_of_view === 0
				) {
					return createCirclePolygon(
						[sensor.latitude, sensor.longitude],
						sensor.max_range
					)
				}

				return createSectorPolygon(
					[sensor.latitude, sensor.longitude],
					sensor.max_range,
					sensor.direction - sensor.sector_field_of_view / 2,
					sensor.direction + sensor.sector_field_of_view / 2
				)
			}),
		[genericSensors]
	)

	const getCoordinatesPointsFromLayers = (layers: L.LayerGroup) => {
		const coordinates: number[][] = []
		layers.eachLayer((layer) => {
			if (layer && layer instanceof L.Marker) {
				const latLng = layer.getLatLng()
				coordinates.push([latLng.lat, latLng.lng])
			}
		})
		return coordinates
	}

	// Using 'any' here as type definitions for 'react-leaflet-draw' don't exist
	const onEditVertex = (e: any) => {
		// Get latlngs, only provides the active points, but not all the points
		const newCoordinates = e.poly._latlngs[0].map((point: L.LatLng) => [
			point.lat,
			point.lng,
		])

		const allCoordinatePoints = getCoordinatesPointsFromLayers(e.layers)

		const isWithinZone = validateCoordinatesWithinPolygonZone({
			polygons:
				zoneType === 'disrupt'
					? {
							dsxDisruptors: dsxDisruptorsPolygons,
							disruptors: disruptorsPolygons,
						}
					: {
							disruptors: disruptorsPolygons,
							dsx: dsxPolygons,
							radars: radarsPolygons,
							rfSensors: rfSensorsPolygons,
							genericSensors: genericSensorsPolygons,
						},
			coordinates: allCoordinatePoints,
		})

		const isPartiallyOutOfZone =
			isWithinZone.features.length < allCoordinatePoints.length
		const isFullyOutOfZone = isWithinZone.features.length === 0

		if (zoneType !== 'label') {
			const tooltipTitle = isFullyOutOfZone
				? t('tooltip.outOfRange')
				: isPartiallyOutOfZone
					? t('tooltip.partiallyOutOfRange')
					: null

			tooltipTitle ? setTooltip({ title: tooltipTitle }) : resetToolTip()
		}

		dispatch(setZoneCoordinates(newCoordinates))
		dispatch(setIsZoneCoordinatesDirty(true))
	}

	const onEditStart = () => {
		resetToolTip()
	}

	return (
		<>
			<SiteMarker position={[siteLatitude, siteLongitude]} />

			{installations?.map(
				({ id, name, latitude, longitude, sentry_type, status_color }) => {
					return (
						<div key={id}>
							<SentryMarker
								key={id}
								name={name}
								position={[latitude, longitude]}
								sentryType={sentry_type}
								iconColor={status_color}
							/>

							{zoneType !== 'disrupt' && (
								<>
									{radars.map((radar) => (
										<RadarSensorSector
											key={radar.id}
											latitude={latitude}
											longitude={longitude}
											bearing={radar.direction}
											reach_max={radar.reach_max}
											reach_min={radar.reach_min}
											radar_az_min_search={radar.radar_az_min_search}
											radar_az_max_search={radar.radar_az_max_search}
											radar_az_min_track={radar.radar_az_min_track}
											radar_az_max_track={radar.radar_az_max_track}
											isActive={sensorId === radar.id}
										/>
									))}

									{rfSensors.map((rfSensor) => (
										<RfSensorSector
											key={rfSensor.id}
											model={rfSensor.model}
											latitude={rfSensor.latitude}
											longitude={rfSensor.longitude}
											bearing={rfSensor.direction}
											reach={rfSensor.reach}
											isActive={sensorId === rfSensor.id}
										/>
									))}

									{dsx.map((sensor) => (
										<DsxSector
											key={sensor.id}
											latitude={sensor.latitude}
											longitude={sensor.longitude}
											reach={sensor.reach}
											isActive={sensorId === sensor.id}
										/>
									))}

									{disruptors.map((disruptor) => (
										<DisruptorSector
											key={disruptor.id}
											cannon_type={disruptor.cannon_type}
											latitude={disruptor.latitude}
											longitude={disruptor.longitude}
											bearing={disruptor.direction}
											reach={disruptor.reach}
											power_trigger_engaged={disruptor.power_trigger_engaged}
											isActive={sensorId === disruptor.id}
										/>
									))}

									{genericSensors.map((sensor) => (
										<GenericSensorSector
											key={sensor.id}
											latitude={sensor.latitude}
											longitude={sensor.longitude}
											bearing={sensor.direction}
											angle={sensor.sector_field_of_view}
											reach={sensor.max_range}
											isActive={sensorId === sensor.id}
										/>
									))}
								</>
							)}

							{zoneType === 'disrupt' && (
								<>
									{disruptors.map((disruptor) => (
										<DisruptorSector
											key={disruptor.id}
											cannon_type={disruptor.cannon_type}
											latitude={disruptor.latitude}
											longitude={disruptor.longitude}
											bearing={disruptor.direction}
											reach={disruptor.reach}
											power_trigger_engaged={disruptor.power_trigger_engaged}
											isActive={sensorId === disruptor.id}
										/>
									))}
									{dsxDisruptors.map((sensor) => (
										<DsxDisruptSector
											key={`${sensor.id}-disruptor`}
											latitude={sensor.latitude}
											longitude={sensor.longitude}
											reach_jamming={sensor.reach_jamming}
											jamming={sensor.jamming}
											isActive={sensorId === sensor.id}
										/>
									))}
								</>
							)}
						</div>
					)
				}
			)}

			{staticZones.map((zone) => (
				<Polygon
					key={zone.id}
					positions={zone.coordinates}
					fillColor='transparent'
					color={colors.zones[zone.zone_type]}
					dashArray={[14]}
					weight={2}
					testId={`zone-${zone.zone_type}`}
				/>
			))}
			<EditControl
				key={nanoid()}
				onEditVertex={onEditVertex}
				onEditStart={onEditStart}
			>
				<Polygon
					key={nanoid()}
					positions={defaultCoordinates || []}
					testId='zone-preview'
				/>
			</EditControl>
		</>
	)
}

export default memo(ZonePreview)
