import { IconButton, Icon } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'

import {
	useGetSiteLiveQuery,
	selectSiteCameraById,
} from '@Store/sites/sitesWsApi'

import {
	useStartRecordingMutation,
	useStopRecordingMutation,
} from '@Store/cameras/camerasApi'

const CameraRecordingButton = ({
	cameraId,
	isDisabled,
}: {
	cameraId: number
	isDisabled: boolean
}) => {
	const { siteId: siteIdParam } = useParams()
	const siteId = Number(siteIdParam)

	const { camera } = useGetSiteLiveQuery(Number(siteIdParam), {
		selectFromResult: ({ data }) => ({
			camera: selectSiteCameraById(data, cameraId),
		}),
	})

	const [startRecording] = useStartRecordingMutation()
	const [stopRecording] = useStopRecordingMutation()

	const handleStart = () => {
		startRecording({ siteId, cameraId })
	}

	const handleStop = () => {
		stopRecording({ siteId, cameraId })
	}

	const startIcon = (
		<Icon viewBox='0 0 20 20'>
			<circle cx='10' cy='10' r='10' fill='white' />
			<circle cx='10' cy='10' r='6.25' fill='currentColor' />
		</Icon>
	)

	const stopIcon = (
		<Icon viewBox='0 0 20 20'>
			<circle cx='10' cy='10' r='10' fill='white' />
			<rect x='5' y='5' width='10' height='10' fill='currentColor' />
		</Icon>
	)

	return camera ? (
		<>
			{camera.recording && (
				<IconButton
					icon={stopIcon}
					variant='unstyled'
					onClick={handleStop}
					aria-label='stop recording'
					color='camera.recording_red'
					sx={{
						svg: {
							transform: 'scale(1.3) translateY(-1px)',
						},
					}}
				/>
			)}
			{!camera.recording && (
				<IconButton
					icon={startIcon}
					variant='unstyled'
					onClick={handleStart}
					aria-label='start recording'
					color='camera.recording_red'
					sx={{
						svg: {
							transform: 'scale(1.3) translateY(-1px)',
						},
					}}
					isDisabled={isDisabled}
				/>
			)}
		</>
	) : null
}

export default CameraRecordingButton

//
