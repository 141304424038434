// React
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
// Chakra
import { Box, Flex } from '@chakra-ui/react'
// Components
import Field from '@Components/FormElements'
// Types
import type { DefaultRfFilterValuesType } from '@Forms/RfFilterForm/RfFiltersForms.schema'
import { useAppDispatch } from '@/store'
import { updateSensorPreview } from '@Store/ui/uiSlice'
import { useGetRfSensorQuery } from '@Store/rfSensors/rfSensorsApi'

const RfFilterFormBearing = ({
	bearing,
	bearing_tolerance,
	isFilterByBearing,
	defaultValues,
	register,
	setValue,
	errors,
}: {
	bearing: number
	bearing_tolerance: number
	isFilterByBearing: boolean
	defaultValues: DefaultRfFilterValuesType
	register: any
	setValue: any
	errors: any
}) => {
	// Translations
	const { t } = useTranslation('forms', { keyPrefix: 'rfFiltersForm' })
	const dispatch = useAppDispatch()
	// Handle Bearings
	const [isBearingValuesLoading, setBearingLoading] = useState(false)
	// RfSensor
	const { siteId: siteIdParam, sensorId: sensorIdParam } = useParams()
	const siteId = Number(siteIdParam)
	const sensorId = Number(sensorIdParam)
	const { isLoading, data: sensor } = useGetRfSensorQuery(
		{ siteId, sensorId },
		{ skip: !siteId || !sensorId }
	)
	const isDsx = sensor?.model?.includes('dsx')

	const formatBearing = (value: number) => Number(value.toFixed(2))

	const handleBearingRangeChange = (value: [number, number]) => {
		setValue('bearing_tolerance', formatBearing((value[1] - value[0]) / 2), {
			shouldDirty: true,
			shouldValidate: true,
			shouldTouch: true,
		})
		setValue('bearing', formatBearing((value[1] + value[0]) / 2), {
			shouldDirty: true,
			shouldValidate: true,
			shouldTouch: true,
		})
	}

	// Set default values to bearing on open
	useEffect(() => {
		if (isFilterByBearing) {
			if (!defaultValues.bearing && !defaultValues.bearing_tolerance) {
				setBearingLoading(true)
				setValue('bearing', 0)
				setValue('bearing_tolerance', 10)
			}
		}
	}, [isFilterByBearing, defaultValues, setValue])

	useEffect(() => {
		const defaultData = {
			sentryId: sensor?.sentry_id,
			sensorType: 'rfFilters',
			reach: sensor?.reach,
			directionOffset: sensor?.direction_offset,
			sensorId: sensor?.id,
			model: sensor?.model,
		}
		setTimeout(() => {
			dispatch(
				updateSensorPreview({
					...defaultData,
					angle: bearing,
					angle_tolerance: bearing_tolerance,
				})
			)
		}, 1)

		return () => {
			dispatch(updateSensorPreview({ ...defaultData }))
		}
	}, [sensor, dispatch, bearing, bearing_tolerance])

	const bearingDefaultValues = useMemo(() => {
		return [
			formatBearing(bearing - bearing_tolerance),
			formatBearing(bearing + bearing_tolerance),
		]
	}, [bearing, bearing_tolerance])

	// Remount slider
	useEffect(() => {
		if (isBearingValuesLoading) {
			setBearingLoading(false)
		}
	}, [isBearingValuesLoading])

	if (isLoading) return null

	return (
		<Box data-testid='filter-bearing'>
			{isDsx && (
				<Flex gap={4} mb={4}>
					<Field.NumberInput
						title={`${t('bearing')} (°)`}
						register={register('bearing', {
							valueAsNumber: true,
						})}
						max={359}
						error={errors?.bearing?.message}
					/>
					<Field.NumberInput
						title={`${t('bearingTolerance')} (°)`}
						register={register('bearing_tolerance', {
							valueAsNumber: true,
						})}
						variant='approximated'
						min={1}
						max={180}
						error={errors?.bearing_tolerance?.message}
					/>
				</Flex>
			)}
			{!isDsx && (
				<>
					<Flex gap={4} mb={4}>
						<Field.NumberInput
							title={`${t('bearing')} (°)`}
							register={register('bearing', {
								valueAsNumber: true,
							})}
							isDisabled
						/>
						<Field.NumberInput
							title={`${t('bearingTolerance')} (°)`}
							register={register('bearing_tolerance', {
								valueAsNumber: true,
							})}
							isDisabled
							variant='approximated'
						/>
					</Flex>
					<Box minH='60px' data-testid='filter-bearing-range'>
						{!isBearingValuesLoading && (
							<Field.RangeFilterSlider
								defaultValue={bearingDefaultValues}
								step={1}
								min={-70}
								max={70}
								onChangeEnd={(value: [number, number]) =>
									handleBearingRangeChange(value)
								}
								error={[
									errors?.bearing?.message,
									errors?.bearing_tolerance?.message,
								]
									.filter((error) => !!error)
									.join(', ')}
							/>
						)}
					</Box>
				</>
			)}
		</Box>
	)
}

export default RfFilterFormBearing
