import { useTranslation } from 'react-i18next'
import {
	Grid,
	GridItem,
	AccordionItem,
	AccordionButton,
	AccordionIcon,
	AccordionPanel,
	Flex,
	Box,
	Text,
	Divider,
	useTheme,
} from '@chakra-ui/react'
import Field from '@Components/FormElements'

import { useFormContext, useFormState } from 'react-hook-form'
import type { ThreatClassifierContribution } from '../utils/ThreatAI'
import { useCallback, useMemo } from 'react'
import type {
	ClassifyByLegalLimitsError,
	ThreatConfigClickElement,
} from '../utils/types'
import Tooltip from '@UI/Tooltip/Tooltip'
import type { FormSelectValueType } from '@/components/FormElements/Select/Select'

type LegalLimitsFormProps = {
	classifier: ThreatClassifierContribution
	divider: boolean
	onClickAccordion: (e: ThreatConfigClickElement) => void
}

const DroneLegalLimitsForm = ({
	classifier,
	divider,
	onClickAccordion,
}: LegalLimitsFormProps) => {
	const { register, getValues, setValue, watch } = useFormContext()
	const { errors } = useFormState()
	const { t } = useTranslation('panels', {
		keyPrefix: 'threatAnalysis',
	})
	const {
		semanticTokens: { colors },
	} = useTheme()

	const [byLegalLimitsActive, ...rest] = watch([
		'classify_by_legal_limits.active',
		'classify_by_class.active',
		'classify_by_communication.active',
		'classify_by_geo_location.active',
		'classify_by_time.active',
		'classify_by_protected_zones.active',
		'classify_by_zone_origin.active',
	])
	const isDisabled = byLegalLimitsActive && rest.every((c) => !c)

	const llSpeedCallback = useCallback(
		(value: number[]) => {
			setValue('classify_by_legal_limits.speed_min', value[0], {
				shouldDirty: true,
			})
			setValue('classify_by_legal_limits.speed_max', value[1], {
				shouldDirty: true,
			})
		},
		[setValue]
	)

	const llAglCallback = useCallback(
		(value: number[]) => {
			setValue('classify_by_legal_limits.agl_min', value[0], {
				shouldDirty: true,
			})
			setValue('classify_by_legal_limits.agl_max', value[1], {
				shouldDirty: true,
			})
		},
		[setValue]
	)

	const llSpeedDefaultValues = useMemo(() => {
		return [
			getValues('classify_by_legal_limits.speed_min') ?? 0,
			getValues('classify_by_legal_limits.speed_max') ?? 150,
		]
	}, [getValues])

	const llAglDefaultValues = useMemo(() => {
		return [
			getValues('classify_by_legal_limits.agl_min') ?? 0,
			getValues('classify_by_legal_limits.agl_max') ?? 150,
		]
	}, [getValues])

	return (
		<AccordionItem>
			<AccordionButton
				_hover={{ bg: 'transparent' }}
				bg={colors.body_bg}
				paddingBlock={4}
				marginTop={0}
				onClick={(e) => onClickAccordion(e)}
			>
				<Flex flexDirection='row' flex='1'>
					<Tooltip
						label={isDisabled ? t('tooltips.parameterDisabled') : undefined}
					>
						<Box
							height='100%'
							sx={{ '& > div > div': { marginTop: 0 } }}
							// Rewrite margin for switch
						>
							<Field.Switch
								id='legal-limits'
								register={register('classify_by_legal_limits.active')}
								disabled={isDisabled}
							/>
						</Box>
					</Tooltip>
					<Flex flexGrow='0' flexDir='column' alignItems='flex-start' w={80}>
						<Box
							textAlign='start'
							textTransform='none'
							fontSize='sm'
							fontWeight='normal'
							color='white'
						>
							<Text>{t('headings.legalLimits')}</Text>
						</Box>
						<Box>
							<Text
								fontSize='xs'
								fontWeight='normal'
								textTransform='none'
								textAlign='left'
								lineHeight='125%'
							>
								{t('tooltips.legalLimits')}
							</Text>
						</Box>
					</Flex>
					<Flex
						flex='1 0 75px'
						padding={0}
						h='100%'
						justifyContent='space-between'
					>
						<Text fontSize='sm' fontWeight='normal' textAlign='center' w={12}>
							{classifier.ContributionThreatLevel
								? Math.round(classifier.ContributionThreatLevel * 100)
								: 0}
							%
						</Text>
						<AccordionIcon color={colors.button_text} />
					</Flex>
				</Flex>
			</AccordionButton>
			<AccordionPanel bg={colors.body_bg}>
				<Grid
					templateColumns='repeat(8, 11fr)'
					bg={colors.card_background}
					rowGap={0}
					paddingBottom={4}
				>
					<GridItem rowSpan={3} colSpan={1} />
					<GridItem rowSpan={1} colSpan={6}>
						<Field.RangeSlider
							title='Speed Legal Limit Range (m/s)'
							min={0}
							max={200}
							testId='classify-by-legal-limits-speed'
							defaultValue={llSpeedDefaultValues}
							onChangeEnd={llSpeedCallback}
							disabled={!byLegalLimitsActive}
						/>
					</GridItem>
					<GridItem rowSpan={1} colSpan={6}>
						<Field.RangeSlider
							title='AGL Limit Range (m)'
							min={0}
							max={200}
							testId='classify-by-legal-limits-agl-limit'
							defaultValue={llAglDefaultValues}
							onChangeEnd={llAglCallback}
							disabled={!byLegalLimitsActive}
						/>
					</GridItem>
					<GridItem rowSpan={1} colSpan={6}>
						<Field.Select
							title='Priority'
							options={
								t('forms.priorityOptions', {
									returnObjects: true,
								}) as FormSelectValueType[]
							}
							testId='classify-by-legal-limits-priority'
							value={getValues('classify_by_legal_limits.priority')}
							register={register('classify_by_legal_limits.priority')}
							error={
								(errors as ClassifyByLegalLimitsError)?.classify_by_legal_limits
									?.priority?.message
							}
							disabled={!byLegalLimitsActive}
						/>
					</GridItem>
				</Grid>
			</AccordionPanel>
			{divider ? <Divider borderColor={colors.border_color} /> : null}
		</AccordionItem>
	)
}

export default DroneLegalLimitsForm
