// React
import { useState, useRef } from 'react'
// Chakra
import {
	Box,
	Button,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Icon,
	Input,
	Tag,
	TagLabel,
	TagCloseButton,
} from '@chakra-ui/react'
import { MdUpload } from 'react-icons/md'
// Components
import Tooltip from '@UI/Tooltip/Tooltip'
// Types
import type { RegisterType } from '@Components/FormElements/types/RegisterType'
import type { InputProps } from '@chakra-ui/react'
import type { FormElementProps } from '@Components/FormElements/types/FormElementProps'
import type { UseFormSetValue } from 'react-hook-form'

type FileInputProps = FormElementProps & {
	accept?: string
	uploadButtonTitle?: string
	isRequired?: boolean
	fieldName: string
	setValue: UseFormSetValue<any>
} & RegisterType<string> &
	InputProps

// TODO: Change uploadButtonTitle to required for translations
const FileInput = ({
	id,
	title,
	register,
	error,
	disabled = undefined,
	accept,
	uploadButtonTitle = 'Upload',
	tooltip,
	testId,
	fieldName,
	setValue,
	isRequired,
}: FileInputProps) => {
	const htmlId = id ?? register?.name ?? 'fileInput'
	const fileInputRef = useRef<HTMLInputElement | null>(null)

	const { ref, ...rest } = register as {
		ref: (instance: HTMLInputElement | null) => void
	}

	const [fileName, setFileName] = useState('')

	const handleUploadFile = () => fileInputRef.current?.click()

	const handleFileData = (files: FileList | null) => {
		if (files) {
			const file = files[0]
			setFileName(file.name)
			setValue(fieldName, file, { shouldValidate: true })
		}
	}

	const handleClickCloseButton = () => {
		setFileName('')
		setValue(fieldName, undefined, { shouldValidate: true })
	}

	return (
		<Tooltip label={tooltip} type='info'>
			<FormControl isInvalid={!!error} isDisabled={disabled}>
				<FormLabel
					htmlFor={htmlId}
					_after={{
						content: isRequired ? '"*"' : undefined,
					}}
				>
					{title}
				</FormLabel>
				<Flex alignItems='center' gap={4} p={1}>
					<Box>
						<Input
							type='file'
							accept={accept}
							style={{ display: 'none' }}
							{...rest}
							{...(register ?? {})}
							ref={(e) => {
								ref(e)
								fileInputRef.current = e
							}}
							data-testid={testId}
							onChange={(e) => handleFileData(e.target.files)}
						/>
						<Button
							size='sm'
							leftIcon={<Icon as={MdUpload} />}
							onClick={handleUploadFile}
						>
							{uploadButtonTitle}
						</Button>
					</Box>
					{fileName && (
						<Tag size='sm'>
							<TagLabel>{fileName}</TagLabel>
							<TagCloseButton onClick={handleClickCloseButton} />
						</Tag>
					)}
				</Flex>
				<FormErrorMessage>{error}</FormErrorMessage>
			</FormControl>
		</Tooltip>
	)
}

export default FileInput
