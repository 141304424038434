import { Icon } from '@chakra-ui/react'

const ThreatAnalysisIcon = () => {
	return (
		<Icon aria-label='Threat Analysis' boxSize={6}>
			<svg
				width='24'
				height='22'
				viewBox='0 0 24 22'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M0 17H11.3414C11.1203 16.3744 11 15.7013 11 15C11 12.3098 12.7705 10.0331 15.2099 9.27159L9.8421 0L0 17ZM8.94737 14.3158V12.5263H10.7368V14.3158H8.94737ZM8.94737 10.7368V7.15789H10.7368V10.7368H8.94737ZM20.0343 17.5472H20.5763L24 20.9777L22.9777 22L19.5472 18.5763V18.0343L19.3619 17.8422C18.5798 18.5146 17.5643 18.9194 16.4597 18.9194C15.5776 18.9194 14.7154 18.6578 13.982 18.1678C13.2486 17.6778 12.677 16.9812 12.3395 16.1663C12.0019 15.3514 11.9136 14.4547 12.0857 13.5897C12.2578 12.7246 12.6825 11.9299 13.3062 11.3062C13.9299 10.6825 14.7246 10.2578 15.5897 10.0857C16.4547 9.91362 17.3514 10.0019 18.1663 10.3395C18.9812 10.677 19.6778 11.2486 20.1678 11.982C20.6578 12.7154 20.9194 13.5776 20.9194 14.4597C20.9201 15.525 20.5378 16.5551 19.8422 17.3619L20.0343 17.5472ZM13.3722 14.4597C13.3722 16.1681 14.7513 17.5472 16.4597 17.5472C18.1681 17.5472 19.5472 16.1681 19.5472 14.4597C19.5472 12.7513 18.1681 11.3722 16.4597 11.3722C14.7513 11.3722 13.3722 12.7513 13.3722 14.4597Z'
					fill='currentColor'
				/>
			</svg>
		</Icon>
	)
}

export default ThreatAnalysisIcon
