import { useParams } from 'react-router-dom'
import { useTheme } from '@chakra-ui/react'

// Redux
import { useAppSelector } from '@Store/index'
import { useGetSiteInstallationQuery } from '@Store/sites/sitesApi'
import { useGetRadarsQuery } from '@Store/radars/radarsApi'
import { useGetCameraQuery } from '@Store/cameras/camerasApi'
import { selectCameraCalibrationStep } from '@Store/ui/uiSlice'
import {
	selectCurrentCamera,
	useGetSiteLiveQuery,
} from '@/store/sites/sitesWsApi'

import SentryMarker from '@Components/Markers/SentryMarker/SentryMarker'
import CameraCalibrationDetections from './CameraCalibrationDetections'
import useCenterMap from '../useCenterMap'

import Donut from '@Components/MapShapes/Donut/Donut'
import { CameraSector, RadarSensorSector } from '@Components/Sectors'

const CameraCalibrationPreview = () => {
	const {
		siteId: siteIdParam,
		installationId: installationIdParam,
		sensorId: cameraIdParam,
	} = useParams()
	const siteId = Number(siteIdParam)
	const installationId = Number(installationIdParam)
	const cameraId = Number(cameraIdParam)

	const {
		semanticTokens: { colors },
	} = useTheme()

	const activeStep = useAppSelector(selectCameraCalibrationStep)

	// Installation details
	const { isSuccess, installation } = useGetSiteInstallationQuery(
		{ siteId, installationId: installationId as number },
		{
			skip: !installationId || !siteId,
			selectFromResult: ({ data, isSuccess }) => ({
				isSuccess,
				installation: data,
			}),
		}
	)

	// TODO: Selected radar comes from device config step?
	const { data: radars } = useGetRadarsQuery({ siteId })
	const { data: camera } = useGetCameraQuery({ siteId, cameraId })

	const radar = radars?.find((radar) => radar.status_color === 'green')

	const { cameraPan, cameraDirection } = useGetSiteLiveQuery(siteId, {
		selectFromResult: ({ data }) => ({
			cameraPan: selectCurrentCamera(data).ptz_pan,
			cameraDirection: selectCurrentCamera(data).direction,
		}),
	})

	useCenterMap({
		latitude: installation?.latitude,
		longitude: installation?.longitude,
		zoomLevel: 18,
	})

	if (isSuccess && installation && radar) {
		const bearing = (installation.direction + radar.direction_offset) % 360
		return (
			<>
				<CameraCalibrationDetections siteId={siteId} />
				<RadarSensorSector
					latitude={installation.latitude}
					longitude={installation.longitude}
					bearing={bearing}
					reach_max={radar.reach_max}
					reach_min={radar.reach_min}
					radar_az_min_search={radar.radar_az_min_search}
					radar_az_max_search={radar.radar_az_max_search}
					radar_az_min_track={radar.radar_az_min_track}
					radar_az_max_track={radar.radar_az_max_track}
					isActive={false}
					isDashed
				/>
				{camera && (
					<CameraSector
						key={camera.id}
						latitude={camera.latitude}
						longitude={camera.longitude}
						bearing={cameraDirection}
						max_fov_angle={camera.max_fov_angle}
						min_fov_angle={camera.min_fov_angle}
						pan={cameraPan}
						zoom={camera.ptz_zoom}
						isActive={false}
					/>
				)}
				{activeStep > 0 && (
					<Donut
						latitude={installation.latitude}
						longitude={installation.longitude}
						startAngle={bearing + radar.radar_az_min_track}
						stopAngle={bearing + radar.radar_az_max_track}
						innerRadius={140}
						outerRadius={160}
						fillColor={
							activeStep > 1 ? colors.sectors.calibration : colors.primary
						}
						strokeWeight={0}
					/>
				)}
				<SentryMarker
					name={installation.name}
					position={[installation.latitude, installation.longitude]}
					sentryType={installation.sentry_type}
					iconColor={installation.status_color}
				/>
			</>
		)
	} else return null
}

export default CameraCalibrationPreview
