// React
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { useCallback } from 'react'
// Chakra
import Field from '@Components/FormElements'
// Store
import { useAppDispatch, useAppSelector } from '@/store'
import {
	selectIsCustomMapCenter,
	selectSiteCustomMapCenter,
	setSiteCustomMapCenter,
} from '@Store/ui/uiSlice'
import type { TypeOf } from 'zod'
import type siteFormSchema from '../SiteForm.schema'

const SiteFormMapCenterCoordinates = () => {
	type Schema = TypeOf<typeof siteFormSchema>

	const {
		watch,
		setValue,
		formState: { errors },
	} = useFormContext<Schema>()
	const { t } = useTranslation('forms', { keyPrefix: 'siteForm' })

	const latitude = watch('map_center_latitude')
	const longitude = watch('map_center_longitude')

	const dispatch = useAppDispatch()
	const isCustomMapCenter = useAppSelector(selectIsCustomMapCenter)
	const siteCustomMapCenter = useAppSelector(selectSiteCustomMapCenter)

	const handleOnFocus = () => {
		dispatch(
			setSiteCustomMapCenter({
				...siteCustomMapCenter,
				isChangedFromMap: false,
			})
		)
	}

	const handleOnBlur = () => {
		dispatch(
			setSiteCustomMapCenter({
				...siteCustomMapCenter,
				isChangedFromMap: true,
			})
		)
	}

	const handleOnChange = useCallback(
		(field: 'latitude' | 'longitude') => (value: number) => {
			const fieldName =
				field === 'latitude' ? 'map_center_latitude' : 'map_center_longitude'
			setValue(fieldName, value, { shouldDirty: true })
			dispatch(
				setSiteCustomMapCenter({
					...siteCustomMapCenter,
					[field]: value,
				})
			)
		},
		[setValue, dispatch, siteCustomMapCenter]
	)

	if (!isCustomMapCenter) return null

	return (
		<>
			<Field.LatLongInput
				type='latitude'
				title={t('customMapCenterLatitude')}
				value={latitude}
				onChange={handleOnChange('latitude')}
				error={errors?.map_center_latitude?.message as string}
				testId='map-center-latitude'
				onFocus={handleOnFocus}
				onBlur={handleOnBlur}
			/>
			<Field.LatLongInput
				type='longitude'
				title={t('customMapCenterLongitude')}
				value={longitude}
				onChange={handleOnChange('longitude')}
				error={errors?.map_center_longitude?.message as string}
				testId='map-center-longitude'
				onFocus={handleOnFocus}
				onBlur={handleOnBlur}
			/>
		</>
	)
}

export default SiteFormMapCenterCoordinates
