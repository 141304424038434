// Turf
import {
	circle,
	combine,
	featureCollection,
	lineArc,
	point,
	destination,
	intersect,
	multiPolygon,
	pointsWithinPolygon,
	points,
} from '@turf/turf'
import { polygon } from '@turf/helpers'
import type { Units } from '@turf/turf'
import type { FeatureCollection, Feature, Polygon } from 'geojson'

import L, { GeoJSON } from 'leaflet'
import type { LatLngExpression, LatLngTuple } from 'leaflet'

// Leaflet uses [lat, lng]
// TurfJS follows GeoJSON which uses [lng, lat]
// hence the need to reverse center[1], center[0] etc below.
// see https://github.com/Turfjs/turf/issues/182
//
// Since TurfJS is the only library we use that needs LngLat,
// these util functions accept LatLng as input and return LatLng
// as output, handling the required inversions internally
//
// Some useful utility is provided by Leafet:
//   GeoJSON.latLngsToCoords
//   GeoJSON.coordsToLatLngs

export const createSectorPolygon = (
	center: LatLngTuple,
	reach: number,
	startAngle: number,
	stopAngle: number
) => {
	const arc = lineArc([center[1], center[0]], reach, startAngle, stopAngle, {
		units: 'meters',
		steps: 100,
	}).geometry.coordinates.map((coord) => [coord[1], coord[0]])
	// Convert the arc into a closed sector polygon
	arc.unshift([center[0], center[1]])
	arc.push([center[0], center[1]])
	return arc
}

export const createCirclePolygon = (center: LatLngTuple, reach: number) => {
	const circ = circle([center[1], center[0]], reach, {
		units: 'meters',
		steps: 100,
	})
	return circ.geometry.coordinates[0].map((coord) => [coord[1], coord[0]])
}

export const createIntersectionPolygons = (polygons: LatLngTuple[][]) => {
	// Create a list of array indexes for all possible polygon pair combinations
	// https://stackoverflow.com/a/57834210/10091560
	const polygonIndexes = Array.from(Array(polygons.length).keys())
	const indexPairs = polygonIndexes
		.map((v, i) => polygonIndexes.slice(i + 1).map((w) => [v, w]))
		.flat()

	// Collect intersections between all polygon pair combinations
	// https://github.com/Turfjs/turf/tree/master/packages/turf-intersect
	const allIntersections = featureCollection(
		indexPairs
			.map(
				(pair) =>
					intersect(
						featureCollection([
							polygon([GeoJSON.latLngsToCoords(polygons[pair[0]])]),
							polygon([GeoJSON.latLngsToCoords(polygons[pair[1]])]),
						])
					) ?? (undefined as unknown as Feature)
			)
			.filter((feature) => feature) // strip undefined
	)

	// Combine intersections
	if (allIntersections.features.length > 0) {
		const i = combine(allIntersections as FeatureCollection<Polygon>)
			.features[0].geometry.coordinates
		return GeoJSON.coordsToLatLngs(i, 2) as LatLngTuple[][]
	}
	// No intersections found
	else {
		return null
	}
}

export const getCoordinatesFromSector = ({
	latitude,
	longitude,
	direction,
	azimuth_min,
	azimuth_max,
	range_min,
	range_max,
}: {
	latitude: number
	longitude: number
	direction: number
	azimuth_min: number
	azimuth_max: number
	range_min: number
	range_max: number
}) => {
	const center: LatLngExpression = [latitude, longitude]
	const startAngle = direction + azimuth_min
	const stopAngle = direction + azimuth_max

	const sector = new (L as any).sector({
		center,
		innerRadius: range_min,
		outerRadius: range_max,
		startBearing: startAngle,
		endBearing: stopAngle,
		numberOfPoints: 4,
	})

	return (
		sector._latlngs[0]?.map((point: L.LatLng) => [point.lat, point.lng]) || []
	)
}

export const createCenteredSquare = (
	latitude: number,
	longitude: number,
	sideLengthMetres: number
) => {
	const center = point([longitude, latitude])
	const options: { units: Units } = { units: 'kilometers' }
	const sideLengthKm: number = sideLengthMetres / 1000 // Convert to km and halve

	// Calculate the four corners of the square
	const west = destination(center, sideLengthKm / 2, -90, options)
	const northwest = destination(west, sideLengthKm / 2, 0, options)
	const southwest = destination(west, sideLengthKm / 2, 180, options)
	const east = destination(center, sideLengthKm / 2, 90, options)
	const northeast = destination(east, sideLengthKm / 2, 0, options)
	const southeast = destination(east, sideLengthKm / 2, 180, options)

	// Create the square polygon
	const squareCoords = [
		northwest.geometry.coordinates,
		northeast.geometry.coordinates,
		southeast.geometry.coordinates,
		southwest.geometry.coordinates,
		northwest.geometry.coordinates, // Close the polygon
	]

	// Convert [long, lat] to [lat, long] for leaflet
	return (squareCoords ?? []).map((point) => [point[1], point[0]]) as [
		latitude: number,
		longitude: number,
	][]
}

export const validateCoordinatesWithinPolygonZone = ({
	polygons,
	coordinates,
}: {
	polygons: {
		[key: string]: number[][][]
	}
	coordinates: number[][]
}) => {
	const multiPolygons = multiPolygon(
		Object.values(polygons).flatMap((polygonSet) =>
			polygonSet.map((polygon) => [polygon])
		)
	)

	return pointsWithinPolygon(points(coordinates), multiPolygons)
}
