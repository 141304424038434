import { z } from 'zod'

import i18n from '@/i18n'
import globalSchema from '@Components/Forms/BaseForm/BaseForm.schema'

const t = (key: string) => i18n.t(key, { ns: 'forms' })

const dsxFormFields = z.object({
	name: globalSchema.shape.name,
	direction_offset: globalSchema.shape.direction_offset,
	sentry_id: z.number(),
	model: z.enum([
		'dsx_direct',
		'dsx_direct_no_jam',
		'dsx_mk2_no_jam',
		'dsx_mk2',
	]),
	reach: z.number().min(1000).max(8000),
	reach_jamming: z.number().optional(),
	serial_number: z.string().length(13),
	show_720_detection: z.boolean(),
	show_sectors: z.boolean(),
	show_sector_as_line: z.boolean(),
	band_24_58_trigger_engaged: z.boolean(),
	gnss_trigger_engaged: z.boolean(),
	ip: z.string().optional(),
	port: z
		.string()
		.min(4, t('dsxForm.validation.portMin'))
		.max(5, t('dsxForm.validation.portMax'))
		.optional(),
	connection_type: z.enum(['v1', 'v2_direct', 'v2_proxy_via_sh']).optional(),
	active_profile_id: z.string().optional(),
	shutoff_time: z.number().optional(),
	height_offset_to_installation:
		globalSchema.shape.height_offset_to_installation,
	tilt: z.number().min(-45).max(45),
	roll: z.number().min(-45).max(45),
	north_offset_to_installation: z.number().min(-10).max(10),
	east_offset_to_installation: z.number().min(-10).max(10),
})

export const dsxFormSchema = ({ isEditForm }: { isEditForm: boolean }) =>
	dsxFormFields.superRefine((data, ctx) => {
		if (!data?.model.includes('no_jam')) {
			if (
				!data.reach_jamming ||
				data.reach_jamming < 250 ||
				data.reach_jamming > 750
			) {
				ctx.addIssue({
					path: ['reach_jamming'],
					message: t('dsxForm.validation.disruptionReach'),
					code: z.ZodIssueCode.custom,
				})
			}

			if (
				isEditForm &&
				(!data.shutoff_time ||
					data.shutoff_time < 30 ||
					data.shutoff_time > 600)
			) {
				ctx.addIssue({
					path: ['shutoff_time'],
					message: t('dsxForm.validation.shutOffTime'),
					code: z.ZodIssueCode.custom,
				})
			}
		}

		if (data?.connection_type === 'v2_direct') {
			if (data.ip === '') {
				ctx.addIssue({
					path: ['ip'],
					message: t('dsxForm.validation.ip'),
					code: z.ZodIssueCode.custom,
				})
			}
		}

		if (
			data?.connection_type === 'v2_proxy_via_sh' &&
			(data.ip === '' || data.ip === undefined)
		)
			return true

		const ipValidation = z.string().ip({ version: 'v4' })
		if (
			data?.model.includes('dsx_mk2') &&
			data.ip !== '' &&
			data.ip !== undefined &&
			!ipValidation.safeParse(data.ip).success
		) {
			ctx.addIssue({
				path: ['ip'],
				message: t('dsxForm.validation.ip'),
				code: z.ZodIssueCode.custom,
			})
		}
	})

export type DsxFormSchema = z.infer<ReturnType<typeof dsxFormSchema>>

const defaultValuesSchema = dsxFormFields.omit({
	sentry_id: true,
	active_profile_id: true,
})

export type DefaultValues = z.infer<typeof defaultValuesSchema>

export const ADD_DSX_DEFAULT_VALUES: DefaultValues = {
	name: '',
	serial_number: '',
	direction_offset: 0,
	model: 'dsx_direct',
	reach: 3000,
	reach_jamming: 500,
	show_720_detection: true,
	gnss_trigger_engaged: false,
	show_sectors: true,
	show_sector_as_line: true,
	band_24_58_trigger_engaged: false,
	ip: '',
	port: '',
	connection_type: 'v2_direct',
	shutoff_time: 300,
	height_offset_to_installation: 0,
	tilt: 0,
	roll: 0,
	north_offset_to_installation: 0,
	east_offset_to_installation: 0,
}
