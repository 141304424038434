import { z } from 'zod'
// Validations
import isEmail from 'validator/lib/isEmail'
import {
	isValidPhoneNumber,
	isSupportedCountry,
} from 'react-phone-number-input'
// Translations
import i18n from '@/i18n'
const t = (key: string) => i18n.t(`forms:global.validation.${key}`)
// Zod docs: https://zod.dev/?id=primitives

const addCtxIssue = (ctx: z.RefinementCtx, message: string) =>
	ctx.addIssue({
		code: z.ZodIssueCode.custom,
		message,
	})

const globalSchema = z.object({
	name: z.string().min(2, t('nameIsTooShort')).max(256, t('nameIsTooLong')),
	direction_offset: z.number().min(0).lt(360),
	latitude: z.preprocess(
		(x) => (x ? x : undefined),
		z.coerce
			.number({
				message: t('latitude'),
			})
			.min(-90, {
				message: t('latitude'),
			})
			.max(90, {
				message: t('latitude'),
			})
	),
	longitude: z.preprocess(
		(x) => (x ? x : undefined),
		z.coerce
			.number({
				message: t('longitude'),
			})
			.min(-180, {
				message: t('longitude'),
			})
			.max(180, {
				message: t('longitude'),
			})
	),
	height_offset_to_installation: z.number().min(-100).max(200),
	zoom_level: z.number().min(1).max(18),
	timezone: z.string().min(3, t('timezone')),
	direction: z.number().min(0).lt(360, t('direction')),
	email: z.string().refine(isEmail, t('email')),
	phone: z
		.string()
		.optional()
		.superRefine((phone, ctx) => {
			if (phone && !isValidPhoneNumber(phone)) addCtxIssue(ctx, t('phone'))
		}),
	countryCode: z
		.string()
		.optional()
		.superRefine((countryCode, ctx) => {
			if (countryCode && !isSupportedCountry(countryCode))
				addCtxIssue(ctx, t('countryCode'))
		}),
})

export const passwordSchema = z.object({
	password: z.string().superRefine((password, ctx) => {
		if (password.length < 8) addCtxIssue(ctx, t('min8char'))
		if (password.length > 128) addCtxIssue(ctx, t('max128char'))
		// Min 1 lowercase alphabetic character required
		if (!/[a-z]/.test(password)) {
			addCtxIssue(ctx, t('min1lowercase'))
		}
		// Min 1 uppercase alphabetic character required
		if (!/[A-Z]/.test(password)) addCtxIssue(ctx, t('min1uppercase'))
		// Min 1 numeric character required
		if (!/\d/.test(password)) addCtxIssue(ctx, t('min1numeric'))
		// // Min 1 special character (.?!) required
		if (!/[^A-Za-z0-9\s]/.test(password)) addCtxIssue(ctx, t('min1special'))
	}),
	password_confirmation: z.string(),
})

// Temporary solution for Profile form
export const passwordOptionalSchema = z.object({
	password: z
		.string()
		.optional()
		.superRefine((password, ctx) => {
			if (password) {
				if (password.length < 8) addCtxIssue(ctx, t('min8char'))
				if (password.length > 128) addCtxIssue(ctx, t('max128char'))
				// Min 1 lowercase alphabetic character required
				if (!/[a-z]/.test(password)) {
					addCtxIssue(ctx, t('min1lowercase'))
				}
				// Min 1 uppercase alphabetic character required
				if (!/[A-Z]/.test(password)) addCtxIssue(ctx, t('min1uppercase'))
				// Min 1 numeric character required
				if (!/\d/.test(password)) addCtxIssue(ctx, t('min1numeric'))
				// // Min 1 special character (.?!) required
				if (!/[^A-Za-z0-9\s]/.test(password)) addCtxIssue(ctx, t('min1special'))
			}
		}),
	password_confirmation: z.string().optional(),
})

export default globalSchema
