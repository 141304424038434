export const leafletPopupStyles = {
	'.c2-marker-popup': {
		bottom: '25px !important', // Half size height of map marker icon
		left: '28px !important',
		'.leaflet-popup-content': {
			margin: '10px',
		},
		'.leaflet-popup-content-wrapper': {
			borderRadius: 0,
			backgroundColor: 'var(--c2-colors-body_bg)',
			color: 'var(--c2-colors-body-text)',
			fontSize: '14px',
			fontWeight: '500',
			fontFamily: 'var(--c2-fonts-body)',
			// Hide popup panel when no content inside
			'&:has(.leaflet-popup-content:empty)': {
				display: 'none',
			},
		},
		'.leaflet-popup-tip-container': {
			display: 'none',
		},
		'.leaflet-popup-close-button': {
			display: 'none',
		},
	},
}
