// Packages
import { Box, Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { MdArrowUpward } from 'react-icons/md'
import isNumber from 'lodash/isNumber'

// Alias
import useUnits from '@/hooks/useUnits'
import {
	formatHeight,
	formatLatLng,
	formatSpeed,
	formatDistance,
	formatTimeOnTarget,
	formatHeading,
} from '@/utils/formatUtils'

export type DetailsLocationProps = {
	latitude?: number
	longitude?: number
	heading?: number | null
	speed?: number
	heightOffsetToSite?: number
	siteDistance?: number
	timeOnTarget?: number
}

const DetailsLocation = ({
	latitude = 0,
	longitude = 0,
	heading = 0,
	speed = 0,
	siteDistance = 0,
	heightOffsetToSite = 0,
	timeOnTarget = 0,
}: DetailsLocationProps) => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'notifications.detections.detectionDetail.headings',
	})

	const { isMils } = useUnits('deg')

	return (
		<Flex w='100%' flexWrap='wrap'>
			<Box w='50%' marginBlockEnd={2}>
				<Text fontSize='xs' color='headings'>
					{t('latitude')}
				</Text>
				<Text fontSize='sm'>{formatLatLng(latitude) ?? '-'}</Text>
			</Box>
			<Box w='50%' marginBlockEnd={2}>
				<Text fontSize='xs' color='headings'>
					{t('longitude')}
				</Text>
				<Text fontSize='sm'>{formatLatLng(longitude) ?? '-'}</Text>
			</Box>
			<Box w='50%' marginBlockEnd={2}>
				<Text fontSize='xs' color='headings'>
					{t('height')}
				</Text>
				<Text fontSize='sm'>{formatHeight(heightOffsetToSite) ?? '-'}</Text>
			</Box>
			<Box w='50%' marginBlockEnd={2}>
				<Text fontSize='xs' color='headings'>
					{t('speed')}
				</Text>
				<Text fontSize='sm'>{formatSpeed(speed) ?? '-'}</Text>
			</Box>
			<Box w='50%' marginBlockEnd={2}>
				<Text fontSize='xs' color='headings'>
					{t('direction') + `${isMils ? ' (mil)' : ' (deg)'}`}
				</Text>
				<Box fontSize='sm' position='relative'>
					{isNumber(heading) ? (
						<>
							<Text
								position='absolute'
								top='2px'
								transform={`rotate(${heading}deg)`}
								as='span'
							>
								<MdArrowUpward />
							</Text>
							<Text paddingInlineStart={4}>
								{formatHeading(heading, isMils)}
							</Text>
						</>
					) : (
						'-'
					)}
				</Box>
			</Box>
			<Box w='50%' marginBlockEnd={2}>
				<Text fontSize='xs' color='headings'>
					{t('timeOnTarget')}
				</Text>
				<Text fontSize='sm'>{formatTimeOnTarget(timeOnTarget) ?? '-'}</Text>
			</Box>
			<Box w='50%' marginBlockEnd={2}>
				<Text fontSize='xs' color='headings'>
					{t('distance')}
				</Text>
				<Text fontSize='sm'>{formatDistance(siteDistance) ?? '-'}</Text>
			</Box>
		</Flex>
	)
}

export default DetailsLocation
