// Packages
import { useCallback } from 'react'
import { Box, Flex, Text, useDisclosure } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import {
	MdOutlineVideoSettings as VideoSettingsIcon,
	MdStop,
	MdOutlineClear,
} from 'react-icons/md'
import useRecordingTool from './hooks/useRecordingTool'
import CancelModal from '@UI/Modals/CancelModal'

const RecordingOverlay = () => {
	const {
		isRecording,
		recordingOwner,
		durationStr,
		stopRecording,
		discardRecording,
	} = useRecordingTool()
	const { t } = useTranslation('recordingTool', {
		keyPrefix: 'component.overlay',
	})
	const { isOpen, onClose, onOpen } = useDisclosure()

	const handleClickStop = useCallback(() => {
		if (isRecording) {
			stopRecording()
		}
	}, [isRecording, stopRecording])

	const handleClickCancel = useCallback(() => {
		if (isRecording) {
			onOpen()
		}
	}, [isRecording, onOpen])

	const handleModalConfirm = useCallback(() => {
		discardRecording()
		onClose()
	}, [discardRecording, onClose])

	return (
		<Box
			position='absolute'
			top={0}
			left={0}
			bottom={0}
			right={0}
			borderWidth={4}
			borderStyle='solid'
			borderColor='blue.500'
			pointerEvents='none'
			zIndex={9999}
		>
			<Flex
				height='100%'
				width='100%'
				justifyContent='center'
				alignItems='start'
				userSelect='none'
			>
				<Flex
					backgroundColor='blue.500'
					paddingInline={4}
					paddingBlockStart={0.5}
					paddingBlockEnd={1}
					alignItems='center'
					pointerEvents='all'
					gap={8}
				>
					<Flex alignItems='center' gap={1}>
						<VideoSettingsIcon />
						<Text fontSize='sm'>{t('title')}</Text>
						{recordingOwner && isRecording && (
							<>
								<Text fontSize='sm'>{durationStr}</Text>
								<Flex
									alignItems='center'
									cursor='pointer'
									gap={1}
									onClick={handleClickStop}
								>
									<MdStop />
									<Text fontSize='sm' textTransform='uppercase'>
										{t('stop')}
									</Text>
								</Flex>

								<Flex
									alignItems='center'
									cursor='pointer'
									gap={1}
									onClick={handleClickCancel}
								>
									<MdOutlineClear />
									<Text fontSize='sm' textTransform='uppercase'>
										{t('cancel')}
									</Text>
								</Flex>
							</>
						)}
					</Flex>
				</Flex>
			</Flex>
			<CancelModal
				isOpen={isOpen}
				onClose={onClose}
				handleCancel={handleModalConfirm}
				headerText={t('cancelModal.header')}
				modalBodyText={t('cancelModal.body')}
			/>
		</Box>
	)
}

export default RecordingOverlay
