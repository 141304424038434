// React
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
// Chakra
import { Box, Flex } from '@chakra-ui/react'
// Components
import Field from '@Components/FormElements'
// Hooks
import useDefaultFrequency from '@Forms/RfFilterForm/_hooks/useDefaultFrequency'
import useMinMaxFrequencyRange from '@Forms/RfFilterForm/_hooks/useMinMaxFrequencyRange'
// Utils
import { convertHzToGHz } from '@Utils/mathUtils'
import { DEFAULT_FREQUENCY_BAND } from '@Constants/sensorFilters'
// Types
import type { DefaultRfFilterValuesType } from '@Forms/RfFilterForm/RfFiltersForms.schema'

const RfFilterFormFrequency = ({
	frequency,
	frequency_tolerance,
	setValue,
	isFilterByFrequency,
	defaultValues,
	errors,
	register,
	bandOptions,
	defaultBandOption,
	frequencyBand,
}: {
	frequency: number
	frequency_tolerance: number
	isFilterByFrequency: boolean
	defaultValues: DefaultRfFilterValuesType
	bandOptions: { value: string; label: string }[]
	defaultBandOption: { value: string; label: string } | undefined
	frequencyBand: string
	register: any
	errors: any
	setValue: any
}) => {
	// Translations
	const { t } = useTranslation('forms', { keyPrefix: 'rfFiltersForm' })
	// Math
	const formatFrequency = (value: number) => Number(value.toFixed(3))

	const sortedBandOptions = bandOptions.sort(
		(a, b) =>
			Number(a.value.replace('MHz', '')) - Number(b.value.replace('MHz', ''))
	)

	useEffect(() => {
		if (!frequencyBand) {
			setValue('frequency_band', DEFAULT_FREQUENCY_BAND)
		}
	}, [frequencyBand, setValue])

	const { defaultFrequency, defaultFrequencyTolerance } = useDefaultFrequency(
		defaultValues,
		frequencyBand,
		isFilterByFrequency
	)

	const { minFrequencyRange, maxFrequencyRange } =
		useMinMaxFrequencyRange(frequencyBand)

	useEffect(() => {
		setValue('frequency', formatFrequency(convertHzToGHz(defaultFrequency)))
		setValue(
			'frequency_tolerance',
			formatFrequency(convertHzToGHz(defaultFrequencyTolerance))
		)
	}, [defaultFrequency, defaultFrequencyTolerance, setValue])

	// Handle Frequency
	const [isFrequencyValuesLoading, setFrequencyLoading] = useState(false)

	const handleFrequencyRangeChange = (value: [number, number]) => {
		setValue(
			'frequency_tolerance',
			formatFrequency((value[1] - value[0]) / 2),
			{
				shouldDirty: true,
				shouldValidate: true,
				shouldTouch: true,
			}
		)
		setValue('frequency', formatFrequency((value[1] + value[0]) / 2), {
			shouldDirty: true,
			shouldValidate: true,
			shouldTouch: true,
		})
	}

	const frequencyRangeDefaultValues = useMemo(() => {
		return [frequency - frequency_tolerance, frequency + frequency_tolerance]
	}, [frequency, frequency_tolerance])

	// Remount slider
	useEffect(() => {
		setFrequencyLoading(true)
	}, [frequencyBand, isFilterByFrequency])
	useEffect(() => {
		if (isFrequencyValuesLoading) {
			setFrequencyLoading(false)
		}
	}, [isFrequencyValuesLoading])

	const showSlider =
		!isFrequencyValuesLoading && frequencyBand && bandOptions.length > 0

	return (
		<Box className='FilterByFrequency'>
			<Flex gap={2} mb={4}>
				<Field.Select
					title={t('frequencyBand')}
					options={sortedBandOptions}
					register={register('frequency_band')}
					error={errors?.frequency_band?.message}
					defaultValue={defaultBandOption}
					disabled={!isFilterByFrequency}
					testId='frequency-band'
				/>
				<Field.NumberInput
					isDisabled
					title={`${t('frequencyToFilter')}(${t('GHZ')})`}
					register={register('frequency', {
						valueAsNumber: true,
					})}
				/>
				<Field.NumberInput
					isDisabled
					variant='approximated'
					title={`${t('frequencyTolerance')}(${t('GHZ')})`}
					register={register('frequency_tolerance', {
						valueAsNumber: true,
					})}
				/>
			</Flex>
			<Box minH='60px' className='FrequencyRange'>
				{showSlider && (
					<Field.RangeFilterSlider
						disabled={!isFilterByFrequency}
						defaultValue={frequencyRangeDefaultValues}
						step={0.001}
						min={minFrequencyRange}
						max={maxFrequencyRange}
						onChangeEnd={(value: [number, number]) =>
							handleFrequencyRangeChange(value)
						}
						error={[
							errors?.frequency?.message,
							errors?.frequency_tolerance?.message,
						]
							.filter((error) => !!error)
							.join(', ')}
					/>
				)}
			</Box>
		</Box>
	)
}

export default RfFilterFormFrequency
