import { Box, Flex } from '@chakra-ui/react'

import AlertsDetails from './AlertsDetails'
import AlertsBarChart from './AlertsBarChart'
import AlertsDetailsTable from './AlertsDetailsTable/AlertsDetailsTable'
import {
	selectIsTactical,
	useGetSystemInfoQuery,
} from '@/store/system/systemApi'

export const DEFAULT_PARAMS = {
	page: 0,
	perPage: 10,
	filter_by_content: '',
	sort_by_id: 'DESC' as const,
}

type AlertsSummaryProps = {
	siteId: number
	startTime: string
	endTime: string
}

const AlertsSummary = ({ siteId, startTime, endTime }: AlertsSummaryProps) => {
	// C2T
	const { isTactical } = useGetSystemInfoQuery(undefined, {
		selectFromResult: ({ data }) => ({ isTactical: selectIsTactical(data) }),
	})

	return (
		<Box width='100%'>
			<Flex alignItems='center' gap='30px' marginBlockEnd={4} width='100%'>
				<AlertsDetails
					siteId={siteId}
					startTime={startTime}
					endTime={endTime}
				/>
				<AlertsBarChart
					siteId={siteId}
					startTime={startTime}
					endTime={endTime}
				/>
			</Flex>
			<AlertsDetailsTable
				isTactical={isTactical}
				siteId={siteId}
				startTime={startTime}
				endTime={endTime}
			/>
		</Box>
	)
}

export default AlertsSummary
