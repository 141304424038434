// Packages
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, Flex, FormControl, Icon } from '@chakra-ui/react'
import { MdAdd, MdInfo } from 'react-icons/md'

// Alias
import Field from '@Components/FormElements'
import Tooltip from '@UI/Tooltip/Tooltip'
import Select from '@UI/Select/Select'
import UserSiteListAssociation from '@Forms/UserForm/UserSiteListAssociation'
import useSiteListOptions from '@Forms/UserForm/useSiteListOptions'
import { useGetSitesListQuery } from '@Store/sites/sitesApi'
import { useGetClientQuery } from '@Store/clients/clientsApi'

// Types
import type { User, UserSiteAssociation } from '@Store/types'
import type {
	UserEditSchema,
	UserSchema,
} from '@Forms/UserForm/UserForm.schema'

type OptionType = { value: number | string; label: string | number }

export type UserFormSitesAssociationProps = {
	clientId: User['client_id']
	role: User['role']
	user: UserSchema | UserEditSchema
	siteAssociations: UserSiteAssociation[]
	setSiteAssociations: (
		siteAssociations:
			| UserSiteAssociation[]
			| ((state: UserSiteAssociation[]) => UserSiteAssociation[])
	) => void
	handleAssociations?: (association: UserSiteAssociation[]) => void
}

const UserFormSitesAssociation = ({
	clientId,
	user,
	role,
	siteAssociations,
	setSiteAssociations,
}: UserFormSitesAssociationProps) => {
	const { t } = useTranslation('forms', { keyPrefix: 'userForm' })

	useEffect(() => {
		if (!user || user.client_id === 0) {
			// User doesn't exist yet
			return
		}

		if (clientId !== user.client_id) {
			setSiteAssociations([])
		} else if (user.site_associations && user.site_associations.length) {
			setSiteAssociations(user.site_associations)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, clientId])

	const [userRole, setUserRole] = useState<UserSiteAssociation['role'] | null>()
	const [userSiteId, setUserSiteId] = useState<number | null>()
	const [siteName, setSiteName] = useState<string | null>()

	const isAssociationError = !siteAssociations.length
	const handleAddSite = useCallback(() => {
		const associatedSite = {
			site_id: userSiteId,
			role: userRole,
			site_name: siteName,
		} as UserSiteAssociation

		if (user && user?.id) {
			associatedSite.user_id = user.id
		}

		setSiteAssociations((state) => [...state, associatedSite])
		setUserRole(null)
		setUserSiteId(null)
		setSiteName(null)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userSiteId, userRole, siteName, user])

	const handleDeleteSite = useCallback((id: number) => {
		setSiteAssociations((state) =>
			state.filter((sites) => sites.site_id !== id)
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// Options
	const siteListOptions = useSiteListOptions(clientId, siteAssociations)

	// Show alert when clients sites are empty.
	const { data: sites } = useGetSitesListQuery()
	const { data: client } = useGetClientQuery(clientId, { skip: !clientId })
	if (role !== 'user') {
		return null
	}

	if (!clientId) {
		return null
	}

	if (!sites?.filter((site) => site.client_id === clientId)?.length)
		return (
			<>
				<Field.Divider title={t('headings.siteAssociation')} />
				<Flex gap={2} mt={3} p={2} bgColor='input_bg' alignItems='center'>
					<Icon as={MdInfo} color='primary' />
					<Flex direction='column'>
						<Box fontSize='sm' fontWeight={500}>
							{t('validation.clientSitesAssociation', {
								clientName: client?.name,
							})}
						</Box>
					</Flex>
				</Flex>
			</>
		)

	return (
		<Box data-testid='sites'>
			<Field.Divider title={t('headings.siteAssociation')} />
			{isAssociationError && (
				<Flex gap={2} mb={1} p={2} bgColor='input_bg' alignItems='center'>
					<Icon as={MdInfo} color='error' />
					<Flex direction='column'>
						<Box fontSize='sm' fontWeight={500}>
							{t('validation.siteAssociations', {
								clientName: client?.name,
							})}
						</Box>
					</Flex>
				</Flex>
			)}
			{clientId === 0 && (
				<Flex gap={2} mt={3} p={2} bgColor='input_bg' alignItems='center'>
					<Icon as={MdInfo} color='primary' />
					<Flex direction='column'>
						<Box fontSize='sm' fontWeight={500}>
							{t('errors.selectClient')}
						</Box>
					</Flex>
				</Flex>
			)}
			{clientId !== 0 && (
				<Tooltip
					label={!siteListOptions?.length ? t('tooltips.newAssociation') : ''}
				>
					<Flex gap={2} alignItems='flex-end' position='relative' top='-0.5rem'>
						<FormControl
							flex={1}
							key={siteListOptions?.length}
							isInvalid={isAssociationError}
						>
							<Select
								variant='opened_on_top'
								title={t('headings.site')}
								options={siteListOptions}
								onChange={(option: OptionType) => {
									setUserSiteId(option?.value as number)
									setSiteName(option?.label as string)
								}}
								isDisabled={!siteListOptions?.length}
								testId='site'
							/>
						</FormControl>
						<Tooltip
							type='wrapper'
							label={
								!siteName && siteListOptions?.length ? t('tooltips.role') : ''
							}
							placement='bottom-start'
						>
							<FormControl
								flex={1}
								key={userSiteId}
								isInvalid={isAssociationError}
							>
								<Select
									variant='opened_on_top'
									title={t('headings.userRole')}
									options={
										t('selects.siteUserRoleOptions', {
											returnObjects: true,
										}) as OptionType[]
									}
									onChange={(option: OptionType) =>
										setUserRole(option?.value as UserSiteAssociation['role'])
									}
									isDisabled={!siteName}
									testId='site-role'
								/>
							</FormControl>
						</Tooltip>
						<Button
							leftIcon={<MdAdd />}
							variant='action'
							onClick={handleAddSite}
							isDisabled={!(userRole && userSiteId)}
							data-testid='add-site-association'
						>
							{t('buttons.add')}
						</Button>
					</Flex>
				</Tooltip>
			)}
			{siteAssociations.length > 0 && (
				<UserSiteListAssociation
					userSiteList={siteAssociations}
					handleDelete={handleDeleteSite}
				/>
			)}
		</Box>
	)
}

export default UserFormSitesAssociation
