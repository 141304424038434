// Packages
import { useMemo } from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'

// Alias
import Notification from '@/components/Site/Panels/Notifications/Notification'
import { useGetEventSummaryQuery } from '@Store/analytics/analyticsApi'
import { selectEventContributionSensors } from '@Store/analytics/analyticsSelectors'
import { formatDateTz } from '@/utils/dates'
import { useGetSiteQuery, selectSiteTimeZone } from '@/store/sites/sitesApi'

// Types
import type {
	Camera,
	Detection,
	GenericSensor,
	Radar,
	RfSensor,
} from '@Store/types'

type EventReplayPanelProps = {
	detection: Detection
	eventId: number
}

const EventReplayPanel = ({ detection, eventId }: EventReplayPanelProps) => {
	const { siteId: siteIdParam } = useParams()
	const siteId = Number(siteIdParam)

	const { siteTimeZone, isSuccess: siteIsSuccess } = useGetSiteQuery(siteId, {
		selectFromResult: ({ data, isSuccess, isLoading }) => ({
			siteTimeZone: selectSiteTimeZone(data),
			isSuccess,
			isLoading,
		}),
	})

	const { isSuccess, contributionSensors } = useGetEventSummaryQuery(
		{ eventId },
		{
			selectFromResult: ({ isSuccess, data }) => ({
				isSuccess,
				contributionSensors: selectEventContributionSensors(data),
			}),
		}
	)

	const { radars, rfSensors, cameras, genericSensors } = useMemo(() => {
		return contributionSensors.reduce(
			(acum, rawSensor) => {
				const sensor = {
					id: rawSensor.sensorId,
					name: rawSensor.sensorName,
					sensorType: rawSensor.sensorType,
				} as any
				switch (rawSensor.sensorType) {
					case 'rfSensor':
					case 'dsx':
						return { ...acum, rfSensors: [...(acum.rfSensors ?? []), sensor] }
					case 'radar':
						return { ...acum, radars: [...(acum.radars ?? []), sensor] }
					case 'camera':
						return { ...acum, cameras: [...(acum.cameras ?? []), sensor] }
					case 'generic':
						return {
							...acum,
							genericSensors: [...(acum.genericSensors ?? []), sensor],
						}
					default:
						return acum
				}
			},
			{} as {
				rfSensors: RfSensor[]
				cameras: Camera[]
				radars: Radar[]
				genericSensors: GenericSensor[]
			}
		)
	}, [contributionSensors])

	if (!isSuccess || !siteIsSuccess || !siteTimeZone) {
		return null
	}

	return (
		<Box
			width='316px'
			position='absolute'
			top={0}
			right={0}
			bottom='calc(55px + var(--c2-sizes-2))'
			zIndex={999}
			m='var(--c2-sizes-2)'
			mb='28px'
			overflow='hidden'
			data-testid='event-replay-panel'
			overflowY='auto'
		>
			<Flex h='40px' bgColor='input_bg' p='10px' gap={2}>
				<Text fontSize='md' as='b' data-testid='timestamp'>
					{formatDateTz(detection.created_at, siteTimeZone)}
				</Text>
			</Flex>
			<Notification
				detection={detection}
				seenContributor={contributionSensors}
				showHeader={false}
				isSelected={true}
				isReplay
				radars={radars}
				rfSensors={rfSensors}
				cameras={cameras}
				genericSensors={genericSensors}
			/>
		</Box>
	)
}

export default EventReplayPanel
