import { z } from 'zod'
import globalSchema from '../BaseForm/BaseForm.schema'

const genericSensorFormSchema = z.object({
	sentry_id: z.number(),
	sensor_type: z.string().min(1),
	name: z.string().min(1),
	serial_number: z.string(),
	model: z.string(),
	height_offset_to_installation:
		globalSchema.shape.height_offset_to_installation,
	direction_offset: z.number(),
	min_range: z.number().min(0).max(10000),
	max_range: z.number().min(0).max(10000),
	sector_field_of_view: z.number().min(0).max(360),
	ip: z.string().ip(),
	port: z.string().min(4).max(5), // TODO z.number().min(1).max(65535),
	detection_visualisation: z.enum(['sector', 'line', 'sector_and_line']),
	tilt: z.number().min(-45).max(45),
	roll: z.number().min(-45).max(45),
	north_offset_to_installation: z.number().min(-10).max(10),
	east_offset_to_installation: z.number().min(-10).max(10),
	sensor_fusion_settings: z.object({
		sensor_weight: z.number().min(0).max(100),
		minimum_contribution_count: z.number().min(1).max(50),
		sensor_contribution_timeout: z.number().min(0).max(60),
		stream_raw_detections: z.boolean(),
		able_to_detect_communication_mode: z.boolean(),
	}),
	// TODO
	// disruptor: z.boolean(),
	// min_disruption_range: z.number().min(0).max(8000),
	// max_disruption_range: z.number().min(0).max(8000),
})

export type GenericSensorDefaultValues = z.infer<typeof genericSensorFormSchema>

export const ADD_DEFAULT_VALUES: GenericSensorDefaultValues = {
	sentry_id: 0,
	sensor_type: '',
	name: 'Generic Sensor',
	serial_number: '',
	model: '',
	height_offset_to_installation: 0,
	direction_offset: 0,
	min_range: 0,
	max_range: 8000,
	sector_field_of_view: 90,
	ip: '',
	port: '',
	detection_visualisation: 'sector_and_line',
	tilt: 0,
	roll: 0,
	north_offset_to_installation: 0,
	east_offset_to_installation: 0,
	sensor_fusion_settings: {
		sensor_weight: 100,
		minimum_contribution_count: 5,
		sensor_contribution_timeout: 5,
		stream_raw_detections: false,
		able_to_detect_communication_mode: false,
	},
	// TODO
	// disruptor: false,
	// min_disruption_range: 0,
	// max_disruption_range: 0,
}

export default genericSensorFormSchema
