import { memo, useMemo } from 'react'
import MapMarker from '@Components/Map/MapMarker/MapMarker'
import useValidCoordinates from '@/hooks/useValidCoordinates'
import {
	selectSiteMarker,
	useGetSiteMarkersQuery,
} from '@/store/siteMarkers/siteMarkersApi'
import { useParams } from 'react-router-dom'
import useCenterMap from './useCenterMap'

const MarkerPreview = () => {
	const { siteId: siteIdParam, markerId: markerIdParam } = useParams()

	const siteId = Number(siteIdParam)
	const markerId = Number(markerIdParam)

	const { marker } = useGetSiteMarkersQuery(
		{ siteId },
		{
			refetchOnMountOrArgChange: true,
			selectFromResult: ({ data }) => ({
				marker: selectSiteMarker(data, markerId),
			}),
		}
	)

	const latitude = useMemo(() => Number(marker?.lat), [marker])
	const longitude = useMemo(() => Number(marker?.lng), [marker])
	useCenterMap({
		latitude: latitude,
		longitude: longitude,
	})

	const coordinates = useValidCoordinates(latitude, longitude)

	if (!marker) return null
	const { number, nickname, colour } = marker
	const validLat = Number(coordinates[0])
	const validLng = Number(coordinates[1])

	return (
		<MapMarker
			latitude={validLat}
			longitude={validLng}
			number={Number(number)}
			nickname={nickname}
			color={colour}
			isSelected
		/>
	)
}

export default memo(MarkerPreview)
