// React
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
// Chakra
import {
	Flex,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	useModalContext,
} from '@chakra-ui/react'

// Store
import type { User } from '@Store/types'

// Forms
import type { TypeOf } from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import { profileFormSchema } from '@Forms/ProfileForm/ProfileForm.schema'
import ProfileFormGeneral from '@Forms/ProfileForm/ProfileFormGeneral'
import ProfileFormProfile from '@Forms/ProfileForm/ProfileFormProfile'
import ProfileFormPassword from '@Forms/ProfileForm/ProfileFormPassword'
// Components
import { FormButtons } from '@Components/FormElements/FormButtons/FormButtons'
// Types
import { setFormError } from '@Forms/formHelper'
import { zodResolver } from '@hookform/resolvers/zod'
// Store
import { useUpdateUserProfileMutation } from '@Store/user/usersApi'

const ProfileForm = ({ user }: { user: User }) => {
	type Schema = TypeOf<typeof profileFormSchema>
	const { t } = useTranslation('forms', { keyPrefix: 'profileForm' })
	const { onClose } = useModalContext()

	const methods = useForm<Schema>({
		resolver: zodResolver(profileFormSchema),
		defaultValues: user,
	})

	const {
		formState: { isSubmitting, dirtyFields, errors },
		handleSubmit,
		setError,
	} = methods

	const isDirty = Object.values(dirtyFields).length > 0

	const [updateProfile] = useUpdateUserProfileMutation()

	const [tabIndex, setTabIndex] = useState(0)

	// TODO: C2-9808 Set an error in particular tab the same as accordion
	useEffect(() => {
		const fields = Object.keys(errors)
		let errorIndex

		for (const field of fields) {
			if (
				!errorIndex &&
				[
					'language',
					'play_alarm_sounds',
					'default_site_id',
					'settings.milsEnabled',
				].includes(field)
			) {
				errorIndex = 0
				break
			} else if (
				!errorIndex &&
				['name', 'username', 'email', 'company', 'phone'].includes(field)
			) {
				errorIndex = 1
				break
			} else if (
				!errorIndex &&
				['password', 'password_confirmation', 'current_password'].includes(
					field
				)
			) {
				errorIndex = 2
				break
			}
		}

		if (errorIndex) setTabIndex(errorIndex)
	}, [errors, setTabIndex])

	// TODO: C2-9808 Set an error in particular tab the same as accordion
	useEffect(() => {
		const fields = Object.keys(errors)
		let errorIndex

		for (const field of fields) {
			if (
				!errorIndex &&
				[
					'language',
					'play_alarm_sounds',
					'default_site_id',
					'settings.milsEnabled',
				].includes(field)
			) {
				errorIndex = 0
				break
			} else if (
				!errorIndex &&
				['name', 'username', 'email', 'company', 'phone'].includes(field)
			) {
				errorIndex = 1
				break
			} else if (
				!errorIndex &&
				['password', 'password_confirmation', 'current_password'].includes(
					field
				)
			) {
				errorIndex = 2
				break
			}
		}

		if (errorIndex) setTabIndex(errorIndex)
	}, [errors, setTabIndex])

	const handleSave = async (payload: Schema) => {
		const {
			settings: { displayUnit, mgrsEnabled, milsEnabled },
		} = payload
		try {
			if (payload.password === '') {
				delete payload.current_password
				delete payload.password
				delete payload.password_confirmation
			}
			await updateProfile({
				...payload,
				settings: {
					...user.settings,
					displayUnit,
					mgrsEnabled,
					milsEnabled,
				},
				userId: user.id,
			}).unwrap()
			onClose()
		} catch (error) {
			setFormError({ error, setError })
		}
	}

	const handleTabsChange = (index: number) => setTabIndex(index)

	return (
		<FormProvider {...methods}>
			<Flex
				as='form'
				onSubmit={handleSubmit(handleSave)}
				direction='column'
				justifyContent='space-between'
				h='full'
			>
				<Tabs
					mt={0}
					h='full'
					display='flex'
					flexDirection='column'
					isLazy
					index={tabIndex}
					onChange={handleTabsChange}
				>
					<TabList sx={{ '& > button': { flex: 1 } }} mt={3}>
						<Tab data-testid='general-tab'>{t('tabs.general')}</Tab>
						<Tab data-testid='profile-tab'>{t('tabs.profile')}</Tab>
						<Tab data-testid='password-tab'>{t('tabs.password')}</Tab>
					</TabList>
					<TabPanels flex={1}>
						<TabPanel p={0} h='full'>
							<ProfileFormGeneral user={user as User} />
						</TabPanel>
						<TabPanel p={0} h='full'>
							<ProfileFormProfile />
						</TabPanel>
						<TabPanel p={0} h='full'>
							<ProfileFormPassword />
						</TabPanel>
					</TabPanels>
				</Tabs>
				<FormButtons
					isSubmitting={isSubmitting}
					isDirty={isDirty}
					handleCancel={onClose}
				/>
			</Flex>
		</FormProvider>
	)
}

export default ProfileForm
