// React
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
// Chakra
import { FormControl } from '@chakra-ui/react'
// Components
import UiSelect from '@UI/Select/Select'
// Store
import {
	selectSiteCustomMapCenter,
	setSiteCustomMapCenter,
} from '@Store/ui/uiSlice'
import { useAppDispatch, useAppSelector } from '@/store'
import { useFormContext } from 'react-hook-form'
// Types
type mapCenterOptionType = { value: string; label: string }

const SiteFormMapCenterSelect = () => {
	const { t } = useTranslation('forms', { keyPrefix: 'siteForm' })
	const { setValue } = useFormContext()
	const siteMapCenter = useAppSelector(selectSiteCustomMapCenter)
	const dispatch = useAppDispatch()

	// Return options values from translations
	const options = t('mapCenterOptions', {
		returnObjects: true,
	}) as mapCenterOptionType[]

	const value = useMemo(() => {
		// Return equivalents of "Custom latitude/longitude" or "Site latitude/longitude" translations
		return options?.[siteMapCenter && siteMapCenter.isCustomMapCenter ? 1 : 0]
	}, [siteMapCenter, options])

	const handleMapCenterChange = useCallback(
		(values: mapCenterOptionType) => {
			const { value } = values
			const selectedValue = options.find(
				(option) => option.value === value
			) as mapCenterOptionType

			const isCustomMapCenter = selectedValue.value === 'custom'
			dispatch(
				setSiteCustomMapCenter(isCustomMapCenter ? { isCustomMapCenter } : null)
			)

			if (!isCustomMapCenter) {
				setValue('map_center_latitude', null, {
					shouldDirty: true,
				})
				setValue('map_center_longitude', null, {
					shouldDirty: true,
				})
			}
		},
		[dispatch, setValue, options]
	)

	if (!value) return null

	return (
		<FormControl>
			<UiSelect
				id='mapCenter'
				title={t('mapCenter')}
				options={options}
				value={value}
				onChange={handleMapCenterChange}
			/>
		</FormControl>
	)
}

export default SiteFormMapCenterSelect
