/* eslint-disable react/no-unescaped-entities */
import { useTranslation } from 'react-i18next'
// Chakra
import {
	Text,
	MenuItem,
	Flex,
	List,
	ListItem,
	Divider,
	Table,
	TableContainer,
	Thead,
	Tr,
	Th,
	Tbody,
	Td,
	Button,
	useDisclosure,
} from '@chakra-ui/react'
import { MdBook, MdDownload } from 'react-icons/md'
// Components
import Modal from '@UI/Modals/Modal'
// Assets
import eulaPdf from '@/assets/dro_eula_2024.pdf'

const LicenseAgreement = () => {
	const { t } = useTranslation('navigation', {
		keyPrefix: 'settingsMenu.license',
	})
	const { isOpen, onOpen, onClose } = useDisclosure()
	const year = new Date().getFullYear()
	const headerStyle = {
		fontWeight: 500,
		fontSize: '16px',
	}
	const listStyle = {
		pl: 6,
		display: 'flex',
		flexDirection: 'column',
		gap: 2,
	}
	const subListStyle = {
		...listStyle,
		py: 2,
	}

	return (
		<>
			<MenuItem
				icon={<MdBook size='1.25rem' />}
				data-testid='license-agreement'
				onClick={onOpen}
			>
				{t('button')}
			</MenuItem>
			<Modal
				isOpen={isOpen}
				onClose={onClose}
				headerText={t('header', { year })}
				size='xxl'
				contentHeight='866px'
				isScrollable
			>
				<Flex direction='column' gap={3} p={4} fontSize='14px'>
					<Flex justifyContent='flex-end'>
						<Button as='a' leftIcon={<MdDownload />} href={eulaPdf} download>
							{t('pdf')}
						</Button>
					</Flex>
					<Text color='error'>IMPORTANT – PLEASE READ CAREFULLY:</Text>
					<Text>
						This End User Licence Agreement (“EULA”) is an agreement between the
						End User and DroneShield. This EULA governs your use of
						DroneShield’s products. The Products are provided only on the
						condition that end user agrees to the Terms and Conditions in this
						EULA, and as amended from time to time. By accepting this agreement
						or by using the Products, end user acknowledges it has read,
						understands, and agrees to be bound by this EULA.
					</Text>
					<Divider />
					<Text as='h2' sx={headerStyle}>
						1. Definitions & Interpretation
					</Text>
					<Text>
						1.1. Definitions. Capitalised words when used in this EULA shall
						have the following meaning unless specified otherwise:{' '}
					</Text>
					<List sx={listStyle}>
						<ListItem>
							(a) Affiliate means any entity that, directly or indirectly
							through one or more intermediaries, controls, is controlled by, or
							is under common control with, that entity.
						</ListItem>
						<ListItem>
							(b) Business Day means a day on which banks are open for business
							in New South Wales, Australia, other than a Saturday, Sunday or
							public holiday in New South Wales, Australia.
						</ListItem>
						<ListItem>
							(c) Claim means any claims, demands, suits, or proceedings of any
							kind, open, pending or threatened, whether mature, unmatured,
							contingent, known or unknown, at law or in equity, in any forum.
						</ListItem>
						<ListItem>
							(d) Cloud means a third-party platform which is a network of
							remote servers hosted on the Internet to store, manage, and
							process data associated with the Products, which DroneShield makes
							available to the End User to access certain Products and Software
							updates on a software-as-a-service basis.
						</ListItem>
						<ListItem>
							(e) Confidential Information means any non-public, commercially
							proprietary, or sensitive information disclosed in connection with
							this EULA regardless of whether that information is specifically
							designated as confidential and regardless of whether such
							information is written, oral, electronic or in any other form. It
							includes information which is expressly designated as
							confidential, is by its nature confidential, or the other party
							knows (or ought to know) that information is confidential.
						</ListItem>
						<ListItem>
							(f) Documentation means the Product installation instructions,
							user manuals, setup posters, release notes, and operating
							instructions prepared by DroneShield, in any form or medium, as
							may be updated from time to time by DroneShield and made generally
							available to End User.
						</ListItem>
						<ListItem>
							(g) DroneShield, we, us, or our means DroneShield LLC if
							designated on the Order or End User is in the United State or
							DroneShield Group Pty Ltd (ABN 44 668 560 610) if designated on
							the order or otherwise.
						</ListItem>
						<ListItem>
							(h) End User means the individual, company, or other legal entity
							that has placed an Order, which is the ultimate end user of the
							Products.
						</ListItem>
						<ListItem>
							(i) Error means a material failure of the Product to conform to
							the Documentation, which is reported by End User and replicable by
							DroneShield.
						</ListItem>
						<ListItem>
							(j) Extended Warranty Period means an extended warranty purchased
							by the End User as specified in an Order.
						</ListItem>
						<ListItem>
							(k) Government Agency means any government or governmental,
							administrative, monetary, fiscal or judicial body, department,
							commission, authority, tribunal, agency or entity in any part of
							the world.
						</ListItem>
						<ListItem>
							(l) Hardware means DroneShield hardware identified on an Order.
						</ListItem>
						<ListItem>
							(m) Initial Period means one (1) year from the date DroneShield or
							one of its suppliers ships the Product to the End User or the
							applicable reseller (whichever occurs first).
						</ListItem>
						<ListItem>
							(n) Intellectual Property Rights means all intellectual property
							rights of any kind, in any jurisdiction, subsisting now or in the
							future (including, without limitation, business, company or trade
							names, domain names, patents, inventions, copyright, design
							rights, know-how, trademarks, the right to sue for passing off and
							rights to use, and protect the confidentiality of, confidential
							information), whether registered or unregistered, and including
							the rights to apply for or renew the registration of any such
							rights and any rights the subject of any lapsed application or
							registration.
						</ListItem>
						<ListItem>
							(o) Maintenance means the annual support services and updates as
							provided by DroneShield and made available to the End User to
							access and download through a secure platform.
						</ListItem>
						<ListItem>
							(p) Order means a purchase agreement between: (1) DroneShield and
							End User; or (2) an authorised reseller(s) of DroneShield and End
							User.
						</ListItem>
						<ListItem>
							(q) Permitted Number means one (1) unless specified otherwise in
							an Order.
						</ListItem>
						<ListItem>
							(r) Products means Hardware and Software (if any) together with
							the applicable Documentation.
						</ListItem>
						<ListItem>
							(s) Software means DroneShield’s proprietary software
							applications, in object code provided with DroneShield Hardware
							and any modified versions of such software, and upgrades, updates,
							additions to such application provided by DroneShield to End User
							at any time to the extent not provided under separate terms.
						</ListItem>
						<ListItem>
							(t) Standard Warranty Period means one (1) year of the date of
							shipment of the Hardware by DroneShield, its related entity, or
							its supplier to the End User or applicable DroneShield reseller.
						</ListItem>
						<ListItem>
							(u) Subscription means a non-exclusive, nontransferable
							subscription to the applicable Software updates for the Products
							for the Subscription Term.
						</ListItem>
						<ListItem>
							(v) Subscription Fees means the fees as agreed in the applicable
							Order.
						</ListItem>
						<ListItem>
							(w) Subscription Term means the subscription term specified in an
							Order agreed upon time period in an Order.
						</ListItem>
						<ListItem>
							(x) Support Services has the meaning given to that term in clause
							4.3 of this EULA.
						</ListItem>
					</List>
					<Text>
						1.2. Interpretation. In this EULA the following rules on
						interpretation will apply, unless the contrary intention appears, or
						the context otherwise requires:
					</Text>
					<List sx={listStyle}>
						<ListItem>
							(a) headings are for convenience only and do not affect the
							interpretation of the EULA.
						</ListItem>
						<ListItem>
							(b) references to a party to any agreement or document include
							that party’s permitted assignees and successors, including
							executors and administrators and legal representatives.
						</ListItem>
						<ListItem>
							(c) words denoting the singular include the plural and words
							denoting the plural include the singular.
						</ListItem>
						<ListItem>
							(d) words denoting any gender include all genders.
						</ListItem>
						<ListItem>
							(e) the word “person” includes any individual, corporation or
							other body corporate, partnership, joint venture, trust,
							association and any Government Agency.
						</ListItem>
						<ListItem>
							(f) a reference to any agreement or document (including this
							agreement) includes any amendments, supplements and replacements
							of that document.
						</ListItem>
						<ListItem>
							(g) a reference to a law includes:
							<List sx={subListStyle}>
								<ListItem>
									(i) legislation, regulations and other instruments made under
									legislation and any consolidations, amendments, re-enactments
									or replacements of them;
								</ListItem>
								<ListItem>
									(ii) a constitutional provision or treaty or decree;
								</ListItem>
								<ListItem>(iii) any judgment;</ListItem>
								<ListItem>
									(iv) any principle or rule of the common law or in equity, and
									is a reference to that law as amended, consolidated, replaced,
									or re-enacted or applied to new or different facts.
								</ListItem>
							</List>
						</ListItem>
						<ListItem>
							(h) any promise, agreement, representation, or warranty given or
							entered into on the part of two or more persons binds them jointly
							and each of them severally.
						</ListItem>
						<ListItem>
							(i) no provision of this Agreement will be construed adversely to
							a party because that party was responsible for the preparation of
							that provision or this Agreement.
						</ListItem>
						<ListItem>
							(j) specifying anything in this agreement after the terms
							“include”, “including”, “includes”, “for example”, “such as” or
							any similar expression does not limit the sense of the words,
							description, definition, phrase, or term preceding those terms
							unless there is express wording to the contrary.
						</ListItem>
					</List>
					<Text as='h2' sx={headerStyle}>
						2. Licence Grant
					</Text>
					<Text>
						2.1. Subject to End User’s continuous compliance with this EULA,
						payment of the applicable licence fees and any necessary export
						approvals, DroneShield grants End User a non-exclusive and limited
						licence to install and use the Software with the Hardware:
					</Text>
					<List sx={listStyle}>
						<ListItem>
							(a) in the location that the End User specified in the Order;
						</ListItem>
						<ListItem>(b) during the Subscription Term;</ListItem>
						<ListItem>
							(c) for the Permitted Number; and (d) in a manner consistent with
							the terms of this EULA, the applicable Documentation and in
							conjunction with any other applicable agreement between the
							parties.
						</ListItem>
					</List>
					<Text as='h2' sx={headerStyle}>
						3. Products
					</Text>
					<Text>
						3.1. The Products are provided to the End User in exchange for
						payment of the applicable fees specified in an Order.
					</Text>
					<Text>
						3.2. Software is provided to the End User as a Subscription as
						follows:
					</Text>
					<List sx={listStyle}>
						<ListItem>
							(a) included in the Hardware cost for the Initial Period; and{' '}
						</ListItem>
						<ListItem>
							(b) on and from the end of the Initial Period for the Subscription
							Term, in exchange for the Subscription Fee.
						</ListItem>
					</List>
					<Text>
						3.3. The Products in an Order will be provided to the End User in
						the manner DroneShield considers to be the most appropriate and
						effective.
					</Text>
					<Text>
						3.4. The Products are provided “as-is” and at End User’s own risk.
					</Text>
					<Text>
						3.5. The End User is responsible for ensuring the Products will meet
						its requirements.
					</Text>
					<Text>3.6. DroneShield may:</Text>
					<List sx={listStyle}>
						<ListItem>
							(a) change, add, or delete functions, features, performance, or
							characteristics of the Software at any time.{' '}
						</ListItem>
						<ListItem>
							(b) deactivate Client access to the Software due to security or
							system issues.
						</ListItem>
					</List>
					<Text as='h2' sx={headerStyle}>
						4. Support Services
					</Text>
					<Text>
						4.1. Subject to clause 4.2 below, DroneShield will provide Support
						Services for the Software to the End User for the Initial Period.
					</Text>
					<Text>
						4.2. Where an End User purchases a Subscription, DroneShield will
						provide the Support Services for the Software to the End User for
						the Subscription Term.
					</Text>
					<Text>4.3. Support Services means:</Text>
					<List sx={listStyle}>
						<ListItem>(a) product technical support which includes:</ListItem>
						<ListItem>
							(i) standard technical support, via phone or email consultation or
							by qualified third-party reseller/partner services, including
							Error corrections or workarounds so that the Products operate in
							substantial conformance with the Documentation; and
						</ListItem>
						<ListItem>
							(ii) the provision of Software update and upgrades, excluding
							updates to DroneShield’s signature database, if and when
							available.
						</ListItem>
						<ListItem>
							(b) standard technical support which includes online website and
							portal access, and telephone support during business hours.
						</ListItem>
					</List>
					<Text>
						4.4. End User must install Software upgrades up to and including the
						latest release to be eligible to access the Support Services.
						Software upgrades are available to download by the End User from the
						DroneShield Customer Portal.
					</Text>
					<Text>
						4.5. DroneShield’s obligation to provide Support Services is limited
						to:
					</Text>
					<List sx={listStyle}>
						<ListItem>
							(a) a Product that has not been altered or modified by anyone
							other than DroneShield or its authorised representatives;
						</ListItem>
						<ListItem>(b) a current release of the Software;</ListItem>
						<ListItem>
							(c) End User’s use of the Product is in accordance with the
							Documentation; and
						</ListItem>
						<ListItem>
							(d) errors and malfunctions are caused by systems or programs
							supplied by DroneShield.
						</ListItem>
					</List>
					<Text>
						4.6. If an Error has been corrected or is not present in the current
						version of the Product or associated Software, DroneShield will
						provide the current Software release to the End User to download and
						install and will not have any obligation to correct such Error in
						non-current/prior versions of such Product or Software.
					</Text>
					<Text as='h2' sx={headerStyle}>
						5. Intellectual Property Rights
					</Text>
					<Text>
						5.1. All Intellectual Property Rights in the Products, Subscriptions
						and Support Services are the Property of DroneShield.
					</Text>
					<Text>
						5.2. Subject to End User’s compliance with the terms of this EULA
						and payment of all applicable fees, DroneShield grants the End User
						a non-exclusive and non-transferrable right to access and use the
						Intellectual Property Rights in the Products, Subscriptions and
						Support Services for its internal business purposes.
					</Text>
					<Text>
						5.3. DroneShield grants the End User a non-transferrable and
						non-exclusive right to access and use the Subscriptions for the
						Subscription Term for the End User’s internal business purposes.
					</Text>
					<Text>
						5.4. End User must not reverse engineer, decompile, disassemble,
						attempt to extract the source code from, modify, create derivative
						works of, distribute, sell, lease or licence any part of the
						Products, Subscriptions or Support Services.
					</Text>
					<Text>
						5.5. End User must not remove any proprietary notice of DroneShield
						or any third party from the Products or any copy of the Products,
						without DroneShield’s prior written consent.
					</Text>
					<Text as='h2' sx={headerStyle}>
						6. Licence Restrictions
					</Text>
					<Text>
						6.1. End User agrees that End User must not itself, or through any
						Affiliate, employee, consultant, contractor, agent or other third
						party:
					</Text>
					<List sx={listStyle}>
						<ListItem>
							(i) sell, resell, distribute, host, lease, rent, licence or
							sublicence, in whole or in part, the Products or engage in the
							manufacture of any other products itself or through third parties
							using or containing the Intellectual Property Rights or any
							Confidential Information;
						</ListItem>
						<ListItem>
							(ii) decipher, decompile, disassemble, reverse assemble, modify,
							translate, reverse engineer or otherwise attempt to derive source
							code, algorithms, tags, specifications, architecture, structure or
							other elements of the Products, in whole or in part, for
							competitive purposes or otherwise;
						</ListItem>
						<ListItem>
							(iii) allow access to, provide, divulge or make available the
							Products to any user other than End User’s employees and
							individual contractors who have a need to such access and who
							shall be bound by nondisclosure obligations that are at least as
							restrictive as the terms of this EULA;
						</ListItem>
						<ListItem>
							(iv) write or develop any derivative works based upon the
							Products;
						</ListItem>
						<ListItem>
							(v) modify, adapt, translate or otherwise make any changes to the
							Products or any part thereof;
						</ListItem>
						<ListItem>
							(vi) use the Products to provide processing services to third
							parties, or otherwise use the same on a ‘service bureau’ basis;
						</ListItem>
						<ListItem>
							(vii) disclose or publish, without DroneShield’s prior written
							consent, performance or capacity statistics or the results of any
							benchmark test performed on the Products;
						</ListItem>
						<ListItem>
							(viii) otherwise use or copy the same except as expressly
							permitted herein; or
						</ListItem>
						<ListItem>
							(ix) use any third-party software included in the Products
							independently from the Products.
						</ListItem>
					</List>
					<Text as='h2' sx={headerStyle}>
						7. Financial Terms
					</Text>
					<Text>
						7.1. Fees and payment terms are specified in the applicable Order.
					</Text>
					<Text>
						7.2. Title in the Products shall pass to Reseller upon payment and
						shipment. Risk of loss shall pass to Reseller upon shipment from
						DroneShield’s shipping point, or as specified in the applicable
						Order.
					</Text>
					<Text>
						7.3. All quotes and Orders will be exclusive of any tax, levy, duty,
						or similar government charge (“Tax”) and you will be responsible for
						all Tax, unless agreed in writing.
					</Text>
					<Text as='h2' sx={headerStyle}>
						8. Limited Warranty, Remedies, Disclaimer
					</Text>
					<Text>
						8.1. Except as otherwise stated in a separate agreement between
						DroneShield and End User, DroneShield warrants to the End User that
						for the Standard Warranty Period the Products, as updated from time
						to time by DroneShield and used in accordance with the Documentation
						and the EULA by End User, will operate in substantial conformance
						with the Documentation under normal use.
					</Text>
					<Text>
						8.2. The End User may purchase from DroneShield an Extended Warranty
						Period for the Products, in which case subject to payment of the
						applicable fee by the End User to DroneShield, DroneShield warrants
						for the Extended Warranty Period the Products, as updated from time
						to time by DroneShield and used in accordance with the Documentation
						and the EULA by End User, will operate in substantial conformance
						with the Documentation under normal use.
					</Text>
					<Text>
						8.3. End User acknowledges that the Product is innovative and as
						such is not foolproof, will not operate without interruption or be
						error-free, and may experience certain operational challenges that
						are beyond DroneShield’s control.
					</Text>
					<Text>8.4. DroneShield does not warrant that the Products will:</Text>
					<List sx={listStyle}>
						<ListItem>(a) be free of defects;</ListItem>
						<ListItem>
							(b) satisfy End User’s requirements or are adapted to End User’s
							needs;
						</ListItem>
						<ListItem>
							(c) be legal to use or possess under any law, statute, or
							regulation, including any data or radio frequency control,
							privacy, or jamming laws;
						</ListItem>
						<ListItem>
							(d) operate in combination with any other software or hardware
							which End User may use; or
						</ListItem>
						<ListItem>(e) operate without interruption or error.</ListItem>
					</List>
					<Text>
						8.5. The End User acknowledges and agrees that the prices and other
						charges contemplated under this EULA and associated Order are based
						on the limited warranty, disclaimer and limitation of liability
						specified herein and such charges would be substantially higher if
						any of these provisions were unenforceable.
					</Text>
					<Text>
						8.6. For the avoidance of doubt, the warranty periods apply only to
						the initial delivery of Product under an Order and does not renew or
						reset, with any Software or Product updates or for any other reason,
						including a later decision of the End User to purchase an extended
						warranty.
					</Text>
					<Text>
						8.7. If during the Standard Warranty Period or Extended Warranty
						Period (whichever applies), an Error arises, the End User must
						promptly notify DroneShield in writing of a claim that an Error
						exists. If DroneShield acting reasonably determines that such Error
						is DroneShield’s responsibility, DroneShield shall use its
						reasonable endeavours to, within thirty (30) days of its receipt of
						End User’s written notice either:
					</Text>
					<List sx={listStyle}>
						<ListItem>
							(a) correct the Error or provide a workaround through the
							provision of Support Services;
						</ListItem>
						<ListItem>
							(b) provide End User with a plan acceptable to End User, acting
							reasonably, for correcting the Error; or
						</ListItem>
						<ListItem>
							(c) if neither (i) nor (ii) can be accomplished with reasonable
							commercial efforts from DroneShield at DroneShield’s discretion,
							then DroneShield may terminate the affected Product Subscription
							(if applicable) and End User will not be entitled to a refund of
							any unused Subscription Fees paid for the balance of the
							then-current Subscription Term (or the price paid for the Product,
							if Subscription does not apply) unless required by applicable
							local laws.
						</ListItem>
					</List>
					<Text>
						This clause contains End User’s sole and exclusive remedy and
						DroneShield's entire liability for any breach of Standard Warranty
						Period or Extended Warranty Period (whichever applies) or other duty
						related to the Products.
					</Text>
					<Text>
						8.8. The warranties and remedies specified in this clause 8 will not
						apply and DroneShield is not obligated to provide Support Services
						if the Error is due to extrinsic causes, including:
					</Text>
					<List sx={listStyle}>
						<ListItem>
							(a) natural disasters, including fire, smoke, water, earthquakes
							or lightning;
						</ListItem>
						<ListItem>
							(b) electrical power fluctuations or failures or electrochemical
							influences, to the End User;
						</ListItem>
						<ListItem>
							(c) the neglect or misuse of the Products by the End User or other
							failure to comply with the instructions set out in the applicable
							Documentation;
						</ListItem>
						<ListItem>
							(d) a correction or modification of the Products not provided by
							DroneShield or End User’s failure to use any new or corrected
							versions of the Product made available by DroneShield;
						</ListItem>
						<ListItem>
							(e) the failure to properly and promptly install the Software as
							updated from time to time;
						</ListItem>
						<ListItem>
							(f) a malfunction of the End User’s hardware equipment, or
						</ListItem>
						<ListItem>
							(g) the combination or use of the Products with other software or
							equipment not provided, or approved by DroneShield;
						</ListItem>
						<ListItem>
							(h) functionality of the Products with the End user’s other third
							party technology. End User shall be solely responsible in the
							event that, End User combines functionality of the Products with
							End User’s or other third party technology and makes any
							warranties that are inconsistent with and contrary to those
							published by DroneShield;
						</ListItem>
						<ListItem>
							(i) misuse, unauthorized modification, improper installation of
							the Products; or
						</ListItem>
						<ListItem>
							(j) if End User is not in compliance with this EULA.
						</ListItem>
					</List>
					<Text>
						8.9. The warranties in this clause 8 are in lieu of, and DroneShield
						and its suppliers expressly disclaim to the maximum extent permitted
						by law, all other warranties, either express or implied, including
						without limitation, any implied warranties of merchantability,
						non-infringement, title or fitness for a particular purpose, and
						freedom from program errors, viruses or any other malicious code
						with respect to the products and services provided under this
						agreement.
					</Text>
					<Text as='h2' sx={headerStyle}>
						9. Limitation of Liability.
					</Text>
					<Text>
						Notwithstanding anything in this EULA to the contrary, DroneShield
						and/or its resellers will not be liable for any indirect,
						consequential, special, punitive or incidental damages, whether
						foreseeable or unforeseeable, arising out of or related to this EULA
						and/or the End User’s use or possession of the Products (including,
						but not limited to, Claims for loss of data, goodwill, use of money
						or use of the products, late delivery, interruption in use or
						availability of data, stoppage of other work or impairment of other
						assets, or damage or loss created by disruption to communications
						from the use of drone countermeasures), arising out of breach or
						failure of express or implied warranty, breach of contract,
						misrepresentation, negligence, strict liability in tort or
						otherwise. To the extent permitted by law, in no event will
						DroneShield’s aggregate liability under or in connection with the
						EULA and/or the Products for any Claim, action, or proceeding exceed
						the total amount actually paid by the End User to DroneShield for
						the specific item that directly caused the damage in the one-year
						period prior to the event out of which the Claim arose.
					</Text>
					<Text as='h2' sx={headerStyle}>
						10. Indemnification
					</Text>
					<Text>
						10.1. The End User agrees to defend, indemnify and hold harmless
						DroneShield, its Affiliates, employees, officers, directors,
						managers, agents, and contractors against any Claims, loss,
						judgments, damages, costs (including reasonable lawyers’ fees and
						disbursements) and settlements agreed to by End User arising out of
						or in relation to:
					</Text>
					<List sx={listStyle}>
						<ListItem>(a) the use of any Product</ListItem>
						<ListItem>
							(b) the failure of End User to comply with any provision of this
							EULA;
						</ListItem>
						<ListItem>
							(c) civil or criminal offenses in connection with its use or
							possession of the Products;
						</ListItem>
						<ListItem>
							(d) injury to a third party, including physical injury;
						</ListItem>
						<ListItem>
							(e) any claim with respect to privacy concerns, including, but not
							limited to, the video, and radio recording frequency recorded by
							the Products and stored in the Cloud, in connection with the use
							of the Product; and
						</ListItem>
						<ListItem>
							(f) any infringement claim arising from or in connection with:
							<List sx={subListStyle}>
								<ListItem>
									(i) modifications to the Product where such modification is
									made by the End User, or on behalf of the End User; and/or
								</ListItem>
								<ListItem>
									(ii) the End User’s combined use of the Product and/or Support
									Services or portion thereof with applications not supplied by
									DroneShield or in any way inconsistent with the Product’s
									intended use,
								</ListItem>
								provided however, that in relation to any of (a) -(d) of this
								clause:
							</List>
						</ListItem>
						<ListItem>
							(g) End User is promptly notified in writing of such claim;
						</ListItem>
						<ListItem>
							(h) End User shall have the sole control of the defense and/or
							settlement thereof;
						</ListItem>
						<ListItem>
							(i) DroneShield furnishes to End User on request any information
							in DroneShield’s possession or control for such defense;
						</ListItem>
						<ListItem>
							(j) DroneShield will not admit any such claim and/or make any
							payments with respect to such claim without the prior written
							consent of End User; and/or
						</ListItem>
						<ListItem>
							(k) DroneShield shall, at End User’s expense, cooperate with, and
							provide such assistance to End User in the defense of such claims
							as reasonably requested by End User.
						</ListItem>
					</List>
					<Text as='h2' sx={headerStyle}>
						11. Usage
					</Text>
					<Text>
						11.1. The End User acknowledges and understands that DroneShield’s
						drone detection equipment may be hosted through a third-party cloud
						service provider and as such the End User:
					</Text>
					<List sx={listStyle}>
						<ListItem>
							(a) consents to the flow of its data through the third-party cloud
							service used by DroneShield; and
						</ListItem>
						<ListItem>
							(b) acknowledges that it may experience loss of data or
							interruption in services hosted on the cloud, including complete
							outages, and his or her information may otherwise be comprised or
							breached.
						</ListItem>
					</List>
					<Text>
						The End User shall not hold DroneShield liable in any capacity for
						any loss of data, data that has been compromised or breached by
						third parties, or interruptions in service. End User acknowledges
						that the Cloud is located in several geographic locations and as
						such, End User consents to the flow of its data and information
						through several foreign countries as DroneShield deems necessary or
						as in the ordinary course of storage and transmission on the Cloud.
					</Text>
					<Text as='h2' sx={headerStyle}>
						12. Term and Termination; Survival
					</Text>
					<Text>
						12.1. This EULA continues in full force and effect until the later
						of:
					</Text>
					<List sx={listStyle}>
						<ListItem>
							(a) expiration or termination of the Initial Term;
						</ListItem>
						<ListItem>
							(b) expiration of the Subscription Term (if applicable); or
						</ListItem>
						<ListItem>
							(c) the completion of all paid services by DroneShield, unless
							otherwise terminated earlier as provided hereunder,
						</ListItem>
					</List>
					<Text>(“Termination Date”).</Text>
					<Text>
						12.2. On and from the Termination Date, End User’s right to use the
						Support Services terminates, this includes the right to access any
						additional Software updates. The End-User will however continue to
						have a licence to use the Products with the Software installed on
						the Hardware as at the expiration of the Initial Term of
						Subscription Term (whichever is applicable) on the terms set out in
						this EULA, save for the entitlement to Support Services as set out
						in clause 4 of this EULA.
					</Text>
					<Text>
						12.3. Clauses 1, 2, 3, 5, 6, 8, 9, 10, 11, 12, 13, 14, 15, and 17
						shall survive the termination of this EULA indefinitely.
					</Text>
					<Text>
						12.4. Either party may terminate this EULA immediately upon written
						notice at any time if:
					</Text>
					<List sx={listStyle}>
						<ListItem>
							(a) the other party commits a non-remediable material breach of
							the EULA; or
						</ListItem>
						<ListItem>
							(b) if one party notifies the other party in writing of any
							remediable material breach, and the party in breach fails to:
							<List sx={subListStyle}>
								<ListItem>(i) cure any remediable material breach; or</ListItem>
								<ListItem>
									(ii) provide a written plan of cure acceptable to the
									non-breaching party, within thirty (30) days of being notified
									in writing of such breach, except for breach of payment terms
									which shall have a ten (10) day cure period; or
								</ListItem>
							</List>
						</ListItem>
						<ListItem>(c) the other party ceases business operations;</ListItem>
						<ListItem>
							(d) except to the extent prohibited by applicable law, the other
							party becomes insolvent, generally stops paying its debts as they
							become due or seeks protection under any bankruptcy, receivership,
							trust deed, creditors arrangement, composition, or comparable
							proceeding, or if any such proceeding is instituted against the
							other (and not dismissed within ninety (90) days after
							commencement of one of the foregoing events);
						</ListItem>
						<ListItem>
							(e) it is not possible to obtain necessary export approvals in
							connection with the Products and Services contemplated under or in
							connection with this EULA.
						</ListItem>
					</List>
					<Text>
						12.5. DroneShield may terminate this EULA for convenience and cease
						all services hereunder upon ninety (90) days written notice.
					</Text>
					<Text>
						12.6. Upon notification of termination by either party, End User
						must uninstall any Products, cease using and destroy or return all
						copies of the Products to DroneShield, and to certify in writing
						that all known copies thereof, including backup copies, have been
						destroyed.
					</Text>
					<Text>
						12.7. Notwithstanding the foregoing, DroneShield suspends the
						services for any reason, the End User may be required to pay an
						additional fee for reinstatement.
					</Text>
					<Text as='h2' sx={headerStyle}>
						13. Government Restricted Rights
					</Text>
					<Text>13.1. For the purposes of U.S. acquisitions:</Text>
					<List sx={listStyle}>
						<ListItem>
							(a) the Products were developed exclusively at private expense and
							are commercial items, as defined in the Federal Acquisition
							Regulation (“FAR”). Accordingly, pursuant to FAR 12.211, 12.212
							and DFARS 227.7202, use, duplication and disclosure of the
							Products to, by, or for the U.S. Government (including, but not
							limited to, all branches, agencies, departments, and all other
							governmental entities) or a U.S. Government contractor or
							subcontractor is controlled by and subject solely to the terms and
							conditions set forth in this Agreement, which shall supersede any
							conflicting terms or conditions in any government order document.
						</ListItem>
						<ListItem>
							(b) to the extent a specific Product does not qualify as a
							commercial item, it still has the status of being developed
							exclusively at private expense and the governmental entity End
							User shall obtain no more than “LIMITED RIGHTS” or “RESTRICTED
							RIGHTS” in such Product as defined in FAR 52.227-14 and DFAR
							252.227-7013 et seq. or its successor.
						</ListItem>
					</List>
					<Text as='h2' sx={headerStyle}>
						14. Export
					</Text>
					<Text>
						14.1. Where the Products are subject to export controls of the
						Australia and other countries (“Export Controls”), provision of such
						Products is at all times subject to DroneShield being able to obtain
						the necessary export approvals. In addition, any further export,
						re-export or diversion contrary to the Export Controls by the End
						User (or any third party) is prohibited unless agreed otherwise by
						DroneShield and the End User complies with all applicable Export
						Controls.
					</Text>
					<Text>
						14.2. Once the Products pass through the United States, they become
						subject to U.S. export control laws which prohibits:
					</Text>
					<List sx={listStyle}>
						<ListItem>
							(a) export or re-export of certain Products or technology to
							specified countries or to a resident or national of those
							countries (“Prohibited Country” or “Prohibited Countries”).
						</ListItem>
						<ListItem>
							(b) export or re-export of the Products or technology to any
							person or entity on the U.S. Department of Commerce Denied Persons
							List, Entities List or Unverified List; the U.S. Department of
							State Debarred List; or any of the lists administered by the U.S.
							Department of Treasury, including lists of Specially Designated
							Nationals, Specially Designated Terrorists or Specially Designated
							Narcotics Traffickers (collectively, the “Lists”).
						</ListItem>
						<ListItem>
							(c) use of the Product or technology with chemical, biological or
							nuclear weapons, or with missiles (“Prohibited Uses”).
						</ListItem>
					</List>
					<Text>
						14.3. End User represents and warrants that it is not located in, or
						a resident or national, of any Prohibited Country; that it is not on
						any Lists; that it will not use the Product or technology for any
						Prohibited Uses; and that it will comply with Export Controls.
					</Text>
					<Text as='h2' sx={headerStyle}>
						15. Compliance with Laws
					</Text>
					<Text>
						15.1. End User must comply with all applicable laws, statutes,
						regulations, and ordinances and only use the Products for legitimate
						business purposes. End User irrevocably indemnifies and holds
						DroneShield harmless from and against any Claims, damages, losses,
						or obligations suffered or incurred by DroneShield arising from End
						User’s failure to so comply.
					</Text>
					<Text>
						15.2. End User must not use the Products, or allow the transfer,
						transmission, export, or re-export of the Products (including any
						portion thereof), in violation of any sanctions or export control
						laws or regulations administered by the Australian government or
						U.S. Commerce Department or any other government agency or the data
						privacy provisions of any applicable jurisdiction.
					</Text>
					<Text>
						15.3. End User must at all times conduct its activities in
						accordance with all applicable laws related to anti-bribery or
						anti-corruption legislation including the U.S. Foreign Corrupt
						Practices Act of 1977 and all applicable national, state, provincial
						or territorial anti-bribery and anti-corruption statutes. End User
						shall accordingly make no offer, payment or gift, will not promise
						to pay or give, and will not authorize, directly or indirectly, the
						promise or payment of, any money or anything of value to any
						DroneShield employee or agent, any government official, any
						political party or its officials, or any person while knowing or
						having reason to know that all or a portion of such money or item of
						value will be offered, given or promised for the purpose of
						influencing any decision or act to assist End User or DroneShield or
						otherwise obtaining any improper advantage or benefit.
					</Text>
					<Text>15.4. GDPR (EEA and UK)</Text>
					<Text>
						Where it is determined that the use of the Product is within the
						jurisdiction of the EU General Data Protection Regulation 2016/679
						or as applied by the United Kingdom (“GDPR”):
					</Text>
					<List sx={listStyle}>
						<ListItem>
							(a) End User agrees that DroneShield is a processor, and not a
							controller or joint controller, of any Personal Data, as defined
							under the GDPR, on your behalf;
						</ListItem>
						<ListItem>
							(b) DroneShield will follow all your lawful instructions in
							relation to the Personal Data; and{' '}
						</ListItem>
						<ListItem>
							(c) End User hereby authorizes DroneShield and its third party
							contractors to transfer and process your data globally, and if
							international to a country that is not under an adequacy
							mechanism, then for the EEA and Switzerland the Processor Standard
							Contractual Clauses Module 4 if you are a “Controller” and Module
							3 if you are a “Processor” are incorporated by reference, and for
							the UK the mandatory clauses of the international data transfer
							Addendum is additionally incorporated by reference.
						</ListItem>
					</List>
					<Text as='h2' sx={headerStyle}>
						16. Force Majeure
					</Text>
					<Text>
						Neither party will be liable for any failure or delay in performing
						a contractual obligation under this EULA that is due to causes
						beyond its reasonable control, such as natural disaster, outbreak of
						disease, governmental acts or omissions, laws or regulations, labor
						strikes or difficulties, transportation stoppages or slowdowns or
						the inability to procure parts or materials. These causes will not
						excuse End User from paying accrued amounts due to DroneShield
						through any available lawful means acceptable to DroneShield. If any
						of these causes continue to prevent or delay performance for more
						than ninety (90) days, DroneShield may terminate this Agreement,
						effective immediately upon notice to End User.
					</Text>
					<Text as='h2' sx={headerStyle}>
						17. General
					</Text>
					<Text>17.1. Marketing.</Text>
					<List sx={listStyle}>
						<ListItem>
							(a) End User consents to DroneShield periodically sending the End
							User messages of an informational or marketing nature for the
							purposes of customer service, technical support, and as a means of
							facilitating interactions with its end-users, and providing
							account information to related third-parties (e.g. End User’s
							reseller).
						</ListItem>
						<ListItem>
							(b) End User may choose to “opt-out” of receiving these messages
							or information sharing by sending an email to info@droneshield.com
							requesting the opt-out.
						</ListItem>
						<ListItem>
							(c) End User acknowledges and agrees that by “opting out” it will
							not receive emails containing messages concerning upgrades and
							enhancements to Products. DroneShield may however continue to send
							emails of a technical nature.
						</ListItem>
					</List>
					<Text>
						17.2. Suggestions. DroneShield owns any suggestions, ideas,
						enhancement requests, feedback, or recommendations provided by End
						User relating to the Products.
					</Text>
					<Text>
						17.3. Data aggregation. DroneShield may use non-identifying and
						aggregate usage and statistical information related to End User’s
						and its users’ use of the Products for its own purposes outside of
						the EULA.
					</Text>
					<Text>
						17.4. No assignment. End User must not transfer any of End User’s
						rights to use the Products or assign or novate this EULA to another
						person or entity, without first obtaining prior written approval
						from DroneShield.
					</Text>
					<Text>
						17.5. Notices. Any notice required or permitted under this EULA or
						required by law must be in writing and in English.
					</Text>
					<Text>
						17.6. A notice is regarded as given and received at the time set out
						in the table below, provided that notice is provided between 8:00am
						and 6:00pm (addressee’s time) on a business day in that location
						(Business Hours Period), then the Notice will be deemed given at the
						start of the next Business Hours Period.
					</Text>
					<TableContainer whiteSpace='normal' w='100%'>
						<Table variant='baseStyle'>
							<Thead>
								<Tr>
									<Th>Delivery Method</Th>
									<Th>When notice is regarded as given</Th>
								</Tr>
							</Thead>
							<Tbody>
								<Tr>
									<Td>By hand to the person</Td>
									<Td>When delivered to the person</Td>
								</Tr>
								<Tr>
									<Td>Registered post or airmail to the nominated address</Td>
									<Td>At 8:00am on the fifth Business Day after posting</Td>
								</Tr>
								<Tr>
									<Td>
										Sent by an internationally recognised overnight air courier,
										in each case properly posted and fully prepaid
									</Td>
									<Td>
										One (1) day after sending via an overnight air courier
										service
									</Td>
								</Tr>
								<Tr>
									<Td>By email to the nominated email address</Td>
									<Td>
										Two (2) hours after the time sent unless there is a read
										receipt, then when the read receipt is issued to the sender
									</Td>
								</Tr>
							</Tbody>
						</Table>
					</TableContainer>
					<Text>
						17.7. Notices sent to DroneShield must be sent to DroneShield, Level
						5, 126 Phillip Street, SYDNEY NSW 2000, Australia.{' '}
					</Text>
					<Text>
						17.8. Disputes. Any dispute arising out of or relating to this EULA
						or the breach thereof shall be governed by:
					</Text>
					<List sx={listStyle}>
						<ListItem>
							(a) the laws of New South Wales, Australia if DroneShield is
							DroneShield Group Pty Ltd, without regard to or application of; or
						</ListItem>
						<ListItem>
							(b) the laws of Virginia, USA if DroneShield is DroneShield LLC,
							without regard to or application of the UCITA and,
							<Text>(“Jurisdiction”) </Text>
						</ListItem>
					</List>

					<Text>
						the choice/conflict of laws, rules, or principles, and without
						regard to the United Nations Convention on the International Sale of
						Goods. The parties submit to the exclusive jurisdiction of the
						Jurisdiction.
					</Text>

					<Text>
						17.9. If there is a dispute arising out of or in connection with
						this EULA the parties must follow the following process:
					</Text>
					<List sx={listStyle}>
						<ListItem>
							(a) the party raising the dispute must notify the other party in
							writing that a dispute exists, with sufficient detail to enable
							the dispute to be considered (“Dispute Notice”);
						</ListItem>
						<ListItem>
							(b) the parties must then meet (which may be remotely by video
							conference) to discuss and attempt to resolve the dispute acting
							reasonably;
						</ListItem>
						<ListItem>
							(c) if the dispute is not resolved within thirty (30) days, the
							dispute is to be referred to each party’s Chief Executive Officer,
							and they must both attempt to resolve the dispute acting
							reasonably, and without prejudice within a further period of seven
							(7) days, or such other time as agreed by the parties; and
						</ListItem>
						<ListItem>
							(d) if the dispute remains unresolved, the dispute must be
							submitted to be finally resolved by arbitration in accordance with
							and subject to:
							<List sx={subListStyle}>
								<ListItem>
									(i) if the Jurisdiction is New South Wales, the Resolution
									Institute Arbitration Rules, and the seat of arbitration will
									be Sydney, New South Wales Australia; or
								</ListItem>
								<ListItem>
									(ii) if the Jurisdiction is Virginia, arbitration shall be
									administered by JAMS in Arlington, Virginia pursuant to its
									Comprehensive Arbitration Rules and Procedures and in
									accordance with the Expedited Procedures in those Rules if the
									Reseller is a United States entity or JAMS International
									Arbitration Rules if the Reseller is not a United States
									entity,
								</ListItem>
							</List>
							the number of arbitrators will be one (1) and the proceedings to
							be conducted in English. This arbitration clause does not affect
							the ability of either party to seek injunctive relief from a court
							of competent jurisdiction.
						</ListItem>
					</List>
					<Text>
						17.10. This EULA constitutes the entire agreement between the
						parties regarding the subject matter herein and the parties have not
						relied on any promise, representation, or warranty, express or
						implied, that is not in this EULA. Any waiver or modification of
						this EULA is only effective if it is in writing and signed by both
						parties. All pre-printed or standard terms of any End User’s
						purchase order or other business processing document have no effect.
						In the event of a conflict between the terms of this EULA and the
						terms of an Order, the terms of this EULA prevail. DroneShield is
						not obligated under any other agreements unless they are in writing
						and signed by an authorized representative of DroneShield.
					</Text>
					<Text>
						17.11. Severability. If the whole or any part of a provision of this
						EULA becomes invalid or unenforceable under the law of any
						jurisdiction, it is severed in that jurisdiction to the extent that
						it is invalid or unenforceable and whether it is in severable terms
						or not.
					</Text>
				</Flex>
			</Modal>
		</>
	)
}

export default LicenseAgreement
