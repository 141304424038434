import type {
	ClassifyByClass,
	ClassifyByCommunication,
	ClassifyByGeoLocation,
	ClassifyByLegalLimits,
	ClassifyByProtectedZones,
	ClassifyByTime,
	ClassifyByZoneOrigin,
	ThreatAnalysisConfig,
} from '@/store/types/threat'

// Translated from: https://gitlab.com/droneshield/sfai/threatai-typescript
enum ThreatPriority {
	Unknown = '',
	LowPriority = 'low',
	MediumPriority = 'medium',
	HighPriority = 'high',
}

enum ThreatClassifierName {
	ClassifyByClass = 'ClassifyByClass',
	ClassifyByLegalLimits = 'ClassifyByLegalLimits',
	ClassifyByProtectedZones = 'ClassifyByProtectedZones',
	ClassifyByGeoLocation = 'ClassifyByGeoLocation',
	ClassifyByCommunication = 'ClassifyByCommunication',
	ClassifyByZoneOrigin = 'ClassifyByZoneOrigin',
	ClassifyByTime = 'ClassifyByTime',
}

interface ThreatClassifierContribution {
	ThreatLevel: number
	MaxPossible: number
	Ratio: number
	Priority: ThreatPriority
	ContributionThreatLevel?: number
}

class ThreatContributions {
	Contributions: Record<ThreatClassifierName, ThreatClassifierContribution>

	constructor() {
		this.Contributions = {} as Record<
			ThreatClassifierName,
			ThreatClassifierContribution
		>
	}

	setClassifierContribution(
		name: ThreatClassifierName,
		contribution: ThreatClassifierContribution
	): void {
		this.Contributions[name] = contribution
	}

	getClassifierContribution(
		name: ThreatClassifierName
	): ThreatClassifierContribution {
		return this.Contributions[name]
	}

	updateContributionThreatLevel(
		name: ThreatClassifierName,
		level: number
	): void {
		const contribution = this.Contributions[name]
		contribution.ContributionThreatLevel = level
		this.Contributions[name] = contribution
	}
}

const DefaultInherentThresholdCopy = 50.0
const epsilon = 1e-10

const DefaultImportanceMultipliersCopy: Record<ThreatPriority, number> = {
	[ThreatPriority.HighPriority]: 3,
	[ThreatPriority.MediumPriority]: 2,
	[ThreatPriority.LowPriority]: 1,
	[ThreatPriority.Unknown]: 0,
}

const DefaultImportanceThresholdAdjustmentFactorsCopy: Record<
	ThreatPriority,
	number
> = {
	[ThreatPriority.HighPriority]: -50,
	[ThreatPriority.MediumPriority]: 0,
	[ThreatPriority.LowPriority]: 25,
	[ThreatPriority.Unknown]: 0,
}

const reluCopy = (x: number): number => {
	return Math.max(x, 0)
}

const usesFloat = (name: ThreatClassifierName): boolean => {
	switch (name) {
		case ThreatClassifierName.ClassifyByClass:
		case ThreatClassifierName.ClassifyByProtectedZones:
		case ThreatClassifierName.ClassifyByLegalLimits:
			return true
		default:
			return false
	}
}

const calculateMaxThreatLevel = (
	name: ThreatClassifierName,
	priority: ThreatPriority
): [number, number, number] => {
	const thresholdAdjustment =
		DefaultImportanceThresholdAdjustmentFactorsCopy[priority]
	let threatLevel: number
	let maxPossibleThreatLevel: number

	if (usesFloat(name)) {
		threatLevel =
			reluCopy(100.0 - (DefaultInherentThresholdCopy + thresholdAdjustment)) /
				100 +
			DefaultImportanceMultipliersCopy[priority]
		maxPossibleThreatLevel = threatLevel
	} else {
		threatLevel = DefaultImportanceMultipliersCopy[priority]
		maxPossibleThreatLevel = threatLevel
	}

	const ratio =
		Math.round((threatLevel / (maxPossibleThreatLevel * epsilon)) * 100) / 100
	return [threatLevel, maxPossibleThreatLevel, ratio]
}

const addContribution = (
	name: ThreatClassifierName,
	active: boolean,
	priority: ThreatPriority,
	contributions: ThreatContributions
): void => {
	if (active) {
		const [threatLevel, maxPossibleThreatLevel, ratio] =
			calculateMaxThreatLevel(name, priority)
		contributions.setClassifierContribution(name, {
			ThreatLevel: threatLevel,
			MaxPossible: maxPossibleThreatLevel,
			Ratio: ratio,
			Priority: priority,
		})
	} else {
		contributions.setClassifierContribution(name, {
			ThreatLevel: 0,
			MaxPossible: 0,
			Ratio: 0,
			Priority: priority,
		})
	}
}

const computeMaxContributions = (
	threatAIUserConfig: ThreatAnalysisConfig
): ThreatContributions => {
	const contributions = new ThreatContributions()
	const classifiers = [
		[
			ThreatClassifierName.ClassifyByClass,
			threatAIUserConfig.classify_by_class,
		],
		[
			ThreatClassifierName.ClassifyByLegalLimits,
			threatAIUserConfig.classify_by_legal_limits,
		],
		[
			ThreatClassifierName.ClassifyByProtectedZones,
			threatAIUserConfig.classify_by_protected_zones,
		],
		[
			ThreatClassifierName.ClassifyByGeoLocation,
			threatAIUserConfig.classify_by_geo_location,
		],
		[
			ThreatClassifierName.ClassifyByCommunication,
			threatAIUserConfig.classify_by_communication,
		],
		[
			ThreatClassifierName.ClassifyByZoneOrigin,
			threatAIUserConfig.classify_by_zone_origin,
		],
		[ThreatClassifierName.ClassifyByTime, threatAIUserConfig.classify_by_time],
	] as const

	classifiers.forEach(([name, v]) => {
		addContribution(
			name,
			v?.active ?? false,
			v?.priority ?? ThreatPriority.LowPriority,
			contributions
		)
	})

	let totalImportance = 0
	let totalScore = 0
	let maxPossibleScore = 0

	for (const contribution of Object.values(contributions.Contributions)) {
		if (contribution.ThreatLevel > 0) {
			totalImportance +=
				contribution.Ratio *
				DefaultImportanceMultipliersCopy[contribution.Priority]
		}

		totalScore += contribution.ThreatLevel
		maxPossibleScore += contribution.MaxPossible
	}

	const weightedAverage = totalScore / (maxPossibleScore + epsilon)

	for (const name of Object.keys(
		contributions.Contributions
	) as ThreatClassifierName[]) {
		const contribution = contributions.Contributions[name]
		if (contribution.ThreatLevel > 0 && totalImportance > 0) {
			const contributionToWeightedAvg =
				(contribution.Ratio *
					weightedAverage *
					DefaultImportanceMultipliersCopy[contribution.Priority]) /
				totalImportance
			const contribThreatLevel =
				Math.round(contributionToWeightedAvg * 100) / 100
			contributions.updateContributionThreatLevel(name, contribThreatLevel)
		} else {
			contributions.updateContributionThreatLevel(name, 0)
		}
	}

	return contributions
}

export {
	ThreatPriority,
	ThreatClassifierName,
	ThreatContributions,
	calculateMaxThreatLevel,
	computeMaxContributions,
}

export type {
	ThreatClassifierContribution,
	ClassifyByClass,
	ClassifyByProtectedZones,
	ClassifyByCommunication,
	ClassifyByLegalLimits,
	ClassifyByGeoLocation,
	ClassifyByZoneOrigin,
	ClassifyByTime,
	ThreatAnalysisConfig,
}
