import { memo, useCallback, useState } from 'react'
import MapMarker from '@Components/Map/MapMarker/MapMarker'
import { useGetSiteLiveQuery, selectSiteMarkers } from '@Store/sites/sitesWsApi'
import { useAppSelector } from '@/store'
import { selectUserZoneSettings } from '@Store/user/userSlice'

type MapMarkersProps = {
	siteId: number
}

const MapMarkers = ({ siteId }: MapMarkersProps) => {
	const zoneSettings = useAppSelector(selectUserZoneSettings)
	const displayMarkers = zoneSettings?.showMarkers

	const { markers, isSuccess } = useGetSiteLiveQuery(siteId, {
		skip: !displayMarkers,
		selectFromResult: ({ data, isSuccess }) => ({
			markers: selectSiteMarkers(data),
			isSuccess,
		}),
	})

	const [selectedMarkerId, setSelectedMarkerId] = useState<number | null>(null)

	const handleSelected = useCallback(
		(newId: number | null) => {
			setSelectedMarkerId((prevId) => (prevId === newId ? null : newId))
		},
		[setSelectedMarkerId]
	)

	return (
		displayMarkers &&
		isSuccess && (
			<>
				{markers?.map(({ id, lat, lng, nickname, number, colour }) => (
					<MapMarker
						key={id}
						latitude={Number(lat)}
						longitude={Number(lng)}
						number={Number(number)}
						nickname={nickname}
						color={colour}
						interactive
						id={id}
						onMouseDown={handleSelected}
						isSelected={selectedMarkerId === id}
					/>
				))}
			</>
		)
	)
}

export default memo(MapMarkers)
