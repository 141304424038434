// Packages
import { Flex } from '@chakra-ui/react'

// Relatives
import Notifications from './Notifications/Notifications'
import QuickActions from './QuickActions/QuickActions'

const RightPanel = ({ siteId }: { siteId: number }) => {
	return (
		<Flex
			className='RightPanel'
			flexDir='column'
			overflow='hidden'
			w='316px'
			h='calc(100% - 28px)'
		>
			<QuickActions />
			<Notifications siteId={siteId} />
		</Flex>
	)
}

export default RightPanel
