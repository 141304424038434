import { baseApi } from '@Store/baseApi'

import type { Site, Radar, RadarMaskZone } from '../types'
import type { RadarMaskSchema } from '@Components/Forms/RadarMaskForm/RadarMaskForm.schema'
import type { CoreApiError } from '../utils/errorUtils'
import { formatApiErrors } from '../utils/errorUtils'

type GetSiteRadarMasks = { siteId: Site['id'] }
type GetRadarMasks = { siteId: Site['id']; sensorId: Radar['id'] }
type GetRadarMask = {
	siteId: Site['id']
	sensorId: Radar['id']
	maskId: RadarMaskZone['id']
}
type CreateRadarMaskRequest = RadarMaskSchema & {
	siteId: Site['id']
	sensorId: Radar['id']
}
type DeleteRadarMaskRequest = {
	siteId: Site['id']
	sensorId: number
	maskId: number
}

export const radarMasksApi = baseApi
	.enhanceEndpoints({ addTagTypes: ['RadarMasks'] })
	.injectEndpoints({
		endpoints: (builder) => ({
			getSiteRadarMasks: builder.query<Array<RadarMaskZone>, GetSiteRadarMasks>(
				{
					query: ({ siteId }) => ({
						url: `/api/sites/${siteId}/radar_mask_zones`,
					}),
				}
			),
			getRadarMasks: builder.query<Array<RadarMaskZone>, GetRadarMasks>({
				query: ({ siteId, sensorId }) => ({
					url: `/api/sites/${siteId}/radars/${sensorId}/radar_mask_zones`,
				}),
				providesTags: ['RadarMasks'],
				transformResponse: (response: { radar_mask_zones: RadarMaskZone[] }) =>
					response.radar_mask_zones?.sort((a, b) => a.id - b.id),
			}),
			getRadarMask: builder.query<RadarMaskZone, GetRadarMask>({
				query: ({ siteId, sensorId, maskId }) => ({
					url: `/api/sites/${siteId}/radars/${sensorId}/radar_mask_zones/${maskId}`,
				}),
				providesTags: ['RadarMasks'],
				transformResponse: (response: { radar_mask_zone: RadarMaskZone }) =>
					response.radar_mask_zone,
			}),
			createRadarMask: builder.mutation<RadarMaskZone, CreateRadarMaskRequest>({
				query: ({ siteId, sensorId, ...mask }) => ({
					url: `/api/sites/${siteId}/radars/${sensorId}/radar_mask_zones`,
					method: 'POST',
					body: {
						radar_mask_zone: mask,
					},
				}),
				invalidatesTags: ['RadarMasks'],
				transformErrorResponse: (response: CoreApiError) =>
					formatApiErrors<RadarMaskZone>(response),
			}),
			updateRadarMask: builder.mutation<
				RadarMaskZone,
				Partial<RadarMaskZone> & {
					siteId: number
					sensorId: number
					maskId: number
				}
			>({
				query: ({ siteId, sensorId, maskId, ...mask }) => ({
					url: `/api/sites/${siteId}/radars/${sensorId}/radar_mask_zones/${maskId}`,
					method: 'PATCH',
					body: {
						radar_mask_zone: mask,
					},
				}),
				invalidatesTags: ['RadarMasks'],
				transformErrorResponse: (response: CoreApiError) =>
					formatApiErrors<RadarMaskZone>(response),
			}),
			deleteRadarMask: builder.mutation<
				{ message: string },
				DeleteRadarMaskRequest
			>({
				query: ({ siteId, sensorId, maskId }) => ({
					url: `/api/sites/${siteId}/radars/${sensorId}/radar_mask_zones/${maskId}`,
					method: 'DELETE',
				}),
				invalidatesTags: ['RadarMasks'],
			}),
		}),
	})

export const {
	useGetSiteRadarMasksQuery,
	useGetRadarMasksQuery,
	useGetRadarMaskQuery,
	useCreateRadarMaskMutation,
	useUpdateRadarMaskMutation,
	useDeleteRadarMaskMutation,
} = radarMasksApi
