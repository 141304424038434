// Base Redux Toolkit Query API
import { baseApi } from '@Store/baseApi'

// Types
import type { Site, NotificationRecipient } from '@Store/types'
import { type CoreApiError, formatApiErrors } from '@Store/utils/errorUtils'

// API types
type GetNotificationRecipientsRequest = Site['id']
type GetNotificationRecipientRequest = {
	siteId: Site['id']
	id: NotificationRecipient['id']
}
type CreateNotificationRecipientRequest = Pick<
	NotificationRecipient,
	'name' | 'address' | 'address_type' | 'zone_ids' | 'sensors_down'
> & {
	siteId: Site['id']
}
type DeleteNotificationRecipientRequest = {
	id: NotificationRecipient['id']
	siteId: Site['id']
}
type UpdateNotificationRecipientRequest = {
	id: NotificationRecipient['id']
	siteId: Site['id']
} & Partial<NotificationRecipient>
type SendTestNotificationRequest = {
	siteId: Site['id']
	id: NotificationRecipient['id']
}

// Notification Recipients API endpoints
export const notificationRecipientsApi = baseApi
	.enhanceEndpoints({
		addTagTypes: ['NotificationRecipient'],
	})
	.injectEndpoints({
		endpoints: (builder) => ({
			// https://au-dev.droneshield.xyz/api/docs#get-/api/sites/-site_id-/notification_recipients
			getNotificationRecipients: builder.query<
				NotificationRecipient[],
				GetNotificationRecipientsRequest
			>({
				query: (siteId) => ({
					url: `/api/sites/${siteId}/notification_recipients`,
				}),
				providesTags: ['NotificationRecipient'],
				transformResponse: (response: {
					notification_recipients: NotificationRecipient[] | null
				}) =>
					response.notification_recipients?.sort((a, b) => a.id - b.id) ?? [],
			}),
			// https://au-dev.droneshield.xyz/api/docs#get-/api/sites/-site_id-/notification_recipients/-id-
			getNotificationRecipient: builder.query<
				NotificationRecipient,
				GetNotificationRecipientRequest
			>({
				query: ({ siteId, id }) => ({
					url: `/api/sites/${siteId}/notification_recipients/${id}`,
				}),
				providesTags: ['NotificationRecipient'],
				transformResponse: (response: {
					notification_recipient: NotificationRecipient
				}) => response.notification_recipient,
			}),
			// https://au-dev.droneshield.xyz/api/docs#post-/api/sites/-site_id-/notification_recipients
			createNotificationRecipient: builder.mutation<
				NotificationRecipient,
				CreateNotificationRecipientRequest
			>({
				query: ({ siteId, ...recipient }) => ({
					url: `/api/sites/${siteId}/notification_recipients`,
					method: 'POST',
					body: {
						notification_recipient: recipient,
					},
				}),
				transformResponse: (response: {
					notification_recipient: NotificationRecipient
				}) => response.notification_recipient,
				transformErrorResponse: (response) =>
					formatApiErrors(response as CoreApiError),
				invalidatesTags: ['NotificationRecipient'],
			}),
			// https://au-dev.droneshield.xyz/api/docs#delete-/api/sites/-site_id-/notification_recipients/-id-
			deleteNotificationRecipient: builder.mutation<
				{ message: string },
				DeleteNotificationRecipientRequest
			>({
				query: ({ siteId, id }) => ({
					url: `/api/sites/${siteId}/notification_recipients/${id}`,
					method: 'DELETE',
				}),
				invalidatesTags: ['NotificationRecipient'],
			}),
			// https://au-dev.droneshield.xyz/api/docs#patch-/api/sites/-site_id-/notification_recipients/-id-
			updateNotificationRecipient: builder.mutation<
				NotificationRecipient,
				UpdateNotificationRecipientRequest
			>({
				query: ({ siteId, id, ...recipient }) => ({
					url: `/api/sites/${siteId}/notification_recipients/${id}`,
					method: 'PATCH',
					body: { notification_recipient: recipient },
				}),
				transformErrorResponse: (response: CoreApiError) =>
					formatApiErrors(response),
				invalidatesTags: (result, error) => {
					if (error) {
						return []
					}

					return ['NotificationRecipient']
				},
			}),
			// https://au-dev.droneshield.xyz/api/docs#post-/api/sites/-site_id-/notification_recipients/-id-/test
			sendTestNotification: builder.mutation<
				{ message: string },
				SendTestNotificationRequest
			>({
				query: ({ siteId, id }) => ({
					url: `/api/sites/${siteId}/notification_recipients/${id}/test`,
					method: 'POST',
				}),
			}),
		}),
	})

// Exported query & mutation hooks
export const {
	useGetNotificationRecipientsQuery,
	useGetNotificationRecipientQuery,
	useCreateNotificationRecipientMutation,
	useDeleteNotificationRecipientMutation,
	useUpdateNotificationRecipientMutation,
	useSendTestNotificationMutation,
} = notificationRecipientsApi
