// React
import { useNavigate, useParams } from 'react-router-dom'
// Chakra
import { IconButton } from '@chakra-ui/react'
import { HiMiniEye, HiMiniEyeSlash } from 'react-icons/hi2'
// Components
import Tooltip from '@UI/Tooltip/Tooltip'
import { useTranslation } from 'react-i18next'
// Types
type MapMarkerContentWrapperProps = {
	markerId: number
}

const MapMarkersActiveButtons = ({
	markerId,
}: MapMarkerContentWrapperProps) => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'mapsAndZones',
	})
	const navigate = useNavigate()
	const { siteId: siteIdParam, markerId: activeMarkerIdParam } = useParams()
	const siteId = Number(siteIdParam)
	const activeMarkerId = Number(activeMarkerIdParam)
	const isActive = activeMarkerId === markerId

	const handleToggleSelectedDevice = () => {
		const pathWithId = `/${siteId}/zones/markers/${markerId}`
		const toggledPathWithId = `/${siteId}/zones${!markerId ? '/markers/' + markerId : ''}`
		navigate(isActive ? toggledPathWithId : pathWithId)
	}

	return (
		<Tooltip
			label={
				isActive
					? t('tooltips.deselectMapMarker')
					: t('tooltips.selectMapMarker')
			}
		>
			<IconButton
				icon={isActive ? <HiMiniEyeSlash /> : <HiMiniEye />}
				variant='ghost'
				colorScheme='white'
				aria-label='highlight active map marker'
				isDisabled={false}
				onClick={handleToggleSelectedDevice}
			/>
		</Tooltip>
	)
}

export default MapMarkersActiveButtons
