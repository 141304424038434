export type FrequencyKeys =
	| '433'
	| '868'
	| '915'
	| '1200'
	| '2400'
	| '5200'
	| '5800'

export const DEFAULT_FREQUENCY_BAND = '2400MHz'
export const DEFAULT_FREQUENCY_BAND_KEY = '2400'
export const DEFAULT_VENDOR = 'DJI SKY XIA'

// Provided by brooke.wells@droneshield.com:
export const DEFAULT_FREQUENCY: Record<FrequencyKeys, number> = {
	'433': 437480000,
	'868': 862985000,
	'915': 919480000,
	'1200': 1078255000,
	'2400': 2439975000,
	'5200': 5224485000,
	'5800': 5819500000,
}

// Provided by brooke.wells@droneshield.com:
export const DEFAULT_FREQUENCY_TOLERANCE: Record<FrequencyKeys, number> = {
	433: 4080000,
	868: 2682000, // @todo: this needs to be clarified
	915: 7680000,
	1200: 29994000,
	2400: 30678000,
	5200: 52284000,
	5800: 62682000,
}

export const isValidFrequency = (s: unknown): s is FrequencyKeys => {
	return Object.keys(FREQUENCY_LIMITS).includes(s as FrequencyKeys)
}

// See: https://gitlab.com/droneshield/project-ddi/-/blob/39f7b5beeaa1a7b6bf302c21073d5b3a29dbf188/configs/disruptor/disruptor_capabilities.json
export const FREQUENCY_LIMITS: Record<
	FrequencyKeys,
	{ min: number; max: number }
> = {
	433: {
		min: 400_000_000,
		max: 470_000_000,
	},
	868: { min: 859_500_000, max: 873_500_000 },
	915: { min: 907_000_000, max: 932_000_000 },
	1200: { min: 1_010_000_000, max: 1_360_000_000 },
	2400: {
		min: 2_370_000_000,
		max: 2_510_000_000,
	},
	5200: { min: 5_100_000_000, max: 5_621_000_000 },
	5800: { min: 5_645_000_000, max: 5_945_000_000 },
}
