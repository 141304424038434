import MapMarkerIconSelected from '@Components/Map/MapMarker/MapMarkerIconSelected'

const MapMarkerIcon = ({
	color,
	number,
	isClicked,
}: {
	color: string
	number: number
	isClicked: boolean
}) => {
	return (
		<>
			<div className='c2-map-marker'>
				<svg
					width='38'
					height='54'
					viewBox='0 0 38 54'
					fill={color}
					xmlns='http://www.w3.org/2000/svg'
				>
					<path d='M18.9999 0.333984C8.67992 0.333984 0.333252 8.68065 0.333252 19.0007C0.333252 23.6407 1.66658 27.9873 4.09325 31.9073C6.62659 36.014 9.95992 39.534 12.5199 43.6407C13.7733 45.6407 14.6799 47.5073 15.6399 49.6673C16.3333 51.134 16.8933 53.6673 18.9999 53.6673C21.1066 53.6673 21.6666 51.134 22.3333 49.6673C23.3199 47.5073 24.1999 45.6407 25.4533 43.6407C28.0133 39.5607 31.3466 36.0406 33.8799 31.9073C36.3333 27.9873 37.6666 23.6407 37.6666 19.0007C37.6666 8.68065 29.3199 0.333984 18.9999 0.333984Z' />
				</svg>
				<div className='c2-map-marker-number' data-testid='map-marker-icon'>
					{number}
				</div>
			</div>
			{isClicked && <MapMarkerIconSelected />}
		</>
	)
}

export default MapMarkerIcon
