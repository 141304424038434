// Packages
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
	Table,
	TableContainer,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react'

// Alias
import Modal from '@UI/Modals/Modal'
import { formatDateTz } from '@/utils/dates'

// Types
import type { SiteLog } from '@Store/types'

type ChangesModalProps = {
	isOpen: boolean
	onClose: () => void
	objectChanges: SiteLog['object_changes']
	siteTimeZone: string
}

const ChangesModal = ({
	isOpen,
	onClose,
	objectChanges,
	siteTimeZone,
}: ChangesModalProps) => {
	const { t } = useTranslation('pages', {
		keyPrefix: 'analytics.siteLogs.modal',
	})

	const objectChangesParsed = useMemo(() => {
		const objectChangesAux = { ...objectChanges }
		Object.keys(objectChanges).forEach((key) => {
			// Dates
			if (['CreatedAt'].includes(key)) {
				objectChangesAux[key] = [
					formatDateTz(objectChanges[key][0] ?? '', siteTimeZone),
					formatDateTz(objectChanges[key][1] ?? '', siteTimeZone),
				]
			}

			return key
		})

		return objectChangesAux
	}, [objectChanges, siteTimeZone])

	return (
		<Modal headerText='Changes' isOpen={isOpen} onClose={onClose} size='3xl'>
			<TableContainer pb={5} m={2}>
				<Table data-testid='changes-table' variant='baseStyle' size='lg'>
					<Thead>
						<Tr>
							<Th>{t('attribute')}</Th>
							<Th>{t('oldValue')}</Th>
							<Th>{t('newValue')}</Th>
						</Tr>
					</Thead>
					<Tbody>
						{/* An example of a log change is {A: ['b', 'c']}. This converts
             the data structure into a table row where 'A', 'b', and 'c' are the cells.  */}
						{objectChangesParsed &&
							Object.entries(objectChangesParsed).map(([key, values]) => (
								<Tr key={key}>
									<Td>{key}</Td>
									{values.map((value, index) => (
										<Td key={index}>{value}</Td>
									))}
								</Tr>
							))}
					</Tbody>
				</Table>
			</TableContainer>
		</Modal>
	)
}

export default ChangesModal
