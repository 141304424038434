import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { distance } from '@turf/turf'

import { Flex, Table, Tbody, Tr, Td } from '@chakra-ui/react'
import Button from '@UI/Button/Button'
import Headings from '@UI/Headings/Headings'

import { useCalibrateCameraMutation } from '@Store/cameras/camerasApi'

import type { Detection } from '@Store/types'
import type { Feature, Point, GeoJsonProperties } from 'geojson'

type ConfirmRadarReadingProps = {
	setSubstep: (susbstep: number) => void
	detection?: Detection
	sensorCoordinates: Feature<Point, GeoJsonProperties>
}

const ConfirmRadarReading = ({
	setSubstep,
	detection,
	sensorCoordinates,
}: ConfirmRadarReadingProps) => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'siteInstallations.cameraCalibration',
	})

	const { sensorId: sensorIdParam, siteId: siteIdParam } = useParams()
	const siteId = Number(siteIdParam)
	const sensorId = Number(sensorIdParam)

	const [calibrateCamera] = useCalibrateCameraMutation()

	const detectionCoordinates = [
		detection?.longitude ?? 0,
		detection?.latitude ?? 0,
	]

	const detectionDistance = Math.abs(
		distance(sensorCoordinates, detectionCoordinates) * 1000
	)

	const handleConfirm = () => {
		setSubstep(2)
		calibrateCamera({ cameraId: sensorId, siteId, action: 'start' })
	}

	return (
		<Flex direction='column' gap='10px'>
			<Headings.FieldHeading title={t('headers.confirmRadar')} />
			<Table variant='baseStyle'>
				<Tbody>
					<Tr h='48px'>
						<Td w='142px' color='label_color' fontWeight={500}>
							{t('distance')}
						</Td>
						<Td>{detectionDistance.toFixed(2)}</Td>
					</Tr>
					<Tr h='48px'>
						<Td color='label_color' fontWeight={500}>
							{t('height')}
						</Td>
						<Td>{detection?.height_offset_to_site.toFixed(2)}</Td>
					</Tr>
				</Tbody>
			</Table>
			<Button
				label={t('buttons.confirm')}
				variant='outline'
				size='xs'
				testId='confirm-radar-readings'
				onClick={handleConfirm}
				w='70px'
			/>
		</Flex>
	)
}

export default ConfirmRadarReading
