// Packages
import { Flex } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

// Alias
import FetchError from '@UI/FetchError/FetchError'
import Headings from '@UI/Headings/Headings'
import Skeleton from '@UI/Skeleton/Skeleton'
import { useGetDisruptionsSummaryQuery } from '@Store/analytics/analyticsApi'
import BarChart from '@/components/_UI/Charts/BarChart'

// Relatives
import DisruptionsBarChartTooltip from './DisruptionsBarChartTooltip'

type DisruptionsBarChartProps = {
	siteId: number
	startTime: string
	endTime: string
}

const DisruptionsBarChart = ({
	siteId,
	startTime,
	endTime,
}: DisruptionsBarChartProps) => {
	const { t } = useTranslation('pages', {
		keyPrefix: 'analytics.disruptionsSummary',
	})

	const { isSuccess, isError, isLoading, isFetching, refetch, data } =
		useGetDisruptionsSummaryQuery({
			siteId: Number(siteId),
			format: 'graph',
			startTime: startTime,
			endTime: endTime,
		})

	const chartData = Object.entries(data?.by_day ?? {}).map(([day, data]) => ({
		label: day,
		day,
		Automatic: data['Automatic'] ?? 0,
		Manual: data['Manual'] ?? 0,
	}))

	return (
		<Skeleton isLoaded={!isLoading && !isFetching} w='100%'>
			{isSuccess && (
				<Flex
					h='312px'
					minWidth={0}
					direction='column'
					gap='16px'
					marginBlockEnd={8}
					alignItems='center'
				>
					<Headings.ChartHeading title={t('disruptionsOverTime')} />
					<BarChart
						data={chartData}
						indexBy='label'
						keys={['Automatic', 'Manual']}
						margin={{ top: 30, right: 30, bottom: 68, left: 60 }}
						padding={0.5}
						legendY={t('yAxisLabel')}
						legendX={t('xAxisLabel')}
						includeLegend
						tooltip={({ id, color, value, data, indexValue }) => (
							<DisruptionsBarChartTooltip
								id={id}
								color={color}
								value={value}
								data={data}
							/>
						)}
					/>
				</Flex>
			)}
			{isError && <FetchError refetch={refetch} entity={t('entities.chart')} />}
		</Skeleton>
	)
}

export default DisruptionsBarChart
