// React
import { memo, useMemo, useRef } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import { Marker, useMapEvent } from 'react-leaflet'
import L, { type LatLngExpression, type LatLngLiteral } from 'leaflet'
// Components
import MarkerPopup from '@UI/MarkerPopup/MarkerPopup'
import MapMarkerIcon from './MapMarkerIcon'

// Types
type MapMarkerProps = {
	onDragEnd?: (position: LatLngLiteral) => void
	latitude: number
	longitude: number
	draggable?: boolean
	number: number
	nickname: string
	color: string
	interactive?: boolean
	id?: number
	onMouseDown?: (id: number | null) => void
	isSelected?: boolean
}

const MapMarker = ({
	latitude,
	longitude,
	onDragEnd,
	number,
	nickname,
	color,
	draggable,
	id,
	onMouseDown,
	isSelected = false,
}: MapMarkerProps) => {
	const memorizedPosition: LatLngExpression = useMemo(() => {
		return [latitude, longitude]
	}, [latitude, longitude])

	const markerRef = useRef<L.Marker>(null)
	const marker = markerRef.current

	const markerIcon = useMemo(() => {
		return new L.DivIcon({
			html: renderToStaticMarkup(
				<MapMarkerIcon isClicked={isSelected} color={color} number={number} />
			),
			iconSize: [40, 52],
			iconAnchor: [20, 52],
			className: '',
		})
	}, [color, number, isSelected])

	// Events
	const handleClick = () => {
		if (typeof onMouseDown === 'function' && id) {
			onMouseDown(id)
		}
	}
	const handleDragEnd = () => {
		if (marker) {
			if (typeof onDragEnd === 'function') onDragEnd(marker.getLatLng().wrap())
			// Reopen the popup after the drag ends
			if (isSelected) marker.openPopup()
		}
	}
	const handleAdd = (e: L.LeafletEvent) => {
		if (e.target && isSelected) e.target.openPopup()
	}
	// Close tooltip and remove selection icon if its opened
	useMapEvent('click', () => {
		if (isSelected) {
			if (typeof onMouseDown === 'function') onMouseDown(null)
		}
	})

	return (
		<Marker
			ref={markerRef}
			position={memorizedPosition}
			draggable={draggable}
			eventHandlers={{
				click: handleClick,
				dragend: handleDragEnd,
				add: handleAdd,
			}}
			alt='Map Marker'
			icon={markerIcon}
		>
			<MarkerPopup title={nickname} />
		</Marker>
	)
}

export default memo(MapMarker)
