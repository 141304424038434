import { useTranslation } from 'react-i18next'

import { Button, Flex, useDisclosure } from '@chakra-ui/react'
import { MdDelete as DeleteIcon } from 'react-icons/md'
import DeleteModal from '@UI/Modals/DeleteModal'
import CancelModal from '@UI/Modals/CancelModal'

export const DeleteDeviceButton = () => {
	const { t } = useTranslation('forms', { keyPrefix: 'global.buttons' })

	return (
		<Flex mt={4}>
			<Button
				colorScheme='error'
				mt={3}
				variant='ghost'
				leftIcon={<DeleteIcon />}
				size='sm'
				fontSize='14px'
				data-testid='delete'
			>
				{t('delete')}
			</Button>
		</Flex>
	)
}

export const FormDeleteButton = ({
	handleDelete,
	name,
	headerText,
	fromText,
	label,
	testId,
}: {
	handleDelete: any
	name: string
	headerText?: string
	fromText?: string
	label?: string
	testId?: string
}) => {
	const { t } = useTranslation('forms', { keyPrefix: 'global.buttons' })
	const { isOpen, onOpen, onClose } = useDisclosure()
	const title = label ? label : t('delete')
	return (
		<>
			<Flex>
				<Button
					colorScheme='error'
					mt={3}
					variant='ghost'
					leftIcon={<DeleteIcon />}
					size='sm'
					fontSize='14px'
					onClick={onOpen}
					data-testid={testId}
				>
					{title}
				</Button>
			</Flex>
			<DeleteModal
				isOpen={isOpen}
				onClose={onClose}
				handleDelete={handleDelete}
				name={name}
				headerText={headerText}
				fromText={fromText}
			/>
		</>
	)
}

type FormButtonsProps = {
	isSubmitting: boolean
	isDirty: boolean
	isDisabled?: boolean
	handleCancel: () => void
	showCancelButton?: boolean
}

const CancelButton = ({
	isDirty,
	handleCancel,
}: Pick<FormButtonsProps, 'isDirty' | 'handleCancel'>) => {
	const { t } = useTranslation('forms', { keyPrefix: 'global' })
	const { isOpen, onOpen, onClose } = useDisclosure()
	const handleButtonCancel = () => {
		if (!isDirty) handleCancel()
		if (isDirty) {
			onOpen()
		}
	}
	return (
		<>
			<Button
				mt={4}
				type='button'
				variant='ghost'
				onClick={handleButtonCancel}
				data-testid='cancel'
			>
				{t('buttons.cancel')}
			</Button>
			<CancelModal
				isOpen={isOpen}
				onClose={onClose}
				handleCancel={handleCancel}
				headerText={t('modals.cancel.header')}
				modalBodyText={t('modals.cancel.body')}
			/>
		</>
	)
}

export const FormButtons = ({
	isSubmitting,
	isDirty,
	isDisabled,
	handleCancel,
	showCancelButton = true,
}: FormButtonsProps) => {
	const { t } = useTranslation('forms', { keyPrefix: 'global.buttons' })

	return (
		<Flex marginBlockStart={6}>
			<Button
				marginBlock={4}
				marginInlineEnd={2}
				isLoading={isSubmitting}
				isDisabled={!isDirty || isDisabled}
				type='submit'
				data-testid='save'
			>
				{t('save')}
			</Button>
			{showCancelButton && (
				<CancelButton handleCancel={handleCancel} isDirty={isDirty} />
			)}
		</Flex>
	)
}
