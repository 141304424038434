import { useTransition } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// Redux
import { useUpdateNotificationRecipientMutation } from '@Store/notifications/notificationRecipientsApi'
import type { NotificationRecipient } from '@Store/types'

// Chakra
import { Switch } from '@chakra-ui/react'

// Store
import { useAppDispatch } from '@/store'
import { addToast } from '@/store/ui/uiSlice'

type RowActionsProps = {
	recipient: NotificationRecipient
}

const AlertRecipientsToggle = ({ recipient }: RowActionsProps) => {
	const { siteId: siteIdParam } = useParams()
	const siteId = Number(siteIdParam)
	const dispatch = useAppDispatch()
	const { t } = useTranslation('pages', {
		keyPrefix: 'alertSettings.alertRecipientsTable',
	})

	const [updateRecipient] = useUpdateNotificationRecipientMutation()
	const [isPending, startTransition] = useTransition()

	const handleToggle = async () => {
		startTransition(() => {
			updateRecipient({
				siteId,
				id: recipient.id,
				enabled: !recipient.enabled,
			})
				.unwrap()
				.catch((error) => {
					dispatch(
						addToast({
							id: recipient.id,
							siteId,
							type: 'error',
							title: t('toast.updateErrorTitle'),
							description: error?.error,
							error: error,
						})
					)
				})
		})
	}

	return (
		<Switch
			isDisabled={isPending}
			isChecked={recipient.enabled}
			onChange={handleToggle}
		/>
	)
}

export default AlertRecipientsToggle
