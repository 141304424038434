// React
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
// Chakra
import { Flex } from '@chakra-ui/react'
import { MdEdit } from 'react-icons/md'
import { HiMiniEye, HiMiniEyeSlash } from 'react-icons/hi2'
// Components
import IconButton from '@UI/IconButton/IconButton'
import RfFilterDeleteModal from './Modals/RfFilterDeleteModal'
// Store
import {
	selectIsSiteCalibrationMode,
	useGetSiteLiveQuery,
} from '@Store/sites/sitesWsApi'
import { useAppDispatch, useAppSelector } from '@/store'
import {
	deleteSelectedSensorFiltersIds,
	selectSelectedRfFiltersIds,
	updateSelectedSensorFiltersIds,
} from '@Store/ui/uiSlice'
// Types
import type { RfSensorFilter } from '@Store/types'

const RfFiltersTableActions = ({
	rfFilter,
	isWhiteListed,
	showSelectButton,
	isDsx = false,
}: {
	rfFilter: RfSensorFilter
	isWhiteListed: boolean
	showSelectButton?: boolean
	isDsx?: boolean
}) => {
	const navigate = useNavigate()
	const { siteId, installationId, sensorId } = useParams()
	// Translations
	const { t } = useTranslation('panels', { keyPrefix: 'rfFilters.table' })

	const { isCalibrationMode } = useGetSiteLiveQuery(Number(siteId), {
		selectFromResult: ({ data }) => ({
			isCalibrationMode: selectIsSiteCalibrationMode(data),
		}),
	})

	const baseFilterUrl = `/${siteId}/installation/${installationId}/${
		isDsx ? 'rf_sensors_dsx' : 'rf_sensors'
	}/${sensorId}/rf_filters`

	const dispatch = useAppDispatch()

	const handleEditButton = (filterId: number) => {
		navigate(
			`${baseFilterUrl}/${filterId}/settings${
				isWhiteListed ? '?whitelist' : ''
			}`
		)
	}

	const handleToggleFilter = (filter: RfSensorFilter) => {
		dispatch(updateSelectedSensorFiltersIds(filter.id))
	}
	useEffect(() => {
		return () => {
			dispatch(deleteSelectedSensorFiltersIds([]))
		}
	}, [dispatch])
	// Select/Deselect filters
	const selectedFilters = useAppSelector(selectSelectedRfFiltersIds)
	const isActive = selectedFilters.some((id) => id === rfFilter.id)

	return (
		<Flex gap={0} justifyContent='center'>
			{showSelectButton && (
				<IconButton
					aria-label='edit'
					testId='show-rf-filter'
					icon={isActive ? <HiMiniEye /> : <HiMiniEyeSlash />}
					onClick={() => handleToggleFilter(rfFilter)}
					tooltip={t(
						`${isActive ? 'un' : ''}select${
							isWhiteListed ? 'Whitelist' : 'Filter'
						}`
					)}
					isDisabled={!rfFilter.active}
				/>
			)}
			<IconButton
				aria-label='edit'
				testId='edit-rf-filter'
				icon={<MdEdit />}
				onClick={() => handleEditButton(rfFilter.id)}
				isDisabled={!isCalibrationMode}
				tooltip={isCalibrationMode ? '' : t('calibration')}
			/>
			<RfFilterDeleteModal rfFilter={rfFilter} />
		</Flex>
	)
}

export default RfFiltersTableActions
