const provideAglCalculations = (
	minValue: number,
	maxValue: number,
	min: number,
	max: number
) => {
	// establish the range
	// e.g -3000 to 3000 is a 6000 range
	const range = maxValue - minValue

	// rejig the min/max so they be relative to range
	const minVal = range / 2 + min
	const maxVal = range / 2 + max
	return [range, minVal, maxVal]
}

const calculateLinePositions = (range: number, n: number) => {
	const positions = []
	const startValue = range / n
	for (let i = 0; i < n - 1; i++) {
		const y = ((i + 1) * startValue) / range
		positions.push(y * 100)
	}
	return positions
}

export { provideAglCalculations, calculateLinePositions }
