import { createContext, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import type { EventFilters } from '@Store/analytics/types'
import type { OnChangeFn, ColumnFiltersState } from '@tanstack/react-table'

type EventFiltersContext = {
	columnFilters: ColumnFiltersState
	setColumnFilters?: OnChangeFn<ColumnFiltersState>
	globalFilter: string
	setGlobalFilter?: OnChangeFn<string>
	filterParams: EventFilters
}

export const useEventFilter = () => {
	const context = useContext<EventFiltersContext | undefined>(
		EventFilterContext
	)
	if (context === undefined) {
		throw new Error('useEventFilter must be used within an EventFilterProvider')
	}
	return context
}

export const EventFilterContext = createContext<
	EventFiltersContext | undefined
>(undefined)

export const EventFilterProvider = ({
	children,
}: {
	children: React.ReactNode
}) => {
	const { t } = useTranslation('pages', {
		keyPrefix: 'analytics.trackSummary',
	})

	const defaultFilters = t('defaultFilters', {
		returnObjects: true,
	}) as ColumnFiltersState

	const [columnFilters, setColumnFilters] =
		useState<ColumnFiltersState>(defaultFilters)
	const [globalFilter, setGlobalFilter] = useState('')

	const filters = useMemo(() => {
		return columnFilters.reduce(
			(acc, curr) => ({ ...acc, [curr.id]: curr.value }),
			{
				probability: '',
				threat_level: '',
				false_positive: '',
				state: '',
				frequency: '',
				vendor: '',
				protocol: '',
				detected_by: '',
				confirmed_by: '',
			}
		)
	}, [columnFilters])

	const getConfirmedByFilter = (sensorType: string) => {
		const filterValue = filters.confirmed_by
		if (filterValue?.split(',').includes(sensorType)) {
			return true
		}
		return undefined
	}

	const filterParams: EventFilters = {
		filter_by_target_or_serial: globalFilter,
		filter_by_probability: filters.probability,
		filter_by_threat_level: filters.threat_level,
		filter_by_false_positive: filters.false_positive,
		filter_by_state: filters.state,
		filter_by_frequency: filters.frequency,
		filter_by_vendor: filters.vendor,
		filter_by_protocol: filters.protocol,
		filter_by_rf_confirmed: getConfirmedByFilter('rf'),
		filter_by_radar_confirmed: getConfirmedByFilter('radar'),
		filter_by_camera_confirmed: getConfirmedByFilter('camera'),
		filter_by_drone_locator_confirmed: getConfirmedByFilter('droneLocator'),
		filter_by_detected_by: filters.detected_by,
	}

	return (
		<EventFilterContext.Provider
			value={{
				columnFilters,
				setColumnFilters,
				globalFilter,
				setGlobalFilter,
				filterParams,
			}}
		>
			{children}
		</EventFilterContext.Provider>
	)
}
