import { Flex } from '@chakra-ui/react'

import DetectionEventDetailsTable from './EventDetailsTable/EventDetailsTable'
import TrackSummaryCharts from './TrackSummaryCharts'
import HeatMap from './HeatMap'
import FlightsBarChart from './FlightsBarChart'
import EventDetails from './EventDetails'
import ErrorBoundary from '@Components/App/ErrorHandling/ErrorBoundary'

import { EventFilterProvider } from './EventDetailsTable/EventFilterContext'

type TrackSummaryProps = {
	siteId: number
	startTime: string
	endTime: string
}

const TrackSummary = ({ siteId, startTime, endTime }: TrackSummaryProps) => (
	<EventFilterProvider>
		<Flex direction='column' gap='36px'>
			<ErrorBoundary>
				<DetectionEventDetailsTable
					siteId={siteId}
					startTime={startTime}
					endTime={endTime}
				/>
			</ErrorBoundary>
			<Flex alignItems='center' gap='56px'>
				<EventDetails siteId={siteId} startTime={startTime} endTime={endTime} />
				<TrackSummaryCharts
					siteId={siteId}
					startTime={startTime}
					endTime={endTime}
				/>
			</Flex>
			<HeatMap siteId={siteId} startTime={startTime} endTime={endTime} />
			<FlightsBarChart
				siteId={siteId}
				startTime={startTime}
				endTime={endTime}
			/>
		</Flex>
	</EventFilterProvider>
)

export default TrackSummary
