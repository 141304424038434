import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
	Flex,
	IconButton,
	Table,
	TableContainer,
	Tbody,
	Td,
	Tr,
	Text,
	useDisclosure,
	Thead,
	Th,
} from '@chakra-ui/react'
import { MdDelete, MdEdit } from 'react-icons/md'

import DeleteModal from '@Components/_UI/Modals/DeleteModal'
import ZoneSettingsActiveButton from './ZoneSettingsActiveButtons'
import { useDeleteZoneMutation } from '@Store/zones/zonesApi'
import type { Zone } from '@Store/types'

type ZoneSettingsTableProps = {
	zones: Zone[]
}

const ZoneSettingsTable = ({ zones }: ZoneSettingsTableProps) => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'mapsAndZones.zoneSettings',
	})

	return (
		<TableContainer>
			<Table
				size='sm'
				maxW='520px'
				mt={2}
				data-testid='zones-table'
				variant='filledStyle'
			>
				<Thead>
					<Tr>
						<Th>{t('zoneSettingsTableHeaders.name')}</Th>
						<Th>{t('zoneSettingsTableHeaders.zoneType')}</Th>
						<Th>{t('zoneSettingsTableHeaders.actions')}</Th>
					</Tr>
				</Thead>
				<Tbody>
					{zones.map(({ id, name, zone_type }) => (
						<Tr key={id}>
							<Td w='220px'>
								<Text noOfLines={1} whiteSpace='pre-line'>
									{name}
								</Text>
							</Td>
							<Td w='220px'>{t(`zoneType.${zone_type}`)}</Td>
							<Td maxW='140px'>
								<ActionButtons id={id} name={name} />
							</Td>
						</Tr>
					))}
				</Tbody>
			</Table>
		</TableContainer>
	)
}

// Note: very similar to RadarMasks ActionButtons - good candidate to abstract
// for all tables
const ActionButtons = ({ name, id }: { name: string; id: number }) => {
	const { siteId: siteIdParam } = useParams()
	const siteId = Number(siteIdParam)

	const navigate = useNavigate()
	const { t } = useTranslation('forms', { keyPrefix: 'zoneForm' })

	const [zoneToDelete, setZoneToDelete] = useState<{
		id: number
		name: string
	} | null>(null)

	const [deleteZone] = useDeleteZoneMutation()

	const { isOpen, onOpen, onClose } = useDisclosure()

	const handleOpenDeleteModal = () => {
		onOpen()
		setZoneToDelete((prev) => ({ ...prev, id, name }))
	}

	const handleDelete = async () => {
		try {
			if (zoneToDelete) {
				await deleteZone({
					siteId,
					zoneId: zoneToDelete.id,
				})
			}
		} catch (error) {
			console.error('Delete zone error', error)
		}
	}

	const handleCloseDeleteModal = () => {
		onClose()
		setZoneToDelete(null)
	}

	const handleEditZone = () => navigate(`/${siteId}/zone/${id}/settings`)

	return (
		<>
			<Flex gap={0} justifyContent='center'>
				<ZoneSettingsActiveButton zoneId={id} />
				<IconButton
					variant='ghost'
					colorScheme='white'
					aria-label='edit'
					icon={<MdEdit />}
					onClick={() => handleEditZone()}
					data-testid={`${name}-edit`}
				/>
				<IconButton
					variant='ghost'
					colorScheme='white'
					aria-label='delete'
					icon={<MdDelete />}
					onClick={() => handleOpenDeleteModal()}
					data-testid={`${name}-delete`}
				/>
			</Flex>
			<DeleteModal
				isOpen={isOpen}
				handleDelete={handleDelete}
				onClose={handleCloseDeleteModal}
				name={zoneToDelete?.name || ''}
				headerText={t('deleteZone')}
				fromText={t('deleteFrom')}
			/>
		</>
	)
}

export default ZoneSettingsTable
