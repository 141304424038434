import { forwardRef } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import {
	FormControl,
	FormLabel,
	FormErrorMessage,
	Input,
} from '@chakra-ui/react'

import PhoneInput, { type Country, type Value } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

import type { RegisterType } from '@Components/FormElements/types/RegisterType'
import type { FormElementProps } from '@Components/FormElements/types/FormElementProps'

type PhoneNumberInputProps = FormElementProps &
	RegisterType<string> & { countryCodeField: string }

const CustomPhoneInput = forwardRef<HTMLInputElement>(({ ...props }, ref) => {
	return <Input type='tel' ref={ref} {...props} />
})
CustomPhoneInput.displayName = 'CustomPhoneInput'

const PhoneNumberInput = ({
	id,
	title,
	register,
	countryCodeField,
	error,
	disabled = false,
	testId,
	required,
}: PhoneNumberInputProps) => {
	const htmlId = id ?? register?.name ?? 'phone-input'
	const { control, setValue } = useFormContext()

	const handleChange = (value: Value, onChange: (value: Value) => void) => {
		if (value) {
			onChange(value)
		}
	}

	const handlePhoneRegionChange = (value: Country) => {
		setValue(countryCodeField, value, { shouldDirty: true })
	}

	return (
		<FormControl isInvalid={!!error} isDisabled={disabled}>
			<FormLabel htmlFor={htmlId}>{title}</FormLabel>
			<Controller
				control={control}
				name={htmlId}
				render={({ field: { value, onChange } }) => {
					return (
						<PhoneInput
							{...(register ?? {})}
							value={value}
							defaultCountry={'AU'}
							international
							countrySelectProps={{ unicodeFlags: true }}
							onChange={(value: Value) => handleChange(value, onChange)}
							onCountryChange={handlePhoneRegionChange}
							inputComponent={CustomPhoneInput}
							data-testid={testId}
							required={required}
						/>
					)
				}}
			/>
			<FormErrorMessage>{error}</FormErrorMessage>
		</FormControl>
	)
}

export default PhoneNumberInput
