import { createColumnHelper } from '@tanstack/react-table'
import { nanoid } from '@reduxjs/toolkit'
import i18n from '@/i18n'

import { Box, Flex, Icon, Text } from '@chakra-ui/react'
import { MdSearch, MdMessage, MdEmail, MdOpenInFull } from 'react-icons/md'

import IconButton from '@UI/IconButton/IconButton'

import type { NotificationRecipient } from '@Store/types/notifications'
import type { AlertSummary } from '@Store/types'
import { formatDateStrTz } from '@/utils/dates'

const columnHelper = createColumnHelper<AlertSummary>()

const t = (key: string) => i18n.t(key, { ns: 'pages' })

const Recipients = ({
	recipients,
}: {
	recipients: NotificationRecipient[]
}) => (
	<Flex gap={4}>
		{recipients.map(({ name, address_type }) => (
			<Flex key={nanoid()} alignItems='center' gap={1}>
				<Icon as={address_type === 'email' ? MdEmail : MdMessage} />
				<Text>{name}</Text>
			</Flex>
		))}
	</Flex>
)

export const getColumns = (
	handleOpenModal: (alert: AlertSummary, isAlertContent?: boolean) => void,
	isTactical: boolean = false
) => {
	const translationKey = 'analytics.alertsSummary.table'

	const columns = [
		columnHelper.accessor('id', {
			id: 'id',
			header: t(`${translationKey}.id`),
		}),
		columnHelper.accessor('created_at', {
			id: 'created_at',
			header: t(`${translationKey}.date`),
			cell: (info) => formatDateStrTz(info.getValue()),
		}),
		columnHelper.accessor('source', {
			id: 'source',
			header: t(`${translationKey}.alertType`),
		}),
		columnHelper.accessor('recipients', {
			id: 'recipients',
			header: t(`${translationKey}.recipients`),
			cell: (info) => <Recipients recipients={info.getValue() ?? []} />,
		}),
		columnHelper.accessor('content', {
			id: 'content',
			header: t(`${translationKey}.content`),
			cell: (info) => info.getValue().slice(0, 100) + '...',
		}),
		columnHelper.display({
			id: 'modal',
			cell: ({ row }) => (
				<Box paddingInlineStart={2}>
					<IconButton
						icon={<MdOpenInFull />}
						testId='track-icon-full-screen-content'
						aria-label='track-icon-full-screen-content'
						onClick={() => handleOpenModal(row.original, true)}
					/>
				</Box>
			),
		}),
		columnHelper.display({
			id: 'track',
			header: t(`${translationKey}.track`),
			cell: ({ row }) => (
				<Box paddingInlineStart={2}>
					<IconButton
						icon={<MdSearch />}
						testId='track-icon'
						aria-label='track-icon'
						isDisabled={row.original.notificatable_type !== 'EventSummary'}
						onClick={() => handleOpenModal(row.original)}
					/>
				</Box>
			),
		}),
	]

	if (isTactical) {
		return columns.filter((column) => column.id !== 'recipients')
	}

	return columns
}
