import { Box, Flex } from '@chakra-ui/react'
import {
	useGetSystemInfoQuery,
	selectLogo,
	selectIsLogoSoteria,
} from '@Store/system/systemApi'

// DroneShield Logo
import DroneShieldLogo from '@UI/DroneShieldLogo/DroneShieldLogo'

// CUSTOMERS LOGOS
import BtLogo from './BtLogo'
import EpirusLogo from './EpirusLogo'
import TrakkaLogo from './TrakkaLogo'
import SoteriaLogo from '@UI/Logos/SoteriaLogo'

export const LOGO_OPTIONS = [
	{
		value: 'none',
		label: 'None',
	},
	{
		value: 'bt',
		label: 'BT',
	},
	{
		value: 'trakka',
		label: 'Trakka',
	},
	{
		value: 'epirus',
		label: 'Epirus',
	},
	{
		value: 'soteria',
		label: 'Soteria',
	},
]

const logoSet = {
	bt: <BtLogo />,
	trakka: <TrakkaLogo />,
	epirus: <EpirusLogo />,
	soteria: <SoteriaLogo />,
}

const TopNavigationLogo = () => {
	const { clientLogo, isSoteria } = useGetSystemInfoQuery(undefined, {
		selectFromResult: ({ isSuccess, data }) => ({
			clientLogo: isSuccess ? selectLogo(data) : undefined,
			isSoteria: isSuccess ? selectIsLogoSoteria(data) : false,
		}),
	})

	return (
		<Flex justifyContent='flex-start' alignItems='center' gap={4}>
			{clientLogo !== 'none' ? (
				<>
					<Flex
						height='42px'
						maxW='80px'
						sx={{ '& > svg': { height: '100%' } }}
					>
						{logoSet[clientLogo as keyof typeof logoSet]}
					</Flex>
					{!isSoteria && (
						<DroneShieldLogo
							height='34px'
							width='100%'
							testId='navigation-logo'
							isPoweredBy
						/>
					)}
				</>
			) : (
				<DroneShieldLogo height='42px' width='100%' testId='navigation-logo' />
			)}
		</Flex>
	)
}

const LoginPageLogo = () => {
	const { clientLogo, isSoteria } = useGetSystemInfoQuery(undefined, {
		selectFromResult: ({ isSuccess, data }) => ({
			clientLogo: isSuccess ? selectLogo(data) : undefined,
			isSoteria: isSuccess ? selectIsLogoSoteria(data) : false,
		}),
	})

	return (
		<Flex direction='column'>
			{clientLogo !== 'none' ? (
				<>
					<Box
						data-testid='login-customer-logo'
						flex={1}
						maxHeight='120px'
						marginBlockEnd={4}
						sx={{ '& > svg': { maxHeight: '100%' } }}
					>
						{logoSet[clientLogo as keyof typeof logoSet]}
					</Box>
					{!isSoteria && (
						<DroneShieldLogo
							height='36px'
							width='100%'
							testId='login-logo'
							isPoweredBy
						/>
					)}
				</>
			) : (
				<DroneShieldLogo height='60px' width='256px' testId='login-logo' />
			)}
		</Flex>
	)
}

const Logos = {
	TopNavigation: TopNavigationLogo,
	Login: LoginPageLogo,
}

export default Logos
