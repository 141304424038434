import { baseApi } from '../baseApi'
import { type CoreApiError, formatApiErrors } from '@Store/utils/errorUtils'

import type { GenericSensor, Site } from '../types/index'

type BaseGenericSensor = Pick<
	GenericSensor,
	| 'name'
	| 'sentry_id'
	| 'height_offset_to_installation'
	| 'direction_offset'
	| 'min_range'
	| 'max_range'
	| 'sector_field_of_view'
	| 'detection_visualisation'
	| 'serial_number'
	| 'sensor_type'
	| 'model'
	| 'ip'
	| 'port'
	| 'sensor_fusion_settings'
	// TODO
	// | 'disruptor'
	// | 'min_disruption_range'
	// | 'max_disruption_range'
>

type GetGenericSensorsRequest = { siteId: Site['id'] }

type GetGenericSensorRequest = {
	siteId: Site['id']
	sensorId: GenericSensor['id']
}

type CreateGenericSensorRequest = {
	siteId: Site['id']
} & BaseGenericSensor

type UpdateGenericSensorRequest = {
	siteId: Site['id']
	sensorId: GenericSensor['id']
} & BaseGenericSensor

type DeleteGenericSensorRequest = {
	siteId: Site['id']
	sensorId: GenericSensor['id']
}
type ToggleGenericSensorRequest = {
	siteId: Site['id']
	sensorId: GenericSensor['id']
	active: boolean
}

const transformGenericSensorReponse = (response: {
	generic_sensor: GenericSensor
}) => ({
	...response.generic_sensor,
	sensor_fusion_settings: {
		...response.generic_sensor?.sensor_fusion_settings,
		sensor_weight:
			response.generic_sensor.sensor_fusion_settings?.sensor_weight * 100,
	},
})

// Generic Sensors API endpoints
export const genericSensorsApi = baseApi
	.enhanceEndpoints({ addTagTypes: ['GenericSensors'] })
	.injectEndpoints({
		endpoints: (builder) => ({
			// https://au-dev.droneshield.xyz/api/docs#get-/api/sites/-site_id-/generic_sensors
			getGenericSensors: builder.query<
				Array<GenericSensor>,
				GetGenericSensorsRequest
			>({
				query: ({ siteId }) => ({
					url: `/api/sites/${siteId}/generic_sensors`,
				}),
			}),
			// https://au-dev.droneshield.xyz/api/docs#get-/api/sites/-site_id-/generic_sensors/-id-
			getGenericSensor: builder.query<GenericSensor, GetGenericSensorRequest>({
				query: ({ siteId, sensorId }) => ({
					url: `/api/sites/${siteId}/generic_sensors/${sensorId}`,
				}),
				transformResponse: transformGenericSensorReponse,
				keepUnusedDataFor: 0,
			}),
			// https://au-dev.droneshield.xyz/api/docs#post-/api/sites/-site_id-/generic_sensors
			createGenericSensor: builder.mutation<
				GenericSensor,
				CreateGenericSensorRequest
			>({
				query: ({ siteId, ...payload }) => ({
					url: `/api/sites/${siteId}/generic_sensors`,
					method: 'POST',
					body: {
						generic_sensor: {
							...payload,
							sensor_fusion_settings: {
								...payload.sensor_fusion_settings,
								sensor_weight:
									payload.sensor_fusion_settings.sensor_weight / 100,
							},
						},
					},
				}),
				transformResponse: (response: { generic_sensor: GenericSensor }) =>
					response.generic_sensor,
				transformErrorResponse: (response: CoreApiError) =>
					formatApiErrors<Site>(response),
				invalidatesTags: ['GenericSensors'],
			}),
			// https://au-dev.droneshield.xyz/api/docs#put-/api/sites/-site_id-/generic_sensors/-id-
			updateGenericSensor: builder.mutation<
				GenericSensor,
				UpdateGenericSensorRequest
			>({
				query: ({ siteId, sensorId, ...payload }) => ({
					url: `/api/sites/${siteId}/generic_sensors/${sensorId}`,
					method: 'PUT',
					body: {
						generic_sensor: {
							...payload,
							sensor_fusion_settings: {
								...payload.sensor_fusion_settings,
								sensor_weight:
									payload.sensor_fusion_settings.sensor_weight / 100,
							},
						},
					},
				}),
				transformResponse: (response: { generic_sensor: GenericSensor }) =>
					response.generic_sensor,
				transformErrorResponse: (response: CoreApiError) =>
					formatApiErrors<Site>(response),
				invalidatesTags: ['GenericSensors'],
			}),
			// https://au-dev.droneshield.xyz/api/docs#patch-/api/sites/-site_id-/generic_sensors/-id-
			toggleGenericSensor: builder.mutation<
				GenericSensor,
				ToggleGenericSensorRequest
			>({
				query: ({ siteId, sensorId, active }) => ({
					url: `/api/sites/${siteId}/generic_sensors/${sensorId}`,
					method: 'PATCH',
					body: {
						generic_sensor: {
							active,
						},
					},
				}),
				invalidatesTags: ['GenericSensors'],
				transformResponse: (response: { generic_sensor: GenericSensor }) =>
					response.generic_sensor,
			}),
			// https://au-dev.droneshield.xyz/api/docs#delete-/api/sites/-site_id-/generic_sensors/-id-
			deleteGenericSensor: builder.mutation<
				GenericSensor,
				DeleteGenericSensorRequest
			>({
				query: ({ siteId, sensorId }) => ({
					url: `/api/sites/${siteId}/generic_sensors/${sensorId}`,
					method: 'DELETE',
				}),
				invalidatesTags: ['GenericSensors'],
			}),
		}),
	})

export const {
	useGetGenericSensorsQuery,
	useGetGenericSensorQuery,
	useCreateGenericSensorMutation,
	useUpdateGenericSensorMutation,
	useDeleteGenericSensorMutation,
	useToggleGenericSensorMutation,
} = genericSensorsApi
