// Packages
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
	Button,
	Flex,
	Icon,
	List,
	ListItem,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/react'
import { MdInfo } from 'react-icons/md'

// Relatives
import RecordingToolForm from './RecordingToolForm/RecordingToolForm'

type RecordingToolModalProps = {
	isOpen: boolean
	onClose: (values?: { filename: string }) => void
	onCancel: () => void
	headerText: string
	fromText?: string
	testId?: string
	modalBodyText?: string
	modalBodyTextFiles?: string[]
}

const RecordingToolModal = ({
	isOpen,
	onClose,
	onCancel,
	headerText,
	modalBodyText = '',
	modalBodyTextFiles = [],
}: RecordingToolModalProps) => {
	const [formValues, setFormValues] = useState({ filename: '' })
	const { t } = useTranslation('app', { keyPrefix: 'global.buttons' })

	const handleClickConfirm = useCallback(async () => {
		if (formValues.filename) {
			onClose(formValues)
			setFormValues({ filename: '' })
		}
	}, [onClose, formValues])

	const handleClickCancel = useCallback(async () => {
		onCancel()
	}, [onCancel])

	const handleSubmit = useCallback(async (values: { filename: string }) => {
		setFormValues(values)
	}, [])

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			variant='deleteConfirmation'
			isCentered
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>
					<Flex alignItems='center' gap='8px'>
						<Icon as={MdInfo} h={6} w={6} color='primary' />
						{headerText}
					</Flex>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Flex flexDirection='column' gap='16px'>
						{modalBodyText}
						{modalBodyTextFiles.length > 0 && (
							<List listStyleType='disc' paddingLeft='16px'>
								{modalBodyTextFiles.map((file, i) => (
									<ListItem key={i}>{file}</ListItem>
								))}
							</List>
						)}
						<RecordingToolForm
							onSubmit={handleSubmit}
							filename={formValues?.filename}
						/>
					</Flex>
				</ModalBody>
				<ModalFooter>
					<Button
						onClick={handleClickConfirm}
						data-testid='confirm'
						isDisabled={!formValues?.filename}
					>
						{t('confirm')}
					</Button>
					<Button
						mr={3}
						onClick={handleClickCancel}
						variant='ghost'
						data-testid='cancel'
					>
						{t('cancel')}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default RecordingToolModal
