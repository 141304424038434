// Packages
import { useContext, useMemo } from 'react'
import { Flex } from '@chakra-ui/react'

// Relatives
import DetailsRadarMask from './DetailsRadarMask'
import DetailsSensorFusion from './DetailsSensorFusion'
import DetailsLocation from './DetailsLocation'
import DetailsRF from './DetailsRF'
import NotificationContext from '../NotificationContext'
import DetailsRcs from './DetailsRcs'
import DetailsBeacon from './DetailsBeacon'
import DetailsEvent from './DetailsEvent'
import DetailsSensor from './DetailsSensor'

// Types
import type { Camera, GenericSensor, Radar, RfSensor } from '@/store/types'

export type NotificationDetailsProps = {
	showRadarMaskPreview?: boolean
	isReplay?: boolean
	isStale?: boolean
	// Sensors
	radars: Radar[]
	rfSensors: RfSensor[]
	cameras: Camera[]
	genericSensors: GenericSensor[]
}

const NotificationDetails = ({
	showRadarMaskPreview = false,
	isStale = false,
	isReplay = false,
	radars = [],
	rfSensors = [],
	cameras = [],
	genericSensors = [],
}: NotificationDetailsProps) => {
	const {
		targetId,
		contributions,
		seenContributor,
		rcs,
		threat,
		threatLevel,
		confidence,
		latitude,
		longitude,
		hasDroneLocator,
		protocol,
		vendor,
		rssi,
		frequency,
		ssid,
		heading,
		speed,
		heightOffsetToSite,
		gcsLatitude,
		gcsLongitude,
		homeLatitude,
		homeLongitude,
		droneSerialNumber,
		timeOnTarget,
		siteDistance,
		threatContributions,
	} = useContext(NotificationContext)

	const hasRadarContributions = useMemo(
		() =>
			contributions?.some(
				(contribution) => contribution.sensor_type === 'radar'
			),
		[contributions]
	)

	const showRadarMaskDetail = showRadarMaskPreview && hasRadarContributions

	const echoShieldIds = useMemo(
		() =>
			radars
				.filter((radar) => radar.radar_type === 'echo_shield')
				.map((radar) => radar.id),
		[radars]
	)

	const isEchoShield = useMemo(() => {
		if (!showRadarMaskDetail) {
			return false
		}

		const radarContributions = contributions?.filter(
			(contribution) => contribution.sensor_type === 'radar'
		)

		if (!radarContributions?.length || !echoShieldIds.length) {
			return false
		}

		let found = false
		radarContributions.forEach((radarContribution) => {
			const radarFound = echoShieldIds.includes(radarContribution.sensor_id)
			if (radarFound) {
				found = true

				return
			}
		})

		return found
	}, [contributions, echoShieldIds, showRadarMaskDetail])

	const elevation = useMemo(() => {
		if (showRadarMaskDetail && contributions && contributions?.length > 0) {
			const radarContribution = contributions.find(
				(contribution) => contribution.sensor_type === 'radar'
			)

			if (radarContribution) {
				return radarContribution.elevation
			}
		}

		return 0
	}, [showRadarMaskDetail, contributions])

	return (
		<Flex
			bgColor='panel_bg'
			paddingLeft={isReplay ? '16px' : '53px'}
			paddingRight='16px'
			paddingBlock={4}
			direction='column'
			gap='4px'
		>
			<DetailsEvent targetId={targetId} />
			{showRadarMaskDetail && (
				<DetailsRadarMask
					targetId={targetId}
					rcs={rcs}
					heightOffsetToSite={heightOffsetToSite}
					elevation={elevation}
					isEchoShield={isEchoShield}
				/>
			)}
			{!showRadarMaskDetail && (
				<>
					<DetailsSensorFusion
						confidence={confidence}
						isStale={isStale}
						threat={threat}
						threatLevel={threatLevel}
						threatContributions={threatContributions}
					/>
					{rfSensors.length > 0 && (
						<DetailsRF
							protocol={protocol}
							vendor={vendor}
							rssi={rssi}
							frequency={frequency}
							ssid={ssid}
						/>
					)}
					<DetailsLocation
						latitude={latitude}
						longitude={longitude}
						heading={heading}
						speed={speed}
						heightOffsetToSite={heightOffsetToSite}
						timeOnTarget={timeOnTarget}
						siteDistance={siteDistance}
					/>
					{radars.length > 0 && <DetailsRcs rcs={rcs} />}
					{hasDroneLocator && (
						<DetailsBeacon
							gcsLatitude={gcsLatitude}
							gcsLongitude={gcsLongitude}
							homeLatitude={homeLatitude}
							homeLongitude={homeLongitude}
							droneSerialNumber={droneSerialNumber}
						/>
					)}
					<DetailsSensor
						contributions={contributions}
						seenContributor={seenContributor}
						hasDroneLocator={hasDroneLocator}
						radars={radars}
						rfSensors={rfSensors}
						cameras={cameras}
						genericSensors={genericSensors}
						isReplay={isReplay}
					/>
				</>
			)}
		</Flex>
	)
}

export default NotificationDetails
