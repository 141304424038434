import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { bearing } from '@turf/turf'

import { Flex } from '@chakra-ui/react'

import Headings from '@UI/Headings/Headings'
import SpinnerText from '@UI/SpinnerText/SpinnerText'

import {
	selectActiveTargetId,
	setCameraCalibrationStep,
} from '@Store/ui/uiSlice'
import {
	selectSiteCameraPosition,
	useGetSiteLiveQuery,
} from '@Store/sites/sitesWsApi'
import { useAppDispatch, useAppSelector } from '@Store/index'
import { useCalibrateCameraMutation } from '@Store/cameras/camerasApi'

import type { RadarDetectionMap } from '../../types'
import type { Feature, Point, GeoJsonProperties } from 'geojson'

import { useInterval } from '@/hooks/useInterval'
import { calculateHomePosition, getMean } from '@Utils/cameraCalibration'

import CameraOffsetChart from './CameraOffsetChart'
import LostDetectionAlert from '../LostDetectionAlert'
import { useTranslation } from 'react-i18next'

const MIN_BEARING_DIFF = 30

type PanCalibrationProps = {
	radarDetections: RadarDetectionMap
	sensorCoordinates: Feature<Point, GeoJsonProperties>
	setPanResult: (panResult: number) => void
}

const PanCalibration = ({
	radarDetections,
	sensorCoordinates,
	setPanResult,
}: PanCalibrationProps) => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'siteInstallations.cameraCalibration',
	})
	const { sensorId: sensorIdParam, siteId: siteIdParam } = useParams()
	const siteId = Number(siteIdParam)
	const sensorId = Number(sensorIdParam)

	const [bearingDifference, setBearingDifference] = useState(0)

	const dispatch = useAppDispatch()
	const [calibrateCamera] = useCalibrateCameraMutation()

	const { cameraPosition } = useGetSiteLiveQuery(siteId, {
		selectFromResult: ({ data }) => ({
			cameraPosition: selectSiteCameraPosition(data, sensorId),
		}),
	})

	const targetId = useAppSelector(selectActiveTargetId) ?? ''
	const { longitude, latitude } = radarDetections?.[targetId] || {}

	const [initialBearing, setInitialBearing] = useState<number>()
	const [calibrationPan, setCalibrationPan] = useState<number[]>([])

	if (!initialBearing && longitude && latitude) {
		setInitialBearing(bearing(sensorCoordinates, [longitude, latitude]))
	}

	useEffect(() => {
		setCalibrationPan((prev) => [
			...prev,
			calculateHomePosition(
				sensorCoordinates,
				longitude,
				latitude,
				cameraPosition.pan
			),
		])
	}, [cameraPosition.pan, sensorCoordinates, longitude, latitude])

	useInterval(() => {
		if (initialBearing && latitude && longitude) {
			const currentBearing = bearing(sensorCoordinates, [longitude, latitude])

			// Calculate bearing difference
			let bearingDifference =
				((currentBearing - initialBearing + 180) % 360) - 180
			if (bearingDifference > 180) bearingDifference += 360

			setBearingDifference(bearingDifference)
			setPanResult(getMean(calibrationPan))

			if (Math.abs(bearingDifference) >= MIN_BEARING_DIFF) {
				calibrateCamera({ cameraId: sensorId, siteId, action: 'start-4' })
				dispatch(setCameraCalibrationStep(4))
			}
		}
	}, 100)

	return (
		<Flex w='430px' p='16px' bgColor='body_bg' direction='column' gap='16px'>
			{!targetId && <LostDetectionAlert />}
			<SpinnerText text={t('fly')} status='active' />
			<Headings.FieldHeading title={t('headers.panCalibration')} />
			<CameraOffsetChart bearingDifference={bearingDifference} />
		</Flex>
	)
}

export default PanCalibration
