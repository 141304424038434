// Packages
import { useCallback, useMemo } from 'react'
import omit from 'lodash/omit'
import { format } from 'date-fns'
import noop from 'lodash/noop'

// Relatives
import { dateTimeFormat } from '../DatePicker'
import { isValidDate, parseDate } from '../DatePickerHelper'
import InputFragment from './InputFragment'

// Types
import type { DatePickerInputProps as DatePickerInputPropsArk } from '@ark-ui/react'

export type DatePickerInputProps = DatePickerInputPropsArk & {
	value?: string
	min?: string
	max?: string
	testId?: string
	onFocusInput?: (fragment: string) => void
	onBlurInput?: (fragment: string) => void
	onChangeInput: (date: string) => void
}

const DatePickerInput = (props: DatePickerInputProps) => {
	const {
		value = '',
		min,
		max,
		onChangeInput = noop,
		onBlurInput = noop,
		onFocusInput = noop,
	} = props
	const valueParsed = useMemo(() => parseDate(value), [value])

	const getNewDate = useCallback(
		(part: string, fragmentValue: string) => {
			const currentDate = parseDate(new Date())
			const {
				year = currentDate?.year,
				month = currentDate?.month,
				day = currentDate?.day,
				hour = currentDate?.hour,
				minute = currentDate?.minute,
			} = {
				...(valueParsed ?? {}),
				[part]: fragmentValue,
			} as any
			const newValue = new Date(year, month - 1, day, hour, minute, 0, 0)

			return format(newValue, dateTimeFormat)
		},
		[valueParsed]
	)

	const validate = useCallback(
		(part: string, fragmentValue: string) => {
			const newValueStr = getNewDate(part, fragmentValue)

			return isValidDate(newValueStr, min, max)
		},
		[min, max, getNewDate]
	)

	const handleBlur = useCallback(
		(part: string, fragmentValue: string) => {
			onBlurInput(part)
			if (!fragmentValue) {
				onChangeInput('')

				return
			}

			const newValueStr = getNewDate(part, fragmentValue)
			if (!validate(part, fragmentValue)) {
				return
			}

			onChangeInput(newValueStr)
		},
		[getNewDate, onChangeInput, validate, onBlurInput]
	)

	const handleFocus = useCallback(
		(part: string) => {
			onFocusInput(part)
		},
		[onFocusInput]
	)

	const inputProps = useMemo(
		() =>
			omit(props, [
				'data-part',
				'value',
				'defaultValue',
				'onBeforeInput',
				'onChange',
				'onFocus',
				'onKeyDown',
				'onChangeInput',
				'onBlurInput',
				'onFocusInput',
				'min',
				'max',
			]),
		[props]
	)

	const fragments = useMemo(
		() => [
			{
				part: 'year',
				value: `${valueParsed?.year ?? ''}`,
				min: 1900,
				max: 2999,
				maxLength: 4,
				placeholder: 'YYYY',
				pattern: /^([0-9]{0,4}|)$/gim,
			},
			'-',
			{
				part: 'month',
				value: `${valueParsed?.month ?? ''}`,
				min: 1,
				max: 12,
				maxLength: 2,
				placeholder: 'MM',
				pattern: /^([0-9]{0,2}|)$/gim,
			},
			'-',
			{
				part: 'day',
				value: `${valueParsed?.day ?? ''}`,
				min: 1,
				max: 31,
				maxLength: 2,
				placeholder: 'DD',
				pattern: /^([0-9]{0,2}|)$/gim,
			},
			',',
			{
				part: 'hour',
				value: `${valueParsed?.hour ?? ''}`,
				min: 0,
				max: 23,
				maxLength: 2,
				placeholder: 'HH',
				pattern: /^([0-9]{0,2}|)$/gim,
			},
			':',
			{
				part: 'minute',
				value: `${valueParsed?.minute ?? ''}`,
				min: 0,
				max: 59,
				maxLength: 2,
				placeholder: 'MM',
				pattern: /^([0-9]{0,2}|)$/gim,
			},
		],
		[valueParsed]
	)

	return (
		<div data-scope='date-picker' data-part='input-container'>
			{fragments.map((fragment, i) => {
				if (typeof fragment === 'string') {
					return <span key={i}>{fragment}</span>
				}

				return (
					<InputFragment
						{...inputProps}
						id={`${inputProps.id}-${fragment.part}`}
						{...fragment}
						onBlur={handleBlur}
						onValidate={validate}
						onFocus={handleFocus}
						key={i}
					/>
				)
			})}
		</div>
	)
}

export default DatePickerInput
