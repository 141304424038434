// Packages
import { DatePicker as DatePickerArk } from '@ark-ui/react'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'

const DatePickerYears = () => {
	return (
		<DatePickerArk.View view='year'>
			<DatePickerArk.Context>
				{(datePicker) => (
					<>
						<DatePickerArk.ViewControl>
							<DatePickerArk.PrevTrigger>
								<MdKeyboardArrowLeft size={20} />
							</DatePickerArk.PrevTrigger>
							<DatePickerArk.ViewTrigger>
								<DatePickerArk.RangeText />
							</DatePickerArk.ViewTrigger>
							<DatePickerArk.NextTrigger>
								<MdKeyboardArrowRight size={20} />
							</DatePickerArk.NextTrigger>
						</DatePickerArk.ViewControl>
						<DatePickerArk.Table>
							<DatePickerArk.TableBody>
								{datePicker.getYearsGrid({ columns: 4 }).map((years, id) => (
									<DatePickerArk.TableRow key={id}>
										{years.map((year, id) => (
											<DatePickerArk.TableCell key={id} value={year.value}>
												<DatePickerArk.TableCellTrigger>
													{year.label}
												</DatePickerArk.TableCellTrigger>
											</DatePickerArk.TableCell>
										))}
									</DatePickerArk.TableRow>
								))}
							</DatePickerArk.TableBody>
						</DatePickerArk.Table>
					</>
				)}
			</DatePickerArk.Context>
		</DatePickerArk.View>
	)
}

export default DatePickerYears
