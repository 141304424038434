import { useState } from 'react'
import { useTranslation } from 'react-i18next'

// Components
import Switch from '@Components/_UI/Switch/Switch'

// Redux
import type { Site, RadarAglMask } from '@Store/types'
import { useUpdateRadarAglMaskMutation } from '@/store/radarAglMasks/radarAglMasksApi'
import type { FormError } from '@/components/Forms/types'
import { addToast } from '@/store/ui/uiSlice'
import { useAppDispatch } from '@/store'

type RadarAglMaskActiveSwitchProps = {
	siteId: Site['id']
	radarAglMask: RadarAglMask
}

const RadarAglMaskActiveSwitch = ({
	siteId,
	radarAglMask,
}: RadarAglMaskActiveSwitchProps) => {
	const dispatch = useAppDispatch()
	const { id, enabled } = radarAglMask
	const { t } = useTranslation('panels', { keyPrefix: 'aglMask' })

	const [isPending, setIsPending] = useState<boolean>(false)
	const [updateRadarAglMask] = useUpdateRadarAglMaskMutation()

	const handleToggleActive = async () => {
		setIsPending(true)
		await updateRadarAglMask({
			siteId: siteId,
			sensorId: radarAglMask.radar_id,
			aglMaskId: id,
			enabled: !enabled,
		})
			.unwrap()
			.then(() => setIsPending(false))
			.catch((error) => {
				dispatch(
					addToast({
						id,
						siteId,
						type: 'error',
						title: t('toast.updateErrorTitle'),
						description: (error as FormError)?.data?.error ?? error?.error,
						error: error,
					})
				)
			})
	}

	return (
		<Switch
			onChange={handleToggleActive}
			label={t('switchLabel')}
			isChecked={enabled}
			isDisabled={isPending}
			testId='radar-agl-active-switch'
		/>
	)
}

export default RadarAglMaskActiveSwitch
