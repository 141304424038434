import type { FC, ReactNode } from 'react'
import { Text, type TextProps } from '@chakra-ui/react'

type HeadingType = {
	title: string
	testId?: string
} & TextProps

const Heading: FC<HeadingType> = ({ title = '', ...rest }) => {
	return (
		<Text color='headings' {...rest}>
			{title}
		</Text>
	)
}

const Page: FC<HeadingType> = ({ title = '', testId = 'title' }) => {
	return (
		<Heading
			title={title}
			as='h1'
			textTransform='uppercase'
			fontWeight='500'
			fontSize='lg'
			data-testid={testId}
		/>
	)
}

const Section: FC<HeadingType> = ({ title = '', testId = '' }) => {
	return (
		<Heading
			title={title}
			as='h2'
			textTransform='uppercase'
			fontWeight='600'
			fontSize='md'
			data-testid={testId}
		/>
	)
}
const SectionSubheading: FC<HeadingType> = ({ title = '' }) => {
	return <Heading title={title} as='h3' fontWeight='500' fontSize='md' mt={4} />
}

const FormHeading: FC<HeadingType> = ({
	title = '',
	textTransform = 'none',
}) => {
	return (
		<Heading
			title={title}
			as='h4'
			fontWeight='500'
			fontSize='sm'
			textTransform={textTransform}
		/>
	)
}

const FieldHeading: FC<HeadingType> = ({ title = '', testId = '' }) => {
	return (
		<Heading
			title={title}
			as='h5'
			fontWeight='400'
			fontSize='sm'
			bgColor='transparent'
			data-testid={testId}
		/>
	)
}

const SensorHeading: FC<HeadingType> = ({ title = '' }) => {
	return (
		<Heading
			title={title}
			as='h2'
			fontWeight='600'
			fontSize='md'
			color='body_text'
		/>
	)
}

const ChartHeading: FC<HeadingType> = ({ title = '' }) => {
	return (
		<Heading
			title={title}
			as='h4'
			fontWeight='500'
			fontSize='sm'
			color='body_text'
		/>
	)
}

const DeviceHeading = ({ name }: { name: string }) => {
	return (
		<Text fontSize='md' fontWeight={500} data-testid='device-name' isTruncated>
			{name}
		</Text>
	)
}

const Headings = {
	Page,
	Section,
	SectionSubheading,
	FormHeading,
	FieldHeading,
	SensorHeading,
	ChartHeading,
	DeviceHeading,
}

export const Subheading = ({ children, ...props }: { children: ReactNode }) => (
	<Text fontSize='sm' color='headings' fontWeight={400} {...props}>
		{children}
	</Text>
)

export default Headings
