// Packages
import { useTranslation } from 'react-i18next'

// Alias
import MultiSelectUI from '@Components/_UI/Select/MultiSelect'

// Types
import type { MultiSelectOption } from '@Components/_UI/Select/MultiSelect'

export type VisibleZoneTypesProps = {
	value: string[]
	defaultValue: MultiSelectOption[]
	options: MultiSelectOption[]
	disabled?: boolean
	loading?: boolean
	onChange: (options: { label: string; value: string }[]) => void
}

const VisibleZoneTypes = (props: VisibleZoneTypesProps) => {
	const {
		value,
		defaultValue,
		loading = false,
		disabled = false,
		options,
		onChange,
	} = props
	const { t } = useTranslation('forms', {
		keyPrefix: 'mapsZonesForm.displaySettings',
	})

	return (
		<MultiSelectUI
			title={t('zonesDisplay')}
			options={options}
			placeholder={t('zonesDisplayPlaceholder')}
			isDisabled={disabled || loading}
			isLoading={loading}
			defaultValue={defaultValue}
			value={options.filter((option: MultiSelectOption) =>
				value.includes(option.value as string)
			)}
			onChange={onChange}
			testId='visible-zones-types'
			variant='map-zone-multi-select'
		/>
	)
}

export default VisibleZoneTypes
