import { z } from 'zod'
import globalSchema from '@/components/Forms/BaseForm/BaseForm.schema'
// Translations
import i18n from '@/i18n'
const t = (key: string, options: any) =>
	i18n.t(`forms:global.validation.${key}`, options)

const FREQUENCY_RANGE = {
	min: 0,
	max: 6,
}

const FREQUENCY_TOLERANCE = {
	min: 0,
	max: 0.16,
}

// TODO: re-assign max and min values depends on selected Frequency band

const rfFiltersFormFields = z.object({
	name: globalSchema.shape.name,
	id: z.number(),
	active: z.boolean(),
	bearing: z.number({
		// Modifying standard error expected number, received nan
		errorMap: (issue, _ctx) => {
			switch (issue.code) {
				default:
					return { message: String(t('empty', {})) }
			}
		},
	}),
	bearing_tolerance: z.number({
		// Modifying standard error expected number, received nan
		errorMap: (issue, _ctx) => {
			switch (issue.code) {
				default:
					return { message: String(t('empty', {})) }
			}
		},
	}),
	frequency: z.number().min(FREQUENCY_RANGE.min).max(FREQUENCY_RANGE.max),
	frequency_tolerance: z
		.number()
		.min(FREQUENCY_TOLERANCE.min)
		.max(FREQUENCY_TOLERANCE.max),
	frequency_band: z.string(),
	whitelist: z.boolean(),
	rf_sensor_ids: z.array(z.number()),
	protocol: z.string().optional(),
	vendor: z.string().optional(),
	mac_address: z.string().nullish().optional(),
	created_at: z.string().nullish().optional(),
	updated_at: z.string().nullish().optional(),
	deleted_at: z.string().nullish().optional(),
	model: z.string().optional(),
	filterType: z.enum(['rf_filter', 'whitelist']).optional(),
})

const rfFiltersFormSchema = rfFiltersFormFields.superRefine((data, ctx) => {
	const isWhiteList = data?.filterType === 'whitelist'
	if (data && data?.model?.includes('dsx')) {
		if (isWhiteList && data.bearing && data.bearing_tolerance) return true
		// DSX bearing from 0 to 360, bearing_tolerance from 0 to 180
		if ((!isWhiteList && data.bearing < 0) || data.bearing > 359) {
			ctx.addIssue({
				path: ['bearing'],
				message: String(t('range', { min: 0, max: 359 })),
				code: z.ZodIssueCode.custom,
			})
		}
		if (
			(!isWhiteList && data.bearing_tolerance < 0) ||
			data.bearing_tolerance > 180
		) {
			ctx.addIssue({
				path: ['bearing_tolerance'],
				message: String(t('range', { min: 0, max: 180 })),
				code: z.ZodIssueCode.custom,
			})
		}
	} else {
		if (isWhiteList && data.bearing && data.bearing_tolerance) return true
		// Non DSX bearing from -48 to 48, bearing_tolerance from -70 to 70
		if ((!isWhiteList && data.bearing < -48) || data.bearing > 48) {
			ctx.addIssue({
				path: ['bearing'],
				message: String(t('range', { min: -48, max: 48 })),
				code: z.ZodIssueCode.custom,
			})
		}
		if (
			(!isWhiteList && data.bearing_tolerance < 0) ||
			data.bearing_tolerance > 70
		) {
			ctx.addIssue({
				path: ['bearing_tolerance'],
				message: String(t('range', { min: 0, max: 70 })),
				code: z.ZodIssueCode.custom,
			})
		}
	}
})

export type DefaultRfFilterValuesType = z.infer<typeof rfFiltersFormFields>
export const ADD_FILTER_DEFAULT_VALUES: DefaultRfFilterValuesType = {
	id: 0,
	name: '',
	active: true,
	bearing: 0,
	bearing_tolerance: 0,
	frequency: 0,
	frequency_band: '',
	frequency_tolerance: 0,
	mac_address: '',
	protocol: '',
	rf_sensor_ids: [],
	vendor: '',
	whitelist: false,
	created_at: '',
	updated_at: '',
	deleted_at: '',
}

export default rfFiltersFormSchema
