import { simpleMapScreenshoter } from 'leaflet'
import { forwardRef, useCallback, useImperativeHandle } from 'react'
import { useMap } from 'react-leaflet'

export type EventReplayScreenshotRef = {
	takeScreenshot: () => Promise<string | undefined>
}

const EventReplayScreenshot = forwardRef<EventReplayScreenshotRef>((_, ref) => {
	const map = useMap()

	const takeScreenshot = useCallback(async () => {
		const screenshotTool = simpleMapScreenshoter().addTo(map)
		const screenshot = await screenshotTool.takeScreen('image')

		if (typeof screenshot === 'string') {
			return screenshot
		}

		return undefined
	}, [map])

	useImperativeHandle(
		ref,
		() => ({
			takeScreenshot,
		}),
		[takeScreenshot]
	)

	return <></>
})
EventReplayScreenshot.displayName = 'EventReplayScreenshot'

export default EventReplayScreenshot
