import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { MdTrackChanges as RadarMaskIcon } from 'react-icons/md'

import { useAppSelector } from '@Store/index'
import { selectActiveTargetId } from '@Store/ui/uiSlice'

import {
	useGetSiteLiveQuery,
	selectSiteRadars,
	selectSiteDetection,
	selectDetectionHasRadarContributions,
} from '@Store/sites/sitesWsApi'

import QuickActionsButton from '../QuickActionsButton/QuickActionsButton'
import { getQuickActionsRadarMaskDefaultValues } from '@Utils/quickActions'

export type DetectionDetails = {
	latitude: number
	longitude: number
	elevation: number
	rcs: number
	altitude: number
}

const RadarMaskButton = ({ siteId }: { siteId: number }) => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'quickActions.maskActions',
	})

	const navigate = useNavigate()
	const activeTargetId = useAppSelector(selectActiveTargetId)

	const { radars, mode, detection, hasRadarContributions } =
		useGetSiteLiveQuery(siteId, {
			selectFromResult: ({ data }) => ({
				radars: selectSiteRadars(data),
				mode: data?.mode,
				detection: selectSiteDetection(data, activeTargetId),
				hasRadarContributions: selectDetectionHasRadarContributions(
					data,
					activeTargetId
				),
			}),
		})
	const detectionContributions = detection?.detection_contributions || []
	const sensorId = detectionContributions[0]?.sensor_id

	const radar = radars.find((radar) => radar.id === sensorId)
	const hasRadars = radars.length > 0

	const isDisabled =
		!hasRadars || !hasRadarContributions || mode !== 'calibration'

	const detectionDetails: DetectionDetails = {
		latitude: detection?.latitude || 0,
		longitude: detection?.longitude || 0,
		elevation: detectionContributions[0]?.elevation || 0,
		rcs: detection?.rcs || -50,
		altitude: detection?.height_offset_to_site || 0,
	}

	const quickActionsDefaultValues =
		radar && getQuickActionsRadarMaskDefaultValues(radar, detectionDetails)

	const installationId = radar?.sentry_id ?? null

	const handleClick = () =>
		navigate(
			`/${siteId}/installation/${installationId}/radar/${sensorId}/mask/add`,
			{
				state: { quickActionsDefaultValues },
			}
		)

	return (
		<QuickActionsButton
			icon={RadarMaskIcon}
			label={t('addRadarMask')}
			isDisabled={isDisabled}
			onClick={handleClick}
			testId='quick-action-radar-mask'
		/>
	)
}

export default RadarMaskButton
