// Packages
import { useTranslation } from 'react-i18next'
import { Flex } from '@chakra-ui/react'

// Alias
import BarChart from '@UI/Charts/BarChart'
import FetchError from '@UI/FetchError/FetchError'
import Headings from '@UI/Headings/Headings'
import Skeleton from '@UI/Skeleton/Skeleton'
import { useGetTrackSummaryQuery } from '@Store/analytics/analyticsApi'

// Relatives
import { useEventFilter } from './EventDetailsTable/EventFilterContext'
import FlightsBarChartTooltip from './FlightsBarChartTooltip'

type FlightsBarChartProps = {
	siteId: number
	startTime: string
	endTime: string
}

const FlightsBarChart = ({
	siteId,
	startTime,
	endTime,
}: FlightsBarChartProps) => {
	const { t } = useTranslation('pages', {
		keyPrefix: 'analytics.trackSummary',
	})

	const { filterParams } = useEventFilter()

	const { isSuccess, isLoading, isError, refetch, isFetching, flightsByHour } =
		useGetTrackSummaryQuery(
			{
				siteId,
				format: 'graph',
				startTime,
				endTime,
				...filterParams,
			},
			{
				selectFromResult: ({
					isSuccess,
					isLoading,
					isError,
					isFetching,
					data,
				}) => ({
					isSuccess,
					isLoading,
					isError,
					isFetching,
					flightsByHour: data?.by_hour ?? {},
				}),
			}
		)

	const chartData = Object.entries(flightsByHour)
		.map(([hour, days]) => ({
			sortBy: Number(hour),
			label: `${hour.toString().padStart(2, '0')}:00`,
			value: Object.values(days).reduce((acum, day) => acum + day, 0),
			hour: hour.toString().padStart(2, '0'),
		}))
		.sort((a, b) => a.sortBy - b.sortBy)

	return (
		<Skeleton isLoaded={!isLoading && !isFetching}>
			{isSuccess && (
				<Flex
					h='360px'
					w='100%'
					direction='column'
					gap='16px'
					marginBlockEnd={8}
					alignItems='center'
				>
					<Headings.ChartHeading title={t('numberOfFlightsBarChart.title')} />
					<BarChart
						data={chartData}
						indexBy='label'
						keys={['value']}
						margin={{ top: 15, right: 30, bottom: 68, left: 60 }}
						legendY={t('numberOfFlightsBarChart.yAxisLabel')}
						legendX={t('numberOfFlightsBarChart.xAxisLabel')}
						tooltip={({ value, data, indexValue }) => (
							<FlightsBarChartTooltip
								value={value}
								data={data}
								flightsByHour={flightsByHour}
								indexValue={indexValue}
							/>
						)}
					/>
				</Flex>
			)}
			{isError && (
				<FetchError
					refetch={refetch}
					entity={t('entities.trackSummaryCharts')}
				/>
			)}
		</Skeleton>
	)
}

export default FlightsBarChart
