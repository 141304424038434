import { useEffect, useRef } from 'react'
import { Polyline as ReactLeafletPolyline } from 'react-leaflet'
import type { Polyline as LeafletPolylineType } from 'leaflet'
import type { PolylineProps as ReactLeafletPolylineProps } from 'react-leaflet'

type PolylineProps = ReactLeafletPolylineProps & {
	testId?: string
}

const Polyline = ({
	positions,
	pathOptions,
	className = undefined,
	testId,
	eventHandlers,
}: PolylineProps) => {
	const ref = useRef<LeafletPolylineType | null>(null)
	useEffect(() => {
		if (ref.current && testId)
			ref.current.getElement()?.setAttribute('data-testid', testId)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ref.current, testId])
	return (
		<ReactLeafletPolyline
			ref={ref}
			positions={positions}
			eventHandlers={eventHandlers}
			className={className}
			pathOptions={pathOptions}
			interactive={eventHandlers && Object.keys(eventHandlers).length > 0}
		/>
	)
}

export default Polyline
