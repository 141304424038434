// React
import { createRoot } from 'react-dom/client'
import { StrictMode } from 'react'

// Redux
import { Provider } from 'react-redux'
import { store } from '@Store/index'
import rehydrateUser from './store/user/rehydrateUser'

// App
import App from '@Components/App/App'

// Rehydrate user state
await rehydrateUser()

// Mount the App
createRoot(document.getElementById('root') as HTMLElement).render(
	<StrictMode>
		<Provider store={store}>
			<App />
		</Provider>
	</StrictMode>
)
