import { Polygon as LeafletPolygon } from 'leaflet'
import type { ReactNode } from 'react'
import type { LatLngExpression, PolylineOptions } from 'leaflet'
import type { LeafletContextInterface } from '@react-leaflet/core'

import {
	createElementObject,
	createPathComponent,
	extendContext,
} from '@react-leaflet/core'

type PolygonProps = {
	positions: LatLngExpression[]
	testId?: string
	children?: ReactNode
} & PolylineOptions

const createPolygon = (
	{ positions, testId, children: _c, ...options }: PolygonProps,
	context: LeafletContextInterface
) => {
	const polygon = new LeafletPolygon(positions, options)
	if (testId) {
		polygon.addEventListener('add', (e) => {
			e.target.getElement().setAttribute('data-testid', testId)
			// Ability to add classList and any DOM stuff
			// e.target.getElement().classList.add('PolygonClassName')
		})
	}
	return createElementObject(
		polygon,
		extendContext(context, {
			overlayContainer: polygon,
		})
	)
}

const updatePolygon = (
	polygon: LeafletPolygon,
	props: PolygonProps,
	prevProps: PolygonProps
) => {
	if (props.positions !== prevProps.positions)
		polygon.setLatLngs(props.positions)
}

const Polygon = createPathComponent(createPolygon, updatePolygon)

export default Polygon
