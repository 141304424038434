// Math taken from Vue
import { bearing, distance } from '@turf/turf'
import { convertDegreeToRad } from './mathUtils'

import type { Feature, Point, GeoJsonProperties } from 'geojson'
import type { RadarDetection } from '@Components/Site/Panels/SiteInstallations/CameraCalibration/types'

const roundNDigits = (x: number, n = 2) => {
	const factor = Math.pow(10, n)
	return Math.round(x * factor) / factor
}

export const getLinearRegression = (data: any, isActiveStream: boolean) => {
	const n = data.y.length
	const [x, y] = [[...data.x], [...data.y]]
	let slope, intercept

	if (n === 1) {
		slope = isActiveStream ? 0.01456664239 : 0.013422 // Trakka or Bosch
		intercept = y[0] - slope * x[0]
	} else {
		let sum_x = 0,
			sum_y = 0,
			sum_xy = 0,
			sum_xx = 0

		for (let i = 0; i < y.length; i++) {
			sum_x += x[i]
			sum_y += y[i]
			sum_xy += x[i] * y[i]
			sum_xx += x[i] * x[i]
		}

		slope = (n * sum_xy - sum_x * sum_y) / (n * sum_xx - sum_x * sum_x)
		intercept = (sum_y - slope * sum_x) / n
	}
	return {
		min: roundNDigits((-1 - intercept) / slope),
		max: roundNDigits((1 - intercept) / slope),
	}
}

const sumArray = (array: number[]) => array.reduce((a, b) => a + b, 0)

export const getMean = (array: number[]) => {
	const meanAngle =
		(180 / Math.PI) *
		Math.atan2(
			sumArray(array.map(convertDegreeToRad).map(Math.sin)) / array.length,
			sumArray(array.map(convertDegreeToRad).map(Math.cos)) / array.length
		)
	return roundNDigits(meanAngle < 0 ? meanAngle + 360 : meanAngle)
}

export const getCircularBounds = (
	angle: number,
	[min, max]: [min: number, max: number]
) => {
	const span = max - min
	if (angle < min) {
		return angle + span * Math.ceil((min - angle) / span)
	} else if (angle > max) {
		return angle - span * Math.ceil((angle - max) / span)
	}
	return angle
}

export const calculateHomePosition = (
	sensorCoordinates: Feature<Point, GeoJsonProperties>,
	longitude: number,
	latitude: number,
	pan: number
) => {
	const angle = bearing(sensorCoordinates, [longitude, latitude])
	const homeAngle = getCircularBounds(angle - pan * 180, [0, 360])
	return homeAngle
}

const TARGET_DISTANCE = 150
const AVG_COUNT = 30

export const getDistanceDifference = (
	sensorCoordinates: Feature<Point, GeoJsonProperties>,
	lastDetectionCoordinates: [latitude: number, longitude: number]
) =>
	Math.abs(
		distance(sensorCoordinates, lastDetectionCoordinates) * 1000 -
			TARGET_DISTANCE
	)

export const getHasNotMoved = (
	coordinateHistory: RadarDetection['coordinateHistory'],
	heightHistory: RadarDetection['heightHistory']
) =>
	distance(coordinateHistory[0], coordinateHistory[AVG_COUNT]) < 0.005 &&
	Math.abs(heightHistory[0] - heightHistory[AVG_COUNT]) < 5
