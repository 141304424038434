// React
import { useEffect, useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
// Chakra
import {
	FormControl,
	FormErrorMessage,
	FormLabel,
	Slider as ChakraSlider,
	SliderFilledTrack,
	SliderThumb,
	SliderTrack,
	Grid,
	Box,
	NumberInputField,
} from '@chakra-ui/react'
// Components
import Tooltip from '@UI/Tooltip/Tooltip'
import SliderCustomLabel from './SliderCustomLabel'
// Types
import type { SliderProps } from '@chakra-ui/react'
import type { RegisterType } from '@/components/FormElements/types/RegisterType'
import type { FormElementProps } from '@Components/FormElements/types/FormElementProps'
import type { Units } from '@Components/FormElements/types/Units'
// Hooks
import useUnits from '@Hooks/useUnits'
import SliderNumberInput from '../SliderNumberInput/SliderNumberInput'
import { useTranslation } from 'react-i18next'

export type FormSliderProps = FormElementProps & {
	defaultValue?: number
	min: number
	max: number
	step?: number
	inputWidth?: number
	units?: Units
	isAbsolute?: boolean
	customLabel?: string | number
} & SliderProps &
	RegisterType<string>

const Slider = ({
	id,
	title,
	error,
	register,
	defaultValue, // deprecated, don't use it
	min,
	max,
	step = 1,
	disabled = false,
	inputWidth = undefined,
	testId,
	tooltip,
	units,
	customLabel,
	isAbsolute = false,
}: FormSliderProps) => {
	const { control, getValues, setError, clearErrors } = useFormContext()
	const [sliderInput, setSliderInput] = useState<string | undefined>()
	const [slider, setSlider] = useState<number>()
	const { t } = useTranslation('forms', {
		keyPrefix: 'global.validation',
	})

	const htmlId = id ?? register?.name ?? 'slider'

	useEffect(() => {
		if (!htmlId) return
		const initialValue = getValues(htmlId)
		setSlider(initialValue)
		setSliderInput(initialValue?.toString())
	}, [getValues, htmlId, setSlider])

	const { unit } = useUnits(units)

	const handleOnChange = (
		value: string | undefined,
		onChange: (...event: any[]) => void
	) => {
		setSliderInput(value)

		const parsed = Number(value)

		if (isNaN(parsed)) {
			setError(htmlId, {
				message: t('range', {
					min: min,
					max: max,
				}),
			})
			onChange(undefined)
			return
		}

		if (Number(value) < min || Number(value) > max) {
			setError(htmlId, {
				message: t('range', {
					min: min,
					max: max,
				}),
			})
		} else {
			clearErrors(htmlId)
			setSlider(Number(value))
		}

		// This is a good way to prevent user from entering empty string as z.number() would parse it as 0
		if (!value) {
			onChange(undefined)
			return
		}

		onChange(Number(value))
	}

	const calculatedInputWidth = useMemo(() => {
		if (inputWidth) return `${inputWidth}ch`
		else if (String(max).length >= 5) return `${String(max).length + 2}ch`
		else return '9ch'
	}, [inputWidth, max])

	return (
		<Tooltip label={tooltip} type='info'>
			<FormControl isInvalid={!!error} isDisabled={disabled} userSelect='none'>
				<Controller
					control={control}
					name={htmlId}
					defaultValue={defaultValue} // need to remove
					render={({ field: { onChange, value } }) => {
						return (
							<Box position='relative'>
								<SliderCustomLabel
									customLabel={customLabel}
									isAbsolute={isAbsolute}
									units={units}
								/>
								<FormLabel flex='1 0 auto'>
									{title} {!!unit && `(${unit})`}
								</FormLabel>
								<Grid templateColumns={`${calculatedInputWidth} 1fr`}>
									<SliderNumberInput
										min={min}
										max={max}
										textAlign='center'
										value={sliderInput}
										onChange={(value) => handleOnChange(value, onChange)}
										step={step}
										clampValueOnBlur={false}
									>
										<NumberInputField
											w={calculatedInputWidth}
											p={0}
											textAlign={'center'}
											data-testid={testId}
										/>
									</SliderNumberInput>
									<ChakraSlider
										id={htmlId}
										onChange={(value) =>
											handleOnChange(String(value), onChange)
										}
										value={slider}
										min={min}
										max={max}
										isDisabled={disabled}
										focusThumbOnChange={false}
										step={step}
									>
										<SliderTrack>
											<SliderFilledTrack />
										</SliderTrack>
										<SliderThumb />
									</ChakraSlider>
								</Grid>
							</Box>
						)
					}}
				></Controller>
				<FormErrorMessage>{error}</FormErrorMessage>
			</FormControl>
		</Tooltip>
	)
}

export default Slider
