import { Box, Center } from '@chakra-ui/react'
import { useMemo } from 'react'
import { MdCamera } from 'react-icons/md'

type CameraRecording = {
	duration: number
	startTime: number
	url: string
}

type CameraChunk = {
	start: number
	width: number
}

// Min Width Percentage to Fit Icon
const CAMERA_ICON_FIT = 3

const VideoControlCameraChunks = ({
	videoRecordings,
	duration,
}: {
	videoRecordings: CameraRecording[]
	duration: number
}) => {
	const cameraChunks: CameraChunk[] = useMemo(
		() =>
			videoRecordings.map((video) => {
				const start = (video.startTime / duration) * 100
				const end =
					video.startTime + video.duration > duration
						? 100
						: ((video.startTime + video.duration) / duration) * 100
				return {
					start,
					width: end - start,
				}
			}),
		[videoRecordings, duration]
	)
	return (
		<>
			{cameraChunks.map(({ start, width }, index: number) => {
				return (
					<Box
						key={index}
						position='absolute'
						left={`${start}%`}
						width={`${width}%`}
						bg='gray.600'
						height='100%'
					>
						<Center height='100%'>
							{width > CAMERA_ICON_FIT && <MdCamera size='80%' />}
						</Center>
					</Box>
				)
			})}
		</>
	)
}

export default VideoControlCameraChunks
