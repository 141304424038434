import { tableAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
	createMultiStyleConfigHelpers(tableAnatomy.keys)

const commonHeadStyles = {
	p: '8px 12px',
	fontSize: 'sm',
	fontWeight: 500,
	color: 'label_color',
	textTransform: 'capitalize',
}

const commonBodyStyles = {
	fontSize: 'sm',
	p: '8px 12px',
	borderBottomWidth: '1px',
	borderBottomColor: 'chakra-border-color',
}

const commonLastOfTypeStyles = {
	'&:last-of-type': {
		td: { borderBottomWidth: 0 },
	},
}

const baseStyle = definePartsStyle({
	thead: {
		tr: {
			th: {
				...commonHeadStyles,
				borderBottomWidth: '1px',
				borderBottomColor: 'chakra-border-color',
			},
		},
	},
	tbody: {
		tr: {
			td: {
				...commonBodyStyles,
			},
			...commonLastOfTypeStyles,
		},
	},
})

const filledStyle = definePartsStyle({
	thead: {
		tr: {
			th: {
				...commonHeadStyles,
			},
		},
	},
	tbody: {
		tr: {
			td: {
				...commonBodyStyles,
				background: 'body_bg',
			},
			...commonLastOfTypeStyles,
		},
	},
})

const sizes = {
	cots: definePartsStyle({ th: 'xs' }),
}

export const Table = defineMultiStyleConfig({
	variants: { baseStyle, filledStyle },
	sizes,
})
