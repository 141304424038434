import { z } from 'zod'
import globalSchema from '@/components/Forms/BaseForm/BaseForm.schema'
// Translations
import i18n from '@/i18n'
const t = (key: string, value: { [key: string]: string }) =>
	i18n.t(`forms:siteForm.validation.${key}`, value) as string

const siteFormFields = z.object({
	// Copied from global schema
	name: globalSchema.shape.name,
	timezone: globalSchema.shape.timezone,
	zoom_level: globalSchema.shape.zoom_level,
	latitude: globalSchema.shape.latitude,
	longitude: globalSchema.shape.longitude,
	map_center_latitude: globalSchema.shape.latitude.nullable(),
	map_center_longitude: globalSchema.shape.longitude.nullable(),
	// Site schema
	auto_jam: z.boolean().optional(),
	accept_location_updates: z.boolean().optional(),
	locator_id: z.number().optional(),
	minimum_display_probability: z.number().nonnegative().max(1).optional(),
	minimum_display_threat_level: z.number().nonnegative().max(1).optional(),
	zone_trigger_probability: z.number().nonnegative().max(0.8).optional(),
	zone_trigger_threat_level: z.number().nonnegative().min(0).max(1),
	always_show_rf_beam: z.boolean().optional(),
	system_warning_enabled: z.boolean().optional(),
	speedleader_estimation_time: z
		.number()
		.nonnegative()
		.max(30, 'Speedleader must be 30s or less'),
	auto_disruption_probability: z.number().nonnegative().max(100),
	auto_disruption_detection_count: z
		.number()
		.nonnegative()
		.min(1)
		.max(2_147_483_647),
	auto_disruption_threat_level: z.number().nonnegative().max(1).optional(),
	auto_jam_interval: z.number().min(1).max(300),
	sensitivity: z.enum(['normal', 'high']),
	max_location_variance: z.number().min(1).max(20000),
	round_frequency_band: z.enum(['none', 'controller', 'all']),
	prob_uav_filter: z.number().nonnegative().max(100).optional(),
	prob_uav_filter_range: z.number().min(20).max(10000),
	fuse_controller_detections: z.boolean().optional(),
	client_id: z.number().optional(),
	drone_locator_detection_timeout: z.number().min(1).max(150),
	height: z.number().min(-460).max(9000),
	//
	// TODO: we move this to user preferences?
	// defaultSite: z.boolean().optional(),
	//
	// TODO: these actually belong to Alerts?
	// alert_suppression_time_window: 600,
	// trigger_sensors_offline_alerts: boolean,
	// zone_trigger_probability: 0.6,
	// zone_trigger_detection_count: 5,
})

const siteFormSchema = siteFormFields.superRefine((data, ctx) => {
	if (data.zone_trigger_probability && data.minimum_display_probability) {
		if (data.zone_trigger_probability < data.minimum_display_probability) {
			const probability = `${data.zone_trigger_probability * 100}`
			ctx.addIssue({
				path: ['minimum_display_probability'],
				message: t('minimum_display_probability', { probability }),
				code: z.ZodIssueCode.custom,
			})
		}
	}
})

const defaultValuesSchema = siteFormSchema

export type SiteForm = z.infer<typeof siteFormSchema>
export type SiteDefaultValues = z.infer<typeof defaultValuesSchema>

export const ADD_SITE_DEFAULT_VALUES: SiteDefaultValues = {
	accept_location_updates: false,
	always_show_rf_beam: false,
	auto_disruption_detection_count: 5,
	auto_disruption_probability: 0,
	auto_jam: false,
	auto_jam_interval: 60,
	auto_disruption_threat_level: 0.6,
	fuse_controller_detections: false,
	latitude: -33.96502,
	locator_id: 0,
	longitude: 151.182591,
	map_center_latitude: null,
	map_center_longitude: null,
	max_location_variance: 300,
	minimum_display_probability: 0,
	minimum_display_threat_level: 0,
	zone_trigger_probability: 0.6,
	zone_trigger_threat_level: 0.6,
	name: '',
	prob_uav_filter: 20,
	prob_uav_filter_range: 350,
	// prob_uav_threshold: 0,
	round_frequency_band: 'controller',
	sensitivity: 'normal',
	speedleader_estimation_time: 10,
	system_warning_enabled: true,
	timezone: 'Australia/Sydney',
	zoom_level: 13,
	drone_locator_detection_timeout: 70,
	height: 0,
}

export default siteFormSchema
