import { useTranslation } from 'react-i18next'

// Form
import { useNavigate, useLocation } from 'react-router-dom'
import { useForm, FormProvider } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import type { TypeOf } from 'zod'

import { FormButtons } from '@Components/FormElements/FormButtons/FormButtons'

import {
	useGetGpsCompassQuery,
	useCreateGpsCompassMutation,
	useUpdateGpsCompassMutation,
} from '@Store/gpsCompasses/gpsCompassesApi'

import {
	selectSiteInstallationStatusColor,
	useGetSiteLiveQuery,
} from '@/store/sites/sitesWsApi'

import type { SensorStatusColor } from '@Store/types'
import { sensorStatusColorMap, type GpsCompass } from '@Store/types'
import { gpsCompassFormSchema } from './GpsCompassForm.schema'

import {
	useGetSiteQuery,
	useGetSiteInstallationQuery,
} from '@Store/sites/sitesApi'
import { skipToken } from '@reduxjs/toolkit/query'

import Field from '@Components/FormElements'
import FormHeader from '../FormHeader'
import GpsCompassWarning from './GpsCompassWarning'
import FormWrapper from '@Components/FormElements/FormWrapper/FormWrapper'
import ErrorBoundary from '@Components/App/ErrorHandling/ErrorBoundary'
import { Flex, Heading } from '@chakra-ui/react'
import StatusIcon from '@/components/_UI/StatusIcon/StatusIcon'
import { useRef } from 'react'
import { useExpandFormAccordion } from '@/hooks/useExpandFormAccordion'
import Accordion from '@/components/FormElements/Accordion/Accordion'

type GpsCompassFormProps = {
	siteId: number
	installationId: number
	sensorId?: number
}

const GpsCompassForm = ({
	siteId,
	installationId,
	sensorId,
}: GpsCompassFormProps) => {
	const { t } = useTranslation('forms', { keyPrefix: 'gpsCompassForm' })
	const isEditForm = !!sensorId

	const {
		isLoading,
		isError,
		isSuccess,
		refetch,
		data: gpsCompass,
	} = useGetGpsCompassQuery(sensorId ? { siteId, sensorId } : skipToken, {
		refetchOnMountOrArgChange: true,
	})

	// Looks for props passed through from navigate() via Unregistered Sensors
	const { state } = useLocation()
	const addGpsCompassDefaultValues = {
		...state,
		sentry_id: installationId,
		height_offset_to_installation: 0,
	} as GpsCompass

	const defaultValues = isEditForm ? gpsCompass : addGpsCompassDefaultValues
	return (
		<FormWrapper
			entity={t('entity')}
			isEditForm={isEditForm}
			isLoading={isLoading}
			isError={isError}
			isSuccess={isSuccess}
			refetch={refetch}
		>
			{defaultValues && (
				<Form
					defaultValues={defaultValues}
					isEditForm={isEditForm}
					siteId={siteId}
					installationId={installationId}
					sensorId={sensorId}
				/>
			)}
		</FormWrapper>
	)
}

const Form = ({
	defaultValues,
	isEditForm,
	siteId,
	installationId,
	sensorId,
}: {
	defaultValues: GpsCompass
	isEditForm: boolean
	siteId: number
	installationId: number
	sensorId?: number
}) => {
	const navigate = useNavigate()

	const { t } = useTranslation('forms', { keyPrefix: 'gpsCompassForm' })

	type Schema = TypeOf<typeof gpsCompassFormSchema>

	const methods = useForm<Schema>({
		resolver: zodResolver(gpsCompassFormSchema),
		defaultValues,
	})
	const {
		register,
		formState: { errors, isSubmitting, isDirty },
		setError,
		handleSubmit,
		watch,
	} = methods

	const [createGpsCompass] = useCreateGpsCompassMutation()
	const [updateGpsCompass] = useUpdateGpsCompassMutation()

	const gpsCompassesRoute = `/${siteId}/installations/${installationId}/gps_compasses`

	const { height_offset_to_installation: heightOffsetToInstallation } = watch()

	const { siteHeight } = useGetSiteQuery(siteId, {
		skip: !siteId,
		selectFromResult: ({ data, isSuccess }) => ({
			siteHeight: isSuccess ? data?.height : 0,
		}),
	})

	const ref = useRef(null)
	const { expandedIndex, setExpandedIndex, errorIndexes } =
		useExpandFormAccordion(ref, errors)

	// Handle absolute offset values
	const { installationName, sentryHeightOffset } = useGetSiteInstallationQuery(
		{ siteId, installationId },
		{
			skip: !installationId || !siteId,
			selectFromResult: ({ data, isSuccess }) => ({
				installationName: isSuccess ? data?.name : 'installation',
				sentryHeightOffset: isSuccess ? data?.height_offset_to_site : 0,
			}),
		}
	)

	const { statusColor } = useGetSiteLiveQuery(siteId, {
		selectFromResult: ({ data }) => ({
			statusColor: selectSiteInstallationStatusColor(data, installationId),
		}),
	})

	const absoluteHeight = (
		heightOffsetToInstallation +
		sentryHeightOffset +
		siteHeight
	).toFixed(2)

	const handleSave = async (data: Schema) => {
		try {
			if (isEditForm) {
				await updateGpsCompass({
					id: Number(sensorId),
					siteId: Number(siteId),
					...data,
				}).unwrap()
			} else {
				await createGpsCompass({ siteId: Number(siteId), ...data }).unwrap()
			}
			navigate(gpsCompassesRoute)
		} catch (e: unknown) {
			const errors = e as { [name in keyof Schema]: string }
			for (const field in errors) {
				setError(field as keyof Schema, {
					type: 'custom',
					message: errors[field as keyof Schema] as string,
				})
			}
		}
	}

	const handleCancel = () => navigate(gpsCompassesRoute)

	return (
		<>
			<FormHeader
				title={
					isEditForm
						? t('headings.gpsCompassSettings')
						: t('headings.addGpsCompass')
				}
				backRoute={gpsCompassesRoute}
			/>
			<Flex marginBlockStart={3}>
				<StatusIcon
					color={sensorStatusColorMap[statusColor as SensorStatusColor]}
					testId={`${installationId}-installation-status-color-${statusColor}`}
				/>
				<Heading
					color='white'
					alignContent='center'
					marginInlineStart={2}
					size='xs'
					data-testid={`installation-${installationId}`}
				>
					{installationName}
				</Heading>
			</Flex>
			<GpsCompassWarning />
			<Field.Divider title={t('headings.generalParameters')} />
			<ErrorBoundary>
				<FormProvider {...methods}>
					<form onSubmit={handleSubmit(handleSave)}>
						<Field.TextInput
							title={t('api.name')}
							register={register('name')}
							error={errors?.name?.message}
							testId='name'
						/>
						<Field.TextInput
							title={t('api.serialNumber')}
							register={register('serial_number')}
							disabled={isEditForm}
							tooltip={isEditForm ? t('tooltips.serial_number_disabled') : ''}
							error={errors?.serial_number?.message}
							testId='serial-number'
						/>
						<Accordion.Container
							setExpandedIndex={setExpandedIndex}
							index={expandedIndex}
						>
							<Accordion.Item
								title={t('headings.calibrationParameters')}
								testId='calibration-parameters'
								isError={errorIndexes.has(0)}
							>
								<Field.UnitsSlider
									isAbsolute
									units='m'
									customLabel={absoluteHeight}
									title={t('api.height_offset_to_installation')}
									tooltip={t('tooltips.height_offset_to_installation')}
									min={-100}
									max={200}
									step={0.1}
									register={register('height_offset_to_installation', {
										valueAsNumber: true,
									})}
									error={errors?.height_offset_to_installation?.message}
									testId='height-offset-to-installation'
								/>
							</Accordion.Item>
						</Accordion.Container>
						<FormButtons
							isSubmitting={isSubmitting}
							isDirty={isDirty}
							handleCancel={handleCancel}
						/>
					</form>
				</FormProvider>
			</ErrorBoundary>
		</>
	)
}

export default GpsCompassForm
