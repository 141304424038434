// React
import { memo, useEffect, useMemo } from 'react'
import { useMap, useMapEvents } from 'react-leaflet'
// Components
import Detections from '@Components/Site/MapLayers/Detections'
import SiteMarker from '@Components/Markers/SiteMarker/SiteMarker'
import useCenterMap from '@Components/Site/MapLayers/PreviewModes/useCenterMap'
import useValidCoordinates from '@Hooks/useValidCoordinates'
// Store
import { useAppDispatch, useAppSelector } from '@/store'
import {
	selectSiteLocationData,
	selectSiteCustomMapCenter,
	selectSiteZoom,
	setSiteLocationData,
	setSiteCustomMapCenter,
} from '@Store/ui/uiSlice'
import type { LatLngLiteral } from 'leaflet'
import useSiteId from '@Hooks/useSiteId'

const SitePreview = () => {
	const dispatch = useAppDispatch()
	const map = useMap()
	const siteId = useSiteId()
	const siteLocationData = useAppSelector(selectSiteLocationData)
	const siteCustomMapCenter = useAppSelector(selectSiteCustomMapCenter)
	const siteZoom = useAppSelector(selectSiteZoom)

	const latitude = useMemo(() => siteLocationData?.latitude, [siteLocationData])
	const longitude = useMemo(
		() => siteLocationData?.longitude,
		[siteLocationData]
	)

	useEffect(() => {
		map.setZoom(siteZoom)
	}, [siteZoom, map])

	const coordinates = useValidCoordinates(latitude, longitude)
	const isChangedFromForm = siteCustomMapCenter?.isChangedFromMap === false

	useCenterMap({
		latitude: isChangedFromForm ? siteCustomMapCenter?.latitude : latitude,
		longitude: isChangedFromForm ? siteCustomMapCenter?.longitude : longitude,
		animation: false,
	})

	const handleDragEnd = (coords: LatLngLiteral) => {
		const { lat: latitude, lng: longitude } = coords
		dispatch(setSiteLocationData({ latitude, longitude }))
	}

	const isSiteDraggable = !siteLocationData?.accept_location_updates

	// Set map center when custom coordinates selected
	useMapEvents({
		dragend() {
			const center = map.getCenter()
			if (siteCustomMapCenter?.isCustomMapCenter) {
				dispatch(
					setSiteCustomMapCenter({
						isCustomMapCenter: true,
						isChangedFromMap: true,
						latitude: center.lat,
						longitude: center.lng,
					})
				)
			}
		},
	})

	// Clean-up store after unmount
	useEffect(() => {
		return () => {
			dispatch(setSiteLocationData(null))
		}
	}, [dispatch])

	if (!siteLocationData) return null

	return (
		<>
			<Detections siteId={siteId} />
			<SiteMarker
				position={coordinates}
				onDragEnd={isSiteDraggable ? handleDragEnd : undefined}
				draggable={isSiteDraggable}
			/>
		</>
	)
}

export default memo(SitePreview)
