import { useTranslation } from 'react-i18next'
import { Box, Flex, Text } from '@chakra-ui/react'
import { MdDownload } from 'react-icons/md'
import Headings from '@UI/Headings/Headings'
import Button from '@UI/Button/Button'
import SiteLogsTable from './SiteLogsTable'
import { useGetSiteLogsQuery } from '@Store/analytics/analyticsApi'
import { downloadCsvV2 } from '@Utils/download'

type SiteLogsProps = {
	siteTimeZone: string
	siteId: number
	startTime: string
	endTime: string
}

const SiteLogs = ({
	siteTimeZone,
	siteId,
	startTime,
	endTime,
}: SiteLogsProps) => {
	const { t } = useTranslation('pages', {
		keyPrefix: 'analytics.siteLogs',
	})

	const { totalCount } = useGetSiteLogsQuery(
		{
			siteId,
			format: 'table',
			startTime: startTime,
			endTime: endTime,
			page: 0,
			perPage: 10,
		},
		{
			selectFromResult: ({ data }) => ({
				totalCount: data?.meta.total,
			}),
		}
	)

	const handleDownloadReport = async () => {
		try {
			const url = `/api/reports/${siteId}/site_logs/csv`
			await downloadCsvV2(url, {
				start_time: startTime,
				end_time: endTime,
			})
		} catch (error) {
			console.error('TODO: unhandled download site logs report error', error)
		}
	}

	return (
		<Box width='100%'>
			<Flex justifyContent='space-between' alignItems='center'>
				<Headings.ChartHeading title={t('siteLogsDetails')} />
				<Flex alignItems='center'>
					<Headings.FieldHeading title={t('siteLogs')} />
					<Button
						label={t('download')}
						testId='download'
						size='sm'
						leftIcon={<MdDownload />}
						onClick={handleDownloadReport}
					/>
				</Flex>
			</Flex>
			<Flex alignItems='center' gap='8px' my={4}>
				<Headings.FieldHeading title={t('total')} />
				<Text fontSize='xl' as='b'>
					{totalCount}
				</Text>
			</Flex>
			<SiteLogsTable
				siteTimeZone={siteTimeZone}
				siteId={siteId}
				startTime={startTime}
				endTime={endTime}
				testId='site-logs-table'
			/>
		</Box>
	)
}

export default SiteLogs
