// Packages
import { DatePicker as DatePickerArk } from '@ark-ui/react'
import noop from 'lodash/noop'

export type DatePickerCalendarHeaderProps = {
	onClickToday?: () => void
}

const DatePickerCalendarHeader = ({
	onClickToday = noop,
}: DatePickerCalendarHeaderProps) => {
	return (
		<div data-scope='date-picker' data-part='content-body-calendar-header'>
			<div data-scope='date-picker' data-part='label-year-select'>
				<DatePickerArk.Label>Year</DatePickerArk.Label>
				<DatePickerArk.YearSelect />
			</div>
			<div
				data-scope='date-picker'
				data-part='label-today'
				onClick={onClickToday}
			>
				Today
			</div>
		</div>
	)
}

export default DatePickerCalendarHeader
