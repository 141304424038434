import { Icon } from '@chakra-ui/react'
import { MdFiberManualRecord } from 'react-icons/md'
import {
	sensorStatusColorMap,
	type SensorType,
	type StatusColor,
} from '@Store/types'

const StatusDot = ({
	color = 'red',
	size = '16px',
	type,
	id,
}: {
	color: StatusColor
	size?: string
	type?: SensorType
	id?: number
}) => {
	return (
		<Icon
			as={MdFiberManualRecord}
			color={sensorStatusColorMap[color]}
			boxSize={size}
			data-testid={`${type}-${id}-status-color-${color}`}
		/>
	)
}

export default StatusDot
