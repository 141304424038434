import { Box, HStack, Flex, Text } from '@chakra-ui/react'
import { useMemo } from 'react'

export interface DistributionChartItem {
	label: string
	weight: number | undefined
	color: string
	chartLabel: string
}

const DistributionChart = ({ items }: { items: DistributionChartItem[] }) => {
	const validItems = useMemo(() => {
		return items.filter((item) => item.weight && item.weight > 0)
	}, [items])

	return (
		<Box>
			<HStack spacing={1} marginTop={3}>
				{validItems.map((item, index) => {
					return (
						<Box
							key={index}
							w={`${item.weight ? item.weight * 100 : 0}%`}
							h='8px'
							bg={item.color}
							transition='width 0.3s'
						/>
					)
				})}
			</HStack>
			<Flex alignItems='center' marginTop={3} wrap='wrap'>
				{items.map((item, index) => {
					return (
						<Flex alignItems='center' marginRight={4} key={index}>
							<Box
								w='10px'
								h='10px'
								bg={item.weight ? item.color : 'disabled'}
								opacity={item.weight ? undefined : 0.5}
								borderRadius='100%'
								marginRight={2}
							/>
							<Text
								fontSize='xs'
								color={item.weight ? 'body_text' : 'disabled'}
							>
								{item.chartLabel}
							</Text>
						</Flex>
					)
				})}
			</Flex>
		</Box>
	)
}

export default DistributionChart
