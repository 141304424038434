import { baseApi } from '@Store/baseApi'

import type { RfSensorFilter } from '../types'
import type { CoreApiError } from '../utils/errorUtils'
import { formatApiErrors } from '../utils/errorUtils'

export const rfFiltersApi = baseApi
	.enhanceEndpoints({ addTagTypes: ['RfSensorFilter'] })
	.injectEndpoints({
		endpoints: (builder) => ({
			getRfSensorFilters: builder.query<
				RfSensorFilter[],
				{ siteId: number } & { sensorId: number }
			>({
				query: ({ siteId, sensorId }) => ({
					url: `/api/sites/${siteId}/rf_sensors/${sensorId}/rf_filters`,
				}),
				providesTags: ['RfSensorFilter'],
				transformResponse: (response: { rf_filters: RfSensorFilter[] }) =>
					response.rf_filters.sort((a, b) => a.id - b.id),
			}),
			createRfSensorFilter: builder.mutation<
				RfSensorFilter,
				{ siteId: number } & {
					rf_filter: RfSensorFilter
				}
			>({
				query: ({ siteId, rf_filter }) => ({
					url: `/api/sites/${siteId}/rf_filters`,
					method: 'POST',
					body: {
						rf_filter: { ...rf_filter },
					},
				}),
				invalidatesTags: ['RfSensorFilter'],
				transformResponse: (response: { rf_filter: RfSensorFilter }) =>
					response.rf_filter,
				transformErrorResponse: (response: CoreApiError) =>
					formatApiErrors<RfSensorFilter>(response),
			}),
			updateRfSensorFilter: builder.mutation<
				RfSensorFilter,
				{ siteId: number } & { rfFilterId: number } & {
					rf_filter: RfSensorFilter
				}
			>({
				query: ({ siteId, rfFilterId, rf_filter }) => ({
					url: `/api/sites/${siteId}/rf_filters/${rfFilterId}`,
					method: 'PATCH',
					body: {
						rf_filter: { ...rf_filter },
					},
				}),
				invalidatesTags: ['RfSensorFilter'],
				transformResponse: (response: { rf_filter: RfSensorFilter }) =>
					response.rf_filter,
				transformErrorResponse: (response: CoreApiError) =>
					formatApiErrors<RfSensorFilter>(response),
			}),
			deleteRfSensorFilter: builder.mutation<
				{ message: string },
				Pick<RfSensorFilter, 'id'> & { siteId: number }
			>({
				query: ({ id: rf_filter_id, siteId }) => ({
					url: `/api/sites/${siteId}/rf_filters/${rf_filter_id}`,
					method: 'DELETE',
				}),
				invalidatesTags: ['RfSensorFilter'],
			}),
		}),
	})

export const {
	useGetRfSensorFiltersQuery,
	useCreateRfSensorFilterMutation,
	useDeleteRfSensorFilterMutation,
	useUpdateRfSensorFilterMutation,
} = rfFiltersApi
