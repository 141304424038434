import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useExpandFormAccordion } from '@Hooks/useExpandFormAccordion'
// Form
import { useNavigate, useLocation } from 'react-router-dom'
import { useForm, FormProvider } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import type { TypeOf } from 'zod'

// Chakra
import { Flex, Heading } from '@chakra-ui/react'
import Field from '@Components/FormElements'
import FormHeader from '../FormHeader'
import { FormButtons } from '@Components/FormElements/FormButtons/FormButtons'
import FormWrapper from '@Components/FormElements/FormWrapper/FormWrapper'
import ErrorBoundary from '@Components/App/ErrorHandling/ErrorBoundary'
import StatusIcon from '@/components/_UI/StatusIcon/StatusIcon'
import Accordion from '@Components/FormElements/Accordion/Accordion'

// Redux
import {
	useCreateDisruptorMutation,
	useUpdateDisruptorBandsMutation,
	useUpdateDisruptorMutation,
	useGetDisruptorQuery,
} from '@Store/disruptors/disruptorsApi'
import {
	selectSiteInstallationStatusColor,
	useGetSiteLiveQuery,
} from '@/store/sites/sitesWsApi'
import {
	useGetSiteInstallationQuery,
	useGetSiteQuery,
} from '@Store/sites/sitesApi'
import type { SensorStatusColor } from '@Store/types'
import { sensorStatusColorMap, type Disruptor } from '@Store/types'
import type {
	DisruptorRequest,
	UpdateDisruptorBandsRequest,
} from '@Store/disruptors/disruptorsApi'
import { useAppDispatch } from '@Store/index'
import { updateSensorPreview } from '@/store/ui/uiSlice'
import { skipToken } from '@reduxjs/toolkit/query'

// Schema
import {
	disruptorFormSchema,
	ADD_DISRUPTOR_DEFAULT_VALUES,
} from './DisruptorForm.schema'

const disruptorFields = [
	'sentry_id',
	'name',
	'serial_number',
	'direction_offset',
	'reach',
	'shutoff_time',
	'height_offset_to_installation',
]

const disruptorBandFields = [
	'band_24_58_trigger_engaged',
	'band_433_trigger_engaged',
	'band_915_trigger_engaged',
	'gnss_trigger_engaged',
]

type DisruptorFormProps = {
	siteId: number
	installationId: number
	sensorId?: number
}

const DisruptorForm = ({
	siteId,
	installationId,
	sensorId,
}: DisruptorFormProps) => {
	const { t } = useTranslation('forms', { keyPrefix: 'disruptorForm' })
	const isEditForm = !!sensorId

	const {
		isLoading,
		isError,
		isSuccess,
		refetch,
		data: disruptor,
	} = useGetDisruptorQuery(
		siteId && sensorId ? { siteId, sensorId } : skipToken,
		{ refetchOnMountOrArgChange: true }
	)

	// Looks for props passed through from navigate() via Unregistered Sensors
	const { state } = useLocation()
	const addDisruptorDefaultValues = {
		...ADD_DISRUPTOR_DEFAULT_VALUES,
		...state,
		sentry_id: installationId,
	} as Disruptor

	const defaultValues = isEditForm ? disruptor : addDisruptorDefaultValues

	return (
		<FormWrapper
			entity={t('entity')}
			isEditForm={isEditForm}
			isLoading={isLoading}
			isError={isError}
			isSuccess={isSuccess}
			refetch={refetch}
		>
			{defaultValues && (
				<Form
					key={sensorId}
					defaultValues={defaultValues}
					isEditForm={isEditForm}
					siteId={siteId}
					installationId={installationId}
					sensorId={sensorId}
				/>
			)}
		</FormWrapper>
	)
}

const Form = ({
	defaultValues,
	isEditForm,
	siteId,
	installationId,
	sensorId,
}: {
	defaultValues: Disruptor
	isEditForm: boolean
	siteId: number
	installationId: number
	sensorId?: number
}) => {
	const navigate = useNavigate()
	const { t } = useTranslation('forms', { keyPrefix: 'disruptorForm' })

	type Schema = TypeOf<typeof disruptorFormSchema>

	const methods = useForm<Schema>({
		resolver: zodResolver(disruptorFormSchema),
		defaultValues,
	})

	const {
		register,
		formState: { errors, isSubmitting, isDirty, dirtyFields },
		handleSubmit,
		setError,
		watch,
	} = methods

	const ref = useRef(null)
	const { expandedIndex, setExpandedIndex, errorIndexes } =
		useExpandFormAccordion(ref, errors)

	const { siteHeight } = useGetSiteQuery(siteId, {
		skip: !siteId,
		selectFromResult: ({ data, isSuccess }) => ({
			siteHeight: isSuccess ? data?.height : 0,
		}),
	})

	// Handle absolute offset values
	const { installationName, sentryDirection, sentryHeightOffset } =
		useGetSiteInstallationQuery(
			{ siteId: Number(siteId), installationId: Number(installationId) },
			{
				skip: !installationId || !siteId,
				selectFromResult: ({ data, isSuccess }) => ({
					installationName: data?.name,
					sentryDirection: isSuccess ? data?.direction : 0,
					sentryHeightOffset: isSuccess ? data?.height_offset_to_site : 0,
				}),
			}
		)

	const { statusColor } = useGetSiteLiveQuery(siteId, {
		selectFromResult: ({ data }) => ({
			statusColor: selectSiteInstallationStatusColor(data, installationId),
		}),
	})
	const {
		direction_offset: directionOffset,
		height_offset_to_installation: heightOffsetToInstallation,
	} = watch()

	const absoluteDirection = Number(
		(directionOffset + sentryDirection) % 360
	).toFixed(2)
	const absoluteHeight = (
		heightOffsetToInstallation +
		sentryHeightOffset +
		siteHeight
	).toFixed(2)
	const sentryId = watch('sentry_id')
	const reach = watch('reach')

	const dispatch = useAppDispatch()
	useEffect(() => {
		dispatch(
			updateSensorPreview({
				sentryId,
				directionOffset,
				heightOffsetToInstallation,
				reach,
			})
		)
		return () => {
			dispatch(updateSensorPreview(null))
		}
	}, [sentryId, directionOffset, heightOffsetToInstallation, reach, dispatch])

	const [createDisruptor] = useCreateDisruptorMutation()
	const [updateDisruptor] = useUpdateDisruptorMutation()
	const [updateDisruptorBands] = useUpdateDisruptorBandsMutation()

	const disruptorsRoute = `/${siteId}/installations/${installationId}/disruptors`

	const isFieldChanged = (fields: string[]) => {
		for (const property in dirtyFields) {
			if (fields.includes(property)) return true
		}
		return false
	}

	const isDisruptorChanged = isFieldChanged(disruptorFields)
	const isDisruptorBandChanged = isFieldChanged(disruptorBandFields)

	const handleSave = async (data: Schema) => {
		const disruptorRequest: DisruptorRequest = {
			sentry_id: installationId,
			name: data.name,
			serial_number: data.serial_number,
			direction_offset: data.direction_offset,
			reach: data.reach,
			shutoff_time: data.shutoff_time,
			height_offset_to_installation: data.height_offset_to_installation,
		}

		try {
			if (isEditForm && sensorId) {
				const updateDisruptorBandsRequest: UpdateDisruptorBandsRequest = {
					band_24_58_trigger_command: data.band_24_58_trigger_engaged,
					band_433_trigger_command: data.band_433_trigger_engaged,
					band_915_trigger_command: data.band_915_trigger_engaged,
					gnss_trigger_command: data.gnss_trigger_engaged,
				}

				if (isDisruptorChanged) {
					await updateDisruptor({
						siteId: Number(siteId),
						id: sensorId,
						...disruptorRequest,
					}).unwrap()
				}
				if (isDisruptorBandChanged) {
					await updateDisruptorBands({
						siteId: Number(siteId),
						id: sensorId,
						...updateDisruptorBandsRequest,
					}).unwrap()
				}
			} else {
				await createDisruptor({
					siteId: Number(siteId),
					...disruptorRequest,
				}).unwrap()
			}
			navigate(disruptorsRoute)
		} catch (e: unknown) {
			const errors = e as { [name in keyof Schema]: string }
			for (const field in errors) {
				setError(field as keyof Schema, {
					type: 'custom',
					message: errors[field as keyof Schema] as string,
				})
			}
		}
	}

	const handleCancel = () => navigate(disruptorsRoute)

	const isDisruptorOnline = (defaultValues as Disruptor).status_color !== 'red'

	return (
		<>
			<FormHeader
				title={
					isEditForm
						? t('headings.disruptorSettings')
						: t('headings.addDisruptor')
				}
				backRoute={disruptorsRoute}
			/>
			<ErrorBoundary>
				<FormProvider {...methods}>
					<form onSubmit={handleSubmit(handleSave)} ref={ref}>
						<Flex marginBlockStart={3}>
							<StatusIcon
								color={sensorStatusColorMap[statusColor as SensorStatusColor]}
								testId={`${installationId}-installation-status-color-${statusColor}`}
							/>
							<Heading
								color='white'
								alignContent='center'
								marginInlineStart={2}
								size='xs'
								data-testid={`installation-${installationId}`}
							>
								{installationName}
							</Heading>
						</Flex>
						{isEditForm && (
							<>
								<Field.Divider title={t('headings.bands')} />
								<Flex w='380px' paddingLeft='4px'>
									<Field.Switch
										title={'2.4\n5.8'}
										register={register('band_24_58_trigger_engaged')}
										testId='24-58-switch'
										disabled={!isDisruptorOnline}
									/>
									<Field.Switch
										title='915'
										register={register('band_915_trigger_engaged')}
										testId='915-switch'
										disabled={!isDisruptorOnline}
									/>
									<Field.Switch
										title='433'
										register={register('band_433_trigger_engaged')}
										testId='433-switch'
										disabled={!isDisruptorOnline}
									/>
									<Field.Switch
										title='GNSS'
										register={register('gnss_trigger_engaged')}
										testId='gnss-switch'
										disabled={!isDisruptorOnline}
									/>
								</Flex>
							</>
						)}
						<Field.Divider title={t('headings.generalParameters')} />
						<Field.TextInput
							title={t('api.name')}
							register={register('name')}
							error={errors?.name?.message}
							testId='name'
						/>
						<Field.TextInput
							title={t('api.serialNumber')}
							register={register('serial_number')}
							disabled={isEditForm}
							tooltip={isEditForm ? t('tooltips.serial_number_disabled') : ''}
							error={errors?.serial_number?.message}
							testId='serial-number'
						/>
						<Field.Slider
							title={t('api.shutoffTime')}
							tooltip={t('tooltips.shutoffTime')}
							min={30}
							max={600}
							register={register('shutoff_time', { valueAsNumber: true })}
							error={errors?.shutoff_time?.message}
							testId='shutoff-time'
						/>
						<Accordion.Container
							setExpandedIndex={setExpandedIndex}
							index={expandedIndex}
						>
							<Accordion.Item
								title={t('headings.displaySettings')}
								testId='display-settings'
								isError={errorIndexes.has(0)}
							>
								<Field.Slider
									title={t('api.reach')}
									min={100}
									max={5000}
									register={register('reach', { valueAsNumber: true })}
									error={errors?.reach?.message}
									testId='display-range'
								/>
							</Accordion.Item>
							<Accordion.Item
								title={t('headings.calibrationParameters')}
								testId='calibration-parameters'
								isError={errorIndexes.has(1)}
							>
								<Field.UnitsSlider
									isAbsolute
									units='m'
									customLabel={absoluteHeight}
									title={t('api.height_offset_to_installation')}
									tooltip={t('tooltips.height_offset_to_installation')}
									min={-100}
									max={200}
									step={0.1}
									register={register('height_offset_to_installation', {
										valueAsNumber: true,
									})}
									error={errors?.height_offset_to_installation?.message}
									testId='height-offset-to-installation'
								/>
								<Field.UnitsSlider
									isAbsolute
									units='deg'
									customLabel={absoluteDirection}
									title={t('api.directionOffset')}
									min={0}
									max={359.99}
									step={0.1}
									register={register('direction_offset', {
										valueAsNumber: true,
									})}
									error={errors?.direction_offset?.message}
									testId='direction-offset'
								/>
							</Accordion.Item>
						</Accordion.Container>
						<FormButtons
							isSubmitting={isSubmitting}
							isDirty={isDirty}
							handleCancel={handleCancel}
						/>
					</form>
				</FormProvider>
			</ErrorBoundary>
		</>
	)
}

export default DisruptorForm
