// Packages
import { useMemo } from 'react'

// Relatives
import NotificationContext from './NotificationContext'

// Types
import type { Detection, DetectionContributionSensor } from '@/store/types'

export type NotificationContextProviderProps = {
	children: React.ReactNode
	detection?: Detection & { zoneName?: string; isAlert?: boolean }
	expiredAt?: number
	seenContributor?: DetectionContributionSensor[]
	isStale?: boolean
}

const getThreatLevel = (threat: number) => {
	if (threat < 0.5) {
		return 'low'
	}

	if (threat >= 0.5 && threat < 0.75) {
		return 'medium'
	}

	return 'high'
}

const NotificationContextProvider = ({
	children,
	detection,
	expiredAt = 0,
	seenContributor = [],
	isStale = false,
}: NotificationContextProviderProps) => {
	const {
		drone_locator_confirmed,
		target_id,
		probability,
		threat_level,
		classification,
		detection_contributions,
		latitude,
		longitude,
		site_distance,
		frequency,
		rssi,
		rcs,
		ssid,
		protocol,
		heading,
		state,
		speed,
		vendor,
		height_offset_to_site,
		prevent_save,
		display_filtered,
		gcs_latitude,
		gcs_longitude,
		home_latitude,
		home_longitude,
		drone_serial_number,
		time_on_target,
		zoneName,
		threat_contributions,
	} = detection ?? {}
	const value = useMemo(
		() => ({
			hasDroneLocator: drone_locator_confirmed,
			targetId: target_id,
			confidence: probability,
			threat: threat_level,
			classification: classification,
			contributions: detection_contributions,
			seenContributor,
			latitude,
			longitude,
			frequency,
			state,
			rssi,
			rcs,
			ssid,
			protocol,
			heading,
			speed,
			vendor,
			preventSave: prevent_save,
			displayFiltered: display_filtered,
			heightOffsetToSite: height_offset_to_site,
			gcsLatitude: gcs_latitude,
			gcsLongitude: gcs_longitude,
			homeLatitude: home_latitude,
			homeLongitude: home_longitude,
			droneSerialNumber: drone_serial_number,
			timeOnTarget: time_on_target,
			siteDistance: site_distance,
			threatContributions: threat_contributions,
			zoneName,
			expiredAt,
			// Extras
			threatLevel: getThreatLevel(threat_level ?? 0) as
				| 'high'
				| 'medium'
				| 'low'
				| undefined,
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			drone_locator_confirmed,
			target_id,
			probability,
			threat_level,
			classification,
			prevent_save,
			display_filtered,
			// eslint-disable-next-line react-hooks/exhaustive-deps
			!isStale ? detection_contributions : undefined,
			// eslint-disable-next-line react-hooks/exhaustive-deps
			!isStale ? seenContributor : undefined,
			latitude,
			longitude,
			site_distance,
			frequency,
			state,
			rssi,
			rcs,
			ssid,
			protocol,
			heading,
			speed,
			vendor,
			height_offset_to_site,
			gcs_latitude,
			gcs_longitude,
			home_latitude,
			home_longitude,
			threat_contributions,
			drone_serial_number,
			time_on_target,
			zoneName,
			expiredAt,
		]
	)

	return (
		<NotificationContext.Provider value={value}>
			{children}
		</NotificationContext.Provider>
	)
}

export default NotificationContextProvider
