// Packages
import { Box, Flex, Text } from '@chakra-ui/react'

// Types
import type { BarDatum } from '@nivo/bar'

export type FlightsBarChartTooltipProps = {
	id: string | number
	color: string
	value: number
	data: BarDatum
}

const DisruptionsBarChartTooltip = ({
	id,
	color,
	value,
	data,
}: FlightsBarChartTooltipProps) => {
	const { day } = data

	return (
		<Flex
			direction='column'
			width='200px'
			bgColor='body_bg'
			gap='8px'
			padding='12px 16px'
			fontSize='14px'
			zIndex='999999999999'
		>
			<Flex gap='4px' alignItems='center'>
				<Box backgroundColor={color} height='12px' width='12px' />
				<Text>{id}:</Text>
				<Text>{value}</Text>
			</Flex>
			<Text color='label_color'>{day}</Text>
		</Flex>
	)
}

export default DisruptionsBarChartTooltip
