import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Flex, Icon, Text } from '@chakra-ui/react'
import { MdClose, MdInfo } from 'react-icons/md'
import type {
	DeepRequired,
	FieldErrorsImpl,
	GlobalError,
} from 'react-hook-form'
import type { Schema } from 'zod'

const FormStatusErrors = ({
	errors,
}: {
	errors?: Partial<FieldErrorsImpl<DeepRequired<Schema>>> & {
		root?: Record<string, GlobalError> & GlobalError
	}
}) => {
	const serverError = errors?.root?.serverError.message
	const [isOpened, setOpened] = useState(!!serverError)
	const { t } = useTranslation('forms', { keyPrefix: 'global' })

	useEffect(() => {
		// open the alert again if errors is mutating, that means that is a new retry and the alert should be popup again
		if (!isOpened && serverError) {
			setOpened(true)
		}
	}, [errors, serverError]) // eslint-disable-line

	return (
		<>
			{serverError && isOpened && (
				<Flex
					gap={3}
					marginBlock={3}
					p={2}
					bgColor='input_bg'
					alignItems='center'
				>
					<Icon as={MdInfo} color='error' />
					<Text fontSize='sm' fontWeight={500}>
						{t(`serverErrors.${serverError}`)}
					</Text>
					<Icon
						as={MdClose}
						ml='auto'
						cursor='pointer'
						onClick={() => setOpened(false)}
					/>
				</Flex>
			)}
		</>
	)
}

export default FormStatusErrors
