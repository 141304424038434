import Button from '@UI/Button/Button'
import { Flex } from '@chakra-ui/react'
import {
	selectHasUnreadWarnings,
	selectUnreadWarningsCount,
	useGetSiteWarningsQuery,
	useUpdateAllSiteWarningsAsReadMutation,
} from '@Store/siteWarnings/siteWarningsApi'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '@/store'
import type { FormError } from '@/components/Forms/types'
import { addToast } from '@/store/ui/uiSlice'

const SystemWarningsAcknowledgeAll = ({ siteId }: { siteId: number }) => {
	const dispatch = useAppDispatch()
	const { t } = useTranslation('navigation', {
		keyPrefix: 'systemWarnings',
	})
	const { isUnread, count } = useGetSiteWarningsQuery(siteId, {
		skip: !siteId,
		selectFromResult: ({ data }) => ({
			isUnread: selectHasUnreadWarnings(data),
			count: selectUnreadWarningsCount(data),
		}),
	})

	const [updateAllWarningsAsRead] = useUpdateAllSiteWarningsAsReadMutation()

	const handleUpdateAllAsRead = async () => {
		await updateAllWarningsAsRead({
			siteId,
		})
			.unwrap()
			.catch((error) => {
				dispatch(
					addToast({
						id: undefined,
						siteId,
						type: 'error',
						title: t('toast.updateErrorTitle'),
						description: (error as FormError)?.data?.error ?? error?.error,
						error: error,
					})
				)
			})
	}

	return (
		<Flex justifyContent='flex-end'>
			{isUnread && (
				<Button
					testId='acknowledge-all'
					variant='link'
					size='sm'
					label={`${t('buttons.acknowledgeAll')} (${count})`}
					textTransform='none'
					fontWeight={500}
					onClick={handleUpdateAllAsRead}
				/>
			)}
		</Flex>
	)
}

export default SystemWarningsAcknowledgeAll
