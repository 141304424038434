// React
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
// Chakra
import {
	Flex,
	IconButton,
	Table,
	TableContainer,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react'
import { MdEdit } from 'react-icons/md'
// Components
import FetchError from '@UI/FetchError/FetchError'
import EditButton from '@Components/Site/Panels/SiteInstallations/EditButton'
import CotDeleteModal from '@Forms/CotForm/CotDeleteModal'
import CotActiveToggleSwitch from '@Forms/CotForm/CotActiveToggleSwitch'
// Store
import { useGetCotsQuery } from '@Store/cots/cotsApi'

const Cots = () => {
	const { t } = useTranslation('forms', { keyPrefix: 'cotForm' })
	const { siteId: siteIdParam } = useParams()
	const navigate = useNavigate()
	const siteId = Number(siteIdParam)

	const {
		data: cots,
		isSuccess,
		isError,
		refetch,
	} = useGetCotsQuery(siteId, {
		skip: !siteId,
	})

	// Handlers
	const handleAdd = () => {
		navigate(`/${siteId}/cot/add`)
	}
	const handleEdit = (id: number) => {
		navigate(`/${siteId}/cot/${id}/settings`)
	}

	return (
		<>
			<Flex my={2} alignItems='center' justifyContent='space-between'>
				<Text>{t('headings.cot')}</Text>
				<EditButton
					label={t('buttons.add')}
					type='add'
					onClick={handleAdd}
					tooltip={t('tooltips.addNewCot')}
					data-testid='add-cots-endpoint'
				/>
			</Flex>
			{isError && (
				<FetchError refetch={refetch} errorMessage={t('status.error')} />
			)}

			{isSuccess && (
				<TableContainer>
					<Table variant='filledStyle' size='cots' mt={4}>
						<Thead>
							<Tr>
								<Th>{/* ACTIVE COT TOGGLE */}</Th>
								<Th>{t('table.ip')}</Th>
								<Th textAlign='center'>{t('table.protocol')}</Th>
								<Th textAlign='center'>{t('table.minProb')}</Th>
								<Th w='80px' textAlign='right'>
									{t('table.actions')}
								</Th>
							</Tr>
						</Thead>
						<Tbody>
							{cots?.map((cot) => {
								const { id, ip, port, protocol, minimum_probability } = cot
								return (
									<Tr key={id}>
										<Td w='60px'>
											<CotActiveToggleSwitch cot={cot} />
										</Td>
										<Td>
											{ip}:{port}
										</Td>
										<Td textAlign='center'>{protocol?.toUpperCase()}</Td>
										<Td textAlign='center'>{minimum_probability * 100}%</Td>
										<Td maxW='80px' textAlign='right'>
											{/*EDIT*/}
											<IconButton
												variant='ghost'
												colorScheme='white'
												aria-label='edit'
												icon={<MdEdit />}
												onClick={() => handleEdit(id)}
											/>
											{/*DELETE*/}
											<CotDeleteModal siteId={siteId} cotId={id} />
										</Td>
									</Tr>
								)
							})}
						</Tbody>
					</Table>
				</TableContainer>
			)}
		</>
	)
}

export default Cots
