import { useTranslation } from 'react-i18next'

import { Flex } from '@chakra-ui/react'
import { MdFilterAlt } from 'react-icons/md'

import Button from '@UI/Button/Button'
import Skeleton from '@UI/Skeleton/Skeleton'
import DebouncedInput from '@UI/DebouncedInput/DebouncedInput'
import MultiSelect from '@UI/Select/MultiSelect'
import { SelectWrapper } from '../../TrackSummary/EventDetailsTable/EventFilter'

import type { AlertSummary } from '@Store/types'
import { type Table } from '@tanstack/react-table'
import type { MultiSelectOption } from '@UI/Select/MultiSelect'
import { useGetAlertsSummaryQuery } from '@/store/analytics/analyticsApi'

type AlertsFilterProps<TData> = {
	table: Table<TData>
	isTactical?: boolean
	siteId: number
	startTime: string
	endTime: string
}

const AlertsFilter = <TData = AlertSummary[],>({
	isTactical = false,
	siteId,
	table,
	startTime,
	endTime,
}: AlertsFilterProps<TData>) => {
	const { t } = useTranslation('pages', {
		keyPrefix: 'analytics.alertsSummary.table',
	})

	const { setGlobalFilter } = table
	const globalFilter = table.getState().globalFilter

	const {
		isLoading,
		isSuccess,
		data: filter_options,
	} = useGetAlertsSummaryQuery({
		siteId: siteId,
		format: 'filter_options',
		startTime: startTime,
		endTime: endTime,
	})

	const handleFilterChange = (
		filterValues: MultiSelectOption[],
		id: string
	) => {
		const filters = filterValues.map((filter) => filter.value).join(',')
		table.getColumn(id)?.setFilterValue(filters)
		table.setPageIndex(0)
	}

	return (
		<Skeleton isLoaded={!isLoading}>
			<Flex alignItems='flex-end' gap='10px' mb={2} flexWrap='wrap'>
				<DebouncedInput
					title={t('search')}
					value={globalFilter ?? ''}
					onChange={(value) => setGlobalFilter(String(value))}
					placeholder={t('search')}
					data-testid='content-search'
					flexGrow={1}
					debounce={750}
				/>
				<Button
					size='xs'
					label={t('filters')}
					leftIcon={<MdFilterAlt />}
					testId='filters'
					pointerEvents='none'
					color='label_color'
				/>
				{isSuccess && !isTactical && (
					<SelectWrapper>
						<MultiSelect
							title={t('recipients')}
							placeholder={t('select')}
							options={filter_options.recipients}
							onChange={(value: MultiSelectOption[]) =>
								handleFilterChange(value, 'recipients')
							}
						/>
					</SelectWrapper>
				)}
				{isSuccess && (
					<SelectWrapper>
						<MultiSelect
							title={t('alertType')}
							placeholder={t('select')}
							options={filter_options.types}
							onChange={(value: MultiSelectOption[]) =>
								handleFilterChange(value, 'source')
							}
						/>
					</SelectWrapper>
				)}
			</Flex>
		</Skeleton>
	)
}

export default AlertsFilter
