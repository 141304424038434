import { useContext } from 'react'
import RecordingToolContext from '../RecordingToolContext'

const useRecordingTool = () => {
	const context = useContext(RecordingToolContext)

	if (context === undefined) {
		throw new Error(
			'useRecordingTool must be used within <RecordingToolProvider>'
		)
	}

	const {
		isRecording,
		recordingOwner,
		durationStr,
		startRecording,
		stopRecording,
		discardRecording,
		recordingEnabled,
	} = context

	return {
		isRecording,
		recordingOwner,
		startRecording,
		stopRecording,
		discardRecording,
		durationStr,
		recordingEnabled,
	}
}

export default useRecordingTool
