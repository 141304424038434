import { Box, Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

type AglMaskLegendProps = {
	isLargePreview?: boolean
}

const AglMaskLegend = ({ isLargePreview = false }: AglMaskLegendProps) => {
	const { t } = useTranslation('panels', { keyPrefix: 'aglMask' })
	return (
		<Flex
			gap={2}
			marginBlock={3}
			paddingInlineStart={3}
			justifyContent={isLargePreview ? 'start' : 'center'}
		>
			<Flex alignItems='center' gap={1}>
				<Box w={2} h={2} borderRadius='100%' bgColor='primary' />
				<Text fontSize='xs'>{t('maskedArea')}</Text>
			</Flex>
			<Flex alignItems='center' gap={1}>
				<Box w={2} h={2} borderRadius='100%' bgColor='gray.500' />
				<Text fontSize='xs'>{t('detectionArea')}</Text>
			</Flex>
		</Flex>
	)
}

export default AglMaskLegend
