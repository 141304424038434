// Packages
import { Box, Flex, Text } from '@chakra-ui/react'

// Types
import type { BarDatum } from '@nivo/bar'

export type FlightsBarChartTooltipProps = {
	value: number
	data: BarDatum
	flightsByHour: { [key: string]: { [key: string]: number } }
	indexValue: number | string
}

const FlightsBarChartTooltip = ({
	value,
	data,
	indexValue,
	flightsByHour,
}: FlightsBarChartTooltipProps) => {
	const { hour } = data
	let flightsData
	if (hour) {
		flightsData = flightsByHour[hour] ?? {}
	}

	return (
		<Flex
			direction='column'
			width='200px'
			bgColor='body_bg'
			gap='8px'
			padding='12px 16px'
			fontSize='14px'
		>
			<Text>{indexValue}</Text>
			{flightsData &&
				Object.keys(flightsData).map((day, i) => (
					<Flex key={i} justifyContent='space-between'>
						<Text color='label_color'>{day}</Text>
						<Box>{`${flightsData[day]} Flights`}</Box>
					</Flex>
				))}
			<Flex justifyContent='space-between'>
				<Text color='label_color'>Total</Text>
				<Box>{`${value} Flights`}</Box>
			</Flex>
		</Flex>
	)
}

export default FlightsBarChartTooltip
