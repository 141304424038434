// Packages
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import get from 'lodash/get'

// Alias
import SelectUI from '@Components/_UI/Select/Select'

// Types
import type { RadiatingCircle } from '@/store/types'

export type MarkerCenterProps = {
	siteId: number
	options: { label: string; value: RadiatingCircle }[]
	value: Record<number, RadiatingCircle>
	disabled?: boolean
	loading?: boolean
	onChange: (option: { label: string; value: RadiatingCircle }) => void
}

const MarkerCenter = ({
	siteId,
	disabled = false,
	loading = false,
	options,
	value,
	onChange,
}: MarkerCenterProps) => {
	const { t } = useTranslation('forms', {
		keyPrefix: 'mapsZonesForm.displaySettings',
	})

	const defaultValue = useMemo(
		() =>
			options.find(
				(option) =>
					option.value.name === get(value, `${siteId}.name`, options[0])
			),
		[options, siteId, value]
	)

	const valueParsed = useMemo(() => {
		const option = options?.find((option) => option?.value === value)

		return option ?? defaultValue
	}, [options, value, defaultValue])

	return (
		<SelectUI
			title={t('markerCenter')}
			options={options}
			placeholder={t('markerCenterPlaceholder')}
			isDisabled={disabled || loading}
			defaultValue={defaultValue}
			value={valueParsed}
			onChange={onChange}
			isLoading={loading}
			testId='concentric-marker'
		/>
	)
}

export default MarkerCenter
