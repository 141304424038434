// Packages
import { useEffect } from 'react'
import { skipToken } from '@reduxjs/toolkit/query'
import { useDispatch } from 'react-redux'

// Alias
import { useAppSelector } from '@/store'
import { selectUserAlertSoundEnabled } from '@/store/user/userSlice'
import useAlertSound from '@/hooks/useAlertSound'
import {
	selectAlertSoundEnabled,
	setAlertSoundEnabled,
} from '@/store/ui/uiSlice'
import {
	selectSiteActiveAlerts,
	useGetSiteLiveQuery,
} from '@/store/sites/sitesWsApi'

// Relatives
import alarm3 from '../../../assets/sounds/alarm1.mp3'

export type AlertSoundProps = {
	siteId: number
}

const AlertSound = ({ siteId }: AlertSoundProps) => {
	const { activeAlerts, isSiteStatusSuccess } = useGetSiteLiveQuery(
		siteId > 0 ? siteId : skipToken,
		{
			selectFromResult: ({ data, isSuccess }) => ({
				activeAlerts: selectSiteActiveAlerts(data),
				isSiteStatusSuccess: isSuccess,
			}),
		}
	)
	const dispatch = useDispatch()
	const userAlertSoundEnabled = useAppSelector(selectUserAlertSoundEnabled)
	const uiAlertSoundEnabled = useAppSelector(selectAlertSoundEnabled)
	useAlertSound(alarm3, {
		loop: true,
		enabled:
			isSiteStatusSuccess &&
			uiAlertSoundEnabled &&
			userAlertSoundEnabled &&
			activeAlerts.length > 0,
	})

	useEffect(() => {
		if (activeAlerts.length === 0 && !uiAlertSoundEnabled) {
			dispatch(setAlertSoundEnabled(true))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeAlerts.length])

	return undefined
}

export default AlertSound
