import type {
	Camera,
	Disruptor,
	GenericSensor,
	GpsCompass,
	Radar,
	RfSensor,
	Site,
} from '@Store/types/index'

export type SensorType =
	| 'radar'
	| 'rfsensor'
	| 'dronesentryx'
	| 'camera'
	| 'disruptor'
	| 'gpscompass'
	| 'generic'

export type Sensor =
	| Radar
	| RfSensor
	| Camera
	| Disruptor
	| GpsCompass
	| GenericSensor

export type SensorStatusColor = 'red' | 'orange' | 'green' | 'grey'

export type SensorStatusColorFigma =
	| 'status.connection'
	| 'status.no_connection'
	| 'status.lost_connection'
	| 'status.no_status'

export type SmartHub = {
	site_id: number
	serial: string
	ip: string
	status: string
	_ui_expire_at: number
}

export type UnregisteredSensor = {
	type: string // actually a model: rfone, compass, radarzero, etc
	serial: string
	site_id: Site['id']
	ip: string
}

export const sensorStatusColorMap: Record<
	SensorStatusColor,
	SensorStatusColorFigma
> = {
	green: 'status.connection',
	red: 'status.no_connection',
	orange: 'status.lost_connection',
	grey: 'status.no_status',
}

// see:
// camera.ts
// disruptor.ts
// radar.ts
// rfsensor.ts
