// Packages
import {
	formatDistance,
	formatRssi,
	formatThreatLevel,
} from '@/utils/formatUtils'
import { Box, Flex, Text } from '@chakra-ui/react'

export type HeaderDetailsProps = {
	threat?: number
	threatLevel?: 'high' | 'medium' | 'low'
	hasDroneLocator?: boolean
	isStale?: boolean
	siteDistance?: number
	zoneName?: string
	siteName?: string
	rssi?: number
}

const HeaderDetails = ({
	threat = 0,
	threatLevel = 'low',
	hasDroneLocator = false,
	isStale = false,
	siteDistance = 0,
	zoneName = '',
	siteName = 'Site Name',
	rssi = 0,
}: HeaderDetailsProps) => {
	let threatColor = 'white'
	if (threatLevel === 'medium' && !isStale) {
		threatColor = 'amber.500'
	} else if (threatLevel === 'high' && !isStale) {
		threatColor = 'error'
	} else if (isStale) {
		threatColor = 'gray.400'
	}

	return (
		<Flex
			justifyContent='center'
			direction='column'
			paddingX='5px'
			grow={1}
			basis={0}
		>
			{hasDroneLocator && (
				<Text color={isStale ? 'gray.400' : 'error'} fontSize='xs'>
					DroneLocator
				</Text>
			)}
			<Flex alignItems='center' gap='8px' fontSize='xs'>
				{threat > 0 && (
					<Text color={threatColor}>
						{formatThreatLevel(threat, true)} Threat
					</Text>
				)}
				{!!rssi && (
					<>
						{threat > 0 && (
							<Box
								width='4px'
								height='4px'
								backgroundColor='gray.600'
								borderRadius='50%'
							/>
						)}
						<Text fontSize='xs' color={isStale ? 'gray.400' : 'white'}>
							{formatRssi(rssi) + 'dBm'}
						</Text>
					</>
				)}
			</Flex>
			{!!siteDistance && hasDroneLocator && (
				<Text fontWeight='bold' color={isStale ? 'gray.400' : 'white'}>
					{formatDistance(siteDistance)}
				</Text>
			)}
			{!hasDroneLocator && zoneName && (
				<Text
					fontWeight='bold'
					color={isStale ? 'gray.400' : 'white'}
					isTruncated
					maxWidth='145px'
					title={zoneName}
				>
					{zoneName}
				</Text>
			)}
			<Text
				color='gray.400'
				fontSize='xs'
				isTruncated
				maxWidth='145px'
				title={siteName}
			>
				{siteName}
			</Text>
		</Flex>
	)
}

export default HeaderDetails
