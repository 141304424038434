// Packages
import { Box, Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

// Alias
import { formatRadarRCS } from '@/utils/formatUtils'

export type DetailsRcsProps = {
	rcs?: number
}

const DetailsRcs = ({ rcs = 0 }: DetailsRcsProps) => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'notifications.detections.detectionDetail.headings',
	})

	return (
		<Flex w='100%' flexWrap='wrap'>
			<Box w='50%' marginBlockEnd={2}>
				<Text fontSize='xs' color='headings'>
					{t('rcs')}
				</Text>
				<Text fontSize='sm'>{formatRadarRCS(rcs) ?? '-'}</Text>
			</Box>
		</Flex>
	)
}

export default DetailsRcs
