// Packages
import { z } from 'zod'

// Alias
import globalSchema, {
	passwordSchema,
} from '@/components/Forms/BaseForm/BaseForm.schema'

const userFormFields = z
	.object({
		id: z.number().optional(),
		name: globalSchema.shape.name,
		email: globalSchema.shape.email,
		phone: globalSchema.shape.phone,
		phone_region: globalSchema.shape.countryCode,
		role: z.enum(['admin', 'manager', 'user'], {
			errorMap: (issue, ctx) => ({ message: 'validation.role' }),
		}),
		client_id: z
			.number({ invalid_type_error: 'validation.client_id' })
			.optional(),
		username: z
			.string()
			.min(4, 'validation.username')
			.max(32, 'validation.username'),
		company: z.string().optional(),
		show_plan_part_lists: z.boolean().optional(),
		default_site_id: z.number().optional(),
		site_associations: z
			.array(
				z.object({
					role: z.enum(['guest', 'operator', 'site_manager']),
					site_id: z.number(),
					site_name: z.string(),
					user_id: z.number().optional(),
				})
			)
			.optional(),
	})
	.refine(
		(data) => {
			if (data.role === 'admin') {
				return true
			} else {
				return (
					(data.role === 'user' || data.role === 'manager') &&
					data.client_id &&
					data.client_id > 0
				)
			}
		},
		{
			message: 'validation.client_id',
			path: ['client_id'],
		}
	)
	.refine(
		(values) => {
			if (values.role !== 'user') {
				return true
			} else {
				return (
					values.role === 'user' &&
					values?.site_associations &&
					values?.site_associations?.length > 0
				)
			}
		},
		{
			message: 'validation.siteAssociations',
			path: ['site_associations'],
		}
	)

const userPasswordSchema = passwordSchema.refine(
	(values) => {
		return values.password === values.password_confirmation
	},
	{
		message: 'validation.mustMatch',
		path: ['password_confirmation'],
	}
)

export const userFormSchema = userFormFields.and(userPasswordSchema)
export const userFormEditSchema = userFormFields

export type UserSchema = z.infer<typeof userFormSchema>
export type UserEditSchema = z.infer<typeof userFormEditSchema>

export const ADD_USER_DEFAULT_VALUES: UserSchema = {
	name: '',
	email: '',
	password: '',
	username: '',
	password_confirmation: '',
	phone: '', // this makes the required flag for the PhoneInput working as expected
	role: 'user',
}
