// Packages
import noop from 'lodash/noop'
import { useCallback, useMemo } from 'react'
import { MdOutlineReplay } from 'react-icons/md'

// Relatives
import DatePicker from '../DatePicker/DatePicker'

// Types
import type { DatePickerPlacement } from '../DatePicker/DatePicker'
import type { ReactNode } from 'react'

// Styles
import './style.scss'

export type DateRangePickerProps = {
	labelStartDate?: string
	labelEndDate?: string
	testIdStartDate?: string
	testIdEndDate?: string
	label?: string | ReactNode
	value?: [string | undefined, string | undefined] | [] | string
	placement?: DatePickerPlacement
	canReset?: boolean
	disabled?: boolean
	mode?: 'datetime' | 'date'
	timezone?: string
	min?: string
	max?: string
	onChange?: (value: [string, string]) => void
}

const DateRangePicker = ({
	labelStartDate = 'Start Date',
	labelEndDate = 'End Date',
	value = '',
	canReset = false,
	disabled = false,
	placement = 'bottom-start',
	mode = 'datetime',
	timezone,
	min = '',
	max = '',
	testIdStartDate = '',
	testIdEndDate = '',
	onChange = noop,
}: DateRangePickerProps) => {
	const [startDate, endDate] = useMemo(() => {
		if (typeof value === 'string') {
			const valueParts = value.split(',')

			return [valueParts[0] ?? '', valueParts[1] ?? '']
		}

		return [value[0] ?? '', value[1] ?? '']
	}, [value])
	const [originalStartDate, originalEndDate] = useMemo(
		() => [startDate, endDate],
		[] // eslint-disable-line
	)

	const handleChangeStart = useCallback(
		(partialValue: string) => {
			if (!onChange) {
				return
			}

			onChange([partialValue, endDate])
		},
		[onChange, endDate]
	)

	const handleChangeEnd = useCallback(
		(partialValue: string) => {
			if (!onChange) {
				return
			}

			onChange([startDate, partialValue])
		},
		[startDate, onChange]
	)

	const handleReset = useCallback(() => {
		if (!canReset || !onChange) {
			return
		}

		onChange([originalStartDate, originalEndDate])
	}, [canReset, onChange, originalStartDate, originalEndDate])

	return (
		<div data-scope='date-range-picker' data-part='root'>
			<DatePicker
				label={labelStartDate}
				value={startDate}
				mode={mode}
				min={min}
				max={endDate ? endDate : max}
				timezone={timezone}
				placement={placement}
				disabled={disabled}
				testId={testIdStartDate}
				onChange={handleChangeStart}
			/>
			<DatePicker
				label={labelEndDate}
				value={endDate}
				mode={mode}
				min={startDate ? startDate : min}
				max={max}
				timezone={timezone}
				placement={placement}
				disabled={disabled}
				testId={testIdEndDate}
				onChange={handleChangeEnd}
			/>
			{canReset && (
				<button
					data-scope='date-range-picker'
					onClick={handleReset}
					data-part='clear-trigger'
				>
					<MdOutlineReplay />
					Reset
				</button>
			)}
		</div>
	)
}

export default DateRangePicker
