// Packages
import { useCallback, useEffect, useId, useMemo, useState } from 'react'
import { DatePicker as DatePickerArk } from '@ark-ui/react'
import { Portal } from '@chakra-ui/react'
import noop from 'lodash/noop'
import { MdCalendarMonth, MdOutlineReplay } from 'react-icons/md'
import { format } from 'date-fns'

// Relatives
import DatePickerContentHeader from './DatePickerContentHeader'
import DatePickerContentFooter from './DatePickerContentFooter'
import DatePickerContentBody from './DatePickerContentBody'
import { isValidDate } from './DatePickerHelper'
import DatePickerInput from './ChildArkComponents/DatePickerInput'

// Types
import type { ReactNode } from 'react'

// Styles
import './style.scss'

export type DatePickerPlacement =
	| 'top'
	| 'right'
	| 'bottom'
	| 'left'
	| 'top-start'
	| 'top-end'
	| 'right-start'
	| 'right-end'
	| 'bottom-start'
	| 'bottom-end'
	| 'left-start'
	| 'left-end'

export type DatePickerProps = {
	label?: string | ReactNode
	value?: string
	placement?: DatePickerPlacement
	canClear?: boolean
	disabled?: boolean
	timezone?: string
	mode?: 'datetime' | 'date'
	min?: string
	max?: string
	testId?: string
	onChange?: (value: string) => void
}

// Due that dateformats are manual, for the moment we have to keep this.
// If they are required to be dynamic, will require extra changes
export const dateFormat = 'yyyy-MM-dd'
export const timeFormat = 'HH:mm'
export const dateTimeFormat = 'yyyy-MM-dd HH:mm'

// @todo: yearSelect cant be fixed due this issue https://github.com/chakra-ui/ark/issues/2866

const DatePicker = ({
	label = 'label',
	value = '',
	canClear = false,
	disabled = false,
	placement = 'bottom-start',
	mode = 'datetime',
	timezone,
	min = '',
	max = '',
	testId = '',
	onChange = noop,
}: DatePickerProps) => {
	const id = useId()
	const positioning = useMemo(() => ({ placement }), [placement])
	const [reRenderCalendar, setReRenderCalendar] = useState(false)
	const [newValue, setNewValue] = useState<string>(value)
	const [open, setOpen] = useState(false)
	const [isFocus, setIsFocus] = useState(false)
	const [date, time, hours, minutes] = useMemo(() => {
		if (!newValue) {
			return [[], '', 0, 0]
		}

		const currentDate = new Date(newValue)
		const date = format(currentDate, dateFormat)
		const time = format(currentDate, timeFormat)
		const hours = currentDate.getHours()
		const minutes = currentDate.getMinutes()

		return [[date], time, hours, minutes]
	}, [newValue])
	const minParsed = useMemo(
		() => (min ? format(new Date(min), 'yyyy-MM-dd') : min),
		[min]
	)
	const maxParsed = useMemo(
		() => (max ? format(new Date(max), 'yyyy-MM-dd') : max),
		[max]
	)

	useEffect(() => {
		setNewValue((state) => {
			if (state === value) {
				return state
			}

			return value
		})
	}, [value])

	const handleValueChange = useCallback(
		(details: { value: any; valueAsString: string[]; view: string }) => {
			if (!details.valueAsString || !details.valueAsString.length) {
				return onChange('')
			}

			if (mode === 'date') {
				setNewValue(details.valueAsString[0])
			}

			if (mode === 'datetime') {
				const [dateStr] = details.valueAsString
				let date
				if (!dateStr) {
					date = new Date()
				} else if (time) {
					date = new Date(`${dateStr} ${time}`)
				} else {
					date = new Date(`${dateStr} ${format(new Date(), 'HH:mm')}`)
				}

				const newVal = format(date, dateTimeFormat)
				setNewValue(newVal)
				if (!open && isValidDate(newVal, min, max)) {
					onChange(newVal)
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[onChange, mode, open, time]
	)

	const handleChangeTime = useCallback(
		(time: string) => {
			if (mode !== 'datetime') {
				return
			}

			let dateInstance
			if (!date?.[0]) {
				dateInstance = new Date(`${format(new Date(), 'yyyy-MM-dd')} ${time}`)
			} else {
				dateInstance = new Date(`${date} ${time}`)
			}

			const datetime = format(dateInstance, dateTimeFormat)
			if (!isValidDate(datetime, min, max)) {
				return
			}

			setNewValue(datetime)
			if (!open && onChange) {
				onChange(datetime)
			}
		},
		[date, mode, open, onChange, min, max]
	)

	const handleClickSubmit = useCallback(() => {
		if (isValidDate(newValue, min, max)) {
			onChange(newValue)
		} else {
			setNewValue(value)
		}

		setOpen(false)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [newValue, onChange])

	const handleClickCancel = useCallback(() => {
		setNewValue(value)
		setOpen(false)
	}, [value])

	const handleOpenChange = useCallback(
		({ open: openState }: { open: boolean }) => {
			setOpen(openState)
			setNewValue(value)
		},
		[value]
	)

	const handleClickToday = useCallback(() => {
		setNewValue(
			format(
				new Date(`${format(new Date(), 'yyyy-MM-dd')} ${time}`),
				mode === 'datetime' ? dateTimeFormat : dateFormat
			)
		)
		setReRenderCalendar((state) => !state)
	}, [time, mode])

	const handleChangeInput = useCallback(
		(inputValue: string) => {
			if (!open && value !== inputValue && isValidDate(inputValue, min, max)) {
				onChange(inputValue)
			}
		},
		[open, value, onChange, min, max]
	)

	const handleFocusInput = useCallback(
		(fragment: string) => setIsFocus(true),
		[]
	)

	const handleBlurInput = useCallback(
		(fragment: string) => setIsFocus(false),
		[]
	)

	return (
		<DatePickerArk.Root
			id={id}
			key={`${reRenderCalendar}`}
			data-focus={isFocus}
			onValueChange={handleValueChange}
			value={date}
			positioning={positioning}
			locale='en-CA'
			timeZone={timezone}
			open={open}
			onOpenChange={handleOpenChange}
			closeOnSelect={false}
			min={minParsed}
			max={maxParsed}
			disabled={disabled}
			data-testid={testId}
		>
			{label && <DatePickerArk.Label>{label}</DatePickerArk.Label>}
			<DatePickerArk.Control>
				<div data-scope='date-picker' data-part='control-input-container'>
					<DatePickerArk.Input asChild>
						<DatePickerInput
							value={newValue}
							min={min}
							max={max}
							testId={testId}
							onChangeInput={handleChangeInput}
							onFocusInput={handleFocusInput}
							onBlurInput={handleBlurInput}
						/>
					</DatePickerArk.Input>
					<DatePickerArk.Trigger>
						<MdCalendarMonth />
					</DatePickerArk.Trigger>
				</div>
				{canClear && (
					<DatePickerArk.ClearTrigger>
						<MdOutlineReplay />
						Reset
					</DatePickerArk.ClearTrigger>
				)}
			</DatePickerArk.Control>
			<Portal>
				<DatePickerArk.Positioner>
					<DatePickerArk.Content>
						<DatePickerContentHeader />
						<DatePickerContentBody
							id={id}
							open={open}
							mode={mode}
							hours={hours}
							minutes={minutes}
							onClickTime={handleChangeTime}
							onClickToday={handleClickToday}
						/>
						<DatePickerContentFooter
							onSubmit={handleClickSubmit}
							onCancel={handleClickCancel}
						/>
					</DatePickerArk.Content>
				</DatePickerArk.Positioner>
			</Portal>
		</DatePickerArk.Root>
	)
}

export default DatePicker
