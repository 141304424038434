// React
import { useTranslation } from 'react-i18next'
// Chakra
import { Box, Flex } from '@chakra-ui/react'
// Components
import Field from '@Components/FormElements'
import Headings from '@Components/_UI/Headings/Headings'
// Store
import { useGetSitesListQuery } from '@Store/sites/sitesApi'
import type { User } from '@Store/types'
import type { ProfileSchema } from '@Forms/ProfileForm/ProfileForm.schema'
import type { FormSelectValueType } from '@/components/FormElements/Select/Select'
import { useFormContext, useFormState } from 'react-hook-form'

const ProfileFormGeneral = ({ user }: { user: User }) => {
	const { t } = useTranslation('forms', { keyPrefix: 'profileForm' })
	const { register } = useFormContext()
	const { errors } = useFormState<ProfileSchema>()

	const { data: sites } = useGetSitesListQuery()

	const sitesOptions = (sites ?? []).map((site) => {
		return {
			label: site.name,
			value: site.id,
		}
	})
	const defaultSiteValue = sitesOptions.find(
		(option) => option.value === user.default_site_id
	)

	return (
		<>
			<Field.Select
				title={t('api.language')}
				options={
					t('languageOptions', {
						returnObjects: true,
					}) as FormSelectValueType[]
				}
				register={register('language')}
				error={errors?.language?.message}
				data-testid='language'
			/>
			<Field.Select
				title={t('api.default_site')}
				options={sitesOptions}
				defaultValue={defaultSiteValue}
				register={register('default_site_id')}
				error={errors?.default_site_id?.message}
				data-testid='default-site-select'
			/>
			<Box marginBlock={1}>
				<Field.Switch
					title={t('api.play_alarm_sounds')}
					register={register('play_alarm_sounds')}
					error={errors?.play_alarm_sounds?.message}
					testId='play_alarm_sounds-enabled'
				/>
			</Box>

			{/*<Box mt={4}>*/}
			{/*	<Headings.FieldHeading title={t('headings.selectUnit')} />*/}
			{/*	<Field.Radio*/}
			{/*		id='displayUnits'*/}
			{/*		options={t('displayUnitOptions', {*/}
			{/*			returnObjects: true,*/}
			{/*		})}*/}
			{/*		defaultValue={user.settings.displayUnit}*/}
			{/*		register={register('settings.displayUnit')}*/}
			{/*		error={errors?.settings?.displayUnit?.message}*/}
			{/*	/>*/}
			{/*</Box>*/}
			<Box mt={4}>
				<Headings.FieldHeading title={t('headings.selectMilitaryUnit')} />
			</Box>

			<Flex>
				{/*<Field.Checkbox*/}
				{/*	title={t('api.mgrs')}*/}
				{/*	register={register('settings.mgrsEnabled')}*/}
				{/*	error={errors?.settings?.mgrsEnabled?.message}*/}
				{/*	testId='mgrs-enabled'*/}
				{/*/>*/}
				<Field.Checkbox
					title={t('api.mils')}
					register={register('settings.milsEnabled')}
					error={errors?.settings?.milsEnabled?.message}
					testId='mils-enabled'
				/>
			</Flex>
		</>
	)
}

export default ProfileFormGeneral
