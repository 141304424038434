// Packages
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

// Alias
import { useUpdateUserSettingsMutation } from '@/store/user/usersApi'
import { useAppDispatch, useAppSelector } from '@/store'
import { selectUserSettings } from '@/store/user/userSlice'
import { addToast } from '@/store/ui/uiSlice'

// Relatives
import DetectionDisplaySettingsForm from '../DetectionDisplaySettingsForm/DetectionDisplaySettingsForm'

// Types
import type { TypeOf } from 'zod'
import type detectionDisplaySettingsFormSchema from '../DetectionDisplaySettingsForm/DetectionDisplaySettingsForm.schema'
import type { FormError } from '../types'

export type DetectionDisplaySettingsProps = {
	className?: string
	siteId?: number
	userId?: number
}

const DetectionDisplaySettingsLoader = ({
	userId,
	siteId,
}: DetectionDisplaySettingsProps) => {
	const [updateUserSettings] = useUpdateUserSettingsMutation()
	type Schema = TypeOf<typeof detectionDisplaySettingsFormSchema>
	const userSettings = useAppSelector(selectUserSettings)
	const dispatch = useAppDispatch()
	const { t } = useTranslation('forms', {
		keyPrefix: 'detectionDisplaySettingsForm',
	})

	const handleSubmit = useCallback(
		async (values: Schema) => {
			const {
				detectionDisplay: {
					showWhitelistedDetections,
					showControllerDetections,
				},
			} = values
			await updateUserSettings({
				userId,
				settings: {
					...userSettings,
					showWhitelistedDetections,
					showControllerDetections,
				},
			})
				.unwrap()
				.then((user) => {
					// Stored in redux, nothing to do here
				})
				.catch((error) => {
					dispatch(
						addToast({
							id: undefined,
							siteId,
							type: 'error',
							title: t('toast.updateErrorTitle'),
							description: (error as FormError)?.data?.error ?? error?.error,
							error: error,
						})
					)
					console.error('Update display settings error', error)
				})
		},
		[userId, updateUserSettings, userSettings, dispatch, siteId, t]
	)

	const detectionDisplay = useMemo(
		() => ({
			showControllerDetections: userSettings?.showControllerDetections,
			showWhitelistedDetections: userSettings?.showWhitelistedDetections,
		}),
		[userSettings]
	)

	return (
		<DetectionDisplaySettingsForm
			siteId={siteId}
			detectionDisplay={detectionDisplay}
			onSubmit={handleSubmit}
		/>
	)
}

export default DetectionDisplaySettingsLoader
