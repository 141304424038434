import { z } from 'zod'
import globalSchema from '@Components/Forms/BaseForm/BaseForm.schema'
// Translations
import i18n from '@/i18n'
const t = (key: string) => i18n.t(`forms:rfSensorForm.validation.${key}`)

const pickedFromGlobal = globalSchema.pick({
	name: true,
})

const rfSensorFormFields = z.object({
	direction_offset: globalSchema.shape.direction_offset,
	sentry_id: z.number(),
	model: z.enum([
		'rf_one',
		'rf_patrol',
		'dsx_direct',
		'dsx_direct_no_jam',
		'dsx_mk2_no_jam',
		'dsx_mk2',
	]),
	reach: z.number().min(500).max(8000),
	serial_number: z.string().refine((data) => data.length === 13, {
		message: t('serial_number'),
	}),
	show_720_detection: z.boolean(),
	show_outside_detections: z.boolean(),
	show_sectors: z.boolean(),
	show_sector_as_line: z.boolean(),
	height_offset_to_installation:
		globalSchema.shape.height_offset_to_installation,
	tilt: z.number().min(-45).max(45),
	roll: z.number().min(-45).max(45),
	north_offset_to_installation: z.number().min(-10).max(10),
	east_offset_to_installation: z.number().min(-10).max(10),
})

export const rfSensorFormSchema = rfSensorFormFields.merge(pickedFromGlobal)
export type RfSchema = z.infer<typeof rfSensorFormSchema>

const defaultValuesSchema = rfSensorFormSchema.omit({
	name: true,
	sentry_id: true,
	serial_number: true,
})

type DefaultValues = z.infer<typeof defaultValuesSchema>

export const ADD_RF_DEFAULT_VALUES: DefaultValues = {
	direction_offset: 0,
	model: 'rf_one',
	reach: 8000,
	show_720_detection: false,
	show_outside_detections: true,
	show_sector_as_line: true,
	show_sectors: false,
	height_offset_to_installation: 0,
	tilt: 0,
	roll: 0,
	north_offset_to_installation: 0,
	east_offset_to_installation: 0,
}
