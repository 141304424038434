// React
import { useCallback, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
// Components
import SiteFormMapCenterCoordinates from '@Forms/SiteForm/SiteFormMapCenter/SiteFormMapCenterCoordinates'
import SiteFormMapCenterSelect from '@Forms/SiteForm/SiteFormMapCenter/SiteFormMapCenterSelect'
// Store
import { useAppDispatch, useAppSelector } from '@/store'
import {
	selectSiteCustomMapCenter,
	setSiteCustomMapCenter,
} from '@Store/ui/uiSlice'
// Types
import type { Site } from '@Store/types'
import type { SiteDefaultValues } from '@Forms/SiteForm/SiteForm.schema'

const SiteFormMapCenter = ({
	defaultValues,
}: {
	defaultValues: Site | SiteDefaultValues
}) => {
	const { map_center_latitude, map_center_longitude } = defaultValues
	const dispatch = useAppDispatch()

	const siteCustomMapCenter = useAppSelector(selectSiteCustomMapCenter)
	const { setValue } = useFormContext()

	const setLat = useCallback(
		(lat: number) =>
			setValue('map_center_latitude', lat, {
				shouldDirty: true,
			}),
		[setValue]
	)

	const setLng = useCallback(
		(lng: number) =>
			setValue('map_center_longitude', lng, {
				shouldDirty: true,
			}),
		[setValue]
	)

	// Make active MapCenterSelect and visible MapCenterCoordinates
	useEffect(() => {
		if (map_center_latitude && map_center_longitude) {
			dispatch(setSiteCustomMapCenter({ isCustomMapCenter: true }))
		}
	}, [map_center_latitude, map_center_longitude, dispatch, setValue])
	// Make custom map center coordinates setting magic
	useEffect(() => {
		if (siteCustomMapCenter) {
			const { latitude, longitude, map_center_latitude, map_center_longitude } =
				defaultValues
			// Set coordinates from DragEndEvent from MapEventsHandler
			if (siteCustomMapCenter.isChangedFromMap !== false) {
				// ^^ Don't simplify it, have to be strict check to false
				if (siteCustomMapCenter.isChangedFromMap) {
					setLat(siteCustomMapCenter.latitude)
					setLng(siteCustomMapCenter.longitude)
				}
				// If custom selector selected and no custom coordinates set coordinates from site location
				else if (
					map_center_latitude === null &&
					map_center_longitude === null
				) {
					setLat(latitude)
					setLng(longitude)
				}
				// If custom selector selected and custom coordinates set them to fields
				else if (
					map_center_latitude !== null &&
					map_center_longitude !== null
				) {
					setLat(map_center_latitude)
					setLng(map_center_longitude)
				}
			}
		}
	}, [siteCustomMapCenter, defaultValues, setLat, setLng, dispatch])

	// Clean-up store when unmount
	useEffect(() => {
		return () => {
			dispatch(setSiteCustomMapCenter(null))
		}
	}, [dispatch])

	return (
		<>
			<SiteFormMapCenterSelect />
			<SiteFormMapCenterCoordinates />
		</>
	)
}

export default SiteFormMapCenter
