import { useTheme } from '@chakra-ui/react'

type AglMaskMeasuringTableProps = {
	bottomFillWidth: string
	topFillWidth: string
	xOffset: string
}

const AglMaskMeasuringTable = ({
	bottomFillWidth,
	topFillWidth,
	xOffset,
}: AglMaskMeasuringTableProps) => {
	const {
		semanticTokens: { colors },
	} = useTheme()

	const ticks = [
		{ x: '0', y: 20, strokeWidth: 4 },
		{ x: '16.6%', y: 20, strokeWidth: 2 },
		{ x: '33.3%', y: 20, strokeWidth: 2 },
		{ x: '50%', y: 20, strokeWidth: 2 },
		{ x: '66.6%', y: 20, strokeWidth: 2 },
		{ x: '83.3%', y: 20, strokeWidth: 2 },
		{ x: '100%', y: 20, strokeWidth: 4 },
	]

	return (
		<svg width='100%' height='40px'>
			<line
				x1={0}
				x2='100%'
				y1={0}
				y2={0}
				stroke={colors.measuringLine}
				strokeWidth={4}
			/>
			{ticks.map((tick, index) => (
				<line
					key={index}
					x1={tick.x}
					x2={tick.x}
					y1={0}
					y2={tick.y}
					stroke={colors.measuringLine}
					strokeWidth={tick.strokeWidth}
				/>
			))}
			<rect
				width={bottomFillWidth}
				height={20}
				fill={colors.primary}
				fillOpacity={0.45}
			/>
			<rect
				width={topFillWidth}
				height={20}
				x={xOffset}
				fill={colors.primary}
				fillOpacity={0.45}
			/>
		</svg>
	)
}
export default AglMaskMeasuringTable
