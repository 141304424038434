import {
	useGetSiteRadarAglMasksQuery,
	selectSiteRadarAglMaskBySensorId,
} from '@Store/radarAglMasks/radarAglMasksApi'
import type { Site, Radar } from '@Store/types'

import { Box, Flex, Text, useDisclosure } from '@chakra-ui/react'
import EditButton from '../EditButton'
import Modal from '@Components/_UI/Modals/Modal'

import RadarAglMaskActiveSwitch from './AglMaskActiveSwitch'
import AglMaskPreview from './AglMaskPreview/AglMaskPreview'
import AglMaskLegend from './AglMaskPreview/AglMaskLegend'
import AglMaskMeasuringTable from './AglMaskMeasuringTable'

import RadarAglMaskForm from '@Forms/RadarAglMaskForm/RadarAglMaskForm'
import { useTranslation } from 'react-i18next'
import {
	selectIsSiteCalibrationMode,
	useGetSiteLiveQuery,
} from '@/store/sites/sitesWsApi'
import { provideAglCalculations } from './utils/aglCalc'

const AglMaskRange = {
	min: -3000,
	max: 3000,
}

type RadarAglMaskProps = {
	siteId: Site['id']
	sensorId: Radar['id']
}

const AglMask = ({ siteId, sensorId }: RadarAglMaskProps) => {
	const { onOpen, isOpen, onClose } = useDisclosure()
	const { t } = useTranslation('panels', { keyPrefix: 'aglMask' })
	const { radarAglMask, isSuccess } = useGetSiteRadarAglMasksQuery(
		{ siteId },
		{
			selectFromResult: ({ data, isSuccess }) => ({
				radarAglMask: selectSiteRadarAglMaskBySensorId(data, sensorId),
				isSuccess,
			}),
			skip: !siteId || !sensorId,
		}
	)
	const { isCalibrationMode } = useGetSiteLiveQuery(siteId, {
		selectFromResult: ({ data }) => ({
			isCalibrationMode: selectIsSiteCalibrationMode(data),
		}),
	})

	const [range, minVal, maxVal] = provideAglCalculations(
		AglMaskRange.min,
		AglMaskRange.max,
		radarAglMask?.min ?? -3000,
		radarAglMask?.max ?? 3000
	)

	const bottomFillWidth = `${(minVal / range) * 100}%`
	const xOffset = `${(maxVal / range) * 100}%`

	const topFillWidth = `${(1 - maxVal / range) * 100}%`

	return (
		isSuccess && (
			<>
				{radarAglMask && (
					<RadarAglMaskActiveSwitch
						siteId={siteId}
						radarAglMask={radarAglMask}
					/>
				)}

				<Flex justifyContent='flex-end' marginBlockEnd={3}>
					{!radarAglMask && (
						<EditButton
							onClick={onOpen}
							type='add'
							label={t('createAglMask')}
							testId='create-agl-mask'
							tooltip={!isCalibrationMode ? t('calibration') : undefined}
							disabled={!isCalibrationMode || radarAglMask ? true : false}
						/>
					)}
					{radarAglMask && (
						<EditButton
							onClick={onOpen}
							type='edit'
							testId='edit-agl-mask'
							tooltip={!isCalibrationMode ? t('calibration') : undefined}
							disabled={!isCalibrationMode || !radarAglMask}
						/>
					)}
				</Flex>
				{radarAglMask?.enabled && (
					<>
						<Flex width={'420px'} direction='row' position='relative'>
							<AglMaskMeasuringTable
								topFillWidth={topFillWidth}
								bottomFillWidth={bottomFillWidth}
								xOffset={xOffset}
							/>
							<Flex position={'absolute'} w='100%' direction='column'>
								{[
									{ value: -3000, width: '-4.35%' },
									{ value: -2000, width: '12%' },
									{ value: -1000, width: '28%' },
									{ value: 0, width: '49.07%' },
									{ value: 1000, width: '62.5%' },
									{ value: 2000, width: '80%' },
									{ value: 3000, width: '96.4%' },
								].map((value, index) => {
									return (
										<Box
											key={index}
											position='absolute'
											top={7}
											left={value.width}
											textAlign='left'
										>
											<Text fontSize='sm' textAlign='center'>
												{value.value}
											</Text>
										</Box>
									)
								})}
							</Flex>
						</Flex>
						<AglMaskLegend isLargePreview={false} />
						<AglMaskPreview
							min={AglMaskRange.min}
							max={AglMaskRange.max}
							value={[radarAglMask.min, radarAglMask.max]}
							isLargePreview={false}
						/>
						<Flex direction='column' paddingBlockStart={2}>
							<Flex justifyContent={'center'} paddingBlock={2}>
								<Text fontSize='xs' color='label_color'>
									{t('detectionAreaLabel')}
								</Text>
							</Flex>
							<Flex justifyContent='center' alignItems='baseline' gap={2}>
								<Text fontSize='xs' color='label_color'>
									min
								</Text>
								<Text data-testid='agl-mask-min-preview'>
									{radarAglMask.min}
								</Text>
								<Text fontSize='xs' color='label_color'>
									max
								</Text>
								<Text data-testid='agl-mask-max-preview'>
									{radarAglMask.max}
								</Text>
							</Flex>
						</Flex>
					</>
				)}

				<Modal
					isOpen={isOpen}
					onClose={onClose}
					headerText={t('modalTitle')}
					variant='table'
				>
					<RadarAglMaskForm
						siteId={siteId}
						sensorId={sensorId}
						maskId={radarAglMask?.id}
						onClose={onClose}
					/>
				</Modal>
			</>
		)
	)
}

export default AglMask
