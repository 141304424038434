// Packages
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Box, Flex, Spacer, Text } from '@chakra-ui/react'
import get from 'lodash/get'

// Alias
import Headings from '@/components/_UI/Headings/Headings'
import { useAppDispatch, useAppSelector } from '@/store'
import {
	selectUserId,
	selectUserSettings,
	selectUserZoneSettings,
} from '@Store/user/userSlice'
import FetchError from '@/components/_UI/FetchError/FetchError'
import {
	selectAvailableSectors,
	selectConcentricMarkerOptions,
	useGetSiteExportQuery,
} from '@/store/sites/sitesApi'
import { useUpdateUserSettingsMutation } from '@/store/user/usersApi'
import { addToast } from '@/store/ui/uiSlice'
import useAuth from '@/hooks/useAuth'

// Relatives
import { displaySettingsDefaultValues } from './DisplaySettings.schema'
import MarkerCenter from './MarkerCenter'
import DeviceDisplay from './DeviceDisplay'
import ShowMarkers from './ShowMarkers'
import VisibleZoneTypes from './VisibleZoneTypes'

// Types
import type { displaySettingsSchema } from './DisplaySettings.schema'
import type { RadiatingCircle, ZoneSettings } from '@/store/types'
import type { FormError } from '../../types'
import type { TypeOf } from 'zod'
import type { MultiSelectOption } from '@/components/_UI/Select/MultiSelect'
import type { ChangeEvent } from 'react'

const Form = ({
	defaultValues,
	siteId,
}: {
	defaultValues: ZoneSettings
	siteId: number
}) => {
	const [updateUserSettings] = useUpdateUserSettingsMutation()
	const [loading, setLoading] = useState(false)
	const { t } = useTranslation('forms', {
		keyPrefix: 'mapsZonesForm.displaySettings',
	})

	type Schema = TypeOf<typeof displaySettingsSchema>
	const dispatch = useAppDispatch()
	const userId = useAppSelector(selectUserId)
	const { isAdmin } = useAuth()
	const userSettings = useAppSelector(selectUserSettings)
	const { availableSectors, availableSectorsReady, concentricMarkerOptions } =
		useGetSiteExportQuery(siteId, {
			selectFromResult: ({ isSuccess, data }) => ({
				availableSectors: selectAvailableSectors(data),
				availableSectorsReady: isSuccess,
				concentricMarkerOptions: selectConcentricMarkerOptions(data),
			}),
		})

	const zonesDisplayOptions = (
		t('zonesDisplayOptions', {
			returnObjects: true,
		}) as MultiSelectOption[]
	).filter((option) => option.value !== 'origin' || isAdmin)

	const zoneDisplayDefaultValues = zonesDisplayOptions.filter((option) =>
		defaultValues.visibleZoneTypes?.includes(option.value as string)
	)

	const handleSave = useCallback(
		async (data: Schema) => {
			setLoading(true)
			await updateUserSettings({
				userId,
				settings: {
					...userSettings,
					zoneSettings: { ...data },
				},
			})
				.unwrap()
				.then((user) => {
					// Stored in redux, nothing to do here
				})
				.catch((error) => {
					dispatch(
						addToast({
							id: undefined,
							siteId,
							type: 'error',
							title: t('toast.updateErrorTitle'),
							description: (error as FormError)?.data?.error ?? error?.error,
							error: error,
						})
					)
					console.error('Update display settings error', error)
				})
			setLoading(false)
		},
		[userId, userSettings, dispatch, siteId, t, updateUserSettings]
	)

	const handleChangeMarkerCenter = useCallback(
		(option: { label: string; value: RadiatingCircle }) => {
			if (!option || !option.value) {
				return
			}

			const concentricMarkers = { ...defaultValues.radiatingCircle }
			// This condition deals with the old data structure and can be phased out with time
			if (concentricMarkers.coordinates || concentricMarkers.name) {
				handleSave({
					...defaultValues,
					radiatingCircle: { [siteId]: option.value },
				})

				return
			}

			handleSave({
				...defaultValues,
				radiatingCircle: {
					...concentricMarkers,
					[siteId]: option.value,
				} as Record<number, RadiatingCircle>,
			})
		},
		[handleSave, siteId, defaultValues]
	)

	const handleChangeShowMarkers = useCallback(
		(e: ChangeEvent<HTMLInputElement>) =>
			handleSave({ ...defaultValues, showMarkers: e.target.checked }),
		[defaultValues, handleSave]
	)

	const handleChangeVisibleZoneTypes = useCallback(
		(options: { label: string; value: string }[]) => {
			handleSave({
				...defaultValues,
				visibleZoneTypes: options.map((option) => option.value),
			})
		},
		[handleSave, defaultValues]
	)

	const handleChangeDeviceDisplay = useCallback(
		(value: (string | number)[]) => {
			const selectedValues = availableSectors.filter((sector) =>
				value.includes(sector.value)
			)
			handleSave({
				...defaultValues,
				displayedSectors: {
					...defaultValues.displayedSectors,
					[siteId]: selectedValues,
				},
			})
		},
		[availableSectors, defaultValues, handleSave, siteId]
	)

	const deviceDisplayValue = useMemo(
		() =>
			get(defaultValues, `displayedSectors.${siteId}`, []).map(
				(sector) => sector.value
			),
		[defaultValues, siteId]
	)

	return (
		<Flex direction='column' gap='16px'>
			<Spacer />
			<Box>
				<Headings.FormHeading title={t('headings.generalDisplay')} />
				<Flex direction='column' rowGap='16px'>
					{availableSectorsReady && (
						<MarkerCenter
							siteId={siteId}
							options={concentricMarkerOptions}
							onChange={handleChangeMarkerCenter}
							value={defaultValues?.radiatingCircle}
							loading={loading}
						/>
					)}
					<ShowMarkers
						checked={defaultValues?.showMarkers}
						onChange={handleChangeShowMarkers}
						loading={loading}
					/>
				</Flex>
			</Box>

			<Box>
				<Headings.FormHeading title={t('headings.zonesDisplay')} />
				<VisibleZoneTypes
					loading={loading}
					defaultValue={zoneDisplayDefaultValues}
					options={zonesDisplayOptions}
					onChange={handleChangeVisibleZoneTypes}
					value={defaultValues?.visibleZoneTypes}
				/>
			</Box>

			<Box>
				<Headings.FormHeading title={t('headings.deviceDisplay')} />
				<Text fontSize='sm' paddingTop='8px'>
					{t('deviceSectors')}
				</Text>
				<DeviceDisplay
					value={deviceDisplayValue}
					options={availableSectors}
					loading={loading}
					onChange={handleChangeDeviceDisplay}
				/>
			</Box>
		</Flex>
	)
}

export type DisplaySettingsProps = {
	siteId: number
}

const DisplaySettings = ({ siteId }: DisplaySettingsProps) => {
	const { t } = useTranslation('forms', {
		keyPrefix: 'mapsZonesForm.displaySettings',
	})
	const userId = useAppSelector(selectUserId)
	const zoneSettings = useAppSelector(selectUserZoneSettings)

	const defaultValues =
		zoneSettings ?? (displaySettingsDefaultValues as ZoneSettings)
	const navigate = useNavigate()

	if (!defaultValues) {
		return <FetchError entity={t('entity')} refetch={() => navigate(0)} />
	}

	return <Form key={userId} siteId={siteId} defaultValues={defaultValues} />
}

export default DisplaySettings
