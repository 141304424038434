import { useTheme } from '@chakra-ui/react'

type BackgroundProps = {
	isLargePreview: boolean
}

const Background = ({ isLargePreview }: BackgroundProps) => {
	const {
		semanticTokens: { colors },
	} = useTheme()
	return (
		<g className='agl-layer-background'>
			<rect
				width='100%'
				height='100%'
				fill={isLargePreview ? colors.primary : colors.body_bg}
				fillOpacity={isLargePreview ? 0.45 : 1}
			/>
		</g>
	)
}

export default Background
