// Packages
import { useCallback, useMemo } from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

// Types
import type {
	Camera,
	DetectionContribution,
	DetectionContributionSensor,
	GenericSensor,
	Radar,
	RfSensor,
} from '@/store/types'

export type DetailsSensorProps = {
	contributions?: DetectionContribution[]
	seenContributor?: DetectionContributionSensor[]
	hasDroneLocator?: boolean
	isReplay?: boolean
	// Sensors
	radars: Radar[]
	rfSensors: RfSensor[]
	cameras: Camera[]
	genericSensors: GenericSensor[]
}

const DetailsSensor = ({
	contributions = [],
	seenContributor = [],
	hasDroneLocator = false,
	isReplay = false,
	radars,
	rfSensors,
	cameras,
	genericSensors,
}: DetailsSensorProps) => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'notifications.detections.detectionDetail.headings',
	})

	const getSensorName = useCallback(
		(sensorType: string, sensorId: number) => {
			switch (sensorType) {
				case 'rfSensor':
				case 'dsx':
					return rfSensors.find((sensor) => sensor.id === sensorId)?.name
				case 'radar':
					return radars.find((sensor) => sensor.id === sensorId)?.name
				case 'camera':
					return cameras.find((sensor) => sensor.id === sensorId)?.name
				case 'generic':
					return genericSensors.find((sensor) => sensor.id === sensorId)?.name
				default:
					return null
			}
		},
		[radars, rfSensors, cameras, genericSensors]
	)

	const contributors = useMemo(() => {
		return seenContributor?.map(({ sensorType, sensorId }) => (
			<Text
				key={`${sensorType}-${sensorId}`}
				fontSize='sm'
				w='50%'
				opacity={
					contributions.some(
						(contribution) =>
							contribution.sensor_id === sensorId &&
							contribution.sensor_type === sensorType
					)
						? undefined
						: 0.4
				}
			>
				{getSensorName(sensorType, sensorId)}
			</Text>
		))
	}, [seenContributor, contributions, getSensorName])

	return (
		<Box w='100%' flexWrap='wrap'>
			<Text fontSize='xs' color='headings'>
				{t('detectionSensors')}
			</Text>
			{hasDroneLocator && <Text fontSize='sm'>DroneLocator</Text>}
			<Flex flexWrap='wrap'>{contributors}</Flex>
		</Box>
	)
}

export default DetailsSensor
