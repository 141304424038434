import { IconButton } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { IoSunnyOutline } from 'react-icons/io5'
import { MdBrightness3 } from 'react-icons/md'
import {
	useGetSiteLiveQuery,
	selectSiteCameraById,
} from '@Store/sites/sitesWsApi'

import { useToggleInfraredMutation } from '@Store/cameras/camerasApi'

const InfraredButton = ({
	cameraId,
	isDisabled,
}: {
	cameraId: number
	isDisabled: boolean
}) => {
	const { siteId: siteIdParam } = useParams()

	const { camera, isSuccess } = useGetSiteLiveQuery(Number(siteIdParam), {
		selectFromResult: ({ data, isSuccess }) => ({
			camera: selectSiteCameraById(data, cameraId),
			isSuccess,
		}),
	})

	const [toggleInfrared] = useToggleInfraredMutation()

	const handleToggle = () => {
		toggleInfrared({ siteId: Number(siteIdParam), cameraId })
	}

	return (
		isSuccess &&
		camera?.is_thermal && (
			<>
				{camera?.active_stream === 'thermal' && (
					<IconButton
						icon={<IoSunnyOutline size={24} />}
						sx={{
							svg: { margin: '0 auto' },
						}}
						variant='unstyled'
						onClick={handleToggle}
						aria-label='toggle infrared off'
						isDisabled={isDisabled}
					/>
				)}
				{camera?.active_stream === 'visible' && (
					<IconButton
						icon={<MdBrightness3 size={24} />}
						variant='unstyled'
						onClick={handleToggle}
						sx={{
							svg: { margin: '0 auto' },
						}}
						aria-label='toggle infrared on'
						isDisabled={isDisabled}
					/>
				)}
			</>
		)
	)
}

export default InfraredButton

//
