// Packages
import { useCallback, useRef, useState } from 'react'

const useScreenRecordingTool = () => {
	const [isScreenRecording, setIsScreenRecording] = useState(false)
	const filenameRef = useRef<string>('recording-tool')
	const [stream, setStream] = useState<MediaStream | undefined>(undefined)
	const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | undefined>(
		undefined
	)
	const bufferRef = useRef<Array<Blob>>([])

	const onDataAvailable = useCallback((e: BlobEvent) => {
		if (e.data.size > 0) {
			bufferRef.current.push(e.data)
		}
	}, [])

	const onStart = useCallback(() => {
		setIsScreenRecording(true)
	}, [])

	const onError = useCallback(() => {
		setIsScreenRecording(false)
	}, [])

	const onStop = useCallback(
		(filename: string) => (discard: boolean) => () => {
			setIsScreenRecording(false)
			if (discard) {
				return
			}

			const blob = new Blob(bufferRef.current, { type: 'video/webm' })
			bufferRef.current = [] // Resetting the data chunks
			const dataDownloadUrl = URL.createObjectURL(blob)

			// Download it onto the user's device
			const a = document.createElement('a')
			a.href = dataDownloadUrl
			a.download = `${filename}.webm`
			a.click()
			URL.revokeObjectURL(dataDownloadUrl)
		},
		[]
	)

	const startScreenRecording = useCallback(
		async (filename: string) => {
			filenameRef.current = filename
			let newStream
			try {
				newStream = await navigator.mediaDevices.getDisplayMedia({
					video: { displaySurface: 'monitor' },
					audio: false,
				})
			} catch (e) {
				return false
			}

			if (!newStream) {
				return false
			}

			const mediaRecorder = new MediaRecorder(newStream, {
				mimeType: 'video/webm',
			})
			mediaRecorder.ondataavailable = onDataAvailable
			mediaRecorder.onstop = onStop(filenameRef.current)(false)
			mediaRecorder.onstart = onStart
			mediaRecorder.onerror = onError
			mediaRecorder.start(250)

			setStream(newStream)
			setMediaRecorder(mediaRecorder)

			return true
		},
		[onDataAvailable, onError, onStart, onStop]
	)

	const stopScreenRecording = useCallback(
		async (discard: boolean = false) => {
			if (!stream || !mediaRecorder || mediaRecorder.state !== 'recording') {
				return false
			}

			stream.getTracks().forEach((track) => track.stop())
			setStream(undefined)
			mediaRecorder.onstop = onStop(filenameRef.current)(discard)

			await mediaRecorder.stop()

			return true
		},
		[stream, mediaRecorder, onStop]
	)

	return {
		stream,
		mediaRecorder,
		startScreenRecording,
		stopScreenRecording,
		isScreenRecording,
	}
}

export default useScreenRecordingTool
