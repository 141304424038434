import { calculateLinePositions } from '@/components/Site/Panels/SiteInstallations/RadarMasks/utils/aglCalc'
import { Flex, useTheme } from '@chakra-ui/react'

type LineProps = {
	yPos?: string
}

const Line = ({ yPos = '50%' }: LineProps) => {
	const {
		semanticTokens: { colors },
	} = useTheme()

	return (
		<g id='agl-layer-line'>
			<line
				y1={yPos}
				y2={yPos}
				x1='0'
				x2='100%'
				strokeWidth={1}
				stroke={colors.measuringLine}
			/>
		</g>
	)
}

const AglRangeSliderMeasuringLines = () => {
	const positions = calculateLinePositions(6000, 6)
	return (
		<Flex
			direction='column'
			height='100%'
			justifyContent='space-evenly'
			position='absolute'
			top='0'
			left='0'
			right='0'
			bottom='0'
			pointerEvents='none'
			zIndex={10}
		>
			<svg height='100%'>
				{positions.map((pos) => (
					<Line key={pos} yPos={`${pos}%`} />
				))}
			</svg>
		</Flex>
	)
}

export default AglRangeSliderMeasuringLines
