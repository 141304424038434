import { useState, useEffect } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import {
	useGetSiteLiveQuery,
	selectSiteTimezone,
} from '@Store/sites/sitesWsApi'
import { skipToken } from '@reduxjs/toolkit/query'
import { formatDateInProvidedFormat } from '@Utils/dates'
import type { Site } from '@Store/types'

const Clock = ({ siteId }: { siteId: Site['id'] }) => {
	const [now, setNow] = useState(new Date())

	useEffect(() => {
		const timerId = setInterval(() => setNow(new Date()), 1000)
		return () => clearInterval(timerId)
	}, [])

	// Site Time Zone
	const { isSuccess, siteTimeZone } = useGetSiteLiveQuery(
		siteId > 0 ? siteId : skipToken,
		{
			selectFromResult: ({ isSuccess, data }) => ({
				isSuccess,
				siteTimeZone: selectSiteTimezone(data),
			}),
		}
	)

	if (!siteId) return null
	if (!isSuccess) return null
	if (!siteTimeZone) return null

	const time = formatDateInProvidedFormat(now, siteTimeZone, 'HH:mm:ss')
	const zone = formatDateInProvidedFormat(now, siteTimeZone, 'zzz')
	const date = formatDateInProvidedFormat(now, siteTimeZone, 'yyyy-MM-dd')

	return (
		<Flex
			direction='column'
			fontSize='14px'
			textAlign='right'
			data-testid='clock'
		>
			<Flex gap={1} lineHeight='16px'>
				<Text data-testid='clock-time'>{time}</Text>
				<Text data-testid='clock-zone'>{zone}</Text>
			</Flex>
			<Text lineHeight='16px'>{date}</Text>
		</Flex>
	)
}

export default Clock
