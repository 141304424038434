import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Flex } from '@chakra-ui/react'

import EditButton from '@Components/Site/Panels/SiteInstallations/EditButton'
import Select from '@Components/_UI/Select/Select'
import ZoneSettingsTable from './ZoneSettingsTable'

import { useGetZonesQuery } from '@Store/zones/zonesApi'
import type { Option } from '@Components/_UI/Select/Select'
import type { ZoneType } from '@Store/types'

type ZoneSettingsProps = {
	siteId: number
}

type FilterZoneType = ZoneType | 'all'

const SHOW_ALL_ZONES = 'all'

const ZoneSettings = ({ siteId }: ZoneSettingsProps) => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'mapsAndZones.zoneSettings',
	})
	const [filterZoneType, setFilterZoneType] =
		useState<FilterZoneType>(SHOW_ALL_ZONES)

	const { isLoading, isError, zones } = useGetZonesQuery(
		{ siteId },
		{
			selectFromResult: ({ isLoading, isError, data }) => ({
				isLoading,
				isError,
				zones: data,
			}),
		}
	)

	const filterZoneTypeOptions = t('filterZoneTypeOptions', {
		returnObjects: true,
	}) as Option<FilterZoneType>[]

	const handleFilterZoneTypeChange = (filter: Option<FilterZoneType>) => {
		setFilterZoneType(filter.value)
	}

	const filteredZones =
		filterZoneType === SHOW_ALL_ZONES
			? zones
			: zones?.filter((zone) => zone.zone_type === filterZoneType)

	return (
		<Box padding='0.5rem 0rem'>
			<Flex width='28rem' justifyContent='space-between' alignItems='end'>
				<Box width='23rem'>
					<Select
						title={t('filterZoneType')}
						options={filterZoneTypeOptions}
						defaultValue={filterZoneTypeOptions[0]}
						onChange={handleFilterZoneTypeChange}
						isDisabled={zones?.length === 0}
					/>
				</Box>
				<EditButton
					label={t('add')}
					type='add'
					to={`/${siteId}/zone/add`}
					testId='add-zone'
				/>
			</Flex>
			{isLoading && <Box>Loading</Box>}
			{isError && <Box>Error fetching zones</Box>}
			{filteredZones && (
				<Box maxH='250px' overflow='auto'>
					<ZoneSettingsTable zones={filteredZones} />
				</Box>
			)}
		</Box>
	)
}

export default ZoneSettings
