// Packages
import { Link, useParams } from 'react-router-dom'
import { Flex } from '@chakra-ui/react'

// Alias
import {
	Clock,
	SiteSelect,
	SiteModeSelect,
	UserMenu,
	SettingsMenu,
	SystemWarningsButton,
	NetworkStatus,
	TestDetections,
} from '@Components/Navigation'
import Logos from '@UI/Logos/Logos'
import ErrorBoundary from '@Components/App/ErrorHandling/ErrorBoundary'

// Relatives
import AlertSound from './AlertSound'
import SiteStatus from './SiteStatus'

const TopNavigation = () => {
	const { siteId: siteIdParam } = useParams<string>()
	const siteId = Number(siteIdParam)

	return (
		<Flex
			className='TopNavigation'
			pr={4}
			pl={4}
			alignItems='center'
			height='100%'
		>
			{!!siteId && (
				<ErrorBoundary>
					<AlertSound siteId={siteId} />
				</ErrorBoundary>
			)}
			<Link to={`/${siteId}`}>
				<Logos.TopNavigation />
			</Link>
			<Flex ml='auto' alignItems='center'>
				<ErrorBoundary quiet>
					<TestDetections siteId={siteId} />
				</ErrorBoundary>
				{!!siteId && (
					<ErrorBoundary>
						<NetworkStatus />
					</ErrorBoundary>
				)}
				{!!siteId && (
					<ErrorBoundary>
						<SiteStatus siteId={siteId} />
					</ErrorBoundary>
				)}
				<ErrorBoundary>
					<SiteSelect />
				</ErrorBoundary>
				<ErrorBoundary>
					<SiteModeSelect />
				</ErrorBoundary>
				<ErrorBoundary quiet>
					<Clock siteId={siteId} />
				</ErrorBoundary>
				<Flex ml='6' alignItems='center' gap='5'>
					<SystemWarningsButton />
					<ErrorBoundary quiet>
						<SettingsMenu />
					</ErrorBoundary>
					<UserMenu />
				</Flex>
			</Flex>
		</Flex>
	)
}

export default TopNavigation
