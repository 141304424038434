// Packages
import { z } from 'zod'

const detectionDisplaySettingsFormFields = z.object({
	detectionDisplay: z.object({
		showWhitelistedDetections: z.boolean(),
		showControllerDetections: z.boolean(),
	}),
})

const detectionDisplaySettingsFormSchema =
	detectionDisplaySettingsFormFields.superRefine((data, ctx) => {})

export default detectionDisplaySettingsFormSchema
