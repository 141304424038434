// Packages
import { useEffect, useMemo, useState } from 'react'
import get from 'lodash/get'

// Alias
import SentryMarker from '@/components/Markers/SentryMarker/SentryMarker'

// Types
import type { LatLngExpression } from 'leaflet'
import type {
	SiteInstallationLocation,
	SiteInstallationType,
} from '@/store/types'

export type InstallationProps = {
	name: string
	sentryType: SiteInstallationType
	duration: number
	currentTime: number
	locations: SiteInstallationLocation[]
}

const Installation = ({
	name,
	sentryType,
	duration,
	currentTime,
	locations,
}: InstallationProps) => {
	const [currentPositionIndex, setCurrentPositionIndex] = useState(0)
	const position = useMemo<LatLngExpression>(
		() => [
			locations[currentPositionIndex].latitude,
			locations[currentPositionIndex].longitude,
		],
		[locations, currentPositionIndex]
	)
	const color = useMemo(
		() => get(locations, `${currentPositionIndex}.status_color`, 'green'),
		[locations, currentPositionIndex]
	)

	useEffect(() => {
		const numberOfLocations = locations?.length ?? 0
		const timePerDetection = duration / numberOfLocations
		const newIndex = Math.floor(currentTime / timePerDetection)
		if (newIndex < numberOfLocations && newIndex !== currentPositionIndex) {
			setCurrentPositionIndex(newIndex)
		}
	}, [currentTime, locations?.length, duration, currentPositionIndex])

	return (
		<SentryMarker
			name={name}
			position={position}
			sentryType={sentryType}
			iconColor={color ? color : 'green'}
		/>
	)
}

export default Installation
