import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import type { ReactNode } from 'react'

// Redux
import { useGetIceServersQuery } from '@Store/system/systemApi'

// Janus
import Janus from './utils/janus'
import adapter from 'webrtc-adapter'
import type { JanusJS } from './utils/janus.d.ts'

// Constants
import { janusUrl } from '@Constants/api'

// Context
import JanusContext from './JanusContext'
import type { ContextValue } from './JanusContext'

import { useInterval } from '@Hooks/useInterval'
import { useUiLoggerMutation } from '@/store/ui/uiLoggerApi'
import { useParams } from 'react-router-dom'

// Provider
type JanusProviderProps = {
	debug?: boolean
	disabled?: boolean
	children: ReactNode
}

const JanusProvider = ({
	debug = false,
	disabled = false,
	children,
}: JanusProviderProps) => {
	const isConnecting = useRef<boolean>(false)
	const isConnected = useRef<boolean>(false)
	const { siteId: siteIdParam } = useParams()
	const siteId = Number(siteIdParam)

	const [logUiMessage] = useUiLoggerMutation()

	// Janus
	const janusDeps = Janus.useDefaultDependencies({ adapter })
	const janusInstance = useRef<Janus | null>(null)
	const janusHandle = useRef<JanusJS.PluginHandle | null>(null)

	// Coturn ICE Server config
	const { isSuccess: isCoturnSuccess, data: iceServers } =
		useGetIceServersQuery(undefined, { skip: disabled })

	// Attached DroneOpt Stream ID
	const [streamId, setStreamId] = useState<number | null>(null)

	// Video ref
	const videoRef = useRef<HTMLVideoElement | null>(null)

	// Track current viewers count
	const [viewerCount, setViewerCount] = useState<number | null>(null)

	const [currentBitrate, setCurrentBitrate] = useState<string | undefined>(
		undefined
	)

	const [isStreaming, setIsStreaming] = useState<boolean>(false)

	const init = useCallback(() => {
		if (!isCoturnSuccess || isConnecting.current || isConnected.current) return
		if (!janusUrl) return
		Janus.init({
			debug: debug ? 'all' : (['error'] as JanusJS.DebugLevel[]),
			dependencies: janusDeps,
			callback: () => {
				if (!Janus.isWebrtcSupported()) {
					console.error('WebRTC not supported')
					return
				}
				const janus = new Janus({
					server: janusUrl,
					iceServers,
					success: () => {
						isConnected.current = true
						isConnecting.current = false
						janusInstance.current = janus
					},
					error: (error) => {
						Janus.error('janus: janus error', error)
						reconnectStream()
					},
					destroyed: () => {
						Janus.error('janus: janus destroyed')
						janusHandle.current = null
						janusInstance.current = null
						isConnecting.current = false
						isConnected.current = false
					},
				})
			},
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debug, iceServers, isCoturnSuccess, janusDeps])

	const destroyJanus = () => {
		setStreamId(null)
		setViewerCount(null)
		setIsStreaming(false)
		janusHandle.current?.send({ message: { request: 'stop' } })
		janusHandle.current?.hangup()
		janusHandle.current?.detach({})
		janusInstance.current?.destroy({})
	}

	const restartJanusSession = (error: string) => {
		console.log(`janus session died, restarting (${error})`)
		logUiMessage({
			level: 'error',
			message: `janus session died, restarting (${error})`,
			siteId,
		})
		isConnected.current = false
		destroyJanus()
		init()
		startStream()
		console.log('janus session restarted')
		logUiMessage({
			level: 'info',
			message: 'janus session restarted',
			siteId,
		})
	}

	const reconnectStream = () => {
		console.log('janus reconnecting stream')
		logUiMessage({
			level: 'warn',
			message: 'janus reconnecting stream',
			siteId,
		})
		janusInstance.current?.reconnect({
			success: () => {
				janusHandle.current?.createOffer({
					iceRestart: true,
					media: {
						audioSend: false,
						videoSend: false,
					},
				})

				janusHandle.current?.send({
					message: { request: 'watch', id: streamId },
				})

				console.log('janus stream reconnected')
				logUiMessage({
					level: 'info',
					message: 'janus stream reconnected',
					siteId,
				})
			},
			error: restartJanusSession,
		})
	}

	const startStream = () => {
		// If turning off a camera, stop the janus stream
		if (streamId === null && janusHandle.current) {
			janusHandle.current.send({ message: { request: 'stop' } })
			setViewerCount(null)
			setIsStreaming(false)
			return
		}
		// Nothing to do
		if (!janusInstance.current || !videoRef.current) {
			return
		}

		// Start the stream
		janusInstance.current.attach({
			plugin: 'janus.plugin.streaming',
			opaqueId: 'stream-' + Janus.randomString(12),
			success: (handle) => {
				janusHandle.current = handle
				janusHandle.current?.send({
					message: { request: 'watch', id: streamId },
				})
			},
			error: (error) => {
				console.log(`janus failed to start session: (${error})`)
				logUiMessage({
					level: 'error',
					message: `janus failed to start session (${error})`,
					siteId,
				})
				Janus.error('janus error:', error)
			},
			iceState: (state) => {
				Janus.debug('janus iceState:', state)
			},
			webrtcState: (state) => {
				Janus.debug('janus webrtcState:', state)
			},
			slowLink: (uplink, lost) => {
				Janus.debug('janus slowLink:', [uplink, lost])
				// TODO: reflect this in the UI?
				// console.error('janus: ' + lost + ' packets dropped')
			},
			detached: (error: any) => {
				Janus.error('janus detached:', error)
				janusHandle.current = null
			},
			onmessage: (message, jsep) => {
				Janus.debug('jsep', jsep)
				if (message.error) {
					Janus.debug('janus error: ', message.error)
				}
				if (jsep && jsep.type === 'offer') {
					Janus.debug('onmessage', janusHandle.current)
					janusHandle.current?.createAnswer({
						jsep: jsep,
						media: {
							audioSend: false,
							videoSend: false,
						},
						success: (jsep) => {
							if (jsep) {
								Janus.debug('janus request start')
								janusHandle.current?.send({
									message: { request: 'start' },
									jsep,
								})
							} else Janus.error('no jsep!')
						},
						error: (error) => {
							Janus.error('ERROR')
							Janus.error(error)
						},
					})
				} else if (jsep) {
					Janus.debug('jsep: ', jsep)
				} else Janus.debug(message)
			},
			oncleanup: () => {
				Janus.debug('janus: cleanup')
				janusHandle.current = null
			},
			onremotestream: async (stream) => {
				// This usually happens when there's a packet loss, therefore we'd need to kick the ice server back up, otherwise the stream will freeze
				if (!stream.active) {
					reconnectStream()
					return
				}

				if (videoRef.current && stream.active) {
					setIsStreaming(true)
					Janus.attachMediaStream(videoRef.current, stream)
				}
			},
		})
	}

	// Initialise Janus
	useEffect(() => {
		init()
	}, [debug, isCoturnSuccess, iceServers, janusDeps, init, isStreaming])

	// Start/Stop a Janus Stream
	useEffect(() => {
		startStream()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [streamId])

	// Viewers Count interval timer
	useInterval(
		() => {
			janusHandle.current?.send({
				message: { request: 'info', id: streamId },
				success: (data) => {
					if (data.info?.viewers) setViewerCount(data.info.viewers)
				},
			})

			janusHandle.current && setCurrentBitrate(janusHandle.current.getBitrate())
		},
		streamId ? 5000 : null
	)

	useEffect(() => {
		if (!streamId) return

		const handleReconnectStream = () =>
			setTimeout(() => reconnectStream(), 1000)

		// When the user went offline, and comes back online, janus instance is deaad and stopped sending onRemoteMessage
		// Therefore, we'd need to manually do it
		window.addEventListener('online', handleReconnectStream)

		return () => window.removeEventListener('online', handleReconnectStream)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [streamId])

	// Teardown Janus when this provider unmounted
	useEffect(() => {
		return () => {
			destroyJanus()
		}
	}, [])

	const contextValue: ContextValue = {
		isReady: isConnected.current,
		isStreaming,
		videoRef,
		streamId,
		viewerCount,
		currentBitrate,
		setStreamId,
	}

	return (
		<JanusContext.Provider value={contextValue}>
			{children}
		</JanusContext.Provider>
	)
}

const useJanus = () => {
	const context = useContext<ContextValue | undefined>(JanusContext)
	if (context === undefined) {
		throw new Error('useJanus must be used within <JanusProvider>')
	}
	const { isReady, isStreaming, videoRef, viewerCount, setStreamId } = context

	return {
		isReady,
		isStreaming,
		videoRef,
		viewerCount,
		setStreamId,
	}
}

export default JanusProvider
export { useJanus }
