import { useTranslation } from 'react-i18next'
import {
	Grid,
	GridItem,
	AccordionItem,
	AccordionButton,
	AccordionIcon,
	AccordionPanel,
	Flex,
	Box,
	Text,
	Divider,
	useTheme,
	useDisclosure,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Button,
} from '@chakra-ui/react'

import Field from '@Components/FormElements'

import { useFormContext, useFormState } from 'react-hook-form'
import type { ThreatClassifierContribution } from '../utils/ThreatAI'
import type {
	ClassifyByZoneOriginError,
	ThreatConfigClickElement,
} from '../utils/types'
import Tooltip from '@UI/Tooltip/Tooltip'
import { selectHasThreatZones, useGetZonesQuery } from '@/store/zones/zonesApi'
import type { FormSelectValueType } from '@/components/FormElements/Select/Select'
import { MdMap } from 'react-icons/md'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import type { Zone } from '@/store/types'
import ThreatConfigZonesActiveButton from './ThreatConfigZonesActiveButton'

type ThreatZonesFormProps = {
	classifier: ThreatClassifierContribution
	divider: boolean
	threatZones: Zone[]
	siteId: number
	isDirty: boolean
	onClickAccordion: (e: ThreatConfigClickElement) => void
}

const ThreatZonesForm = ({
	classifier,
	divider,
	threatZones,
	siteId,
	isDirty,
	onClickAccordion,
}: ThreatZonesFormProps) => {
	const { register, watch } = useFormContext()
	const { errors } = useFormState()
	const { isOpen, onOpen, onClose } = useDisclosure()
	const { t } = useTranslation('panels', {
		keyPrefix: 'threatAnalysis',
	})
	const {
		semanticTokens: { colors },
	} = useTheme()
	const navigate = useNavigate()

	const [byThreatZoneActive, ...rest] = watch([
		'classify_by_zone_origin.active',
		'classify_by_class.active',
		'classify_by_communication.active',
		'classify_by_geo_location.active',
		'classify_by_legal_limits.active',
		'classify_by_time.active',
		'classify_by_protected_zones.active',
	])
	const isDisabled = byThreatZoneActive && rest.every((c) => !c)

	const { hasThreatZones } = useGetZonesQuery(
		{ siteId },
		{
			selectFromResult: ({ data }) => ({
				hasThreatZones: selectHasThreatZones(data),
			}),
		}
	)

	const redirect = useCallback(() => {
		navigate(`/${siteId}/zones`, {
			state: { activeTab: 1 },
		})
	}, [navigate, siteId])

	const redirectToZones = useCallback(() => {
		if (!isDirty) redirect()
		else onOpen()
	}, [redirect, onOpen, isDirty])

	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose} isCentered>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{t('modal.heading')}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>{t('modal.body')}</ModalBody>

					<ModalFooter>
						<Button onClick={redirect}>{t('modal.discard')}</Button>
						<Button variant='ghost' onClick={onClose}>
							{t('modal.cancel')}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
			<Tooltip
				label={!hasThreatZones && t('tooltips.noThreatZones')}
				type='wrapper'
			>
				<AccordionItem>
					<AccordionButton
						_hover={{ bg: 'transparent' }}
						bg={colors.body_bg}
						paddingBlock={4}
						marginTop={0}
						onClick={(e) => onClickAccordion(e)}
					>
						<Flex flexDirection='row' flex='1'>
							<Tooltip
								label={isDisabled ? t('tooltips.parameterDisabled') : undefined}
							>
								<Box
									height='100%'
									sx={{ '& > div > div': { marginTop: 0 } }}
									// Rewrite margin for switch
								>
									<Field.Switch
										id='threat-zones'
										register={register('classify_by_zone_origin.active')}
										disabled={isDisabled}
									/>
								</Box>
							</Tooltip>
							<Flex
								flexGrow='0'
								flexDir='column'
								alignItems='flex-start'
								w={80}
							>
								<Box
									textAlign='start'
									textTransform='none'
									fontSize='sm'
									fontWeight='normal'
									color='white'
								>
									<Text>{t('headings.threatZones')}</Text>
								</Box>
								<Box>
									<Text
										fontSize='xs'
										fontWeight='normal'
										textTransform='none'
										textAlign='left'
										lineHeight='125%'
									>
										{t('tooltips.threatZones')}
									</Text>
								</Box>
							</Flex>
							<Flex
								flex='1 0 75px'
								padding={0}
								h='100%'
								justifyContent='space-between'
							>
								<Text
									fontSize='sm'
									fontWeight='normal'
									textAlign='center'
									w={12}
								>
									{classifier.ContributionThreatLevel
										? Math.round(classifier.ContributionThreatLevel * 100)
										: 0}
									%
								</Text>
								<AccordionIcon color={colors.button_text} />
							</Flex>
						</Flex>
					</AccordionButton>
					<AccordionPanel bg={colors.body_bg}>
						<Grid
							templateColumns='repeat(8, 11fr)'
							bg={colors.card_background}
							rowGap={0}
							paddingBottom={4}
						>
							<GridItem rowSpan={3} colSpan={1} />
							<GridItem rowSpan={1} colSpan={6}>
								<GridItem rowSpan={3} colSpan={6} marginBottom={2}>
									<Flex alignItems='center' justifyContent='end'>
										<Box onClick={redirectToZones} cursor='pointer'>
											<Flex alignItems='center' justifyContent='space-around'>
												<MdMap />
												<Text
													textTransform='uppercase'
													paddingLeft={2}
													fontSize='xs'
												>
													{t('tooltips.editZones')}
												</Text>
											</Flex>
										</Box>
									</Flex>
								</GridItem>
								<GridItem rowSpan={1} colSpan={6}>
									{threatZones &&
										threatZones.map((zone: Zone, index: number) => {
											return (
												<Box key={index} height={10}>
													<Divider />
													<Flex
														key={index}
														h='100%'
														alignItems='center'
														justifyContent='space-between'
													>
														<Text fontSize='sm'>{zone.name}</Text>
														<ThreatConfigZonesActiveButton zoneId={zone.id} />
													</Flex>
												</Box>
											)
										})}
								</GridItem>
								<Field.Select
									title='Priority'
									options={
										t('forms.priorityOptions', {
											returnObjects: true,
										}) as FormSelectValueType[]
									}
									testId='classify-by-zone-origin-priority'
									register={register('classify_by_zone_origin.priority')}
									error={
										(errors as ClassifyByZoneOriginError)
											?.classify_by_zone_origin?.priority?.message
									}
								/>
							</GridItem>
						</Grid>
					</AccordionPanel>
					{divider ? <Divider borderColor={colors.border_color} /> : null}
				</AccordionItem>
			</Tooltip>
		</>
	)
}

export default ThreatZonesForm
