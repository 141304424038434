import { useTranslation } from 'react-i18next'

import { Box } from '@chakra-ui/react'

import FetchError from '@UI/FetchError/FetchError'
import Skeleton from '@UI/Skeleton/Skeleton'

import { useGetAlertsSummaryQuery } from '@Store/analytics/analyticsApi'

import NumberDisplay from '../NumberDisplay'
import { DEFAULT_PARAMS } from './AlertsSummary'

type AlertsDetailsProps = {
	siteId: number
	startTime: string
	endTime: string
}

const AlertsDetails = ({ siteId, startTime, endTime }: AlertsDetailsProps) => {
	const { t } = useTranslation('pages', {
		keyPrefix: 'analytics.alertsSummary',
	})

	const {
		isSuccess,
		isError,
		isLoading,
		isFetching,
		refetch,
		data: alerts,
	} = useGetAlertsSummaryQuery({
		siteId: Number(siteId),
		format: 'table',
		startTime: startTime,
		endTime: endTime,
		...DEFAULT_PARAMS, // Provide default params so rtk query doesn't run this twice
	})

	const totalCount = alerts?.meta.total

	return (
		<Skeleton isLoaded={!isLoading && !isFetching}>
			<Box w='250px'>
				{isSuccess && (
					<>
						<NumberDisplay
							title={t('numberOfAlerts')}
							number={totalCount ?? 0}
							testId='number-of-alerts'
						/>
					</>
				)}
				{isError && (
					<FetchError refetch={refetch} entity={t('entities.data')} />
				)}
			</Box>
		</Skeleton>
	)
}

export default AlertsDetails
