// React
import { Controller, useFormContext } from 'react-hook-form'
// Chakra
import { FormControl, FormErrorMessage } from '@chakra-ui/react'
// Components
import SelectUI, { type SelectVariantType } from '@Components/_UI/Select/Select'
import Tooltip from '@UI/Tooltip/Tooltip'
// Types
import type { RegisterType } from '@Components/FormElements/types/RegisterType'
import type { FormElementProps } from '@Components/FormElements/types/FormElementProps'

export type FormSelectValueType = {
	value: string | number | null | Record<string, unknown>
	label: string
}

type SelectProps = FormElementProps & {
	options: FormSelectValueType[]
	placeholder?: string
	value?: string | number | null | Record<string, unknown>
	onCustomChange?: (option: FormSelectValueType) => void
	defaultValue?:
		| FormSelectValueType
		| string
		| number
		| null
		| Record<string, unknown>
	styles?: React.CSSProperties
	testId?: string
	isSearchable?: boolean
	variant?: SelectVariantType
} & RegisterType<string>

// TODO: needs onBlur for isDirty support
// TODO: check onChange is sound
const Select = ({
	id = undefined,
	title,
	placeholder = undefined,
	options,
	register,
	error,
	defaultValue = undefined,
	disabled = false,
	isSearchable = false,
	styles,
	testId,
	tooltip,
	onCustomChange,
	variant = 'default',
}: SelectProps) => {
	const htmlId = id ?? register?.name ?? 'select'
	const { control } = useFormContext()

	return (
		<Tooltip label={tooltip} closeOnClick type='info'>
			<FormControl
				isInvalid={!!error}
				isDisabled={disabled}
				sx={styles}
				data-testid={`${testId}-control`}
			>
				<Controller
					control={control}
					name={htmlId}
					render={({ field: { value, onChange } }) => {
						// Check if value is number
						const isValueNumber = typeof value === 'number'

						const handleValue = (options: FormSelectValueType[]) => {
							return options.find((option) =>
								isValueNumber
									? Number(option.value) === value
									: option.value === value
							)
						}
						const handleChange = (option: FormSelectValueType) => {
							onChange(isValueNumber ? Number(option?.value) : option?.value)
						}

						return (
							<SelectUI
								title={title}
								id={htmlId}
								options={options}
								placeholder={placeholder}
								isDisabled={disabled}
								isSearchable={isSearchable}
								defaultValue={defaultValue}
								value={handleValue(options)}
								onChange={(option: FormSelectValueType) =>
									onCustomChange ? onCustomChange(option) : handleChange(option)
								}
								variant={variant}
								testId={testId}
							/>
						)
					}}
				/>
				<FormErrorMessage>{error}</FormErrorMessage>
			</FormControl>
		</Tooltip>
	)
}

export default Select
