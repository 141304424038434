// Packages
import { useCallback, useEffect, useMemo } from 'react'
import { Box, Input, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import noop from 'lodash/noop'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useNavigate } from 'react-router-dom'

// Relatives
import Headings from '@/components/_UI/Headings/Headings'
import recordingFormSchema from './RecordingToolForm.schema'

// Types
import type { TypeOf } from 'zod'
import { FormButtons } from '@/components/FormElements/FormButtons/FormButtons'

export type RecordingToolFormProps = {
	className?: string
	siteId?: number
	filename: string
	autoSave?: boolean
	onSubmit?: (values: any) => void
}

type Schema = TypeOf<typeof recordingFormSchema>

const RecordingToolForm = ({
	siteId,
	filename = '',
	autoSave = true,
	onSubmit = noop,
}: RecordingToolFormProps) => {
	const { t } = useTranslation('recordingTool', {
		keyPrefix: 'component.action.modal.form',
	})

	const navigate = useNavigate()
	const defaultValues = useMemo(() => ({ filename }), [filename])
	const {
		control,
		handleSubmit,
		watch,
		formState: { isSubmitting, isDirty },
	} = useForm<Schema>({
		resolver: zodResolver(recordingFormSchema),
		defaultValues,
	})

	const handleCancel = useCallback(
		() => navigate('/' + siteId),
		[navigate, siteId]
	)

	useEffect(() => {
		if (autoSave) {
			const subscription = watch(
				handleSubmit(onSubmit) as (values: any) => void
			)

			return () => subscription.unsubscribe()
		}
	}, [handleSubmit, watch, onSubmit, autoSave])

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Controller
				control={control}
				name='filename'
				rules={{ required: false }}
				render={({ field: { onChange, value } }) => (
					<Box>
						<Headings.FormHeading title={t('filename.title')} />
						<Input
							value={value}
							onChange={onChange}
							placeholder={t('filename.placeholder')}
							type='text'
							w='calc(100% - 2px)'
							data-testid='record-name'
						/>
						<Text fontSize='sm' paddingTop='8px'>
							{t('filename.description')}
						</Text>
					</Box>
				)}
			/>
			{!autoSave && (
				<FormButtons
					isSubmitting={isSubmitting}
					isDirty={isDirty}
					handleCancel={handleCancel}
				/>
			)}
		</form>
	)
}

export default RecordingToolForm
