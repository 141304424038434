import { useEffect } from 'react'

import L from 'leaflet'

const setTooltip = ({ title, description }: Tooltip) => {
	;(L as any).drawLocal.edit.handlers.edit.tooltip.text = title
	;(L as any).drawLocal.edit.handlers.edit.tooltip.subtext = description
}

type Tooltip = {
	title: string
	description?: string
}

const useLeafletDrawToolTip = (props?: Tooltip) => {
	useEffect(() => {
		setTooltip({
			title: props?.title || '',
			description: props?.description || '',
		})
	}, [props?.description, props?.title])

	const resetToolTip = () => {
		setTooltip({
			title: '',
			description: '',
		})
	}

	return {
		resetToolTip,
		setTooltip,
	}
}

export default useLeafletDrawToolTip
