import { useTranslation } from 'react-i18next'
import { useGetSiteLiveQuery } from '@Store/sites/sitesWsApi'
import { useUpdateSiteAutoJamMutation } from '@Store/sites/sitesApi'

import QuickActionsButton from '../QuickActionsButton/QuickActionsButton'

import { useAppDispatch } from '@Store/index'
import { addToast } from '@Store/ui/uiSlice'
import type { CoreApiError } from '@Store/utils/errorUtils'
import AutoDisruptIcon from './AutoDisruptIcon'

type AutoDisruptProps = {
	siteId: number
}

const AutoDisruptButton = ({ siteId }: AutoDisruptProps) => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'quickActions.disruptorActions',
	})

	const { autoJam } = useGetSiteLiveQuery(siteId, {
		selectFromResult: ({ data }) => ({
			autoJam: data?.auto_jam,
		}),
	})

	const dispatch = useAppDispatch()
	const [updateSiteAutoJamMutation] = useUpdateSiteAutoJamMutation()

	const toggleAutoJam = async () => {
		try {
			await updateSiteAutoJamMutation({
				siteId,
				autoJam: !autoJam || false,
			}).unwrap()
		} catch (error: unknown) {
			dispatch(
				addToast({
					type: 'error',
					title: t('status.autoDisruptError'),
					siteId,
					error,
					id: `auto-disrupt-${(error as CoreApiError).status}`,
				})
			)
			throw new Error(t('status.autoDisruptError'))
		}
	}

	return (
		<QuickActionsButton
			icon={(props) => <AutoDisruptIcon active={!!autoJam} {...props} />}
			label={autoJam ? t('stopAutoDisrupt') : t('autoDisrupt')}
			onClick={toggleAutoJam}
			isActive={autoJam}
			testId='auto-disrupt'
		/>
	)
}

export default AutoDisruptButton
