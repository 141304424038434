import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import type { TypeOf } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'

import {
	Card,
	CardHeader,
	Box,
	Text,
	Accordion,
	useTheme,
	Grid,
	GridItem,
} from '@chakra-ui/react'
import Headings from '@UI/Headings/Headings'

import DistributionChart, {
	type DistributionChartItem,
} from '@Components/_UI/Charts/DistributionChart'
import { useTranslation } from 'react-i18next'
import { FormProvider } from 'react-hook-form'
import { setFormError } from '@/components/Forms/formHelper'

import { computeMaxContributions, ThreatClassifierName } from './utils/ThreatAI'
import { FormButtons } from '@Components/FormElements/FormButtons/FormButtons'

import {
	DroneClassificationForm,
	ProtectedZonesForm,
	DroneTransmissionMethodForm,
	GeoLocationForm,
	LegalLimitsForm,
	ThreatZonesForm,
	MonitoringScheduleForm,
} from './ThreatAnalysisFormParts/index'

import { useUpdateSiteThreatAnalysisConfigMutation } from '@/store/sites/sitesApi'

import threatAnalysisFormSchema from './ThreatAnalysisForm.schema'
import { useCallback, useMemo, useState } from 'react'
import type { ThreatAnalysisConfig, Zone } from '@Store/types'
import type { ThreatConfigClickElement } from './utils/types'

export interface ThreatAnalysisFormProps {
	siteId: number
	defaultValues: ThreatAnalysisConfig
	dependencies: {
		alertZones: Zone[]
		threatZones: Zone[]
	}
}

const ThreatAnalysisForm = ({
	siteId,
	defaultValues,
	dependencies: { alertZones, threatZones },
}: ThreatAnalysisFormProps) => {
	const navigate = useNavigate()
	const { t } = useTranslation('panels', { keyPrefix: 'threatAnalysis' })
	const {
		semanticTokens: { colors },
	} = useTheme()
	const [index, setIndex] = useState<number[]>([])
	type Schema = TypeOf<typeof threatAnalysisFormSchema>

	const methods = useForm<Schema>({
		resolver: zodResolver(threatAnalysisFormSchema),
		defaultValues,
	})

	const {
		formState: { isSubmitting, isDirty },
		handleSubmit,
		watch,
		setError,
	} = methods

	const [
		classify_by_class,
		classify_by_protected_zones,
		classify_by_zone_origin,
		classify_by_communication,
		classify_by_legal_limits,
		classify_by_geo_location,

		classify_by_time,
	] = watch([
		'classify_by_class',
		'classify_by_protected_zones',
		'classify_by_zone_origin',
		'classify_by_communication',
		'classify_by_legal_limits',
		'classify_by_geo_location',
		'classify_by_time',
	])

	const threatConfig = {
		classify_by_class,
		classify_by_protected_zones,
		classify_by_zone_origin,
		classify_by_communication,
		classify_by_legal_limits,
		classify_by_geo_location,
		classify_by_time,
	}

	const threatCalculations = computeMaxContributions(threatConfig)

	const accordionChange = (i: number, e: ThreatConfigClickElement) => {
		if (e?.target?.checked !== undefined) {
			return e?.target?.checked
				? setIndex([...index, i])
				: setIndex(index.filter((index) => index !== i))
		}
		return setIndex(
			index.includes(i) ? index.filter((index) => index !== i) : [...index, i]
		)
	}

	const formItems: DistributionChartItem[] = useMemo(() => {
		return [
			{
				label: 'drone-classification',
				weight: threatCalculations.getClassifierContribution(
					ThreatClassifierName.ClassifyByClass
				).ContributionThreatLevel,
				color: colors.charts.distribution.droneClassification,
				chartLabel: t('charts.distributionChart.droneClassification'),
			},
			{
				label: 'protected-zones',
				weight: threatCalculations.getClassifierContribution(
					ThreatClassifierName.ClassifyByProtectedZones
				).ContributionThreatLevel,
				color: colors.charts.distribution.protectedZones,
				chartLabel: t('charts.distributionChart.protectedZones'),
			},
			{
				label: 'threat-zones',
				weight: threatCalculations.getClassifierContribution(
					ThreatClassifierName.ClassifyByZoneOrigin
				).ContributionThreatLevel,
				color: colors.charts.distribution.threatZones,
				chartLabel: t('charts.distributionChart.threatZones'),
			},
			{
				label: 'drone-transmission-method',
				weight: threatCalculations.getClassifierContribution(
					ThreatClassifierName.ClassifyByCommunication
				).ContributionThreatLevel,
				color: colors.charts.distribution.droneTransmissionMethod,
				chartLabel: t('charts.distributionChart.droneTransmissionMethod'),
			},
			{
				label: 'geo-location',
				weight: threatCalculations.getClassifierContribution(
					ThreatClassifierName.ClassifyByGeoLocation
				).ContributionThreatLevel,
				color: colors.charts.distribution.geoLocation,
				chartLabel: t('charts.distributionChart.geoLocation'),
			},
			{
				label: 'legal-limits',
				weight: threatCalculations.getClassifierContribution(
					ThreatClassifierName.ClassifyByLegalLimits
				).ContributionThreatLevel,
				color: colors.charts.distribution.legalLimits,
				chartLabel: t('charts.distributionChart.legalLimits'),
			},
			{
				label: 'monitoring-schedule',
				weight: threatCalculations.getClassifierContribution(
					ThreatClassifierName.ClassifyByTime
				).ContributionThreatLevel,
				color: colors.charts.distribution.monitoringSchedule,
				chartLabel: t('charts.distributionChart.monitoringSchedule'),
			},
		]
	}, [threatCalculations, colors, t])

	const [updateThreatAnalysis] = useUpdateSiteThreatAnalysisConfigMutation()

	const classificationFormProps = useMemo(() => {
		return {
			classifier: threatCalculations.getClassifierContribution(
				ThreatClassifierName.ClassifyByClass
			),
			divider: true,
		}
	}, [threatCalculations])

	const protectedZonesFormProps = useMemo(() => {
		return {
			classifier: threatCalculations.getClassifierContribution(
				ThreatClassifierName.ClassifyByProtectedZones
			),
			divider: true,
			alertZones,
			siteId,
			isDirty,
		}
	}, [threatCalculations, alertZones, siteId, isDirty])

	const threatZonesFormProps = useMemo(() => {
		return {
			classifier: threatCalculations.getClassifierContribution(
				ThreatClassifierName.ClassifyByZoneOrigin
			),
			divider: true,
			threatZones,
			isDirty,
			siteId,
		}
	}, [threatCalculations, threatZones, siteId, isDirty])

	const transmissionMethodFormProps = useMemo(() => {
		return {
			classifier: threatCalculations.getClassifierContribution(
				ThreatClassifierName.ClassifyByCommunication
			),
			divider: true,
			siteId,
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [threatCalculations])

	const geoLocationFormProps = useMemo(() => {
		return {
			classifier: threatCalculations.getClassifierContribution(
				ThreatClassifierName.ClassifyByGeoLocation
			),
			divider: true,
		}
	}, [threatCalculations])

	const legalLimitsFormProps = useMemo(() => {
		return {
			classifier: threatCalculations.getClassifierContribution(
				ThreatClassifierName.ClassifyByLegalLimits
			),
			divider: true,
		}
	}, [threatCalculations])

	const monitoringScheduleFormProps = useMemo(() => {
		return {
			classifier: threatCalculations.getClassifierContribution(
				ThreatClassifierName.ClassifyByTime
			),
			divider: false,
		}
	}, [threatCalculations])

	const handleSave = useCallback(
		async (payload: Schema) => {
			try {
				await updateThreatAnalysis({
					id: siteId,
					...payload,
				})
					.unwrap()
					.then(() => navigate(`/${siteId}`))
			} catch (error: any) {
				setFormError<Schema>({ error, setError })
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[updateThreatAnalysis, navigate, setFormError, setError, siteId]
	)

	const handleCancel: any = useCallback(
		() => navigate(`/${siteId}`),
		[navigate, siteId]
	)

	return (
		<Box className='ThreatAnalysis' height='100%' margin={0}>
			<Headings.Section title={t('headings.threatAnalysis')} />
			<Card marginTop={6}>
				<CardHeader
					data-testid='parameter-distribution'
					fontWeight='medium'
					padding={0}
				>
					{t('headings.parameterDistribution')}
				</CardHeader>
				<Text data-testid='parameter-distribution-tooltip' fontSize={'sm'}>
					{t('tooltips.parameterDistribution')}
				</Text>
				<DistributionChart items={formItems} />
			</Card>
			<Grid
				templateColumns='repeat(16, 1fr)'
				margin={0}
				padding={0}
				color={colors.label_color}
			>
				<GridItem colSpan={6} rowSpan={1} textAlign='center'>
					<Text fontSize='sm'>{t('tooltips.parameter')}</Text>
				</GridItem>
				<GridItem colSpan={7} rowSpan={1} />
				<GridItem colSpan={3} rowSpan={1} textAlign='start'>
					<Text fontSize='sm'>{t('tooltips.weight')}</Text>
				</GridItem>
			</Grid>
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(handleSave)}>
					<Accordion
						background='body_bg'
						allowMultiple={true}
						index={index}
						marginTop={0}
					>
						<DroneClassificationForm
							{...classificationFormProps}
							onClickAccordion={(e) => accordionChange(0, e)}
						/>
						<ProtectedZonesForm
							{...protectedZonesFormProps}
							onClickAccordion={(e) => accordionChange(1, e)}
						/>
						<ThreatZonesForm
							{...threatZonesFormProps}
							onClickAccordion={(e) => accordionChange(2, e)}
						/>
						<DroneTransmissionMethodForm
							{...transmissionMethodFormProps}
							onClickAccordion={(e) => accordionChange(3, e)}
						/>
						<GeoLocationForm
							{...geoLocationFormProps}
							onClickAccordion={(e) => accordionChange(4, e)}
						/>
						<LegalLimitsForm
							{...legalLimitsFormProps}
							onClickAccordion={(e) => accordionChange(5, e)}
						/>
						<MonitoringScheduleForm
							{...monitoringScheduleFormProps}
							onClickAccordion={(e) => accordionChange(6, e)}
						/>
					</Accordion>
					<FormButtons
						isSubmitting={isSubmitting}
						isDirty={isDirty}
						handleCancel={handleCancel}
					/>
				</form>
			</FormProvider>
		</Box>
	)
}

export default ThreatAnalysisForm
