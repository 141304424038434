import type { PolylineProps } from 'react-leaflet'
import LatLon from 'geodesy/latlon-ellipsoidal-vincenty'
import Polyline from '@Components/MapShapes/Polyline/Polyline'

import type { Detection } from '@Store/types'

type RfDetectionLineProps = {
	latitude: number
	longitude: number
	bearing: number
	reach: number
	className?: string
	id?: string
	classification?: Detection['classification']
	onClick?: () => void
	onMouseDown?: () => void
} & Omit<PolylineProps, 'positions'>

const RfDetectionLine = ({
	latitude,
	longitude,
	bearing,
	reach,
	className = undefined,
	classification,
	onClick,
	onMouseDown,
	color,
	weight = 1,
}: RfDetectionLineProps) => {
	const centerPoint = new LatLon(latitude, longitude)
	const destinationPoint = centerPoint.destinationPoint(reach, bearing)
	const dashArray =
		classification === 'controller' ? [2, 16, 24, 16] : undefined
	return (
		<Polyline
			positions={[centerPoint, destinationPoint]}
			pathOptions={{ color, weight, dashArray }}
			className={className}
			eventHandlers={{
				click() {
					typeof onClick === 'function' && onClick()
				},
				mousedown() {
					typeof onMouseDown === 'function' && onMouseDown()
				},
			}}
			testId='rf-detection-line'
		/>
	)
}

export default RfDetectionLine
