// React/Redux
import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { nanoid } from '@reduxjs/toolkit'
// Components
import { Box, Flex } from '@chakra-ui/react'
import Field from '@Components/FormElements'
// Forms
import useRfFilterFormOptions from '@Forms/RfFilterForm/_hooks/useRfFilterFormOptions'
import type { DefaultRfFilterValuesType } from '@Forms/RfFilterForm/RfFiltersForms.schema'
// Types
import type { RfSensor_SupportedDetections } from '@Store/types'

const VendorSelect = ({
	defaultValue,
	defaultProtocolValue,
	options,
	isFilterByVendorAndProtocol,
	errors,
	vendor,
}: {
	defaultValue: { label: string; value: string }
	options: Array<{ label: string; value: string }>
	vendor: string
	defaultProtocolValue: string
	isFilterByVendorAndProtocol: boolean
	errors: any
}) => {
	const { t } = useTranslation('forms', { keyPrefix: 'rfFiltersForm' })
	const { register, setValue } = useFormContext()

	useEffect(() => {
		setValue('protocol', defaultProtocolValue)
	}, [vendor, defaultProtocolValue, setValue])

	return (
		<Field.Select
			key={nanoid()}
			title={t('vendor')}
			options={options}
			defaultValue={defaultValue}
			register={register('vendor')}
			error={errors?.vendor?.message}
			disabled={!isFilterByVendorAndProtocol}
			testId='vendor'
		/>
	)
}

const RfFilterFormVendorProtocol = ({
	supportedDetections,
	defaultValues,
	frequencyBand,
	vendor,
	isFilterByVendorAndProtocol,
	errors,
}: {
	supportedDetections: RfSensor_SupportedDetections
	defaultValues: DefaultRfFilterValuesType
	frequencyBand: string
	vendor?: string
	isFilterByVendorAndProtocol: boolean
	isFilterByFrequency: boolean
	errors: any
}) => {
	const { register } = useFormContext()
	const { t } = useTranslation('forms', { keyPrefix: 'rfFiltersForm' })

	const {
		vendorOptions,
		protocolOptions,
		defaultVendorOption,
		defaultProtocolOption,
	} = useRfFilterFormOptions({
		supportedDetections,
		defaultValues,
		frequencyBand,
		vendor,
	})

	if (!vendorOptions) return null
	if (!vendor) return null
	if (!defaultVendorOption) return null
	if (!defaultProtocolOption) return null

	const defaultProtocolValue = defaultProtocolOption.value
	if (!defaultProtocolValue) return null

	return (
		<Box className='FilterByVendorAndProtocol'>
			<Flex gap={4} mb={4}>
				<VendorSelect
					vendor={vendor}
					defaultProtocolValue={defaultProtocolValue}
					defaultValue={defaultVendorOption}
					options={vendorOptions}
					errors={errors}
					isFilterByVendorAndProtocol={isFilterByVendorAndProtocol}
				/>
				<Field.Select
					key={nanoid()}
					title={t('protocol')}
					options={protocolOptions}
					defaultValue={
						defaultProtocolOption as { value: string; label: string }
					}
					register={register('protocol')}
					error={errors?.protocol?.message}
					disabled={!isFilterByVendorAndProtocol}
					testId='protocol'
				/>
			</Flex>
		</Box>
	)
}

export default RfFilterFormVendorProtocol
