import type { ReactNode } from 'react'
import { Flex } from '@chakra-ui/react'

type ActionSectionProps = {
	children: ReactNode
}
const ActionSection = ({ children }: ActionSectionProps) => {
	return (
		<Flex
			justifyContent='space-between'
			gap={2}
			h={5}
			data-testid='action-section'
		>
			{children}
		</Flex>
	)
}

export default ActionSection
