// React
import { useLocation, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// Components
import Accordion from '@Components/FormElements/Accordion/Accordion'
import ErrorBoundary from '@Components/App/ErrorHandling/ErrorBoundary'
import MapLayers from './MapLayers/MapLayers'
import DisplaySettings from '@Forms/MapsZonesForms/DisplaySettings/DisplaySettings'
import ZoneSettings from './ZoneSettings/ZoneSettings'
import MapMarkers from './MapMarkers/MapMarkers'

import FormHeader from '@Forms/FormHeader'
import useAuth from '@Hooks/useAuth'
import { useAppSelector } from '@/store'
import { selectUserId } from '@/store/user/userSlice'
import DetectionDisplaySettingsLoader from '@/components/Forms/DetectionDisplaySettingsForm/DetectionDisplaySettingsLoader'
import { useState } from 'react'

const MapsAndZones = () => {
	const location = useLocation()
	const [activeTab] = useState(
		location.state ? location.state.activeTab : undefined
	)
	const { siteId: siteIdParam } = useParams()
	const siteId = Number(siteIdParam)
	const { isSiteManager } = useAuth()
	const userId = useAppSelector(selectUserId)
	// Translations
	const { t } = useTranslation('panels', {
		keyPrefix: 'mapsAndZones',
	})

	return (
		<>
			<FormHeader title={t('entity')} backRoute={`/${siteId}`} />

			<Accordion.Container defaultIndex={activeTab}>
				{isSiteManager && (
					<>
						<Accordion.Item
							title={t('headers.mapLayers')}
							testId='map-layers-settings'
						>
							<ErrorBoundary>
								<MapLayers siteId={siteId} />
							</ErrorBoundary>
						</Accordion.Item>
						<Accordion.Item
							title={t('headers.zoneSettings')}
							testId='zones-settings'
						>
							<ErrorBoundary>
								<ZoneSettings siteId={siteId} />
							</ErrorBoundary>
						</Accordion.Item>
						<Accordion.Item
							title={t('headers.mapMarkerSettings')}
							testId='map-markers-settings'
						>
							<ErrorBoundary>
								<MapMarkers siteId={siteId} />
							</ErrorBoundary>
						</Accordion.Item>
					</>
				)}
				<Accordion.Item
					title={t('headers.displaySettings')}
					testId='display-settings'
				>
					<ErrorBoundary>
						<DisplaySettings siteId={siteId} />
					</ErrorBoundary>
				</Accordion.Item>
				<Accordion.Item
					title={t('headers.detectionDisplaySettings')}
					testId='detection-display-settings'
				>
					<ErrorBoundary>
						<DetectionDisplaySettingsLoader siteId={siteId} userId={userId} />
					</ErrorBoundary>
				</Accordion.Item>
			</Accordion.Container>
		</>
	)
}

export default MapsAndZones
