// Packages
import { Flex, Switch, Text } from '@chakra-ui/react'
import { useId } from 'react'
import { useTranslation } from 'react-i18next'

// Types
import type { ChangeEvent } from 'react'

export type ShowMarkersProps = {
	value?: string | number
	checked: boolean
	loading?: boolean
	disabled?: boolean
	onChange: (value: ChangeEvent<HTMLInputElement>) => void
}

const ShowMarkers = (props: ShowMarkersProps) => {
	const { value, checked, loading = false, disabled = false, onChange } = props
	const { t } = useTranslation('forms', {
		keyPrefix: 'mapsZonesForm.displaySettings',
	})
	const htmlId = useId()

	return (
		<Flex direction='row'>
			<Switch
				value={value}
				isChecked={checked}
				disabled={disabled || loading}
				onChange={onChange}
				data-testid='show-markers'
				sx={{ width: '32px', top: '4px', left: '2px' }}
			/>
			<Flex direction='column' paddingInlineStart='2'>
				<Text
					as='label'
					htmlFor={htmlId}
					mb='0'
					fontSize='sm'
					opacity={disabled ? 0.4 : undefined}
					whiteSpace='pre-wrap'
				>
					{t('mapMarkers')}
				</Text>
			</Flex>
		</Flex>
	)
}

export default ShowMarkers
