import { useMemo } from 'react'
import {
	DEFAULT_FREQUENCY_BAND_KEY,
	FREQUENCY_LIMITS,
	isValidFrequency,
} from '@Constants/sensorFilters'
import { convertHzToGHz } from '@Utils/mathUtils'

const UseMinMaxFrequencyRange = (frequencyBand: string | null | undefined) => {
	const frequencyValue = useMemo(() => {
		const frequencyBandNoUnits = frequencyBand?.replace('MHz', '')
		if (typeof frequencyBandNoUnits !== 'string') {
			return DEFAULT_FREQUENCY_BAND_KEY
		}

		if (isValidFrequency(frequencyBandNoUnits)) {
			return frequencyBandNoUnits
		}

		return DEFAULT_FREQUENCY_BAND_KEY
	}, [frequencyBand])

	const minFrequencyRange = useMemo(() => {
		return FREQUENCY_LIMITS[frequencyValue].min
	}, [frequencyValue])

	const maxFrequencyRange = useMemo(() => {
		return FREQUENCY_LIMITS[frequencyValue].max
	}, [frequencyValue])

	return {
		minFrequencyRange: convertHzToGHz(minFrequencyRange),
		maxFrequencyRange: convertHzToGHz(maxFrequencyRange),
	}
}

export default UseMinMaxFrequencyRange
