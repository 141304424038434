import {
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getSortedRowModel,
	type FilterFn,
	type ColumnDef,
	type SortingState,
	useReactTable,
} from '@tanstack/react-table'
import { rankItem } from '@tanstack/match-sorter-utils'
import {
	Box,
	Flex,
	Table,
	TableContainer,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react'
import DebouncedInput from '@UI/DebouncedInput/DebouncedInput'
import { BsSortDown, BsSortDownAlt } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'
import { type ReactNode, useState } from 'react'

const UsersDataTable = ({
	columns,
	data,
	actionComponent,
	variant = 'user',
}: {
	// columns: ColumnDef<Client | User, string | number>[]
	columns: ColumnDef<any, string | number>[]
	data: any
	actionComponent: ReactNode
	variant?: 'user' | 'client'
}) => {
	const { t } = useTranslation('pages', {
		keyPrefix: 'userManagement',
	})
	const [sorting, setSorting] = useState<SortingState>([])
	const [globalFilter, setGlobalFilter] = useState('')

	const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
		// Rank the item
		const itemRank = rankItem(row.getValue(columnId), value)
		// Store the ranking info
		addMeta(itemRank)
		// Return if the item should be filtered in/out
		return itemRank.passed
	}

	const table = useReactTable({
		columns,
		data,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		globalFilterFn: fuzzyFilter,
		onGlobalFilterChange: setGlobalFilter,
		onSortingChange: setSorting,
		state: {
			sorting,
			globalFilter,
		},
	})

	const tableHeaderGroup = table.getHeaderGroups()
	const tableRows = table.getRowModel().rows

	return (
		<>
			<Flex direction='column'>
				<Flex justifyContent='space-between' mb={4}>
					<Box>
						<DebouncedInput
							value={globalFilter ?? ''}
							onChange={(value) => setGlobalFilter(String(value))}
							placeholder={t('table.searchAll')}
							data-testid='user-search'
						/>
					</Box>
					{actionComponent}
				</Flex>
				<TableContainer>
					<Table data-testid={`${variant}-data-table`} variant='baseStyle'>
						<Thead>
							{tableHeaderGroup.map((headerGroup) => (
								<Tr key={headerGroup.id}>
									{headerGroup.headers.map((header) => {
										const isSorted = header.column.getIsSorted()
										return (
											<Th
												key={header.id}
												onClick={header.column.getToggleSortingHandler()}
												_last={{
													w: '80px',
												}}
											>
												<Flex alignItems='flex-end'>
													<Box userSelect='none'>
														{flexRender(
															header.column.columnDef.header,
															header.getContext()
														)}
													</Box>
													<Box ml={3}>
														{isSorted ? (
															isSorted === 'desc' ? (
																<BsSortDown />
															) : (
																<BsSortDownAlt />
															)
														) : null}
													</Box>
												</Flex>
											</Th>
										)
									})}
								</Tr>
							))}
						</Thead>
						<Tbody>
							{tableRows.map((row) => {
								const isActive = !row?.original?.deleted_at
								return (
									<Tr key={row.id} bgColor={isActive ? '' : 'body_bg'}>
										{row.getVisibleCells().map((cell) => {
											return (
												<Td key={cell.id}>
													{flexRender(
														cell.column.columnDef.cell,
														cell.getContext()
													)}
												</Td>
											)
										})}
									</Tr>
								)
							})}
						</Tbody>
					</Table>
				</TableContainer>
			</Flex>
		</>
	)
}

export default UsersDataTable
