import { useEffect, useState } from 'react'
import { Flex, SlideFade } from '@chakra-ui/react'

import { useAppSelector } from '@Store/index'
import { selectActiveTargetId } from '@Store/ui/uiSlice'

import SpinnerText from '@UI/SpinnerText/SpinnerText'

import RadarReading from './RadarReading'
import ConfirmRadarReading from './ConfirmRadarReading'
import CameraReading from './CameraReading'

import type { RadarDetectionMap, CameraDetectionMap } from '../../types'
import type { Feature, Point, GeoJsonProperties } from 'geojson'
import { useTranslation } from 'react-i18next'

type TargetAcquisitionProps = {
	radarDetections: RadarDetectionMap
	cameraDetections: CameraDetectionMap
	sensorCoordinates: Feature<Point, GeoJsonProperties>
}

const TargetAcquisition = ({
	radarDetections,
	cameraDetections,
	sensorCoordinates,
}: TargetAcquisitionProps) => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'siteInstallations.cameraCalibration',
	})
	const [substep, setSubstep] = useState(0)

	const targetId = useAppSelector(selectActiveTargetId)

	const detection = targetId ? radarDetections[targetId] : undefined

	useEffect(() => {
		if (targetId) {
			setSubstep(1)
		} else {
			setSubstep(0)
		}
	}, [targetId])

	const getSubstepStatus = (activeStep: number) => {
		if (activeStep === substep) return 'active'
		if (activeStep > substep) return 'inactive'
		if (activeStep < substep) return 'complete'
	}

	return (
		<Flex w='430px' p='16px' bgColor='body_bg' direction='column' gap='16px'>
			<SpinnerText
				text={t('headers.flyDroneFromInstallation')}
				status={getSubstepStatus(0)}
				hint={substep > 0 ? undefined : t('headers.hoverDronePosition')}
			/>
			<SlideFade in={substep === 0} unmountOnExit>
				<RadarReading
					radarDetections={radarDetections}
					sensorCoordinates={sensorCoordinates}
				/>
			</SlideFade>

			<SpinnerText
				text={t('headers.confirmRadarReadings')}
				status={getSubstepStatus(1)}
			/>
			<SlideFade in={substep === 1} unmountOnExit>
				<ConfirmRadarReading
					setSubstep={setSubstep}
					detection={detection}
					sensorCoordinates={sensorCoordinates}
				/>
			</SlideFade>

			<SpinnerText
				text={t('headers.controlCamera')}
				status={getSubstepStatus(2)}
			/>
			<SlideFade in={substep === 2} unmountOnExit>
				<CameraReading cameraDetections={cameraDetections} />
			</SlideFade>
		</Flex>
	)
}

export default TargetAcquisition
