// React
import { useTranslation } from 'react-i18next'
// Chakra
import {
	Table,
	TableContainer,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react'
// Components
import RfFilterActiveToggleSwitch from './RfFilterActiveToggleSwitch'
import RfFiltersTableActions from './RfFiltersTableActions'
// Types
import type { RfSensorFilter } from '@Store/types'

export const RfFiltersTable = ({
	rf_filters,
	variant,
	isWhiteListed = false,
	isDsx = false,
}: {
	rf_filters: RfSensorFilter[]
	variant: 'collapsed' | 'expanded'
	isWhiteListed?: boolean
	isDsx?: boolean
}) => {
	// Translations
	const { t } = useTranslation('panels', { keyPrefix: 'rfFilters.table' })
	const isCollapsed = variant === 'collapsed'
	const formatAngleOfArrival = (value: number | undefined): string => {
		if (String(value) === 'undefined') return ''
		if (String(value).length) return `${Number(value).toFixed(2)}°`
		return ''
	}
	const formatAngleTolerance = (value: number | undefined): string => {
		if (String(value) === 'undefined') return ''
		if (String(value).length) return `± ${Number(value).toFixed(2)}°`
		return ''
	}

	const formatFrequency = (value: number | undefined): string => {
		if (String(value) === 'undefined') return ''
		if (String(value).length) return `${Number(value) / 1e9} ${t('GHZ')}`
		return ''
	}

	const formatFrequencyTolerance = (value: number | undefined): string => {
		if (String(value) === 'undefined') return ''
		if (String(value).length) return `± ${Number(value) / 1e6} ${t('MHZ')}`
		return ''
	}

	const tableHeaders = [
		'', // Active Switch Header
		t('id'),
		t('name'),
		t('vendor'),
		t('protocol'),
		t('frequency'),
		t('frequencyTolerance'),
		t('bearing'),
		t('bearingTolerance'),
	]

	return (
		<>
			{isCollapsed ? (
				<TableContainer>
					<Table variant='filledStyle' maxW='520px' mt={4}>
						<Thead>
							<Tr>
								<Th>{/* ACTIVE */}</Th>
								<Th>{t('id')}</Th>
								<Th>{t('name')}</Th>
								<Th w='80px'>
									<Text align='center'>{t('actions')}</Text>
								</Th>
							</Tr>
						</Thead>
						<Tbody>
							{rf_filters.map((rf_filter) => {
								const { id, name } = rf_filter
								return (
									<Tr key={id}>
										<Td w='60px'>
											<RfFilterActiveToggleSwitch rf_filter={rf_filter} />
										</Td>
										<Td w='60px'>{id}</Td>
										<Td>
											<Text
												maxW='200px'
												whiteSpace='nowrap'
												overflow='hidden'
												textOverflow='ellipsis'
												data-testid={`rf-filter-name-${name}`}
											>
												{name}
											</Text>
										</Td>
										<Td maxW='120px'>
											<RfFiltersTableActions
												showSelectButton
												rfFilter={rf_filter}
												isWhiteListed={isWhiteListed}
												isDsx={isDsx}
											/>
										</Td>
									</Tr>
								)
							})}
						</Tbody>
					</Table>
				</TableContainer>
			) : (
				<TableContainer>
					<Table variant='simple' mt={4}>
						<Thead>
							<Tr>
								{tableHeaders.map((header) => (
									<Th key={header}>
										<Text>{header}</Text>
									</Th>
								))}
								<Th w='80px'>
									<Text align='center'>{t('actions')}</Text>
								</Th>
							</Tr>
						</Thead>
						<Tbody>
							{rf_filters.map((rf_filter) => {
								const {
									id,
									name,
									vendor,
									protocol,
									frequency,
									frequency_tolerance,
									bearing,
									bearing_tolerance,
								} = rf_filter
								return (
									<Tr key={id}>
										<Td w='60px'>
											<RfFilterActiveToggleSwitch rf_filter={rf_filter} />
										</Td>
										<Td w='60px'>{id}</Td>
										<Td>{name}</Td>
										<Td>{vendor}</Td>
										<Td>{protocol}</Td>
										<Td>{formatFrequency(frequency)}</Td>
										<Td>{formatFrequencyTolerance(frequency_tolerance)}</Td>
										<Td>{formatAngleOfArrival(bearing)}</Td>
										<Td>{formatAngleTolerance(bearing_tolerance)}</Td>
										<Td maxW='120px'>
											<RfFiltersTableActions
												rfFilter={rf_filter}
												isWhiteListed={isWhiteListed}
												isDsx={isDsx}
											/>
										</Td>
									</Tr>
								)
							})}
						</Tbody>
					</Table>
				</TableContainer>
			)}
		</>
	)
}

export default RfFiltersTable
