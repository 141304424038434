import { useState, useEffect, memo } from 'react'

import { Box } from '@chakra-ui/react'

import CustomSvgLayer from '@UI/Charts/CustomSvgLayer'
import ScatterPlot from '@UI/Charts/ScatterPlot'

import type { ScatterPlotRawSerie, ScatterPlotDatum } from '@nivo/scatterplot'
import { useTranslation } from 'react-i18next'

const BottomCustomLayer = (targetAltitude: number) =>
	CustomSvgLayer({
		startX: 0,
		endX: 100,
		startY: targetAltitude - 48,
		endY: targetAltitude - 52,
		color: 'var(--c2-colors-charts-customAmber)',
	})

const TopCustomLayer = (targetAltitude: number) =>
	CustomSvgLayer({
		startX: 0,
		endX: 100,
		startY: targetAltitude + 2,
		endY: targetAltitude - 2,
		color: 'var(--c2-colors-charts-customGreen)',
	})

type AltitudeChartProps = {
	heightOffsetToSite: number
	targetHeight: number
}

const AltitudeChart = ({
	heightOffsetToSite,
	targetHeight,
}: AltitudeChartProps) => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'siteInstallations.cameraCalibration',
	})
	const [heightData, setHeightData] = useState<
		ScatterPlotRawSerie<ScatterPlotDatum>[]
	>([{ id: 'height_offset_to_site', data: [] }])

	useEffect(() => {
		if (heightOffsetToSite) {
			setHeightData((prevCoordinates) => {
				const lastCoordinate =
					prevCoordinates[0].data[prevCoordinates[0].data.length - 1]?.x ?? 0

				const newCoordinate = {
					x: (lastCoordinate as number) + 0.2,
					y: heightOffsetToSite,
				}
				return [
					{
						id: 'height_offset_to_site',
						data: [...prevCoordinates[0].data, newCoordinate],
					},
				]
			})
		}
	}, [heightOffsetToSite])

	return (
		<Box h='310px' w='200px'>
			<ScatterPlot
				data={heightData}
				yLabel={t('height')}
				layers={[
					'grid',
					'axes',
					'mesh',
					BottomCustomLayer(targetHeight),
					TopCustomLayer(targetHeight),
					'nodes',
				]}
				xScale={{ type: 'linear', min: 0, max: 100 }}
				xTickValues={[]}
				yScale={{
					type: 'linear',
					min: targetHeight - 55,
					max: targetHeight + 5,
				}}
				margin={{ top: 10, right: 10, bottom: 25, left: 45 }}
			/>
		</Box>
	)
}

export default memo(AltitudeChart)
