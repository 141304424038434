// Packages
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Howl } from 'howler'

// TODO figure out the typescript to include src!
export type useAlertSoundOptions = {
	loop?: boolean
	autoplay?: boolean
	enabled?: boolean
}

const useAlertSound = (
	src: string | undefined,
	{ loop = false, autoplay = false, enabled }: useAlertSoundOptions
) => {
	const [isPlaying, setIsPlaying] = useState(false)

	const handleOnPlay = useCallback(() => setIsPlaying(true), [])
	const handleOnPause = useCallback(() => setIsPlaying(false), [])
	const handleOnStop = useCallback(() => setIsPlaying(false), [])

	const sound = useMemo(() => {
		if (!src) {
			return undefined
		}

		return new Howl({
			src,
			loop,
			autoplay,
			onplay: handleOnPlay,
			onpause: handleOnPause,
			onstop: handleOnStop,
			onend: handleOnStop,
			onplayerror: (soundId, e) => console.error(soundId, e),
			onloaderror: (soundId, e) => console.error(soundId, e),
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [src, loop, autoplay])

	const handlePlay = useCallback(() => {
		if (!sound?.playing()) {
			sound?.play()
		}
	}, [sound])

	const handleStop = useCallback(() => {
		sound?.stop()
	}, [sound])

	useEffect(() => {
		if (enabled === undefined) {
			// Self control disabled
			return
		}

		if (!enabled) {
			handleStop()
		} else if (enabled) {
			handlePlay()
		}

		return () => {
			handleStop()
		}
	}, [handlePlay, handleStop, enabled])

	return { howl: sound, play: handlePlay, stop: handleStop, isPlaying }
}

export default useAlertSound
