// Packages
import { useCallback, useMemo } from 'react'
import { Checkbox, CheckboxGroup, Flex, Text } from '@chakra-ui/react'

export type DetectionDisplayProps = {
	value?: { [key: string]: boolean }
	options: { name: string; value: string }[]
	loading?: boolean
	onChange: (value: { [key: string]: boolean }) => void
}

const DetectionDisplay = ({
	options = [],
	loading = false,
	value = {},
	onChange,
}: DetectionDisplayProps) => {
	const valueParsed = useMemo<string[]>(() => {
		if (typeof value !== 'object') {
			return []
		}

		return Object.keys(value)
			.filter((valueKey) => !!value[valueKey])
			.map((valueKey) => valueKey)
	}, [value])

	const handleChange = useCallback(
		(val: string[]) => {
			onChange(
				options.reduce(
					(acc, option) => ({
						...acc,
						[option.value]: val.includes(option.value),
					}),
					{}
				)
			)
		},
		[onChange, options]
	)

	return (
		<CheckboxGroup
			value={valueParsed}
			onChange={handleChange}
			isDisabled={loading}
		>
			<Flex direction='column' gap='16px' paddingTop='8px'>
				{options.map(({ name, value: optionValue }, index) => (
					<Checkbox
						key={index}
						id={`${name}-${index}`}
						value={optionValue}
						data-testid={`${optionValue}-device-display-sector`}
					>
						<label htmlFor={`${name}-${index}`}>
							<Text fontSize='sm'>{name}</Text>
						</label>
					</Checkbox>
				))}
			</Flex>
		</CheckboxGroup>
	)
}

export default DetectionDisplay
