import { Box, Center, Checkbox, Flex, Text } from '@chakra-ui/react'
import { chakraComponents } from 'chakra-react-select'

const ZoneTypesCustomOption = (props: any) => {
	return (
		<chakraComponents.Option {...props}>
			<Flex justify='space-between' flexDirection='row' w='100%'>
				<Flex align='left' flexDirection='column'>
					<Flex>
						{props.isSelected ? (
							<Checkbox marginInlineEnd={2} defaultChecked></Checkbox>
						) : (
							<Checkbox
								marginInlineEnd={2}
								checked={props.isSelected}
							></Checkbox>
						)}
						<Text isTruncated>{props.data.label}</Text>
					</Flex>

					<Text paddingInlineStart={6} fontSize='xs' color='label_color'>
						{props.data.zoneInfo}
					</Text>
				</Flex>
				<Center>
					<Box
						marginInlineStart={3}
						w={3}
						h={3}
						backgroundColor={props.data.zoneBoxColor}
					></Box>
				</Center>
			</Flex>
		</chakraComponents.Option>
	)
}

export default ZoneTypesCustomOption
