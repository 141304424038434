import { Box } from '@chakra-ui/react'
import { RxTriangleRight } from 'react-icons/rx'

type ThumbProps = {
	value: number
	thumbIndex: number
	thumbProps: any
	bgColor: string
	testId?: string
	onKeyDownStepBy: (
		e: React.KeyboardEvent<HTMLDivElement>,
		thumbIndex: number
	) => void
	yOffset: number
}

const Thumb = ({
	bgColor,
	thumbIndex,
	thumbProps,
	onKeyDownStepBy,
	testId,
	yOffset = 0,
}: ThumbProps) => {
	return (
		<Box
			left={0}
			w={3}
			h='6px'
			bgColor={bgColor}
			data-testid={testId}
			_focusVisible={{
				outline: 'none',
			}}
			onKeyDown={(e) => {
				onKeyDownStepBy(e, thumbIndex)
			}}
			{...thumbProps}
		>
			<Box
				position='absolute'
				right='-48px'
				color='primary'
				transform={`translateY(${10 + yOffset}px)`}
				bottom={thumbProps?.style?.bottom}
			>
				{<RxTriangleRight size={25} />}
			</Box>
		</Box>
	)
}
export default Thumb
