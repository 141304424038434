import { useTheme } from '@chakra-ui/react'

import Sector from '@Components/MapShapes/Sector/Sector'

type GenericSensorSectorProps = {
	latitude: number
	longitude: number
	bearing: number
	angle: number
	reach: number
	isActive: boolean
}

const GenericSensorSector = ({
	latitude,
	longitude,
	bearing,
	angle,
	reach = 0,
	isActive = false,
}: GenericSensorSectorProps) => {
	const {
		semanticTokens: { colors },
	} = useTheme()
	const fillColor = colors.sectors.rfSensorFill
	const strokeColor = isActive
		? colors.sectors.highlight
		: colors.sectors.rfSensorStroke
	const strokeWeight = isActive ? 4 : 2

	return (
		<Sector
			latitude={latitude}
			longitude={longitude}
			angle={angle}
			bearing={bearing}
			reach={reach}
			color={strokeColor}
			fillColor={fillColor}
			weight={strokeWeight}
			testId='generic-sensor-sector'
		/>
	)
}

export default GenericSensorSector
