// Packages
import { useTranslation } from 'react-i18next'
import { Box, Flex, Text } from '@chakra-ui/react'

// Alias
import Headings from '@UI/Headings/Headings'

// Relatives
import ReportingToolTable from './RecordingToolTable'

type RecordingToolTableLoaderProps = {
	siteId: number
	startTime: string
	endTime: string
	siteTimeZone: string
}

const RecordingToolTableLoader = ({
	siteId,
	startTime,
	endTime,
	siteTimeZone,
}: RecordingToolTableLoaderProps) => {
	const { t } = useTranslation('pages', {
		keyPrefix: 'analytics.recordingTool',
	})

	// const { totalCount } = useRecordingToolListQuery(
	// 	{
	// 		siteId,
	// 		format: 'table',
	// 		startTime: startTime,
	// 		endTime: endTime,
	// 		page: 0,
	// 		perPage: 10,
	// 	},
	// 	{
	// 		selectFromResult: ({ data }) => ({
	// 			totalCount: data?.meta.total,
	// 		}),
	// 	}
	// )

	return (
		<Box width='100%'>
			<Flex justifyContent='space-between' alignItems='center'>
				<Headings.ChartHeading title={t('recordingToolDetails')} />
				<Flex alignItems='center'>
					<Headings.FieldHeading title={t('title')} />
				</Flex>
			</Flex>
			<Flex alignItems='center' gap='8px' my={4}>
				<Headings.FieldHeading title={t('total')} />
				<Text fontSize='xl' as='b'>
					{/* {totalCount} */}
				</Text>
			</Flex>
			<ReportingToolTable
				siteId={siteId}
				startTime={startTime}
				endTime={endTime}
				siteTimeZone={siteTimeZone}
				testId='reporting-tool-table'
			/>
		</Box>
	)
}

export default RecordingToolTableLoader
