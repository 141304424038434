// Relatives
import DatePickerMonths from './DatePickerMonths'
import DatePickerYears from './DatePickerYears'
import DatePickerDays from './DatePickerDays'
import DatePickerTime from './DatePickerTime'
import DatePickerCalendarHeader from './DatePickerCalendarHeader'

export type DatePickerContentBodyProps = {
	id: string
	open?: boolean
	mode?: 'datetime' | 'date'
	hours?: number
	minutes?: number
	onClickTime?: (time: string) => void
	onClickToday?: () => void
}

const DatePickerContentBody = ({
	id,
	open,
	mode,
	hours,
	minutes,
	onClickTime,
	onClickToday,
}: DatePickerContentBodyProps) => {
	return (
		<div data-scope='date-picker' data-part='content-body'>
			<div data-scope='date-picker' data-part='content-body-calendar'>
				<DatePickerCalendarHeader onClickToday={onClickToday} />
				<DatePickerDays />
				<DatePickerMonths />
				<DatePickerYears />
			</div>
			{mode === 'datetime' && (
				<div data-scope='date-picker' data-part='content-body-time'>
					<DatePickerTime
						id={id}
						open={open}
						hours={hours}
						minutes={minutes}
						onClick={onClickTime}
					/>
				</div>
			)}
		</div>
	)
}

export default DatePickerContentBody
