// Base Redux Toolkit Query API
import { baseApi } from '../baseApi'
import { createSelector } from '@reduxjs/toolkit'

// Types
import type {
	SystemInfo,
	SystemStats,
	Timezone,
	CoturnCredentials,
	SystemSettings,
} from '../types/system'

// Constants
import { coturnUrl, stunUrl } from '@Constants/api'

// General System API endpoints
export const systemApi = baseApi
	.enhanceEndpoints({ addTagTypes: ['System'] })
	.injectEndpoints({
		endpoints: (builder) => ({
			getSystemInfo: builder.query<SystemInfo, void>({
				query: () => ({
					url: '/api/system_stats/info',
				}),
				providesTags: ['System'],
			}),
			getSystemStats: builder.query<SystemStats, void>({
				query: () => ({
					url: '/api/system_stats',
				}),
			}),
			getTimezones: builder.query<Timezone[], undefined>({
				// https://au-release.droneshield.xyz/api/docs#get-/api/timezones
				query: () => ({
					url: '/api/timezones',
				}),
			}),
			getIceServers: builder.query<RTCIceServer[], undefined>({
				query: () => ({
					url: '/api/coturn_auth',
				}),
				transformResponse: (response: {
					username: CoturnCredentials['username']
					password: CoturnCredentials['password']
				}) => [
					{
						urls: coturnUrl as string,
						username: response.username as string,
						credential: response.password as string,
					},
					{ urls: stunUrl as string },
				],
			}),
			getSystemSettings: builder.query<SystemSettings, void>({
				query: () => ({
					url: '/api/system_settings',
				}),
				providesTags: ['System'],
				transformResponse: (response: { system_setting: SystemSettings }) =>
					response.system_setting,
			}),
			updateSystemSettings: builder.mutation<SystemSettings, SystemSettings>({
				query: ({ ...systemSettings }) => ({
					url: '/api/system_settings',
					method: 'PUT',
					body: {
						system_settings: {
							...systemSettings,
						},
					},
				}),
				invalidatesTags: ['System'],
			}),
		}),
	})

export const selectIsDefeatEnabled = createSelector(
	(data?: SystemInfo) => data,
	(info) => info?.defeat_enabled
)

export const selectIsOnPrem = createSelector(
	(data?: SystemInfo) => data,
	(info) => info?.environment === 'prem'
)

export const selectNotificationsEnabled = createSelector(
	(data?: SystemInfo) => data,
	(info) => info?.notifications_enabled
)

export const selectIsOnPremOrStaging = createSelector(
	(data?: SystemInfo) => data,
	(info) => info?.environment && ['prem', 'staging'].includes(info.environment)
)

export const selectIsSimulationEnabled = createSelector(
	(data?: SystemInfo) => data,
	(info) => info?.simulation_enabled
)

export const selectIsTactical = createSelector(
	(data?: SystemInfo) => data,
	(info) => info?.tactical
)

export const selectEnvironment = createSelector(
	(data?: SystemInfo) => data,
	(info) => info?.environment
)

export const selectLogo = createSelector(
	(data?: SystemInfo) => data,
	(info) => info?.logo
)

export const selectVersions = createSelector(
	(data?: SystemInfo) => data,
	(info) => info?.version
)

export const selectTimezoneOptions = createSelector(
	(data?: Timezone[]) => data,
	(data) => ({
		timezoneOptions: (data ?? []).map((timezone) => ({
			value: timezone,
			label: timezone,
		})),
	})
)

export const selectIsLogoSoteria = createSelector(
	(data?: SystemInfo) => data,
	(info) => info?.logo === 'soteria'
)

export const {
	useGetSystemInfoQuery,
	useGetSystemStatsQuery,
	useGetTimezonesQuery,
	useGetIceServersQuery,
	useGetSystemSettingsQuery,
	useUpdateSystemSettingsMutation,
} = systemApi
