// React
import { type ReactElement, useMemo } from 'react'
import { RouterProvider, createBrowserRouter, Outlet } from 'react-router-dom'

// Page Layouts
import { Dashboard } from '../Layouts'

// Page Components
import Preload from './Preload'

// Pages
import Analytics from '@Pages/Analytics/Analytics'
import UserManagement from '@Pages/UserManagement/UserManagement'
import AlertSettings from '@Pages/AlertSettings/AlertSettings'
import Login from '@Pages/Login/Login'
import ResetPassword from '@Pages/ResetPassword/ResetPassword'
import Track from '@Pages/Analytics/TrackSummary/Track'

// Components
import Site from '@Components/Site/Site'
import SiteInstallations from '@Components/Site/Panels/SiteInstallations/SiteInstallations'
import FormsRouter from '@Components/Forms/FormsRouter'
import MapsAndZones from '@Components/Site/Panels/MapsAndZones/MapsAndZones'
import RadarMasks from '@Components/Site/Panels/SiteInstallations/RadarMasks/RadarMasks'
import RfFilters from '@Components/Site/Panels/SiteInstallations/RfFilters/RfFilters'
import CameraCalibration from '@Components/Site/Panels/SiteInstallations/CameraCalibration/CameraCalibration'
import AddSite from '@Components/Site/AddSite'
import Notes from '@Components/Site/Panels/Notes/Notes'

// Hooks
import useAuth from '@Hooks/useAuth'
import usePermissionRoute from '@Hooks/usePermissionRoute'
import RecordingToolProvider from '../RecordingTool/RecordingToolProvider'

// Packages
import { CSSReset, ChakraProvider } from '@chakra-ui/react'

// Chakra
import Theme from '@Theme/DRO'

export type Route = {
	element: ReactElement
	path?: string
	include?: boolean
	children?: Route[]
}

const Root = () => {
	return (
		<ChakraProvider theme={Theme}>
			<CSSReset />
			<Outlet />
		</ChakraProvider>
	)
}

const Router = () => {
	const { isAuthenticated, isGuest, isManager, isSiteManager, isOperator } =
		useAuth()

	const routes: Route[] = useMemo(() => {
		return [
			{
				element: <Dashboard />,
				children: [
					{
						path: '/',
						include: true,
						element: <Preload />,
					},
					{
						path: '/site/add',
						element: (
							<AddSite>
								<FormsRouter form='site' />
							</AddSite>
						),
						include: isManager,
					},
					{
						path: '/:siteId',
						element: (
							<RecordingToolProvider>
								<Site />
							</RecordingToolProvider>
						),
						include: isGuest,
						children: [
							{
								path: 'settings',
								element: <FormsRouter form='site' />,
								include: isGuest,
							},
							{
								path: 'settings/threat',
								element: <FormsRouter form='threat' />,
								include: isOperator,
								children: [
									{
										path: ':zoneId',
										element: <FormsRouter form='threat' />,
										include: isOperator,
									},
								],
							},
							{
								path: 'installations',
								element: <SiteInstallations />,
								include: isGuest,
								children: [
									{
										path: ':installationId',
										element: <SiteInstallations />,
										include: isGuest,
									},
									{
										path: ':installationId/:sensorType',
										element: <SiteInstallations />,
										include: isGuest,
									},
									{
										path: ':installationId/:sensorType/:sensorId',
										element: <SiteInstallations />,
										include: isGuest,
									},
								],
							},
							...[
								'installation/:installationId/rf_sensors/:sensorId/rf_filters',
								'installation/:installationId/rf_sensors_dsx/:sensorId/rf_filters',
							].map((path) => ({
								path,
								element: <RfFilters />,
								include: isGuest,
							})),
							...[
								'installation/:installationId/rf_sensors/:sensorId/rf_filters/add',
								'installation/:installationId/rf_sensors/:sensorId/rf_filters/:filterId/settings',
								'installation/:installationId/rf_sensors_dsx/:sensorId/rf_filters/add',
								'installation/:installationId/rf_sensors_dsx/:sensorId/rf_filters/:filterId/settings',
							].map((path) => ({
								path,
								element: <FormsRouter form='rfFilter' />,
								include: isGuest,
							})),
							{
								path: 'installation/:installationId/settings',
								element: <FormsRouter form='installation' />,
								include: isGuest,
							},
							...[
								'installation/add',
								'installation/:installationId/settings',
							].map((path) => ({
								path,
								element: <FormsRouter form='installation' />,
								include: isGuest,
							})),
							...[
								'installation/:installationId/rf_sensors/add',
								'installation/:installationId/rf_sensors/:sensorId/settings',
							].map((path) => ({
								path,
								element: <FormsRouter form='rfSensor' />,
								include: isOperator,
							})),
							...[
								'installation/:installationId/disruptor/add',
								'installation/:installationId/disruptor/:sensorId/settings',
							].map((path) => ({
								path,
								element: <FormsRouter form='disruptor' />,
								include: isSiteManager,
							})),
							...[
								'installation/:installationId/camera/add',
								'installation/:installationId/camera/:sensorId/settings',
							].map((path) => ({
								path,
								element: <FormsRouter form='camera' />,
								include: isGuest,
							})),
							{
								path: 'installation/:installationId/camera/:sensorId/calibrate',
								element: <CameraCalibration />,
								include: isGuest,
							},
							...[
								'installation/:installationId/radar/add',
								'installation/:installationId/radar/:sensorId/settings',
							].map((path) => ({
								path,
								element: <FormsRouter form='radar' />,
								include: isGuest,
							})),
							...[
								'installation/:installationId/rf_sensors_dsx/add',
								'installation/:installationId/rf_sensors_dsx/:sensorId/settings',
							].map((path) => ({
								path,
								element: <FormsRouter form='dsx' />,
								include: isGuest,
							})),
							...[
								'installation/:installationId/gpscompass/add',
								'installation/:installationId/gpscompass/:sensorId/settings',
							].map((path) => ({
								path,
								element: <FormsRouter form='gps' />,
								include: isGuest,
							})),
							...[
								'installation/:installationId/generic_sensor/add',
								'installation/:installationId/generic_sensor/:sensorId/settings',
							].map((path) => ({
								path,
								element: <FormsRouter form='genericSensor' />,
								include: isSiteManager,
							})),
							...[
								'installation/:installationId/radar/:sensorId/masks',
								'installation/:installationId/radar/:sensorId/masks/:maskId',
							].map((path) => ({
								path,
								element: <RadarMasks />,
								include: isGuest,
							})),
							...[
								'installation/:installationId/radar/:sensorId/mask/add',
								'installation/:installationId/radar/:sensorId/mask/:maskId/settings',
							].map((path) => ({
								path,
								element: <FormsRouter form='radarMask' />,
								include: isGuest,
							})),
							{
								path: 'zones',
								element: <MapsAndZones />,
								include: isGuest,
								children: [
									{
										path: ':zoneId',
										element: <MapsAndZones />,
										include: isOperator,
									},
									{
										path: 'markers/:markerId',
										element: <MapsAndZones />,
										include: isOperator,
									},
								],
							},
							...['notes', 'notes/add'].map((path) => ({
								path,
								element: <Notes />,
								include: isGuest,
							})),
							...['zone/add', 'zone/:zoneId/settings'].map((path) => ({
								path,
								element: <FormsRouter form='zone' />,
								include: isSiteManager,
							})),
							{
								path: 'map-layers',
								element: <FormsRouter form='mapLayers' />,
								include: isSiteManager,
							},
							...['markers/add', 'markers/:markerId/settings'].map((path) => ({
								path,
								element: <FormsRouter form='markers' />,
								include: isSiteManager,
							})),
							...['cot/add', 'cot/:cotId/settings'].map((path) => ({
								path,
								element: <FormsRouter form='cot' />,
								include: isManager,
							})),
						],
					},
					{
						path: '/:siteId/analytics',
						element: <Analytics />,
						include: isGuest,
						children: [
							{
								path: 'track/:eventId',
								element: <Track />,
								include: isGuest,
							},
						],
					},
					{
						path: '/:siteId/alert-settings',
						element: <AlertSettings />,
						include: isSiteManager,
					},
					{
						path: '/:siteId/user-management',
						element: <UserManagement />,
						include: isManager,
					},
					{
						path: '*',
						include: true,
						element: <Preload />,
					},
				],
			},
		]
	}, [isManager, isSiteManager, isOperator, isGuest])

	const nonAuthRoutes = [
		{
			path: '/reset_password/:token',
			element: <ResetPassword />,
		},
		{ path: '*', element: <Login /> },
	]

	const authRoutes = usePermissionRoute(routes)

	const appRoutes = [
		{
			element: <Root />,
			children: isAuthenticated ? authRoutes : nonAuthRoutes,
		},
	]

	return <RouterProvider router={createBrowserRouter(appRoutes)} />
}

// Export
export default Router
