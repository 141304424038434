// React
import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

// Form
import { useForm, FormProvider } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import type { TypeOf } from 'zod'
import Field from '@Components/FormElements'
import Accordion from '@Components/FormElements/Accordion/Accordion'
// Components
import Cots from '@Forms/CotForm/Cots'
// Schema
import siteFormSchema, {
	ADD_SITE_DEFAULT_VALUES,
	type SiteDefaultValues,
} from './SiteForm.schema'
import type { Site } from '@/store/types'

// State
import {
	useGetSiteQuery,
	useUpdateSiteMutation,
	useGetSiteInstallationsQuery,
	selectSiteInstallationLocatorOptions,
	useCreateSiteMutation,
	useDeleteSiteMutation,
} from '@Store/sites/sitesApi'
import {
	useGetTimezonesQuery,
	selectTimezoneOptions,
} from '@Store/system/systemApi'
import {
	useGetClientsListQuery,
	selectClientsOptions,
} from '@Store/clients/clientsApi'
import { nanoid } from '@reduxjs/toolkit'
import {
	FormButtons,
	FormDeleteButton,
} from '@Components/FormElements/FormButtons/FormButtons'
import useAuth from '@Hooks/useAuth'
import FormWrapper from '@Components/FormElements/FormWrapper/FormWrapper'
import { useAppDispatch } from '@/store'
import { skipToken } from '@reduxjs/toolkit/query'
import {
	selectSiteLocationData,
	setSiteLocationData,
	setSiteZoom,
} from '@Store/ui/uiSlice'
import {
	selectFirstValidMapLayer,
	useCreateSiteMapLayerAssociationMutation,
	useGetMapLayersQuery,
} from '@Store/mapLayers/mapLayersApi'

import { useExpandFormAccordion } from '@Hooks/useExpandFormAccordion'
import SiteFormMapCenter from '@Forms/SiteForm/SiteFormMapCenter'
import { Box, Flex, Icon, Text } from '@chakra-ui/react'
import type { FormSelectValueType } from '@/components/FormElements/Select/Select'
import { MdError } from 'react-icons/md'

// Site Form Loader
const SiteForm = ({ siteId }: { siteId?: number }) => {
	const { t } = useTranslation('forms', { keyPrefix: 'siteForm' })
	const isEditForm = !!siteId
	const {
		isLoading,
		isError,
		isSuccess,
		refetch,
		data: siteData,
	} = useGetSiteQuery(siteId ? siteId : skipToken, {
		refetchOnMountOrArgChange: true,
	})

	const addSiteDefaultValues = {
		...ADD_SITE_DEFAULT_VALUES,
	}

	const defaultValues = isEditForm ? siteData : addSiteDefaultValues

	return (
		<FormWrapper
			entity={t('entity')}
			isEditForm={isEditForm}
			isLoading={isLoading}
			isError={isError}
			isSuccess={isSuccess}
			refetch={refetch}
		>
			{defaultValues && (
				<Form
					key={nanoid()}
					isEditForm={isEditForm}
					defaultValues={defaultValues}
					siteId={siteId}
				/>
			)}
		</FormWrapper>
	)
}

// Site Form
const Form = ({
	defaultValues,
	isEditForm,
	siteId,
}: {
	defaultValues: Site | SiteDefaultValues
	isEditForm: boolean
	siteId?: number
}) => {
	const location = useLocation()
	const [activeTab] = useState(
		location.state ? location.state.activeTab : undefined
	)
	const { state } = useLocation()
	const navigate = useNavigate()

	type Schema = TypeOf<typeof siteFormSchema>

	const ref = useRef(null)

	// React Hook Form
	const methods = useForm<Schema>({
		resolver: zodResolver(siteFormSchema),
		defaultValues,
	})
	const {
		register,
		formState: { errors, isSubmitting, dirtyFields },
		handleSubmit,
		watch,
		setValue,
		setError,
	} = methods
	const isDirty = Object.keys(dirtyFields).length > 0

	// Translations
	const { t } = useTranslation('forms', { keyPrefix: 'siteForm' })
	const { isAdmin, isManager } = useAuth()

	// RTK Query site form dependency data

	// TODO: find a better way, maybe as a `useGetSiteFormQuery`
	// that returns both defaultValues and dependentValues

	const { timezoneOptions, tzOptionsReady } = useGetTimezonesQuery(undefined, {
		selectFromResult: ({ isSuccess, data }) => ({
			...selectTimezoneOptions(data),
			tzOptionsReady: isSuccess,
		}),
	})
	const { locatorOptions, locatorOptionsReady, installations } =
		useGetSiteInstallationsQuery(siteId ? siteId : skipToken, {
			selectFromResult: ({ isSuccess, data }) => ({
				...selectSiteInstallationLocatorOptions(data),
				locatorOptionsReady: isSuccess,
				installations: data,
			}),
		})
	const { clientsOptions, clientsOptionsReady } = useGetClientsListQuery(
		undefined,
		{
			selectFromResult: ({ isSuccess, data }) => ({
				...selectClientsOptions(data),
				clientsOptionsReady: isSuccess,
			}),
			skip: !isAdmin,
		}
	)
	// Default layer
	const { isLayerSuccess, layer } = useGetMapLayersQuery(undefined, {
		selectFromResult: ({ isSuccess, isError, data }) => ({
			isLayerSuccess: isSuccess,
			isError,
			layer: selectFirstValidMapLayer(data),
		}),
		skip: isEditForm,
	})

	const { expandedIndex, setExpandedIndex, errorIndexes } =
		useExpandFormAccordion(ref, errors)

	// RTK Query mutation(s)
	const [updateSite] = useUpdateSiteMutation()
	const [createSite] = useCreateSiteMutation()
	const [deleteSite] = useDeleteSiteMutation()
	const [createMapLayerAssociation] = useCreateSiteMapLayerAssociationMutation()

	const handleSave = async (payload: Schema) => {
		try {
			if (isEditForm && siteId) {
				await updateSite({ ...payload, id: siteId }).unwrap()
				navigate(`/${siteId}/installations`)
			} else {
				await createSite({ ...payload })
					.unwrap()
					.then((site) => {
						if (isLayerSuccess) {
							if (layer && Object.keys(layer).length > 0) {
								try {
									createMapLayerAssociation({
										siteId: site?.id,
										mapLayerId: layer?.id,
									}).unwrap()
								} catch (error) {
									console.error('Error update default map layer: ', error)
								}
							}
						}
						navigate(`/${site?.id}/installation/add`)
					})
			}
		} catch (errors: unknown) {
			// Surface server-side validation errors to react-hook-form
			for (const field in errors as { [name in keyof Schema]: string }) {
				setError(field as keyof Schema, {
					type: 'custom',
					message: (errors as { [name in keyof Schema]: string })[
						field as keyof Schema
					] as string,
				})
			}
		}
	}

	const handleCancel = () => navigate('/' + siteId + '/installations')
	const handleDelete = async () => {
		try {
			if (siteId) await deleteSite(siteId).unwrap()
			navigate('/')
		} catch (e) {
			console.error('Error deleting site', e)
		}
	}

	// Track live values
	const {
		accept_location_updates: acceptLocationUpdates,
		auto_jam: autoDisrupt,
		name,
		latitude,
		longitude,
		zoom_level,
		locator_id,
		height,
	} = watch()

	// Handle Drag/Drop site location
	const dispatch = useAppDispatch()
	const siteLocationData = useSelector(selectSiteLocationData)

	useEffect(() => {
		if (acceptLocationUpdates) {
			const selectedInstallation = installations?.find(
				(installation) => installation.id === locator_id
			)
			if (selectedInstallation) {
				const { latitude, longitude } = selectedInstallation
				dispatch(setSiteLocationData({ latitude, longitude }))
			}
		}
		dispatch(
			setSiteLocationData({ accept_location_updates: acceptLocationUpdates })
		)
	}, [acceptLocationUpdates, installations, locator_id, dispatch])

	useEffect(() => {
		dispatch(
			setSiteLocationData({
				latitude: defaultValues?.latitude,
				longitude: defaultValues?.longitude,
			})
		)
	}, [dispatch, defaultValues])

	const handleCoordinatesChange = useCallback(
		(type: 'latitude' | 'longitude') => (value: number) => {
			setValue(type, value, { shouldDirty: true })
			dispatch(
				setSiteLocationData({
					[type]: Number(value),
				})
			)
		},
		[dispatch, setValue]
	)

	// Set Zoom level from map preferences slider
	useEffect(() => {
		dispatch(setSiteZoom(zoom_level))
	}, [zoom_level, dispatch])

	useEffect(() => {
		if (clientsOptionsReady && state?.clientId) {
			const option = clientsOptions?.find(
				(option) => option.value === state?.clientId
			)
			if (option !== undefined) setValue('client_id', option.value)
		}
	}, [clientsOptionsReady, clientsOptions, state?.clientId, setValue])

	const adminsClientOptions = [
		...clientsOptions,
		{ value: 0, label: t('clientIsEmpty') },
	]

	useEffect(() => {
		if (
			siteLocationData &&
			siteLocationData?.latitude !== latitude &&
			siteLocationData?.longitude !== longitude
		) {
			setValue('latitude', siteLocationData?.latitude, { shouldDirty: true })
			setValue('longitude', siteLocationData?.longitude, { shouldDirty: true })
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [siteLocationData, setValue, defaultValues])

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(handleSave)} ref={ref}>
				<Field.TextInput
					title={t('name')}
					register={register('name')}
					error={errors?.name?.message}
					testId='site-name'
				/>
				<Accordion.Container
					setExpandedIndex={setExpandedIndex}
					defaultIndex={activeTab}
					index={expandedIndex}
				>
					<Accordion.Item
						title={t('locationPrefsDivider')}
						testId='site-location-accordion'
						isError={errorIndexes.has(0)}
						isWarning={height === 0}
					>
						<Flex gap={2}>
							<Field.LatLongInput
								title={t('siteLatitude')}
								type='latitude'
								error={errors?.latitude?.message}
								register={register('latitude')}
								disabled={acceptLocationUpdates}
								testId='site-latitude'
								value={latitude}
								onChange={handleCoordinatesChange('latitude')}
							/>
							<Field.LatLongInput
								title={t('siteLongitude')}
								type='longitude'
								error={errors?.longitude?.message}
								register={register('longitude')}
								disabled={acceptLocationUpdates}
								testId='site-longitude'
								value={longitude}
								onChange={handleCoordinatesChange('longitude')}
							/>
						</Flex>
						<Field.Switch
							title={t('locationUpdates')}
							tooltip={t('tooltips.locationUpdates')}
							register={register('accept_location_updates')}
							error={errors?.accept_location_updates?.message}
							disabled={Object.keys(locatorOptions).length === 0}
							testId='accept-location-updates'
						/>
						{acceptLocationUpdates && locatorOptionsReady && (
							<Field.Select
								title={t('installationLocation')}
								options={locatorOptions}
								register={register('locator_id', { valueAsNumber: true })}
								disabled={!acceptLocationUpdates}
								testId='locator'
							/>
						)}
						<Field.UnitsSlider
							isAbsolute={false}
							units='m'
							title={t('height')}
							tooltip={t('tooltips.height')}
							min={-460}
							max={9000}
							step={0.1}
							register={register('height', {
								valueAsNumber: true,
							})}
							error={errors?.height?.message}
							testId='site-height'
						/>
						{height === 0 && (
							<Flex alignItems='center' marginBlockStart={4}>
								<Box>
									<Flex bgColor='input_bg' p={2} direction='column'>
										<Flex gap={1}>
											<Icon as={MdError} h={4} w={4} color='primary' />
											<Text fontSize='sm'>{t('tooltips.siteHeightZero')}</Text>
										</Flex>
									</Flex>
								</Box>
							</Flex>
						)}
					</Accordion.Item>

					<Accordion.Item
						title={t('dateTimePrefs')}
						testId='date-time-accordion'
						isError={errorIndexes.has(1)}
					>
						{tzOptionsReady && (
							<Field.Select
								title={t('timezone')}
								options={timezoneOptions}
								register={register('timezone')}
								error={errors?.timezone?.message}
								isSearchable
							/>
						)}
						{/*TODO: Add default site switch*/}
					</Accordion.Item>
					<Accordion.Item
						title={t('mapPrefsDivider')}
						testId='map-preferences-accordion'
						isError={errorIndexes.has(2)}
					>
						<SiteFormMapCenter defaultValues={defaultValues} />
						<Field.Slider
							title={t('zoomLevel')}
							min={1} // TODO: get these values from Zod?
							max={18}
							register={register('zoom_level', { valueAsNumber: true })}
							error={errors?.zoom_level?.message}
							testId='zoom-level'
						/>
					</Accordion.Item>
					<Accordion.Item
						title={t('disruptionParamsDivider')}
						testId='disruption-parameters-accordion'
						isError={errorIndexes.has(3)}
					>
						<Field.Switch
							title={t('autoDisruptionSwitch')}
							tooltip={t('tooltips.autoDisruptionSwitch')}
							register={register('auto_jam')}
							error={errors?.auto_jam?.message}
							testId='auto-jam-switch'
						/>

						<Field.Select
							title={t('auto_disruption_probability')}
							tooltip={t('tooltips.auto_disruption_probability')}
							options={
								t('probability_options', {
									returnObjects: true,
								}) as FormSelectValueType[]
							}
							register={register('auto_disruption_probability', {
								valueAsNumber: true,
							})}
							error={errors?.auto_disruption_probability?.message}
							disabled={!autoDisrupt}
						/>

						<Field.Select
							title={t('auto_disruption_threat_level')}
							tooltip={t('tooltips.auto_disruption_threat_level')}
							options={
								t('threat_level_options', {
									returnObjects: true,
								}) as FormSelectValueType[]
							}
							register={register('auto_disruption_threat_level', {
								valueAsNumber: true,
							})}
							error={errors?.auto_disruption_threat_level?.message}
							disabled={!autoDisrupt}
						/>

						<Field.NumberInput
							title={t('auto_disruption_detection_count')}
							tooltip={t('tooltips.auto_disruption_detection_count')}
							min={0}
							max={2_147_483_647}
							register={register('auto_disruption_detection_count', {
								valueAsNumber: true,
							})}
							error={errors?.auto_disruption_detection_count?.message}
							disabled={!autoDisrupt}
							testId='auto-disruption-detection-count'
						/>

						<Field.Slider
							title={t('auto_jam_interval')}
							tooltip={t('tooltips.auto_jam_interval')}
							min={1}
							max={300}
							register={register('auto_jam_interval', { valueAsNumber: true })}
							error={errors?.auto_jam_interval?.message}
							disabled={!autoDisrupt}
							testId='auto-jam-interval'
						/>
					</Accordion.Item>
					<Accordion.Item
						title={t('displayPrefsDivider')}
						testId='display-settings-accordion'
						isError={errorIndexes.has(4)}
					>
						<Field.Select
							title={t('detectionProbability')}
							tooltip={t('tooltips.detectionProbability')}
							options={
								t('probability_options', {
									returnObjects: true,
								}) as FormSelectValueType[]
							}
							register={register('minimum_display_probability')}
							error={errors?.minimum_display_probability?.message}
						/>

						<Field.Select
							title={t('displayThreatLevel')}
							tooltip={t('tooltips.displayThreatLevel')}
							options={
								t('threat_level_options', {
									returnObjects: true,
								}) as FormSelectValueType[]
							}
							register={register('minimum_display_threat_level')}
							error={errors?.minimum_display_threat_level?.message}
						/>

						<Field.Slider
							title={t('drone_locator_detection_timeout')}
							defaultValue={defaultValues.drone_locator_detection_timeout}
							tooltip={t('tooltips.drone_locator_detection_timeout')}
							min={1}
							max={150}
							register={register('drone_locator_detection_timeout', {
								valueAsNumber: true,
							})}
							error={errors?.drone_locator_detection_timeout?.message}
							testId='drone-locator-detection-timeout'
						/>

						<Field.Checkbox
							title={t('showRFSector')}
							tooltip={t('tooltips.showRFSector')}
							register={register('always_show_rf_beam')}
							testId='show-rf-sector'
						/>

						<Field.Checkbox
							title={t('showSystemWarnings')}
							tooltip={t('tooltips.showSystemWarnings')}
							register={register('system_warning_enabled')}
							testId='show-system-warnings'
						/>

						<Field.Slider
							title={t('speedleader_estimation_time')}
							tooltip={t('tooltips.speedleader_estimation_time')}
							min={0} // TODO: get these values from Zod?
							max={30}
							register={register('speedleader_estimation_time', {
								valueAsNumber: true,
							})}
							error={errors?.speedleader_estimation_time?.message}
							testId='speedleader-estimation-time'
						/>
					</Accordion.Item>
					{isAdmin && (
						<>
							<Accordion.Item
								title={t('sensorFusionParameters')}
								testId='sensor-fusion-accordion'
								isError={errorIndexes.has(5)}
							>
								<Field.Select
									title={t('sensitivity')}
									tooltip={<Trans>{t('tooltips.sensitivity')}</Trans>}
									options={
										t('sensitivityOptions', {
											returnObjects: true,
										}) as FormSelectValueType[]
									}
									register={register('sensitivity')}
									error={errors?.sensitivity?.message}
								/>

								<Field.Slider
									title={t('max_location_variance')}
									tooltip={t('tooltips.max_location_variance')}
									min={1} // TODO: get these values from Zod?
									max={20000}
									register={register('max_location_variance', {
										valueAsNumber: true,
									})}
									error={errors?.max_location_variance?.message}
									testId='max-location-variance'
								/>

								<Field.Select
									title={t('round_frequency_band')}
									tooltip={t('tooltips.round_frequency_band')}
									options={
										t('round_frequency_bandOptions', {
											returnObjects: true,
										}) as FormSelectValueType[]
									}
									register={register('round_frequency_band')}
									error={errors?.sensitivity?.message}
								/>

								<Field.Slider
									title={t('prob_uav_filter')}
									tooltip={t('tooltips.prob_uav_filter')}
									min={0}
									max={100}
									step={1}
									defaultValue={defaultValues.prob_uav_filter}
									register={register('prob_uav_filter', {
										valueAsNumber: true,
									})}
									error={errors?.prob_uav_filter?.message}
									testId='prob-uav-filter'
								/>

								<Field.UnitsSlider
									units='m'
									title={t('prob_uav_filter_range')}
									tooltip={t('tooltips.prob_uav_filter_range')}
									min={20}
									max={10000}
									step={10}
									defaultValue={defaultValues.prob_uav_filter_range}
									register={register('prob_uav_filter_range', {
										valueAsNumber: true,
									})}
									error={errors?.prob_uav_filter_range?.message}
									testId='prob-uav-filter-range'
								/>
								<Field.Switch
									title={t('fuse_controller_detections')}
									tooltip={t('tooltips.fuse_controller_detections')}
									register={register('fuse_controller_detections')}
									error={errors?.fuse_controller_detections?.message}
									testId='fuse-controller-detections'
								/>
							</Accordion.Item>
							{clientsOptionsReady && (
								<Accordion.Item
									title={t('clientManagementPrefs')}
									testId='client-management-accordion'
									isError={errorIndexes.has(isAdmin ? 6 : 5)}
								>
									<Field.Select
										title={t('client')}
										options={isAdmin ? adminsClientOptions : clientsOptions}
										register={register('client_id')}
										error={errors?.client_id?.message}
										placeholder={t('clientPlaceholder')}
									/>
								</Accordion.Item>
							)}
						</>
					)}
					{isEditForm && (
						<Accordion.Item
							title={t('outputPrefsDivider')}
							testId='output-options-accordion'
							isLazy
						>
							<Cots />
						</Accordion.Item>
					)}
				</Accordion.Container>
				{isEditForm && isManager && (
					<FormDeleteButton
						handleDelete={handleDelete}
						name={`Site ${name}`}
						headerText={t('deleteSite')}
						fromText='from instance'
						label={t('deleteSite')}
						testId='delete-site'
					/>
				)}
				{/* BUTTONS */}
				<FormButtons
					isSubmitting={isSubmitting}
					isDirty={isDirty}
					handleCancel={handleCancel}
				/>
			</form>
		</FormProvider>
	)
}

export default SiteForm
