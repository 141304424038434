import type React from 'react'
import type { IconBaseProps } from 'react-icons'

const AutoDisruptIcon: React.FC<IconBaseProps & { active: boolean }> = ({
	active,
	...rest
}) => {
	return active ? (
		<svg
			viewBox='0 0 24 24'
			fill='currentColor'
			xmlns='http://www.w3.org/2000/svg'
			{...rest}
		>
			<path d='M5 2v12h3v9l7-12h-4l4-9zm15.8 0h-2l-3.2 9h1.9l.7-2h3.2l.7 2H24zm-2.15 5.65L19.8 4l1.15 3.65z' />
		</svg>
	) : (
		<svg
			viewBox='0 0 24 24'
			fill='currentColor'
			xmlns='http://www.w3.org/2000/svg'
			{...rest}
		>
			<path d='M15 11H11.39L13.67 13.28L15 11ZM15 2H5V4.61L11.13 10.74L15 2ZM1.41 3.86L0 5.27L5 10.27V14H8V23L11.58 16.85L15.73 21L17.14 19.59L1.41 3.86Z' />
			<path d='M20.8 2H18.8L15.6 11H17.5L18.2 9H21.4L22.1 11H24L20.8 2ZM18.6503 7.65L19.8003 4L20.9503 7.65H18.6503Z' />
		</svg>
	)
}

export default AutoDisruptIcon
