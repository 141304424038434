import { useTranslation } from 'react-i18next'

import { Box, Flex, Text, useDisclosure } from '@chakra-ui/react'

import EditButton from '../EditButton'
import Modal from '@UI/Modals/Modal'
import ZoneMasksTable from './ZoneMasksTable'

import {
	useGetSiteLiveQuery,
	selectIsSiteCalibrationMode,
	selectSiteRadarById,
} from '@Store/sites/sitesWsApi'
import { useGetRadarMasksQuery } from '@Store/radarMasks/radarMasksApi'
import type { Site, Radar, SiteInstallation } from '@Store/types'

export const MAX_RADAR_MASKS = 32

type ZoneMasksProps = {
	siteId: Site['id']
	installationId: SiteInstallation['id']
	sensorId: Radar['id']
}

const ZoneMasks = ({ siteId, installationId, sensorId }: ZoneMasksProps) => {
	const { isOpen, onClose, onOpen } = useDisclosure()
	const { t } = useTranslation('panels', { keyPrefix: 'radarMasks' })

	const {
		isLoading,
		isError,
		isSuccess,
		data: radarMasks,
	} = useGetRadarMasksQuery({
		siteId,
		sensorId,
	})

	const { isCalibrationMode, radarData } = useGetSiteLiveQuery(siteId, {
		selectFromResult: ({ data }) => ({
			isCalibrationMode: selectIsSiteCalibrationMode(data),
			radarData: selectSiteRadarById(data, sensorId),
		}),
	})

	const numberOfRadarMasks = radarMasks ? radarMasks.length : 0
	const hasMaxRadarMasks = numberOfRadarMasks >= MAX_RADAR_MASKS

	const getTooltip = () => {
		if (!isCalibrationMode) {
			return t('calibration')
		} else if (hasMaxRadarMasks) {
			return t('maxMasks')
		}
		return undefined
	}

	const enableHardMask = radarData?.radar_type !== 'echo_shield'

	if (isLoading) return <Box>Loading</Box>
	else if (isError) return <Box>Error loading Radar Mask Zones</Box>
	else if (isSuccess)
		return (
			<>
				<Flex justifyContent='space-between'>
					<EditButton
						onClick={onOpen}
						type='open'
						label={t('tableView')}
						disabled={!radarMasks}
						testId='table-view'
					/>
					<EditButton
						to={`/${siteId}/installation/${installationId}/radar/${sensorId}/mask/add`}
						type='add'
						label={t('add')}
						disabled={!isCalibrationMode || hasMaxRadarMasks}
						tooltip={getTooltip()}
						testId='add-zone-mask'
					/>
				</Flex>
				<Flex justifyContent='flex-end'>
					<Text color='headings' fontSize='xs' data-testid='zone-mask-count'>
						{`${numberOfRadarMasks}/${t('masksCreated')}`}
					</Text>
				</Flex>
				{radarMasks && (
					<>
						<ZoneMasksTable
							enableHardMask={enableHardMask}
							radarMasks={radarMasks}
						/>
						<Modal
							isOpen={isOpen}
							onClose={onClose}
							headerText={t('zoneMasks')}
							variant='table'
						>
							<ZoneMasksTable
								enableHardMask={enableHardMask}
								radarMasks={radarMasks}
								isExpanded
							/>
						</Modal>
					</>
				)}
			</>
		)
}

export default ZoneMasks
