// Packages
import { Box, Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

// Alias
import { formatBeaconString, formatLatLng } from '@/utils/formatUtils'

export type DetailsBeaconProps = {
	gcsLatitude?: number
	gcsLongitude?: number
	homeLatitude?: number
	homeLongitude?: number
	droneSerialNumber?: string
}

const DetailsBeacon = ({
	gcsLatitude = 0,
	gcsLongitude = 0,
	homeLatitude = 0,
	homeLongitude = 0,
	droneSerialNumber,
}: DetailsBeaconProps) => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'notifications.detections.detectionDetail.headings',
	})

	return (
		<Flex w='100%' flexWrap='wrap'>
			{gcsLatitude !== 0 && (
				<Box w='50%' marginBlockEnd={2}>
					<Text fontSize='xs' color='headings'>
						{t('gcsLatitude')}
					</Text>
					<Text fontSize='sm'>{formatLatLng(gcsLatitude) ?? '-'}</Text>
				</Box>
			)}
			{gcsLongitude !== 0 && (
				<Box w='50%' marginBlockEnd={2}>
					<Text fontSize='xs' color='headings'>
						{t('gcsLongitude')}
					</Text>
					<Text fontSize='sm'>{formatLatLng(gcsLongitude) ?? '-'}</Text>
				</Box>
			)}
			{homeLatitude !== 0 && (
				<Box w='50%' marginBlockEnd={2}>
					<Text fontSize='xs' color='headings'>
						{t('homeLatitude')}
					</Text>
					<Text fontSize='sm'>{formatLatLng(homeLatitude) ?? '-'}</Text>
				</Box>
			)}
			{homeLongitude !== 0 && (
				<Box w='50%' marginBlockEnd={2}>
					<Text fontSize='xs' color='headings'>
						{t('homeLongitude')}
					</Text>
					<Text fontSize='sm'>{formatLatLng(homeLongitude) ?? '-'}</Text>
				</Box>
			)}
			{droneSerialNumber && (
				<Box w='100%' marginBlockEnd={2}>
					<Text fontSize='xs' color='headings'>
						{t('droneSerialNumber')}
					</Text>
					<Text fontSize='sm'>
						{formatBeaconString(droneSerialNumber) ?? '-'}
					</Text>
				</Box>
			)}
		</Flex>
	)
}

export default DetailsBeacon
