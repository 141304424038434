import { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
	useReactTable,
	getCoreRowModel,
	type SortingState,
	type PaginationState,
	type ColumnFiltersState,
} from '@tanstack/react-table'
import { Flex } from '@chakra-ui/react'
import { useGetDisruptionsSummaryQuery } from '@Store/analytics/analyticsApi'
import {
	useGetSiteExportQuery,
	selectSiteExportAllDisruptors,
} from '@Store/sites/sitesApi'
import { useGetUsersListQuery } from '@Store/user/usersApi'
import FetchError from '@UI/FetchError/FetchError'
import Headings from '@UI/Headings/Headings'
import Button from '@UI/Button/Button'
import { MdDownload } from 'react-icons/md'
import TanStackTable from '@UI/TanStackTable/Table'
import Pagination from '@UI/TanStackTable/Pagination'
import Skeleton from '@UI/Skeleton/Skeleton'

import { formatDateStrTz, getMillisecondsBetween } from '@Utils/dates'
import { getSorting } from '@Utils/tables'
import { type DisruptionRow, SORTABLE_COLUMNS } from './columnDefinitions'
import { downloadCsvV2 } from '@Utils/download'
import useDisruptionColumns from '@Pages/Analytics/DisruptionsSummary/DisruptionDetailsTable/useDisruptionColumns'
import DisruptionFilter from './DisruptionFilter'
import { formatMillisecondsAsDuration } from '@/utils/formatUtils'
import type { DisruptionsFilters } from '@/store/analytics/types'

type DisruptionDetailsTableProps = {
	siteId: number
	startTime: string
	endTime: string
}

const emptyResult = [] as DisruptionRow[]

const DisruptionDetailsTable = ({
	startTime,
	endTime,
	siteId,
}: DisruptionDetailsTableProps) => {
	const { t } = useTranslation('pages', {
		keyPrefix: 'analytics.disruptionsSummary',
	})

	const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 10,
	})
	const [sorting, setSorting] = useState<SortingState>([
		{ id: 'id', desc: true },
	])
	const [globalFilter, setGlobalFilter] = useState<string>('')
	const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
	const pagination = useMemo(
		() => ({ pageIndex, pageSize }),
		[pageIndex, pageSize]
	)

	const columnFiltersApi: DisruptionsFilters = useMemo(() => {
		const filtersApi = columnFilters.reduce(
			(acum, { id, value }) => {
				id = `filter_by_${id}`
				if (acum[id]) {
					return { ...acum, [id]: `$${acum[id]},value` }
				}

				return { ...acum, [id]: value as string }
			},
			{} as { [key: string]: string }
		)

		return filtersApi
	}, [columnFilters])

	const {
		isSuccess,
		isError,
		isLoading,
		isFetching,
		refetch,
		disruptions,
		totalCount,
	} = useGetDisruptionsSummaryQuery(
		{
			siteId,
			format: 'table',
			startTime: startTime,
			endTime: endTime,
			page: pageIndex,
			perPage: pageSize,
			...{ ...columnFiltersApi, search: globalFilter },
			...getSorting(sorting),
		},
		{
			selectFromResult: ({
				isSuccess,
				isError,
				isLoading,
				isFetching,
				data,
			}) => ({
				isSuccess,
				isError,
				isLoading,
				isFetching,
				disruptions: data?.records ?? [],
				totalCount: data?.meta.total,
			}),
		}
	)

	const { disruptors } = useGetSiteExportQuery(siteId, {
		selectFromResult: ({ data, isSuccess }) => ({
			disruptors: selectSiteExportAllDisruptors(data),
			isSuccess,
		}),
	})

	const { data: users } = useGetUsersListQuery()

	const getUser = (userId: number, isManual: boolean) => {
		if (!isManual) {
			return t('automatic')
		}
		const user = users?.find((user) => user.id === userId)

		return user ? user.name : t('manual')
	}

	const tableData: DisruptionRow[] = (disruptions ?? []).map((disruption) => {
		const disruptor = disruptors?.find((disruptor) => {
			if ('Cannon' in disruptor) {
				return disruptor.Cannon?.id === disruption.cannon_id
			}
			return disruptor.id === disruption.cannon_id
		})

		const {
			target_id,
			activating_user_id,
			activated_manually,
			activated_at,
			deactivating_user_id,
			deactivated_manually,
			deactivated_at,
		} = disruption

		return {
			target_id,
			activated_at: formatDateStrTz(activated_at),
			deactivated_at: formatDateStrTz(deactivated_at),
			disruptor: disruptor?.name ?? '',
			serial_number: disruptor?.serial_number ?? '',
			activated_manually: activated_manually,
			activated_by: getUser(activating_user_id, activated_manually),
			deactivated_by: getUser(deactivating_user_id, deactivated_manually),
			duration:
				formatMillisecondsAsDuration(
					getMillisecondsBetween(activated_at, deactivated_at)
				) ?? '-',
		}
	})

	const pageCount = totalCount ? Math.ceil(totalCount / pageSize) : 0
	const columns = useDisruptionColumns()

	const table = useReactTable({
		columns,
		data: tableData ?? emptyResult,
		getCoreRowModel: getCoreRowModel(),
		pageCount: pageCount ?? -1,
		state: {
			columnFilters,
			pagination,
			sorting,
		},
		onColumnFiltersChange: setColumnFilters,
		onGlobalFilterChange: setGlobalFilter,
		onPaginationChange: setPagination,
		onSortingChange: setSorting,
		manualPagination: true,
		manualSorting: true,
		enableSortingRemoval: false,
	})

	const handleDownloadReport = async () => {
		try {
			const url = `/api/reports/${siteId}/cannon_disruptions/csv`
			await downloadCsvV2(url, {
				start_time: startTime,
				end_time: endTime,
			})
		} catch (error) {
			console.error('TODO: unhandled download disruptions report error', error)
		}
	}

	return (
		<>
			<Flex justifyContent='space-between' alignItems='center'>
				<Headings.ChartHeading title={t('disruptionDetails')} />
				<Flex alignItems='center'>
					<Headings.FieldHeading title={t('report')} />
					<Button
						label={t('download')}
						testId='download'
						size='sm'
						leftIcon={<MdDownload />}
						onClick={handleDownloadReport}
					/>
				</Flex>
			</Flex>
			<DisruptionFilter table={table} siteId={siteId} />
			<Skeleton isLoaded={!isLoading && !isFetching}>
				{isSuccess && (
					<>
						<TanStackTable table={table} sortableColumns={SORTABLE_COLUMNS} />
						<Pagination table={table} totalCount={totalCount ?? 0} />
					</>
				)}

				{isError && (
					<FetchError refetch={refetch} entity={t('entities.table')} />
				)}
			</Skeleton>
		</>
	)
}

export default DisruptionDetailsTable
