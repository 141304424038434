import { useParams } from 'react-router-dom'
import { nanoid } from '@reduxjs/toolkit'

// Forms
import SiteForm from '@Forms/SiteForm/SiteForm'
import InstallationForm from '@Forms/InstallationForm/InstallationForm'
import RfSensorForm from '@Forms/RfSensorForm/RfSensorForm'
import CameraForm from '@Forms/CameraForm/CameraForm'
import DisruptorForm from './DisruptorForm/DisruptorForm'
import DsxForm from './DsxForm/DsxForm'
import RadarForm from './RadarForm/RadarForm'
import RadarMaskForm from './RadarMaskForm/RadarMaskForm'
import ZoneForm from './MapsZonesForms/ZoneForm/ZoneForm'
import MapLayersEdit from '@Components/Site/Panels/MapsAndZones/MapLayers/MapLayersEdit'
import FormHeader from '@Forms/FormHeader'
import RfFilterForm from '@Forms/RfFilterForm/RfFilterForm'
import GpsCompassForm from '@Forms/GpsCompassForm/GpsCompassForm'
import MapMarkersForm from '@Forms/MapsZonesForms/MapMarkersForm/MapMarkersForm'
import CotForm from '@Forms/CotForm/CotForm'
import ThreatAnalysisFormLoader from '@Forms/ThreatAnalysisForm/ThreatAnalysisFormLoader'
import GenericSensorForm from './GenericSensorForm/GenericSensorForm'
import ErrorBoundary from '@Components/App/ErrorHandling/ErrorBoundary'

// Index for map layers tab in maps and zones
const MAP_LAYERS_TAB = 0

type FormsRouterProps = {
	form:
		| 'site'
		| 'installation'
		| 'rfSensor'
		| 'camera'
		| 'disruptor'
		| 'radar'
		| 'dsx'
		| 'gps'
		| 'radarMask'
		| 'rfFilter'
		| 'zone'
		| 'mapLayers'
		| 'markers'
		| 'cot'
		| 'threat'
		| 'genericSensor'
}

// Forms Router
const FormsRouter = ({ form }: FormsRouterProps) => {
	const {
		siteId: siteIdParam,
		installationId: installationIdParam,
		sensorId: sensorIdParam,
		filterId: filterIdParam,
		maskId: maskIdParam,
		zoneId: zoneIdParam,
		cotId: cotIdParam,
	} = useParams()

	// TODO make useSiteParam() hook?
	// TODO if param is not a number, return undefined instead of NaN
	const siteId = siteIdParam ? Number(siteIdParam) : undefined
	const installationId = installationIdParam
		? Number(installationIdParam)
		: undefined
	const sensorId = sensorIdParam ? Number(sensorIdParam) : undefined
	const filterId = filterIdParam ? Number(filterIdParam) : undefined
	const maskId = maskIdParam ? Number(maskIdParam) : undefined
	const zoneId = zoneIdParam ? Number(zoneIdParam) : undefined
	const cotId = cotIdParam ? Number(cotIdParam) : undefined

	return (
		<ErrorBoundary>
			{form === 'site' && (
				<>
					<FormHeader
						title='Site Settings'
						backRoute={`/${siteId}/installations`}
					/>
					<ErrorBoundary>
						<SiteForm siteId={siteId} />
					</ErrorBoundary>
				</>
			)}
			{form === 'installation' && siteId && (
				<>
					<FormHeader
						title='Installation Settings'
						backRoute={`/${siteId}/installations${installationId ? `/${installationId}` : ''}`}
					/>
					<ErrorBoundary>
						<InstallationForm
							key={nanoid()}
							siteId={siteId}
							installationId={installationId}
						/>
					</ErrorBoundary>
				</>
			)}
			{form === 'rfSensor' && siteId && installationId && (
				<RfSensorForm
					key={nanoid()}
					siteId={siteId}
					installationId={installationId}
					sensorId={sensorId}
				/>
			)}
			{form === 'dsx' && siteId && installationId && (
				<>
					<DsxForm
						key={nanoid()}
						siteId={siteId}
						installationId={installationId}
						sensorId={sensorId}
					/>
				</>
			)}
			{form === 'disruptor' && siteId && installationId && (
				<>
					<DisruptorForm
						key={nanoid()}
						siteId={siteId}
						installationId={installationId}
						sensorId={sensorId}
					/>
				</>
			)}
			{form === 'zone' && siteId && (
				<ZoneForm key={nanoid()} siteId={siteId} zoneId={zoneId} />
			)}
			{form === 'mapLayers' && (
				<>
					<FormHeader
						title='Maps and Zones'
						backRoute={`/${siteId}/zones`}
						backRouteState={MAP_LAYERS_TAB}
					/>
					<ErrorBoundary>
						<MapLayersEdit key={nanoid()} />
					</ErrorBoundary>
				</>
			)}
			{form === 'markers' && siteId && (
				<MapMarkersForm key={nanoid()} siteId={siteId} />
			)}
			{form === 'camera' && siteId && installationId && (
				<CameraForm
					key={nanoid()}
					siteId={siteId}
					installationId={installationId}
					cameraId={sensorId}
				/>
			)}
			{form === 'radar' && siteId && installationId && (
				<RadarForm
					key={nanoid()}
					siteId={siteId}
					installationId={installationId}
					sensorId={sensorId}
				/>
			)}
			{form === 'radarMask' && siteId && installationId && sensorId && (
				<RadarMaskForm
					key={nanoid()}
					siteId={siteId}
					installationId={installationId}
					sensorId={sensorId}
					maskId={maskId}
				/>
			)}
			{form === 'rfFilter' && siteId && sensorId && (
				<RfFilterForm
					key={nanoid()}
					siteId={siteId}
					sensorId={sensorId}
					filterId={filterId}
				/>
			)}
			{form === 'gps' && siteId && installationId && (
				<GpsCompassForm
					key={nanoid()}
					siteId={siteId}
					installationId={installationId}
					sensorId={sensorId}
				/>
			)}
			{form === 'cot' && siteId && (
				<CotForm key={nanoid()} siteId={siteId} cotId={cotId} />
			)}
			{form === 'threat' && siteId && (
				<ThreatAnalysisFormLoader siteId={siteId} />
			)}
			{form === 'genericSensor' && siteId && installationId && (
				<GenericSensorForm
					key={nanoid()}
					siteId={siteId}
					installationId={installationId}
					sensorId={sensorId}
				/>
			)}
		</ErrorBoundary>
	)
}

export default FormsRouter
