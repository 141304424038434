// React
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
// Form
import { useNavigate, useLocation } from 'react-router-dom'
import { useForm, FormProvider } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import type { TypeOf } from 'zod'
// Chakra
import { Box, Collapse, Flex, Heading } from '@chakra-ui/react'
// Components
import Headings from '@UI/Headings/Headings'
import { FormButtons } from '@Components/FormElements/FormButtons/FormButtons'
import Field from '@Components/FormElements'
import type { FormSelectValueType } from '@/components/FormElements/Select/Select'
import Accordion from '@Components/FormElements/Accordion/Accordion'
import FormHeader from '../FormHeader'
import FormWrapper from '@Components/FormElements/FormWrapper/FormWrapper'
import FormStatusErrors from '@Components/FormElements/FormStatusErrors/FormStatusErrors'
import StatusIcon from '@/components/_UI/StatusIcon/StatusIcon'

// Store
import {
	useGetRadarQuery,
	useCreateRadarMutation,
	useUpdateRadarMutation,
} from '@Store/radars/radarsApi'
import type { SensorStatusColor } from '@Store/types'
import { sensorStatusColorMap, type Radar } from '@Store/types'
import { radarFormSchema, ADD_RADAR_DEFAULT_VALUES } from './RadarForm.schema'
import {
	useGetSiteInstallationQuery,
	useGetSiteQuery,
} from '@Store/sites/sitesApi'
import { useAppDispatch } from '@Store/index'
import { updateSensorPreview } from '@Store/ui/uiSlice'
import {
	useGetSiteLiveQuery,
	selectSiteRadarById,
	selectSiteInstallationStatusColor,
} from '@Store/sites/sitesWsApi'
import { skipToken } from '@reduxjs/toolkit/query'

import useAuth from '@Hooks/useAuth'
import { useExpandFormAccordion } from '@Hooks/useExpandFormAccordion'
import ErrorBoundary from '@/components/App/ErrorHandling/ErrorBoundary'
import { setFormError } from '../formHelper'
import { ECHOSHIELD_RADAR_FREQUENCY_CHANNEL } from '@Constants/radars'

type RadarFormProps = {
	siteId: number
	installationId: number
	sensorId?: number
}

const RadarForm = ({ siteId, installationId, sensorId }: RadarFormProps) => {
	const { t } = useTranslation('forms', { keyPrefix: 'radarForm' })
	const isEditForm = !!sensorId

	const {
		isLoading,
		isError,
		isSuccess,
		refetch,
		data: radar,
	} = useGetRadarQuery(siteId && sensorId ? { siteId, sensorId } : skipToken, {
		refetchOnMountOrArgChange: true,
	})

	// Looks for props passed through from navigate() via Unregistered Sensors
	const { state } = useLocation()
	const addRadarDefaultValues = {
		...ADD_RADAR_DEFAULT_VALUES,
		...state,
		sentry_id: installationId,
	} as Radar

	const defaultValues = isEditForm ? radar : addRadarDefaultValues
	return (
		<FormWrapper
			entity={t('entity')}
			isEditForm={isEditForm}
			isLoading={isLoading}
			isError={isError}
			isSuccess={isSuccess}
			refetch={refetch}
		>
			{defaultValues && (
				<Form
					key={sensorId}
					defaultValues={defaultValues}
					isEditForm={isEditForm}
					siteId={siteId}
					installationId={installationId}
					sensorId={sensorId}
				/>
			)}
		</FormWrapper>
	)
}

const Form = ({
	defaultValues,
	isEditForm,
	siteId,
	installationId,
	sensorId,
}: {
	defaultValues: Radar
	isEditForm: boolean
	siteId: number
	installationId: number
	sensorId?: number
}) => {
	const ref = useRef(null)

	const navigate = useNavigate()

	const { t } = useTranslation('forms', { keyPrefix: 'radarForm' })

	type Schema = TypeOf<typeof radarFormSchema>

	const methods = useForm<Schema>({
		resolver: zodResolver(radarFormSchema),
		defaultValues,
	})
	const {
		register,
		formState: { errors, isSubmitting, isDirty },
		handleSubmit,
		setValue,
		setError,
		watch,
	} = methods

	const { expandedIndex, setExpandedIndex, errorIndexes } =
		useExpandFormAccordion(ref, errors)

	// Track live IMU Roll/Tilt
	const { radarData, imuReady } = useGetSiteLiveQuery(
		siteId && sensorId ? siteId : skipToken,
		{
			selectFromResult: ({ data, isSuccess }) => ({
				imuReady: isSuccess,
				radarData: selectSiteRadarById(data, Number(sensorId)),
			}),
		}
	)

	const { statusColor } = useGetSiteLiveQuery(siteId, {
		selectFromResult: ({ data }) => ({
			statusColor: selectSiteInstallationStatusColor(data, installationId),
		}),
	})

	const { siteHeight } = useGetSiteQuery(siteId, {
		skip: !siteId,
		selectFromResult: ({ data, isSuccess }) => ({
			siteHeight: isSuccess ? data?.height : 0,
		}),
	})

	// Handle absolute offset values
	const { installationName, sentryDirection, sentryHeightOffset } =
		useGetSiteInstallationQuery(
			{ siteId, installationId },
			{
				selectFromResult: ({ data, isSuccess }) => {
					return {
						installationName: isSuccess ? data?.name : 'installation',
						sentryDirection: isSuccess ? data?.direction : 0,
						sentryHeightOffset: isSuccess ? data?.height_offset_to_site : 0,
					}
				},
			}
		)

	const {
		radar_type,
		channel,
		direction_offset: directionOffset,
		height_offset_to_installation: heightOffsetToInstallation,
		sentry_id: sentryId,
		reach_max,
		reach_min,
		radar_az_min_search,
		radar_az_max_search,
		radar_az_min_track,
		radar_az_max_track,
	} = watch()

	const dispatch = useAppDispatch()
	useEffect(() => {
		dispatch(
			updateSensorPreview({
				sentryId,
				directionOffset,
				reach_max,
				reach_min,
				radar_az_min_search,
				radar_az_max_search,
				radar_az_min_track,
				radar_az_max_track,
			})
		)
		return () => {
			dispatch(updateSensorPreview(null))
		}
	}, [
		sentryId,
		directionOffset,
		reach_max,
		reach_min,
		radar_az_min_search,
		radar_az_max_search,
		radar_az_min_track,
		radar_az_max_track,
		dispatch,
	])

	const [lastRadarType, setLastRadarType] = useState<string | undefined>()

	useEffect(() => {
		if (!isEditForm) {
			if (!lastRadarType) {
				setLastRadarType(radar_type)
			}
			if (lastRadarType !== radar_type) {
				setLastRadarType(radar_type)
				if (radar_type === 'echo_shield') {
					setValue('channel', 12)
					if (reach_min < 100) {
						setValue('reach_min', 100)
					}
					setValue('reach_max', 5000)
				} else if (radar_type === 'radar_zero') {
					if (channel > 2) {
						setValue('channel', 0)
					}
					if (reach_max > 3000) {
						setValue('reach_max', 3000)
					}
				}
			}
		}
	}, [
		radar_type,
		reach_min,
		reach_max,
		channel,
		lastRadarType,
		setLastRadarType,
		setValue,
		isEditForm,
	])

	const absoluteDirection = Number(
		(directionOffset + sentryDirection) % 360
	).toFixed(2)
	const absoluteHeight = (
		heightOffsetToInstallation +
		sentryHeightOffset +
		siteHeight
	).toFixed(2)

	const [createRadar] = useCreateRadarMutation()
	const [updateRadar] = useUpdateRadarMutation()

	const radarsRoute = `/${siteId}/installations/${installationId}/radars`

	const handleSave = async (data: Schema) => {
		try {
			if (isEditForm && sensorId) {
				await updateRadar({
					id: sensorId,
					siteId: siteId,
					...data,
				}).unwrap()
			} else {
				await createRadar({ siteId: siteId, ...data }).unwrap()
			}
			navigate(radarsRoute)
		} catch (error) {
			setFormError<Schema>({ error, setError })
		}
	}

	const [azimuthStep, elevationStep] = watch(['radar_az_step', 'radar_el_step'])

	const handleCancel = () => navigate(radarsRoute)

	const { isAdmin } = useAuth()
	// This is kind of superfluous given that we hide the Type from non-admin, but anyway
	const radarTypeOptions = (
		t('radarTypeOptions', {
			returnObjects: true,
		}) as Array<{ label: string; value: string }>
	).filter(
		(option) => option.value !== 'rada' || (option.value === 'rada' && isAdmin)
	)

	return (
		<>
			<FormHeader
				title={
					isEditForm ? t('headings.radarSettings') : t('headings.addRadar')
				}
				backRoute={radarsRoute}
			/>
			<FormStatusErrors errors={errors} />
			<ErrorBoundary>
				<FormProvider {...methods}>
					<form onSubmit={handleSubmit(handleSave)} ref={ref}>
						<Flex marginBlockStart={3}>
							<StatusIcon
								color={sensorStatusColorMap[statusColor as SensorStatusColor]}
								testId={`${installationId}-installation-status-color-${statusColor}`}
							/>
							<Heading
								color='white'
								alignContent='center'
								marginInlineStart={2}
								size='xs'
								data-testid={`installation-${installationId}`}
							>
								{installationName}
							</Heading>
						</Flex>
						<Field.Divider title={t('headings.generalParameters')} />
						<Field.TextInput
							title={t('api.name')}
							register={register('name')}
							error={errors?.name?.message}
							testId='name'
						/>
						<Field.Select
							title={t('api.radarType')}
							options={radarTypeOptions}
							register={register('radar_type')}
							disabled={isEditForm}
							tooltip={isEditForm ? t('tooltips.radar_type_disabled') : ''}
							error={errors?.radar_type?.message}
						/>
						<Field.TextInput
							title={t('api.serialNumber')}
							register={register('serial_number')}
							disabled={isEditForm}
							tooltip={isEditForm ? t('tooltips.serial_number_disabled') : ''}
							error={errors?.serial_number?.message}
							testId='serial-number'
						/>

						<Accordion.Container
							setExpandedIndex={setExpandedIndex}
							index={expandedIndex}
						>
							<Accordion.Item
								title={t('headings.displaySettings')}
								testId='display-settings'
								isError={errorIndexes.has(0)}
							>
								<Field.RangeSlider
									title={t('api.reach')}
									tooltip={t('tooltips.reach')}
									step={1}
									min={radar_type === 'echo_shield' ? 100 : 20}
									max={radar_type === 'echo_shield' ? 10000 : 3000}
									defaultValue={[
										defaultValues.reach_min,
										defaultValues.reach_max,
									]}
									onChangeEnd={(value: [number, number]) => {
										setValue('reach_min', value[0], {
											shouldDirty: true,
											shouldValidate: true,
											shouldTouch: true,
										})
										setValue('reach_max', value[1], {
											shouldDirty: true,
											shouldValidate: true,
											shouldTouch: true,
										})
									}}
									error={[
										errors?.reach_min?.message,
										errors?.reach_max?.message,
									]
										.filter((error) => !!error)
										.join(', ')}
									testId='reach-range'
								/>
							</Accordion.Item>
							<Accordion.Item
								title={t('headings.calibrationParameters')}
								testId='calibration-parameters'
								isError={errorIndexes.has(1)}
							>
								<Box position='relative'>
									<Field.UnitsSlider
										units='deg'
										customLabel={
											imuReady && radarData?.imu_tilt
												? `${t('headings.emuTilt')} ${radarData?.imu_tilt.toFixed(2)}`
												: ''
										}
										title={t('api.tilt')}
										tooltip={t('tooltips.tilt')}
										min={-45}
										max={45}
										register={register('tilt', { valueAsNumber: true })}
										error={errors?.tilt?.message}
										testId='tilt'
									/>
								</Box>
								<Box position='relative'>
									<Field.UnitsSlider
										units='deg'
										customLabel={
											imuReady && radarData?.imu_roll
												? `${t('headings.emuRoll')} ${radarData?.imu_roll.toFixed(2)}`
												: ''
										}
										title={t('api.roll')}
										tooltip={t('tooltips.roll')}
										min={-45}
										max={45}
										register={register('roll', { valueAsNumber: true })}
										error={errors?.roll?.message}
										testId='roll'
									/>
								</Box>
								<Field.UnitsSlider
									isAbsolute
									units='deg'
									customLabel={absoluteDirection}
									title={t('api.directionOffset')}
									tooltip={t('tooltips.directionOffset')}
									min={0}
									max={359.99}
									step={0.1}
									register={register('direction_offset', {
										valueAsNumber: true,
									})}
									error={errors?.direction_offset?.message}
									testId='direction-offset'
								/>
								{isAdmin && (
									<>
										<Box position='relative'>
											<Field.UnitsSlider
												units='m'
												title={t('api.north_offset_to_installation')}
												tooltip={t('tooltips.north_offset_to_installation')}
												min={-10}
												max={10}
												step={0.1}
												register={register('north_offset_to_installation', {
													valueAsNumber: true,
												})}
												error={errors?.north_offset_to_installation?.message}
												testId='north_offset_to_installation'
											/>
										</Box>
										<Box position='relative'>
											<Field.UnitsSlider
												units='m'
												title={t('api.east_offset_to_installation')}
												tooltip={t('tooltips.east_offset_to_installation')}
												min={-10}
												max={10}
												step={0.1}
												register={register('east_offset_to_installation', {
													valueAsNumber: true,
												})}
												error={errors?.east_offset_to_installation?.message}
												testId='east_offset_to_installation'
											/>
										</Box>
									</>
								)}
								<Field.UnitsSlider
									isAbsolute
									units='m'
									customLabel={absoluteHeight}
									title={t('api.height_offset_to_installation')}
									tooltip={t('tooltips.height_offset_to_installation')}
									min={-100}
									max={200}
									step={0.1}
									register={register('height_offset_to_installation', {
										valueAsNumber: true,
									})}
									error={errors?.height_offset_to_installation?.message}
									testId='height-offset-to-installation'
								/>
							</Accordion.Item>
							<Collapse in={radar_type !== 'rada'}>
								<Accordion.Item
									title={t('headings.advancedParameters')}
									testId='advanced-parameters'
									isError={errorIndexes.has(2)}
								>
									<Field.Select
										title={t('api.channel')}
										tooltip={t(
											radar_type === 'echo_shield'
												? 'tooltips.echoShieldChannel'
												: 'tooltips.channel'
										)}
										options={
											radar_type === 'echo_shield'
												? ECHOSHIELD_RADAR_FREQUENCY_CHANNEL
												: (t('channelOptions', {
														returnObjects: true,
													}) as FormSelectValueType[])
										}
										register={register('channel')}
										error={errors?.channel?.message}
									/>
									{radar_type === 'radar_zero' && (
										<>
											<Field.Select
												title={t('api.tcm')}
												tooltip={t('tooltips.tcm')}
												options={
													t('radarTcmOptions', {
														returnObjects: true,
													}) as FormSelectValueType[]
												}
												register={register('tcm_num')}
												error={errors?.tcm_num?.message}
											/>
											<Field.UnitsSlider
												units='deg'
												title={t('api.azimuthStep')}
												tooltip={t('tooltips.azimuthStepSize')}
												min={2}
												max={60}
												step={2}
												register={register('radar_az_step', {
													valueAsNumber: true,
												})}
												error={errors?.radar_az_step?.message}
												testId='azimuth-step'
											/>
											<Field.UnitsSlider
												units='deg'
												title={t('api.elevationStep')}
												tooltip={t('tooltips.elevationStepSize')}
												step={2}
												min={2}
												max={40}
												register={register('radar_el_step', {
													valueAsNumber: true,
												})}
												error={errors?.radar_el_step?.message}
												testId='elevation-step'
											/>
										</>
									)}
									<Headings.SectionSubheading
										title={t('headings.searchParameters')}
									/>
									<Field.RangeSlider
										title={t('api.azimuth')}
										tooltip={t('tooltips.azimuth')}
										units='deg'
										defaultValue={[
											defaultValues.radar_az_min_search,
											defaultValues.radar_az_max_search,
										]}
										step={azimuthStep}
										min={-60}
										max={60}
										onChangeEnd={(value: [number, number]) => {
											setValue('radar_az_min_search', value[0], {
												shouldDirty: true,
												shouldValidate: true,
												shouldTouch: true,
											})
											setValue('radar_az_max_search', value[1], {
												shouldDirty: true,
												shouldValidate: true,
												shouldTouch: true,
											})
										}}
										error={[
											errors?.radar_az_min_search?.message,
											errors?.radar_az_max_search?.message,
										]
											.filter((error) => !!error)
											.join(', ')}
										testId='azimuth-search-min-max-range'
									/>
									<Field.RangeSlider
										title={t('api.elevation')}
										units='deg'
										defaultValue={[
											defaultValues.radar_el_min_search,
											defaultValues.radar_el_max_search,
										]}
										step={elevationStep}
										min={-40}
										max={40}
										tooltip={t('tooltips.elevationSearch')}
										onChangeEnd={(value: [number, number]) => {
											setValue('radar_el_min_search', value[0], {
												shouldDirty: true,
												shouldValidate: true,
												shouldTouch: true,
											})
											setValue('radar_el_max_search', value[1], {
												shouldDirty: true,
												shouldValidate: true,
												shouldTouch: true,
											})
										}}
										error={[
											errors?.radar_el_min_search?.message,
											errors?.radar_el_max_search?.message,
										]
											.filter((error) => !!error)
											.join(', ')}
										testId='elevation-search-min-max-range'
									/>
									<Headings.SectionSubheading
										title={t('headings.trackParameters')}
									/>
									<Field.RangeSlider
										title={t('api.azimuth')}
										tooltip={t('tooltips.azimuthTrack', {
											min: defaultValues.radar_az_min_track,
											max: defaultValues.radar_az_max_track,
										})}
										units='deg'
										defaultValue={[
											defaultValues.radar_az_min_track,
											defaultValues.radar_az_max_track,
										]}
										step={azimuthStep}
										min={-60}
										max={60}
										onChangeEnd={(value: [number, number]) => {
											setValue('radar_az_min_track', value[0], {
												shouldDirty: true,
												shouldValidate: true,
												shouldTouch: true,
											})
											setValue('radar_az_max_track', value[1], {
												shouldDirty: true,
												shouldValidate: true,
												shouldTouch: true,
											})
										}}
										error={[
											errors?.radar_az_min_track?.message,
											errors?.radar_az_max_track?.message,
										]
											.filter((error) => !!error)
											.join(', ')}
										testId='azimuth-track-min-max-range'
									/>
									<Field.RangeSlider
										title={t('api.elevation')}
										units='deg'
										tooltip={t('tooltips.elevationTrack')}
										defaultValue={[
											defaultValues.radar_el_min_track,
											defaultValues.radar_el_max_track,
										]}
										step={elevationStep}
										min={-40}
										max={40}
										onChangeEnd={(value: [number, number]) => {
											setValue('radar_el_min_track', value[0], {
												shouldDirty: true,
												shouldValidate: true,
												shouldTouch: true,
											})
											setValue('radar_el_max_track', value[1], {
												shouldDirty: true,
												shouldValidate: true,
												shouldTouch: true,
											})
										}}
										error={[
											errors?.radar_el_min_track?.message,
											errors?.radar_el_max_track?.message,
										]
											.filter((error) => !!error)
											.join(', ')}
										testId='elevation-track-min-max-range'
									/>
									<Headings.SectionSubheading
										title={t('headings.maskParameters')}
									/>
									{radar_type === 'radar_zero' && (
										<Field.Select
											title={t('api.maskClutterWidth')}
											tooltip={t('tooltips.maskClutterWidth')}
											options={
												t('maskClutterWidthOptions', {
													returnObjects: true,
												}) as FormSelectValueType[]
											}
											register={register('mask_clutter_width')}
											error={errors?.mask_clutter_width?.message}
										/>
									)}
									<Field.RangeSlider
										title={t('api.rcs')}
										tooltip={t('tooltips.rcs')}
										defaultValue={[
											defaultValues.min_rcs,
											defaultValues.max_rcs,
										]}
										step={1}
										min={-50}
										max={100}
										onChangeEnd={(value: [number, number]) => {
											setValue('min_rcs', value[0], {
												shouldDirty: true,
												shouldValidate: true,
												shouldTouch: true,
											})
											setValue('max_rcs', value[1], {
												shouldDirty: true,
												shouldValidate: true,
												shouldTouch: true,
											})
										}}
										error={[errors?.min_rcs?.message, errors?.max_rcs?.message]
											.filter((error) => !!error)
											.join(', ')}
										testId='rcs-range'
									/>
									<Field.Slider
										title={t('api.min_reporting_confidence')}
										tooltip={t('tooltips.min_reporting_confidence')}
										min={0}
										max={100}
										register={register('min_reporting_confidence', {
											valueAsNumber: true,
										})}
										error={errors?.min_reporting_confidence?.message}
										testId='min-reporting-confidence'
									/>
									<Field.Slider
										title={t('api.max_confidence_time')}
										tooltip={t('tooltips.max_confidence_time')}
										min={0}
										max={10}
										register={register('max_confidence_time', {
											valueAsNumber: true,
										})}
										error={errors?.max_confidence_time?.message}
										testId='max-confidence-time'
									/>
									<Field.Slider
										title={t('api.max_coast_time')}
										tooltip={t('tooltips.max_coast_time')}
										min={0}
										max={10}
										register={register('max_coast_time', {
											valueAsNumber: true,
										})}
										error={errors?.max_coast_time?.message}
										testId='max-coast-time'
									/>
									<Field.Slider
										title={t('api.max_target_velocity')}
										tooltip={t('tooltips.max_target_velocity')}
										min={0}
										max={100}
										register={register('max_target_velocity', {
											valueAsNumber: true,
										})}
										error={errors?.max_target_velocity?.message}
										testId='max-target-velocity'
									/>
								</Accordion.Item>
							</Collapse>
						</Accordion.Container>

						<FormButtons
							isSubmitting={isSubmitting}
							isDirty={isDirty}
							handleCancel={handleCancel}
						/>
					</form>
				</FormProvider>
			</ErrorBoundary>
		</>
	)
}

export default RadarForm
