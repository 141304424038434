import { useTranslation } from 'react-i18next'
import {
	MdVisibility as ShowStreamIcon,
	MdVisibilityOff as HideStreamIcon,
} from 'react-icons/md'

import { useAppDispatch, useAppSelector } from '@Store/index'

import {
	useGetSiteLiveQuery,
	selectCurrentCamera,
	selectIsCameraDisabled,
} from '@Store/sites/sitesWsApi'
import { setActiveCameraId } from '@Store/ui/uiSlice'

import QuickActionsButton from '../QuickActionsButton/QuickActionsButton'

// There is a cameraPersistence.ts middleware that manages the activeCameraId

const CameraStreamButton = ({ siteId }: { siteId: number }) => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'quickActions.cameraActions',
	})

	const activeCameraId = useAppSelector((state) => state.ui.activeCameraId)
	const dispatch = useAppDispatch()

	const { currentCamera, isDisabled } = useGetSiteLiveQuery(siteId, {
		selectFromResult: ({ data }) => ({
			currentCamera: selectCurrentCamera(data),
			isDisabled: selectIsCameraDisabled(data),
		}),
	})

	const isStreaming = !!activeCameraId

	const toggleCameraStream = () => {
		dispatch(setActiveCameraId(isStreaming ? null : currentCamera.id))
	}

	return (
		<QuickActionsButton
			icon={!isStreaming ? ShowStreamIcon : HideStreamIcon}
			label={!isStreaming ? t('cameraStream') : t('hideCameraStream')}
			onClick={toggleCameraStream}
			isActive={isStreaming}
			isDisabled={isDisabled}
			testId='quick-action-camera-stream'
		/>
	)
}

export default CameraStreamButton
