// React
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
// Chakra
import { MdFilterList as FilterIcon } from 'react-icons/md'
// Components
import QuickActionsButton from '../QuickActionsButton/QuickActionsButton'
// Store
import {
	selectSiteDetection,
	useGetSiteLiveQuery,
	selectSiteHasRfSensor,
	selectDetectionHasRfContributions,
} from '@Store/sites/sitesWsApi'
import { useAppSelector } from '@/store'
import { selectActiveTargetId } from '@Store/ui/uiSlice'
import { convertRadToDegree } from '@Utils/mathUtils'
// Utils
import { findFrequencyBand } from '@Utils/rfFilter'
import { useGetRfSensorFiltersQuery } from '@Store/rfFilters/rfFiltersApi'
import { isValidFrequency } from '@/constants/sensorFilters'
import { formatProtocol, formatVendor } from '@/utils/formatUtils'
import { ADD_FILTER_DEFAULT_VALUES } from '@/components/Forms/RfFilterForm/RfFiltersForms.schema'

const RfFilterButton = () => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'quickActions.maskActions',
	})

	const navigate = useNavigate()
	const { siteId: siteIdParam } = useParams()
	const siteId = Number(siteIdParam)

	const activeTargetId = useAppSelector(selectActiveTargetId)

	// Detections
	const { mode, detection, hasRfSensor, hasRfContributions } =
		useGetSiteLiveQuery(siteId, {
			selectFromResult: ({ data }) => ({
				mode: data?.mode,
				detection: selectSiteDetection(data, activeTargetId),
				hasRfSensor: selectSiteHasRfSensor(data),
				hasRfContributions: selectDetectionHasRfContributions(
					data,
					activeTargetId
				),
			}),
		})
	const detectionContributions = detection?.detection_contributions || []

	const sensorType = detectionContributions[0]?.sensor_type
	const sensorId = detectionContributions[0]?.sensor_id

	// Filters
	const { data: rf_filters } = useGetRfSensorFiltersQuery(
		{ siteId, sensorId },
		{ skip: !sensorId || !['rfSensor', 'dsx'].includes(sensorType) }
	)
	const filtersLength: number =
		rf_filters?.filter((rf_filter) => !rf_filter?.whitelist).length || 0

	const handleClick = () => {
		const installationId = detectionContributions[0].sentry_id
		const aoa = detectionContributions[0]?.aoa
		const aoaError = detectionContributions[0]?.aoa_error
		const aoaIsValid = aoa < 12
		const rfFilterState = {
			vendor: formatVendor(detection?.vendor) ?? '',
			protocol: formatProtocol(detection?.protocol) ?? '',
			frequency_band: findFrequencyBand(detection?.frequency),
			frequency: detection?.frequency,
			frequency_tolerance: 5_000_000,
			bearing: aoaIsValid
				? Number(convertRadToDegree(aoa).toFixed(2))
				: ADD_FILTER_DEFAULT_VALUES.bearing,
			bearing_tolerance: aoaIsValid
				? Number(convertRadToDegree(aoaError).toFixed(2))
				: ADD_FILTER_DEFAULT_VALUES.bearing_tolerance,
			name: `${t('newFilter')} #${filtersLength + 1}`,
		}
		navigate(
			`/${siteId}/installation/${installationId}/rf_sensors/${sensorId}/rf_filters/add`,
			{
				state: { rfFilterState },
			}
		)
	}

	const frequencyBand = findFrequencyBand(detection?.frequency)?.replace(
		'MHz',
		''
	)
	const isDisabled =
		mode !== 'calibration' ||
		!sensorId ||
		!hasRfSensor ||
		!hasRfContributions ||
		!isValidFrequency(frequencyBand)

	return (
		<QuickActionsButton
			icon={FilterIcon}
			label={t('addFilter')}
			isDisabled={isDisabled}
			onClick={handleClick}
			testId='rf-filter-button'
		/>
	)
}

export default RfFilterButton
