import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
	Grid,
	GridItem,
	AccordionItem,
	AccordionButton,
	AccordionIcon,
	AccordionPanel,
	Flex,
	Box,
	Text,
	Divider,
	Modal,
	useDisclosure,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Button,
	useTheme,
	Icon,
} from '@chakra-ui/react'
import { MdError, MdMap } from 'react-icons/md'
import Field from '@Components/FormElements'

import { useFormContext, useFormState } from 'react-hook-form'
import type { ThreatClassifierContribution } from '../utils/ThreatAI'
import type { Zone } from '@Store/types'
import { useCallback, useMemo } from 'react'
import type {
	ClassifyByProtectedZonesError,
	ThreatConfigClickElement,
} from '../utils/types'
import Tooltip from '@UI/Tooltip/Tooltip'
import ThreatConfigZonesActiveButton from './ThreatConfigZonesActiveButton'
import type { FormSelectValueType } from '@/components/FormElements/Select/Select'

// Index for zone settings tab in maps and zones accordion
const ZONE_SETTINGS_TAB = 1

interface ProtectedZonesFormProps {
	classifier: ThreatClassifierContribution
	divider: boolean
	alertZones: Zone[]
	siteId: number
	isDirty: boolean
	onClickAccordion: (e: ThreatConfigClickElement) => void
}

const DroneProtectedZonesForm = ({
	classifier,
	divider,
	alertZones,
	siteId,
	isDirty,
	onClickAccordion,
}: ProtectedZonesFormProps) => {
	const { register, watch } = useFormContext()
	const { errors } = useFormState()
	const { isOpen, onOpen, onClose } = useDisclosure()
	const navigate = useNavigate()
	const { t } = useTranslation('panels', {
		keyPrefix: 'threatAnalysis',
	})
	const {
		semanticTokens: { colors },
	} = useTheme()

	const [byProtectedZonesActive, ...rest] = watch([
		'classify_by_protected_zones.active',
		'classify_by_class.active',
		'classify_by_communication.active',
		'classify_by_geo_location.active',
		'classify_by_legal_limits.active',
		'classify_by_time.active',
		'classify_by_zone_origin.active',
	])
	const isDisabled = byProtectedZonesActive && rest.every((c) => !c)

	const validAlertZones = useMemo(() => {
		return (
			alertZones &&
			alertZones.length > 0 &&
			alertZones.filter((zone) => zone.zone_type === 'alert')
		)
	}, [alertZones])

	const redirect = useCallback(() => {
		navigate(`/${siteId}/zones`, {
			state: { activeTab: [ZONE_SETTINGS_TAB] },
		})
	}, [navigate, siteId])

	const redirectToZones = useCallback(() => {
		if (!isDirty) redirect()
		else onOpen()
	}, [redirect, onOpen, isDirty])

	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose} isCentered>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{t('modal.heading')}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>{t('modal.body')}</ModalBody>

					<ModalFooter>
						<Button onClick={redirect}>{t('modal.discard')}</Button>
						<Button variant='ghost' onClick={onClose}>
							{t('modal.cancel')}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
			<AccordionItem>
				<AccordionButton
					_hover={{ bg: 'transparent' }}
					bg={colors.body_bg}
					paddingBlock={4}
					marginTop={0}
					onClick={(e) => onClickAccordion(e)}
				>
					<Flex flexDirection='row' flex='1'>
						<Tooltip
							label={isDisabled ? t('tooltips.parameterDisabled') : undefined}
						>
							<Box
								height='100%'
								sx={{ '& > div > div': { marginTop: 0 } }}
								// Rewrite margin for switch
							>
								<Field.Switch
									id='protected-zones'
									register={register('classify_by_protected_zones.active')}
									disabled={isDisabled}
								/>
							</Box>
						</Tooltip>
						<Flex flexGrow='0' flexDir='column' alignItems='flex-start' w={80}>
							<Box
								textAlign='start'
								textTransform='none'
								fontSize='sm'
								fontWeight='normal'
								color='white'
							>
								<Text>{t('headings.protectedZones')}</Text>
							</Box>
							<Box>
								<Text
									fontSize='xs'
									fontWeight='normal'
									textTransform='none'
									textAlign='left'
									lineHeight='125%'
								>
									{t('tooltips.protectedZones')}
								</Text>
							</Box>
						</Flex>
						<Flex
							flex='1 0 75px'
							padding={0}
							h='100%'
							justifyContent='space-between'
						>
							<Text fontSize='sm' fontWeight='normal' textAlign='center' w={12}>
								{classifier.ContributionThreatLevel
									? Math.round(classifier.ContributionThreatLevel * 100)
									: 0}
								%
							</Text>
							<AccordionIcon color={colors.button_text} />
						</Flex>
					</Flex>
				</AccordionButton>
				<AccordionPanel bg={colors.body_bg}>
					<Grid
						templateColumns='repeat(8, 11fr)'
						bg={colors.card_background}
						rowGap={0}
						paddingBottom={4}
					>
						<GridItem rowSpan={6} colSpan={1} />
						{!validAlertZones && (
							<GridItem colSpan={6} mb={4}>
								<Flex alignItems='center'>
									<Box>
										<Flex bgColor='input_bg' p={2} direction='column'>
											<Flex gap={1}>
												<Icon as={MdError} h={4} w={4} color='primary' />
												<Text fontSize='sm'>{t('alerts.title')}</Text>
											</Flex>
										</Flex>
									</Box>
								</Flex>
							</GridItem>
						)}
						<GridItem rowSpan={3} colSpan={6} marginBottom={2}>
							<Flex alignItems='center' justifyContent='end'>
								<Box onClick={redirectToZones} cursor='pointer'>
									<Flex alignItems='center' justifyContent='space-around'>
										<MdMap />
										<Text
											textTransform='uppercase'
											paddingLeft={2}
											fontSize='xs'
										>
											{t('tooltips.editZones')}
										</Text>
									</Flex>
								</Box>
							</Flex>
						</GridItem>
						<GridItem rowSpan={1} colSpan={6}>
							{validAlertZones ? (
								validAlertZones.map((zone: Zone, index: number) => {
									return (
										<Box key={index} height={10}>
											<Divider />
											<Flex
												key={index}
												h='100%'
												alignItems='center'
												justifyContent='space-between'
											>
												<Text fontSize='sm'>{zone.name}</Text>
												<ThreatConfigZonesActiveButton zoneId={zone.id} />
											</Flex>
										</Box>
									)
								})
							) : (
								<Box height={10}>
									<Divider />
									<Flex
										h='100%'
										alignItems='center'
										justifyContent='space-between'
									>
										<Text>{t('tooltips.defaultZone')}</Text>
										<ThreatConfigZonesActiveButton zoneId={1} />
									</Flex>
								</Box>
							)}
						</GridItem>
						<GridItem rowSpan={1} colSpan={6}>
							<Field.Select
								title='Priority'
								options={
									t('forms.priorityOptions', {
										returnObjects: true,
									}) as FormSelectValueType[]
								}
								testId='classify-by-protected-zones-priority'
								register={register('classify_by_protected_zones.priority')}
								error={
									(errors as ClassifyByProtectedZonesError)
										?.classify_by_protected_zones?.priority?.message
								}
								disabled={!byProtectedZonesActive}
							/>
						</GridItem>
					</Grid>
				</AccordionPanel>
				{divider ? <Divider borderColor={colors.border_color} /> : null}
			</AccordionItem>
		</>
	)
}

export default DroneProtectedZonesForm
