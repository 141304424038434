// Packages
import { memo, useCallback } from 'react'
import { Flex } from '@chakra-ui/react'
import noop from 'lodash/noop'

// Relatives
import NotificationHeader from './NotificationHeader/NotificationHeader'
import NotificationDetails from './NotificationDetails/NotificationDetails'
import NotificationContextProvider from './NotificationContextProvider'

// Types
import type {
	Camera,
	Detection,
	DetectionContributionSensor,
	GenericSensor,
	Radar,
	RfSensor,
} from '@/store/types'

export type NotificationProps = {
	detection?: Detection & { zoneName?: string; isAlert?: boolean }
	seenContributor?: DetectionContributionSensor[]
	expiredAt?: number
	siteName?: string
	isSelected?: boolean
	isStale?: boolean
	isAlert?: boolean
	isTrack?: boolean
	isReplay?: boolean
	showHeader?: boolean
	// Extra Flags
	showRadarMaskPreview?: boolean
	// Sensors
	radars?: Radar[]
	rfSensors?: RfSensor[]
	cameras?: Camera[]
	genericSensors: GenericSensor[]
	// Others
	testId?: string
	// Callbacks
	onSelect?: (id: string) => void
	onCloseAlert?: (id: string) => void
}

const Notification = ({
	detection,
	seenContributor = [],
	expiredAt = 0,
	siteName = '',
	isSelected = false,
	isStale = false,
	isAlert = false,
	isTrack = false,
	isReplay = false,
	showHeader = true,
	// Extra Flags
	showRadarMaskPreview = false,
	// Sensors
	radars = [],
	rfSensors = [],
	cameras = [],
	genericSensors = [],
	// Others
	testId = '',
	onSelect = noop,
	onCloseAlert = noop,
}: NotificationProps) => {
	const handleClickHeader = useCallback(
		() => detection && onSelect(detection.target_id),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[detection?.target_id, onSelect]
	)

	return (
		<Flex
			direction='column'
			_notLast={{ marginBlockEnd: '1px' }}
			data-testid={testId}
			data-uuid={detection?.target_id}
			pointerEvents='all'
		>
			<NotificationContextProvider
				isStale={isStale}
				detection={detection}
				expiredAt={expiredAt}
				seenContributor={seenContributor}
			>
				{showHeader && (
					<NotificationHeader
						siteName={siteName}
						isSelected={isSelected}
						isAlert={isAlert}
						isTrack={isTrack}
						isStale={isStale}
						onClick={handleClickHeader}
						onCloseAlert={onCloseAlert}
					/>
				)}
				{isSelected && (
					<NotificationDetails
						showRadarMaskPreview={showRadarMaskPreview}
						radars={radars}
						rfSensors={rfSensors}
						cameras={cameras}
						genericSensors={genericSensors}
						isStale={isStale}
						isReplay={isReplay}
					/>
				)}
			</NotificationContextProvider>
		</Flex>
	)
}

export default memo(Notification)
