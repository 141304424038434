// Packages
import { Flex } from '@chakra-ui/react'

// Relatives
import DisruptionDetailsTable from './DisruptionDetailsTable/DisruptionDetailsTable'
import DisruptionDetails from './DisruptionDetails'
import DisruptionsBarChart from './DisruptionsBarChart'

type DisruptionsSummaryProps = {
	siteId: number
	startTime: string
	endTime: string
}

const DisruptionsSummary = ({
	siteId,
	startTime,
	endTime,
}: DisruptionsSummaryProps) => (
	<>
		<Flex alignItems='center' gap='30px' marginBlockEnd={4}>
			<Flex direction='column' gap='20px'>
				<DisruptionDetails
					siteId={siteId}
					startTime={startTime}
					endTime={endTime}
				/>
			</Flex>
			<DisruptionsBarChart
				siteId={siteId}
				startTime={startTime}
				endTime={endTime}
			/>
		</Flex>
		<DisruptionDetailsTable
			siteId={siteId}
			startTime={startTime}
			endTime={endTime}
		/>
	</>
)

export default DisruptionsSummary
