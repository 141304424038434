// React
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
// Components
import { Switch } from '@chakra-ui/react'
// Store
import { useAppDispatch } from '@/store'
import { useUpdateRadarMaskMutation } from '@Store/radarMasks/radarMasksApi'
import { addToast } from '@Store/ui/uiSlice'
import type { RadarMaskZone } from '@Store/types'
import type { FormError } from '@Forms/types'
import { getCoordinatesFromSector } from '@Utils/turfUtils'
import { useGetRadarQuery } from '@Store/radars/radarsApi'

const ZoneMasksActiveSwitch = ({ radarMask }: { radarMask: RadarMaskZone }) => {
	const { t } = useTranslation('panels', { keyPrefix: 'zoneMasks' })
	const { siteId: siteIdParam, sensorId: sensorIdParam } = useParams()
	const siteId = Number(siteIdParam)
	const sensorId = Number(sensorIdParam)
	const { id, active } = radarMask

	const [updateRadarMask, { isLoading }] = useUpdateRadarMaskMutation()
	const dispatch = useAppDispatch()

	const { radarData } = useGetRadarQuery(
		{ siteId, sensorId },
		{
			selectFromResult: ({ data }) => ({ radarData: data }),
		}
	)

	const handleToggleActive = async () => {
		try {
			const updatedRadarMask: RadarMaskZone = {
				...radarMask,
				active: !active,
			}

			if (updatedRadarMask.active) {
				updatedRadarMask.coordinates = getCoordinatesFromSector({
					latitude: radarData?.latitude || 0,
					longitude: radarData?.longitude || 0,
					direction: radarData?.direction || 0,
					azimuth_min: updatedRadarMask.azimuth_min,
					azimuth_max: updatedRadarMask.azimuth_max,
					range_min: updatedRadarMask.range_min,
					range_max: updatedRadarMask.range_max,
				})
			}

			await updateRadarMask({
				siteId: Number(siteId),
				sensorId: Number(sensorId),
				maskId: id,
				...updatedRadarMask,
			}).unwrap()
		} catch (error) {
			dispatch(
				addToast({
					id,
					siteId,
					type: 'error',
					title: t('toast.updateErrorTitle'),
					description:
						(error as FormError)?.data?.error ??
						(error as { error: string; status: string })?.error,
					error: error,
				})
			)
			console.error('Unhandled update radar mask active error', error)
		}
	}

	return (
		<Switch
			onChange={handleToggleActive}
			isChecked={active}
			isDisabled={isLoading}
			data-testid='radar-mask-active-switch'
		/>
	)
}

export default ZoneMasksActiveSwitch
