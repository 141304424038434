// Packages
import { useCallback, useState } from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

// Alias
import {
	selectActiveTargetId,
	selectSeenContributorsList,
	toggleActiveTargetId,
} from '@Store/ui/uiSlice'
import { useAppDispatch, useAppSelector } from '@Store/index'
import {
	useGetSiteLiveQuery,
	selectSiteAlertTargetIds,
	selectSiteStaleAlerts,
	selectSiteName,
	clearSiteAlert,
	selectSiteRadars,
	selectSiteRfSensors,
	selectSiteCameras,
	selectCameraTrackedTargetId,
	selectSiteDetectionNotifications,
	selectSiteGenericSensors,
} from '@Store/sites/sitesWsApi'
import { skipToken } from '@reduxjs/toolkit/query/react'
import ErrorBoundary from '@Components/App/ErrorHandling/ErrorBoundary'
import type { Detection } from '@/store/types'

// Relatives
import ThreatLevelSelector from './ThreatLevelSelector'
import ClearAlertsButton from './ClearAlertsButton'
import useShowPreviewMode from '../../MapLayers/PreviewModes/useShowPreviewMode'
import AlertSoundButton from './AlertSoundButton'
import Notification from './Notification/Notification'

const Notifications = ({ siteId }: { siteId: number }) => {
	const { t } = useTranslation('panels', { keyPrefix: 'notifications' })
	const { showCameraCalibrationPreview, showRadarMaskPreview } =
		useShowPreviewMode()
	const {
		alertTargetIds,
		trackedTargetId,
		staleAlerts,
		siteName,
		radars,
		rfSensors,
		cameras,
		genericSensors,
		detectionNotifications,
	} = useGetSiteLiveQuery(siteId ?? skipToken, {
		selectFromResult: ({ data }) => ({
			alertTargetIds: selectSiteAlertTargetIds(data),
			trackedTargetId: selectCameraTrackedTargetId(data),
			siteName: selectSiteName(data),
			staleAlerts: selectSiteStaleAlerts(data),
			radars: selectSiteRadars(data),
			rfSensors: selectSiteRfSensors(data),
			cameras: selectSiteCameras(data),
			genericSensors: selectSiteGenericSensors(data),
			detectionNotifications: selectSiteDetectionNotifications(
				data,
				showCameraCalibrationPreview
			),
		}),
	})
	const activeTargetId = useAppSelector(selectActiveTargetId)
	const [filterThreatLevel, setFilterThreatLevel] = useState<number>(0)
	const seenContributors = useAppSelector(selectSeenContributorsList())

	const filterDetections = (detection: Detection) =>
		detection.threat_level >= filterThreatLevel

	// detections with active alerts first, and user-selected detection on top
	const sortDetections = (a: Detection, b: Detection) =>
		(activeTargetId === a.target_id
			? -2
			: alertTargetIds.includes(a.target_id)
				? -1
				: 1) -
		(activeTargetId === b.target_id
			? -2
			: alertTargetIds.includes(b.target_id)
				? -1
				: 1)

	const dispatch = useAppDispatch()
	const handleClickNotification = useCallback(
		(id: string) => dispatch(toggleActiveTargetId(id)),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	)

	const handleClickCloseAlert = useCallback(
		(id: string) => dispatch(clearSiteAlert(siteId, id)),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[siteId]
	)

	return (
		(detectionNotifications.length > 0 || staleAlerts.length > 0) && (
			<ErrorBoundary showBackButton={false} showLocationReloadButton={true}>
				<Flex
					bgColor='body_bg'
					paddingInline={2}
					paddingBlock={3}
					pointerEvents='all'
					gap={2}
				>
					<Text
						fontWeight='bold'
						textTransform='uppercase'
						color='label_color'
						flexGrow={1}
					>
						{t('notificationsPanelHeading')}
					</Text>
					<AlertSoundButton />
					<ThreatLevelSelector
						value={filterThreatLevel}
						disabled={detectionNotifications.length < 1}
						onChange={(value) => {
							setFilterThreatLevel(value)
						}}
					/>
					<ClearAlertsButton disabled={alertTargetIds.length < 1} />
				</Flex>
				<Flex
					flexGrow={1}
					flexShrink={0}
					flexDirection='column'
					position='relative'
				>
					<Box
						display='flex'
						flexDirection='column'
						flexGrow={1}
						pointerEvents='none'
						position='absolute'
						h='100%'
						w='100%'
					>
						<Box overflowY='scroll'>
							{detectionNotifications
								.filter(filterDetections)
								.sort(sortDetections)
								.map((detection) => (
									<Notification
										key={detection.target_id}
										detection={detection}
										seenContributor={seenContributors?.[detection.target_id]}
										siteName={siteName}
										onSelect={handleClickNotification}
										onCloseAlert={handleClickCloseAlert}
										isSelected={activeTargetId === detection.target_id}
										isAlert={detection.isAlert}
										isTrack={trackedTargetId === detection.target_id}
										showRadarMaskPreview={showRadarMaskPreview}
										radars={radars}
										rfSensors={rfSensors}
										cameras={cameras}
										genericSensors={genericSensors}
										testId='detection-notification-box'
									/>
								))}
							{staleAlerts.map(
								(alert) =>
									alert.detection && (
										<Notification
											key={alert.track.id}
											detection={alert.detection}
											expiredAt={alert._ui_expired_at}
											seenContributor={seenContributors?.[alert.track.id]}
											siteName={siteName}
											onSelect={handleClickNotification}
											onCloseAlert={handleClickCloseAlert}
											isSelected={activeTargetId === alert.track.id}
											isAlert={false}
											isStale={true}
											isTrack={false}
											showRadarMaskPreview={showRadarMaskPreview}
											radars={radars}
											rfSensors={rfSensors}
											cameras={cameras}
											genericSensors={genericSensors}
											testId='stale-detection-notification-box'
										/>
									)
							)}
						</Box>
					</Box>
				</Flex>
			</ErrorBoundary>
		)
	)
}

export default Notifications
