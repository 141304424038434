import type { NumberInputProps, NumberInputField } from '@chakra-ui/react'
import type { FocusEvent } from 'react'

import {
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInput,
	NumberInputStepper,
} from '@chakra-ui/react'
import { useMemo } from 'react'

type Props = Omit<NumberInputProps, 'onChange'> & {
	value: string | undefined
	min: number
	max: number
	precision?: number
	onChange: (value: string) => void
	showStepper?: boolean
	children: React.ReactElement<typeof NumberInputField>
}

const SliderNumberInput = ({
	value,
	min,
	max,
	precision,
	showStepper,
	children,
	onChange,
	onBlur,
	...props
}: Props) => {
	const negativeAllowed = useMemo(() => min < 0 || max < 0, [min, max])

	const handleBlur = (e: FocusEvent<HTMLInputElement, Element>) => {
		if (!value) return
		if (value === '-') return

		onBlur?.(e)
	}

	const handleChange = (value: string) => {
		const negativeNumberRegex = /^-?\d*\.?\d{0,2}?$/
		const positiveNumberRegex = /^\d*\.?\d{0,2}?$/

		const isValidNumber = negativeAllowed
			? negativeNumberRegex.test(value)
			: positiveNumberRegex.test(value)

		if (!isValidNumber) {
			return
		}

		onChange(value)
	}

	return (
		<NumberInput
			onChange={(value) => handleChange(value)}
			onBlur={handleBlur}
			value={value}
			min={min}
			max={max}
			precision={precision}
			pattern='(-)?[0-9]*(.[0-9]+)?'
			{...props}
		>
			{children}

			{showStepper && (
				<NumberInputStepper>
					<NumberIncrementStepper />
					<NumberDecrementStepper />
				</NumberInputStepper>
			)}
		</NumberInput>
	)
}

export default SliderNumberInput
