import { useTheme } from '@chakra-ui/react'
import { calculateLinePositions } from '../../utils/aglCalc'

const Measurement = () => {
	const {
		semanticTokens: { colors },
	} = useTheme()
	const positions = calculateLinePositions(6000, 6)
	return (
		<>
			{positions.map((pos, index) => (
				<line
					key={index}
					y1={`${pos}%`}
					y2={`${pos}%`}
					x1='0'
					x2='100%'
					strokeWidth={1}
					stroke={colors.measuringLine}
				/>
			))}
		</>
	)
}

export default Measurement
