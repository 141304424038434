import {
	useGetSiteLiveQuery,
	selectSiteData,
	selectSiteHasRfSensor,
	selectSiteHasGenericSensors,
} from '@/store/sites/sitesWsApi'
import {
	Box,
	Card,
	CardHeader,
	CardBody,
	Flex,
	Heading,
	Icon,
	Text,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'

import { Subheading } from '@/components/_UI/Headings/Headings'

import EditButton from './EditButton'

import { formatLatLng } from '@Utils/formatUtils'
import useAuth from '@Hooks/useAuth'
import { useTranslation } from 'react-i18next'
import { MdError } from 'react-icons/md'

const Site = ({ siteId }: { siteId: number }) => {
	const { name, latitude, longitude, height, hasGenericSensor, hasRfSensor } =
		useGetSiteLiveQuery(siteId, {
			selectFromResult: ({ data }) => ({
				...selectSiteData(data),
				hasRfSensor: selectSiteHasRfSensor(data),
				hasGenericSensor: selectSiteHasGenericSensors(data),
			}),
		})
	const { t } = useTranslation('panels', {
		keyPrefix: 'siteInstallations.headings',
	})
	const { isSiteManager } = useAuth()
	return (
		<Card className='Site' variant='site' marginBlockStart={1}>
			<CardHeader data-testid='site-header'>{t('site')}</CardHeader>
			<CardBody>
				<Flex alignItems='center' justifyContent='space-between'>
					<Box isTruncated marginInlineEnd={4}>
						<Heading size='sm' data-testid='site-name' isTruncated>
							{name}
						</Heading>
						<Subheading data-testid='site-coordinates'>
							{latitude ? formatLatLng(latitude) : '-'}{' '}
							{longitude ? formatLatLng(longitude) : '-'}
						</Subheading>
					</Box>
					{isSiteManager && (
						<Box pr={4}>
							<EditButton
								to={`/${siteId}/settings`}
								type='edit'
								testId='edit-site'
							/>
						</Box>
					)}
				</Flex>
				{height === 0 && (hasGenericSensor || hasRfSensor) && (
					<Flex alignItems='center' marginBlockStart={3}>
						<Box>
							<Flex bgColor='input_bg' p={2} direction='column'>
								<Flex gap={1}>
									<Icon as={MdError} h={4} w={4} color='primary' />
									<Text display='inline' fontSize='sm'>
										<Text as='span'>{t('siteHeightZero')}</Text>
										{isSiteManager ? (
											<Link to={`/${siteId}/settings`}>
												<Text
													as='span'
													color='primary'
													cursor='pointer'
													_hover={{ textDecoration: 'underline' }}
												>
													{t('update')}
												</Text>
											</Link>
										) : (
											<Text as='span'>{t('update')}</Text>
										)}
										<Text as='span'>{t('siteHeightValue')}</Text>
									</Text>
								</Flex>
							</Flex>
						</Box>
					</Flex>
				)}
			</CardBody>
		</Card>
	)
}

export default Site
