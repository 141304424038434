// Packages
import { Box, Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

// Alias
import {
	formatBeaconString,
	formatFrequency,
	formatProtocol,
	formatRssi,
	formatVendor,
} from '@/utils/formatUtils'

export type DetailsRFProps = {
	protocol?: string
	vendor?: string
	rssi?: number
	frequency?: number
	ssid?: string
}

const DetailsRF = ({
	protocol,
	vendor,
	rssi = 0,
	frequency = 0,
	ssid = '',
}: DetailsRFProps) => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'notifications.detections.detectionDetail.headings',
	})

	return (
		<Flex w='100%' flexWrap='wrap'>
			<Box w='50%' marginBlockEnd={2}>
				<Text fontSize='xs' color='headings'>
					{t('protocol')}
				</Text>
				<Text fontSize='sm'>{formatProtocol(protocol) || '-'}</Text>
			</Box>
			<Box w='50%' marginBlockEnd={2}>
				<Text fontSize='xs' color='headings'>
					{t('frequency')}
				</Text>
				<Text fontSize='sm'>{formatFrequency(frequency) || '-'}</Text>
			</Box>
			<Box w='50%' marginBlockEnd={2}>
				<Text fontSize='xs' color='headings'>
					{t('vendor')}
				</Text>
				<Text fontSize='sm'>{formatVendor(vendor) || '-'}</Text>
			</Box>
			<Box w='50%' marginBlockEnd={2}>
				<Text fontSize='xs' color='headings'>
					{t('ssid')}
				</Text>
				<Text fontSize='sm'>{formatBeaconString(ssid) ?? '-'}</Text>
			</Box>
			<Box w='50%' marginBlockEnd={2}>
				<Text fontSize='xs' color='headings'>
					{t('rssi') + ' (dBm)'}
				</Text>
				<Text fontSize='sm'>{formatRssi(rssi)}</Text>
			</Box>
		</Flex>
	)
}

export default DetailsRF
