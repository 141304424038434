import { leafletStyles } from './leaflet'

export const styles = {
	global: {
		'html, body': {},
		body: {
			letterSpacing: '0.025rem',
		},
		'@supports selector(::-webkit-scrollbar)': {
			'::-webkit-scrollbar': {
				w: '0.25rem',
				h: '0.25rem',
			},
			'::-webkit-scrollbar-corner': {
				bgColor: 'primary',
			},
			'::-webkit-scrollbar-thumb': {
				bgColor: 'primary',
			},
		},
		'@supports selector(scrollbar-width)': {
			'*': {
				scrollbarColor: 'var(--c2-colors-primary) var(--c2-colors-panel_bg)',
				scrollbarWidth: 'thin',
				scrollbarHeight: 'thin',
			},
		},
		'.rf-detection-sector-show-outside': {
			filter: 'blur(8px)',
		},
		'.disruptor-sector-animation': {
			animation: 'sector-pulse 2s infinite',
			fill: 'red',
			fillOpacity: 0.4,
			stroke: 'red',
			strokeWidth: 3,
		},
		'@keyframes sector-pulse': {
			'0%': {
				opacity: 0.3,
			},
			'50%': {
				opacity: 0.8,
			},
			'100%': {
				opacity: 0.3,
			},
		},
		// Move top-right toast position next to QuickActions and SiteMode alert
		'.chakra-toast__inner': {
			maxWidth: '350px',
			width: '350px',
			margin: '0 !important',
		},
		'div#chakra-toast-manager-top-right': {
			top: '50px !important',
			right: '0',
			gap: '10px',
			padding: '10px',
		},
		'body:has(div.RightPanel) :is(div#chakra-toast-manager-top-right)': {
			right: '324px !important',
		},
		'body:has(div.AlertsPanel):has(div.RightPanel) :is(div#chakra-toast-manager-top-right)':
			{
				top: '114px !important',
			},
		'.joystick-container button:focus': {
			outline: 'var(--c2-colors-primary) 2px solid',
		},
		// Leaflet styles
		...leafletStyles,
	},
}
