// React
import { useTranslation } from 'react-i18next'
// Chakra
import { Box, IconButton, useDisclosure } from '@chakra-ui/react'
import { MdEdit } from 'react-icons/md'
// Components
import MapEditLayerForm from '@Forms/MapsZonesForms/MapLayersForm/EditMapLayerForm'
import EditButton from '@Components/Site/Panels/SiteInstallations/EditButton'
import Modal from '@UI/Modals/Modal'
// Types
import type { MapLayer } from '@Store/types/mapLayers'

const EditMapLayerModal = ({
	layer,
	variant,
}: {
	layer: MapLayer
	variant: 'icon' | 'button'
}) => {
	const { isOpen, onOpen, onClose } = useDisclosure()
	// Translations
	const { t } = useTranslation('forms', {
		keyPrefix: 'mapsZonesForm.mapLayersForm',
	})

	return (
		<>
			{variant === 'icon' ? (
				<IconButton
					variant='ghost'
					colorScheme='white'
					aria-label={t('buttons.editLayer')}
					icon={<MdEdit />}
					onClick={onOpen}
				/>
			) : (
				<EditButton
					label={t('buttons.editLayer')}
					testId='edit-map-layer'
					onClick={onOpen}
					asMenuListItem
				/>
			)}
			<Modal isOpen={isOpen} onClose={onClose} headerText={t('editMapLayer')}>
				<Box m={4} mt={0}>
					<MapEditLayerForm defaultValues={layer} onClose={onClose} />
				</Box>
			</Modal>
		</>
	)
}

export default EditMapLayerModal
