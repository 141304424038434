import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// Form
import { useForm, FormProvider } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import type { TypeOf } from 'zod'

// Redux
import {
	useGetSiteQuery,
	useUpdateSiteAlertSettingsMutation,
	selectSiteAlertSettings,
} from '@Store/sites/sitesApi'
import type { SiteAlertSettings } from '@Store/types'

// UI
import Field from '@Components/FormElements'
import { FormButtons } from '@Components/FormElements/FormButtons/FormButtons'
import FormWrapper from '@Components/FormElements/FormWrapper/FormWrapper'
// Schema
import { alertSettingsFormSchema } from './AlertSettingsForm.schema'
import {
	selectNotificationsEnabled,
	useGetSystemInfoQuery,
} from '@Store/system/systemApi'
import { setFormError } from '../formHelper'
import FormStatusErrors from '@/components/FormElements/FormStatusErrors/FormStatusErrors'
import type { FormSelectValueType } from '@/components/FormElements/Select/Select'

// Alert Settings Form Loader
const AlertSettingsForm = ({ siteId }: { siteId: number }) => {
	const { t } = useTranslation('forms', { keyPrefix: 'alertSettings' })
	const {
		isLoading,
		isError,
		isSuccess,
		refetch,
		alertSettings: defaultValues,
	} = useGetSiteQuery(siteId, {
		refetchOnMountOrArgChange: true,
		skip: !siteId,
		selectFromResult: ({ isLoading, isError, isSuccess, data }) => ({
			isLoading,
			isError,
			isSuccess,
			alertSettings: selectSiteAlertSettings(data),
		}),
	})

	return (
		<FormWrapper
			entity={t('entity')}
			isEditForm
			isLoading={isLoading}
			isError={isError}
			isSuccess={isSuccess}
			refetch={refetch}
		>
			<Form siteId={siteId} defaultValues={defaultValues} />
		</FormWrapper>
	)
}

// Site Alert Settings Form
const Form = ({
	siteId,
	defaultValues,
}: {
	siteId: number
	defaultValues: SiteAlertSettings
}) => {
	const navigate = useNavigate()

	type Schema = TypeOf<typeof alertSettingsFormSchema>

	// React Hook Form
	const methods = useForm<Schema>({
		resolver: zodResolver(alertSettingsFormSchema),
		defaultValues,
	})
	const {
		register,
		formState: { errors, isSubmitting, isDirty },
		handleSubmit,
		setError,
	} = methods

	// Translations
	const { t } = useTranslation('forms', { keyPrefix: 'alertSettings' })

	// RTK Query mutation(s)
	const [updateSiteAlertSettings] = useUpdateSiteAlertSettingsMutation()

	const handleSave = async (payload: Schema) => {
		try {
			await updateSiteAlertSettings({ ...payload, id: Number(siteId) }).unwrap()
			navigate(`/${siteId}`)
		} catch (error) {
			setFormError<Schema>({ error, setError })
		}
	}

	const handleCancel = () => navigate('/' + siteId)

	const { notificationsEnabled, notificationsEnabledLoaded } =
		useGetSystemInfoQuery(undefined, {
			selectFromResult: ({ data, isSuccess }) => ({
				notificationsEnabledLoaded: isSuccess,
				notificationsEnabled: selectNotificationsEnabled(data),
			}),
		})

	return (
		<FormProvider {...methods}>
			<FormStatusErrors errors={errors} />
			<form onSubmit={handleSubmit(handleSave)}>
				<Field.Select
					title={t('zone_trigger_probability')}
					register={register('zone_trigger_probability')}
					options={
						t('zone_trigger_probability_options', {
							returnObjects: true,
						}) as FormSelectValueType[]
					}
					error={errors?.zone_trigger_probability?.message}
					testId='alerts-zone-trigger-probability'
				/>

				<Field.Select
					title={t('zone_trigger_threat_level')}
					register={register('zone_trigger_threat_level')}
					options={
						t('zone_trigger_threat_level_options', {
							returnObjects: true,
						}) as FormSelectValueType[]
					}
					error={errors?.zone_trigger_threat_level?.message}
					testId='alerts-zone_trigger_threat_level'
				/>

				<Field.Slider
					title={t('zone_trigger_detection_count')}
					tooltip={t('tooltips.disruption')}
					register={register('zone_trigger_detection_count')}
					min={1}
					max={50}
					error={errors?.zone_trigger_detection_count?.message}
					testId='alerts-zone-trigger-detection-count'
					inputWidth={5}
				/>

				<Field.Slider
					title={t('alert_suppression_time_window')}
					tooltip={t('tooltips.alert')}
					register={register('alert_suppression_time_window')}
					min={5}
					max={3600}
					error={errors?.alert_suppression_time_window?.message}
					testId='alerts-suppression-time-window'
					inputWidth={5}
				/>
				{notificationsEnabledLoaded && notificationsEnabled && (
					<Field.Switch
						title={t('trigger_sensors_offline_alerts')}
						register={register('trigger_sensors_offline_alerts')}
						error={errors?.trigger_sensors_offline_alerts?.message}
						description={t('trigger_sensors_offline_alerts_description')}
						testId='alerts-trigger-sensors-offline'
					/>
				)}

				<FormButtons
					isSubmitting={isSubmitting}
					isDirty={isDirty}
					handleCancel={handleCancel}
				/>
			</form>
		</FormProvider>
	)
}

export default AlertSettingsForm
