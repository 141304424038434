import { z } from 'zod'
import { ThreatPriority } from './utils/ThreatAI'

const isThreatPriority = (t: string): t is ThreatPriority => {
	return Object.values(ThreatPriority).includes(t as ThreatPriority)
}

const validThreatPriority = (t: ThreatPriority): ThreatPriority => {
	return t === ThreatPriority.Unknown ? ThreatPriority.LowPriority : t
}

export const stringToValidThreatPriority = (
	t: string,
	ctx: z.RefinementCtx
): ThreatPriority => {
	if (!isThreatPriority(t)) {
		ctx.addIssue({
			code: z.ZodIssueCode.custom,
			message: 'unrecognized threat priority',
		})

		return z.NEVER
	}

	return validThreatPriority(t)
}

const threatAnalysisFormFields = z.object({
	classify_by_class: z
		.object({
			active: z.boolean().optional(),
			size: z.boolean().optional(),
			unknown: z.boolean().optional(),
			priority: z.string().transform(stringToValidThreatPriority),
		})
		.optional(),
	classify_by_protected_zones: z
		.object({
			active: z.boolean().optional(),
			priority: z.string().transform(stringToValidThreatPriority),
		})
		.optional(),
	classify_by_communication: z
		.object({
			active: z.boolean().optional(),
			priority: z.string().transform(stringToValidThreatPriority),
		})
		.optional(),
	classify_by_geo_location: z
		.object({
			active: z.boolean().optional(),
			priority: z.string().transform(stringToValidThreatPriority),
		})
		.optional(),
	classify_by_legal_limits: z
		.object({
			active: z.boolean().optional(),
			priority: z.string().transform(stringToValidThreatPriority),
			speed_min: z.number().min(0).max(200).optional(),
			speed_max: z.number().min(0).max(200).optional(),
			agl_min: z.number().min(0).max(200).optional(),
			agl_max: z.number().min(0).max(200).optional(),
		})
		.optional(),
	classify_by_zone_origin: z
		.object({
			active: z.boolean().optional(),
			priority: z.string().transform(stringToValidThreatPriority),
		})
		.optional(),
	classify_by_time: z
		.object({
			active: z.boolean().optional(),
			priority: z.string().transform(stringToValidThreatPriority),
			start_time: z.number().min(0).max(24).optional(),
			duration: z.number().min(0).max(1440).optional(),
		})
		.optional(),
})

const threatAnalysisFormSchema = threatAnalysisFormFields.superRefine(
	(data, ctx) => {}
)

export type ThreatAnalysisDefaultValues = z.infer<
	typeof threatAnalysisFormSchema
>

export const THREAT_ANALYSIS_DEFAULT_VALUES = {
	classify_by_class: {
		active: true,
		size: false,
		unknown: false,
		priority: ThreatPriority.HighPriority,
	},
	classify_by_protected_zones: {
		active: true,
		priority: ThreatPriority.HighPriority,
	},
	classify_by_zone_origin: {
		active: false,
		priority: ThreatPriority.LowPriority,
	},
	classify_by_communication: {
		active: false,
		priority: ThreatPriority.LowPriority,
	},
	classify_by_geo_location: {
		active: false,
		priority: ThreatPriority.LowPriority,
	},
	classify_by_legal_limits: {
		active: false,
		priority: ThreatPriority.LowPriority,
		speed_min: 0,
		speed_max: 150,
		agl_min: 0,
		agl_max: 150,
	},
	classify_by_time: {
		active: false,
		priority: ThreatPriority.LowPriority,
		start_time: 0,
		duration: 0,
	},
}

export default threatAnalysisFormSchema
