// React
import { useTransition } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// Components
import { Switch } from '@chakra-ui/react'

// Store
import type { FormError } from '@/components/Forms/types'
import type { RfSensorFilter } from '@Store/types'
import { useUpdateRfSensorFilterMutation } from '@Store/rfFilters/rfFiltersApi'
import { useAppDispatch } from '@/store'
import { addToast } from '@/store/ui/uiSlice'

const RfFilterActiveToggleSwitch = ({
	rf_filter,
}: {
	rf_filter: RfSensorFilter
}) => {
	const { t } = useTranslation('panels', { keyPrefix: 'rfFilters' })
	const { siteId } = useParams()
	const { id, active } = rf_filter
	const [isPending, startTransition] = useTransition()
	const [updateRfSensorFilter] = useUpdateRfSensorFilterMutation()
	const dispatch = useAppDispatch()

	const handleToggleActive = () => {
		startTransition(() => {
			const updatedRfFilter: RfSensorFilter = {
				...rf_filter,
				active: !active,
			}
			updateRfSensorFilter({
				siteId: Number(siteId),
				rfFilterId: id,
				rf_filter: updatedRfFilter,
			})
				.unwrap()
				.catch((error) => {
					dispatch(
						addToast({
							id,
							siteId,
							type: 'error',
							title: t('toast.updateErrorTitle'),
							description: (error as FormError)?.data?.error ?? error?.error,
							error: error,
						})
					)
				})
		})
	}

	return (
		<Switch
			onChange={handleToggleActive}
			isChecked={active}
			isDisabled={isPending}
		/>
	)
}

export default RfFilterActiveToggleSwitch
