import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex, Heading } from '@chakra-ui/react'
import { MdOpenInNew } from 'react-icons/md'

import Button from '@UI/Button/Button'
import StatusIcon from '@UI/StatusIcon/StatusIcon'
import { Subheading } from '@UI/Headings/Headings'

import { useGetSiteLiveQuery, selectSmarthubs } from '@Store/sites/sitesWsApi'

import { sensorStatusColorMap, type SensorStatusColor } from '@Store/types'

import { openInNewTab } from '@Utils/navigation'
import useIpValidation from '@Hooks/useTestIp'
import { baseUrl, isProduction } from '@Constants/api'

const Smarthubs = ({ siteId }: { siteId: number }) => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'siteInstallations.smarthubs',
	})

	const [smarthubIp, setSmarthubsIp] = useState<string | undefined>()
	const { validateIps } = useIpValidation()

	const { smarthubs } = useGetSiteLiveQuery(siteId, {
		selectFromResult: ({ data }) => ({
			smarthubs: selectSmarthubs(data),
		}),
	})

	const hasSmarthubs = smarthubs && smarthubs.length > 0
	// Note: Handling one smarthub for now until we have the backend migrations
	useEffect(() => {
		const fetchSmarthubIps = async () => {
			try {
				const smarthubIp = `${isProduction ? '' : baseUrl}/smarthub`
				const validIp = await validateIps([smarthubIp])
				setSmarthubsIp(validIp || undefined)
			} catch (e) {
				console.error(e)
			}
		}
		fetchSmarthubIps()
	}, [smarthubs, validateIps])

	return (
		<>
			{hasSmarthubs &&
				smarthubs.map(({ serial, status, ip }, index) => (
					<Flex
						bgColor='body_bg'
						key={serial}
						justifyContent='space-between'
						alignItems='center'
						paddingBlock={4}
						paddingInline={4}
					>
						<Flex gap={2}>
							<StatusIcon
								color={sensorStatusColorMap[status as SensorStatusColor]}
								testId={`${serial}-smarthub-status-color`}
							/>
							<Flex direction='column' gap={1}>
								<Heading size='sm'>{serial}</Heading>
								{ip && <Subheading>{`(${ip})`}</Subheading>}
								<Subheading>{t('smarthub')}</Subheading>
							</Flex>
						</Flex>
						<Button
							label={t('open')}
							leftIcon={<MdOpenInNew size='16px' />}
							testId={`${serial}-open-smarthub`}
							color='button_text'
							_hover={{ bg: 'none' }}
							size='xs'
							isDisabled={!smarthubIp}
							tooltip={!smarthubIp ? t('unableToRedirect') : undefined}
							onClick={() => {
								smarthubIp && openInNewTab(smarthubIp)
							}}
						/>
					</Flex>
				))}
		</>
	)
}

export default Smarthubs
