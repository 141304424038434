// React Router
import Router from './Router'

// Translations
import I18nProvider from '@Components/App/I18nProvider'

// Alias
import usePinchProtector from '@Hooks/usePinchProtector'

// App HOC
const App = () => {
	usePinchProtector()

	return (
		<I18nProvider>
			<Router />
		</I18nProvider>
	)
}

// Export
export default App
