// Packages
import { useCallback, useEffect } from 'react'

const usePinchProtector = () => {
	const handleTouchMove = useCallback((e: TouchEvent) => {
		const target = e.target as HTMLElement
		const touches = e.touches.length
		const isTouchFromMap =
			(target.classList.contains('MapContainer') ||
				!!target?.closest('.MapContainer')) &&
			!target?.closest('.leaflet-control-container')

		if (isTouchFromMap && touches > 1) {
			// Pinch Detected but inside the map, allowed
			return
		}

		if (touches > 1) {
			// Pinch Detected, propagation stopped
			e.preventDefault()
			e.stopImmediatePropagation()
		}
	}, [])

	const handleMouseWheel = useCallback((e: MouseEvent) => {
		const target = e.target as HTMLElement
		const isTouchFromMap =
			target.classList.contains('MapContainer') ||
			!!target?.closest('.MapContainer')
		if (isTouchFromMap && e.ctrlKey) {
			// Pinch Detected but inside the map, allowed
			return
		}

		if (e.ctrlKey) {
			// Pinch Detected, propagation stopped
			e.preventDefault()
			e.stopImmediatePropagation()
		}
	}, [])

	useEffect(() => {
		window.addEventListener('touchmove', handleTouchMove, { passive: false })
		window.addEventListener('wheel', handleMouseWheel, { passive: false })

		return () => {
			window.removeEventListener('touchmove', handleTouchMove)
			window.removeEventListener('wheel', handleMouseWheel)
		}
	}, [handleTouchMove, handleMouseWheel])

	return undefined
}

export default usePinchProtector
