// Packages
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Flex, useTheme } from '@chakra-ui/react'
import { MdFilterAlt } from 'react-icons/md'

// Alias
import Button from '@UI/Button/Button'
import MultiSelect from '@UI/Select/MultiSelect'
import DebouncedInput from '@UI/DebouncedInput/DebouncedInput'
import { useGetDisruptionsSummaryQuery } from '@/store/analytics/analyticsApi'

// Types
import type { Table } from '@tanstack/react-table'
import type { Disruption } from '@Store/types'
import type { MultiSelectOption } from '@UI/Select/MultiSelect'
import type { DisruptionColumnType } from './columnDefinitions'

export const SelectWrapper = ({ children }: { children: React.ReactNode }) => (
	<Flex direction='column' flexGrow={1} maxW='250px'>
		{children}
	</Flex>
)

type FilterableColumn = {
	id: DisruptionColumnType
	title: string
	options: MultiSelectOption[]
	defaultValue?: MultiSelectOption[]
	placeholder?: string
	isMulti?: boolean
	isClearable?: boolean
}

type DisruptionFilterProps<TData> = {
	table: Table<TData>
	siteId: number
}

const DisruptionFilter = <TData = Disruption[],>({
	table,
	siteId,
}: DisruptionFilterProps<TData>) => {
	const {
		semanticTokens: { colors },
	} = useTheme()

	const { t } = useTranslation('pages', {
		keyPrefix: 'analytics.disruptionsSummary',
	})

	const { setGlobalFilter } = table
	const globalFilter = table.getState().globalFilter

	const { disruptors, disruptionMethods } = useGetDisruptionsSummaryQuery(
		{ siteId, format: 'filter_options' },
		{
			skip: !siteId,
			selectFromResult: ({ data }) => ({
				disruptors: data?.disruptor ?? [],
				disruptionMethods: data?.activated_manually ?? [],
			}),
		}
	)

	const disruptorsOptions = useMemo(
		() =>
			disruptors.map((disruptor) => ({
				label: disruptor.label,
				value: `${disruptor.value}`,
			})),
		[disruptors]
	)

	const disruptionMethodsOptions = useMemo(
		() =>
			disruptionMethods.map((disruptionMethod) => ({
				label: disruptionMethod.label,
				value: `${disruptionMethod.value}`,
			})),
		[disruptionMethods]
	)

	const filterableColumns: FilterableColumn[] = [
		{
			id: 'disruptor',
			title: t('table.effector'),
			options: disruptorsOptions,
			placeholder: t('placeholders.selectOption'),
		},
		{
			id: 'activated_manually',
			title: t('table.disruptionMethod'),
			options: disruptionMethodsOptions,
			placeholder: t('placeholders.selectOption'),
		},
	]

	const filterValue = (columnId: string) => {
		const filters = table.getColumn(columnId)?.getFilterValue() as string
		if (!filters || typeof filters !== 'string') {
			return undefined
		}

		const column = filterableColumns.find((column) => column.id === columnId)
		const filtersArr = filters.split(',')

		return column?.options.filter((option) =>
			filtersArr.includes(option.value as string)
		)
	}

	const handleFilterChange = (
		filterValues: MultiSelectOption[] | MultiSelectOption,
		type: DisruptionColumnType
	) => {
		if (Array.isArray(filterValues)) {
			const filters = filterValues.map((filter) => filter.value).join(',')
			table.getColumn(type)?.setFilterValue(filters)
		} else {
			table.getColumn(type)?.setFilterValue(filterValues?.value ?? '')
		}

		table.setPageIndex(0)
	}

	return (
		<Flex alignItems='flex-end' gap='10px' mb={2} flexWrap='wrap'>
			<DebouncedInput
				title={t('searchGlobal.title')}
				value={globalFilter ?? ''}
				onChange={(value) => setGlobalFilter(String(value))}
				placeholder={t('searchGlobal.placeholder')}
				data-testid='content-search'
				flexGrow={1}
				debounce={750}
			/>
			<Button
				size='xs'
				label='Filters'
				leftIcon={<MdFilterAlt />}
				testId='filters'
				pointerEvents='none'
				color={colors.label_color}
			/>
			{filterableColumns.map((column) => (
				<SelectWrapper key={column.id}>
					<MultiSelect
						title={column.title}
						placeholder={column.placeholder}
						options={column.options}
						isMulti={column.isMulti}
						isClearable={column.isClearable}
						defaultValue={column.defaultValue}
						value={filterValue(column.id) ?? null}
						onChange={(value: MultiSelectOption[] | MultiSelectOption) =>
							handleFilterChange(value, column.id)
						}
					/>
				</SelectWrapper>
			))}
			<Button
				size='xs'
				label={t('buttons.resetFilters')}
				testId='reset-filters'
				variant='link'
				onClick={() => table.resetColumnFilters()}
			/>
		</Flex>
	)
}

export default DisruptionFilter
