import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { MdAdd } from 'react-icons/md'
import Button from '@UI/Button/Button'

const NoteAdd = ({ siteId }: { siteId: number }) => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'notes.buttons',
	})

	const navigate = useNavigate()
	const handleClick = () => navigate(`/${siteId}/notes/add`)

	return (
		<Button
			leftIcon={<MdAdd />}
			testId='add-note-marker'
			label={t('add')}
			variant='outlined'
			onClick={handleClick}
			color='primary'
		/>
	)
}

export default NoteAdd
