// Packages
import { useCallback, useMemo, useEffect, useRef } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useTheme, useToast } from '@chakra-ui/react'
import { Trans, useTranslation } from 'react-i18next'

// Relatives
import {
	useCreateRecordingMutation,
	useDiscardRecordingMutation,
	useSaveActiveRecordingMutation,
} from '@/store/recordingTool/recordingToolApi'
import useScreenRecordingTool from './hooks/useScreenRecordingTool'
import useDuration from './hooks/useDuration'
import RecordingToolContext from './RecordingToolContext'
import { fetchFile } from '@/utils/download'
import {
	selectActiveRecordingLog,
	useGetSiteLiveQuery,
} from '@/store/sites/sitesWsApi'
import { selectUser } from '@/store/user/userSlice'
import { useAppSelector } from '@/store'

// Type
import type { ReactNode } from 'react'
import type { RecordingToolLog } from '@/store/types/recordingTool'

export type RecordingToolContextProviderProps = {
	children: ReactNode
}

const RecordingToolContextProvider = ({
	children,
}: RecordingToolContextProviderProps) => {
	const { t } = useTranslation('recordingTool', { keyPrefix: 'component' })

	const { siteId: siteIdParam } = useParams()
	const siteId = Number(siteIdParam)

	const { startScreenRecording, stopScreenRecording, isScreenRecording } =
		useScreenRecordingTool()
	const user = useAppSelector(selectUser)
	const toast = useToast()
	const {
		semanticTokens: {
			colors: { primary },
		},
	} = useTheme()

	const [createRecording] = useCreateRecordingMutation()
	const [saveActiveRecording] = useSaveActiveRecordingMutation()
	const [discardRecording] = useDiscardRecordingMutation()
	const { activeRecordingTool } = useGetSiteLiveQuery(siteId, {
		selectFromResult: ({ data }) => ({
			activeRecordingTool: selectActiveRecordingLog(data),
		}),
	})
	const recordingInstanceRef = useRef<RecordingToolLog | null | undefined>()
	recordingInstanceRef.current = activeRecordingTool

	// Only the user who started a recording can stop it
	const recordingOwner =
		(!!activeRecordingTool &&
			!!user &&
			activeRecordingTool?.initiating_user_id === user?.id) ||
		!activeRecordingTool

	// Duration Timer
	const [duration, durationStr, startDuration, stopDuration] = useDuration()

	useEffect(() => {
		if (activeRecordingTool === null) stopDuration()
		else if (activeRecordingTool?.created_at) startDuration()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeRecordingTool])

	const handleStartRecording = useCallback(
		async (description: string) => {
			createRecording({
				siteId,
				description,
			})
				.unwrap()
				.then(() => {
					startScreenRecording(description).catch((error) => {
						console.error('screen recording failed', error)
					})
				})
				.catch((error) => {
					console.error('system recording failed', error)
					toast({
						status: 'error',
						title: t('title'),
						description: t('notifications.inProgress'),
						duration: 2500,
						position: 'top-right',
						variant: 'left-accent',
					})
				})
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[startScreenRecording, createRecording, stopScreenRecording, siteId]
	)

	const handleStopRecording = useCallback(
		async () => {
			const tempRecordingInstance = recordingInstanceRef.current
			await stopScreenRecording().catch((e) => console.error(e))
			if (tempRecordingInstance && recordingOwner) {
				const {
					site_id: siteId,
					id: recordingId,
					description,
				} = tempRecordingInstance

				await saveActiveRecording({ siteId, recordingId })
					.unwrap()
					.then(async () => {
						const file = await fetchFile(
							`/api/sites/${siteId}/recording_tool/${recordingId}/download`
						)
						if (!file) {
							return
						}
						const url = window.URL.createObjectURL(file)
						const a = document.createElement('a')
						a.href = url
						a.download = `${description ?? 'recording_logs'}.zip`
						document.body.appendChild(a)
						a.click()
						window.URL.revokeObjectURL(url)

						toast({
							status: 'success',
							title: t('successTitle'),
							description: (
								<Trans
									t={t}
									i18nKey='notifications.success'
									components={[
										<Link
											key={1}
											style={{
												color: primary,
												fontWeight: 'bold',
												textDecoration: 'underline',
											}}
											to={`${siteId}/analytics#rawDetectionData`}
										/>,
									]}
								/>
							),
							duration: 2500,
							position: 'top-right',
							variant: 'left-accent',
						})
					})
			} else if (activeRecordingTool) {
				const { site_id: siteId, id: recordingId } = activeRecordingTool
				await discardRecording({ siteId, recordingId })
				toast({
					status: 'warning',
					title: t('title'),
					description: t('notifications.discard'),
					duration: 2500,
					position: 'top-right',
					variant: 'left-accent',
				})
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[stopScreenRecording, saveActiveRecording, activeRecordingTool]
	)

	const handleDiscardRecording = useCallback(
		async () => {
			const discard = true
			await stopScreenRecording(discard).catch((e) => console.log(e))
			if (recordingInstanceRef.current && recordingOwner) {
				const { site_id: siteId, id: recordingId } =
					recordingInstanceRef.current
				await discardRecording({ siteId, recordingId }).then(() => {
					toast({
						status: 'warning',
						title: t('title'),
						description: t('notifications.discard'),
						duration: 2500,
						position: 'top-right',
						variant: 'left-accent',
					})
				})
			} else if (activeRecordingTool) {
				const { site_id: siteId, id: recordingId } = activeRecordingTool
				await discardRecording({ siteId, recordingId })
				toast({
					status: 'warning',
					title: t('title'),
					description: t('notifications.discard'),
					duration: 2500,
					position: 'top-right',
					variant: 'left-accent',
				})
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[stopScreenRecording, saveActiveRecording, activeRecordingTool]
	)

	const data = useMemo(
		() => ({
			startRecording: handleStartRecording,
			stopRecording: handleStopRecording,
			discardRecording: handleDiscardRecording,
			isRecording: isScreenRecording || !!activeRecordingTool,
			isScreenRecording,
			recordingOwner,
			recordingEnabled:
				(!!activeRecordingTool && recordingOwner) || !activeRecordingTool,
			duration,
			durationStr,
		}),
		[
			handleStartRecording,
			handleStopRecording,
			handleDiscardRecording,
			isScreenRecording,
			recordingOwner,
			duration,
			durationStr,
			activeRecordingTool,
		]
	)

	return (
		<RecordingToolContext.Provider value={data}>
			{children}
		</RecordingToolContext.Provider>
	)
}

export default RecordingToolContextProvider
