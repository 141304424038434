import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useAuth from '@/hooks/useAuth'
import {
	useDeleteGenericSensorMutation,
	useToggleGenericSensorMutation,
} from '@Store/genericSensors/genericSensorsApi'
import type { SiteInstallation } from '@Store/types'
import {
	Box,
	Card,
	CardBody,
	CardHeader,
	Flex,
	Spacer,
	useDisclosure,
} from '@chakra-ui/react'
import EditButton from '../../EditButton'
import Chunk from '../Chunk'
import ActionSection from '../../ActionSection'
import EditPalette from '../../EditPalette'
import DeleteModal from '@/components/_UI/Modals/DeleteModal'
import DeviceHeader from '../DeviceHeader'
import ActiveSensorButton from '../../ActiveSensorButton'
import { formatDirection } from '@/utils/formatUtils'
import { DEVICE_FAULT_STATE } from '@/constants/devices'

type GenericSensorContentProps = {
	devices: SiteInstallation['generic_sensors']
}

const GenericSensorContent = ({ devices }: GenericSensorContentProps) => {
	const { siteId: siteIdParam, installationId } = useParams()
	const siteId = Number(siteIdParam)

	const { t } = useTranslation('panels', {
		keyPrefix: 'devicesContent',
	})

	const baseRoute = `/${siteId}/installation/${installationId}/generic_sensor`

	const [genericSensorToDelete, setGenericSensorToDelete] = useState<{
		id: number
		name: string
	} | null>(null)

	const { isOpen, onOpen, onClose } = useDisclosure()
	const [toggleGenericSensor] = useToggleGenericSensorMutation()
	const [deleteGenericSensor] = useDeleteGenericSensorMutation()

	const handleOpenDeleteModal = (id: number, name: string) => {
		onOpen()
		setGenericSensorToDelete((prev) => ({ ...prev, id, name }))
	}

	const handleToggleActive = async (sensorId: number, active: boolean) => {
		try {
			await toggleGenericSensor({ siteId, sensorId, active: active }).unwrap()
		} catch (error) {
			console.error('Toggle generic sensor error', error)
		}
	}

	const handleDeleteGenericSensor = async () => {
		try {
			if (genericSensorToDelete) {
				await deleteGenericSensor({
					siteId,
					sensorId: genericSensorToDelete.id,
				}).unwrap()
				onClose()
			}
		} catch (error) {
			console.error('Delete generic sensor error', error)
		}
	}

	const handleCloseDeleteModal = () => {
		onClose()
		setGenericSensorToDelete(null)
	}

	const { isSiteManager } = useAuth()

	return (
		<>
			<Card variant='devices'>
				{isSiteManager && (
					<CardHeader textAlign='right'>
						{/* 
						REVIEW: There's no endpoint to restart a generic sensor?
						<EditButton
							to={`${baseRoute}/generic-sensor/restart`}
							type='restart'
							label={t('buttons.restart')}
							testId='restart-generic-sensors'
						/> */}
						<Spacer />
						<EditButton
							to={`${baseRoute}/add`}
							type='add'
							label={t('buttons.add')}
							testId='add-generic-sensor'
						/>
					</CardHeader>
				)}
				<CardBody>
					{devices.map((device) => (
						<Box key={device.id} paddingBlockEnd={4}>
							<Flex paddingInlineStart={2}>
								<DeviceHeader device={device} deviceType='generic' />
								<ActionSection>
									<ActiveSensorButton deviceId={device.id} />
									{isSiteManager && (
										<EditPalette testId={`device-menu-${device.name}`}>
											<EditButton
												to={`${baseRoute}/${device.id}/settings`}
												label={t('buttons.edit')}
												testId='edit-device'
												asMenuListItem
											/>
											<EditButton
												onClick={() =>
													handleToggleActive(
														device.id,
														device.active ? false : true
													)
												}
												type={device.active ? 'disable' : 'enable'}
												label={
													device.active
														? t('buttons.disableDetections')
														: t('buttons.enableDetections')
												}
												disabled={
													device.status_color === 'red' &&
													device.status !== DEVICE_FAULT_STATE
												}
												testId={`disable-detections-${device.name}`}
												asMenuListItem
											/>
											<EditButton
												onClick={() =>
													handleOpenDeleteModal(device.id, device.name)
												}
												label={t('buttons.delete')}
												type='delete'
												testId='delete-device'
												asMenuListItem
											/>
										</EditPalette>
									)}
								</ActionSection>
							</Flex>
							<Flex flexWrap='wrap' paddingInlineStart={8}>
								<Chunk
									units='deg'
									label={t('content.direction')}
									value={formatDirection(device.direction) ?? '-'}
									testId='direction'
								/>
								<Chunk
									label={t('content.status')}
									value={`${device.status}`}
									testId='status'
								/>
								<Chunk
									label={t('content.serial')}
									value={device.serial_number ?? '-'}
									testId='serial-number'
								/>
							</Flex>
						</Box>
					))}
				</CardBody>
			</Card>

			<DeleteModal
				isOpen={isOpen}
				onClose={handleCloseDeleteModal}
				handleDelete={handleDeleteGenericSensor}
				name={genericSensorToDelete?.name || ''}
			/>
		</>
	)
}

export default GenericSensorContent
