//
// Note: Is it worth migrating these to convert-units 'Angle' system?

export const convertDegreeToRad = (degrees: number) => degrees * (Math.PI / 180)

export const convertRadToDegree = (radian: number) => radian * (180 / Math.PI)

export const convertHzToGHz = (hz: number) => hz / 1e9
export const convertGHzToHz = (hz: number) => hz * 1e9

export const formatHz = (hz: number): string => {
	if (!+hz) return '0 Hz'

	const k = 1_000
	const bands = ['Hz', 'KHz', 'MHz', 'GHz', 'THz']

	const i = Math.floor(Math.log(hz) / Math.log(k))
	return `${parseFloat((hz / Math.pow(k, i)).toFixed(2))} ${bands[i]}`
}

export const convertDegreesToMils = (degrees: number) => {
	return (degrees * (6400 / 360)).toFixed(0)
}

export const convertDegreesToCardinal = (angle: number) => {
	const index = Math.round(angle / 45) % 8
	return ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'][index]
}

export const convertDegreesToCardinalWithPointer = (angle: number) => {
	const index = Math.round(angle / 45) % 8
	return ['↑ N', '↗ NE', '→ E', '↘ SE', '↓ S', '↙ SW', '← W', '↖ NW'][index]
}

export const convertDegreesToCardinal16 = (angle: number) => {
	const index = Math.round(angle / 22.5) % 16
	return [
		'N',
		'NNE',
		'NE',
		'ENE',
		'E',
		'ESE',
		'SE',
		'SSE',
		'S',
		'SSW',
		'SW',
		'WSW',
		'W',
		'WNW',
		'NW',
		'NNW',
	][index]
}

export const convertRadtToCardinal = (radian: number) =>
	convertDegreesToCardinal(convertRadToDegree(radian))

export const convertRadToCardinalWithPointer = (radian: number) =>
	convertDegreesToCardinalWithPointer(convertRadToDegree(radian))
