import { format, formatInTimeZone, utcToZonedTime } from 'date-fns-tz'
import enAU from 'date-fns/locale/en-AU'
import { subDays } from 'date-fns'

export const todaySiteDate = (date: string) =>
	format(new Date(date), 'yyyy-MM-dd HH:mm')

export const formatSiteTime = (timeZone: string) => {
	return formatInTimeZone(new Date(), timeZone, 'yyyy-MM-dd HH:mm', {
		locale: enAU,
	})
}

export const yesterdaySiteDate = (date: string) => {
	const parsedDate = new Date(date)
	const yesterday = subDays(parsedDate, 1)
	return format(yesterday, 'yyyy-MM-dd HH:mm')
}

// WARNING this function will always resolve to browser's timezone
export const formatDate = (date: string | number) => {
	console.warn('formatDate function defaults to browser timezone')
	const newDate = new Date(date)
	return format(newDate, 'yyyy-MM-dd HH:mm')
}

// @todo: this can be removed after backend fix the format for the dates
export const formatDateStrTz = (date: string, format = 'datetime') => {
	if (format === 'datetime') {
		return date.replace(/^([\d-]+)T([\d:]+).+$/, '$1 $2')
	}

	return date.replace(/^([\d-]+)T([\d:]+).+$/, '$1')
}

// See: https://pkg.go.dev/time#Time
const ZERO_DATE = '0001-01-01T00:00:00Z'

export const formatDateTz = (date: string, timeZone: string) => {
	if (date === ZERO_DATE) return '-'
	const newDate = Date.parse(date)
	return formatInTimeZone(newDate, timeZone, 'yyyy-MM-dd HH:mm', {
		locale: enAU,
	})
}

export const formatDateWithMonth = (date: string | number) => {
	const newDate = new Date(date)
	return format(newDate, 'dd MMMM yyyy')
}

export const formatDateTime = (date: string | number) => {
	const newDate = new Date(date)
	return format(newDate, 'yyyy-MM-dd HH:mm:ss')
}

export const getDate = (date: string | number) => {
	const newDate = new Date(date)
	return format(newDate, 'yyyy-MM-dd')
}

export const getTime = (date: string | number) => {
	const newDate = new Date(date)
	return format(newDate, 'HH:mm:ss z')
}

export const getMilliseconds = (date: string | number) =>
	new Date(date).getTime()

export const getMillisecondsBetween = (startTime: string, endTime: string) => {
	const start = getMilliseconds(startTime)
	const end = getMilliseconds(endTime)

	return Math.abs(start - end)
}

export const getTimeZone = (date: string | number, timezone: string) => {
	const newDate = new Date(date)

	return formatInTimeZone(newDate, timezone, 'z', {
		locale: enAU,
	})
}

export const getTimeZoneDate = (date: string | number, timeZone: string) => {
	const zonedDate = utcToZonedTime(date, timeZone)

	return format(zonedDate, 'yyyy-MM-dd HH:mm', { timeZone })
}

export const getSecondsBetween = (startTime: string, endTime: string) => {
	return getMillisecondsBetween(startTime, endTime) / 1000
}

export const getTimeZoneDateFromUTC = (date: string, timeZone: string) => {
	// Convert the UTC date to the local time zone
	// Append 'Z' to indicate UTC time
	const zonedDate = utcToZonedTime(date + 'Z', timeZone)
	const convertedDate = format(zonedDate, 'yyyy-MM-dd HH:mm', {
		timeZone,
		locale: enAU,
	})
	// Return the string format "2024-04-11 08:49" which is required for server request
	return convertedDate
}

export const formatDateInProvidedFormat = (
	date: Date,
	timeZone: string,
	format: string
) => {
	return formatInTimeZone(date, timeZone, format, {
		locale: enAU,
	})
}

const formatTimeOnTargetLocale = {
	xMinutes: '{{count}}m',
	xSeconds: '{{count}}s',
}

export const formatTimeOnTargetLocaleFn = {
	formatDistance: (
		token: keyof typeof formatTimeOnTargetLocale,
		count: string
	) => formatTimeOnTargetLocale[token].replace('{{count}}', count),
}

const formatElapsedTimeLocale: { [key: string]: string } = {
	lessThanXSeconds: '{{count}}s',
	xSeconds: '{{count}}s',
	halfAMinute: '30s',
	lessThanXMinutes: '~{{count}}m',
	xMinutes: '{{count}}m',
	aboutXHours: '{{count}}h',
	xHours: '{{count}}h',
	xDays: '{{count}}d',
	aboutXWeeks: '{{count}}w',
	xWeeks: '{{count}}w',
	aboutXMonths: '{{count}}m',
	xMonths: '{{count}}m',
	aboutXYears: '{{count}}y',
	xYears: '{{count}}y',
	overXYears: '{{count}}y',
	almostXYears: '{{count}}y',
}

export const formatElapsedTimeLocaleFn = (
	token: string,
	count: string,
	options: { addSuffix?: boolean; comparison?: number } = {}
) => {
	const result = formatElapsedTimeLocale[token].replace('{{count}}', count)
	if (options.addSuffix) {
		if (typeof options.comparison === 'number' && options.comparison > 0) {
			return `in ${result}`
		}

		return `${result} ago`
	}

	return result
}
