const SoteriaLogo = () => {
	return (
		<svg
			width='1071'
			height='1071'
			viewBox='0 0 1071 1071'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M491.35 48.62C592.42 -16.06 668.81 16.74 696.22 27.22C901.67 105.79 828.14 330.46 730.78 447.1C579.7 628.09 85.65 982.05 23.88 574.9C236.18 627.55 416.31 515.81 587 311.42C728.65 141.73 644.13 15.23 433.4 82.89L491.35 48.62Z'
				fill='url(#paint0_radial_0_5)'
			/>
			<path
				d='M856.68 105.79C1140.45 470.5 511.51 928 202.09 891.87C399.17 1071.41 627.49 953.87 807.62 791.87C1083.28 544.16 1108.45 302.87 856.68 105.77V105.79Z'
				fill='url(#paint1_radial_0_5)'
			/>
			<path
				d='M1071 574.91C972.09 855.68 778.77 1031.16 469 1065.6C803 1107.92 1054 847.49 1071 574.91Z'
				fill='url(#paint2_radial_0_5)'
			/>
			<path
				d='M489.42 61.43C363.3 213 821.19 727 1071 574.91C1026.81 1146.54 -97.72 126.48 435.94 9.76997C435.94 9.76997 550.14 -14.41 651.39 13.31C651.39 13.31 525.81 17.69 489.42 61.43Z'
				fill='url(#paint3_radial_0_5)'
			/>
			<path
				d='M238.92 91.12C2.43997 395.11 537.45 913 940.86 885.89C781 1089.48 476.4 955.17 293.09 790.49C17.43 542.84 -30.12 274 238.92 91.12Z'
				fill='url(#paint4_radial_0_5)'
			/>
			<path
				d='M10.65 441.25C41.86 786.63 256.27 1040.51 646.41 1059.07C220.51 1137.77 -59.09 755.37 10.65 441.25Z'
				fill='url(#paint5_radial_0_5)'
			/>
			<defs>
				<radialGradient
					id='paint0_radial_0_5'
					cx='0'
					cy='0'
					r='1'
					gradientUnits='userSpaceOnUse'
					gradientTransform='translate(425.7 386.27) scale(389.46 389.46)'
				>
					<stop stopColor='#C8001E' stopOpacity='0' />
					<stop offset='0.85' stopColor='#AA0000' />
				</radialGradient>
				<radialGradient
					id='paint1_radial_0_5'
					cx='0'
					cy='0'
					r='1'
					gradientUnits='userSpaceOnUse'
					gradientTransform='translate(616.5 543.92) scale(426.44 426.44)'
				>
					<stop stopColor='#C8001E' stopOpacity='0' />
					<stop offset='0.85' stopColor='#AA0000' />
				</radialGradient>
				<radialGradient
					id='paint2_radial_0_5'
					cx='0'
					cy='0'
					r='1'
					gradientUnits='userSpaceOnUse'
					gradientTransform='translate(770 822.52) scale(275.6 275.6)'
				>
					<stop stopColor='#C8001E' stopOpacity='0' />
					<stop offset='0.85' stopColor='#AA0000' />
				</radialGradient>
				<radialGradient
					id='paint3_radial_0_5'
					cx='0'
					cy='0'
					r='1'
					gradientUnits='userSpaceOnUse'
					gradientTransform='translate(681.77 373.84) scale(381.61 381.61)'
				>
					<stop stopColor='#FF4C4C' stopOpacity='0.5' />
					<stop offset='0.19' stopColor='#F5323C' stopOpacity='0.67' />
					<stop offset='0.39' stopColor='#EC1C2F' stopOpacity='0.81' />
					<stop offset='0.59' stopColor='#E60D26' stopOpacity='0.92' />
					<stop offset='0.8' stopColor='#E20320' stopOpacity='0.98' />
					<stop offset='1' stopColor='#E1001E' />
				</radialGradient>
				<radialGradient
					id='paint4_radial_0_5'
					cx='0'
					cy='0'
					r='1'
					gradientUnits='userSpaceOnUse'
					gradientTransform='translate(500.41 539.93) scale(444.65 444.65)'
				>
					<stop stopColor='#FF4C4C' stopOpacity='0.5' />
					<stop offset='0.19' stopColor='#F5323C' stopOpacity='0.67' />
					<stop offset='0.39' stopColor='#EC1C2F' stopOpacity='0.81' />
					<stop offset='0.59' stopColor='#E60D26' stopOpacity='0.92' />
					<stop offset='0.8' stopColor='#E20320' stopOpacity='0.98' />
					<stop offset='1' stopColor='#E1001E' />
				</radialGradient>
				<radialGradient
					id='paint5_radial_0_5'
					cx='0'
					cy='0'
					r='1'
					gradientUnits='userSpaceOnUse'
					gradientTransform='translate(321.508 753.821) rotate(-2) scale(318.71 318.71)'
				>
					<stop stopColor='#FF4C4C' stopOpacity='0.5' />
					<stop offset='0.19' stopColor='#F5323C' stopOpacity='0.67' />
					<stop offset='0.39' stopColor='#EC1C2F' stopOpacity='0.81' />
					<stop offset='0.59' stopColor='#E60D26' stopOpacity='0.92' />
					<stop offset='0.8' stopColor='#E20320' stopOpacity='0.98' />
					<stop offset='1' stopColor='#E1001E' />
				</radialGradient>
			</defs>
		</svg>
	)
}

export default SoteriaLogo
