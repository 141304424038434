// Packages
import { format } from 'date-fns'

export const dateHasTime = (date: string) =>
	!!date.match(/[0-9]{2}:[0-9]{2}/gim)

export const isValidDate = (
	dateStr: string,
	min?: string,
	max?: string
): boolean => {
	if (!dateStr) {
		return true
	}

	const date = new Date(dateStr)
	if (date.toString() === 'Invalid Date') {
		return false
	}

	if (min) {
		if (!dateHasTime(min)) {
			min = `${min} 00:00`
		}

		const minDate = new Date(min)
		if (minDate > date) {
			return false
		}
	}

	if (max) {
		if (!dateHasTime(max)) {
			min = `${min} 23:59`
		}

		const maxDate = new Date(max)
		if (maxDate < date) {
			return false
		}
	}

	return true
}

export const parseDate = (
	value: string | Date = '',
	dateFormat: string = 'yyyy-MM-dd HH:mm'
) => {
	let valueDate = value
	if (typeof valueDate === 'string') {
		valueDate = new Date(valueDate)
		if (valueDate.toString() === 'Invalid Date') {
			return undefined
		}
	}

	return {
		date: valueDate,
		dateStr: format(valueDate, dateFormat),
		year: valueDate.getFullYear(),
		month: valueDate.getMonth() + 1,
		day: valueDate.getDate(),
		hour: valueDate.getHours(),
		minute: valueDate.getMinutes(),
	}
}
