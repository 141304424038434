// React
import { useTranslation } from 'react-i18next'
import { nanoid } from '@reduxjs/toolkit'
// Chakra
import { Box, Flex } from '@chakra-ui/react'
import { MdDelete } from 'react-icons/md'
// Components
import IconButton from '@UI/IconButton/IconButton'
import Tooltip from '@UI/Tooltip/Tooltip'
// Store
import { useGetSitesListQuery } from '@Store/sites/sitesApi'
import type { UserSiteAssociation } from '@Store/types'
// Constants
const MAX_SITE_TITLE_LENGTH = 22

const UserSiteListAssociation = ({
	userSiteList,
	handleDelete,
}: {
	userSiteList: UserSiteAssociation[]
	handleDelete: (id: number) => void
}) => {
	const { t } = useTranslation('forms', { keyPrefix: 'userForm' })
	const { data: sites } = useGetSitesListQuery()
	const roles = Array(
		t('selects.siteUserRoleOptions', {
			returnObjects: true,
		})
	).flat() as { label: string; value: string }[]

	if (!userSiteList.length) return null

	return (
		<>
			{userSiteList?.map(({ site_id, role }) => {
				const siteName = sites?.find((site) => site.id === site_id)?.name
				const siteRole = roles?.find((r) => r?.value === role)?.label
				return (
					<Flex key={nanoid()}>
						<Tooltip
							label={
								siteName && siteName?.length > MAX_SITE_TITLE_LENGTH
									? siteName
									: undefined
							}
						>
							<Box
								flex={1}
								fontSize='sm'
								px={2}
								maxW={`${MAX_SITE_TITLE_LENGTH}ch`}
								isTruncated
							>
								{siteName}
							</Box>
						</Tooltip>
						<Box flex={1} fontSize='sm' px={2}>
							{siteRole}
						</Box>
						<Box w='78px' textAlign='center'>
							<IconButton
								testId={`delete-site-${site_id}`}
								aria-label='delete'
								icon={<MdDelete />}
								onClick={() => handleDelete(site_id)}
							/>
						</Box>
					</Flex>
				)
			})}
		</>
	)
}

export default UserSiteListAssociation
