import type { LatLngExpression, LatLngLiteral } from 'leaflet'

import Marker from '../index'
import SentryMarkerIcon from './SentryMarkerIcon'
import MarkerPopup from '@UI/MarkerPopup/MarkerPopup'

import { COLORS } from '@Constants/colors'
import type { SiteInstallationType } from '@Store/types'
import type { StatusColor } from '@Store/types'

type SentryMarkerProps = {
	name: string
	position: LatLngExpression
	sentryType: SiteInstallationType
	iconColor?: StatusColor
	isSelected?: boolean
	onDragEnd?: (position: LatLngLiteral) => void
	draggable?: boolean
	onClick?: () => void
}

const ICON_ANCHOR = [25, 50]

const SentryMarker = ({
	name,
	position,
	sentryType,
	iconColor,
	isSelected,
	draggable,
	onClick,
	onDragEnd,
}: SentryMarkerProps) => {
	// TODO: add logic for colours using constants/colors
	// ignore this logic for now - it's just here so we can see
	// what things look like in storybook
	let fill = COLORS.GREY
	if (iconColor === 'green') fill = COLORS.GREEN
	else if (iconColor === 'red') fill = COLORS.RED
	else if (iconColor === 'orange') fill = COLORS.ORANGE

	return (
		<Marker
			// TODO: transition: 'transform ease-in 0.25s'
			position={position}
			onClick={onClick}
			onDragEnd={onDragEnd}
			draggable={draggable}
			icon={
				<SentryMarkerIcon
					fill={fill}
					sentryType={sentryType}
					isSelected={isSelected}
				/>
			}
			iconAnchor={ICON_ANCHOR}
			alt='Sentry Marker'
		>
			<MarkerPopup title={name} />
		</Marker>
	)
}

export default SentryMarker
