// React
import { memo, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
// Chakra
import { Flex } from '@chakra-ui/react'
// Components
import Field from '@Components/FormElements'
import Headings from '@UI/Headings/Headings'
import FetchError from '@UI/FetchError/FetchError'
import { FormButtons } from '@Components/FormElements/FormButtons/FormButtons'
import RfFilterFormBearing from '@Forms/RfFilterForm/RfFilterFormBearing'
import RfFilterFormFrequency from '@Forms/RfFilterForm/RfFilterFormFrequency'
import FormHeader from '@Forms/FormHeader'
import ErrorBoundary from '@Components/App/ErrorHandling/ErrorBoundary'
// ZOD
import { zodResolver } from '@hookform/resolvers/zod'
import type { TypeOf } from 'zod'
import rfFiltersFormSchema, {
	ADD_FILTER_DEFAULT_VALUES,
	type DefaultRfFilterValuesType,
} from '@Forms/RfFilterForm/RfFiltersForms.schema'
// Store
import { useGetRfSensorQuery } from '@Store/rfSensors/rfSensorsApi'
import {
	useCreateRfSensorFilterMutation,
	useGetRfSensorFiltersQuery,
	useUpdateRfSensorFilterMutation,
} from '@Store/rfFilters/rfFiltersApi'
// Types
import type { RfSensor, RfSensorFilter } from '@Store/types'
// Hooks
import useRfFilterFormOptions from '@Forms/RfFilterForm/_hooks/useRfFilterFormOptions'
// Utils
import { convertGHzToHz } from '@Utils/mathUtils'
import FormWrapper from '@Components/FormElements/FormWrapper/FormWrapper'
import { updateSensorPreview } from '@Store/ui/uiSlice'
import { useAppDispatch } from '@/store'
import RfFilterFormVendorProtocol from '@Forms/RfFilterForm/RfFilterFormVendorProtocol'
import { setFormError } from '../formHelper'
import FormStatusErrors from '@/components/FormElements/FormStatusErrors/FormStatusErrors'

const Form = ({
	defaultValues,
	isEditForm,
	sensor,
	isQuickAction,
}: {
	defaultValues: DefaultRfFilterValuesType
	isEditForm: boolean
	sensor: RfSensor
	isQuickAction: boolean
}) => {
	// Translations
	const { t } = useTranslation('forms', { keyPrefix: 'rfFiltersForm' })
	const navigate = useNavigate()
	const { siteId, sensorId, installationId } = useParams()
	const { search } = useLocation()
	const isWhiteList = search.includes('whitelist')

	type Schema = TypeOf<typeof rfFiltersFormSchema>

	const methods = useForm<Schema>({
		resolver: zodResolver(rfFiltersFormSchema),
		defaultValues,
	})

	const isDsx = sensor?.model.includes('dsx')

	const locationTab = isWhiteList ? '#whitelist_tab' : '#filter_tab'
	const rfFiltersRoute = `/${siteId}/installation/${installationId}/${
		isDsx ? 'rf_sensors_dsx' : 'rf_sensors'
	}/${sensorId}/rf_filters${locationTab}`

	const [updateRfSensorFilter] = useUpdateRfSensorFilterMutation()
	const [createRfSensorFilter] = useCreateRfSensorFilterMutation()

	const {
		register,
		formState: { errors, isSubmitting, isDirty },
		handleSubmit,
		setValue,
		setError,
		watch,
	} = methods

	const {
		frequency_band,
		frequency,
		frequency_tolerance,
		vendor,
		protocol,
		bearing,
		bearing_tolerance,
	} = watch()
	// Control switchers
	const [isFilterByFrequency, setFilterByFrequency] = useState<boolean>(
		!!frequency_band || !!frequency
	)
	const [isFilterByBearing, setFilterByBearing] = useState<boolean>(
		!!bearing || !!bearing_tolerance
	)
	const [isFilterByVendorAndProtocol, setFilterByVendorAndProtocol] =
		useState<boolean>(!!vendor || !!protocol)

	const isSaveButtonDisabled =
		isFilterByFrequency || isFilterByBearing || isFilterByVendorAndProtocol

	const handleCancel = () => navigate(rfFiltersRoute)

	// Format data object before send
	const formatDataWithOffSwitchers = (
		data: RfSensorFilter,
		isFilterByFrequency: boolean,
		isFilterByBearing: boolean,
		isFilterByVendorAndProtocol: boolean
	) => {
		return {
			...data,
			frequency_band: !isFilterByFrequency
				? ADD_FILTER_DEFAULT_VALUES.frequency_band
				: data.frequency_band,
			frequency: !isFilterByFrequency
				? ADD_FILTER_DEFAULT_VALUES.frequency
				: data.frequency,
			frequency_tolerance: !isFilterByFrequency
				? ADD_FILTER_DEFAULT_VALUES.frequency_tolerance
				: data.frequency_tolerance,
			bearing:
				!isFilterByBearing || !showFilterByBearing
					? ADD_FILTER_DEFAULT_VALUES.bearing
					: data.bearing,
			bearing_tolerance:
				!isFilterByBearing || !showFilterByBearing
					? ADD_FILTER_DEFAULT_VALUES.bearing_tolerance
					: data.bearing_tolerance,
			vendor: !isFilterByVendorAndProtocol
				? ADD_FILTER_DEFAULT_VALUES.vendor
				: data.vendor,
			protocol: !isFilterByVendorAndProtocol
				? ADD_FILTER_DEFAULT_VALUES.protocol
				: data.protocol,
		}
	}

	const handleSave = async (data: RfSensorFilter) => {
		// Check if frequency and frequency_tolerance values are in Hz
		const { frequency, frequency_tolerance } = data
		const isFrequencyInHZ = String(frequency).length >= 9
		const isFrequencyToleranceInHZ = String(frequency).length >= 7
		// Check if switchers off then erase the data
		const formattedFrequency = {
			...data,
			frequency: isFrequencyInHZ ? frequency : convertGHzToHz(frequency),
			frequency_tolerance: isFrequencyToleranceInHZ
				? frequency_tolerance
				: convertGHzToHz(frequency_tolerance),
		}
		const formattedData = formatDataWithOffSwitchers(
			formattedFrequency,
			isFilterByFrequency,
			isFilterByBearing,
			isFilterByVendorAndProtocol
		) as RfSensorFilter
		try {
			const updatedRfFilter: RfSensorFilter = {
				...formattedData,
			}
			if (isEditForm) {
				await updateRfSensorFilter({
					siteId: Number(siteId),
					rfFilterId: formattedData.id,
					rf_filter: updatedRfFilter,
				}).unwrap()
			} else {
				await createRfSensorFilter({
					siteId: Number(siteId),
					rf_filter: { ...updatedRfFilter, rf_sensor_ids: [Number(sensorId)] },
				}).unwrap()
			}
			navigate(rfFiltersRoute)
		} catch (error) {
			setFormError<Schema>({ error, setError })
		}
	}

	const supportedDetections = sensor?.supported_detections
	const {
		bandOptions,
		defaultBandOption,
		defaultVendorOption,
		defaultProtocolOption,
	} = useRfFilterFormOptions({
		supportedDetections,
		defaultValues,
		frequencyBand: frequency_band,
		vendor,
	})
	const formTitle = useMemo(() => {
		if (isEditForm) {
			return isWhiteList ? t('editWhitelist') : t('editFilter')
		} else {
			return isWhiteList ? t('addNewWhitelist') : t('addNewFilter')
		}
	}, [isEditForm, isWhiteList, t])

	useEffect(() => {
		setValue('filterType', isWhiteList ? 'whitelist' : 'rf_filter')
		if (!isEditForm && !isQuickAction) {
			setValue('bearing', 0)
			setValue('bearing_tolerance', 10)
		}
	}, [isWhiteList, setValue, isEditForm, isQuickAction])

	const isRfPatrol = sensor?.model === 'rf_patrol'
	const showFilterByBearing = isFilterByBearing && !isWhiteList && !isRfPatrol

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(handleSave)}>
				<FormHeader title={formTitle} backRoute={rfFiltersRoute} />
				<FormStatusErrors errors={errors} />
				<Headings.SensorHeading title={sensor ? sensor.name : ''} />
				<Headings.FieldHeading
					title={isWhiteList ? t('whitelistRfByParams') : t('filterRfByParams')}
				/>
				<Field.TextInput
					title={t('filterName')}
					register={register('name')}
					error={errors?.name?.message}
					testId='filter-name'
				/>

				<Flex mt={2}>
					<Field.Switch
						id='by_frequency'
						title={
							isWhiteList ? t('whitelistByFrequency') : t('filterByFrequency')
						}
						onChange={() => {
							setFilterByFrequency((prev) => !prev)
							setValue('frequency_band', frequency_band, {
								shouldDirty: true,
							})
							setValue('frequency_tolerance', frequency_tolerance, {
								shouldDirty: true,
							})
							setValue('frequency', frequency, {
								shouldDirty: true,
							})
						}}
						defaultChecked={isFilterByFrequency}
						testId='filter-by-frequency'
					/>
				</Flex>
				{isFilterByFrequency && (
					<RfFilterFormFrequency
						bandOptions={bandOptions}
						defaultBandOption={defaultBandOption}
						setValue={setValue}
						frequencyBand={frequency_band}
						frequency={frequency ? frequency : 0}
						frequency_tolerance={frequency_tolerance ? frequency_tolerance : 0}
						isFilterByFrequency={isFilterByFrequency}
						defaultValues={defaultValues}
						register={register}
						errors={errors}
					/>
				)}

				{!isWhiteList && !isRfPatrol && (
					<Field.Switch
						id='by_bearing'
						title={t('filterByBearing')}
						onChange={() => {
							setFilterByBearing((prev) => !prev)
							setValue('bearing', bearing, { shouldDirty: true })
							setValue('bearing_tolerance', bearing_tolerance, {
								shouldDirty: true,
							})
						}}
						defaultChecked={isFilterByBearing}
						testId='filter-by-bearing'
					/>
				)}
				{showFilterByBearing && (
					<RfFilterFormBearing
						setValue={setValue}
						bearing={bearing as number}
						bearing_tolerance={bearing_tolerance as number}
						isFilterByBearing={isFilterByBearing}
						defaultValues={defaultValues}
						register={register}
						errors={errors}
					/>
				)}

				<Field.Switch
					id='by_vendor_and_protocol'
					title={
						isWhiteList
							? t('whitelistByVendorAndProtocol')
							: t('filterByVendorAndProtocol')
					}
					onChange={() => {
						setFilterByVendorAndProtocol((prev) => !prev)
						setValue('vendor', vendor || defaultVendorOption?.value, {
							shouldDirty: true,
						})
						setValue('protocol', protocol || defaultProtocolOption?.value, {
							shouldDirty: true,
						})
					}}
					defaultChecked={isFilterByVendorAndProtocol}
					testId='filter-by-vendor-and-protocol'
				/>
				{isFilterByVendorAndProtocol && (
					<RfFilterFormVendorProtocol
						isFilterByFrequency={isFilterByFrequency}
						isFilterByVendorAndProtocol={isFilterByVendorAndProtocol}
						errors={errors}
						defaultValues={defaultValues}
						frequencyBand={frequency_band}
						supportedDetections={supportedDetections}
						vendor={vendor}
					/>
				)}

				<FormButtons
					isSubmitting={isSubmitting}
					isDirty={(isDirty && isSaveButtonDisabled) || isQuickAction}
					handleCancel={handleCancel}
				/>
			</form>
		</FormProvider>
	)
}

const RfFilterForm = ({
	siteId,
	filterId,
	sensorId,
}: {
	siteId: number
	filterId?: number
	sensorId: number
}) => {
	// Translations
	const { t } = useTranslation('forms', { keyPrefix: 'rfFiltersForm' })
	const { t: tSensor } = useTranslation('forms', { keyPrefix: 'rfSensorForm' })
	// Filter Query
	const {
		isLoading: isFilterLoading,
		isError: isFilterError,
		isSuccess: isFiltersSuccess,
		data: rfFilters,
		refetch,
	} = useGetRfSensorFiltersQuery(
		{ siteId, sensorId },
		{ refetchOnMountOrArgChange: true }
	)
	// RF Sensor Query
	const {
		data: sensor,
		isLoading: isSensorLoading,
		isError: isSensorError,
		isSuccess: isSensorSuccess,
		refetch: sensorRefetch,
	} = useGetRfSensorQuery({ siteId, sensorId }, { skip: !siteId || !sensorId })
	// Looks for props passed through from navigate() via quick actions
	const { search, pathname, state } = useLocation()
	// Single RF filter get fetch query has NULL as rf_sensor_ids[] array. Logic borrowed from VUE.
	const rfFilter = rfFilters?.find((filter) => filter.id === filterId)

	const isWhiteList = search.includes('whitelist')
	const isEditForm = pathname.includes('settings')
	const isQuickAction = !!state?.rfFilterState

	const addFilterDefaultValues = {
		...ADD_FILTER_DEFAULT_VALUES,
		whitelist: isWhiteList,
		model: sensor?.model,
		...(isQuickAction && state?.rfFilterState),
	}

	const defaultValues = isEditForm
		? { ...addFilterDefaultValues, ...rfFilter }
		: addFilterDefaultValues

	// Show preview on the map
	const dispatch = useAppDispatch()
	useEffect(() => {
		dispatch(
			updateSensorPreview({
				sentryId: sensor?.sentry_id,
				sensorType: 'rfFilters',
				reach: sensor?.reach,
				directionOffset: sensor?.direction_offset,
				sensorId: sensor?.id,
				model: sensor?.model,
			})
		)
		return () => {
			dispatch(updateSensorPreview(null))
		}
	}, [sensor, dispatch])

	return (
		<>
			{isSensorError && (
				<FetchError
					errorMessage={tSensor('fetchStatus.error')}
					refetch={sensorRefetch}
				/>
			)}
			<ErrorBoundary>
				<FormWrapper
					entity={t('entity')}
					isEditForm
					isSuccess={isFiltersSuccess && isSensorSuccess}
					isLoading={isFilterLoading || isSensorLoading}
					isError={isFilterError}
					errorMessage={t('status.error')}
					refetch={refetch}
				>
					{defaultValues && sensor && (
						<Form
							defaultValues={defaultValues}
							isEditForm={isEditForm}
							sensor={sensor}
							isQuickAction={isQuickAction}
						/>
					)}
				</FormWrapper>
			</ErrorBoundary>
		</>
	)
}
export default memo(RfFilterForm)
