const colors = {
	// Chakra settings
	body_text: '#ffffff',
	body_text__dark: '#212121',
	body_bg: '#303030',
	border_color: '#7A7A7A',
	inverse_text: '', // TODO: find where it using
	subtle_bg: '', // TODO: find where it using
	subtle_text: '', // TODO: find where it using
	placeholder_color: '#C0C0C0',
	// Custom settings
	DRO: '#f57b15', // DroneShield Orange
	primary: '#FFA726',
	// Buttons,
	action_button_color: '#E0E0E0',
	// Statuses
	error: '#F44336',
	success: '#4CAF50',
	warning: '#FB8C00',
	// Inputs
	input_bg: '#212121',
	input_color: '#FFFFFF',
	label_color: '#C0C0C0',
	disabled: '#7A7A7A',
	background: '#303030',
	card_background: '#424242',
	card_background__disabled: 'rgba(48, 48, 48, 0.8)',
	badge_background: '#424242',
	divider_background: '#424242',
	// Navbars
	top_navbar: '#212121',
	left_navbar: '#424242',
	// Menu
	menu_bg: '#303030',
	menu_bg_active: '#424242',
	// Panels
	login_panel_bg: '#424242',
	panel_bg: 'rgba(33, 33, 33, 0.85)',
	headings: '#C0C0C0',
	tertiary_buttons: '#EEEEEE',
	// Modal
	modal_bg: 'rgba(33, 33, 33, 0.65)',
	modal_heading: '#E0E0E0',
	// Leaflet
	leaflet_control_color: '#e0e0e0',
	leaflet_control_bg: '#212121',
	leaflet_control_transparent_bg: 'rgba(255, 255, 255, 0.8)',
	leaflet_control_text: '#424242',
	// Camera
	camera_control_color: '#e0e0e0',
	camera_control_bg: 'rgba(33, 33, 33, 0.85)',
	//Charts
	charts: {
		grid: '#606060',
		bar: [
			'rgba(255, 167, 38, 0.45)',
			'rgba(244,67,54, 0.45)',
			'rgba(76, 175, 80, 0.45)',
		],
		pie: ['#FFE6A8', '#FFA726'],
		line: '#FFA726',
		customAmber: 'rgba(255, 167, 38, 0.25)',
		customGreen: 'rgba(76,175,80, 0.25)',
		distribution: {
			droneClassification: '#750A26',
			protectedZones: '#AF1B29',
			droneTransmissionMethod: '#F44336',
			geoLocation: '#FB8C00',
			legalLimits: '#FFB74D',
			threatZones: '#FFE0B2',
			monitoringSchedule: '#FFFFFF',
		},
	},
	// STATUSES
	status: {
		no_status: '#7A7A7A',
		no_connection: '#F44336',
		connection: '#4CAF50',
		lost_connection: '#FB8C00',
	},
	// Sectors
	sectors: {
		radarStroke: '#FFA726',
		radarFill: 'transparent',
		rfSensorStroke: '#FFA726',
		rfSensorFill: 'transparent',
		disruptorStroke: '#FFA726',
		disruptorFill: 'transparent',
		cameraStroke: '#FFA500',
		cameraFill: 'transparent',
		highlight: '#FFFFFF',
		dsxReach: '#FA001B',
		calibration: 'rgba(76,175,80, 0.75)',
	},
	// ZONES
	zones: {
		disrupt: '#F44336',
		alert: '#FFA726',
		label: '#0080F7',
		origin: '#FB8C00',
		editMode: '#FA001B',
	},
	// Detections
	detections: {
		default: '#FA001B',
		whitelisted: '#0080F7',
		not_saved_detection: '#7bcf77',
		display_filtered: '#919090',
		selected: {
			fillColor: '#212121',
			markerColor: '#FFA726',
		},
	},
	// Notifications
	notifications: {
		alert: '#750A26',
		stale: '#C0C0C0',
		warning: '#FB8C00',
		bg: '#212121D8',
		active_bg: '#212121',
	},
	// Filters
	filter_bg: 'rgba(255, 167, 38, 0.45)',
	filter_bg__disabled: 'rgba(33, 33, 33)',
	radarMasks: {
		stroke: '#FFA726',
		fill: '#212121',
		active: '#FFFFFF',
	},
	// AGL Mask Measuring Lines
	measuringLine: '#616161',
}

const colorSchemas = {
	amber: {
		'50': '#fff8e1',
		'100': '#fff4d3',
		'200': '#ffe6a8',
		'300': '#ffd47c',
		'400': '#ffc35c',
		'500': '#ffa726',
		'600': '#db851b',
		'700': '#b76713',
		'800': '#934c0c',
		'900': '#7a3807',
	},
	gray: {
		'50': '#fafafa',
		'100': '#f5f5f5',
		'200': '#eeeeee',
		'300': '#e0e0e0',
		'400': '#c0c0c0',
		'500': '#7a7a7a',
		'600': '#616161',
		'700': '#424242',
		'800': '#303030',
		'900': '#212121',
	},
	red: {
		'50': '#fbe9e7',
		'100': '#fee6d6',
		'200': '#fdc7ae',
		'300': '#fba186',
		'400': '#f87d67',
		'500': '#f44336',
		'600': '#d12729',
		'700': '#af1b29',
		'800': '#8d1127',
		'900': '#750a26',
	},
	orange: {
		'50': '#fff3e0',
		'100': '#ffe0b2',
		'200': '#ffcc80',
		'300': '#ffb74d',
		'400': '#ffa726',
		'500': '#fb8c00',
		'600': '#f57c00',
		'700': '#ef6c00',
		'800': '#e65100',
		'900': '#782d00',
	},
	green: {
		'50': '#e8f5e9',
		'100': '#e7fbde',
		'200': '#cbf7bf',
		'300': '#a3e798',
		'400': '#7bcf77',
		'500': '#4caf50',
		'600': '#379643',
		'700': '#267d39',
		'800': '#18652f',
		'900': '#0e532a',
	},
	blue: {
		'50': '#e3f2fd',
		'100': '#cbf0fe',
		'200': '#98dcfe',
		'300': '#65c3fc',
		'400': '#3ea9fa',
		'500': '#0080f7',
		'600': '#0063d4',
		'700': '#0049b1',
		'800': '#00348f',
		'900': '#002476',
	},
}

const FIGMA = { colors, colorSchemas }
export default FIGMA
