// Packages
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Flex } from '@chakra-ui/react'

// Alias
import Headings from '@UI/Headings/Headings'
import DateRangePicker from '@/components/_UI/DateRangePicker/DateRangePicker'

type ReportRangeProps = {
	minDateTime?: string
	maxDateTime?: string
	timezone?: string
	startTime: string
	endTime: string
	setStartTime: (startTime: string) => void
	setEndTime: (endTime: string) => void
	isDisabled?: boolean
}

const ReportRange = ({
	minDateTime = '',
	maxDateTime = '',
	timezone,
	startTime,
	endTime,
	setStartTime,
	setEndTime,
	isDisabled,
}: ReportRangeProps) => {
	const { t } = useTranslation('pages', {
		keyPrefix: 'analytics.reportRange',
	})

	const handleChangeRange = (dates: [string, string]) => {
		const [startDate, endDate] = dates
		setStartTime(startDate)
		setEndTime(endDate)
	}

	const dateRange = useMemo<[string, string]>(
		() => [startTime, endTime],
		[startTime, endTime]
	)

	return (
		<Flex p='16px 0px' alignItems='center' gap='6px' placeItems={'end'}>
			<Headings.FieldHeading title={t('viewReport')} />
			<DateRangePicker
				value={dateRange}
				canReset
				min={minDateTime}
				max={maxDateTime}
				timezone={timezone}
				disabled={isDisabled}
				labelStartDate={t('startTime')}
				labelEndDate={t('endTime')}
				testIdStartDate='report-from'
				testIdEndDate='report-to'
				onChange={handleChangeRange}
			/>
		</Flex>
	)
}

export default ReportRange
