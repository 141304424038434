// Packages
import { DatePicker as DatePickerArk } from '@ark-ui/react'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'

const DatePickerMonths = () => {
	return (
		<DatePickerArk.View view='month'>
			<DatePickerArk.Context>
				{(datePicker) => (
					<>
						<DatePickerArk.ViewControl>
							<DatePickerArk.PrevTrigger>
								<MdKeyboardArrowLeft size={20} />
							</DatePickerArk.PrevTrigger>
							<DatePickerArk.ViewTrigger>
								<DatePickerArk.RangeText />
							</DatePickerArk.ViewTrigger>
							<DatePickerArk.NextTrigger>
								<MdKeyboardArrowRight size={20} />
							</DatePickerArk.NextTrigger>
						</DatePickerArk.ViewControl>
						<DatePickerArk.Table>
							<DatePickerArk.TableBody>
								{datePicker
									.getMonthsGrid({ columns: 4, format: 'short' })
									.map((months, id) => (
										<DatePickerArk.TableRow key={id}>
											{months.map((month, id) => (
												<DatePickerArk.TableCell key={id} value={month.value}>
													<DatePickerArk.TableCellTrigger>
														{month.label}
													</DatePickerArk.TableCellTrigger>
												</DatePickerArk.TableCell>
											))}
										</DatePickerArk.TableRow>
									))}
							</DatePickerArk.TableBody>
						</DatePickerArk.Table>
					</>
				)}
			</DatePickerArk.Context>
		</DatePickerArk.View>
	)
}

export default DatePickerMonths
