import { useTheme } from '@chakra-ui/react'

export const useChartTheme = () => {
	const {
		semanticTokens: { colors },
	} = useTheme()
	return {
		text: {
			fontSize: 12,
			fill: colors.body_text,
			outlineWidth: 0,
			outlineColor: 'transparent',
		},
		grid: {
			line: {
				stroke: colors.charts.grid,
				strokeWidth: 1,
			},
		},
		tooltip: {
			container: {
				background: colors.charts.body_text,
				fontSize: 12,
				color: colors.input_bg,
			},
			wrapper: {
				zIndex: 9999,
			},
		},
	}
}

export default useChartTheme
