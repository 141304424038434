// Relatives
import { baseApi } from '../baseApi'
import { formatApiErrors } from '../utils/errorUtils'

// Types
import type { CoreApiError } from '../utils/errorUtils'
import type { RecordingToolLog } from '../types/recordingTool'
import type { Site } from '../types'

type GetRecordingToolListRequest = {
	siteId: Site['id']
	startTime?: string
	endTime?: string
}
type CreateRecordingToolRequest = { siteId: Site['id']; description: string }
type DiscardRecordingToolRequest = {
	siteId: Site['id']
	recordingId: RecordingToolLog['id']
}
type SaveRecordingToolRequest = {
	siteId: Site['id']
	recordingId: RecordingToolLog['id']
}

export const recordingToolApi = baseApi
	.enhanceEndpoints({ addTagTypes: ['Logs'] })
	.injectEndpoints({
		endpoints: (builder) => ({
			getRecordingToolList: builder.query<
				Array<RecordingToolLog>,
				GetRecordingToolListRequest
			>({
				query: ({ siteId, startTime, endTime }) => ({
					url: `/api/sites/${siteId}/recording_tool`,
					params: { start_time: startTime, end_time: endTime },
				}),
				providesTags: ['Logs'],
				transformResponse: (response: { recording_tool: RecordingToolLog[] }) =>
					response.recording_tool.sort((a, b) => a.id - b.id),
			}),
			createRecording: builder.mutation<
				RecordingToolLog,
				CreateRecordingToolRequest
			>({
				query: ({ siteId, description }) => ({
					url: `/api/sites/${siteId}/recording_tool`,
					method: 'POST',
					body: { description },
				}),
				transformResponse: (response: { recording_tool: RecordingToolLog }) =>
					response.recording_tool,
				transformErrorResponse: (response: CoreApiError) =>
					formatApiErrors<RecordingToolLog>(response),
			}),
			discardRecording: builder.mutation<
				RecordingToolLog,
				DiscardRecordingToolRequest
			>({
				query: ({ siteId, recordingId }) => ({
					url: `/api/sites/${siteId}/recording_tool/${recordingId}/discard`,
					transformResponse: (response: { recording_tool: RecordingToolLog }) =>
						response.recording_tool,
					transformErrorResponse: (response: CoreApiError) =>
						formatApiErrors<RecordingToolLog>(response),
				}),
			}),
			saveActiveRecording: builder.mutation<
				RecordingToolLog,
				SaveRecordingToolRequest
			>({
				query: ({ siteId, recordingId }) => ({
					url: `/api/sites/${siteId}/recording_tool/${recordingId}/save`,
				}),
				transformResponse: (response: { recording_tool: RecordingToolLog }) =>
					response.recording_tool,
				transformErrorResponse: (response: CoreApiError) =>
					formatApiErrors<RecordingToolLog>(response),
			}),
		}),
	})

export const {
	useGetRecordingToolListQuery,
	useCreateRecordingMutation,
	useSaveActiveRecordingMutation,
	useDiscardRecordingMutation,
} = recordingToolApi
