// Packages
import { Box, Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

// Alias
import { formatEventId } from '@/utils/formatUtils'
import CopyButton from '@UI/CopyButton/CopyButton'

export type DetailsEventProps = {
	targetId?: string
}

const DetailsEvent = ({ targetId = '' }: DetailsEventProps) => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'notifications.detections',
	})

	return (
		<Box w='100%'>
			<Text fontSize='xs' color='headings'>
				{t('detectionDetail.headings.eventId')}
			</Text>
			<Flex alignItems='center' fontSize='sm' gap={1}>
				<Text>{formatEventId(targetId)}</Text>
				<CopyButton
					content={formatEventId(targetId) ?? ''}
					successTitle={t('buttons.copyButton.titles.success')}
					errorTitle={t('buttons.copyButton.titles.error')}
					successDescription={t('buttons.copyButton.descriptions.success')}
					errorDescription={t('buttons.copyButton.descriptions.error')}
				/>
			</Flex>
		</Box>
	)
}

export default DetailsEvent
