import { isAnyOf } from '@reduxjs/toolkit'
import type { AppStartListening } from '@Store/middleware'
import { sitesWsApi } from '../sites/sitesWsApi'
import { setActiveCameraId } from '@Store/ui/uiSlice'
import type { Site, Camera, SiteLive } from '@Store/types/index'

import { baseUrl } from '@Constants/api'

// get the siteId from /:siteId
const getSiteId = () => {
	const siteId = Number(window.location.pathname.replace(/^\/(\d+).*$/, '$1'))
	if (siteId > 0) return siteId
	else return null
}

// the session storage value is keyed by hostname and siteId
const hostname = baseUrl.replace(/^https*:\/\/(.+)\/*$/, '$1')

const persistState = (
	hostname: string,
	siteId: Site['id'],
	cameraId: Camera['id']
) => {
	if (cameraId) {
		sessionStorage.setItem(`${hostname}-${siteId}-camera`, String(cameraId))
	} else {
		sessionStorage.removeItem(`${hostname}-${siteId}-camera`)
	}
}

const fetchState = (hostname: string, siteId: Site['id']) => {
	const cameraId = sessionStorage.getItem(`${hostname}-${siteId}-camera`)
	if (Number(cameraId) > 0) return Number(cameraId)
	else return null
}

const addCameraPersistenceMiddleware = (startListening: AppStartListening) => {
	startListening({
		matcher: isAnyOf(
			// match when react subscribes to SiteLive websocket
			sitesWsApi.endpoints.getSiteLive.matchFulfilled
		),
		effect: async (action, { dispatch }) => {
			const siteId = (action.payload as SiteLive).id
			const cameraId = fetchState(hostname, siteId)
			if (cameraId) {
				dispatch(setActiveCameraId(cameraId))
			} else {
				dispatch(setActiveCameraId(null))
			}
		},
	})
	startListening({
		actionCreator: setActiveCameraId,
		effect: (action) => {
			const cameraId = action.payload
			const siteId = getSiteId()
			if (siteId) persistState(hostname, siteId, cameraId)
		},
	})
}

// Exports
export { addCameraPersistenceMiddleware }
