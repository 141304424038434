// React
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
// Chakra
import { MdOutlinePlaylistAddCheck as WhitelistIcon } from 'react-icons/md'
// Components
import QuickActionsButton from '../QuickActionsButton/QuickActionsButton'
// Store
import { useAppSelector } from '@/store'
import { selectActiveTargetId } from '@Store/ui/uiSlice'
import {
	selectSiteDetection,
	useGetSiteLiveQuery,
	selectSiteHasRfSensor,
	selectDetectionHasRfContributions,
} from '@Store/sites/sitesWsApi'
import { useGetRfSensorFiltersQuery } from '@Store/rfFilters/rfFiltersApi'
// Utils
import { findFrequencyBand } from '@Utils/rfFilter'
import { isValidFrequency } from '@/constants/sensorFilters'
import { formatProtocol, formatVendor } from '@/utils/formatUtils'

const WhitelistButton = () => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'quickActions.maskActions',
	})

	const navigate = useNavigate()
	const { siteId: siteIdParam } = useParams()
	const siteId = Number(siteIdParam)

	const activeTargetId = useAppSelector(selectActiveTargetId)

	// Detections
	const { mode, detection, hasRfSensor, hasRfContributions } =
		useGetSiteLiveQuery(siteId, {
			selectFromResult: ({ data }) => ({
				mode: data?.mode,
				detection: selectSiteDetection(data, activeTargetId),
				hasRfSensor: selectSiteHasRfSensor(data),
				hasRfContributions: selectDetectionHasRfContributions(
					data,
					activeTargetId
				),
			}),
		})
	const detectionContributions = detection?.detection_contributions || []
	const sensorType = detectionContributions[0]?.sensor_type
	const sensorId = detectionContributions[0]?.sensor_id

	// Filters
	const { data: rf_filters } = useGetRfSensorFiltersQuery(
		{ siteId, sensorId },
		{ skip: !sensorId || !['rfSensor', 'dsx'].includes(sensorType) }
	)
	const whiteListLength: number =
		rf_filters?.filter((rf_filter) => rf_filter?.whitelist).length || 0

	// TODO: Add RfPatrol, DSX
	const handleClick = () => {
		const installationId = detectionContributions[0].sentry_id
		const rfFilterState = {
			vendor: formatVendor(detection?.vendor) ?? '',
			protocol: formatProtocol(detection?.protocol) ?? '',
			frequency_band: findFrequencyBand(detection?.frequency),
			frequency: detection?.frequency,
			frequency_tolerance: 5_000_000,
			name: `${t('newWhitelist')} #${whiteListLength + 1}`,
		}

		navigate(
			`/${siteId}/installation/${installationId}/rf_sensors/${sensorId}/rf_filters/add?whitelist`,
			{
				state: { rfFilterState },
			}
		)
	}

	const frequencyBand = findFrequencyBand(detection?.frequency)?.replace(
		'MHz',
		''
	)
	const isDisabled =
		mode !== 'calibration' ||
		!sensorId ||
		!hasRfSensor ||
		!hasRfContributions ||
		!isValidFrequency(frequencyBand)

	return (
		<QuickActionsButton
			icon={WhitelistIcon}
			label={t('addWhitelist')}
			isDisabled={isDisabled}
			onClick={handleClick}
			testId='quick-action-whitelist'
		/>
	)
}

export default WhitelistButton
