import { useMemo } from 'react'
import {
	selectSiteThreatAnalysisConfig,
	useGetSiteQuery,
} from '@Store/sites/sitesApi'
import {
	selectAlertZones,
	selectThreatZones,
	useGetZonesQuery,
} from '@/store/zones/zonesApi'
import { skipToken } from '@reduxjs/toolkit/query'

import { THREAT_ANALYSIS_DEFAULT_VALUES } from './ThreatAnalysisForm.schema'

import ThreatAnalysisForm from './ThreatAnalysisForm'
import type { ThreatAnalysisConfig } from '@/store/types'

type ThreatAnalysisFormLoaderProps = {
	siteId: number
}

const ThreatAnalysisFormLoader = ({
	siteId,
}: ThreatAnalysisFormLoaderProps) => {
	const { threatAnalysisConfig, isSuccess } = useGetSiteQuery(
		siteId ? siteId : skipToken,
		{
			selectFromResult: ({ data, isSuccess }) => ({
				threatAnalysisConfig: selectSiteThreatAnalysisConfig(data),
				isSuccess,
			}),
			refetchOnMountOrArgChange: true,
		}
	)

	const { alertZones, zonesLoaded, threatZones } = useGetZonesQuery(
		siteId ? { siteId } : skipToken,
		{
			selectFromResult: ({ data, isSuccess }) => ({
				zonesLoaded: isSuccess,
				alertZones: selectAlertZones(data) ?? [],
				threatZones: selectThreatZones(data) ?? [],
			}),
		}
	)

	const defaultValues: ThreatAnalysisConfig = useMemo(
		() =>
			threatAnalysisConfig &&
			Object.values(threatAnalysisConfig).some(
				(config) => config.active === true
			)
				? threatAnalysisConfig
				: THREAT_ANALYSIS_DEFAULT_VALUES,
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[threatAnalysisConfig, THREAT_ANALYSIS_DEFAULT_VALUES]
	)

	if (!isSuccess || !zonesLoaded) return null

	return (
		<ThreatAnalysisForm
			siteId={siteId}
			defaultValues={defaultValues}
			dependencies={{ alertZones, threatZones }}
		/>
	)
}

export default ThreatAnalysisFormLoader
