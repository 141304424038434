// Packages
import noop from 'lodash/noop'
import { useCallback, useEffect } from 'react'
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md'

export type DatePickerTimeProps = {
	id: string
	open?: boolean
	hours?: number
	minutes?: number
	onClick?: (time: string) => void
}

const DatePickerTime = ({
	id,
	open,
	hours = 0,
	minutes = 0,
	onClick = noop,
}: DatePickerTimeProps) => {
	const formatTime = useCallback(
		(hour: number, minute: number) =>
			`${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`,
		[]
	)

	const handleClickHour = useCallback(
		(hour: number) => () => onClick(formatTime(hour, minutes)),
		[onClick, minutes, formatTime]
	)

	const handleClickMinute = useCallback(
		(minute: number) => () => onClick(formatTime(hours, minute)),
		[onClick, hours, formatTime]
	)

	const handleClickUpHour = useCallback(() => {
		if (hours - 1 < 0) {
			return
		}

		onClick(formatTime(hours - 1, minutes))
	}, [onClick, hours, formatTime, minutes])

	const handleClickDownHour = useCallback(() => {
		if (hours + 1 > 23) {
			return
		}

		onClick(formatTime(hours + 1, minutes))
	}, [onClick, hours, formatTime, minutes])

	const handleClickUpMinute = useCallback(() => {
		if (minutes - 1 < 0) {
			return
		}

		onClick(formatTime(hours, minutes - 1))
	}, [onClick, minutes, formatTime, hours])

	const handleClickDownMinute = useCallback(() => {
		if (minutes + 1 > 59) {
			return
		}

		onClick(formatTime(hours, minutes + 1))
	}, [onClick, minutes, formatTime, hours])

	useEffect(() => {
		if (!open) {
			return
		}

		setTimeout(() => {
			const query =
				'div[data-source="date-picker"][data-part="time-hour"][aria-selected="true"]'
			document
				.getElementById(`datepicker:${id}:positioner`)
				?.querySelector(query)
				?.scrollIntoView({ block: 'nearest', behavior: 'instant' })
		}, 0)
	}, [id, hours, open])

	useEffect(() => {
		if (!open) {
			return
		}

		setTimeout(() => {
			const query =
				'[data-source="date-picker"][data-part="time-minute"][aria-selected="true"]'
			document
				.getElementById(`datepicker:${id}:positioner`)
				?.querySelector(query)
				?.scrollIntoView({ block: 'nearest', behavior: 'instant' })
		}, 0)
	}, [id, minutes, open])

	return (
		<div data-source='date-picker' data-part='time-container'>
			<div data-source='date-picker' data-part='time-hour-container'>
				<div
					data-source='date-picker'
					data-part='time-hour-up'
					onClick={handleClickUpHour}
				>
					<MdKeyboardArrowUp />
				</div>
				<div
					className='no-scrollbar'
					data-source='date-picker'
					data-part='time-hours'
				>
					{Array(24)
						.fill(0)
						.map((_, i) => (
							<div
								key={i}
								data-source='date-picker'
								data-part='time-hour'
								aria-selected={`${i === hours}`}
								onClick={handleClickHour(i)}
							>
								{`${i}`.padStart(2, '0')}
							</div>
						))}
				</div>
				<div
					data-source='date-picker'
					data-part='time-hour-down'
					onClick={handleClickDownHour}
				>
					<MdKeyboardArrowDown />
				</div>
			</div>
			<div data-source='date-picker' data-part='time-minute-container'>
				<div
					data-source='date-picker'
					data-part='time-minute-up'
					onClick={handleClickUpMinute}
				>
					<MdKeyboardArrowUp />
				</div>
				<div
					className='no-scrollbar'
					data-source='date-picker'
					data-part='time-minutes'
				>
					{Array(60)
						.fill(0)
						.map((_, i) => (
							<div
								key={i}
								data-source='date-picker'
								data-part='time-minute'
								aria-selected={`${i === minutes}`}
								onClick={handleClickMinute(i)}
							>
								{`${i}`.padStart(2, '0')}
							</div>
						))}
				</div>
				<div
					data-source='date-picker'
					data-part='time-minute-down'
					onClick={handleClickDownMinute}
				>
					<MdKeyboardArrowDown />
				</div>
			</div>
		</div>
	)
}

export default DatePickerTime
