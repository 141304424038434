import { useState, useCallback, useRef } from 'react'

import { formatMillisecondsAsDuration } from '@/utils/formatUtils'

type useDurationHook = () => [
	duration: number | null,
	duration: string | null,
	start: () => void,
	stop: () => void,
]

const useDuration: useDurationHook = () => {
	const [duration, setDuration] = useState<number | null>(null)
	const [durationStr, setDurationStr] = useState<string | null>(null)
	const startTimeRef = useRef<number | null>(null)
	const intervalRef = useRef<NodeJS.Timer | undefined>(undefined)

	const start = useCallback(() => {
		if (startTimeRef.current !== null) return
		startTimeRef.current = Date.now()

		intervalRef.current = setInterval(() => {
			if (startTimeRef.current === null) return

			const elapsed = Date.now() - startTimeRef.current
			setDuration(elapsed)
			setDurationStr(formatMillisecondsAsDuration(elapsed))
		}, 500)
	}, [])

	const stop = useCallback(() => {
		if (startTimeRef.current === null) return
		if (!intervalRef.current) {
			clearInterval(intervalRef.current)
			intervalRef.current = undefined
		}
		const finalDuration = Date.now() - startTimeRef.current
		setDuration(finalDuration)
		setDurationStr(formatMillisecondsAsDuration(finalDuration))
		startTimeRef.current = null
	}, [])

	return [duration, durationStr, start, stop]
}

export default useDuration
