import { isValidNumber } from '@/utils/numbers'
import { useEffect, useState } from 'react'

const useValidCoordinates = (
	latitude: number | null | undefined,
	longitude: number | null | undefined
): [number, number] => {
	const [prevLat, setPrevLat] = useState<number | null | undefined>(latitude)
	const [prevLng, setPrevLng] = useState<number | null | undefined>(longitude)

	useEffect(() => {
		if (isValidNumber(latitude)) setPrevLat(Number(latitude))
		if (isValidNumber(longitude)) setPrevLng(Number(longitude))
	}, [latitude, longitude])

	// Use 0 as default when nothing else is valid
	let validLatitude = 0
	let validLongitude = 0

	// Use last value as default when latest is invalid
	if (isValidNumber(prevLat)) {
		validLatitude = prevLat
	}
	if (isValidNumber(prevLng)) {
		validLongitude = prevLng
	}

	// Use latest value if it is valid
	if (isValidNumber(latitude)) {
		validLatitude = latitude
	}
	if (isValidNumber(longitude)) {
		validLongitude = longitude
	}

	return [validLatitude, validLongitude]
}

export default useValidCoordinates
