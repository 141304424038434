import { type ReactNode, useMemo, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
	Box,
	Flex,
	Icon,
	Tabs,
	TabList,
	TabPanels,
	Tab as ChakraTab,
	TabPanel as ChakraTabPanel,
} from '@chakra-ui/react'
import {
	MdBolt as DisruptorIcon,
	MdCamera as CameraIcon,
	MdTrackChanges as MaskIcon,
	MdOutlineVideoSettings as VideoSettingsIcon,
} from 'react-icons/md'

import CameraActions from './CameraActions/CameraActions'
import DisruptorActions from './DisruptorActions/DisruptorActions'
import MaskActions from './MaskActions/MaskActions'
import ErrorBoundary from '@Components/App/ErrorHandling/ErrorBoundary'

import { useAppSelector } from '@Store/index'
import {
	useGetSiteLiveQuery,
	selectSiteHasCamera,
	selectSiteHasDisruptor,
	selectSiteHasRadar,
	selectSiteHasRfSensor,
} from '@Store/sites/sitesWsApi'
import { selectActiveTargetId } from '@Store/ui/uiSlice'
import {
	useGetSystemInfoQuery,
	selectIsTactical,
	selectIsDefeatEnabled,
} from '@Store/system/systemApi'
import useAuth from '@Hooks/useAuth'
import RecordingToolActions from './RecordingToolActions/RecordingToolActions'

const iconSize = { w: 6, h: 6 }

// Note: Tab + TabPanel style is unique to quick actions.
// Move to different file if this one becomes too bloated
const Tab = ({
	children,
	isDisabled,
	testId,
}: {
	children: ReactNode
	isDisabled?: boolean
	testId?: string
}) => (
	<ChakraTab
		backgroundColor='body_bg'
		_selected={{ color: 'primary', bg: 'panel_bg' }}
		border='none'
		isDisabled={isDisabled}
		data-testid={testId}
		pointerEvents={isDisabled ? 'none' : undefined}
	>
		{children}
	</ChakraTab>
)

const TabPanel = ({
	children,
	isTactical,
}: {
	children: ReactNode
	isTactical?: boolean
}) => (
	<ChakraTabPanel padding='12px' minH={!isTactical ? '156px' : undefined}>
		<Flex wrap='wrap' columnGap='8px' rowGap='12px'>
			{children}
		</Flex>
	</ChakraTabPanel>
)

const QuickActions = () => {
	const { siteId: siteIdParam } = useParams()
	const siteId = Number(siteIdParam)
	const { isSiteManager, isOperator, isGuest } = useAuth()

	const [defaultIndex, setDefaultIndex] = useState<number | undefined>()

	const { hasCamera, hasRadar, hasRfSensor, hasDisruptor } =
		useGetSiteLiveQuery(siteId, {
			selectFromResult: ({ data }) => ({
				hasCamera: selectSiteHasCamera(data),
				hasRadar: selectSiteHasRadar(data),
				hasRfSensor: selectSiteHasRfSensor(data),
				hasDisruptor: selectSiteHasDisruptor(data, true), // true means include DSX
			}),
		})
	const activeTargetId = useAppSelector(selectActiveTargetId)

	// C2T
	const { isTactical, isDefeatEnabled } = useGetSystemInfoQuery(undefined, {
		selectFromResult: ({ data }) => ({
			isTactical: selectIsTactical(data),
			isDefeatEnabled: selectIsDefeatEnabled(data),
		}),
	})

	const calculatedIndex = useMemo(() => {
		if (hasCamera) {
			return 0
		} else if (hasDisruptor) {
			return 1
		} else if (activeTargetId || hasRadar || hasRfSensor) {
			// no defeat means only 2 tabs so index 1 is already the masks tab
			return isDefeatEnabled ? 2 : 1
		}
	}, [
		hasCamera,
		hasDisruptor,
		activeTargetId,
		hasRadar,
		hasRfSensor,
		isDefeatEnabled,
	])

	useEffect(() => {
		setDefaultIndex(calculatedIndex)
	}, [calculatedIndex])

	return (
		<ErrorBoundary>
			<Box mb={3} pointerEvents='auto'>
				{defaultIndex !== undefined && (
					<Tabs isFitted variant='quick_actions' defaultIndex={defaultIndex}>
						<TabList height='40px' gap='1px'>
							{!isTactical && isGuest && (
								<Tab testId='quick-action-camera' isDisabled={!hasCamera}>
									<Icon as={CameraIcon} {...iconSize} />
								</Tab>
							)}
							{isDefeatEnabled && isOperator && (
								<Tab testId='quick-action-disruptor' isDisabled={!hasDisruptor}>
									<Icon as={DisruptorIcon} {...iconSize} />
								</Tab>
							)}
							{isSiteManager && (
								<Tab
									testId='quick-action-mask'
									isDisabled={!hasRadar && !hasRfSensor}
								>
									<Icon as={MaskIcon} {...iconSize} />
								</Tab>
							)}
							<Tab testId='quick-action-record'>
								<Icon as={VideoSettingsIcon} {...iconSize} />
							</Tab>
						</TabList>

						<TabPanels bgColor='panel_bg'>
							{!isTactical && isGuest && (
								<TabPanel>
									<ErrorBoundary>
										<CameraActions siteId={siteId} />
									</ErrorBoundary>
								</TabPanel>
							)}
							{isDefeatEnabled && isOperator && (
								<TabPanel>
									<ErrorBoundary>
										<DisruptorActions siteId={siteId} />
									</ErrorBoundary>
								</TabPanel>
							)}
							{isSiteManager && (
								<TabPanel isTactical={isTactical}>
									<ErrorBoundary>
										<MaskActions siteId={siteId} />
									</ErrorBoundary>
								</TabPanel>
							)}
							<TabPanel data-testid='nice'>
								<ErrorBoundary>
									<RecordingToolActions />
								</ErrorBoundary>
							</TabPanel>
						</TabPanels>
					</Tabs>
				)}
			</Box>
		</ErrorBoundary>
	)
}

export default QuickActions
