// React
import { useEffect, useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
// Chakra
import {
	FormControl,
	FormErrorMessage,
	FormLabel,
	SliderFilledTrack,
	SliderThumb,
	SliderTrack,
	Grid,
	Box,
	Slider,
	NumberInputField,
} from '@chakra-ui/react'
// Components
import Tooltip from '@UI/Tooltip/Tooltip'
import SliderCustomLabel from './SliderCustomLabel'
// Types
import type { SliderProps } from '@chakra-ui/react'
import type { RegisterType } from '@/components/FormElements/types/RegisterType'
import type { FormElementProps } from '@Components/FormElements/types/FormElementProps'
import type { Units } from '@Components/FormElements/types/Units'
// Utils
import { formatDegToMils, formatMilsToDeg } from '@Utils/formatUtils'
// Hooks
import useUnits from '@Hooks/useUnits'
import SliderNumberInput from '../SliderNumberInput/SliderNumberInput'
import { useTranslation } from 'react-i18next'

export type FormSliderProps = FormElementProps & {
	defaultValue?: number
	min: number
	max: number
	step?: number
	inputWidth?: number
	units: Units
	isAbsolute?: boolean
	customLabel?: string | number
	showStepper?: boolean
} & SliderProps &
	RegisterType<string>

const UnitsSlider = ({
	id,
	title,
	error,
	register,
	min,
	max,
	step = 1,
	testId,
	disabled = false,
	inputWidth = undefined,
	tooltip,
	units,
	customLabel,
	isAbsolute = false,
	showStepper = false,
}: FormSliderProps) => {
	const { control, getValues, setError, clearErrors } = useFormContext()
	const { t } = useTranslation('forms', {
		keyPrefix: 'global.validation',
	})

	const htmlId = id ?? register?.name ?? 'slider'

	const { unit, isMils } = useUnits(units)

	const [slider, setSlider] = useState<number>()
	const [range, setRange] = useState<string | undefined>('')

	useEffect(() => {
		if (!register?.name) return

		const initialValue = getValues(register?.name)
		const formatted = isMils ? formatDegToMils(initialValue) : initialValue

		setRange(formatted)
		setSlider(formatted)
	}, [getValues, isMils, register?.name, setSlider])

	const inputMax = useMemo(() => {
		return isMils ? Number(formatDegToMils(max)) : max
	}, [isMils, max])
	const inputMin = useMemo(() => {
		return isMils ? Number(formatDegToMils(min)) : min
	}, [isMils, min])

	const handleOnChange = (
		value: string | undefined,
		onChange: (...event: any[]) => void
	) => {
		setRange(value)

		const parsed = Number(value)

		if (isNaN(parsed)) {
			setError(htmlId, {
				message: t('range', {
					min: inputMin,
					max: inputMax,
				}),
			})
			onChange(undefined)
			return
		}

		if (parsed < inputMin || parsed > inputMax) {
			setError(htmlId, {
				message: t('range', {
					min: inputMin,
					max: inputMax,
				}),
			})
		} else {
			clearErrors(htmlId)
			setSlider(parsed)
		}

		// This is a good way to prevent user from entering empty string as z.number() would parse it as 0
		if (!value) {
			onChange(undefined)
			return
		}

		const milValue =
			isMils && value ? Number(formatMilsToDeg(parsed)) : undefined
		onChange(isMils ? milValue : parsed)
	}

	const calculatedInputWidth = useMemo(() => {
		if (inputWidth) return `${inputWidth}ch`
		else if (String(inputMax).length + 3 >= 5)
			return `${String(inputMax).length + 5}ch`
		else if (isMils) return showStepper ? '11ch' : '9ch'
		else return showStepper ? '9ch' : '7ch'
	}, [inputWidth, isMils, showStepper, inputMax])

	return (
		<Tooltip label={tooltip} type='info'>
			<FormControl isInvalid={!!error} isDisabled={disabled} userSelect='none'>
				<Controller
					control={control}
					name={htmlId}
					render={({ field: { onChange } }) => {
						return (
							<Box position='relative'>
								<Box position='relative' right={tooltip ? 8 : undefined}>
									<SliderCustomLabel
										customLabel={customLabel}
										isAbsolute={isAbsolute}
										units={units}
									/>
								</Box>
								<FormLabel flex='1 0 auto'>
									{title} {!!unit && `(${unit})`}
								</FormLabel>
								<Grid templateColumns={`${calculatedInputWidth} 1fr`} gap={2}>
									<SliderNumberInput
										value={range?.toString()}
										min={inputMin}
										max={inputMax}
										onChange={(value) => handleOnChange(value, onChange)}
										precision={2}
										step={step}
										clampValueOnBlur={false}
										showStepper={showStepper}
									>
										<NumberInputField
											w={calculatedInputWidth}
											p={1}
											textAlign={'center'}
											data-testid={testId}
										/>
									</SliderNumberInput>

									<Slider
										focusThumbOnChange={false}
										value={slider}
										onChange={(value) =>
											handleOnChange(String(value), onChange)
										}
										step={step}
										min={inputMin}
										max={inputMax}
										isDisabled={disabled}
									>
										<SliderTrack>
											<SliderFilledTrack />
										</SliderTrack>
										<SliderThumb />
									</Slider>
								</Grid>
							</Box>
						)
					}}
				></Controller>
				<FormErrorMessage>{error}</FormErrorMessage>
			</FormControl>
		</Tooltip>
	)
}

export default UnitsSlider
