// TODO: Get data to implement the video recording within the event replay
// Packages
import { useState, useEffect, useMemo } from 'react'
import { Box, Center, Spinner } from '@chakra-ui/react'

// Alias
import { getSecondsBetween } from '@Utils/dates'
import Map from '@Components/Map/Map'
import SiteMarker from '@Components/Markers/SiteMarker/SiteMarker'
import Zones from '@Components/Site/MapLayers/Zones'
import VideoControls from '@UI/VideoPlayer/VideoControls'
import VideoPlayer from '@UI/VideoPlayer/VideoPlayer'
import { useGetVideoRecordingsQuery } from '@Store/analytics/analyticsApi'

// Relatives
import EventReplayDetections from './EventReplayDetection'
import EventReplayPanel from './EventReplayPanel'
import Installation from './Installation'

// Types
import type {
	EventSummary,
	SimplifiedSiteInstallation,
	VideoRecording,
} from '@Store/types'
import type { Dispatch, Ref, SetStateAction } from 'react'
import type { EventReplayScreenshotRef } from './EventReplayScreenshot'
import EventReplayScreenshot from './EventReplayScreenshot'
import VideoControlCameraChunks from '@/components/_UI/VideoPlayer/VideoControlCameraChunks'

type EventReplayProps = {
	eventId: number
	siteId: number
	currentTime: number
	setCurrentTime: (time: number) => void
	isPlaying: boolean
	setIsPlaying: Dispatch<SetStateAction<boolean>>
	setIsMapReady: (isReady: boolean) => void
	fullScreenRef: Ref<HTMLDivElement>
	screenshotRef: Ref<EventReplayScreenshotRef>
	isFullScreen: boolean
	eventSummary?: EventSummary
}

const EventReplay = ({
	eventId,
	siteId,
	currentTime,
	setCurrentTime,
	isPlaying,
	fullScreenRef,
	screenshotRef,
	isFullScreen,
	eventSummary,
	setIsPlaying,
	setIsMapReady,
}: EventReplayProps) => {
	const [currentDetectionIndex, setCurrentDetectionIndex] = useState<number>(0)
	const [seekTime, setSeekTime] = useState<number>(0)
	const { event, site, tracks: detections = [] } = eventSummary ?? {}
	const duration = event?.duration ?? 0
	const zones = site?.zones ?? []

	const eventCreatedAt = event?.created_at ?? ''
	const installationsWithLocations = useMemo(
		() =>
			(site?.sentries ?? []).filter(
				(sentry) => sentry?.sentry_locations?.length
			),
		[site?.sentries]
	)

	const recordingIds = (
		(event?.video_recordings as VideoRecording[]) ?? []
	).map((recording) => recording.id)

	const { data: recordings } = useGetVideoRecordingsQuery(
		{ recordingIds },
		{ skip: recordingIds.length < 1 }
	)

	const videoRecordings = (recordings ?? []).map((recording) => ({
		url: recording.url,
		startTime: getSecondsBetween(eventCreatedAt, recording.created_at),
		duration: recording.duration / 1000,
	}))

	const activeVideo = videoRecordings.find(
		({ startTime, duration }) =>
			currentTime > startTime && currentTime < startTime + duration
	)

	const setModifiedSeekTime = (value: number) => {
		if (activeVideo) {
			const videoSeekTime = value - activeVideo.startTime
			setSeekTime(videoSeekTime > 0 ? videoSeekTime : 0)
		}
	}

	const sentries = useMemo<{ [key: string]: SimplifiedSiteInstallation }>(
		() =>
			site?.sentries?.reduce(
				(acc, sentry) => ({ ...acc, [sentry.id]: sentry }),
				{}
			) ?? {},
		[site?.sentries]
	)

	// Handles the incrementation of detections
	useEffect(() => {
		const numberOfDetections = detections.length
		const timePerDetection = duration / numberOfDetections
		const newIndex = Math.floor(currentTime / timePerDetection)
		if (newIndex < numberOfDetections && newIndex !== currentDetectionIndex) {
			setCurrentDetectionIndex(newIndex)
		}
	}, [currentTime, detections?.length, duration, currentDetectionIndex])

	const videoControlCameraChunks = useMemo(
		() => (
			<VideoControlCameraChunks
				videoRecordings={videoRecordings}
				duration={duration}
			/>
		),
		[videoRecordings, duration]
	)

	return (
		<Box
			h={isFullScreen ? 'calc(100vh - 50px)' : 'calc(100vh - 200px)'}
			bgColor={!eventSummary ? 'input_bg' : undefined}
			position='relative'
		>
			{!eventSummary && (
				<Center h='90%'>
					<Spinner
						boxSize={100}
						thickness='5px'
						speed='0.6s'
						color='gray.600'
					/>
				</Center>
			)}
			{site && event && (
				<>
					<Box h='calc(100% - 55px)' position='relative'>
						<Map
							center={[site.latitude, site.longitude]}
							whenReady={() => setIsMapReady(true)}
							id='event-replay-map'
							attributionControl={true}
							showMeasurementTools
						>
							<EventReplayScreenshot ref={screenshotRef} />
							<SiteMarker position={[site.latitude, site.longitude]} />
							<EventReplayDetections
								event={event}
								detection={detections[currentDetectionIndex]}
								detections={detections}
							/>
							{installationsWithLocations.map((sentry) => (
								<Installation
									key={sentry.id}
									name={sentry.name}
									sentryType={sentry.sentry_type}
									duration={duration}
									currentTime={currentTime}
									locations={sentries[sentry.id]?.sentry_locations ?? []}
								/>
							))}
							<Zones isEventReplay eventReplayZones={zones} siteId={siteId} />
						</Map>
						{activeVideo && (
							<Box position='absolute' bottom={0} left={7} zIndex={9999} my={3}>
								<VideoPlayer
									url={activeVideo.url}
									width='400px'
									height='200px'
									playing={isPlaying}
									seekTime={seekTime}
									allowSpinner={true}
								/>
							</Box>
						)}
					</Box>
					<EventReplayPanel
						detection={detections[currentDetectionIndex]}
						eventId={eventId}
					/>
					<VideoControls
						isPlaying={isPlaying}
						setIsPlaying={setIsPlaying}
						duration={duration}
						currentTime={currentTime}
						setCurrentTime={setCurrentTime}
						setSeekTime={setModifiedSeekTime}
						isDisabled={!eventSummary}
						ref={fullScreenRef}
						videoChunks={videoControlCameraChunks}
					/>
				</>
			)}
		</Box>
	)
}

export default EventReplay
