// React
import { useTranslation } from 'react-i18next'
// Chakra
import { Box, Flex, Text } from '@chakra-ui/react'
// Components
import ActionSection from '../../ActionSection'
import ActiveSensorButton from '../../ActiveSensorButton'
import RfMenu from './RfMenu'
import GpsModuleError from '../GpsCompassDevices/GpsModuleError'
import DeviceHeader from '../DeviceHeader'
import Chunk from '../Chunk'
// Utils
import useAuth from '@Hooks/useAuth'
import { formatDirection } from '@Utils/formatUtils'
import { DEVICE_FAULT_STATE } from '@Constants/devices'
// Types
import type { RfSensor, SiteInstallation } from '@Store/types'
type RfContentProps = {
	device: RfSensor
	acceptLocationUpdates: SiteInstallation['accept_location_updates']
	siteId: number
	installationId: number
}

const RfContent = ({
	device,
	acceptLocationUpdates,
	siteId,
	installationId,
}: RfContentProps) => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'devicesContent',
	})

	const { isSiteManager } = useAuth()

	const isGpsModuleError = (device: RfSensor) => {
		return acceptLocationUpdates ? device.direction === 400 : false
	}

	return (
		<Box key={device.id} paddingBlockEnd={4}>
			<Flex paddingInlineStart={2} marginBlockEnd={1}>
				<Flex isTruncated flex={2}>
					<DeviceHeader device={device} deviceType='rfsensor'>
						{!device.active && device.state !== DEVICE_FAULT_STATE && (
							<Text
								fontWeight={400}
								color='label_color'
								data-testid='detections-disabled'
							>
								{t('detectionDisabled')}
							</Text>
						)}
						{device.state === DEVICE_FAULT_STATE && (
							<Text
								fontWeight={400}
								color='label_color'
								data-testid='rf-sensor-faulty'
							>
								{t('rfSensorFaulty')}
							</Text>
						)}
					</DeviceHeader>
				</Flex>
				<ActionSection>
					<ActiveSensorButton deviceId={device.id} />
					{isSiteManager && (
						<RfMenu
							device={device}
							siteId={siteId}
							installationId={installationId}
						/>
					)}
				</ActionSection>
			</Flex>

			{/* GPS module 400 error */}
			{isGpsModuleError(device) && <GpsModuleError />}

			<Flex flexWrap='wrap' paddingInlineStart={8} paddingBlockEnd={4}>
				<Chunk
					units='deg'
					label={t('content.direction')}
					value={formatDirection(device.direction) ?? '-'}
					testId='direction'
				/>
				{device.model === 'rf_one' && (
					<Chunk
						units='deg'
						label={t('content.tilt')}
						value={device.tilt?.toFixed(2)}
						testId='tilt'
					/>
				)}
				<Chunk
					label={t('content.serial')}
					value={device.serial_number}
					testId='serial-number'
				/>
				<Chunk
					label={t('content.version')}
					value={device.software_version ?? '-'}
					testId='software-version'
				/>
				{device.connection_type !== 'v1' && (
					<Chunk
						label={t('content.detection_mode')}
						value={device.detection_mode ?? '-'}
						testId='detection-mode'
					/>
				)}
				<Chunk
					label={t('content.activeFilters')}
					value={
						(device.RfFilter ?? []).filter(
							(filter) => filter.active && !filter.whitelist
						).length
					}
					testId='active-filters'
				/>
			</Flex>
		</Box>
	)
}

export default RfContent
