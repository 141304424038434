// Packages
import { Box, Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

// Alias
import { formatProbability, formatThreatLevel } from '@/utils/formatUtils'
import Tooltip from '@UI/Tooltip/Tooltip'
import ThreatContributionsTooltip from './ThreatContributionsTooltip'
import type { ThreatContribution } from '@/store/types'

export type DetailsSensorFusionProps = {
	confidence?: number
	isStale?: boolean
	threat?: number
	threatLevel?: 'high' | 'medium' | 'low'
	threatContributions?: ThreatContribution | null
}

const DetailsSensorFusion = ({
	confidence = 0,
	isStale = false,
	threat = 0,
	threatContributions,
	threatLevel = 'low',
}: DetailsSensorFusionProps) => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'notifications.detections.detectionDetail.headings',
	})

	let threatColor = 'white'
	if (threatLevel === 'medium' && !isStale) {
		threatColor = 'amber.500'
	} else if (threatLevel === 'high' && !isStale) {
		threatColor = 'error'
	}

	return (
		<Flex w='100%' flexWrap='wrap'>
			<Box w='50%' marginBlockEnd={2}>
				<Text fontSize='xs' color='headings'>
					{t('confidence')}
				</Text>
				<Text fontSize='sm'>{formatProbability(confidence) || '-'}</Text>
			</Box>
			<Box w='50%' marginBlockEnd={2}>
				<Tooltip
					label={
						threatContributions && threat > 0 ? (
							<ThreatContributionsTooltip
								threatContributions={threatContributions}
							/>
						) : null
					}
					placement='bottom-start'
					type='info'
					background='panel_bg'
					width='300px'
				>
					<Text fontSize='xs' color='headings'>
						{t('threatLevel')}
					</Text>
				</Tooltip>
				<Text fontSize='sm' color={threatColor}>
					{formatThreatLevel(threat) ?? '-'}
				</Text>
			</Box>
		</Flex>
	)
}

export default DetailsSensorFusion
