import { useParams } from 'react-router-dom'
import { useContext } from 'react'

// Redux
import {
	useGetSiteLiveQuery,
	selectSiteData,
	selectSiteMapCenter,
	selectSiteHasCamera,
} from '@Store/sites/sitesWsApi'
import { skipToken } from '@reduxjs/toolkit/query/react'

import { Flex } from '@chakra-ui/react'
import type { LatLngExpression } from 'leaflet'

// Site Components
import Map from '@Components/Map/Map'
import ConcentricMarker from '../Markers/ConcentricMarker/ConcentricMarker'
import SiteMarker from '../Markers/SiteMarker/SiteMarker'
import Installations from './MapLayers/Installations'
import RfIntersections from './MapLayers/RfIntersections'
import Detections from './MapLayers/Detections'
import Zones from './MapLayers/Zones'
import MapMarkers from './MapLayers/MapMarkers'
import Panels from './Panels'
import { SiteLoadError } from '../App/Preload'
import PreviewModes from './MapLayers/PreviewModes'
import RecordingToolOverlay from '../RecordingTool/RecordingToolOverlay'

// Hooks
import useShowPreview from './MapLayers/PreviewModes/useShowPreviewMode'
import { useShowSectors } from '@Components/Sectors/useShowSectors'

// Janus
import JanusProvider from '../Janus/JanusProvider'
import ErrorBoundary from '../App/ErrorHandling/ErrorBoundary'
import RecordingToolContext from '../RecordingTool/RecordingToolContext'

// Site
const Site = () => {
	const { siteId: siteIdParam } = useParams()
	const siteId = Number(siteIdParam)

	const { isRecording } = useContext(RecordingToolContext)
	const { showRfIntersection } = useShowSectors()

	// Site Data
	const {
		isFetching,
		isLoading,
		isError,
		isSuccess,
		// refetch,
		mapCenter,
		hasCamera,
		...site
	} = useGetSiteLiveQuery(siteId > 0 ? siteId : skipToken, {
		selectFromResult: ({
			isFetching,
			isLoading,
			isError,
			isSuccess,
			data,
		}) => ({
			isFetching,
			isLoading,
			isError,
			isSuccess,
			mapCenter: selectSiteMapCenter(data),
			hasCamera: selectSiteHasCamera(data),
			...selectSiteData(data),
		}),
	})

	// Previewing Sensor?
	const { isPreviewRoute } = useShowPreview()

	// TODO: render a spinner?
	if (isFetching || isLoading) return <p>Loading</p>
	// Handle bad siteId
	else if (Number.isNaN(siteId)) return <SiteLoadError />
	else if (
		isSuccess &&
		site.latitude !== undefined &&
		site.longitude !== undefined
	) {
		return (
			<JanusProvider debug={false} disabled={!hasCamera}>
				<Flex w='100%' h='100%' position='relative'>
					{/*TODO: Swap panels and map when Detection Notification panel will be done*/}
					<Panels siteId={siteId} siteMode={site.mode} />
					<ErrorBoundary quiet>
						<Map
							center={mapCenter as LatLngExpression}
							zoom={site.zoom_level}
							scrollWheelZoom={true}
							showMeasurementTools={true}
							showMapLegend
						>
							{isPreviewRoute ? (
								<ErrorBoundary quiet>
									<PreviewModes />
								</ErrorBoundary>
							) : (
								<>
									<ErrorBoundary quiet>
										<SiteMarker position={[site.latitude, site.longitude]} />
									</ErrorBoundary>
									<ErrorBoundary quiet>
										<Installations siteId={siteId} />
									</ErrorBoundary>
									<ErrorBoundary quiet>
										<Detections siteId={siteId} />
									</ErrorBoundary>
									<ErrorBoundary quiet>
										<Zones siteId={siteId} />
									</ErrorBoundary>
									<ErrorBoundary quiet>
										<MapMarkers siteId={siteId} />
									</ErrorBoundary>
									<ErrorBoundary quiet>
										<ConcentricMarker siteId={siteId} />
									</ErrorBoundary>
									{showRfIntersection && (
										<ErrorBoundary quiet>
											<RfIntersections siteId={siteId} />
										</ErrorBoundary>
									)}
								</>
							)}
						</Map>
					</ErrorBoundary>
					{isRecording && <RecordingToolOverlay />}
				</Flex>
			</JanusProvider>
		)
	}
	// Handle fetch error
	else if (isError) return <SiteLoadError />
	// TODO: keep looking for errors to handle
	else return <p>Unknown error</p>
}

export default Site
