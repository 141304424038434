// Packages
import { DatePicker as DatePickerArk } from '@ark-ui/react'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'

const DatePickerDays = () => {
	return (
		<DatePickerArk.View view='day'>
			<DatePickerArk.Context>
				{(datePicker) => (
					<>
						<DatePickerArk.ViewControl>
							<DatePickerArk.PrevTrigger>
								<MdKeyboardArrowLeft size={20} />
							</DatePickerArk.PrevTrigger>
							<DatePickerArk.ViewTrigger>
								<DatePickerArk.RangeText />
							</DatePickerArk.ViewTrigger>
							<DatePickerArk.NextTrigger>
								<MdKeyboardArrowRight size={20} />
							</DatePickerArk.NextTrigger>
						</DatePickerArk.ViewControl>
						<DatePickerArk.Table>
							<DatePickerArk.TableHead>
								<DatePickerArk.TableRow>
									{datePicker.weekDays.map((weekDay, id) => (
										<DatePickerArk.TableHeader key={id}>
											{weekDay.narrow}
										</DatePickerArk.TableHeader>
									))}
								</DatePickerArk.TableRow>
							</DatePickerArk.TableHead>
							<DatePickerArk.TableBody>
								{datePicker.weeks.map((week, id) => (
									<DatePickerArk.TableRow key={id}>
										{week.map((day, id) => (
											<DatePickerArk.TableCell key={id} value={day}>
												<DatePickerArk.TableCellTrigger>
													{day.day}
												</DatePickerArk.TableCellTrigger>
											</DatePickerArk.TableCell>
										))}
									</DatePickerArk.TableRow>
								))}
							</DatePickerArk.TableBody>
						</DatePickerArk.Table>
					</>
				)}
			</DatePickerArk.Context>
		</DatePickerArk.View>
	)
}

export default DatePickerDays
