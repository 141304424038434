// React
import { useNavigate, useParams } from 'react-router-dom'
// Chakra
import { IconButton } from '@chakra-ui/react'
import { HiMiniEye, HiMiniEyeSlash } from 'react-icons/hi2'
// Components
import Tooltip from '@UI/Tooltip/Tooltip'
import { useTranslation } from 'react-i18next'
// Types
type ZoneContentWrapperProps = {
	zoneId: number
}

const ThreatConfigZonesActiveButton = ({ zoneId }: ZoneContentWrapperProps) => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'threatAnalysis',
	})
	const navigate = useNavigate()
	const { siteId: siteIdParam, zoneId: activeZoneIdParam } = useParams()
	const siteId = Number(siteIdParam)
	const activeZoneId = Number(activeZoneIdParam)
	const isActive = activeZoneId === zoneId

	const handleToggleSelectedDevice = () => {
		const pathWithId = `/${siteId}/settings/threat/${zoneId}`
		const toggledPathWithId = `/${siteId}/settings/threat/${!zoneId ? zoneId : ''}`
		navigate(isActive ? toggledPathWithId : pathWithId)
	}

	return (
		<Tooltip label={isActive ? t('tooltips.deselect') : t('tooltips.select')}>
			<IconButton
				icon={isActive ? <HiMiniEyeSlash size={16} /> : <HiMiniEye size={16} />}
				variant='icon'
				aria-label='highlight active zone'
				isDisabled={false}
				onClick={handleToggleSelectedDevice}
			/>
		</Tooltip>
	)
}

export default ThreatConfigZonesActiveButton
