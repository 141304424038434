import { useEffect, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// Form
import { useForm, FormProvider } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import type { TypeOf } from 'zod'

// Chakra
import { Box, Flex, Heading } from '@chakra-ui/react'
import { MdHome } from 'react-icons/md'
import Headings from '@UI/Headings/Headings'
import FormHeader from '../FormHeader'
import Field from '@Components/FormElements'
import Accordion from '@Components/FormElements/Accordion/Accordion'

// Store
import {
	useGetSiteQuery,
	useGetSiteInstallationQuery,
} from '@Store/sites/sitesApi'
import {
	selectSiteInstallationStatusColor,
	useGetSiteLiveQuery,
} from '@/store/sites/sitesWsApi'
import {
	useGetCameraQuery,
	useCreateCameraMutation,
	useUpdateCameraMutation,
	useMoveCameraHomeMutation,
} from '@Store/cameras/camerasApi'
import { useAppDispatch } from '@Store/index'
import { updateSensorPreview } from '@Store/ui/uiSlice'
import { skipToken } from '@reduxjs/toolkit/query'

// Schema
import type { SensorStatusColor } from '@Store/types'
import { sensorStatusColorMap, type Camera } from '@Store/types'
import {
	cameraFormSchema,
	ADD_CAMERA_DEFAULT_VALUES,
} from './CameraForm.schema'
// Components
import IconButton from '@UI/IconButton/IconButton'
import FormWrapper from '@Components/FormElements/FormWrapper/FormWrapper'
import { FormButtons } from '@Components/FormElements/FormButtons/FormButtons'

import { useExpandFormAccordion } from '@Hooks/useExpandFormAccordion'
import ErrorBoundary from '@/components/App/ErrorHandling/ErrorBoundary'
import { CAMERA_TYPES } from '@Constants/cameras'
import StatusIcon from '@/components/_UI/StatusIcon/StatusIcon'
import useAuth from '@Hooks/useAuth'
import type { FormSelectValueType } from '@/components/FormElements/Select/Select'

type CameraFormProps = {
	siteId: number
	installationId: number
	cameraId?: number
}

const CameraForm = ({ siteId, installationId, cameraId }: CameraFormProps) => {
	const { t } = useTranslation('forms', { keyPrefix: 'cameraForm' })
	const isEditForm = !!cameraId
	const {
		isLoading,
		isError,
		isSuccess,
		refetch,
		data: camera,
	} = useGetCameraQuery(siteId && cameraId ? { siteId, cameraId } : skipToken, {
		refetchOnMountOrArgChange: true,
	})

	// Looks for props passed through from navigate() via Unregistered Sensors
	const { state } = useLocation()
	const addCameraDefaultValues = {
		...ADD_CAMERA_DEFAULT_VALUES,
		...state,
		sentry_id: installationId,
	} as unknown as Camera

	const defaultValues = isEditForm ? camera : addCameraDefaultValues

	return (
		<FormWrapper
			entity={t('entity')}
			isEditForm={isEditForm}
			isLoading={isLoading}
			isError={isError}
			isSuccess={isSuccess}
			refetch={refetch}
		>
			{defaultValues && (
				<Form
					key={cameraId}
					defaultValues={defaultValues}
					isEditForm={isEditForm}
					siteId={siteId}
					installationId={installationId}
					cameraId={cameraId}
				/>
			)}
		</FormWrapper>
	)
}

const Form = ({
	defaultValues,
	isEditForm,
	siteId,
	installationId,
	cameraId,
}: {
	defaultValues: Camera
	isEditForm: boolean
	siteId: number
	installationId: number
	cameraId?: number
}) => {
	const navigate = useNavigate()

	const ref = useRef(null)

	// Translations
	const { t } = useTranslation('forms', { keyPrefix: 'cameraForm' })

	type Schema = TypeOf<typeof cameraFormSchema>

	const methods = useForm<Schema>({
		resolver: zodResolver(cameraFormSchema),
		defaultValues,
	})
	const {
		register,
		formState: { errors, isSubmitting, isDirty },
		handleSubmit,
		setValue,
		setError,
		watch,
	} = methods

	const { statusColor } = useGetSiteLiveQuery(siteId, {
		selectFromResult: ({ data }) => ({
			statusColor: selectSiteInstallationStatusColor(data, installationId),
		}),
	})

	const { expandedIndex, setExpandedIndex, errorIndexes } =
		useExpandFormAccordion(ref, errors)

	// Handle absolute offset values
	const { installationName, sentryDirection, sentryHeightOffset } =
		useGetSiteInstallationQuery(
			{ siteId, installationId },
			{
				selectFromResult: ({ data, isSuccess }) => ({
					installationName: data?.name,
					sentryDirection: isSuccess ? data?.direction : 0,
					sentryHeightOffset: isSuccess ? data?.height_offset_to_site : 0,
				}),
			}
		)

	const { siteHeight } = useGetSiteQuery(siteId, {
		skip: !siteId,
		selectFromResult: ({ data, isSuccess }) => ({
			siteHeight: isSuccess ? data?.height : 0,
		}),
	})

	const [
		directionOffset,
		heightOffsetToInstallation,
		minFovAngle,
		maxFovAngle,
	] = watch([
		'direction_offset',
		'height_offset_to_installation',
		'min_fov_angle',
		'max_fov_angle',
	])

	const absoluteDirection = ((directionOffset + sentryDirection) % 360).toFixed(
		2
	)
	const absoluteHeight = (
		heightOffsetToInstallation +
		sentryHeightOffset +
		siteHeight
	).toFixed(2)

	const dispatch = useAppDispatch()
	useEffect(() => {
		dispatch(
			updateSensorPreview({
				directionOffset,
				// reset to zero while setting up the Camera
				ptz_pan: 0,
				ptz_zoom: 0,
				minFovAngle,
				maxFovAngle,
			})
		)
		return () => {
			dispatch(updateSensorPreview(null))
		}
	}, [sentryDirection, directionOffset, dispatch, minFovAngle, maxFovAngle])

	const [createCamera] = useCreateCameraMutation()
	const [updateCamera] = useUpdateCameraMutation()
	const [moveCameraHome] = useMoveCameraHomeMutation()

	const handleGoToHome = () => {
		if (cameraId)
			moveCameraHome({ siteId, cameraId }).catch((reason: any) => {
				console.error('camera reset', reason)
			})
	}
	const camerasRoute = `/${siteId}/installations/${installationId}/cameras`

	const handleSave = async (data: Schema) => {
		try {
			if (isEditForm) {
				await updateCamera({
					siteId: Number(siteId),
					id: Number(cameraId),
					...data,
				}).unwrap()
			} else {
				await createCamera({ siteId: Number(siteId), ...data }).unwrap()
			}
			navigate(camerasRoute)
		} catch (e: unknown) {
			const errors = e as { [name in keyof Schema]: string }
			for (const field in errors) {
				setError(field as keyof Schema, {
					type: 'custom',
					message: errors[field as keyof Schema] as string,
				})
			}
		}
	}

	const handleCancel = () => navigate(camerasRoute)
	const { isAdmin } = useAuth()

	return (
		<>
			<FormHeader
				title={
					isEditForm ? t('headings.cameraSettings') : t('headings.addCamera')
				}
				backRoute={camerasRoute}
			/>
			<ErrorBoundary>
				<FormProvider {...methods}>
					<form onSubmit={handleSubmit(handleSave)} ref={ref}>
						<Flex marginBlockStart={3}>
							<StatusIcon
								color={sensorStatusColorMap[statusColor as SensorStatusColor]}
								testId={`${installationId}-installation-status-color-${statusColor}`}
							/>
							<Heading
								color='white'
								alignContent='center'
								marginInlineStart={2}
								size='xs'
								data-testid={`installation-${installationId}`}
							>
								{installationName}
							</Heading>
						</Flex>
						<Field.Divider title={t('headings.generalParameters')} />
						<Field.TextInput
							title={t('api.name')}
							register={register('name')}
							error={errors?.name?.message}
							testId='name'
						/>
						<Field.Select
							title={t('api.camera_type')}
							options={CAMERA_TYPES}
							register={register('camera_type')}
							disabled={isEditForm}
							tooltip={isEditForm ? t('tooltips.camera_type_disabled') : ''}
							error={errors?.camera_type?.message}
						/>
						<Field.TextInput
							title={t('api.serialNumber')}
							register={register('serial_number')}
							disabled={isEditForm}
							tooltip={isEditForm ? t('tooltips.serial_number_disabled') : ''}
							error={errors?.serial_number?.message}
							testId='serial-number'
						/>

						<Accordion.Container
							setExpandedIndex={setExpandedIndex}
							index={expandedIndex}
						>
							<Accordion.Item
								title={t('headings.calibrationParameters')}
								testId='calibration-parameters'
								isError={errorIndexes.has(0)}
							>
								{isAdmin && (
									<>
										<Box position='relative'>
											<Field.UnitsSlider
												units='deg'
												title={t('api.tilt')}
												tooltip={t('tooltips.tilt')}
												min={-45}
												max={45}
												step={0.1}
												register={register('tilt', { valueAsNumber: true })}
												error={errors?.tilt?.message}
												testId='tilt'
											/>
										</Box>
										<Box position='relative'>
											<Field.UnitsSlider
												units='deg'
												title={t('api.roll')}
												tooltip={t('tooltips.roll')}
												min={-45}
												max={45}
												step={0.1}
												register={register('roll', { valueAsNumber: true })}
												error={errors?.roll?.message}
												testId='roll'
											/>
										</Box>
									</>
								)}
								<Field.UnitsSlider
									isAbsolute
									units='deg'
									customLabel={absoluteDirection}
									title={t('api.direction_offset')}
									tooltip={t('tooltips.direction_offset')}
									min={0}
									max={359.99}
									step={0.1}
									register={register('direction_offset', {
										valueAsNumber: true,
									})}
									error={errors?.direction_offset?.message}
									testId='direction-offset'
								/>
								<IconButton
									variant='ghost'
									icon={<MdHome />}
									aria-label={t('buttons.homePosition')}
									tooltip={t('tooltips.homePosition')}
									tooltipPlacement='bottom-start'
									testId='home-position'
									onClick={handleGoToHome}
									isDisabled={!isEditForm}
								/>
								{isAdmin && (
									<>
										<Box position='relative'>
											<Field.UnitsSlider
												units='m'
												title={t('api.north_offset_to_installation')}
												tooltip={t('tooltips.north_offset_to_installation')}
												min={-10}
												max={10}
												step={0.1}
												register={register('north_offset_to_installation', {
													valueAsNumber: true,
												})}
												error={errors?.north_offset_to_installation?.message}
												testId='north_offset_to_installation'
											/>
										</Box>
										<Box position='relative'>
											<Field.UnitsSlider
												units='m'
												title={t('api.east_offset_to_installation')}
												tooltip={t('tooltips.east_offset_to_installation')}
												min={-10}
												max={10}
												step={0.1}
												register={register('east_offset_to_installation', {
													valueAsNumber: true,
												})}
												error={errors?.east_offset_to_installation?.message}
												testId='east_offset_to_installation'
											/>
										</Box>
									</>
								)}
								<Field.UnitsSlider
									isAbsolute
									customLabel={absoluteHeight}
									units='m'
									title={t('api.height_offset_to_installation')}
									tooltip={t('tooltips.height_offset_to_installation')}
									min={-100}
									max={200}
									step={0.1}
									register={register('height_offset_to_installation', {
										valueAsNumber: true,
									})}
									error={errors?.height_offset_to_installation?.message}
									testId='height-offset-to-installation'
								/>
							</Accordion.Item>
							<Accordion.Item
								title={t('headings.advancedParameters')}
								testId='advanced-parameters'
								isError={errorIndexes.has(1)}
							>
								<Headings.SectionSubheading title={t('headings.credentials')} />
								<Field.TextInput
									title={t('api.username')}
									register={register('username')}
									error={errors?.username?.message}
									testId='username'
								/>
								<Field.TextInput
									type='password'
									placeholder='••••••••••'
									title={t('api.password')}
									register={register('password')}
									error={errors?.password?.message}
									testId='password'
								/>
								<Field.TextInput
									title={t('api.mac_address')}
									register={register('mac_address')}
									error={errors?.mac_address?.message}
									disabled
									testId='mac-address'
								/>
								<Field.TextInput
									title={t('api.ip')}
									register={register('ip')}
									error={errors?.ip?.message}
									disabled
									testId='ip'
								/>
								<Headings.SectionSubheading
									title={t('headings.opticalSensorSpecifications')}
								/>
								<Field.RangeSlider
									units='deg'
									title={t('api.fov_angle_range')}
									tooltip={t('tooltips.fov_angle_range')}
									defaultValue={[
										defaultValues.min_fov_angle,
										defaultValues.max_fov_angle,
									]}
									step={0.1}
									min={0.5}
									max={160}
									onChangeEnd={(value: [number, number]) => {
										setValue('min_fov_angle', value[0], {
											shouldDirty: true,
											shouldValidate: true,
											shouldTouch: true,
										})
										setValue('max_fov_angle', value[1], {
											shouldDirty: true,
											shouldValidate: true,
											shouldTouch: true,
										})
									}}
									error={[
										errors?.min_fov_angle?.message,
										errors?.max_fov_angle?.message,
									]
										.filter((error) => !!error)
										.join(', ')}
									testId='fov-angle-range'
								/>
								<Field.RangeSlider
									units='deg'
									title={t('api.tilt_angle_range')}
									tooltip={t('tooltips.tilt_angle_range')}
									defaultValue={[
										defaultValues.min_tilt_angle,
										defaultValues.max_tilt_angle,
									]}
									step={0.1}
									min={-130}
									max={130}
									onChangeEnd={(value: [number, number]) => {
										setValue('min_tilt_angle', value[0], {
											shouldDirty: true,
											shouldValidate: true,
											shouldTouch: true,
										})
										setValue('max_tilt_angle', value[1], {
											shouldDirty: true,
											shouldValidate: true,
											shouldTouch: true,
										})
									}}
									error={[
										errors?.min_tilt_angle?.message,
										errors?.max_tilt_angle?.message,
									]
										.filter((error) => !!error)
										.join(', ')}
									testId='tilt-angle-range'
								/>
								{/*TODO: Add Reach and Zoom calculation*/}
								{/*TODO: Syntax for tooltips with values : {t('tooltips.reach', { reach: '52' })}*/}
								<Headings.SectionSubheading
									title={t('headings.trackingParameters')}
								/>
								<Field.Select
									title={t('api.auto_track')}
									tooltip={t('tooltips.auto_track')}
									options={
										t('auto_track_options', {
											returnObjects: true,
										}) as FormSelectValueType[]
									}
									register={register('auto_track')}
									error={errors?.auto_track?.message}
								/>
								<Field.Slider
									title={t('api.droneoptid_prob_threshold')}
									tooltip={t('tooltips.droneoptid_prob_threshold')}
									min={0}
									max={100}
									step={1}
									register={register('droneoptid_prob_threshold', {
										valueAsNumber: true,
									})}
									error={errors?.droneoptid_prob_threshold?.message}
									testId='probability-threshold'
								/>
								<Field.Slider
									title={t('api.tracking_slew_hfov_percent')}
									tooltip={t('tooltips.tracking_slew_hfov_percent')}
									min={0}
									max={25}
									step={0.1}
									register={register('tracking_slew_hfov_percent', {
										valueAsNumber: true,
									})}
									error={errors?.tracking_slew_hfov_percent?.message}
									testId='tracking-slew-hfov-percent'
								/>
								<Flex mt={2}>
									<Field.Switch
										title={t('api.tracking_auto_focus')}
										tooltip={t('tooltips.tracking_auto_focus')}
										register={register('tracking_auto_focus')}
										error={errors?.tracking_auto_focus?.message}
										testId='auto-focus-switch'
									/>

									<Field.Switch
										title={t('api.tracking_manual_focus')}
										tooltip={t('tooltips.tracking_manual_focus')}
										register={register('tracking_manual_focus')}
										error={errors?.tracking_manual_focus?.message}
										testId='manual-focus-switch'
									/>
								</Flex>
								<Field.Slider
									title={t('api.tracking_slew_retry_delay')}
									tooltip={t('tooltips.tracking_slew_retry_delay')}
									min={0}
									max={60}
									step={0.5}
									register={register('tracking_slew_retry_delay', {
										valueAsNumber: true,
									})}
									error={errors?.tracking_slew_retry_delay?.message}
									testId='retry-delay'
								/>
								<Field.Select
									title={t('api.tracking_control_loop_speed')}
									tooltip={t('tooltips.tracking_control_loop_speed')}
									options={
										t('tracking_control_loop_speed', {
											returnObjects: true,
										}) as FormSelectValueType[]
									}
									register={register('tracking_control_loop_speed')}
									error={errors?.tracking_control_loop_speed?.message}
								/>
								<Headings.SectionSubheading
									title={t('headings.recordingParameters')}
								/>
								<Field.Slider
									title={t('api.recording_max_duration')}
									tooltip={t('tooltips.recording_max_duration')}
									min={1}
									max={30}
									register={register('recording_max_duration', {
										valueAsNumber: true,
									})}
									error={errors?.recording_max_duration?.message}
									testId='recording-max-duration'
								/>
								<Field.Switch
									title={t('api.auto_record')}
									tooltip={t('tooltips.auto_record')}
									register={register('auto_record')}
									error={errors?.auto_record?.message}
									testId='auto-record-switch'
								/>
								<Headings.SectionSubheading
									title={t('headings.streamingParameters')}
								/>
								<Field.Slider
									title={t('api.streaming_bitrate')}
									tooltip={t('tooltips.streaming_bitrate')}
									min={1}
									max={5}
									step={0.5}
									register={register('streaming_bitrate', {
										valueAsNumber: true,
									})}
									error={errors?.streaming_bitrate?.message}
									testId='streaming-bitrate'
								/>
								<Field.Switch
									title={t('api.always_stream')}
									tooltip={t('tooltips.always_stream')}
									register={register('always_stream')}
									error={errors?.always_stream?.message}
									testId='always-stream-switch'
								/>
							</Accordion.Item>
						</Accordion.Container>
						<FormButtons
							isSubmitting={isSubmitting}
							isDirty={isDirty}
							handleCancel={handleCancel}
						/>
					</form>
				</FormProvider>
			</ErrorBoundary>
		</>
	)
}

export default CameraForm
