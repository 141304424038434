import { useState } from 'react'
import { Flex, IconButton } from '@chakra-ui/react'
import { MdOutlineCached } from 'react-icons/md'
import { GoDotFill } from 'react-icons/go'
import { useParams } from 'react-router-dom'

import { useTriggerNucCalibrationMutation } from '@Store/cameras/camerasApi'
import {
	selectSiteCameraById,
	useGetSiteLiveQuery,
} from '@Store/sites/sitesWsApi'

const BLOCK_NUC_BUTTON_TIMEOUT = 5000

const NucCalibrationButton = ({
	cameraId,
	isDisabled,
}: {
	cameraId: number
	isDisabled: boolean
}) => {
	const { siteId: siteIdParam } = useParams()
	const siteId = Number(siteIdParam)
	const [isPending, setIsPending] = useState<boolean>(false)

	const { isThermalMode, isSuccess } = useGetSiteLiveQuery(siteId, {
		selectFromResult: ({ data, isSuccess }) => ({
			isThermalMode:
				selectSiteCameraById(data, cameraId)?.active_stream === 'thermal',
			isSuccess,
		}),
	})

	const [triggerNucCalibration] = useTriggerNucCalibrationMutation()
	const handleClick = () => {
		setIsPending(true)
		triggerNucCalibration({ siteId, cameraId })
			.unwrap()
			.then(() => {
				setTimeout(() => setIsPending(false), BLOCK_NUC_BUTTON_TIMEOUT)
			})
			.catch((e) => {
				setIsPending(false)
				console.error('camera NUC calibration failed: ', e)
			})
	}

	return (
		isSuccess &&
		isThermalMode && (
			<IconButton
				icon={
					<Flex justifyContent={'center'} alignItems={'center'}>
						<MdOutlineCached size={24} />
						<GoDotFill
							size={6}
							style={{
								position: 'absolute',
								alignItems: 'center',
							}}
						/>
					</Flex>
				}
				variant='unstyled'
				onClick={handleClick}
				aria-label='trigger camera nuc'
				sx={{
					svg: { transform: 'scale(1.15)' },
				}}
				isDisabled={isDisabled || isPending}
			/>
		)
	)
}

export default NucCalibrationButton
