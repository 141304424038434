// React
import { useTranslation } from 'react-i18next'
// Chakra
import { Box, Flex, Text } from '@chakra-ui/react'
// Components
import ActionSection from '../../ActionSection'
import ActiveSensorButton from '../../ActiveSensorButton'
import DsxBandStatus from './DsxBandStatus'
import DisruptionPanel from './DisruptionPanel'
import DsxMenu from './DsxMenu'
import GpsModuleError from '../GpsCompassDevices/GpsModuleError'
import DeviceHeader from '../DeviceHeader'
import Chunk from '../Chunk'
// Utils
import useAuth from '@Hooks/useAuth'
import { formatDirection } from '@Utils/formatUtils'
import { DEVICE_FAULT_STATE } from '@Constants/devices'
// Types
import { type RfSensor, type SiteInstallation } from '@Store/types'
type DsxContentProps = {
	device: RfSensor
	acceptLocationUpdates: SiteInstallation['accept_location_updates']
	siteId: number
	installationId: number
}

const DsxContent = ({
	device,
	acceptLocationUpdates = false,
	siteId,
	installationId,
}: DsxContentProps) => {
	const { isSiteManager } = useAuth()

	const { t } = useTranslation('panels', {
		keyPrefix: 'devicesContent',
	})

	// Show GPS Error module when sensor direction receives 400
	const isGpsModuleError = (device: RfSensor) => {
		return acceptLocationUpdates ? device.direction === 400 : false
	}

	return (
		<>
			<Box key={device.id} paddingBlockEnd={4}>
				<Flex paddingInlineStart={2} marginBlockEnd={1}>
					<DeviceHeader device={device} deviceType='dronesentryx'>
						{!device.active && device.state !== DEVICE_FAULT_STATE && (
							<Text
								fontWeight={400}
								color='label_color'
								data-testid='detections-disabled'
							>
								{t('detectionDisabled')}
							</Text>
						)}
						{device.state === DEVICE_FAULT_STATE && (
							<Text
								fontWeight={400}
								color='label_color'
								data-testid='rf-sensor-faulty'
							>
								{t('rfSensorFaulty')}
							</Text>
						)}
					</DeviceHeader>
					<ActionSection>
						<ActiveSensorButton deviceId={device.id} />
						{isSiteManager && (
							<DsxMenu
								device={device}
								siteId={siteId}
								installationId={installationId}
							/>
						)}
					</ActionSection>
				</Flex>
				{/* GPS module 400 error */}
				{isGpsModuleError(device) && <GpsModuleError />}
				<Flex flexWrap='wrap' paddingInlineStart={8} paddingBlockEnd={4}>
					<Chunk
						units='deg'
						label={t('content.direction')}
						value={formatDirection(device.direction) ?? '-'}
						testId='direction'
					/>
					<Chunk
						label={t('content.activeFilters')}
						value={
							(device.RfFilter ?? []).filter(
								(filter) => filter.active && !filter.whitelist
							).length
						}
						testId='active-filters'
					/>
					<Chunk
						label={t('content.serial')}
						value={device.serial_number}
						testId='serial-number'
					/>
					{device.software_version && (
						<Chunk
							label={t('content.version')}
							value={device.software_version ?? '-'}
							testId='software-version'
						/>
					)}
					{device.connection_type !== 'v1' && (
						<Chunk
							label={t('content.detection_mode')}
							value={device.detection_mode ?? '-'}
							testId='detection-mode'
						/>
					)}
				</Flex>
				{device.model === 'dsx_direct' && (
					<DsxBandStatus
						id={device.id}
						dsx={device}
						testId='dsx-mk1-band-status'
					/>
				)}
				{device.model === 'dsx_mk2' && (
					<DisruptionPanel
						siteId={siteId}
						sensorId={device.id}
						testId='dsx-mk2-band-status'
					/>
				)}
			</Box>
		</>
	)
}

export default DsxContent
