import { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { skipToken } from '@reduxjs/toolkit/query'

import {
	useReactTable,
	getCoreRowModel,
	type SortingState,
	type PaginationState,
	type ColumnFiltersState,
} from '@tanstack/react-table'
import { Flex, useDisclosure } from '@chakra-ui/react'
import {
	useGetEventSummaryQuery,
	useGetAlertsSummaryQuery,
} from '@Store/analytics/analyticsApi'
import { MdDownload } from 'react-icons/md'
import FetchError from '@UI/FetchError/FetchError'
import Button from '@UI/Button/Button'
import Headings from '@UI/Headings/Headings'
import TanStackTable from '@UI/TanStackTable/Table'
import Pagination from '@UI/TanStackTable/Pagination'
import Skeleton from '@UI/Skeleton/Skeleton'
import { getColumns } from './columnDefinitions'
import AlertsFilter from './AlertsFilter'
import EventDetailsModal from '../../TrackSummary/EventDetailsModal/EventDetailsModal'
import AlertContentModal from './AlertContentModal'

import { getSorting } from '@Utils/tables'
import { downloadCsvV2 } from '@Utils/download'
import type { AlertSummary } from '@Store/types'
import type { AlertFilters } from '@Store/analytics/types'

type AlertsDetailsTableProps = {
	isTactical?: boolean
	startTime: string
	endTime: string
	siteId: number
}

const AlertsDetailsTable = ({
	isTactical = false,
	startTime,
	endTime,
	siteId,
}: AlertsDetailsTableProps) => {
	const { t } = useTranslation('pages', {
		keyPrefix: 'analytics.alertsSummary',
	})

	const [alertDetails, setAlertDetails] = useState<AlertSummary>()
	const [alertContent, setAlertContent] = useState<string>('')

	const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 10,
	})

	const [sorting, setSorting] = useState<SortingState>([
		{ id: 'id', desc: true },
	])

	const [globalFilter, setGlobalFilter] = useState<string>('')
	const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])

	const pagination = useMemo(
		() => ({
			pageIndex,
			pageSize,
		}),
		[pageIndex, pageSize]
	)

	const filters = useMemo(
		() =>
			columnFilters.reduce(
				(acum, filter) => ({ ...acum, [filter.id]: filter.value }),
				{ source: '', recipients: '' }
			),
		[columnFilters]
	)

	const filterParams: AlertFilters = {
		filter_by_content: globalFilter,
		filter_by_recipients: filters.recipients,
		filter_by_types: filters.source,
	}

	const { isOpen, onOpen, onClose } = useDisclosure()
	const {
		isOpen: isOpenAlertContent,
		onOpen: onOpenAlertContent,
		onClose: onCloseAlertContent,
	} = useDisclosure()

	const handleOpenModal = (
		alert: AlertSummary,
		isAlertContent: boolean = false
	) => {
		if (isAlertContent) {
			setAlertContent(alert.content)
			onOpenAlertContent()

			return
		}

		setAlertDetails(alert)
		onOpen()
	}

	const handleDownloadReport = async () => {
		try {
			const url = `/api/reports/${siteId}/alerts/csv`
			await downloadCsvV2(url, {
				start_time: startTime,
				end_time: endTime,
				...filterParams,
			})
		} catch (error) {
			console.error('TODO: unhandled download alerts report error', error)
		}
	}

	const { data: eventSummary, isSuccess: getEventSuccess } =
		useGetEventSummaryQuery(
			alertDetails?.notificatable_id
				? { eventId: alertDetails.notificatable_id }
				: skipToken
		)

	const {
		isSuccess,
		isError,
		isLoading,
		isFetching,
		refetch,
		alerts,
		totalCount,
	} = useGetAlertsSummaryQuery(
		{
			siteId,
			format: 'table',
			startTime: startTime,
			endTime: endTime,
			page: pageIndex,
			perPage: pageSize,
			...filterParams,
			...getSorting(sorting),
		},
		{
			selectFromResult: ({
				isSuccess,
				isError,
				isLoading,
				isFetching,
				data,
			}) => ({
				isSuccess,
				isError,
				isLoading,
				isFetching,
				alerts: data?.records ?? [],
				totalCount: data?.meta.total,
			}),
		}
	)

	const pageCount = totalCount ? Math.ceil(totalCount / pageSize) : 0
	const columns = getColumns(handleOpenModal, isTactical)

	const table = useReactTable({
		columns,
		data: alerts,
		getCoreRowModel: getCoreRowModel(),
		pageCount: pageCount ?? -1,
		state: {
			pagination,
			globalFilter,
			columnFilters,
			sorting,
		},
		onPaginationChange: setPagination,
		onColumnFiltersChange: setColumnFilters,
		onGlobalFilterChange: setGlobalFilter,
		onSortingChange: setSorting,
		manualPagination: true,
		manualFiltering: true,
		manualSorting: true,
		enableSortingRemoval: false,
	})

	return (
		<>
			<Flex justifyContent='space-between' alignItems='center'>
				<Headings.ChartHeading title={t('alertsDetails')} />
				<Flex alignItems='center'>
					<Headings.FieldHeading title={t('report')} />
					<Button
						label={t('download')}
						testId='download'
						size='sm'
						leftIcon={<MdDownload />}
						onClick={handleDownloadReport}
					/>
				</Flex>
			</Flex>
			<AlertsFilter
				isTactical={isTactical}
				siteId={siteId}
				table={table}
				startTime={startTime}
				endTime={endTime}
			/>
			<Skeleton isLoaded={!isLoading && !isFetching}>
				{isSuccess && (
					<>
						<TanStackTable
							table={table}
							sortableColumns={['id', 'created_at']}
							testId='alerts-details-table'
						/>
						<Pagination table={table} totalCount={totalCount ?? 0} />
						{/* The same modal is used across the track summary and alerts summary tables */}
						{alertDetails && getEventSuccess && (
							<EventDetailsModal
								isOpen={isOpen}
								onClose={onClose}
								siteId={siteId}
								isAlert
								eventSummary={eventSummary}
								eventId={alertDetails.notificatable_id}
							/>
						)}
						{isOpenAlertContent && (
							<AlertContentModal
								isOpen={isOpenAlertContent}
								onClose={onCloseAlertContent}
								content={alertContent}
							/>
						)}
					</>
				)}
				{isError && (
					<FetchError refetch={refetch} errorMessage={t('fetchError')} />
				)}
			</Skeleton>
		</>
	)
}

export default AlertsDetailsTable
