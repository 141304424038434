// Packages
import { createIcon, Icon } from '@chakra-ui/react'

// Types
import type { Detection } from '@Store/types'

export const DroneIcon = createIcon({
	displayName: 'DroneIcon',
	viewBox: '0 0 50 50',
	path: (
		<>
			<path
				d='M15.3941 13.7996C18.0103 18.3778 21.9654 21.4846 25.1636 21.4846V25.4087H19.9314C19.9314 23.0516 17.6423 18.2961 13.1459 16.0479C13.1459 16.0479 13.968 15.6754 14.4948 15.1486C15.0216 14.6218 15.3941 13.7996 15.3941 13.7996Z'
				fill='currentColor'
			/>
			<path
				d='M13.7848 14.2391C11.8532 16.1707 9.43976 17.0885 8.83857 16.4873C8.23738 15.8861 9.31588 13.8329 11.2474 11.9014C13.179 9.96979 15.2322 8.8913 15.8334 9.49249C16.4346 10.0937 15.7163 12.3076 13.7848 14.2391Z'
				fill='currentColor'
			/>
			<path
				d='M34.6059 13.7996C31.9897 18.3778 28.0346 21.4846 24.8364 21.4846V25.4087H30.0686C30.0686 23.0516 32.3577 18.2961 36.8541 16.0479C36.8541 16.0479 36.032 15.6754 35.5052 15.1486C34.9784 14.6218 34.6059 13.7996 34.6059 13.7996Z'
				fill='currentColor'
			/>
			<path
				d='M36.2152 14.2391C38.1468 16.1707 40.5602 17.0885 41.1614 16.4873C41.7626 15.8861 40.6841 13.8329 38.7526 11.9014C36.821 9.96979 34.7678 8.8913 34.1666 9.49249C33.5654 10.0937 34.2837 12.3076 36.2152 14.2391Z'
				fill='currentColor'
			/>
			<path
				d='M15.3941 36.2003C18.0103 31.6221 21.9654 28.5154 25.1636 28.5154V24.5912H19.9314C19.9314 26.9483 17.6423 31.7038 13.1459 33.9521C13.1459 33.9521 13.968 34.3246 14.4948 34.8514C15.0216 35.3782 15.3941 36.2003 15.3941 36.2003Z'
				fill='currentColor'
			/>
			<path
				d='M13.7848 35.7608C11.8532 33.8292 9.43976 32.9114 8.83857 33.5126C8.23738 34.1138 9.31588 36.167 11.2474 38.0986C13.179 40.0302 15.2322 41.1086 15.8334 40.5075C16.4346 39.9063 15.7163 37.6924 13.7848 35.7608Z'
				fill='currentColor'
			/>
			<path
				d='M34.6059 36.2003C31.9897 31.6221 28.0346 28.5153 24.8364 28.5153V24.5912H30.0686C30.0686 26.9483 32.3577 31.7038 36.8541 33.952C36.8541 33.952 36.032 34.3245 35.5052 34.8513C34.9784 35.3781 34.6059 36.2003 34.6059 36.2003Z'
				fill='currentColor'
			/>
			<path
				d='M36.2152 35.7608C38.1468 33.8292 40.5602 32.9114 41.1614 33.5126C41.7626 34.1138 40.6841 36.167 38.7526 38.0985C36.821 40.0301 34.7678 41.1086 34.1666 40.5074C33.5654 39.9062 34.2837 37.6924 36.2152 35.7608Z'
				fill='currentColor'
			/>
		</>
	),
})

export const BirdIcon = createIcon({
	displayName: 'BirdIcon',
	viewBox: '0 0 50 50',
	path: (
		<>
			<path
				d='M26.1262 19.2965C26.1262 20.1365 25.5501 22.6779 25.0941 22.6779C24.6381 22.6779 24.062 20.1365 24.062 19.2965C24.062 18.4565 24.6381 17.7755 25.0941 17.7755C25.5501 17.7755 26.1262 18.4565 26.1262 19.2965Z'
				fill='currentColor'
			/>
			<path
				d='M25.0932 17.2594L25.5401 18.0335H24.6463L25.0932 17.2594Z'
				fill='currentColor'
			/>
			<path
				d='M17.0486 19.751C20.9705 21.1959 23.8034 20.5277 24.5774 20.0977C26.1255 21.9898 27.6789 26.2531 22.7249 27.4916C16.5325 29.0397 16.0165 26.2015 10.3401 24.9114C5.79892 23.8793 5.69571 22.7612 6.21175 22.3312C8.18991 20.8691 13.1267 18.3061 17.0486 19.751Z'
				fill='currentColor'
			/>
			<path
				d='M32.9514 19.751C29.0295 21.1959 26.3836 20.5277 25.6095 20.0977C24.0614 21.9898 22.3211 26.2531 27.2751 27.4916C33.4675 29.0397 33.9835 26.2015 39.6599 24.9114C44.2011 23.8793 44.3043 22.7612 43.7882 22.3312C41.8101 20.8691 36.8733 18.3061 32.9514 19.751Z'
				fill='currentColor'
			/>
			<path
				d='M23.5456 26.032H26.6418L28.964 31.1924C28.964 31.1924 26.8999 32.7405 25.0937 32.7405C23.2876 32.7405 21.4814 30.9344 21.4814 30.9344L23.5456 26.032Z'
				fill='currentColor'
			/>
		</>
	),
})

export const PlaneIcon = createIcon({
	displayName: 'PlaneIcon',
	viewBox: '0 0 50 50',
	path: (
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			// d='M19.0523 0.0829148C18.2038 0.833748 16.4704 3.19887 16.325 6.65271C16.325 6.65271 16.3142 9.81087 16.32 13.6953C15.9824 13.8781 15.1922 14.3068 14.1368 14.8842V14.6771C14.1368 13.8738 13.4856 13.2225 12.6822 13.2225C11.8789 13.2225 11.2277 13.8738 11.2277 14.6771V16.273C11.2277 16.3421 11.2325 16.4101 11.2419 16.4767C6.68219 19.0001 0.793082 22.3365 0.681875 22.7956C0.5 23.5464 0.5 25.6112 0.5 25.6112L16.356 21.1468C16.3836 24.04 16.43 26.4966 16.5068 27.4883C16.6174 28.9152 16.854 31.0398 17.2168 33.0143C15.6046 34.1517 12.434 36.4178 12.3847 36.6801C12.3186 37.032 12.3186 37.9999 12.3186 37.9999L17.9206 35.9666C18.2448 36.9786 18.622 37.7335 19.0523 38L19.9546 38V0.0829148C19.6637 -0.0672519 19.2318 0.0203453 19.0523 0.0829148ZM19.9547 0.0829789C20.8032 0.833812 22.5366 3.19894 22.682 6.65277C22.682 6.65277 22.6928 9.81295 22.687 13.6991C23.0479 13.8946 23.9064 14.3604 25.0455 14.9839V14.6771C25.0455 13.8738 25.6968 13.2225 26.5001 13.2225C27.3034 13.2225 27.9546 13.8738 27.9546 14.6771V16.273C27.9546 16.3743 27.9442 16.4733 27.9245 16.5688C32.4597 19.0805 38.2083 22.3421 38.3181 22.7956C38.5 23.5464 38.5 25.6112 38.5 25.6112L22.6509 21.1487C22.6234 24.0411 22.577 26.4969 22.5002 27.4884C22.3874 28.9437 22.1435 31.1248 21.7684 33.1321C23.4223 34.3011 26.386 36.4265 26.4336 36.6801C26.4998 37.032 26.4998 37.9999 26.4998 37.9999L21.0666 36.0279C20.7469 37.0091 20.3763 37.7389 19.9547 38.0001V0.0829789Z'
			d='M24.5523 6.08291C23.7038 6.83375 21.9704 9.19887 21.825 12.6527C21.825 12.6527 21.8142 15.8109 21.82 19.6953C21.4824 19.8781 20.6922 20.3068 19.6368 20.8842V20.6771C19.6368 19.8738 18.9856 19.2225 18.1822 19.2225C17.3789 19.2225 16.7277 19.8738 16.7277 20.6771V22.273C16.7277 22.3421 16.7325 22.4101 16.7419 22.4767C12.1822 25.0001 6.29308 28.3365 6.18187 28.7956C6 29.5464 6 31.6112 6 31.6112L21.856 27.1468C21.8836 30.04 21.93 32.4966 22.0068 33.4883C22.1174 34.9152 22.354 37.0398 22.7168 39.0143C21.1046 40.1517 17.934 42.4178 17.8847 42.6801C17.8186 43.032 17.8186 43.9999 17.8186 43.9999L23.4206 41.9666C23.7448 42.9786 24.122 43.7335 24.5523 44L25.4546 44V6.08291C25.1637 5.93275 24.7318 6.02035 24.5523 6.08291ZM25.4547 6.08298C26.3032 6.83381 28.0366 9.19894 28.182 12.6528C28.182 12.6528 28.1928 15.8129 28.187 19.6991C28.5479 19.8946 29.4064 20.3604 30.5455 20.9839V20.6771C30.5455 19.8738 31.1968 19.2225 32.0001 19.2225C32.8034 19.2225 33.4546 19.8738 33.4546 20.6771V22.273C33.4546 22.3743 33.4442 22.4733 33.4245 22.5688C37.9597 25.0805 43.7083 28.3421 43.8181 28.7956C44 29.5464 44 31.6112 44 31.6112L28.1509 27.1487C28.1234 30.0411 28.077 32.4969 28.0002 33.4884C27.8874 34.9437 27.6435 37.1248 27.2684 39.1321C28.9223 40.3011 31.886 42.4265 31.9336 42.6801C31.9998 43.032 31.9998 43.9999 31.9998 43.9999L26.5666 42.0279C26.2469 43.0091 25.8763 43.7389 25.4547 44.0001V6.08298Z'
			fill='currentColor'
		/>
	),
})

const HelicopterIcon = createIcon({
	displayName: 'HelicopterIcon',
	viewBox: '0 0 50 50',
	path: (
		<path
			d='M27.6911 7.97015C27.0216 6.99127 26.0798 6.02467 25 6.00046C23.9202 6.02467 22.9784 6.99127 22.3089 7.97015C21.5637 9.0597 21.2739 10.1642 21.1911 10.597C21.1248 10.9433 20.7494 14.8109 20.57 16.7015L7.75623 7.14925C7.28011 6.80597 6.61354 6.98806 6.26577 7.23881C5.83105 7.55224 5.95526 8.02985 6.45208 8.40299C6.62411 8.53219 15.795 14.8159 20.4665 18.0149V18.8955C20.018 19.0448 19.2286 19.3463 19.2452 19.5373C19.2617 19.7284 19.2521 22.3035 19.2452 23.5672C19.2728 23.7114 19.357 24 19.4729 24C19.5888 24 10.8199 30.1045 6.43138 33.209C6.22191 33.3571 5.97596 33.9254 6.20367 34.1791C6.46596 34.4714 7.17661 34.597 7.73553 34.3134C8.18266 34.0866 16.5886 27.6418 20.7356 24.4478H21.3774C21.5016 24.4478 21.5713 25.2108 21.6465 25.4925C21.9362 26.5787 23.1369 27.806 23.4474 28.0149C23.6958 28.1821 24.034 35.0697 24.172 38.4925H20.1146V40.5149H24.379L24.586 44H24.9379H25.0621H25.414L25.621 40.5149H29.8854V38.4925H25.828C25.966 35.0697 26.3041 28.1821 26.5526 28.0149C26.8631 27.806 28.0638 26.5787 28.3535 25.4925C28.4287 25.2108 28.4984 24.4478 28.6226 24.4478H29.2644C33.4114 27.6418 41.8173 34.0866 42.2645 34.3134C42.8234 34.597 43.534 34.4714 43.7963 34.1791C44.024 33.9254 43.7781 33.3571 43.5686 33.209C39.18 30.1045 30.4112 24 30.5271 24C30.643 24 30.7272 23.7114 30.7548 23.5672C30.7479 22.3035 30.7383 19.7284 30.7548 19.5373C30.7714 19.3463 29.982 19.0448 29.5335 18.8955V18.0149C34.205 14.8159 43.3759 8.53219 43.5479 8.40299C44.0447 8.02985 44.1689 7.55224 43.7342 7.23881C43.3865 6.98806 42.7199 6.80597 42.2438 7.14925L29.43 16.7015C29.2506 14.8109 28.8752 10.9433 28.8089 10.597C28.7261 10.1642 28.4363 9.0597 27.6911 7.97015Z'
			fill='currentColor'
		/>
	),
})

const UnknownIcon = createIcon({
	displayName: 'UnknownIcon',
	viewBox: '0 0 50 50',
	path: <circle cx='25' cy='25' r='4' fill='currentColor' />,
})

const LobIcon = createIcon({
	displayName: 'LobIcon',
	viewBox: '0 0 50 50',
	path: (
		<line
			x1='7.75'
			y1='24'
			x2='42.25'
			y2='24'
			stroke='currentColor'
			strokeWidth='2'
		/>
	),
})

const LobControllerIcon = createIcon({
	displayName: 'LobControllerIcon',
	viewBox: '0 0 50 50',
	path: (
		<line
			x1='7.75'
			y1='24'
			x2='42.25'
			y2='24'
			stroke='currentColor'
			strokeWidth='2'
			strokeDasharray='2 3 4 2'
		/>
	),
})

const ControllerIcon = createIcon({
	displayName: 'ControllerIcon',
	viewBox: '0 0 50 50',
	path: (
		<>
			<path
				d='M11 26.5C11 25.3954 11.8954 24.5 13 24.5H37C38.1046 24.5 39 25.3954 39 26.5V37.5C39 38.6046 38.1046 39.5 37 39.5H13C11.8954 39.5 11 38.6046 11 37.5V26.5ZM17 36.5C19.2091 36.5 21 34.7091 21 32.5C21 30.2909 19.2091 28.5 17 28.5C14.7909 28.5 13 30.2909 13 32.5C13 34.7091 14.7909 36.5 17 36.5ZM33 36.5C35.2091 36.5 37 34.7091 37 32.5C37 30.2909 35.2091 28.5 33 28.5C30.7909 28.5 29 30.2909 29 32.5C29 34.7091 30.7909 36.5 33 36.5ZM29 35.5H21V37.5H29V35.5Z'
				fill='currentColor'
			/>
			<rect x='16' y='10.5' width='2' height='13' rx='1' fill='currentColor' />
			<rect x='32' y='10.5' width='2' height='13' rx='1' fill='currentColor' />
		</>
	),
})

export type NotificationIconProps = {
	classification?: Detection['classification']
	showLob?: boolean
	color?: string
}

const NotificationIcon = ({
	classification,
	showLob,
	color = 'magenta',
}: NotificationIconProps) => {
	const props = { boxSize: '3rem', color }
	if (showLob) {
		switch (classification) {
			case 'controller':
				return <Icon as={LobControllerIcon} {...props} />
			default:
				return <Icon as={LobIcon} {...props} />
		}
	}

	switch (classification) {
		case 'drone':
			return <Icon as={DroneIcon} {...props} />
		case 'bird':
			return <Icon as={BirdIcon} {...props} />
		case 'plane':
			return <Icon as={PlaneIcon} {...props} />
		case 'helicopter':
			return <Icon as={HelicopterIcon} {...props} />
		case 'controller':
			return <Icon as={ControllerIcon} {...props} />
		default:
			return <Icon as={UnknownIcon} {...props} />
	}
}

export default NotificationIcon
