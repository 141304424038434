import {
	useGetSystemInfoQuery,
	selectIsOnPrem,
	useGetSystemStatsQuery,
	selectIsLogoSoteria,
} from '@/store/system/systemApi'
import { useTranslation } from 'react-i18next'
import { baseUrl } from '@Constants/api'

import {
	Box,
	Icon,
	Menu,
	MenuButton,
	MenuDivider,
	MenuGroup,
	MenuItem,
	MenuList,
	Text,
} from '@chakra-ui/react'
import {
	MdMiscellaneousServices,
	MdInfo,
	MdStorage,
	MdMessage,
	MdContactSupport,
} from 'react-icons/md'

import LicenseAgreement from './LicenseAgreement'
import SystemStatus from './SystemStatus'
import SoftwareVersions from './SoftwareVersions'
import SystemSettings from './SystemSettings'
import { getStatusColor } from '@Utils/functions'

const SettingsMenu = () => {
	const { isOnPrem, isSoteria } = useGetSystemInfoQuery(undefined, {
		selectFromResult: ({ data, isSuccess }) => ({
			isOnPrem: isSuccess ? selectIsOnPrem(data) : undefined,
			isSoteria: isSuccess ? selectIsLogoSoteria(data) : false,
		}),
	})

	const { t } = useTranslation('navigation', { keyPrefix: 'settingsMenu' })

	const handleFeedback = () =>
		document.location.replace('mailto:feedback@droneshield.com')

	const handleSupport = () =>
		document.location.replace('mailto:support@droneshield.com')

	const { data: stats } = useGetSystemStatsQuery()
	const statsNumbers = [
		Number(stats?.cpu_info.total_cpu_usage.toFixed(1)),
		Number(stats?.memory_info.memory_used_percent.toFixed(1)),
		Number(stats?.disk_usage.used_percent.toFixed(1)),
	]
	const badgeColor = getStatusColor(Math.max(...statsNumbers))
	const showBadge = badgeColor !== 'green'

	return (
		<Menu
			placement='bottom-start'
			strategy='fixed'
			gutter={1}
			autoSelect={false}
			variant='userMenu'
		>
			<MenuButton position='relative' data-testid='settings'>
				<Icon as={MdMiscellaneousServices} boxSize={6} />
				{showBadge && (
					<Box
						boxSize={2}
						bgColor={`status.${badgeColor}`}
						position='absolute'
						borderRadius='100%'
						top='0'
						right='0'
					/>
				)}
			</MenuButton>
			<MenuList>
				<MenuGroup title={t('headings.about')}>
					<MenuItem
						icon={<MdInfo size='1rem' />}
						alignItems='flex-start'
						as='div'
						userSelect='initial'
						_focus={{ background: 'unset' }}
						paddingBlockEnd={0}
						sx={{
							svg: {
								// control vertical alignment of the
								// icon when the versions block is long
								marginBlockStart: '0.5rem',
							},
						}}
					>
						<SoftwareVersions />
					</MenuItem>
					<MenuItem
						icon={<MdStorage size='1rem' />}
						as='div'
						userSelect='initial'
						_focus={{ background: 'unset' }}
						paddingBlockEnd={0}
					>
						<Text fontSize='xs' color='label_color'>
							{t('server')}
						</Text>
						<Text noOfLines={1}>{baseUrl}</Text>
					</MenuItem>
					{isOnPrem !== undefined && isOnPrem === false && !isSoteria && (
						<>
							<MenuItem
								icon={<MdMessage size='1rem' />}
								onClick={handleSupport}
								paddingBlockEnd={0}
							>
								<Text
									color='primary'
									fontWeight='bold'
									textDecoration='underline'
								>
									{t('emailSupport')}
								</Text>
							</MenuItem>
							<MenuItem
								icon={<MdContactSupport size='1rem' />}
								onClick={handleFeedback}
								lineHeight={1}
							>
								<Text
									color='primary'
									fontWeight='bold'
									textDecoration='underline'
								>
									{t('emailFeedback')}
								</Text>
							</MenuItem>
						</>
					)}
				</MenuGroup>
				<MenuDivider />
				<MenuGroup title={t('headings.systemStatus')}>
					<SystemStatus />
				</MenuGroup>
				<MenuDivider />
				<MenuGroup title={t('headings.actions')}>
					<SystemSettings />
					{!isSoteria && <LicenseAgreement />}
				</MenuGroup>
			</MenuList>
		</Menu>
	)
}

export default SettingsMenu
