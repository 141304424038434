// React
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
// Chakra
import {
	Box,
	Card,
	CardHeader,
	CardBody,
	Flex,
	Spacer,
	useDisclosure,
} from '@chakra-ui/react'
// Components
import DeleteModal from '@UI/Modals/DeleteModal'
import ActionSection from '../../ActionSection'
import EditButton from '../../EditButton'
import EditPalette from '../../EditPalette'
import DeviceHeader from '../DeviceHeader'
import Chunk from '../Chunk'
// Utils
import { formatDirection } from '@Utils/formatUtils'
// Store
import useAuth from '@Hooks/useAuth'
import { useDeleteGpsCompassMutation as useDeleteGpsMutation } from '@Store/gpsCompasses/gpsCompassesApi'
// Types
import { type GpsCompass } from '@Store/types'
type DsxContentProps = {
	devices: GpsCompass[]
}

const GpsCompassContent = ({ devices }: DsxContentProps) => {
	const { siteId: siteIdParam, installationId } = useParams()
	const siteId = Number(siteIdParam)

	const { t } = useTranslation('panels', {
		keyPrefix: 'devicesContent',
	})

	const baseRoute = `/${siteId}/installation/${installationId}`

	const [gpsToDelete, setDsxToDelete] = useState<{
		id: number
		name: string
	} | null>(null)

	const [deleteGps] = useDeleteGpsMutation()
	const { isOpen, onOpen, onClose } = useDisclosure()

	const handleOpenDeleteModal = (id: number, name: string) => {
		onOpen()
		setDsxToDelete((prev) => ({ ...prev, id, name }))
	}

	const handleDeleteDsx = async () => {
		try {
			if (gpsToDelete) {
				await deleteGps({ siteId, sensorId: gpsToDelete.id })
				onClose()
			}
		} catch (error) {
			console.error('Delete Gps Compass error', error)
		}
	}

	const handleCloseDeleteModal = () => {
		onClose()
		setDsxToDelete(null)
	}

	const { isSiteManager } = useAuth()

	return (
		<Card variant='devices'>
			{isSiteManager && (
				<CardHeader textAlign='right'>
					<Spacer />
					<EditButton
						to={`${baseRoute}/gpscompass/add`}
						type='add'
						label={t('buttons.add')}
						testId='add-gps-compass'
					/>
				</CardHeader>
			)}
			<CardBody>
				{devices.map((device) => (
					<Box key={device.id} paddingBlockEnd={4}>
						<Flex paddingInlineStart={2} marginBlockEnd={1}>
							<DeviceHeader device={device} deviceType='gpscompass' />
							<ActionSection>
								{isSiteManager && (
									<EditPalette testId={`device-menu-${device.name}`}>
										<EditButton
											to={`${baseRoute}/gpscompass/${device.id}/settings`}
											label={t('buttons.edit')}
											testId='edit-device'
											asMenuListItem
										/>
										<EditButton
											onClick={() =>
												handleOpenDeleteModal(device.id, device.name)
											}
											label={t('buttons.delete')}
											testId='delete-device'
											asMenuListItem
										/>
									</EditPalette>
								)}
							</ActionSection>
						</Flex>
						<Flex flexWrap='wrap' paddingInlineStart={8} paddingBlockEnd={4}>
							<Chunk
								label={t('content.direction')}
								value={formatDirection(device.direction) ?? '-'}
								testId='direction'
							/>
							<Chunk
								units='m'
								label={t('content.orthometric_height')}
								value={device.orthometric_height?.toFixed(2) ?? '-'}
								testId='orthometric-height'
							/>
							<Chunk
								label={t('content.serial')}
								value={device.serial_number}
								testId='serial'
							/>
						</Flex>
					</Box>
				))}
			</CardBody>

			<DeleteModal
				isOpen={isOpen}
				onClose={handleCloseDeleteModal}
				handleDelete={handleDeleteDsx}
				name={gpsToDelete?.name || ''}
			/>
		</Card>
	)
}

export default GpsCompassContent
