import { useCallback, useMemo, useRef, useState } from 'react'
import throttle from 'lodash/throttle'
import type { KeyboardEvent } from 'react'
import { Joystick } from 'react-joystick-component'
import type { IJoystickUpdateEvent } from 'react-joystick-component/build/lib/Joystick'
import {
	useMoveCameraMutation,
	useMoveCameraStopMutation,
} from '@Store/cameras/camerasApi'

type JoystickControlProps = {
	siteId: number
	cameraId: number
}

const JoystickControl = ({ siteId, cameraId }: JoystickControlProps) => {
	const keyPressedRef = useRef<{ [key: string]: boolean }>({})
	const [stickPos, setStickPos] = useState({ x: 0, y: 0 })
	const [moveCamera] = useMoveCameraMutation()
	const [moveCameraStop] = useMoveCameraStopMutation()

	const handleMove = (e: IJoystickUpdateEvent) => {
		if (e.type === 'move' && e.x && e.y) {
			moveCamera({ siteId, cameraId, x: e.x, y: e.y }).catch((error) => {
				console.error('moveCamera failed: ', error)
			})
		}
	}

	const handleStop = (e: IJoystickUpdateEvent) => {
		if (e.type === 'stop') {
			moveCameraStop({ siteId, cameraId }).catch((error) => {
				console.error('moveCameraStop failed: ', error)
			})
		}
	}

	const moveCameraThrottle = useMemo(
		() => throttle(moveCamera, 150),
		[moveCamera]
	)

	const handleKeyDown = useCallback(
		(e: KeyboardEvent<HTMLElement>) => {
			keyPressedRef.current[e.key] = true
			let pos = { x: 0, y: 0 }
			switch (true) {
				case keyPressedRef.current['ArrowUp'] &&
					keyPressedRef.current['ArrowRight']:
					pos = { x: 0.5, y: 0.5 }
					break

				case keyPressedRef.current['ArrowUp'] &&
					keyPressedRef.current['ArrowLeft']:
					pos = { x: -0.5, y: 0.5 }
					break

				case keyPressedRef.current['ArrowDown'] &&
					keyPressedRef.current['ArrowRight']:
					pos = { x: 0.5, y: -0.5 }
					break

				case keyPressedRef.current['ArrowDown'] &&
					keyPressedRef.current['ArrowLeft']:
					pos = { x: -0.5, y: -0.5 }
					break

				case keyPressedRef.current['ArrowUp']:
					pos = { x: 0, y: 0.5 }
					break

				case keyPressedRef.current['ArrowDown']:
					pos = { x: 0, y: -0.5 }
					break

				case keyPressedRef.current['ArrowLeft']:
					pos = { x: -0.5, y: 0 }
					break

				case keyPressedRef.current['ArrowRight']:
					pos = { x: 0.5, y: 0 }
					break

				default:
			}

			setStickPos(pos)
			moveCameraThrottle({ siteId, cameraId, ...pos }).catch((error) => {
				console.error('moveCamera failed: ', error)
			})
		},
		[cameraId, siteId, moveCameraThrottle]
	)

	const handleKeyUp = useCallback(
		(e: KeyboardEvent<HTMLElement>) => {
			delete keyPressedRef.current[e.key]
			setStickPos({ x: 0, y: 0 })
			moveCameraThrottle.cancel()
			moveCameraStop({ siteId, cameraId }).catch((error) => {
				console.error('moveCameraStop failed: ', error)
			})
		},
		[setStickPos, moveCameraStop, cameraId, siteId, moveCameraThrottle]
	)

	return (
		<div
			onKeyDown={handleKeyDown}
			onKeyUp={handleKeyUp}
			className='joystick-container'
		>
			<Joystick
				baseColor='#e0e0e080'
				stickColor='#fff'
				size={64}
				stickSize={32}
				move={handleMove}
				stop={handleStop}
				throttle={150}
				pos={stickPos}
			/>
		</div>
	)
}

export default JoystickControl
