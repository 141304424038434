// Packages
import { CheckboxGroup, Text, Checkbox, Flex } from '@chakra-ui/react'

export type DeviceDisplayProps = {
	value: string[]
	options: { name: string; value: string }[]
	loading?: boolean
	onChange: (value: (string | number)[]) => void
}

const DeviceDisplay = (props: DeviceDisplayProps) => {
	const { options = [], loading = false, value = [], onChange } = props

	return (
		<CheckboxGroup value={value} onChange={onChange} isDisabled={loading}>
			<Flex direction='column' gap='16px' paddingTop='8px'>
				{options.map(({ name, value: optionValue }, index) => (
					<Checkbox
						key={index}
						id={`${name}-${index}`}
						value={optionValue}
						data-testid={`${optionValue}-device-sector`}
					>
						<label htmlFor={`${name}-${index}`}>
							<Text fontSize='sm'>{name}</Text>
						</label>
					</Checkbox>
				))}
			</Flex>
		</CheckboxGroup>
	)
}

export default DeviceDisplay
