import { useTranslation } from 'react-i18next'
import {
	Grid,
	GridItem,
	AccordionItem,
	AccordionButton,
	AccordionIcon,
	AccordionPanel,
	Flex,
	Box,
	Text,
	Divider,
	useTheme,
} from '@chakra-ui/react'
import Field from '@Components/FormElements'
import type { FormSelectValueType } from '@/components/FormElements/Select/Select'

import { useFormContext, useFormState } from 'react-hook-form'
import type { ThreatClassifierContribution } from '../utils/ThreatAI'
import type {
	ClassifyByCommunicationError,
	ThreatConfigClickElement,
} from '../utils/types'
import { useGetSiteRfSensorsQuery } from '@/store/rfSensors/rfSensorsApi'
import Tooltip from '@UI/Tooltip/Tooltip'

type TransmissionMethodFormProps = {
	classifier: ThreatClassifierContribution
	divider: boolean
	siteId: number
	onClickAccordion: (e: ThreatConfigClickElement) => void
}

const TransmissionMethodForm = ({
	classifier,
	divider,
	siteId,
	onClickAccordion,
}: TransmissionMethodFormProps) => {
	const { register, watch } = useFormContext()
	const { errors } = useFormState()
	const { t } = useTranslation('panels', {
		keyPrefix: 'threatAnalysis',
	})
	const {
		semanticTokens: { colors },
	} = useTheme()

	const { hasSensorInstalled } = useGetSiteRfSensorsQuery(
		{ siteId },
		{
			selectFromResult: ({ data }) => ({
				hasSensorInstalled: data?.length,
			}),
		}
	)

	const [byDroneTransmissionActive, ...rest] = watch([
		'classify_by_communication.active',
		'classify_by_class.active',
		'classify_by_geo_location.active',
		'classify_by_legal_limits.active',
		'classify_by_time.active',
		'classify_by_protected_zones.active',
		'classify_by_zone_origin.active',
	])
	const isDisabled =
		(byDroneTransmissionActive && rest.every((c) => !c)) || !hasSensorInstalled

	return (
		<Tooltip
			label={!hasSensorInstalled && t('tooltips.noRfSensorsInstalled')}
			type='wrapper'
		>
			<AccordionItem isDisabled={isDisabled}>
				<AccordionButton
					_hover={{ bg: 'transparent' }}
					bg={colors.body_bg}
					paddingBlock={4}
					marginTop={0}
					onClick={(e) => onClickAccordion(e)}
				>
					<Flex flexDirection='row' flex='1'>
						<Tooltip
							label={isDisabled ? t('tooltips.parameterDisabled') : undefined}
						>
							<Box height='100%' sx={{ '& > div > div': { marginTop: 0 } }}>
								<Field.Switch
									id='drone-transmission-method'
									register={register('classify_by_communication.active')}
									disabled={isDisabled}
								/>
							</Box>
						</Tooltip>
						<Flex flexGrow='0' flexDir='column' alignItems='flex-start' w={80}>
							<Box
								textAlign='start'
								textTransform='none'
								fontSize='sm'
								fontWeight='normal'
								color='white'
							>
								<Text>{t('headings.droneTransmissionMethod')}</Text>
							</Box>
							<Box>
								<Text
									fontSize='xs'
									fontWeight='normal'
									textTransform='none'
									textAlign='left'
									lineHeight='125%'
								>
									{t('tooltips.droneTransmissionMethod')}
								</Text>
							</Box>
						</Flex>
						<Flex
							flex='1 0 75px'
							padding={0}
							h='100%'
							justifyContent='space-between'
						>
							<Text fontSize='sm' fontWeight='normal' textAlign='center' w={12}>
								{classifier.ContributionThreatLevel
									? Math.round(classifier.ContributionThreatLevel * 100)
									: 0}
								%
							</Text>
							<AccordionIcon color={colors.button_text} />
						</Flex>
					</Flex>
				</AccordionButton>
				<AccordionPanel bg={colors.body_bg}>
					<Grid
						templateColumns='repeat(8, 11fr)'
						bg={colors.card_background}
						rowGap={0}
						paddingBottom={4}
					>
						<GridItem rowSpan={1} colSpan={1} />
						<GridItem rowSpan={1} colSpan={6}>
							<Field.Select
								title='Priority'
								options={
									t('forms.priorityOptions', {
										returnObjects: true,
									}) as FormSelectValueType[]
								}
								testId='classify-by-communication-priority'
								register={register('classify_by_communication.priority')}
								error={
									(errors as ClassifyByCommunicationError)
										?.classify_by_communication?.priority?.message
								}
								disabled={!byDroneTransmissionActive}
							/>
						</GridItem>
					</Grid>
				</AccordionPanel>
				{divider ? <Divider borderColor={colors.border_color} /> : null}
			</AccordionItem>
		</Tooltip>
	)
}

export default TransmissionMethodForm
