// Packages
import { useCallback, useEffect, useRef, useState } from 'react'
import noop from 'lodash/noop'

export type InputFragmentProps = {
	id?: string
	value?: string
	type?: string
	placeholder?: string
	min?: number
	max?: number
	autoSaveTimeout?: number
	minLength?: number
	maxLength?: number
	part?: string
	pattern?: RegExp
	testId?: string
	onBlur?: (part: string, fragmentValue: string) => void
	onFocus?: (part: string) => void
	onNext?: (value: string) => void
	onValidate: (part: string, fragmentValue: string) => boolean
}

const InputFragment = ({
	id,
	value = '',
	min = 0,
	max = 0,
	autoSaveTimeout = 1000,
	minLength = 0,
	maxLength = 0,
	type = 'text',
	placeholder = '',
	pattern,
	part = '',
	testId = '',
	onBlur = noop,
	onFocus = noop,
	onValidate,
	...otherProps
}: InputFragmentProps) => {
	const autoSaveHandlerRef = useRef<NodeJS.Timeout>()
	const [valueAux, setValueAux] = useState(
		value ? value.padStart(maxLength, '0') : ''
	)

	useEffect(() => {
		if (value) {
			setValueAux(value.padStart(maxLength, '0'))
		} else if (value === '') {
			setValueAux('')
		}
	}, [value, maxLength])

	const getNewValue = useCallback(
		(newValue: string) => {
			newValue = newValue.padStart(maxLength, '0')
			if (maxLength) {
				newValue = newValue.slice(0, maxLength)
			}

			if (Number(newValue) > max) {
				return `${value ? value : max}`.padStart(maxLength, '0')
			}

			if (Number(newValue) < min) {
				return `${value ? value : min}`.padStart(maxLength, '0')
			}

			if (!onValidate(part, newValue)) {
				return value.padStart(maxLength, '0')
			}

			return newValue
		},
		[value, min, max, maxLength, part, onValidate]
	)

	const clearAutoSaveHandler = useCallback(() => {
		if (autoSaveHandlerRef.current) {
			clearTimeout(autoSaveHandlerRef.current)
			autoSaveHandlerRef.current = undefined
		}
	}, [])

	const handleBlur = useCallback(() => {
		clearAutoSaveHandler()
		if (valueAux) {
			const newValue = getNewValue(valueAux)
			setValueAux(newValue)
			onBlur(part, newValue)

			return
		}

		onBlur(part, valueAux)
	}, [valueAux, onBlur, part, getNewValue, clearAutoSaveHandler])

	const handleChange = useCallback(
		(e: any) => {
			let newValue = e.target.value
			if (
				newValue.length > maxLength ||
				!newValue.match(pattern) ||
				Number(newValue) > max
			) {
				return
			}

			if (newValue.length === maxLength && !onValidate(part, newValue)) {
				return
			}

			if (maxLength) {
				newValue = newValue.slice(0, maxLength)
			}

			setValueAux(newValue)
			clearAutoSaveHandler()
			autoSaveHandlerRef.current = setTimeout(() => {
				newValue = getNewValue(newValue)
				onBlur(part, newValue)
			}, autoSaveTimeout)
		},
		[
			maxLength,
			max,
			pattern,
			part,
			autoSaveTimeout,
			onValidate,
			onBlur,
			clearAutoSaveHandler,
			getNewValue,
		]
	)

	const handleFocus = useCallback(
		(e: any) => {
			e.target.select()
			onFocus(part)
		},
		[onFocus, part]
	)

	return (
		<input
			{...otherProps}
			id={id}
			pattern='[0-9]+'
			value={valueAux}
			onChange={handleChange}
			onFocus={handleFocus}
			onBlur={handleBlur}
			placeholder={placeholder}
			minLength={minLength}
			maxLength={maxLength}
			data-part={`input-${part}`}
			data-testid={`${testId}-${part}`}
		/>
	)
}

export default InputFragment
