/* eslint-disable react/display-name */

import type { ReactElement } from 'react'
import {
	type Dispatch,
	type MutableRefObject,
	type SetStateAction,
	forwardRef,
	useCallback,
	useEffect,
} from 'react'
import {
	Box,
	Flex,
	Slider,
	SliderTrack,
	SliderFilledTrack,
	SliderThumb,
	Text,
} from '@chakra-ui/react'
import { MdPlayArrow, MdOutlinePause } from 'react-icons/md'

import Tooltip from '@UI/Tooltip/Tooltip'
import IconButton from '@UI/IconButton/IconButton'
import FullScreenButton from '@Components/Site/Panels/Cameras/Cameras/FullScreenButton'

import { formatSecondsToMinutes } from '@Utils/formatUtils'

import { useInterval } from '@Hooks/useInterval'
import type VideoControlCameraChunks from './VideoControlCameraChunks'

type VideoControlsProps = {
	isPlaying: boolean
	currentTime: number
	duration: number // duration in seconds
	setCurrentTime: (time: any) => void
	setIsPlaying: Dispatch<SetStateAction<boolean>>
	isDisabled?: boolean
	setSeekTime?: (time: number) => void
	videoChunks: ReactElement<typeof VideoControlCameraChunks>
}
const VideoControls = forwardRef(
	(
		{
			isPlaying,
			duration,
			currentTime,
			setIsPlaying,
			setCurrentTime,
			isDisabled,
			setSeekTime,
			videoChunks,
		}: VideoControlsProps,
		fullScreenRef
	) => {
		useEffect(() => {
			const handleKeyDown = (event: KeyboardEvent) => {
				event.preventDefault()

				if (event.code === 'Space') {
					setIsPlaying(!isPlaying)
				}

				if (event.code === 'ArrowRight' && currentTime < duration) {
					setCurrentTime((prevTime: number) => prevTime + 1)
				}

				if (event.code === 'ArrowLeft' && currentTime > 0) {
					setCurrentTime((prevTime: number) => prevTime - 1)
				}
			}
			document.addEventListener('keydown', handleKeyDown)
			return () => document.removeEventListener('keydown', handleKeyDown)
		}, [isPlaying, setIsPlaying, currentTime, duration, setCurrentTime])

		useInterval(
			() => {
				setCurrentTime((prevTime: number) => {
					if (prevTime >= duration) {
						setIsPlaying(false)

						return duration
					}

					return prevTime + 0.25
				})
			},
			isPlaying ? 250 : null
		)

		const handleSliderChange = (value: number) => {
			setSeekTime && setSeekTime(value)
			setCurrentTime(value)
			if (value === duration) {
				setIsPlaying(false)
			}
		}

		// Prevent negative values
		const remainingTime =
			duration - currentTime >= 0 ? duration - currentTime : 0
		const minutesLeft = Math.floor(remainingTime / 60)
		const secondsLeft = Math.floor(remainingTime % 60)
			.toString()
			.padStart(2, '0')

		const handleClickPlay = useCallback(() => {
			if (!isPlaying && currentTime === duration) {
				setCurrentTime(0)
			}

			setIsPlaying((state) => !state)
		}, [setIsPlaying, currentTime, duration, isPlaying, setCurrentTime])

		return (
			<Flex
				bgColor='input_bg'
				h='55px'
				p='12px'
				alignItems='center'
				data-testid='video-controls'
			>
				<IconButton
					testId={isPlaying ? 'pause' : 'play'}
					aria-label='Play video'
					icon={isPlaying ? <MdOutlinePause /> : <MdPlayArrow />}
					onClick={handleClickPlay}
					bg='card_bg'
					isDisabled={isDisabled}
				/>
				<Slider
					defaultValue={0}
					max={duration}
					value={currentTime}
					onChange={handleSliderChange}
					isDisabled={isDisabled}
				>
					<SliderTrack h='25px' bg='card_bg'>
						<SliderFilledTrack zIndex={1} backgroundColor='filter_bg' />
						{videoChunks}
					</SliderTrack>
					<Tooltip
						label={formatSecondsToMinutes(currentTime)}
						placement='bottom'
					>
						<SliderThumb borderRadius={0} h='25px' w='6px' />
					</Tooltip>
				</Slider>
				<Box paddingLeft={5} paddingRight={1}>
					<Text fontSize='sm'>{`${minutesLeft}:${secondsLeft}`}</Text>
				</Box>
				<FullScreenButton
					cameraRef={fullScreenRef as MutableRefObject<HTMLDivElement>}
				/>
			</Flex>
		)
	}
)

export default VideoControls
