export const CAMERA_TYPES = [
	{
		label: 'Bosch MIC IP Starlight 7000i',
		value: 'bosch_7000i',
	},
	{
		label: 'Bosch MIC IP Starlight 7100i',
		value: 'bosch_7100i',
	},
	{
		label: 'FLIR Ranger HDC MR UC',
		value: 'flir_hdc_mr_uc',
	},
	{
		label: 'Trakka TC-300',
		value: 'trakka_tc300',
	},
]
