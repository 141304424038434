import { createSelector } from '@reduxjs/toolkit'
import { baseApi } from '@Store/baseApi'

import type { Site, Zone } from '../types'
import type { ZoneSchema } from '@Components/Forms/MapsZonesForms/ZoneForm/ZoneForm.schema'

type CreateZoneRequest = ZoneSchema & { siteId: Site['id'] }
type UpdateZoneRequest = ZoneSchema & { siteId: Site['id']; zoneId: Zone['id'] }
type DeleteZoneRequest = { siteId: Site['id']; zoneId: Zone['id'] }

export const zonesApi = baseApi
	.enhanceEndpoints({ addTagTypes: ['Zones'] })
	.injectEndpoints({
		endpoints: (builder) => ({
			getZones: builder.query<Zone[], { siteId: number }>({
				query: ({ siteId }) => ({
					url: `/api/sites/${siteId}/zones`,
				}),
				providesTags: ['Zones'],
				transformResponse: (response: { zones: Zone[] }) => response.zones,
			}),
			getZone: builder.query<Zone, { siteId: number; zoneId: number }>({
				query: ({ siteId, zoneId }) => ({
					url: `/api/sites/${siteId}/zones/${zoneId}`,
				}),
				providesTags: ['Zones'],
				transformResponse: (response: { zone: Zone }) => response.zone,
			}),
			createZone: builder.mutation<Zone, CreateZoneRequest>({
				query: ({ siteId, ...zone }) => ({
					url: `/api/sites/${siteId}/zones`,
					method: 'POST',
					body: {
						...zone,
						site_id: siteId,
					},
				}),
				invalidatesTags: ['Zones'],
			}),
			updateZone: builder.mutation<Zone, UpdateZoneRequest>({
				query: ({ siteId, zoneId, ...zone }) => ({
					url: `/api/sites/${siteId}/zones/${zoneId}`,
					method: 'PATCH',
					body: {
						...zone,
						site_id: siteId,
					},
				}),
				invalidatesTags: ['Zones'],
			}),
			deleteZone: builder.mutation<{ message: string }, DeleteZoneRequest>({
				query: ({ siteId, zoneId }) => ({
					url: `/api/sites/${siteId}/zones/${zoneId}`,
					method: 'DELETE',
				}),
				invalidatesTags: ['Zones'],
			}),
		}),
	})

export const selectAlertZones = createSelector(
	(data?: Zone[]) => data,
	(zones) => zones?.filter((zone) => zone.zone_type === 'alert') ?? []
)

export const selectThreatZones = createSelector(
	(data?: Zone[]) => data,
	(zones) => zones?.filter((zone) => zone.zone_type === 'origin') ?? []
)

export const selectHasAlertZones = createSelector(
	[selectAlertZones],
	(zones) => zones?.length > 0
)

export const selectHasThreatZones = createSelector(
	(data?: Zone[]) => data,
	(data) => data?.find((zone) => zone.zone_type === 'origin')
)

export const {
	useGetZonesQuery,
	useGetZoneQuery,
	useCreateZoneMutation,
	useUpdateZoneMutation,
	useDeleteZoneMutation,
} = zonesApi
