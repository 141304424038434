// Packages
import { createContext } from 'react'

export type RecordingToolContextData = {
	startRecording: (filename: string) => void
	stopRecording: () => void
	discardRecording: () => void
	isRecording: boolean
	isScreenRecording: boolean
	recordingOwner: boolean
	recordingEnabled: boolean
	duration: number | null
	durationStr: string | null
}

const RecordingToolContext = createContext<RecordingToolContextData>({
	startRecording: (filename) => {},
	stopRecording: () => {},
	discardRecording: () => {},
	isRecording: false,
	isScreenRecording: false,
	recordingOwner: false,
	recordingEnabled: true,
	duration: 0,
	durationStr: '00:00:00',
})

export default RecordingToolContext
