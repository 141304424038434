import type { ReactNode } from 'react'
import { Box, Flex } from '@chakra-ui/react'
import Headings from '@UI/Headings/Headings'
import StatusDot from './StatusDot'
import type { Sensor, SensorType } from '@Store/types'

const DeviceHeader = ({
	device,
	deviceType,
	children,
}: {
	device: Sensor
	deviceType: SensorType
	children?: ReactNode
}) => {
	return (
		<Flex isTruncated flex={2}>
			<Box minW={6} w={6} paddingBlockStart={0.5}>
				<StatusDot
					color={device.status_color}
					type={deviceType}
					id={device.id}
				/>
			</Box>
			<Box isTruncated marginInlineEnd={2}>
				<Headings.DeviceHeading name={device.name} />
				{children}
			</Box>
		</Flex>
	)
}

export default DeviceHeader
