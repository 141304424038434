export const leafletMapMarkerStyles = {
	'.c2-map-marker': {
		'&__selected': {
			position: 'absolute',
			top: '-8px',
			left: '-6px',
			zIndex: -100,
		},
	},
	'.c2-map-marker-number': {
		position: 'absolute',
		left: '19px',
		top: 'calc(50% - 7px)', // Half size of font
		transform: 'translate(-50%, -50%)',
		fontSize: 14,
		fontWeight: 400,
		width: '26px',
		height: '26px',
		lineHeight: '26px',
		textAlign: 'center',
		borderRadius: '100%',
		backgroundColor: 'white',
		overflow: 'hidden',
		color: 'var(--c2-colors-body_text__dark)',
	},
}
