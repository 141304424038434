// Packages
import { z } from 'zod'

const recordingToolFormFields = z.object({
	filename: z.string().max(255),
})

const recordingToolFormSchema = recordingToolFormFields.superRefine(
	(data, ctx) => {}
)

export default recordingToolFormSchema
