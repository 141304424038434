import { Center, Button, Icon, Text } from '@chakra-ui/react'
import type { IconType } from 'react-icons'

type QuickActionsButtonProps = {
	icon: IconType
	label: string
	isDisabled?: boolean
	onClick?: () => void
	isActive?: boolean
	isError?: boolean
	testId?: string
	isLoading?: boolean
	width?: string | number
	maxWidth?: string | number
	height?: string | number
	flexGrow?: string | number
}

const iconSize = '24px'

const QuickActionsButton = ({
	icon,
	label,
	isDisabled,
	onClick,
	isActive,
	testId,
	isError = false,
	isLoading = false,
	width,
	height,
	flexGrow = 1,
}: QuickActionsButtonProps) => {
	return (
		<Button
			variant='quickActions'
			h='76px'
			minW='calc(50% - 4px)'
			isDisabled={isDisabled}
			pointerEvents={isDisabled ? 'none' : undefined}
			onClick={onClick}
			isActive={isActive}
			data-testid={testId}
			border={`${isError ? '1px solid' : 'none'}`}
			borderColor={`${isError ? 'error' : ''}`}
			isLoading={isLoading}
			width={width}
			height={height}
			flexGrow={flexGrow}
			flex={1}
		>
			<Center flexDirection='column' gap='4px'>
				<Icon as={icon} boxSize={iconSize} />
				<Text whiteSpace='normal' fontSize='10px'>
					{label}
				</Text>
			</Center>
		</Button>
	)
}

export default QuickActionsButton
