import type { AppStartListening } from '@Store/middleware'
import { sitesApi } from '../sites/sitesApi'
import { sitesWsApi } from '../sites/sitesWsApi'
import { setActiveTargetId } from '../ui/uiSlice'
import { systemApi } from '../system/systemApi'

let isDemoSystem: boolean | undefined = undefined

const addSimulatorDemoMiddleware = (startListening: AppStartListening) => {
	startListening({
		matcher: systemApi.endpoints.getSystemInfo.matchFulfilled,
		effect: (action) => {
			const { payload } = action
			isDemoSystem = payload?.demo_system
		},
	})

	startListening({
		matcher: sitesApi.endpoints.updateHeartbeat.matchFulfilled,
		effect: (action, { dispatch, getState }) => {
			const { payload, meta } = action
			const siteId = meta.arg.originalArgs.siteId

			if (isDemoSystem) {
				// determine is a dbsim simulator running
				const isSimulatorRunning =
					payload.message !== 'Skip - Simulation not running'

				if (isSimulatorRunning) {
					if (siteId) {
						// fetch the getSiteLive state
						const state = getState()
						const result =
							sitesWsApi.endpoints.getSiteLive.select(siteId)(state)
						const { data } = result

						// pull out the latest new target_id
						const latestTargetId =
							data?.detections &&
							data?.detections.length > 0 &&
							data?.detections[data?.detections.length - 1]?.target_id

						// set the active detection
						if (latestTargetId) dispatch(setActiveTargetId(latestTargetId))
					}
				}
			}
		},
	})
}

// Exports
export { addSimulatorDemoMiddleware }
