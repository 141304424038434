import { useTranslation } from 'react-i18next'
import {
	Grid,
	GridItem,
	AccordionItem,
	AccordionButton,
	AccordionIcon,
	AccordionPanel,
	Flex,
	Box,
	Text,
	Divider,
	useTheme,
} from '@chakra-ui/react'
import Field from '@Components/FormElements'

import { useFormContext, useFormState } from 'react-hook-form'
import type { ThreatClassifierContribution } from '../utils/ThreatAI'
import type {
	ClassifyByGeoLocationError,
	ThreatConfigClickElement,
} from '../utils/types'
import Tooltip from '@UI/Tooltip/Tooltip'
import type { FormSelectValueType } from '@/components/FormElements/Select/Select'

type GeoLocationFormProps = {
	classifier: ThreatClassifierContribution
	divider: boolean
	onClickAccordion: (e: ThreatConfigClickElement) => void
}

const DroneGeoLocationForm = ({
	classifier,
	divider,
	onClickAccordion,
}: GeoLocationFormProps) => {
	const { register, watch } = useFormContext()
	const { errors } = useFormState()
	const { t } = useTranslation('panels', {
		keyPrefix: 'threatAnalysis',
	})
	const {
		semanticTokens: { colors },
	} = useTheme()

	const [byGeoLocationActive, ...rest] = watch([
		'classify_by_geo_location.active',
		'classify_by_class.active',
		'classify_by_communication.active',
		'classify_by_legal_limits.active',
		'classify_by_time.active',
		'classify_by_protected_zones.active',
		'classify_by_zone_origin.active',
	])
	const isDisabled = byGeoLocationActive && rest.every((c) => !c)

	return (
		<AccordionItem>
			<AccordionButton
				_hover={{ bg: 'transparent' }}
				bg={colors.body_bg}
				paddingBlock={4}
				marginTop={0}
				onClick={(e) => onClickAccordion(e)}
			>
				<Flex flexDirection='row' flex='1'>
					<Tooltip
						label={isDisabled ? t('tooltips.parameterDisabled') : undefined}
					>
						<Box
							height='100%'
							sx={{ '& > div > div': { marginTop: 0 } }}
							// Rewrite margin for switch
						>
							<Field.Switch
								id='geo-location'
								register={register('classify_by_geo_location.active')}
								disabled={isDisabled}
							/>
						</Box>
					</Tooltip>
					<Flex flexGrow='0' flexDir='column' alignItems='flex-start' w={80}>
						<Box
							textAlign='start'
							textTransform='none'
							fontSize='sm'
							fontWeight='normal'
							color='white'
						>
							<Text>{t('headings.geoLocation')}</Text>
						</Box>
						<Box>
							<Text
								fontSize='xs'
								fontWeight='normal'
								textTransform='none'
								textAlign='left'
								lineHeight='125%'
							>
								{t('tooltips.geoLocation')}
							</Text>
						</Box>
					</Flex>
					<Flex
						flex='1 0 75px'
						padding={0}
						h='100%'
						justifyContent='space-between'
					>
						<Text fontSize='sm' fontWeight='normal' textAlign='center' w={12}>
							{classifier.ContributionThreatLevel
								? Math.round(classifier.ContributionThreatLevel * 100)
								: 0}
							%
						</Text>
						<AccordionIcon color={colors.button_text} />
					</Flex>
				</Flex>
			</AccordionButton>
			<AccordionPanel bg={colors.body_bg}>
				<Grid
					templateColumns='repeat(8, 11fr)'
					bg={colors.card_background}
					rowGap={0}
					paddingBottom={4}
				>
					<GridItem rowSpan={3} colSpan={1} />
					<GridItem rowSpan={1} colSpan={6}>
						<Field.Select
							title='Priority'
							options={
								t('forms.priorityOptions', {
									returnObjects: true,
								}) as FormSelectValueType[]
							}
							testId='classify-by-geo-location-priority'
							register={register('classify_by_geo_location.priority')}
							error={
								(errors as ClassifyByGeoLocationError)?.classify_by_geo_location
									?.priority?.message
							}
							disabled={!byGeoLocationActive}
						/>
					</GridItem>
				</Grid>
			</AccordionPanel>
			{divider ? <Divider borderColor={colors.border_color} /> : null}
		</AccordionItem>
	)
}

export default DroneGeoLocationForm
