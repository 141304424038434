// React
import { useEffect, useMemo, useRef, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
// Chakra
import {
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Grid,
	RangeSlider as ChakraRangeSlider,
	RangeSliderFilledTrack,
	RangeSliderThumb,
	RangeSliderTrack,
	type RangeSliderProps,
	NumberInputField,
} from '@chakra-ui/react'
// Components
import Tooltip from '@UI/Tooltip/Tooltip'
import SliderNumberInput from '../SliderNumberInput/SliderNumberInput'
// Types
import type { Units } from '@Components/FormElements/types/Units'
import type { FormElementProps } from '@Components/FormElements/types/FormElementProps'
// Utils
import { formatDegToMils, formatMilsToDeg } from '@Utils/formatUtils'
// Hooks
import useUnits from '@Hooks/useUnits'

type FormRangeSliderProps = Omit<FormElementProps, 'value' | 'required'> & {
	defaultValue: number[]
	min: number
	max: number
	step?: number
	minStepsBetweenThumbs?: number
	inputWidth?: number
	onChangeEnd: RangeSliderProps['onChangeEnd']
	units?: Units
}

const formatDegToMilsValue = (value: number, isMils: boolean): string => {
	return isMils ? formatDegToMils(value) : String(value)
}

const formatDegToMilsNumberValue = (value: number, isMils: boolean): number => {
	return isMils ? Number(formatDegToMils(value)) : value
}

const formatMilsToDegNumberValue = (value: number, isMils?: boolean) => {
	return isMils ? Number(formatMilsToDeg(value)) : value
}

const RangeSlider = ({
	id,
	title,
	error,
	defaultValue,
	min,
	max,
	step = 1,
	minStepsBetweenThumbs = 1,
	disabled = false,
	onChangeEnd,
	inputWidth = undefined,
	testId,
	tooltip,
	units,
}: FormRangeSliderProps) => {
	const { unit, isMils } = useUnits(units)
	const milsRef = useRef(isMils)

	const htmlId = id ?? 'rangeSlider'
	const { control } = useFormContext()

	const [range, setRange] = useState<string[]>(() =>
		defaultValue.map((value) => formatDegToMilsValue(value, !!isMils))
	)

	// Reason why we can't use form's value, is because it seems to get overlapped, because we don't have control on the form's register
	const [slider, setSlider] = useState<number[]>(() =>
		defaultValue.map((value) => formatDegToMilsNumberValue(value, !!isMils))
	)

	// This is when user switching Mils Angle, therefore we'd need to reconvert so that it reflects the correct value
	useEffect(() => {
		if (milsRef.current === isMils) return
		milsRef.current = isMils

		if (isMils) {
			setSlider(slider.map((slide) => Number(formatDegToMils(slide))))
			setRange(slider.map((slide) => formatDegToMils(slide)))
		} else {
			setSlider(slider.map((slide) => Number(formatMilsToDeg(slide))))
			setRange(slider.map((slide) => formatMilsToDeg(slide)))
		}
	}, [isMils, slider])

	const leftInputMin = formatDegToMilsNumberValue(min, !!isMils)
	const leftInputMax = slider[1] - minStepsBetweenThumbs

	const rightInputMin = slider[0] + minStepsBetweenThumbs
	const rightInputMax = formatDegToMilsNumberValue(max, !!isMils)

	const calculatedInputWidth = useMemo(() => {
		if (inputWidth) return `${inputWidth}ch`
		else if (String(max).length >= 5) return `${String(max).length + 2}ch`
		else return '9ch'
	}, [inputWidth, max])

	const handleOnIndexChange = (
		{
			value,
			index,
			formValue,
		}: {
			value: string
			index: number
			formValue: number[]
		},
		onChange: (...event: any[]) => void
	) => {
		const nextSlider = range.map((r, i) => (i === index ? value : r))
		setRange(nextSlider)

		if (
			index === 0 &&
			(Number(value) < leftInputMin || Number(value) > leftInputMax)
		)
			return

		if (
			index === 1 &&
			(Number(value) < rightInputMin || Number(value) > rightInputMax)
		)
			return

		const nextSliderFormValue = slider.map((v, i) =>
			i === index ? Number(value) : v
		)

		setSlider(nextSliderFormValue)

		const nextSliderFormattedFormValue = formValue.map((v, i) =>
			i === index ? formatMilsToDegNumberValue(Number(value), isMils) : v
		)

		onChange(nextSliderFormattedFormValue)
		onChangeEnd?.(nextSliderFormattedFormValue)
	}

	const handleOnSliderChange = (
		value: number[],
		onChange: (...event: any[]) => void
	) => {
		setSlider(value)
		setRange(value.map(String))

		onChange(value.map((v) => formatMilsToDegNumberValue(v, isMils)))
		onChangeEnd?.(value.map((v) => formatMilsToDegNumberValue(v, isMils)))
	}

	return (
		<Tooltip label={tooltip} type='info'>
			<FormControl isInvalid={!!error} isDisabled={disabled}>
				<Controller
					control={control}
					name={htmlId}
					defaultValue={defaultValue}
					render={({ field: { value, onChange } }) => {
						return (
							<>
								<FormLabel>
									{title} {!!unit && `(${unit})`}
								</FormLabel>
								<Grid
									templateColumns={`${calculatedInputWidth} 1fr ${calculatedInputWidth}`}
								>
									<Flex justifyContent='flex-start'>
										<SliderNumberInput
											min={leftInputMin}
											max={leftInputMax}
											value={range[0]}
											step={step}
											onChange={(val) =>
												handleOnIndexChange(
													{
														value: val,
														index: 0,
														formValue: value,
													},
													onChange
												)
											}
										>
											<NumberInputField
												w={calculatedInputWidth}
												p={0}
												textAlign={'center'}
												{...(testId && { 'data-testid': `${testId}-min` })}
											/>
										</SliderNumberInput>
									</Flex>

									<ChakraRangeSlider
										defaultValue={defaultValue}
										min={leftInputMin}
										max={rightInputMax}
										step={step}
										isDisabled={disabled}
										minStepsBetweenThumbs={minStepsBetweenThumbs}
										onChange={(value) => handleOnSliderChange(value, onChange)}
										value={slider}
									>
										<RangeSliderTrack>
											<RangeSliderFilledTrack />
										</RangeSliderTrack>
										<RangeSliderThumb index={0} />
										<RangeSliderThumb index={1} />
									</ChakraRangeSlider>
									<Flex justifyContent='flex-end'>
										<SliderNumberInput
											textAlign='center'
											min={rightInputMin}
											max={rightInputMax}
											value={range[1]}
											step={step}
											onChange={(val) =>
												handleOnIndexChange(
													{
														value: val,
														index: 1,
														formValue: value,
													},
													onChange
												)
											}
										>
											<NumberInputField
												w={calculatedInputWidth}
												p={1}
												textAlign={'center'}
												{...(testId && { 'data-testid': `${testId}-max` })}
											/>
										</SliderNumberInput>
									</Flex>
								</Grid>
							</>
						)
					}}
				/>
				<FormErrorMessage>{error}</FormErrorMessage>
			</FormControl>
		</Tooltip>
	)
}

export default RangeSlider
