// React
import { useMemo } from 'react'
// Chakra
import { Box, Text } from '@chakra-ui/react'
// Utils
import useUnits from '@Hooks/useUnits'
import { formatDegToMils } from '@Utils/formatUtils'
import type { Units } from '@Components/FormElements/types/Units'

const Chunk = ({
	label,
	value = '-',
	testId,
	units,
}: {
	label: string
	value: string | number
	testId?: string
	units?: Units
}) => {
	const { unit, isMils } = useUnits(units)

	const displayedUnit = useMemo(() => {
		if (units && unit) {
			if (value === '-' || value === '') return null
			if (value === 0) return unit
			return unit
		}
		return null
	}, [unit, value, units])

	const displayedValue = useMemo(() => {
		if (value === '' || value === '-') return '-'
		if (isMils) {
			if (value === 0) return value ? formatDegToMils(+value) : value
			return formatDegToMils(+value)
		}
		return value
	}, [value, isMils])

	let displayedLabel = label
	if (displayedUnit) {
		displayedLabel += ` (${displayedUnit})`
	}

	return (
		<Box width='50%' paddingBlockEnd={2}>
			<Text fontSize='sm' color='label_color'>
				{displayedLabel}
			</Text>
			<Text
				fontSize='md'
				color='white'
				data-testid={testId}
				_firstLetter={{ textTransform: 'uppercase' }}
			>
				{displayedValue}
			</Text>
		</Box>
	)
}

export default Chunk
