// Packages
import { useCallback } from 'react'
import { Flex, Text, useDisclosure } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { MdPlayArrow, MdStop } from 'react-icons/md'

// Alias
import RecordingToolModal from '@/components/RecordingTool/RecordingToolModal'

// Relatives
import QuickActionsButton from '../QuickActionsButton/QuickActionsButton'
import useRecordingTool from '@Components/RecordingTool/hooks/useRecordingTool'

const RecordingToolActions = () => {
	const { isRecording, recordingEnabled, startRecording, stopRecording } =
		useRecordingTool()

	const { isOpen, onOpen, onClose } = useDisclosure()
	const { t } = useTranslation('recordingTool', {
		keyPrefix: 'component.action',
	})

	const handleClick = useCallback(() => {
		if (isRecording) {
			stopRecording()
		} else {
			onOpen()
		}
	}, [onOpen, stopRecording, isRecording])

	const handleClickClose = useCallback(
		(values?: { filename: string }) => {
			onClose()
			if (values?.filename) {
				startRecording(values.filename)
			}
		},
		[onClose, startRecording]
	)

	const handleClickCancel = useCallback(() => {
		onClose()
	}, [onClose])

	return (
		<Flex
			flexDirection='column'
			alignItems='center'
			justifyContent='center'
			gap='16px'
			minHeight='100%'
			paddingTop='8px'
			paddingBottom='8px'
		>
			<Text fontSize='12px' align='center'>
				{t('header')}
			</Text>
			<QuickActionsButton
				width='100%'
				height='auto'
				flexGrow='0'
				icon={isRecording ? MdStop : MdPlayArrow}
				label={t(isRecording ? 'actionButtonStop' : 'actionButton')}
				onClick={handleClick}
				// isActive={isStreaming}
				isDisabled={!recordingEnabled}
				testId='quick-action-record'
			/>
			<RecordingToolModal
				isOpen={isOpen}
				onClose={handleClickClose}
				onCancel={handleClickCancel}
				headerText={t('modal.header')}
				modalBodyText={t('modal.body')}
				modalBodyTextFiles={
					t('modal.files', { returnObjects: true }) as string[]
				}
			/>
		</Flex>
	)
}

export default RecordingToolActions
