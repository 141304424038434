import { memo } from 'react'
import { useParams } from 'react-router-dom'
import { useTheme } from '@chakra-ui/react'
import { nanoid } from '@reduxjs/toolkit'
import {
	useGetSiteQuery,
	useGetSiteInstallationsQuery,
} from '@Store/sites/sitesApi'
import { useGetZonesQuery } from '@Store/zones/zonesApi'

import Polygon from '@Components/MapShapes/Polygon/Polygon'
import SiteMarker from '@Components/Markers/SiteMarker/SiteMarker'
import SentryMarker from '@Components/Markers/SentryMarker/SentryMarker'

import useCenterMap from '../useCenterMap'

const ZonePreview = () => {
	const {
		semanticTokens: { colors },
	} = useTheme()

	const { siteId: siteIdParam, zoneId: zoneIdParam } = useParams()
	const siteId = Number(siteIdParam)
	const zoneId = Number(zoneIdParam)

	const { siteLatitude, siteLongitude } = useGetSiteQuery(siteId, {
		selectFromResult: ({ data }) => ({
			siteLatitude: data?.latitude ?? 0,
			siteLongitude: data?.longitude ?? 0,
		}),
		skip: !siteId,
	})

	const { data: zones } = useGetZonesQuery(
		{ siteId },
		{
			skip: !siteId,
		}
	)

	const { data: installations } = useGetSiteInstallationsQuery(siteId, {
		skip: !siteId,
	})

	useCenterMap({
		latitude: siteLatitude,
		longitude: siteLongitude,
	})

	const highlightedZone = (zones ?? []).find((zone) => zone.id === zoneId)

	const normalZones = (zones ?? []).filter((zone) => zone.id !== zoneId)

	return (
		<>
			<SiteMarker position={[siteLatitude, siteLongitude]} />
			{installations?.map(
				({ id, name, latitude, longitude, sentry_type, status_color }) => (
					<SentryMarker
						key={id}
						name={name}
						position={[latitude, longitude]}
						sentryType={sentry_type}
						iconColor={status_color}
					/>
				)
			)}
			{normalZones &&
				normalZones.map((zone) => (
					<Polygon
						key={zone.id}
						positions={zone.coordinates}
						fillColor='transparent'
						color={colors.zones[zone.zone_type]}
						dashArray={[14]}
						weight={2}
						testId={`zone-${zone.zone_type}`}
					/>
				))}
			{highlightedZone && (
				<Polygon
					key={nanoid()}
					positions={highlightedZone?.coordinates}
					fillColor={colors.zones[highlightedZone.zone_type]}
					dashArray={[14]}
					weight={2}
					fillOpacity={0.5}
					color={colors.sectors.highlight}
					testId='zone-highlight'
				/>
			)}
		</>
	)
}

export default memo(ZonePreview)
