// React
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
// Chakra
import { Flex } from '@chakra-ui/react'
import { MdOpenInNew } from 'react-icons/md'
// Components
import Field from '@Components/FormElements'
import Button from '@UI/Button/Button'
// Store
import type { RfSensor_ConnectionTypes, Site } from '@Store/types'
// Utils
import { openInNewTab } from '@Utils/navigation'
// Hooks
import useAuth from '@Hooks/useAuth'
import useIpValidation, { buildRfSensorIps } from '@Hooks/useTestIp'

const DsxFormEditProfilesButton = ({
	isDisabled,
	ip,
	connectionType,
	serialNumber,
}: {
	siteId: Site['id']
	isDisabled: boolean
	connectionType: undefined | RfSensor_ConnectionTypes
	ip: undefined | string
	serialNumber: string
}) => {
	const { t } = useTranslation('forms', { keyPrefix: 'dsxForm' })

	const { isAdmin } = useAuth()

	const [deviceManagerIp, setDeviceManagerIp] = useState<string>('')

	const { validateIps, isLoading } = useIpValidation()

	useEffect(() => {
		const fetchDeviceManagerIp = async () => {
			try {
				const findRfSensorIp = buildRfSensorIps(
					connectionType,
					serialNumber,
					ip
				)
				if (!findRfSensorIp) {
					// eslint-disable-next-line react-hooks/exhaustive-deps
					isDisabled = true
					return
				}
				const validIp = await validateIps(findRfSensorIp)
				if (validIp) {
					setDeviceManagerIp(validIp.replace('http://', ''))
				}
			} catch (e) {
				console.error(e)
			}
		}
		fetchDeviceManagerIp()
	}, [ip, connectionType, serialNumber, validateIps])

	const handleClick = () => {
		if (deviceManagerIp) openInNewTab(`${deviceManagerIp}/disruptprofiles`)
	}
	if (!isAdmin) return null
	return (
		<>
			<Field.Divider title={t('headings.disruptionProfiles')} />
			<Flex justifyContent='flex-end'>
				<Button
					label={t('editProfiles')}
					leftIcon={<MdOpenInNew />}
					size='xs'
					variant='actions'
					testId='edit-profiles'
					isLoading={isLoading}
					isDisabled={isDisabled}
					onClick={handleClick}
					tooltip={isDisabled ? t('tooltips.unableToRedirect') : undefined}
				/>
			</Flex>
		</>
	)
}

export default DsxFormEditProfilesButton
