// Components
import type { DevicesList } from '../Installation'
import CameraContent from './CameraDevices/CameraContent'
import DisruptorContent from './DisruptorDevices/DisruptorContent'
import DsxDevices from './DsxDevices/DsxDevices'
import GpsCompassContent from './GpsCompassDevices/GpsCompassContent'
import RadarContent from './RadarDevices/RadarContent'
import RfDevices from './RfDevices/RfDevices'
import GenericSensorContent from './GenericSensor/GenericSensorContent'

// Store
import type { SiteInstallation } from '@Store/types'
// Utils
import ErrorBoundary from '@Components/App/ErrorHandling/ErrorBoundary'

type DevicesContentProps = {
	installationDirection: SiteInstallation['direction']
	sensorType: keyof DevicesList
	acceptLocationUpdates: SiteInstallation['accept_location_updates']
	devices:
		| SiteInstallation['rf_sensors']
		| SiteInstallation['radars']
		| SiteInstallation['cameras']
		| SiteInstallation['disruptors']
		| SiteInstallation['gps_compasses']
		| SiteInstallation['generic_sensors']
}

const DevicesContent = ({
	sensorType,
	devices,
	acceptLocationUpdates = false,
}: DevicesContentProps) => {
	switch (sensorType) {
		case 'cameras':
			return (
				<ErrorBoundary>
					<CameraContent devices={devices as SiteInstallation['cameras']} />
				</ErrorBoundary>
			)
		case 'disruptors':
			return (
				<ErrorBoundary>
					<DisruptorContent
						devices={devices as SiteInstallation['disruptors']}
					/>
				</ErrorBoundary>
			)
		case 'radars':
			return (
				<ErrorBoundary>
					<RadarContent devices={devices as SiteInstallation['radars']} />
				</ErrorBoundary>
			)
		case 'rf_sensors':
			return (
				<ErrorBoundary>
					<RfDevices
						devices={devices as SiteInstallation['rf_sensors']}
						acceptLocationUpdates={acceptLocationUpdates}
					/>
				</ErrorBoundary>
			)
		case 'rf_sensors_dsx':
			return (
				<ErrorBoundary>
					<DsxDevices
						devices={devices as SiteInstallation['rf_sensors']}
						acceptLocationUpdates={acceptLocationUpdates}
					/>
				</ErrorBoundary>
			)
		case 'gps_compasses':
			return (
				<ErrorBoundary>
					<GpsCompassContent
						devices={devices as SiteInstallation['gps_compasses']}
					/>
				</ErrorBoundary>
			)
		case 'generic_sensors':
			return (
				<ErrorBoundary>
					<GenericSensorContent
						devices={devices as SiteInstallation['generic_sensors']}
					/>
				</ErrorBoundary>
			)
		default:
			return <p>Unknown error</p>
	}
}

export default DevicesContent
