// React
import { useTransition } from 'react'
import { useTranslation } from 'react-i18next'

// Components
import { Switch } from '@chakra-ui/react'

// Store
import type { Cot } from '@Store/types/cot'
import { useUpdateCotMutation } from '@Store/cots/cotsApi'
import { useAppDispatch } from '@/store'
import { addToast } from '@/store/ui/uiSlice'
import type { FormError } from '../types'

const CotActiveToggleSwitch = ({ cot }: { cot: Cot }) => {
	const { t } = useTranslation('forms', { keyPrefix: 'cotForm' })
	const { id: cotId, site_id: siteId, enabled } = cot
	const [isPending, startTransition] = useTransition()
	const [updateCot] = useUpdateCotMutation()
	const dispatch = useAppDispatch()

	const handleToggleActive = async () => {
		startTransition(() => {
			updateCot({
				siteId,
				cotId,
				cot: { ...cot, enabled: !enabled },
			})
				.unwrap()
				.catch((error) => {
					dispatch(
						addToast({
							id: cotId,
							siteId,
							type: 'error',
							title: t('toast.updateErrorTitle'),
							description: (error as FormError)?.data?.error ?? error?.error,
							error: error,
						})
					)
				})
		})
	}

	return (
		<Switch
			onChange={handleToggleActive}
			isChecked={enabled}
			isDisabled={isPending}
		/>
	)
}

export default CotActiveToggleSwitch
