const MapMarkerIconSelected = () => {
	return (
		<div className='c2-map-marker__selected'>
			<svg
				width='52'
				height='67'
				viewBox='0 0 52 67'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path opacity='0.2' d='M49.8571 2H2V65H49.8571V2Z' fill='black' />
				<path
					d='M2 14.68V2H14.6786'
					stroke='#F68920'
					strokeWidth='3'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					d='M37.1786 2H49.8572V14.68'
					stroke='#F68920'
					strokeWidth='3'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					d='M49.8572 52.3097V64.9897H37.1786'
					stroke='#F68920'
					strokeWidth='3'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					d='M14.6786 64.9897H2V52.3097'
					stroke='#F68920'
					strokeWidth='3'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
			</svg>
		</div>
	)
}

export default MapMarkerIconSelected
