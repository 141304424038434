import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Flex, Heading, useTheme } from '@chakra-ui/react'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import type { TypeOf } from 'zod'
import {
	useCreateGenericSensorMutation,
	useGetGenericSensorQuery,
	useUpdateGenericSensorMutation,
} from '@Store/genericSensors/genericSensorsApi'
import {
	selectSiteInstallationStatusColor,
	useGetSiteLiveQuery,
} from '@Store/sites/sitesWsApi'
import {
	useGetSiteInstallationQuery,
	useGetSiteQuery,
} from '@/store/sites/sitesApi'
import { useAppDispatch } from '@Store/index'
import { updateSensorPreview } from '@Store/ui/uiSlice'
import type { SensorStatusColor } from '@Store/types'
import { sensorStatusColorMap } from '@Store/types'
import { skipToken } from '@reduxjs/toolkit/query'

import { FormButtons } from '@Components/FormElements/FormButtons/FormButtons'
import { setFormError } from '@Components/Forms/formHelper'
import FormWrapper from '@/components/FormElements/FormWrapper/FormWrapper'
import FormHeader from '../FormHeader'
import Field from '@/components/FormElements'

import genericSensorFormSchema, {
	ADD_DEFAULT_VALUES,
} from './GenericSensorForm.schema'
import StatusIcon from '@/components/_UI/StatusIcon/StatusIcon'
import { useEffect, useRef } from 'react'
import useAuth from '@Hooks/useAuth'
import Accordion from '@Components/FormElements/Accordion/Accordion'
import { useExpandFormAccordion } from '@Hooks/useExpandFormAccordion'
import type { RadioOptionType } from '@/components/FormElements/Radio/Radio'

interface GenericSensorFormProps {
	siteId: number
	installationId: number
	sensorId?: number
}

const GenericSensorForm = ({
	siteId,
	installationId,
	sensorId,
}: GenericSensorFormProps) => {
	const { t } = useTranslation('forms', { keyPrefix: 'genericSensor' })
	const isEditForm = !!sensorId
	const {
		isLoading,
		isError,
		isSuccess,
		data: sensor,
		refetch,
	} = useGetGenericSensorQuery(
		sensorId ? { siteId: siteId, sensorId: sensorId } : skipToken,
		{
			refetchOnMountOrArgChange: true,
		}
	)

	if (isEditForm && !isSuccess) return null

	const defaultValues = isEditForm
		? sensor
		: { ...ADD_DEFAULT_VALUES, sentry_id: installationId }

	return (
		<FormWrapper
			entity={t('entity')}
			isEditForm={isEditForm}
			isLoading={isLoading}
			isError={isError}
			isSuccess={isSuccess}
			refetch={refetch}
		>
			{defaultValues && (
				<Form
					siteId={siteId}
					installationId={installationId}
					sensorId={sensorId}
					defaultValues={defaultValues}
				/>
			)}
		</FormWrapper>
	)
}

type FormProps = {
	siteId: number
	installationId: number
	sensorId?: number
	defaultValues: any
}

const Form = ({
	siteId,
	installationId,
	sensorId,
	defaultValues,
}: FormProps) => {
	const navigate = useNavigate()
	const dispatch = useAppDispatch()
	const { t } = useTranslation('forms', {
		keyPrefix: 'genericSensor',
	})
	const {
		semanticTokens: { colors },
	} = useTheme()

	type Schema = TypeOf<typeof genericSensorFormSchema>
	const methods = useForm<Schema>({
		resolver: zodResolver(genericSensorFormSchema),
		defaultValues: defaultValues as Schema,
	})

	const isEditForm = !!sensorId

	const {
		formState: { isDirty, isSubmitting, errors },
		handleSubmit,
		setError,
		register,
		setValue,
		watch,
	} = methods

	const {
		direction_offset: directionOffset,
		height_offset_to_installation: heightOffsetToInstallation,
		max_range,
		sector_field_of_view,
		sensor_fusion_settings,
	} = watch()

	useEffect(() => {
		dispatch(
			updateSensorPreview({
				sentryId: installationId,
				sensorType: 'genericSensor',
				directionOffset,
				sector_field_of_view,
				max_range,
			})
		)
		return () => {
			dispatch(updateSensorPreview(null))
		}
	}, [
		installationId,
		directionOffset,
		max_range,
		sector_field_of_view,
		dispatch,
	])

	const { installationStatusColor } = useGetSiteLiveQuery(siteId, {
		selectFromResult: ({ data }) => ({
			installationStatusColor: selectSiteInstallationStatusColor(
				data,
				installationId
			),
		}),
	})

	const { siteHeight } = useGetSiteQuery(siteId, {
		skip: !siteId,
		selectFromResult: ({ data, isSuccess }) => ({
			siteHeight: isSuccess ? data?.height : 0,
		}),
	})

	// Handle absolute offset values
	const { installationName, sentryDirection, sentryHeightOffset } =
		useGetSiteInstallationQuery(
			{ siteId, installationId },
			{
				selectFromResult: ({ data, isSuccess }) => {
					return {
						installationName: isSuccess ? data?.name : 'installation',
						sentryDirection: isSuccess ? data?.direction : 0,
						sentryHeightOffset: isSuccess ? data?.height_offset_to_site : 0,
					}
				},
			}
		)

	const absoluteDirection = ((directionOffset + sentryDirection) % 360).toFixed(
		2
	)
	const absoluteHeight = (
		heightOffsetToInstallation +
		sentryHeightOffset +
		siteHeight
	).toFixed(2)

	const [createGenericSensor] = useCreateGenericSensorMutation()
	const [updateGenericSensor] = useUpdateGenericSensorMutation()

	const baseRoute = `/${siteId}/installations/${installationId}`

	const handleSave = async (payload: Schema | any) => {
		try {
			if (sensorId) {
				await updateGenericSensor({
					siteId,
					sensorId,
					...payload,
					// TODO: remove this as part of C2-10234
					generic_sensor_type: 'detector',
				})
					.unwrap()
					.then(() => navigate(baseRoute + '/generic_sensors'))
			} else {
				await createGenericSensor({
					siteId,
					...payload,
					// TODO: remove this as part of C2-10234
					generic_sensor_type: 'detector',
				})
					.unwrap()
					.then(() => navigate(baseRoute + '/generic_sensors'))
			}
		} catch (error) {
			setFormError<Schema>({ error, setError })
		}
	}

	const handleCancel = () => {
		navigate(baseRoute + '/generic_sensors')
	}

	const { isAdmin } = useAuth()

	const ref = useRef(null)
	const { expandedIndex, setExpandedIndex, errorIndexes } =
		useExpandFormAccordion(ref, errors)

	return (
		<Box bgColor={colors.background}>
			<FormHeader
				title={isEditForm ? t('headings.editSensor') : t('headings.addSensor')}
				backRoute={baseRoute + '/generic_sensors'}
			/>
			<Flex marginBlockStart={3}>
				<StatusIcon
					color={
						sensorStatusColorMap[installationStatusColor as SensorStatusColor]
					}
					testId={`${installationId}-installation-status-color-${installationStatusColor}`}
				/>
				<Heading
					color='white'
					alignContent='center'
					marginInlineStart={2}
					size='xs'
					data-testid={`installation-${installationId}`}
				>
					{installationName}
				</Heading>
			</Flex>
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(handleSave)} ref={ref}>
					<Field.TextInput
						title={t('fields.name')}
						register={register('name')}
						error={errors?.name?.message}
						testId='name'
					/>
					<Field.TextInput
						title={t('fields.serial_number')}
						register={register('serial_number')}
						tooltip={isEditForm ? t('tooltips.serial_number') : undefined}
						error={errors?.serial_number?.message}
						disabled={isEditForm}
						testId='serial-number'
					/>
					<Field.TextInput
						title={t('fields.sensor_type')}
						register={register('sensor_type')}
						tooltip={t(
							isEditForm
								? 'tooltips.sensor_type_disabled'
								: 'tooltips.sensor_type'
						)}
						error={errors?.sensor_type?.message}
						disabled={isEditForm}
						testId='sensor_type'
					/>
					<Field.TextInput
						title={t('fields.model')}
						register={register('model')}
						tooltip={isEditForm ? t('tooltips.model') : undefined}
						error={errors?.model?.message}
						disabled={isEditForm}
						testId='model'
					/>

					<Field.TextInput
						title={t('fields.ip')}
						register={register('ip')}
						error={errors?.ip?.message}
						tooltip={isEditForm ? t('tooltips.ip') : undefined}
						disabled={isEditForm}
						testId='ip'
					/>
					<Field.TextInput
						title={t('fields.port')}
						register={register('port')}
						error={errors?.port?.message}
						tooltip={isEditForm ? t('tooltips.port') : undefined}
						disabled={isEditForm}
						testId='port'
					/>

					<Accordion.Container
						setExpandedIndex={setExpandedIndex}
						index={expandedIndex}
					>
						<Accordion.Item
							title={t('headings.displaySettings')}
							testId='display-settings'
							isError={errorIndexes.has(0)}
						>
							<Field.RangeSlider
								title={t('fields.range')}
								tooltip={t('tooltips.range')}
								step={1}
								min={0}
								max={10000}
								defaultValue={[
									defaultValues.min_range,
									defaultValues.max_range,
								]}
								onChangeEnd={(value: [number, number]) => {
									setValue('min_range', value[0], {
										shouldDirty: true,
										shouldValidate: true,
										shouldTouch: true,
									})
									setValue('max_range', value[1], {
										shouldDirty: true,
										shouldValidate: true,
										shouldTouch: true,
									})
								}}
								error={[errors?.min_range?.message, errors?.max_range?.message]
									.filter((error) => !!error)
									.join(', ')}
								testId='range'
							/>
							<Field.Slider
								title={t('fields.sector_field_of_view')}
								tooltip={t('tooltips.sector_field_of_view')}
								min={0}
								max={360}
								register={register('sector_field_of_view')}
								error={errors?.sector_field_of_view?.message}
							/>
							<Heading fontSize='sm' marginTop={8} color={colors.label_color}>
								Detection Display
							</Heading>
							<Field.Radio
								register={register('detection_visualisation')}
								options={
									t('options.detectionVisualisationOptions', {
										returnObjects: true,
									}) as RadioOptionType[]
								}
								error={errors?.detection_visualisation?.message}
								defaultValue={defaultValues.detection_visualisation}
								onChange={(value) =>
									setValue(
										'detection_visualisation',
										value as 'sector' | 'line' | 'sector_and_line'
									)
								}
							/>
						</Accordion.Item>
						<Accordion.Item
							title={t('headings.calibrationParameters')}
							testId='calibration-parameters'
							isError={errorIndexes.has(1)}
						>
							{isAdmin && (
								<>
									<Box position='relative'>
										<Field.UnitsSlider
											units='deg'
											title={t('fields.tilt')}
											tooltip={t('tooltips.tilt')}
											min={-45}
											max={45}
											register={register('tilt', { valueAsNumber: true })}
											error={errors?.tilt?.message}
											testId='tilt'
										/>
									</Box>
									<Box position='relative'>
										<Field.UnitsSlider
											units='deg'
											title={t('fields.roll')}
											tooltip={t('tooltips.roll')}
											min={-45}
											max={45}
											register={register('roll', { valueAsNumber: true })}
											error={errors?.roll?.message}
											testId='roll'
										/>
									</Box>
								</>
							)}
							<Box position='relative'>
								<Field.UnitsSlider
									isAbsolute
									units='deg'
									customLabel={absoluteDirection}
									title={t('fields.direction_offset')}
									tooltip={t('tooltips.direction_offset')}
									min={0}
									max={359.99}
									step={0.1}
									register={register('direction_offset', {
										valueAsNumber: true,
									})}
									error={errors?.direction_offset?.message}
									testId='direction-offset'
								/>
							</Box>
							{isAdmin && (
								<>
									<Box position='relative'>
										<Field.UnitsSlider
											units='m'
											title={t('fields.north_offset_to_installation')}
											tooltip={t('tooltips.north_offset_to_installation')}
											min={-10}
											max={10}
											step={0.1}
											register={register('north_offset_to_installation', {
												valueAsNumber: true,
											})}
											error={errors?.north_offset_to_installation?.message}
											testId='north_offset_to_installation'
										/>
									</Box>
									<Box position='relative'>
										<Field.UnitsSlider
											units='m'
											title={t('fields.east_offset_to_installation')}
											tooltip={t('tooltips.east_offset_to_installation')}
											min={-10}
											max={10}
											step={0.1}
											register={register('east_offset_to_installation', {
												valueAsNumber: true,
											})}
											error={errors?.east_offset_to_installation?.message}
											testId='east_offset_to_installation'
										/>
									</Box>
								</>
							)}
							<Box position='relative'>
								<Field.UnitsSlider
									isAbsolute
									units='m'
									customLabel={absoluteHeight}
									title={t('fields.height_offset_to_installation')}
									tooltip={t('tooltips.height_offset_to_installation')}
									min={-100}
									max={200}
									step={0.1}
									register={register('height_offset_to_installation', {
										valueAsNumber: true,
									})}
									error={errors?.height_offset_to_installation?.message}
									testId='height-offset-to-installation'
								/>
							</Box>
						</Accordion.Item>
						<Accordion.Item
							title={t('headings.sensorFusionParameters')}
							testId='advanced-parameters'
							isError={errorIndexes.has(2)}
						>
							<Field.Slider
								title={t('fields.sensor_weight')}
								tooltip={t('tooltips.sensor_weight')}
								min={0}
								max={100}
								step={0.1}
								register={register('sensor_fusion_settings.sensor_weight', {
									valueAsNumber: true,
								})}
								error={errors?.sensor_fusion_settings?.sensor_weight?.message}
								testId='sensor-weight'
							/>
							<Field.Slider
								title={t('fields.minimum_contribution_count')}
								tooltip={t('tooltips.minimum_contribution_count')}
								min={1}
								max={50}
								step={1}
								register={register(
									'sensor_fusion_settings.minimum_contribution_count',
									{
										valueAsNumber: true,
									}
								)}
								error={
									errors?.sensor_fusion_settings?.minimum_contribution_count
										?.message
								}
								testId='minimum-contribution-count'
							/>
							<Field.Slider
								title={t('fields.sensor_contribution_timeout')}
								tooltip={t('tooltips.sensor_contribution_timeout')}
								min={1}
								max={60}
								step={1}
								register={register(
									'sensor_fusion_settings.sensor_contribution_timeout',
									{
										valueAsNumber: true,
									}
								)}
								error={
									errors?.sensor_fusion_settings?.sensor_contribution_timeout
										?.message
								}
								testId='sensor-contribution-timeout'
							/>
							<Field.Switch
								title={t('fields.stream_raw_detections')}
								tooltip={t('tooltips.stream_raw_detections')}
								register={register(
									'sensor_fusion_settings.stream_raw_detections'
								)}
								error={
									errors?.sensor_fusion_settings?.stream_raw_detections?.message
								}
								testId='stream-raw-detections'
							/>
							<Field.Radio
								title={t('fields.able_to_detect_communication_mode')}
								tooltip={t('tooltips.able_to_detect_communication_mode')}
								error={
									errors?.sensor_fusion_settings
										?.able_to_detect_communication_mode?.message
								}
								options={
									t('options.communicationModes', {
										returnObjects: true,
									}) as RadioOptionType[]
								}
								value={
									sensor_fusion_settings.able_to_detect_communication_mode
										? 'true'
										: 'false'
								}
								onChange={(value) => {
									setValue(
										'sensor_fusion_settings.able_to_detect_communication_mode',
										value === 'true',
										{ shouldDirty: true }
									)
								}}
								testId='able-to-detect-communication-mode'
							/>
						</Accordion.Item>
					</Accordion.Container>
					<FormButtons
						isSubmitting={isSubmitting}
						isDirty={isDirty}
						handleCancel={handleCancel}
					/>
				</form>
			</FormProvider>
		</Box>
	)
}

export default GenericSensorForm
