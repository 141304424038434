import { useMemo } from 'react'
import { useTheme } from '@chakra-ui/react'
import type { Detection } from '@Store/types'

const useMarkerDetectionColor = (
	isWhitelisted: boolean = false,
	preventSave: Detection['prevent_save'] = false,
	classification: Detection['classification'] = '',
	displayFiltered: Detection['display_filtered'] = false
) => {
	const {
		semanticTokens: { colors },
	} = useTheme()

	return useMemo(() => {
		if (isWhitelisted) {
			return colors.detections.whitelisted
		} else if (preventSave || classification === 'truth') {
			return colors.detections.not_saved_detection
		} else if (displayFiltered) {
			return colors.detections.display_filtered
		}
		return colors.detections.default
	}, [
		isWhitelisted,
		preventSave,
		classification,
		displayFiltered,
		colors.detections,
	])
}

export default useMarkerDetectionColor
