import { useTranslation } from 'react-i18next'
import { Box, Flex, Text } from '@chakra-ui/react'

import {
	useGetSiteLiveQuery,
	selectSiteDsxProfile,
} from '@Store/sites/sitesWsApi'

import InfoText from '@UI/InfoText/InfoText'
import FetchError from '@UI/FetchError/FetchError'
import StatusDot from '@Components/Site/Panels/SiteInstallations/DevicesContent/StatusDot'

import type { DsxProfile } from '@Store/types'

type Band = {
	label: string
	value: boolean
}

export const BandStatus = ({ profile }: { profile: DsxProfile }) => {
	const bandValues = Object.entries(profile?.bands_enabled ?? []).map(
		([key, value]) => ({
			label: `${key.split('_')[1]} MHz`,
			value: value,
		})
	)

	const bandsIncludingGnss: Band[] = [
		...bandValues,
		{ label: 'GNSS', value: profile?.gnss_enabled || false },
	]

	return (
		<Flex gap={3} flexWrap='wrap' mt={3}>
			{bandsIncludingGnss.map(({ label, value }) => (
				<Flex w='86px' h={10} alignItems='center' gap={3} key={label}>
					<StatusDot color={value ? 'green' : 'red'} size='10px' />
					<Text fontSize='12px' color='label_color'>
						{label}
					</Text>
				</Flex>
			))}
		</Flex>
	)
}

const DisruptionPanel = ({
	siteId,
	sensorId,
	testId,
}: {
	siteId: number
	sensorId: number
	testId?: string
}) => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'devicesContent.dsxContent',
	})

	const { dsxProfile, isSuccess, isError, refetch } = useGetSiteLiveQuery(
		siteId,
		{
			selectFromResult: ({ isSuccess, isError, data }) => ({
				dsxProfile: selectSiteDsxProfile(data, sensorId),
				isSuccess,
				isError,
			}),
		}
	)

	return (
		<>
			{/* TODO: Replace with standardised translated fetch error */}
			{isError && (
				<FetchError
					errorMessage={t('fetchError')}
					refetch={refetch}
					testId={`${testId}-error`}
				/>
			)}
			{isSuccess && dsxProfile && (
				<Box
					w='415px'
					bgColor='body_bg'
					p={4}
					marginInlineStart={2}
					data-testid={testId}
				>
					<Flex justifyContent='space-between'>
						<Text fontSize='sm' fontWeight={500}>
							{t('disruptionPanel')}
						</Text>
						<Text fontSize='sm'>{dsxProfile.name}</Text>
					</Flex>
					{!dsxProfile.id ? (
						<Box mt={3}>
							<InfoText title={t('onlineDevice')}>
								{t('connectDevice')}
							</InfoText>
						</Box>
					) : (
						<BandStatus profile={dsxProfile} />
					)}
				</Box>
			)}
		</>
	)
}

export default DisruptionPanel
