import { leafletMapMarkerStyles } from './map-marker'
import { leafletPopupStyles } from './popup'

export const leafletStyles = {
	// Fix screenshot tool displaying extra borders around markers
	'.leaflet-marker-icon': {
		border: '1px solid transparent',
		boxSizing: 'content-box',
	},
	'.leaflet-container': {
		'.leaflet-tile-pane': {
			filter: 'grayscale(0.8)',
		},
		'.leaflet-control-attribution': {
			marginRight: 'var(--c2-space-2)',
			a: { display: 'none' },
			span: { display: 'none' },
			// attribution control is stubbornly too tall
			lineHeight: '18px',
			height: '18px',
		},
	},
	// Map
	'.MapContainer': {
		boxSize: '100%',
	},
	// MapControls order:
	// 1) Compass, 2) MapCenter, 3) Zoom, 4) Screenshot, 5) Measure, 6) MapLayer, 7) MapLegend
	'.leaflet-control:has(.c2-map-compass-control)': {
		order: -1000,
	},
	'.leaflet-control:has(.MapLegendFullScreen)': {
		order: 1000,
	},
	// Controls
	'.leaflet-control': {
		fontFamily: 'Roboto',
	},

	'.leaflet-control-scale-line': {
		textShadow: 'none',
		lineHeight: 'unset',
		padding: 'unset',
		paddingInline: '4px',
		border: '1px solid #777',
		borderTop: 0,
	},
	'.leaflet-control-container .leaflet-bottom.leaflet-right': {
		display: 'flex',
		zIndex: 400,
	},
	'.leaflet-control-attribution': {
		order: 100,
		color: 'var(--c2-colors-leaflet.control_text)',
	},
	'.leaflet-control-container .leaflet-left': {
		transition: 'left .30s',
		display: 'flex',
		flexDirection: 'column',
	},
	'.leaflet-control-container .leaflet-control': {
		border: 'none',
		marginBottom: 'var(--c2-space-2)',
		a: {
			width: '32px',
			height: '32px',
			color: 'var(--c2-colors-leaflet-control_color)',
			backgroundColor: 'var(--c2-colors-leaflet-control_bg)',
		},
	},
	'.leaflet-bar a': {
		borderBottom: '1px solid var(--c2-colors-chakra-border-color)',
	},
	// Fix map controls overlays device action menu buttons
	'.leaflet-bottom': {
		zIndex: 500,
	},
	'.leaflet-div-icon:not(.leaflet-marker-draggable)': {
		backgroundColor: 'transparent',
		border: 0,
	},
	...leafletMapMarkerStyles,
	...leafletPopupStyles,
}
