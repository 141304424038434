// Packages
import { Box, Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

// Alias
import {
	formatElevation,
	formatEventId,
	formatHeight,
	formatRadarRCS,
} from '@/utils/formatUtils'
import useUnits from '@Hooks/useUnits'

export type DetailsRadarMaskProps = {
	targetId?: string
	elevation?: number
	rcs?: number
	isEchoShield?: boolean
	heightOffsetToSite?: number
}

const DetailsRadarMask = ({
	targetId = '',
	elevation = 0,
	rcs = 0,
	heightOffsetToSite = 0,
	isEchoShield = false,
}: DetailsRadarMaskProps) => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'notifications.detections.detectionDetail.headings',
	})

	const { isMils } = useUnits('deg')

	return (
		<Flex w='100%' flexWrap='wrap'>
			<Box w='50%' marginBlockEnd={2}>
				{isEchoShield && (
					<>
						<Text fontSize='xs' color='headings'>
							{t('height')}
						</Text>
						<Text fontSize='sm'>{formatHeight(heightOffsetToSite) ?? '-'}</Text>
					</>
				)}
				{!isEchoShield && (
					<>
						<Text fontSize='xs' color='headings'>
							{t('elevation') + `${isMils ? ' (mil)' : ' (deg)'}`}
						</Text>
						<Text fontSize='sm'>
							{formatElevation(elevation, isMils) ?? '-'}
						</Text>
					</>
				)}
			</Box>
			<Box w='50%' marginBlockEnd={2}>
				<Text fontSize='xs' color='headings'>
					{t('rcs')}
				</Text>
				<Text fontSize='sm'>{formatRadarRCS(rcs) ?? '-'}</Text>
			</Box>
			<Box>
				<Text fontSize='xs' color='headings'>
					{t('eventId')}
				</Text>
				<Text fontSize='sm'>{formatEventId(targetId) ?? '-'}</Text>
			</Box>
		</Flex>
	)
}

export default DetailsRadarMask
